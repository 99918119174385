import React from 'react';
import { Button, IconButton } from '@material-ui/core';
import CommonSectionHeader from '../CommonSectionHeader';
import { worksDataList } from '../Data/Works';
import { fn } from '../../../Functions';
import './Works.scss';

const TitleCard = ({
    title, platformInformation, firstAuthor, secondAuthor, illustLandScape, illustPortrait, href, 
}) => {
    return (
        <div className={'TitleCardContainer'}>
            <Button target='_blank' href={href} className={'IllustAnchor'} onClick={fn.gotoByAnchor} title={title}>
                <img className={'Illust LandScapeImage'} src={illustLandScape} alt={title}/>
                <img className={'Illust PortraitImage'} src={illustPortrait} alt={title}/>
            </Button>
            <div className={'InfoWrapper'}>
                <div className={'TitleName'}>
                    {title}
                </div>
                <div className={"InfoAndAction"}>
                    <div className={'InfoItemList'}>
                        <div className={'InfoItem'}>
                            {platformInformation}
                        </div>
                        <div className={`InfoItem Author`}>
                            <span>{firstAuthor || ''}</span>
                            <span>{secondAuthor || ''}</span>
                        </div>
                    </div>
                    <div className={'ArrowButtonWrapper'}>
                        <IconButton target='_blank' href={href} className={'ArrowButton'} onClick={fn.gotoByAnchor} title={title}>
                            <img src={"https://static.webtoon.today/ddah/icon/arrow_right.svg"} alt={'ArrowRight'}/>
                        </IconButton> 
                    </div>
                </div>
            </div>    
        </div>
    );
}

const Works = () => {
    return (
        <section className={'GrayColorGg'}>
            <div className={'IntroductionCardContainer'}>
                <CommonSectionHeader title={'WORKS'}/>
                <div className={'GridWrapper'}>
                    <div className={'TitleCardGrid'}>
                        {worksDataList.map(data => <TitleCard key={data.title} {...data}/>)}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Works;