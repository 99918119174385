
import { useState } from 'react';
import './FunnelDiagnosis.scss';
import { numericUnitConverter } from '../Util/Functions';
import ComparisonSingleBarChart from './ComparisonSingleBarChart';
import { Button } from '@material-ui/core';


const colNames = {
    impressions: "노출",
    acquirement: "획득",
    conversion: "전환",
    maintenance: "유지"
}

const colKeys =  ["impressions", "acquirement", "conversion", "maintenance"];

const FunnelDiagnosis = ({ 
    data,
    colSpan: givenColSpan,
    className = ""
}) => {
    const [offset, setOffset] = useState(0);
    const colSpan = givenColSpan;

    const { comparison: {rawData: comData}, target: {rawData: targetData} } = data;
    let colArray = [];
    
    for ( let idx = 0; idx < Math.min(colKeys.length, colSpan) ; idx ++ ) {
        const col = colKeys[offset + idx];
        switch (col) {
            case "impressions":
                colArray.push([ col, comData.searchExposure / (targetData.searchExposure || 1) ]);
                break;
            case "acquirement":
                colArray.push([ col, comData.weeklyPopularity / (targetData.weeklyPopularity || 1)]);
                break;
            case "conversion":
                colArray.push([ col, (comData.firstPaidEpLike/(comData.firstFreeEpLike||1)) / (targetData.firstPaidEpLike/(targetData.firstFreeEpLike||1)||1) ]);
                break;
            case "maintenance":
                colArray.push([ col, (comData.download/(comData.popularity||1)) / (targetData.download/(targetData.popularity||1)||1) ]);
                break;
            default:
                console.log("error");
        }
    }
    
    const initialMethod = colArray.reduce( (acc, cur) => Number(acc[1]) < Number(cur[1]) ? acc : cur )[0];

    const [ diagnosisMethod,  ] = useState(initialMethod);

    let drawableCols = new Array(colSpan).fill(null).map((_,idx) => (4 + offset + idx) % 4 ).map((idx) => Object.entries(colNames)[idx])
    
    return(<div className={`FunnelDiagnosisArea ${className}`} >
        {colSpan <  4 && <div className={'PrevNext'}>
            <Button onClick={()=>{setOffset( offset => (4 + offset - colSpan) % 4 )}}>{"<"}</Button>
            <Button onClick={()=>{setOffset( offset => (4 + offset + colSpan) % 4 )}}>{">"}</Button>
        </div>}
        <div className={'FunnelDiagnosisInner'} >
            <div className={`TableCol`} key={`empty`} >
                <div className={`TableHeaderCell Hidden Col`} >
                    {"empty"}
                </div>
                <div className={'TableBodyCells'} >
                    {(["comparison", "target", "similarTop"]).map( (type) => data[type].title && (
                        <div className={`TableRowHeaderCell`} key={`TableBodyRowHeaderCell:${type}:${ data[type].title}`} >
                            <RowHeaderCell cellData={[data[type].title, data[type]]} />
                        </div>
                    ))}
                </div>
            </div>
            {drawableCols.map( ([key, label]) => (
                <div className={`TableCol${key===diagnosisMethod?" Selected":""}`} key={`${key}:${label}`} >
                    <div className={`TableHeaderCell${label?"":" Hidden"} ${label}Col`} >
                        {label}
                    </div>
                    <div className={'TableBodyCells'} >
                        {(["comparison", "target", "similarTop"]).map( (type) => data[type].title && (
                            key === "empty"
                            ?<div className={`TableRowHeaderCell`} key={`TableBodyRowHeaderCell:${type}:${ data[type].title}`} >
                                <RowHeaderCell cellData={[data[type].title, data[type]]} />
                            </div>
                            :<div className={`TableBodyCell`} key={`TableBodyCell:${type}:${data[type].title}`} >
                                <Cell colName={key} cellData={[data[type].title, data[type]]} />
                            </div>))}
                    </div>
                </div>
            ))}
        </div>
    </div>)
}

const RowHeaderCell = ( {cellData}) => {
    const [title, titleInfo] = cellData;
    const rowHeaderContent = [
        // TODO: 회차와 매출 attr가 생기면 수정
        ["totalEpisode", "회차"],
        ["sales",   "추정 매출"]
    ]
    
    const roundToFirstTwoDigits = (num) => {
        const digits = Math.floor(Math.log10(Math.abs(num))) + 1;
        const divisor = Math.pow(10, digits - 2);
      
        return Math.round(num / divisor) * divisor;
    }

    return(<>
        <div className={'HeaderCellTitle'}>
            {title}
        </div>
        <div className={'HeaderBodyCellBox'} >
            {rowHeaderContent.map( ([key, label]) => {
                return (<div key={`${key}:${label}`} >
                    <ValueLabel name={label} value={numericUnitConverter(key==='sales' ? roundToFirstTwoDigits(titleInfo?.rawData[key] ||0) : titleInfo?.rawData[key] ||0, 1)} unit={key==="sales"?"":"회"} style={{fontSize: '0.75rem'}} />
                </div>);
            })}
        </div>
    </>);
}

const Cell = ( {colName, cellData}) => {
    const [, {rawData: info}] = cellData;
    const barChartData = {
        free: info.firstFreeEpLike/(info.firstFreeEpLike||1)*100,
        payment: info.firstPaidEpLike/(info.firstFreeEpLike||1)*100
    }


    if ( colName === "impressions"){
        return <div className={'InnerCell'}>
            <KeyLabel value={'구글 검색 결과'} />
            <ValueLabel value={numericUnitConverter(info.searchExposure || 0)} unit={' 개'} />
        </div>;
    }

    if ( colName === "acquirement"){
        return <div className={'InnerCell'}>
            <KeyLabel value={'관심 독자 수'} />
            <div className={'ContentPart'} >
                {([["weeklyPopularity", "주 당", " 명"], ["popularity", "총 누적", " 명"]]).map( ([key, label, unit]) => (
                    <ValueLabel name={label} value={(numericUnitConverter(info[key],0) || 0)} unit={unit} key={`${key}:${label}:${unit}`} />
                ))}
            </div>
        </div>;
    }
    
    if ( colName ==="conversion"){
        return <ComparisonSingleBarChart width={190} height={160} data={barChartData} />
    }

    if ( colName === "maintenance"){
        return <>
            <div className={'InnerCell'}>
                <KeyLabel value={'인당 다운로드'} />
                <ValueLabel name={'약'} value={( Number( (info.download / (info.popularity || 1)).toFixed(1) ).toLocaleString())} unit={'회'} />
            </div>
            <div className={'InnerCell'}>
                <KeyLabel value={'다운로드'} />
                <div className={'ContentPart'} >
                    {([["download", "총 누적", "회"],["weeklyDownload", "주 당", "회"]]).map(([key, label, unit]) => (
                        <ValueLabel name={label} value={numericUnitConverter(Math.round(info[key] ||0), 0)} unit={unit} key={`${key}:${label}:${unit}`} />
                    ))}
                </div>
            </div>
        </>
    }

    return <></>;
}

const KeyLabel = ({value, style}) => {
    return(<div className={'KeyLabelBox'} style={{...style}}>
        {value}
    </div>);
}

const ValueLabel = ({name, value, style, unit}) => {
    return(<div className={'ValueLabelBox'} style={{...style}}>
        { name && 
        <span className={'ValueLabel'}> {name}  </span>}
        <span className={'Value'}> {`${value}${unit}`} </span>
    </div>);
}


export default FunnelDiagnosis;