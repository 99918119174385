import './NotFound.scss';
import React from 'react';
import { Button } from '@material-ui/core';
import {fn} from '../Functions';


const NotFound = () => {

    return (
        <div className={'NotFoundPage'}>
            <div className={'NotFoundArea'} >
                <div className={'ImageArea'} >
                    <img src={'https://static.webtoon.today/ddah/logo-not-found.png'} alt={'NotFoundImage'} style={{width: '35%', minWidth: '175px'}}/>
                </div>
                <div className={'PageTitle'} >
                    {'페이지를 찾을 수 없어요.'}
                </div>
                <div className={'PageText'} >
                    <div className={'TextLine'}>{`페이지의 주소가 잘못 입력되었거나,`}</div>
                    <div className={'TextLine'}>{`변경 또는 삭제되어 요청하신 페이지를 찾을 수 없어요.`}</div>
                </div>
                <div className={'ButtonArea'} >
                    <Button size={'large'} variant={'contained'} color={'primary'} 
                        href={'/landing'} onClick={fn.gotoByAnchor} >
                        {'메인으로 돌아가기'}
                    </Button>
                    <Button size={'large'} variant={'contained'} color={'primary'} 
                        href={'/company'} onClick={fn.gotoByAnchor} >
                        {'회사소개 보기'}
                    </Button>
                </div>
            </div>
            
        </div>
    )
}

export default NotFound;