export const worksDataList = [
    {
        title: '데스멘탈',
        platformInformation: '카카오페이지',
        firstAuthor: '글 이권',
        secondAuthor: '그림 이권',
        illustLandScape: 'https://static.webtoon.today/ddah/introduction_page/image/poster/deathMental_landScape.png',
        illustPortrait: 'https://static.webtoon.today/ddah/introduction_page/image/poster/deathMental_portrait.png' ,
        href: "https://webt.ly/aa4/RFEPVvpnNiPCcUQW",
    },
    {
        title: '평행선의 100일',
        platformInformation: '카카오페이지',
        firstAuthor: '글 일',
        secondAuthor: '그림 일',
        illustLandScape: 'https://static.webtoon.today/ddah/introduction_page/image/poster/parallelLine100_landScape.png',
        illustPortrait: 'https://static.webtoon.today/ddah/introduction_page/image/poster/parallelLine100_portrait.png' ,
        href: "https://webt.ly/7f8/qLKCItrmzzBhUrRV",
    },
    {
        title: '하이웨이 투 헬',
        platformInformation: '네이버웹툰',
        firstAuthor: '글 가개비',
        secondAuthor: '그림 가개비',
        illustLandScape: 'https://static.webtoon.today/ddah/introduction_page/image/poster/highway2hell_landScape.png',
        illustPortrait: 'https://static.webtoon.today/ddah/introduction_page/image/poster/highway2hell_portrait.png' ,
        href: "https://webt.ly/53a/LMTDwNxLROWIowKS",
    },
    {
        title: '리드래프트',
        platformInformation: '카카오페이지',
        firstAuthor: '글 뇨민',
        secondAuthor: '그림 뇨민',
        illustLandScape: 'https://static.webtoon.today/ddah/introduction_page/image/poster/reDraft_landScape.png',
        illustPortrait: 'https://static.webtoon.today/ddah/introduction_page/image/poster/reDraft_portrait.png' ,
        href: "https://page.kakao.com/content/62336807",
    },
    {
        title: 'VR히어로',
        platformInformation: '카카오웹툰',
        firstAuthor: '글 백민기',
        secondAuthor: '그림 백민기',
        illustLandScape: 'https://static.webtoon.today/ddah/introduction_page/image/poster/vr_landScape.png',
        illustPortrait: 'https://static.webtoon.today/ddah/introduction_page/image/poster/vr_portrait.png',
        href: "https://webtoon.kakao.com/content/VR%ED%9E%88%EC%96%B4%EB%A1%9C/3322?tab=episode",
    },
    {
        title: '육식고',
        platformInformation: '네이버웹툰',
        firstAuthor: '글 반적광',
        secondAuthor: '그림 반적광',
        illustLandScape: 'https://static.webtoon.today/ddah/introduction_page/image/poster/yugsiggo_landScape.png',
        illustPortrait: 'https://static.webtoon.today/ddah/introduction_page/image/poster/yugsiggo_portrait.png' ,
        href: "https://comic.naver.com/webtoon/list?titleId=804364",
    },
    {
        title: '뻐꾸기새끼',
        platformInformation: '카카오페이지',
        firstAuthor: '글 닭군',
        secondAuthor: '그림 닭군',
        illustLandScape: 'https://static.webtoon.today/ddah/introduction_page/image/poster/cuckoo_landScape.png',
        illustPortrait: 'https://static.webtoon.today/ddah/introduction_page/image/poster/cuckoo_portrait.png' ,
        href: "https://page.kakao.com/content/61143224",
    },
    {
        title: '심연의 1호선',
        platformInformation: '네이버 시리즈',
        firstAuthor: '글 박차장',
        secondAuthor: '그림 박차장',
        illustLandScape: 'https://static.webtoon.today/ddah/introduction_page/image/poster/line1_landScape.png',
        illustPortrait: 'https://static.webtoon.today/ddah/introduction_page/image/poster/line1_portrait.png' ,
        href: "https://series.naver.com/comic/detail.series?productNo=8650471",
    },
    {
        title: '서툴고 어리숙해도 좋아하는',
        platformInformation: '만화경',
        firstAuthor: '글 몬데구거',
        secondAuthor: '그림 몬데구거',
        illustLandScape: 'https://static.webtoon.today/ddah/introduction_page/image/poster/clumsy_landScape.png',
        illustPortrait: 'https://static.webtoon.today/ddah/introduction_page/image/poster/clumsy_portrait.png' ,
        href: "https://manhwakyung.onelink.me/Te4S/vy1ltpc9",
    },
    {
        title: '1331',
        platformInformation: '네이버웹툰',
        firstAuthor: '글 보라기린',
        secondAuthor: '그림 릴매, 보라기린',
        illustLandScape: 'https://static.webtoon.today/ddah/introduction_page/image/poster/1331_landScape.png',
        illustPortrait: 'https://static.webtoon.today/ddah/introduction_page/image/poster/1331_portrait.png' ,
        href: "https://comic.naver.com/webtoon/list?titleId=796618",
    },
    {
        title: '두 번째 나에게',
        platformInformation: '카카오페이지',
        firstAuthor: '글 해인',
        secondAuthor: '그림 해인',
        illustLandScape: 'https://static.webtoon.today/ddah/introduction_page/image/poster/theSecondOne_landScape.png',
        illustPortrait: 'https://static.webtoon.today/ddah/introduction_page/image/poster/theSecondOne_portrait.png' ,
        href: "https://page.kakao.com/content/58391680",
    },
    {
        title: '행복을 만드는 방법',
        platformInformation: '네이버웹툰',
        firstAuthor: '글 적목',
        secondAuthor: '그림 적목',
        illustLandScape: 'https://static.webtoon.today/ddah/introduction_page/image/poster/happiness_landScape.png',
        illustPortrait: 'https://static.webtoon.today/ddah/introduction_page/image/poster/happiness_portrait.png' ,
        href: "https://comic.naver.com/webtoon/list?titleId=784840",
    },
];