import { Button } from '@material-ui/core';
import React from 'react';
import EditableTable from '../../Component/Table/EditableTable';
import { thumbnailize } from '../../Functions';
import { marketMetaList, updateMarketMeta } from './Market/Data/MarketMeta';

const Meta = (props) => {

    const [metas, setMetas] = React.useState([]);
    const [range, setrange] = React.useState([0,10]);

    React.useEffect(()=>{
        document.title = `메타정보 수정`;
    },[])

    React.useEffect(()=>{
        (async ()=>{
            setMetas((await marketMetaList({})).filter(meta => ['naver'].includes(meta.serviceId)).filter(meta=> !meta.historicalSetting || !meta.fictionalSetting || !meta.genre || !meta.perspective || !meta.drawingStyle));
        })();
    },[])

    const [columnsFunction, setColumnsFunction] = React.useState(()=>(row,index)=>{
        return [
            {type:'default', defaultValue: row.titleId, field: 'titleId'},
            {type:'default', defaultValue: row.serviceId, field: 'serviceId'},
            {type:'dummy', defaultValue: <p>{row.title}</p>, field: 'title'},
            {type:'dummy', defaultValue: <a href={row.thumbnail} target={'blank'}>
                    <img style={{width:100, height: 100, objectFit: 'cover'}} onError={e => e.target.style.display='none'} src={thumbnailize(row.thumbnail)} alt={row.title}/>
            </a>, field: 'thumbnail'},
            {isEditing: true, type:'select', defaultValue: row.historicalSetting || '', field: 'historicalSetting', options:[
                {key: '', value: ''}, {key: '현대', value: 'contemporary'}, {key: '중세동양', value: 'middleAgeEast'}, {key: '중세서양', value: 'middleAgeWest'}, {key: '근대동양', value: 'modernEast'}, {key: '근대서양', value: 'modernWest'}, {key: '미래', value: 'future'}
            ]},
            {isEditing: true, type:'select', defaultValue: row.fictionalSetting || '', field: 'fictionalSetting', options:[
                {key: '', value: ''}, {key: '현실', value: 'nonfiction'}, {key: '공상과학', value: 'scifi'}, {key: '판타지', value: 'fantasy'}, {key: '근현실', value: 'nearnonfiction'}, 
            ]},
            {isEditing: true, type:'select', defaultValue: row.genre || '', field: 'genre', options:[
                {key: '', value: ''}, {key: '로맨스', value: 'romance'}, {key: '액션', value: 'action'}, {key: '코미디', value: 'comedy'}, {key: '스릴러/공포', value: 'thriller'}, {key: '성장', value: 'growingup'}, {key: '힐링', value: 'healing'},
            ]},
            {isEditing: true, type:'select', defaultValue: row.perspective || '', field: 'perspective', options:[
                {key: '', value: ''}, {key: '여성향', value: 'girl'}, {key: '남성향', value: 'boy'}, 
            ]},
            {isEditing: true, type:'select', defaultValue: row.deployment || '', field: 'deployment', options:[
                {key: '', value: ''}, {key: '1인주인공', value: 'mono'}, {key: '다중주인공', value: 'ensemble'}
            ]},
            {isEditing: true, type:'select', defaultValue: row.drawingStyle || '', field: 'drawingStyle', options:[
                {key: '', value: ''}, {key: '세미망가체', value: 'semimanga'}, {key: '극실사체', value: 'hyperreal'}, {key: '실사체', value: 'real'},
                {key: '세미카툰체', value: 'semicartoon'}, {key: '세미기타체', value: 'semielse'}, {key: '카툰체', value: 'deformercartoon'}, {key: '망가체', value: 'deformermanga'}, {key: '기타그림체', value: 'deformerelse'}
            ]},
        ]
    });

    React.useEffect(()=>{
        setColumnsFunction(()=>(row,index)=>{
            return [
                {type:'default', defaultValue: row.titleId, field: 'titleId'},
                {type:'default', defaultValue: row.serviceId, field: 'serviceId'},
                {type:'dummy', defaultValue: <a href={row.href} target={"_blank"} rel="noreferrer" >{row.title}</a>, field: 'title'},
                {type:'dummy', defaultValue: <a href={row.thumbnail} target={'blank'}>
                        <img style={{width:100, height: 100, objectFit: 'cover'}} onError={e => e.target.style.display='none'} src={thumbnailize(row.thumbnail)} alt={row.title}/>
                </a>, field: 'thumbnail'},
                {isEditing: true, type:'select', defaultValue: row.historicalSetting || '', field: 'historicalSetting', options:[
                    {key: '', value: ''}, {key: '현대', value: 'contemporary'}, {key: '중세동양', value: 'middleAgeEast'}, {key: '중세서양', value: 'middleAgeWest'}, {key: '근대동양', value: 'modernEast'}, {key: '근대서양', value: 'modernWest'}, {key: '미래', value: 'future'}
                ]},
                {isEditing: true, type:'select', defaultValue: row.fictionalSetting || '', field: 'fictionalSetting', options:[
                    {key: '', value: ''}, {key: '현실', value: 'nonfiction'}, {key: '공상과학', value: 'scifi'}, {key: '판타지', value: 'fantasy'}, {key: '근현실', value: 'nearnonfiction'}, 
                ]},
                {isEditing: true, type:'select', defaultValue: row.genre || '', field: 'genre', options:[
                    {key: '', value: ''}, {key: '로맨스', value: 'romance'}, {key: '액션', value: 'action'}, {key: '코미디', value: 'comedy'}, {key: '스릴러/공포', value: 'thriller'}, {key: '성장', value: 'growingup'}, {key: '힐링', value: 'healing'},
                ]},
                {isEditing: true, type:'select', defaultValue: row.perspective || '', field: 'perspective', options:[
                    {key: '', value: ''}, {key: '여성향', value: 'girl'}, {key: '남성향', value: 'boy'}, 
                ]},
                {isEditing: true, type:'select', defaultValue: row.deployment || '', field: 'deployment', options:[
                    {key: '', value: ''}, {key: '1인주인공', value: 'mono'}, {key: '다중주인공', value: 'ensemble'}
                ]},
                {isEditing: true, type:'select', defaultValue: row.drawingStyle || '', field: 'drawingStyle', options:[
                    {key: '', value: ''}, {key: '세미망가체', value: 'semimanga'}, {key: '극실사체', value: 'hyperreal'}, {key: '실사체', value: 'real'},
                    {key: '세미카툰체', value: 'semicartoon'}, {key: '세미기타체', value: 'semielse'}, {key: '카툰체', value: 'deformercartoon'}, {key: '망가체', value: 'deformermanga'}, {key: '기타그림체', value: 'deformerelse'}
                ]},
            ]
        });
    },[metas])

    const [updateGenerator, setUpdateGenerator] = React.useState(()=>(row,index)=>async (newValue)=>{
        let newRow = Object.assign({}, row);
        newRow = Object.assign(newRow, newValue);
        await updateMarketMeta(newRow)
        const newMetas = metas.map(meta => (meta.serviceId === row.serviceId && meta.titleId === row.titleId)?newRow:meta)
        setMetas(newMetas);
    });

    React.useEffect(()=>{
        setUpdateGenerator(()=>(row,index)=>async (newValue)=>{
            let newRow = Object.assign({}, row);
            newRow = Object.assign(newRow, newValue);
            await updateMarketMeta(newRow)
            const newMetas = metas.map(meta => (meta.serviceId === row.serviceId && meta.titleId === row.titleId)?newRow:meta)
            setMetas(newMetas);
        });
    },[metas])
    
    return (<>
        <div style={{textAlign:'center'}}>
            {"현재 범위"}
            {JSON.stringify(range)}
            {"총 문서수"}
            {metas.length}
            <Button onClick={()=>{
                setrange([range[0]+10, range[1]+10])
            }}>{"다음"}</Button>
        </div>
        <div style={{display:'flex', alignItems: 'center', justifyContent: 'center', fontSize: '0.8rem'}}>
            <EditableTable
                name={"meta"}
                data={metas.slice(...range)}
                headerButtonHeaders={[]}
                columnHeaders={[
                    'titleId', 'serviceId', '제목', '썸네일',
                    '시대배경', '설정', '장르',
                    '타겟', '전개', '그림체'
                ]}
                defaultSortCol={[1,'asc']}
                headerButtonsFunction={(row,index)=>[]}
                columnsFunction={columnsFunction}
                updateGenerator={updateGenerator}
            />
        </div>
    </>)
};

export default Meta;