import React from "react";
import PropTypes from 'prop-types';

const TabPanel = (props) => {
    
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            aria-labelledby={`tab-panel-${index}`}
            {...other}
        >
            {value === index 
                ?children
                :<></>}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


export default TabPanel

