import React from "react";

import './AnalysisLaunching.scss';

import { updateContents, readContents, createContents } from "./Data/Contents";
import { sendBehaviorToChallenge } from "../../../Data/Behavior";
import { publishFBAd } from "./Data/Ad";
import { Avatar, Button } from "@material-ui/core";
import AdsSlides from "../../AdsSlides";

const AnalysisLaunching = ({candidate={}, callback=()=>{}}) => {

    const [firstEpisode, setFirstEpisode] = React.useState({images: []});
    const [sequentialEpisodes, setSequentialEpisodes] = React.useState([]);
    const [progressList, setProgressList] = React.useState([]);

    const [didRequest, setDidRequest] = React.useState(false);
    
    React.useEffect(()=>{
        let isActive = true;
        (async()=>{
            let firstEpisodeContents = (await readContents(candidate.serviceId, candidate.titleId, candidate.episodeId, candidate.snapshotId)).data;
            
            if (!isActive){
                return;
            }
            setFirstEpisode(firstEpisodeContents);
            let {nextEpisodeId, nextSnapshotId} = firstEpisodeContents || {images: []};
            
            if (nextEpisodeId === "NULL"){nextEpisodeId = null};
            if (nextSnapshotId === "NULL"){nextSnapshotId = null};

            if (!nextEpisodeId){
                nextEpisodeId = Number(candidate.episodeId) + 1;
                nextSnapshotId = null;
            }
            let secondEpisodeContents = (await readContents(candidate.serviceId, candidate.titleId, nextEpisodeId, nextSnapshotId)).data;
            if (secondEpisodeContents.nextEpisodeId === "NULL"){secondEpisodeContents.nextEpisodeId = null};
            if (secondEpisodeContents.nextSnapshotId === "NULL"){secondEpisodeContents.nextSnapshotId = null};

            if (!isActive){
                return;
            }
            if (secondEpisodeContents && secondEpisodeContents.images && secondEpisodeContents.images.length > 0){
                setSequentialEpisodes([secondEpisodeContents]);
                setProgressList(["-"]);
            }
        })();

        return ()=>{
            isActive = false;
        }
    },[candidate])

    React.useEffect(()=>{
        if (sequentialEpisodes.length === 0){
            return;
        }
        let isActive = true;
        (async()=>{
            let latestEpisodeContents = sequentialEpisodes[sequentialEpisodes.length - 1];
            let {serviceId, titleId, episodeId, nextEpisodeId, nextSnapshotId} = latestEpisodeContents || {images: []};

            if (nextEpisodeId === "NULL"){nextEpisodeId = null};
            if (nextSnapshotId === "NULL"){nextSnapshotId = null};
            
            if (!nextEpisodeId){
                nextEpisodeId = Number(episodeId) + 1;
                nextSnapshotId = null;
            }
            let nextEpisodeContents = (await readContents(serviceId, titleId, nextEpisodeId, nextSnapshotId)).data;
            if (nextEpisodeContents.nextEpisodeId === "NULL"){nextEpisodeContents.nextEpisodeId = null};
            if (nextEpisodeContents.nextSnapshotId === "NULL"){nextEpisodeContents.nextSnapshotId = null};

            if (!isActive){
                return;
            }
            if (nextEpisodeContents && nextEpisodeContents.images && nextEpisodeContents.images.length > 0){
                setSequentialEpisodes([...sequentialEpisodes, nextEpisodeContents]);
                setProgressList(Array(sequentialEpisodes.length+1).fill('-'));
            }
        
        })();
        
        return ()=>{
            isActive = false;
        }
    },[sequentialEpisodes])

    if (!candidate.title){
        return <div></div>;
    }

    return (<div className="AnalysisLaunching">
        <AdsSlides title={{...candidate, slides: candidate.slides.map(obj => obj.image)}}/>
        <div className={"TitleSection"}>
            <div className={"Title"}><Avatar src={candidate.thumbnail} size={'small'}></Avatar><span>{candidate.title}</span></div>
            <Button
                variant="contained"
                disableElevation
                disabled={didRequest}
                onClick={()=>{
                    setDidRequest(true);
                    (async()=>{
                        let arr = [firstEpisode, ...sequentialEpisodes];
                        for(let index = 0; index < arr.length; index ++){
                            let {serviceId, titleId, episodeId, snapshotId} = arr[index];
                            {
                                let newProgress = [...progressList];
                                progressList[index] = "슬라이스";
                                setProgressList(newProgress);
                            }
                            await sendBehaviorToChallenge('create-contents', {serviceId, titleId, episodeId, snapshotId});
                            {
                                let newProgress = [...progressList];
                                progressList[index] = "스냅샷덮기";
                                setProgressList(newProgress);
                            }
                            await createContents({contents: {
                                serviceId, titleId, episodeId, data: {
                                    images: arr[index].images
                                }
                            }})
                            
                            setTimeout(()=>{
                                let newProgress = [...progressList];
                                progressList[index] = "완료";
                                setProgressList(newProgress);
                            },50);
                        }
                        try{
                            let {serviceId, titleId, episodeId, snapshotId} = arr[0];
                            const target = candidate.target ? candidate.target : "whole"
                            await publishFBAd({serviceId, titleId, episodeId, snapshotId, target});
                        }catch(e){
                            console.error(e);                            
                        }
                        callback();
                    })();
                }}
            >{"데이터 분석 집행"}</Button>
        </div>
        <table>
            <thead><tr>
            <td>{'eId'}</td><td>{'snpId'}</td><td>{'image의 갯수'}</td><td>{'nextEp, nextSnp'}</td><td>{"진행상황"}</td>
            </tr></thead>
            <tbody>
            <tr className={'ContentsRow'} key={firstEpisode.episodeId}>
                <td>{firstEpisode.episodeId}</td>
                <td>{firstEpisode.snapshotId}</td>
                <td>{firstEpisode.images.length}</td>
                <td>{firstEpisode.nextEpisodeId?`${firstEpisode.nextEpisodeId}, ${firstEpisode.nextSnapshotId}`
                    :sequentialEpisodes.length > 0?<Button onClick={()=>{
                        (async()=>{
                            let {serviceId, titleId, episodeId, snapshotId} = firstEpisode;
                            let {episodeId: nextEpisodeId, snapshotId: nextSnapshotId} = sequentialEpisodes[0] || {};
                            await updateContents({contents:{serviceId, titleId, episodeId, snapshotId, nextEpisodeId, nextSnapshotId, data: firstEpisode}})
                            setFirstEpisode({...firstEpisode, nextEpisodeId, nextSnapshotId})
                        })();
                    }}>{"자동 입력"}</Button>
                    :<></>}
                </td>
                <td>
                    {progressList[0]}
                </td>
            </tr>
            {sequentialEpisodes.map( (row, index) => <tr className={'ContentsRow'} key={row.episodeId}>
                <td>{row.episodeId}</td>
                <td>{row.snapshotId}</td>
                <td>{row.images.length}</td>
                <td>{row.nextEpisodeId?`${row.nextEpisodeId}, ${row.nextSnapshotId}`
                    :sequentialEpisodes.length > index+1?<Button onClick={()=>{
                        (async()=>{
                            let {serviceId, titleId, episodeId, snapshotId} = sequentialEpisodes[index];
                            let {episodeId: nextEpisodeId, snapshotId: nextSnapshotId} = sequentialEpisodes[index+1] || {};
                            await updateContents({contents:{serviceId, titleId, episodeId, snapshotId, nextEpisodeId, nextSnapshotId, data: sequentialEpisodes[index]}})
                            setSequentialEpisodes([
                                ...sequentialEpisodes.slice(0, index),
                                {...row, nextEpisodeId, nextSnapshotId},
                                ...sequentialEpisodes.slice(index+1),
                            ])
                        })();
                    }}>{"자동 입력"}</Button>
                    :<></>}
                </td>
                <td>
                    {progressList[index+1]}
                </td>
            </tr>)}
            </tbody>
        </table>
    </div>);
}

export default AnalysisLaunching;