import React from 'react';
import { Paper, Button } from '@material-ui/core';
import SubmitFileField from './Component/SubmitFileField';
import './Inquiry.scss';

const Inquiry = () => {


    const [application, setApplication] = React.useState({slides:[]})
    
    const updateSlideOfSlides = React.useMemo(()=> async (newValue, orgFilename)=>{

        let idx = Object.keys(newValue)[0];
        let slides = [...(application.slides || [])];
        
        slides[Number(idx)] = newValue[idx];
        slides = slides.filter(thumb => thumb);
    
        setApplication({...application, ...{slides}});
        
    }, [application]);

    const [disabled, setDisabled] = React.useState(false);

    const handleSubmit = (event) => {
        setDisabled(true);
        event.preventDefault();
        setDisabled(false);
    };


    const Genre = [
        "테스트 신청",
        "연재 협의",
        "비지니스 / 협업 문의",
        "기타"
    ]

    return(
        <>
            <div className={"InquiryTemplate"}>
                <div className={"InquiryBody"}>
                    <form onSubmit={handleSubmit}>
                        <div className={"FormTitle"}>
                            {"문의하기"}
                        </div>
                            <div>
                                <label className={"Labels"}>{"이름"}</label>
                                <input type="text" className={"EachInput"} placeholder={"이름 또는 필명"} autoComplete="off" />
                                <label className={"Labels"}>{"연락처"}</label>
                                <input type="text" className={"EachInput"} placeholder={"이메일"} autoComplete="off" />
                                <label className={"Labels"}>{"문의 주제"}</label>
                                    <select className={"EachInput"} placeholder={"문의 주제"}>
                                        {Genre.map(name => 
                                            <option value={`${name}`}>{`${name}`}</option>    
                                        )}
                                    </select>
                                <label className={"Labels"}>{"문의 내용"}</label>
                                    <textarea className={"InquiryText"} placeholder={"* 문의하실 내용을 구체적으로 작성해주세요"}>
                                    </textarea>
                                <label className={"Labels"}>
                                    {"첨부"}
                                    <div className={"SubLabels"}>{"문제 발생 시 상황을 설명할 수 있는 사진이 있다면 첨부해 주세요"}</div>
                                </label>
                                <Paper variant={"outlined"} className={"Slides"} >
                                    {application.slides && application.slides.length > 0
                                        ?application.slides.map( (image,idx) =>
                                            <SubmitFileField
                                                key={idx}
                                                className={"SlideThumb"}
                                                defaultValue={image}
                                                update={updateSlideOfSlides}
                                                field={`${idx}`}
                                                isEditing={true}
                                                accept={".gif,.jpg,.jpeg,.png,.svg,.zip"}
                                                isDownloadable={true}
                                            />)
                                        :<></>}
                                        <SubmitFileField
                                            key={application.slides?application.slides.length:0}
                                            className={"SlideThumb"}
                                            defaultValue={''}
                                            update={updateSlideOfSlides}
                                            field={`${application.slides?application.slides.length:0}`}
                                            isEditing={true}
                                        />
                                </Paper>
                                <div className={"SubmitArea"}>
                                    <Button 
                                        type={"submit"} 
                                        className={"SubmitButton"}
                                        disabled={disabled}
                                    >
                                        {"문의하기"}
                                    </Button>
                                </div>
                            </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Inquiry