import React from 'react';
import {Paper, } from '@material-ui/core';

import './Survey.scss';

const SurveyLogo = 'https://static.webtoon.today/ddah/logo-01.png';
const EventCoffee = 'https://static.webtoon.today/application/EventCoffee.png';


const FinishNote = () => {

    const [remainedTimeToRedirect, setRemainedTimeToRedirect] = React.useState(5);

    React.useEffect(()=>{
        document.title = "오늘의 도전으로의 작품 이전 동의";

        const timer = setInterval(()=>{
            if (remainedTimeToRedirect <= 1){
                clearInterval(timer);

                window.location.href = 'https://challenge.webtoon.today/help/inquiry';
            }


            setRemainedTimeToRedirect(remainedTimeToRedirect - 1);
        }, 1000);

        return ()=>{
            clearInterval(timer);
        }
    },[remainedTimeToRedirect])

    return (
    
    <div className={"SurveyBody"} style={{minHeight: '100vh', boxSizing: 'border-box'}}>
        <div className={"SurveyContainer"}>
            <div elevation={0} className={"Section SurveyLogo"}>
                <div className={"Inner"}>
                    <img className={"WebtoonToday"} src={SurveyLogo} alt="logo"/>
                </div>
            </div>
            <Paper elevation={0} className={"Section SubmittedPaper"} style={{lineHeight: '2rem'}}>
                <div className={"Inner"}>
                    <div className={"SubmittedTitle"}>
                        {"작품이전 동의 신청이 마감됐습니다."}<br/>
                    </div>
                    <br/>
                    <div className={"SubmittedContents"}>
                        {"추가 문의사항은 "}
                        <span className={"BoldText"}>{"오늘의웹툰 자유연재 플랫폼 문의페이지"}</span>
                        {" 또는 "}
                        <span className={"BoldText"}><a href="mailto:dl_marketing@webtoon.today">{"담당자 메일"}</a></span>
                        {"로 연락주시면 영업일 기준 3일이내에 답변 드리겠습니다."}
                    </div>
                    <br/>
                    <div className={"SubmittedContents"}>
                        {`${remainedTimeToRedirect} 초 뒤 문의 페이지로 이동합니다.`}
                    </div>
                    <br/>
                    <div className={"SubmittedContents"}>
                        {"기존에 신청해주신 분들께는 "}
                        <span className={"BoldText"}>{"예정된 오픈 일정"}</span>
                        {"에 따라 연락 드릴 예정입니다."}
                    </div>
                    <div className={"Image"} >
                        <img src={EventCoffee} alt={"EventCoffee"}/>
                    </div>
                </div>
            </Paper>
        </div>
    </div>);

};

export default FinishNote;