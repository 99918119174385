import axios from 'axios';
import ss from './Session';


export async function sendApplication (applicationInfo) {
    try{
        let res = await axios.post(`https://api.webtoon.today/analytics-submit-confirm`, applicationInfo, {
            headers: {  
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        if (res.data && res.data.code === 200){
            return true;

        } else {
            return false;

        }
    }catch(e){
        return false;
    }
}
