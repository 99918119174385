export const numericUnitConverter = ( income, floatUnit ) => {
    const strIncome = `${income}`
    if (!income) return strIncome;

    const number = Number(income)
    if (!number) return strIncome;

    const units = [ '', '만', '억', '조', '경']
    const unitOfIncome = Math.floor(((strIncome || '').length - 1) / 4)
    const floatingPoint = typeof floatUnit === "number"?floatUnit:unitOfIncome

    if ( (strIncome || "").length < 5 ) {
        return Number(income).toLocaleString();
    } else {
        return `${(number / (10000 ** unitOfIncome)).toFixed(floatingPoint)}${units[unitOfIncome]}`
    }
}