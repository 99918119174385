import React, { useEffect } from 'react';

import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Select, Tooltip} from '@material-ui/core';

import EditableTable from '../../../Component/Table/EditableTable';

import './CompUniversityProcess.scss';

import {fn, } from '../../../Functions';
import NDA from './NDA';
import { listCompetition } from './Data/CompUniversityProcess';
import LoadingCircle from '../../../Component/LoadingCircle';
import { useDispatch } from 'react-redux';
import { readCompetitionResult } from './Data/Competition';
import moment from 'moment-timezone';
import { getTitles } from './Data/Title';
import AdsPreview from './AdsPreview';

const competitionGroups = [{}]
const UNIVERSITY_CONTEST_YEAR_LIST = ['2022','2023','2024'];

const ReportsForPartnership = ({isPartnership, ndaConfirmed, setNdaConfirmed}) => {
    if (!isPartnership){
        fn.goto('/challenge');
    }
    let dispatch = useDispatch();

    const [isLoading, setIsLoading] = React.useState(false);

    const [candidates, setCandidates] = React.useState([]);
    const [titleInfo, setTitleInfo] = React.useState({});

    const [refreshTimer, setRefreshTimer] = React.useState(null);

    const [results, setResults] = React.useState([]);

    const [askStartAnalysis, setAskStartAnalysis] = React.useState(false);
    const [currentAnalysisTarget, setCurrentAnalysisTarget] = React.useState({});

    const [askOnceAgain, setAskOnceAgain] = React.useState(false);
    const [postponedAction, setPostonedAction] = React.useState(()=>()=>{});
    const [postponedMessage, setPostponedMessage] = React.useState("");    
    
    const [filterDeleted, setFilterDeleted] = React.useState(true);
    const [filterTest, setFilterTest] = React.useState(true);
    const [filterFiltered, setFilterFiltered] = React.useState(true);

    const [showSchool, setShowSchool] = React.useState(false);
    const [showAuthor, setShowAuthor] = React.useState(false);
    const [showEmail,  setShowEmail]  = React.useState(false);
    
    const [universityContestYear, setUniversityContestYear] = React.useState(UNIVERSITY_CONTEST_YEAR_LIST.at(-1));

    const selectUniversityYear = (event) => {
        setUniversityContestYear(event.target.value);
    }

    
    useEffect(()=>{
        document.title = `${universityContestYear} 과제부활전`;
    },[universityContestYear]);

    useEffect(()=>{

        (async ()=>{
            if (ndaConfirmed) {
                setIsLoading(true);

                let candi = await listCompetition();
                setCandidates(candi);

                console.log(candi)

                let titleList = await getTitles(candi);

                let titleObj = titleList.map(row => ({[`${row.serviceId}:${row.titleId}`]: row})).reduce((a,b)=> Object.assign(a,b), {});
                setTitleInfo(titleObj);

                try{
                    let resultList = await readCompetitionResult(candi);
                    let resultObj = resultList.map(({serviceId, titleId, episodeId, snapshotId, ...other}) => ({[[serviceId, titleId, episodeId, snapshotId].join(':')]: {serviceId, titleId, episodeId, snapshotId, ...other}})).reduce((a,b)=> Object.assign(a,b), {});
                    setResults(resultObj);
                }catch(e){
                    //
                }
                setIsLoading(false);
            }
        })();
        
    },[dispatch, ndaConfirmed])

    const updateRow = () => {};

    const isBetweenTimestamp = (createdAt, startTS, endTS) => {
        if(createdAt >= startTS && createdAt <= endTS){
            return true;
        }
        return false;
    }

    if (!ndaConfirmed){
        return <NDA setNdaConfirmed={setNdaConfirmed}/>;
    }

    return (<div className={"Competition"}>
        <div style={{display: 'flex', flexDirection:'row-reverse', paddingRight: 20, justifyContent:'flex-start', alignItems:'center'}}>
            <Checkbox checked={filterDeleted} onChange={(event, checked) => setFilterDeleted(checked)}/>
            <label>{"삭제된 항목 숨기기"}</label>
            <Checkbox checked={filterTest} onChange={(event, checked) => setFilterTest(checked)}/>
            <label>{"테스트 항목 숨기기"}</label>
            <Checkbox checked={filterFiltered} onChange={(event, checked) => setFilterFiltered(checked)}/>
            <label>{"필터링된 항목 숨기기"}</label>
        </div>
        <div style={{display: 'flex', flexDirection:'row-reverse', paddingRight: 20, justifyContent:'flex-start', alignItems:'center'}}>
            <Checkbox checked={showSchool} onChange={(event, checked) => setShowSchool(checked)}/>
            <label>{"학교"}</label>
            <Checkbox checked={showAuthor} onChange={(event, checked) => setShowAuthor(checked)}/>
            <label>{"작가명"}</label>
            <Checkbox checked={showEmail} onChange={(event, checked) => setShowEmail(checked)}/>
            <label>{"이메일"}</label>
        </div>
        <div className={'SelectUniversityYearBox'}>
            <span>{'과제부활전 년도 선택'}</span>
            <Select
                className={'SelectUniversityYear'}
                value={universityContestYear}
                onChange={selectUniversityYear}
            >
                {UNIVERSITY_CONTEST_YEAR_LIST.map(value => (
                    <MenuItem key={value} value={value}>{value}</MenuItem>
                ))}
            </Select>
        </div>
        {competitionGroups.map(() => {
            let startTSFor2022 = new Date("2022-12-19 00:00:00+09:00").getTime()/1000;
            let endTSFor2022 = new Date("2023-01-01 00:00:00+09:00").getTime()/1000;
            let startTSFor2023 = new Date("2023-12-18 00:00:00+09:00").getTime()/1000;
            let endTSFor2023 = new Date("2024-01-01 00:00:00+09:00").getTime()/1000;
            let startTSFor2024 = new Date("2024-12-16 00:00:00+09:00").getTime()/1000;
            let endTSFor2024 = new Date("2025-01-01 00:00:00+09:00").getTime()/1000;
            let weekIter = `${universityContestYear} 과제부활전`;
            let subsetCandidates = candidates.filter(row =>
                   (!filterDeleted || titleInfo[`${row.serviceId}:${row.titleId}`])
                && (!filterFiltered || !row.validatedAt || row.isValid)
                && (!filterTest || !titleInfo[`${row.serviceId}:${row.titleId}`] || (titleInfo[`${row.serviceId}:${row.titleId}`].title || '').indexOf('(테스트)') < 0)
                && row.year === universityContestYear
            )

            return <div key={`${weekIter} subset`} className={"CompetitionSubset"}>
                <h2 className={"SubsetHeader"}>{weekIter}</h2><span>{`${
                        ((num)=>(num>0?`전체: ${num} 개`:``))(subsetCandidates.length)
                    } ${
                        ((num)=>(num>0?`심의중: ${num} 개`:``))(subsetCandidates.filter(row => !row.validatedAt).length)
                    } ${
                        ((num)=>(num>0?`분석대기: ${num} 개`:``))(subsetCandidates.filter(row => !row.testStartedAt && row.vaildatedAt).length)
                    } ${
                        ((num)=>(num>0?`검토중: ${num} 개`:``))(subsetCandidates.filter(row => !row.resultConfirmedAt && row.testStartedAt && row.validatedAt).length)
                    } ${
                        ((num)=>(num>0?`공지대기: ${num} 개`:``))(subsetCandidates.filter(row => !row.resultNotifiedAt && row.isValid && row.resultConfirmedAt).length)
                    } ${
                        ((num)=>(num>0?`처리완료: ${num} 개`:``))(subsetCandidates.filter(row => row.resultNotifiedAt || (row.validatedAt && !row.isValid)).length)
                    }`}</span>
                <EditableTable
                    headerButtonHeaders={[]}
                    headerButtonsFunction={()=>[]}
                    isDownloadable={true}
                    defaultSortCol={[1, "desc"]}
                    data={subsetCandidates
                        .map(candidate => {
                            let title = {...(titleInfo[`${candidate.serviceId}:${candidate.titleId}`] || {})};
                            if (!isBetweenTimestamp(candidate.createdAt, startTSFor2022, endTSFor2022) 
                                && !isBetweenTimestamp(candidate.createdAt, startTSFor2023, endTSFor2023) 
                                && !isBetweenTimestamp(candidate.createdAt, startTSFor2024, endTSFor2024) 
                                && title.title){
                                title.title = "(기간외)"+title.title;
                            }
                            let result = results[[candidate.serviceId, candidate.titleId, candidate.episodeId, candidate.snapshotId].join(':')] || {};
                            return {...title, ...candidate, ...result};
                        })}
                    columnHeaders={["제목", "생성일", "로그라인", "캐릭터설정", ...([
                        showSchool?"학교":"", showAuthor?"작가명":"", showEmail?"이메일":"",
                        ].filter(term => term)),
                        "광고컷", "필터링", "테스트 상태", "총독자", "결과 검토", "수상 여부", "결과 공지"]}
                    columnsFunction={(row, index) => [
                        {isEditing: false, field: "title", type: 'default', style: {width: '20vw', borderBottom: '1px lightgray solid', margin: 4, whiteSpace: 'nowrap'},
                            defaultValue: <Tooltip title={row.title}><div style={{display:'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{whiteSpace:'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis',}}>{row.title || `${row.author}의 작품` || '(삭제됨)'}</div>
                                <div>
                                    <IconButton size={"small"} href={`/challenge/contents/${row.serviceId}/${row.titleId}`} onClick={fn.gotoByAnchor}>📃</IconButton>
                                    <IconButton href={`/challenge/report/${row.serviceId}/${row.titleId}/${row.episodeId}/${row.snapshotId}`} onClick={fn.gotoByAnchor} size={"small"}>🎢</IconButton>
                                    <IconButton size={"small"} style={{fontSize: '0.8rem'}} onClick={()=>{
                                        setCurrentAnalysisTarget(row);
                                        setAskStartAnalysis(true);
                                    }}>📩</IconButton>
                                </div>
                            </div></Tooltip> },
                        {isEditing: false, field: "createdAt", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',}, defaultValue: moment(row.createdAt * 1000).format('YYYY-MM-DD')},
                        {isEditing: false, field: "logline", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',}, defaultValue: row.logline},
                        {isEditing: false, field: "character", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',}, defaultValue: row.character},
                        ...showSchool?[{isEditing: false, field: "schoolInfo", defaultValue: row.schoolInfo, type: 'default', style: {whiteSpace: 'nowrap'}},]:[],
                        ...showAuthor?[{isEditing: false, field: "author", defaultValue: row.author, type: 'default', style: {whiteSpace: 'nowrap'}},]:[],
                        ...showEmail?[{isEditing: false, field: "email", defaultValue: row.email, type: 'default', style: {whiteSpace: 'nowrap'}},]:[],
                        {isEditing: false, field: "slides", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',},
                            defaultValue: (!row.slides || !row.slides.length)?"🚫":"🟢",},
                        {isEditing: false, field: "validatedAt", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',},
                            defaultValue: !row.validatedAt?<></>:<>
                                {row.isValid?"🟢":<>
                                    {"🚫"}
                                    <Tooltip title={row.rejectReason || ""}><span style={{display: "inline-block", whiteSpace:"nowrap", verticalAlign: "middle", maxWidth: 90, overflow: 'hidden', textOverflow: 'ellipsis'}}>{row.rejectReason||""}</span></Tooltip>
                                </>}
                            </>},
                        {isEditing: false, field: "testStartedAt", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',},
                            defaultValue: 
                                (row.validatedAt && !row.isValid)?"🚫"
                                :!row.testStartedAt?"📩":<></>},
                        {isEditing: false, field: "readCount", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',}, defaultValue: row.readCount,},
                        {isEditing: false, field: "resultConfirmedAt", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center', width: 150, marginLeft: 'auto', marginRight: 'auto'},
                            defaultValue: !row.resultConfirmedAt?
                            !row.isValid?
                            !row.validatedAt?<>
                                {"필터링 대기중"}
                            </>:<>
                                {"제외 대상"}
                            </>:
                            !row.CTR?<>
                                {"대기중"}
                            </>:<>
                                {`${('' + row.CTR * 100).substring(0,3)}% / ${('' + row.fullReadRate * 100).substring(0,4)}% / ${('' + row.nextClickRate * 100).substring(0,4)}%`}
                            </>:<>
                                {`${('' + row.CTR * 100).substring(0,3)}% / ${('' + row.fullReadRate * 100).substring(0,4)}% / ${('' + row.nextClickRate * 100).substring(0,4)}%`}
                            </>},
                        {isEditing: (row.isValid || !row.validatedAt) && !row.resultNotifiedAt, field: "isWinner", type: 'select', style: {whiteSpace: 'nowrap', textAlign: 'center',}, fullWidth: true,
                            defaultValue: row.isWinner || "fail",  options:[{value: "unqualified", key: "🥀과락"}, {value: "fail", key: "🥀"}, {value: "topclass", key: "🏅대상"}, {value: "first", key: "🏅최우수상"}, {value: "runnerup", key: "🏅우수상"}, {value: "class_first", key: "🏅학내최우수상"}, {value: "class_runnerup", key: "🏅학내우수상"}, {value: "popularity", key: "🏅인기상"}],},
                        {isEditing: false, field: "resultNotifiedAt", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',},
                            defaultValue:
                                !row.validatedAt?"❓":
                                !row.isValid?"❌":
                                !row.resultConfirmedAt?"📃":
                                !row.resultNotifiedAt?"✅":"🟢",},
                    ]}
                    updateGenerator={(row, index)=> (newValue)=>{
                        updateRow({...row, ...newValue})
                    }}
                />
            </div>
        })}
        <Dialog open={askStartAnalysis} maxWidth={"md"} onClose={()=>{setAskStartAnalysis(false); setCurrentAnalysisTarget({})}}>
            <AdsPreview
                candidate={currentAnalysisTarget}
                callback={()=>{
                    setAskStartAnalysis(false);
                    setCurrentAnalysisTarget({});
                    
                    if (refreshTimer){
                        clearTimeout(refreshTimer);
                        setRefreshTimer(null);
                    }

                    const timer = setTimeout(()=>{
                        (async () => {
                            setIsLoading(true);
                
                            let candi = await listCompetition();
                            setCandidates(candi);
                    
                            setIsLoading(false);

                            setRefreshTimer(null);
                        })();
                    },5000)

                    setRefreshTimer(timer);
                }}/>
        </Dialog>

        <Dialog open={askOnceAgain}>
            <DialogTitle>{"다음 동작을 확인해주세요."}</DialogTitle>
            <DialogContent>{postponedMessage}</DialogContent>
            <DialogActions>
                <Button color={"primary"} variant={"contained"} onClick={()=>{
                    postponedAction();
                    setPostonedAction(()=>()=>{});
                    setPostponedMessage("");
                    setAskOnceAgain(false);
                }}>{"실행"}</Button>
                <Button color={"secondary"} variant={"outlined"} onClick={()=>{
                    setPostonedAction(()=>()=>{});
                    setPostponedMessage("");
                    setAskOnceAgain(false);
                }}>{"취소"}</Button>
            </DialogActions>
        </Dialog>
        <LoadingCircle show={isLoading}/>
    </div>);
}

export default ReportsForPartnership;