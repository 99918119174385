import React from 'react';

import MotherBoard from './Component/MotherBoard';

import MenuBar from './Component/MenuBar';
import WebtoonScore from './Component/WebtoonScore';
import PostScript from './Component/PostScript';
import Member from './Component/Member';
import Submit from './Component/Submit';
import QnA from './Component/QnA';
import Copyright from './Component/Copyright';

import { getOffsetInScreen } from '../../Functions';

import './Landing.scss';
import { Button } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

const Landing = () => {
    
    React.useEffect(()=>{
        document.title = "웹툰 데이터 분석";
    },[])

    const [isSubmitShowing, setIsSubmitShowing] = React.useState(false);

    React.useEffect(()=>{
        const scrollListener = (event)=>{
            let submitDOM = document.getElementById('Submit');

            let currentTopPos = getOffsetInScreen(submitDOM).top;
            let currentBottomPos = getOffsetInScreen(submitDOM).height + currentTopPos;
            if (currentTopPos < window.innerHeight && currentBottomPos > 0){
                if(!isSubmitShowing){
                    setIsSubmitShowing(true);
                }
            }else {
                if(isSubmitShowing){
                    setIsSubmitShowing(false);
                }
            }

        }

        window.addEventListener('scroll', scrollListener);
        return ()=>{
            window.removeEventListener('scroll', scrollListener);
        }
    },[isSubmitShowing]);
    
    return (
        <>
            <div className={"LandingPage"}>
                {!isSubmitShowing
                    ?<Button href={"#Submit"} color={"primary"} className={"FloatingHash"} variant={"contained"}>
                        <div>
                            {"신청 바로가기"}
                            <div style={{position: "relative", width: '100%', height: '0.5rem'}}>
                                <ExpandMore style={{position: 'absolute', left: 'calc(50% - 10px)', top: -5, width: 20}}/>
                                <ExpandMore style={{position: 'absolute', left: 'calc(50% - 10px)', top: 0, width: 20}}/>
                            </div>
                        </div>
                    </Button>
                    :<></>
                }
                <MenuBar />
                <MotherBoard />
                <WebtoonScore />
                <PostScript />
                <Member />
                <Submit />
                <QnA />
                <Copyright />
                
            </div>
        </>
    );

}

export default Landing;


