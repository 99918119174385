import { IconButton, MenuItem, Select, } from '@material-ui/core';
import React from 'react';
import EditableTable from '../../Component/Table/EditableTable';

import {listReviews, updateReview} from '../../Data/Review';
import {readEpisodes} from '../../Data/Episode';
import {listMyOwnTitles} from '../../Data/Ownership';

import {fn, unique} from '../../Functions';
import { ArrowRight, Cancel, Link, WarningRounded, } from '@material-ui/icons';

import './ReviewList.scss';
import LoadingCircle from '../../Component/LoadingCircle';
import { getTitles } from '../../Data/Title';

import { useDispatch } from 'react-redux';

const ReviewAdmin = ({serviceId, titleId, episodeId}) => {

    const [isLoading, setIsLoading] = React.useState(false);

    const [titles, setTitles] = React.useState([]);

    const dispatch = useDispatch();

    React.useEffect(()=>{
        document.title = `댓글 관리`;
        
        (async()=>{
            let titleKeys = await listMyOwnTitles();
            let newTitles = await getTitles(titleKeys.map(title => [title.serviceId, title.titleId]), false, dispatch);
            setTitles(newTitles);
        })();

    },[dispatch])

    const [episodes, setEpisodes] = React.useState({});
    React.useEffect(()=>{
        if (!titleId){
            setEpisodes([]);
            return;
        }
        (async()=>{
            let newEpisodes = await readEpisodes(serviceId, titleId);
            setEpisodes(Object.fromEntries(newEpisodes.map(episode => [episode.episodeId, episode])));
        })();
    },[serviceId, titleId])

    const [reviews, setReviews] = React.useState([]);
    React.useEffect(()=>{
        if (!serviceId){
            setReviews([]);
            return;
        }
        (async()=>{
            setIsLoading(true);

            let newReivews = await listReviews({serviceId, titleId, episodeId}, true);        
            setReviews(newReivews);
            setIsLoading(false);
        })();

    },[serviceId, titleId, episodeId])

    const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
    const [, setInnerWidth] = React.useState(window.innerWidth);
    React.useEffect(()=>{
        const resizeListener = () => {
            setInnerHeight(window.innerHeight)
            setInnerWidth(window.innerWidth);
        }
        window.addEventListener('resize', resizeListener);

        return ()=>{
            window.removeEventListener('resize', resizeListener);
        }
    },[])

    return (<div className={"ReviewList"}>
        <div className={"ControlBox"}>
            <Select className={"Select"} value={serviceId || ''} onChange={(event)=>{
                fn.goto(`/home/comment/${event.target.value}`)
            }}>
                {titles.map(title => title.serviceId).filter(unique).map(serviceId => <MenuItem key={serviceId} value={serviceId}>{serviceId}</MenuItem>)}
            </Select>
            <ArrowRight className={"Arrow"}/>
            <Select className={"Select"} value={titleId || ''} onChange={(event)=>{
                fn.goto(`/home/comment/${serviceId}/${event.target.value}`)
            }}>
                <MenuItem key={""} value={""}><Cancel/></MenuItem>
                {titles.filter(title => title.serviceId === serviceId).map(title => <MenuItem key={title.titleId} value={title.titleId}>{title.title}</MenuItem>)}
            </Select>
            <ArrowRight className={"Arrow"}/>
            <Select className={"Select"} value={episodeId || ''} onChange={(event)=>{
                fn.goto(`/home/comment/${serviceId}/${titleId}/${event.target.value}`)
            }}>
                <MenuItem key={""} value={""}><Cancel/></MenuItem>
                {Object.values(episodes).map(episode => <MenuItem key={episode.episodeId} value={episode.episodeId}>{episode.title}</MenuItem>)}
            </Select>
        </div>
        <div className={"ReviewListContainer"}>
            <EditableTable
                style={{maxHeight: innerHeight - 130, overflow: 'auto'}}
                headerButtonHeaders={[
                ]}
                columnHeaders={['썸네일', '작품', '댓글', '★', '공개상태', '작성일', '작품으로']}
                defaultSortCol={[5, 'desc']}
                headerButtonsFunction={(row, index)=>[]}
                columnsFunction={(row, index)=>[
                    {isEditing: false, type: 'file', field: 'thumbnail', options:[], style: {width: 30, height:30, margin:5, borderRadius:30}, defaultValue: row.userImage},
                    {isEditing: false, type: 'dummy', field: 'orgTitle', options:[], style: {whiteSpace: 'nowrap', wordBreak: 'none'}, defaultValue: <div>
                        <div style={{fontWeight:"bold"}}>{`${row.orgTitle.title || "(제목 없음)"}${row.episodeId?`- ${(episodes[row.episodeId] || {}).title || row.episodeId + "화"}`:''}`}</div>
                        <div style={{fontSize:'0.8rem', color: 'rgb(40,40,40)'}}>{`${row.orgTitle.serviceId}/${row.orgTitle.titleId}`}</div>
                    </div>},
                    {isEditing: false, type: 'string', field: 'title', options:[], style: {textAlign: 'center'}, defaultValue: row.title},
                    {isEditing: false, type: 'string', field: 'rating', options:[], style: {textAlign: 'center'}, defaultValue: row.rating * 10},
                    {isEditing: true, type: 'select', field: 'exposure', options:[{key:"승인대기", value:'suspended'}, {key: "공개", value: 'public'}, {key:"숨김", value:'hidden'}], defaultValue: row.exposure},
                    {isEditing: false, type: 'date', field: 'createdAt', style:{whiteSpace:'nowrap'}, defaultValue: row.createdAt},
                    {isEditing: false, type: 'dummy', field: 'gotoEpisode', style:{textAlign: 'center'}, defaultValue: <IconButton href={`/view/${row.serviceId}/${row.titleId}/${row.episodeId}`} onClick={fn.gotoByAnchor}><Link/></IconButton>},
                ]}
                updateGenerator={(row, index) => async (newValue) => {
                    updateReview(
                        {...row, ...newValue}
                    )
                    for(const [key, value] of Object.entries(newValue)){
                        row[key] = value;
                    }
                }}
                data={reviews.map(review => ({...review, orgTitle: (titles.filter(title => title.serviceId === review.serviceId && title.titleId === review.titleId)).concat({})[0] }))}
                name={"reviews"}

            />
        </div>
        {!isLoading && reviews.length === 0
        ?<div className={"ReviewListTail"}>
            <div className={"NoReviewWarning"}>
                <WarningRounded/>
                {"아직 댓글이 없습니다."}
            </div>
        </div>:<></>}
        <LoadingCircle show={isLoading}/>
    </div>);
}

export default ReviewAdmin;