import { combineReducers } from "redux";
import {
    titlesReducer as titles,
    SearchReducer as search
} from './Title'

const reducer = combineReducers({
    titles,
    search
});

export default reducer;