import axios from 'axios';
import ss from './Session';

/**
 * @typedef {{
 *   postId: number,
 *   type: string,
 *   exposure: string,
 *   significantFrom: number,
 *   significantTo: number,
 *   title: string,
 *   createdAt: number,
 *   updatedAt: number,
 *   body: string,
 * }} PostType
 * 
 */

/**
 * 
 * @param {string} postId 
 * @returns  {Promise<PostType[]>}
 */
export async function readPost (postId = null) {
    try{
        let res = await axios.post('https://api.webtoon.today/post', {postId}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        if (res.data && res.data.code === 200){

            return res.data.data;
        } else {
            return [];
        }

    } catch(e) {
        return [];
    }
}

/**
 * 
 * @param {string} postId 
 * @returns  {Promise<PostType[]>}
 */
export async function createPost (post) {
    try{
        let res = await axios.put('https://api.webtoon.today/post', post, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        if (res.data && res.data.code === 200){

            return res.data.key;
        } else {
            return ;
        }

    } catch(e) {
        return ;
    }
}


/**
 * 
 * @param {string} postId 
 * @returns {Promise<string>}
 */
export async function updatePost (post) {
    try{
        let res = await axios.patch('https://api.webtoon.today/post', post, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        if (res.data && res.data.code === 200){

            return res.data.key;
        } else {
            return ;
        }

    } catch(e) {
        return ;
    }
}

/**
 * 
 * @param {string} postId 
 * @returns {Promise<string>}
 */
export async function deletePost (postId) {
    try{
        let res = await axios.delete(`https://api.webtoon.today/post?postId=${postId}`,{
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        if (res.data && res.data.code === 200){

            return res.data.key;
        } else {
            return ;
        }

    } catch(e) {
        return ;
    }
}