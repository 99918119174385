import { Button, TextField } from '@material-ui/core';
import moment from 'moment-timezone';
import React from 'react';

import { putNDA } from '../../../Data/NDA';

import './NDA.scss';

let data = (props) => ({
    title: "기밀 유지 약정서",
    prefix: "",
    body: `사용자는 주식회사 오늘의웹툰(이하 "회사")가 'webtoon.today'를 통해 제공하는 서비스, 정보에 대해 다음과 같이 약속합니다.`,
    nested: [
        {
            title: "(정보의 취득과 취급)",
            body: "회사의 정보 제공을 위하여 사용자는 아래의 조건을 이행할 것을 약속한다.",
            prefix: "가.",
            nested: [
                "사용자는 회사의 사전서면동의 없이 취득한 정보를 공표 또는 누설하지 않는다.",
                "회사로부터 제공받은 모든 정보는 본래의 목적 이외에 사용될 수 없으며 사전의 승낙 없이 제3자에 누출하지 않는다.",
                "사용자는 본 건에 직접 관여하고 평가 및 협상 등을 위해 꼭 필요한 회사 임직원, 외부 자문기관 외에는 제공된 정보를 유출하지 않는다.",
                "또, 사용자는 위에 해당되는 임직원, 기관에게 본 기밀유지약정서에 대한 사항을 인지하도록 하며 본 약정사항을 준수토록 할 책임이 있다.",
                "사용자는 제 2조에서 명시한 목적 및 세부 과제 달성 이외에는 어떠한 형태로도 자료를 기록, 복사, 저장할 수없다.",
                "만약 본래의 목적이 성공적으로 종결되지 않을 경우에도 제공된 자료는 지속적으로 기밀이 유지되어야 하며 복사 혹은 이와 유사한 행위로 정보를 보관하지 않은 상태에서 회사에 반환하여야 한다.",
            ]
        },
        {
            title: "(기밀 유지의 면책)",
            body: "위의 2, 3, 4, 5 호는 아래의 경우에는 적용되지 않는다.",
            prefix: "나.",
            nested: [
                "제공된 정보가 이미 일반에 공개된 정보 혹은 수임자가 이미 보유하고 있는 정보일 경우",
                "해당 정보가 제공 후 수임자의 공개에 의하지 아니하고도 제3자 등이 쉽게 입수, 취득할 수 있게 된 경우",
                "법적으로 제공, 공개해야만 하는 경우",
                "관련법, 규정에 의해 증권거래소 및 관련기관에 자료, 정보를 공개할 경우.",
                "다만, 3, 4 의 경우라도 수임자는 이러한 법적, 규정적 자료공개요구 사실을 회사에게 즉시 통보하여야 하며, 공개의 방법 내지는 그 타당성에 대한 이의신청 등을 위해 회사와 사전에 협의하여야 한다.",
            ]
        }
    ]
})

const DrawData = (data, prefix, depth) => {

    let nested = data.nested?data.nested:[];
    nested = nested.map( (row, i) => (typeof row === 'string')?{title: row, prefix: `${i+1}.`}:row)

    return (
        <div key={`${depth} ${JSON.stringify(data.title)}`} style={{paddingLeft: `${depth * (2 / depth)}%`, fontSize: 120*Math.pow(0.9, depth)+'%' }}>
            <p>
                {data.prefix?`${data.prefix}`:''} {data.title || ""}
            </p>
            {data.body || ""}
            {nested.map(row => DrawData(row, prefix?`${prefix}.${data.prefix}`:data.prefix, depth+1))}
        </div>
    );
}


const NDA = ({setNdaConfirmed}) => {

    const [name, setName] = React.useState("");
    const [signedAt, ] = React.useState(moment().format('YYYY. MM. DD.'));

    let props = {

    };

    React.useEffect(()=>{
        if (document.getElementById('realname')){
            document.getElementById('realname').focus();
        }
    },[])

    return (<div className='NDA' >
        {DrawData(data(props), "", 1)}
        <div className={"ButtonBox"}>
            <span>{`위의 내용을 숙지하였으며, 동의의 의미로 다음과 같이 서명합니다. ${signedAt}`}</span>
            <TextField
                id={'realname'}
                className={"Name"} fullWidth
                variant={'outlined'}
                label={"성함"}
                inputProps={{style: {padding: 10, backgroundColor: 'white', textAlign: 'center'}}}
                value={name} onChange={event => setName(event.target.value)}
            />
            <Button
                disabled={name.length < 2} variant='outlined'
                fullWidth className={"Button"}
                onClick={()=>{
                    (async()=>{
                        
                        await putNDA({name, signedAt });
                        
                        setNdaConfirmed(signedAt);

                    })();
                }}
            >{"서명"}</Button>
        </div>
    </div>);
}

export default NDA;