import React, {useRef} from 'react';
import { motion } from "framer-motion";
import { appear, appearUp, appearRight } from "./../SettingValues";
import { Box, Container } from "@material-ui/core";

import "./MotherBoard.scss";

import {useOnScreen} from '../../../Hooks';
import {logBehavior} from '../../../Data/Behavior';
let didSeen = false;

const BoardImg2 = 'https://static.webtoon.today/application/BoardImg2.png';
const WebtoonAnalyticsLogo = "https://static.webtoon.today/application/AnalyticsLogo.png";

const MotherBoard = () => {

    const ref = useRef();
    const onScreen = useOnScreen(ref);
    if (!didSeen && onScreen){
        didSeen = onScreen;
        logBehavior('scroll-motherboard', {});
    }

    return (
        <>
            <motion.div
                ref={ref}
                initial="initial"
                animate="animate"
                className={"MotherBoardSubRoot"}
                id={"Home"}
            >
                <motion.div
                    variants={appear}
                    className={"BoardOverlay"}
                />

                <motion.div
                    data-sizes="auto"
                    variants={appearUp}
                    className={"BoardImgMotionContainer"}
                >
                    <div className={"BoardImgContainer"}>
                        <img 
                            alt="product"
                            className={"BoardImgLeft"}
                            src={BoardImg2}
                        />
                        <img 
                            alt="product"
                            className={"BoardImgRight"}
                            src={"https://static.webtoon.today/ddah/210622-reprot%20example.png"}
                        />
                    </div>
    
                </motion.div>

                <Container maxWidth="lg">
                    <div className={"Content"}>
                        <motion.div variants={appearRight}>
                            <div
                                className={"HeaderContent"}
                            >
                                <div className={"TitleLine"}>
                                    <div>{"독자의 마음을 분석하는"}</div>
                                    <div>{"새로운 서비스"}</div>
                                </div>
                                <div className={"ImageLine"}>
                                    <img src={WebtoonAnalyticsLogo} alt={"WebtoonAnalytics"} className={"Logo"} />
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </Container>
            </motion.div>
            <Box sx={{ height: { md: "100vh" } }} />
        </>
    );
}

export default MotherBoard;