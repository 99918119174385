import { FormControl, IconButton, MenuItem, Select, TextField } from '@material-ui/core';

import React from 'react';
import EditableTable from '../../../Component/Table/EditableTable';

import {updateTitle, readTitlesByQuery, getTitles} from './Data/Title';

import {fn, createFuzzyMatcher} from '../../../Functions';
import { EditRounded, WarningRounded, Search as SearchIcon, MobileScreenShare } from '@material-ui/icons';

import './TitleList.scss';
import LoadingCircle from '../../../Component/LoadingCircle';

const TitleAdmin = ({serviceId}) => {

    const [titles, setTitles] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const refreshTitles = async (serviceId) => {
        setIsLoading(true);
        let newtitleKeys = await readTitlesByQuery('title', '', 4000, true);
        let newtitles = await getTitles(newtitleKeys)
        setTitles(newtitles);
        setIsLoading(false);
    }
    React.useEffect(()=>{
        document.title = `작품 목록`;
        refreshTitles(serviceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serviceId]);

    const [keyword, setKeyword] = React.useState("");
    const [editingKeyword, setEditingKeyword] = React.useState("");

    let fuzzyMatcher = createFuzzyMatcher(keyword);
    
    const [maximumTitles, setMaximumTitles] = React.useState(20);

    return (<div className={"TitleList"}>
        <div>
            <div className={"SearchArea"}>
                <div className={"SearchBar"}>
                    <div className={"SearchIcon"}>
                        <SearchIcon/>
                    </div>
                    <TextField className={"SearchField"} placeholder={"Search..."} value={editingKeyword}
                        onKeyUp={(event) => event.key === 'Enter' ? event.target.blur() : null }
                        onChange={(event) => setEditingKeyword(event.target.value)}
                        onBlur={(event) => setKeyword(event.target.value) } />
                </div>
                <div className={"SelectBox"} style={{padding: 7}}>
                    <FormControl>
                        <Select 
                            value={maximumTitles} 
                            onChange={(event) => setMaximumTitles(event.target.value)}
                        >
                            <MenuItem value={20}>{"최대 20개"}</MenuItem>
                            <MenuItem value={40}>{"최대 40개"}</MenuItem>
                            <MenuItem value={60}>{"최대 60개"}</MenuItem>
                            <MenuItem value={`${Number(titles.length)}`}>{"전체보기"}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
        <div className={"TitleListContainer"}>
            <EditableTable
                style={{maxHeight: `calc(100% - 130px)`, overflow: 'auto'}}
                headerButtonHeaders={[
                ]}
                columnHeaders={['썸네일', '공개 범위', '작가', '제목', '태그', '# Ep.', '작품 정보 보기', '생성일', '수정일']}
                defaultSortCol={[8, 'desc']}
                headerButtonsFunction={(row, index)=>[]}
                columnsFunction={(row, index)=> index>maximumTitles?[]:[
                    {isEditing: false, type: 'file', field: 'thumbnail', options:[], style: {width: 60, height:32, margin: 5}, defaultValue: row.thumbnail},
                    {isEditing: false, type: 'select', field: 'exposure', style:{whiteSpace: 'nowrap', fontSize: '0.8rem'}, defaultValue: row.exposure, options: [
                        {value: 'private', key: '나만 보기🚫'}, {value:'unlist', key: '목록 제외👻'}, {value: 'public', key: '전체 공개✅'},
                    ]},
                    {isEditing: false, type: 'string', field: 'author', options:[], style:{whiteSpace: 'nowrap', fontSize: '0.8rem'}, defaultValue: `${row.author} (${row.serviceId})`},
                    {isEditing: false, type: 'string', field: 'title', style:{whiteSpace: 'nowrap', fontSize: '0.8rem'}, defaultValue: row.title},
                    {isEditing: false, type: 'string', field: 'tags', style:{whiteSpace: 'nowrap', fontSize: '0.8rem', textOverflow: 'ellipsis', maxWidth: 200, overflow: 'hidden'}, defaultValue: [row.tags, row.keywords].filter(words => words).join(",")},
                    {isEditing: false, type: 'string', field: 'episodeCount', style:{whiteSpace: 'nowrap', fontSize: '0.8rem'}, defaultValue: row.episodeCount},
                    {isEditing: false, type: 'dummy', field: 'gotoEpisodeEdit', defaultValue: <div style={{display:'flex', justifyContent: 'center'}}>
                        <IconButton href={`/challenge/contents/${row.serviceId}/${row.titleId}`} onClick={fn.gotoByAnchor}><EditRounded/></IconButton>
                        <IconButton href={`https://challenge.webtoon.today/contents/${row.serviceId}/${row.titleId}`} onClick={fn.gotoByAnchor}><MobileScreenShare/></IconButton>
                    </div>},
                    {isEditing: false, type: 'date', field: 'createdAt', style:{whiteSpace: 'nowrap', fontSize: '0.8rem', textAlign: 'center'}, defaultValue: row.createdAt},
                    {isEditing: false, type: 'date', field: 'updatedAt', style:{whiteSpace: 'nowrap', fontSize: '0.8rem', textAlign: 'center'}, defaultValue: Math.max(row.lastEpisodeUpdatedAt, row.createdAt)},
                ]}
                updateGenerator={(row, index) => async (newValue) => {
                    updateTitle({...row, ...newValue})
                    for(const [key, value] of Object.entries(newValue)){
                        row[key] = value;
                    }
                }}
                isDownloadable={true}
                data={ titles.filter(row => keyword?(fuzzyMatcher.test(row.title) || fuzzyMatcher.test(row.author)):true) }
                name={"titles"}

            />
        </div>
        <div className={"TitleListTail"}>
            {!isLoading && titles.length === 0
            ?<div className={"NoTitleWarning"}>
                <WarningRounded/>
                {"아직 생성된 작품이 없습니다."}
            </div>:<></>}
        </div>
        <LoadingCircle show={isLoading}/>
    </div>);
}

export default TitleAdmin;