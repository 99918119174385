import React from 'react';
import {Avatar, IconButton} from '@material-ui/core';

import {NaverLogo, DaumLogo, KakaoLogo, ToomicsLogo, ToptoonLogo, LezhinLogo, ComicoLogo} from '../Logos';
import { MergeAll, thumbnailize } from '../Functions';


/**
 * 
 * @param {{
 *   serviceId: string,
 *   style: React.CSSProperties,
 *   goto: (url:string)=>void
 * }} param 
 */
const ServiceAvatar = ({serviceId, style = {}, goto}) => (
    <IconButton
        style={MergeAll({width: 40, height: 40},style)}
        onClick={()=>(goto || console.log)(`/detail/${serviceId}`)}
    >
        <Avatar
            alt={serviceId}
            style={{position:'absolute', left:0, top: 0, width: style.width||40, height: style.height||40}}
            src={
                serviceId === 'naver'?thumbnailize(NaverLogo,style.wdith || 40):
                serviceId === 'daum' ?thumbnailize(DaumLogo,style.wdith || 40):
                serviceId === 'kakao'?thumbnailize(KakaoLogo,style.wdith || 40):
                serviceId === 'lezhin'?thumbnailize(LezhinLogo,style.wdith || 40):
                serviceId === 'comico'?thumbnailize(ComicoLogo,style.wdith || 40):
                serviceId === 'toomics'?thumbnailize(ToomicsLogo,style.wdith || 40):
                serviceId === 'toptoon'?thumbnailize(ToptoonLogo,style.wdith || 40):null
            }
        />
    </IconButton>
);

export default ServiceAvatar;