import React from 'react';
import Input from '@material-ui/core/Input';
import { Button, IconButton } from '@material-ui/core';
import {createImageWithS3} from '../../Data/Image';
import { Cancel, Delete } from '@material-ui/icons';

import {randomString} from '../../Functions';

/**
 * @description Returns extension of filename to lowercase if filename has an extenstion, else filename to lowercase.
 * @param {string} filename 
 * @returns {string} extention name without `.`(dot)
 */
const getExtensionOf = (filename) => filename.substring(filename.lastIndexOf(".")+1).toLowerCase();
/**
 * @description Returns true extension of targetValue is included in validExtensionList, else false.
 * @param {string[]} validExtensionList for example `['jpg', 'jpeg', 'png', 'svg', 'gif']`
 * @param {string} targetValue for example `test.file.jpg`
 * @returns {boolean} 
 */
const isValidExtension = (validExtensionList, targetValue) => validExtensionList.includes(getExtensionOf(targetValue));

/**
 * @param {{
 *      uniqueId: string,
 *      value: any,
 *      previewShow: boolean,
 *      setPreviewShow: React.Dispatch<React.SetStateAction<boolean>>,
 * }} param0 
 * @returns 
 */
const PreviewImg = ({uniqueId, previewShow, setPreviewShow, value}) => {
    return (
        <div
            id={`PreviewImg_${uniqueId}`}
            tabIndex={0}
            style={{
                position:'fixed', top: 0, left: 0,
                width: '100%', height: '100%',
                backgroundColor: 'rgba(0,0,0,0.5)',
                opacity: previewShow?1:0, zIndex: previewShow?2:-2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            onClick={()=>{setPreviewShow(false)}}
            onKeyDown={(event) => {
                if(event.key === 'Escape'){
                    setPreviewShow(false);
                }
            }}
        >
            <img
                style={{maxWidth:'80%', maxHeight: '80%'}}
                src={value}
                alt={"none"}
                onClick={(event)=>event.stopPropagation()}
            />
            <IconButton
                style={{position: 'absolute', right: 10, top: 50, color: 'white'}}
                onClick={()=>{setPreviewShow(false)}}
            >
                <Cancel/>
            </IconButton>
        </div>
    );
}

/**
 * 
 * @param {{
 *  isEditing: true|false,
 *  defaultValue: any,
 *  expandPreview: boolean,
 *  field: string,
 *  update: () => void,
 *  onLoad: (dom: DOMElement) => void,
 *  style: React.StyleHTMLAttributes,
 *  buttonStyle: React.StyleHTMLAttributes,
 *  accept: string,
 * }} props 
 */
const EditableFileField = ({isEditing: givenIsEditing, defaultValue, expandPreview=false, field, update, onLoad, className, style, buttonStyle, accept}) => {

    const [uniqueId,] = React.useState(`ImageHolder_${randomString(20)}`);

    const [previewShow, setPreviewShow] = React.useState(false);

    const [isEditing, setIsEditing] = React.useState(givenIsEditing || "");
    React.useEffect(()=>{
        setIsEditing(givenIsEditing);
    },[givenIsEditing]);
    
    const [value, setValue] = React.useState(defaultValue);
    React.useEffect(()=>{
        setValue(defaultValue);
    },[defaultValue]);

    const onImagePutIn = React.useMemo(()=> async (event)=> {
        try{
            event.target.setAttribute('disabled', true);
            let filename = await createImageWithS3(event.target, (event)=>{
                //setProgress(event.loaded / event.)
            });
            let newValue = {};
            newValue[field] = filename;
            setValue(filename);
            await update(newValue)
        }catch(e){
            document.getElementById(uniqueId).removeAttribute('disabled');
            console.error(e)
        }
    }, [field, uniqueId, update]);

    if (isEditing){
        if (!value){
            return (
                <div>
                    <Button variant={"outlined"} className={"AddNewSlide SlideThumb"} style={buttonStyle} onClick={()=>{
                        document.getElementById(uniqueId).click();
                    }}>
                        +
                    </Button>
                    <Input
                        id={uniqueId}
                        className={className}
                        type={"file"}
                        style={{width:1,height:1, display:'none'}}
                        onChange={onImagePutIn}
                        name={"file"}
                        inputProps={{
                            accept: accept || ".gif,.jpg,.jpeg,.png,.svg"
                        }}
                    >
                    </Input>
                    <PreviewImg uniqueId={uniqueId} previewShow={previewShow} setPreviewShow={setPreviewShow} value={value}/>
                </div>
            );                  
        }
        if (isValidExtension(['jpg', 'jpeg', 'png', 'svg', 'gif'], value)){
            return (
                <div>
                    <div className={className} style={{display: 'block', position: 'relative', overflow: 'hidden', ...style}}>
                        <img
                            style={{
                                width: '100%', height: '100%',
                                objectFit: 'contain',
                                ...(expandPreview?{cursor: 'pointer'}:{})
                            }}
                            target={"blank"}
                            src={value} alt={value}
                            {...expandPreview?{onClick:()=>{setPreviewShow(true)}}:{}}
                            onLoad={onLoad}
                        />
                        <IconButton
                            style={{position: 'absolute', top:-5, right:-5, width: 35, height: 35, border:'1px lightgray solid', backgroundColor: 'rgba(255,255,255,0.5)'}}
                            onClick={async ()=>{
                                let newValue = {};
                                newValue[field] = null;
                                setValue(null);
                                await update(newValue)
                            }}
                        >
                            <Delete style={{fontSize: '1rem'}}/>
                        </IconButton>
                    </div>
                    <PreviewImg uniqueId={uniqueId} previewShow={previewShow} setPreviewShow={setPreviewShow} value={value}/>
                </div>
            );
        }
        if (isValidExtension(['zip'], value)){
            return (
                <div>
                    <div className={className} style={{display: 'block', position: 'relative', overflow: 'hidden', ...style}}>
                        <img style={{width: '100%', height: '100%', objectFit: 'contain'}} target={"blank"} src={'https://static.webtoon.today/loading.gif'} alt={'https://static.webtoon.today/loading.gif'} onLoad={onLoad}/>
                        <IconButton
                            style={{position: 'absolute', top:-5, right:-5, width: 35, height: 35, border:'1px lightgray solid', backgroundColor: 'rgba(255,255,255,0.5)'}}
                            onClick={async ()=>{
                                let newValue = {};
                                newValue[field] = null;
                                setValue(null);
                                await update(newValue)
                            }}
                        >
                            <Delete style={{fontSize: '1rem'}}/>
                        </IconButton>
                    </div>
                    <PreviewImg uniqueId={uniqueId} previewShow={previewShow} setPreviewShow={setPreviewShow} value={value}/>
                </div>
            );     
        }
        // if(value && field && update){
            return (
                <div>
                    <div className={className} style={{display: 'block', position: 'relative', overflow: 'hidden', ...style}}>
                        <div className={"SlideThumb"}>{`.${value}`.split('.').reverse()[0].toLowerCase()}</div>
                        <IconButton
                            style={{position: 'absolute', top:-5, right:-5, width: 35, height: 35, border:'1px lightgray solid', backgroundColor: 'rgba(255,255,255,0.5)'}}
                            onClick={async ()=>{
                                let newValue = {};
                                newValue[field] = null;
                                setValue(null);
                                await update(newValue)
                            }}
                        >
                            <Delete style={{fontSize: '1rem'}}/>
                        </IconButton>
                    </div>
                    <PreviewImg uniqueId={uniqueId} previewShow={previewShow} setPreviewShow={setPreviewShow} value={value}/>
                </div>
            ); 
        // }
    }

    if (!value || isValidExtension(['jpg', 'jpeg', 'png', 'svg', 'gif'], value)){
        return (
            <div>
                <div className={className} style={{position: 'relative', overflow: 'hidden', backgroundImage: `url('${value || "https://static.webtoon.today/noimage.jpg"}')`, backgroundSize: 'cover', backgroundPosition: 'center', ...style}}>
                    <img
                        style={{
                            width: '100%', height: '100%',
                            objectFit: 'contain', backdropFilter: 'blur(10px) brightness(0.8)',
                            ...(expandPreview?{cursor: 'pointer'}:{})
                        }}
                        target={"blank"}
                        src={value || "https://static.webtoon.today/noimage.jpg"} alt={value}
                        {...expandPreview?{onClick:()=>{
                            setPreviewShow(true);
                            document.querySelector(`#PreviewImg_${uniqueId}`).focus()
                        }}:{}}
                        onLoad={onLoad} onError={()=>{
                            if (this) {
                                this.src="https://static.webtoon.today/noimage.jpg"
                            }
                        }}
                    />
                </div>
                <PreviewImg uniqueId={uniqueId} previewShow={previewShow} setPreviewShow={setPreviewShow} value={value} />
            </div>
        );
    }

    if (isValidExtension(['zip'], value)){
        return (
            <div>
                <img style={{width: '100%', height: '100%', objectFit: 'contain'}} target={"blank"} src={'https://static.webtoon.today/loading.gif'} alt={'https://static.webtoon.today/loading.gif'} onLoad={onLoad}/>
                <PreviewImg uniqueId={uniqueId} previewShow={previewShow} setPreviewShow={setPreviewShow} value={value} />
            </div>
        );
    }

    return (
        <div>
            <div className={"SlideThumb"}>{`.${getExtensionOf(value)}`}</div>
            <PreviewImg uniqueId={uniqueId} previewShow={previewShow} setPreviewShow={setPreviewShow} value={value} />
        </div>
    );
}

export default EditableFileField;