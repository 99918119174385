import React, { useEffect, useRef, useState } from 'react';
import './Intro.scss';

const serviceTitleData = [
    {
        title:'웹툰 제작사',
        className: 'Studio',
    },
    {
        title:'자유연재 플랫폼',
        className: 'Serial',
    },
    {
        title:'데이터 분석 서비스', 
        className: 'DataAnalysis',
    },
    {
        title:'마케팅 서비스', 
        className: 'AdManagement',
    },
    {
        title:'연재 관리도구', 
        className: 'MarketingDashBoard',
    },
];

const carouselClassNameOf = {
    0: "FadeOut",
    1: "Prev",
    2: "Active",
    3: "Next",
}

const circularShiftLeftArray = (targetArray) => {
    let newArr = [...targetArray];
    newArr.push(newArr.shift());

    return newArr;
}

const DELAY = 2000;

const Intro = () =>{
    const [circularServiceTitleObjectList, setCircularServiceTitleObjectList] = useState(serviceTitleData);
    const activedClassName = circularServiceTitleObjectList[2].className;

    const introSectionRef = useRef(null);
    const interval = useRef();
    
    useEffect(()=>{
        if (!introSectionRef || !introSectionRef.current){
            return;
        }

        const intersectionObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) {
                        clearInterval(interval.current);
                        return;
                    }

                    setCircularServiceTitleObjectList((prev) => circularShiftLeftArray(prev));
                    
                    interval.current = setInterval(() => {
                        setCircularServiceTitleObjectList((prev) => circularShiftLeftArray(prev));
                    }, DELAY);
                });
              }
        );
        intersectionObserver.observe(introSectionRef.current);
        
        return () => {
            intersectionObserver.disconnect();
            clearInterval(interval.current);
        };
    },[introSectionRef]);

    return (
        <section ref={introSectionRef}>
            <div className={'IntroSection'}>
                <div className={'IntroTextWrapper'}>
                    <span className={'MainText'}>{'오늘의웹툰은'}</span>
                    <ul className={`ServiceCarousel ${activedClassName}`}>
                        {circularServiceTitleObjectList
                            .map((serviceTitleItem, index) => (
                                <li key={serviceTitleItem.title} 
                                    className={[
                                        'ServiceNameItem',
                                        serviceTitleItem.className,
                                        carouselClassNameOf[index] || 'None'
                                    ]
                                    .join(" ")
                                    .trim()}
                                >
                                    <span className={'ServiceName'}>{serviceTitleItem.title}</span>
                                </li>
                            )
                        )}
                    </ul>
                    <span className={'MainText'}>{'입니다.'}</span>
                </div>
            </div>
        </section>
    );
}

export default Intro;