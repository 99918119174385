import { Paper } from '@material-ui/core';
import React from 'react';
import './AdsSlides.scss';

import { MoreVert,
    ChevronRight,
    ChevronLeft,
    FavoriteBorder,
    ChatBubbleOutline,
    Send,
    BookmarkBorder } from '@material-ui/icons';

const AdsSlides = ( {title, carouselOnly=false} ) => {
    
    const [adSlide, setAdSlide] = React.useState(0);

    const slideWidth = 300

    const nextMove = () => {
        setAdSlide((adSlide + 1) % title.slides.length);
    }

    const prevMove = () => {
        setAdSlide((adSlide + (title.slides.length - 1)) % (title.slides.length));
    }

    const PageHandler = (idx) => {
        setAdSlide( idx );
    }
    const Carousel = {
        transform: `translate(-${adSlide * slideWidth}px, 0px)` 
    }
    const [isReadMore, setIsReadMore] = React.useState(false);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore)
    }
    
    return(

        <Paper className={"AdsContainer"} elevation={0}>
            <Paper variant={"outlined"} className={"AdsPaper"}>
                <div className={"Header"} style={carouselOnly?{display:'none'}:{}}>
                    <img src={"https://static.webtoon.today/ddah/instagram-banner.png"} alt={"instagram banner"}/>
                </div>
                <div className={"CompanyArea"} style={carouselOnly?{display:'none'}:{}}>
                    <img className={"CompanyImg"} src={"https://static.webtoon.today/ddah/logo-04.png"} alt={"Logo"} />
                    <div className={"Company"}>
                        <div className={"Brand"}>{"오늘의 웹툰"}</div>
                        <div className={"Sponsored"} >{"Sponsored"}</div>
                    </div>
                    <MoreVert className={"CollapseIcon"} />
                </div>
                <div className={"SliderContainer"}>
                    <ChevronLeft className={"ArrowPrev"} onClick={prevMove} />
                    <ChevronRight className={"ArrowNext"} onClick={nextMove} />
                    <div className={"Slider"} style={Carousel} >
                        {title.slides && title.slides.length > 0
                            ?title.slides.map( (image,index) =>
                                <img className={"SlideCut"} key={index} src={image} alt="Img" />
                            )
                            :<></>}
                    </div>
                </div>
                <div className={"AccessMore"} style={carouselOnly?{display:'none'}:{}}>
                    <div className={"MoreText"}>
                        <div>
                            {"더 알아보기"}
                        </div>
                    </div>
                    <div className={"MoreIcon"}>
                        <ChevronRight />
                    </div>
                </div>
                <div className={"IconsArea"} style={carouselOnly?{display:'none'}:{}}>
                    <FavoriteBorder className={"Favorite Icon"} />
                    <ChatBubbleOutline className={"Chat Icon"} />   
                    <Send className={"Send Icon"} />
                    <div className={"Pagination"}>
                        {title.slides && title.slides.length > 0
                            ?title.slides.map( (image, idx) =>
                                <button 
                                    key={idx} 
                                    value={idx} 
                                    className={`PaginationButton ${idx === (adSlide)?"Selected":""}`} 
                                    onClick={() => PageHandler(idx)} 
                                />
                            ):<></>}
                    </div>
                    <BookmarkBorder className={"Bookmark Icon"} />
                </div>
                <div className={"TextArea"} style={carouselOnly?{display:'none'}:{}}>
                    <div className={"ViewContent"}>
                        <div>
                            {`${title.serviceId} - ${title.title}`}
                        </div>
                        <div hidden={isReadMore===true}>
                            <span onClick={toggleReadMore} className={"ViewMore"} >{"... more"}</span>
                        </div>
                        <div hidden={isReadMore===false}>
                            {"(링크)"}
                        </div>
                        <div hidden={isReadMore===false}>
                            {"---"}
                        </div>
                        <div hidden={isReadMore===false}>
                            {title.description}
                        </div>
                    </div>
                </div>
            </Paper>
        </Paper>
    );
}

export default AdsSlides;