import React from 'react';
import './SendEmail.scss'
import { Button } from '@material-ui/core';
import { triggerEmail } from '../../../../Data/TriggerEmail';


const CompetitionResult = ({title, author, weekIter, email, analysisResultLocation, sendCallback}) => {

    const body = `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </head>
        <body>
            <div style="display: block; width: 600px; background-color: white; padding: 30px 20px; font-size: 14px; box-sizing: border-box; color: rgb(68,68,68); line-height: 170%; margin: auto; border: 1px solid rgb(236,240,241); border-bottom: 0px">
                <h3 style="font-size: 18px;"><strong>데이터 테스트 분석 결과 안내</strong></h3>
                안녕하세요 ${author} 작가님, 오늘의웹툰입니다.<br/>
                &lt;${title}&gt; 작품의 데이터 분석 결과 보내드립니다. <br/>
                <br/>
                <a href=${analysisResultLocation} target="_blank" rel="noopener" style="text-decoration: none; color: rgb(204, 162, 80);">${analysisResultLocation}</a><br/>
                (2화 이상 분량을 접수하신 경우 보고서 우측 하단에 다음화로 이동 버튼이 있습니다.)<br/>
                <br/>
                <div>읽어보시고 이해가 안 가는 부분이 있다면 <a href="https://static.webtoon.today/ddah/210621-report%20review.pdf" target="_blank" rel="noopener" style="text-decoration: none; color: rgb(204, 162, 80);">데이터 분석 리포트 설명 파일</a>을 참고 부탁드립니다. 그 외 문의 사항은 회신 주시면 빠르게 답변드리겠습니다.  </div>
                <br/>
                ${weekIter} 공모전 당선 여부는 매월 첫 번째 월요일 홈페이지를 통해 발표될 예정이며, 이메일로도 공지될 예정입니다.<br/>
                추가로 데이터 분석 경험에 대한 솔직한 피드백 작성을 부탁드립니다. <br/>
                남겨주신 피드백은 더 좋은 서비스를 개발하는 데 사용하겠습니다 :) <br/>
                <br/>
                ${author} 작가님의 작품을 분석할 수 있도록 기꺼이 원고를 내어주신 점 다시 한번 진심으로 감사드립니다. <br/><br/>
                <a href="https://webtoon.today/survey/competitionSurvey" target="_blank" rel="noopener" style="color: white; background-color: rgb(0,176,240); width: 120px; font-size: 14px; padding: 10px 15px; border: 0px; border-radius: 4px; text-align: center; text-decoration: none;">피드백 남기기 </a>
            </div>
            <div style="background-color: rgb(236,240,241); width: 600px; box-sizing: border-box; font-size: 12px; color: rgb(177,178,181); line-height: 170%; padding: 10px 0 20px 0; text-align: center; margin: auto; border: 1px solid rgb(236,240,241);">
                주식회사 오늘의웹툰 | 웹툰 데이터 분석 · 에이전시 · 스튜디오<br/>
                서울시 강남구 강남대로 78길 33-10, 3층<br/>
                작품문의: artist@webtoon.today<br/>
                기타문의: help@webtoon.today<br/>
                <br/><div>© ${new Date().getFullYear()} All Rights Reserved</div>
            <div>
        </body>
    </html>
    `
    const mailTitle = `[오늘의웹툰] 데이터 분석 결과를 보내드립니다.(공모전 결과 미포함)`

    const [isSending, setIsSending] = React.useState(false);

    return (
        <div style={{display: 'flex', justifyContent: 'center', paddingTop: '20px'}}>
            <div className={'SendEmailInner'}>
                <div className={`EmailInfo`} style={{marginBottom:10, fontSize:'0.8rem', borderBottom: '1px black solid'}}>
                    <span style={{fontWeight: 'bold'}}>{"발송 정보"}</span>
                    <table>
                        <tbody>
                            <tr>
                                <td>{"수신인"}</td><td>{email}</td>
                            </tr>
                            <tr>
                                <td>{"제목"}</td><td>{mailTitle}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={`EmailPreview`}>
                    <div dangerouslySetInnerHTML={{__html: body}} />
                </div>
                <div className={'SendButton'}>
                    <Button 
                        fullWidth
                        disabled={!author || !analysisResultLocation || !weekIter || isSending}
                        variant={'contained'} color={'primary'} 
                        onClick={async()=>{
                            setIsSending(true);
                            // 배포 직전에 groups 를 artist@webtoontoday.com 으로 바꾸기
                            const result = await triggerEmail(email,mailTitle,body,'artist@webtoontoday.com')
                            if (!result) {
                                alert('이메일 전송에 실패했습니다.')
                            }
                            sendCallback(!!result);
                            setIsSending(false);
                        }}
                    >
                        {'이메일 보내기'}
                    </Button>
                </div>
            </div>
        </div>
        
    )
}

export default CompetitionResult ;