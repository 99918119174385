import { Button, IconButton, TextField } from '@material-ui/core';
import { DeleteForeverOutlined } from '@material-ui/icons';
import React from 'react';
import { logBehavior } from '../Data/Behavior';
import { createReview, updateReview, deleteReview } from '../Data/Review';
import { getOffsetInScreen, randomString } from '../Functions';
import UserAvatar from './UserAvatar';

import ss from '../Data/Session';

import './ReviewShort.scss';

const Star = 'https://static.webtoon.today/Star.svg';

let hasTouch = false;

/**
 * @typedef {import('../VirtualRouter').size} size
 * @typedef {import('../Data/Session').sessionType} sessionType
 * @typedef {import('../Data/Title').titleType} titleType
 * @typedef {import('../Data/Review').review} review
 * @typedef {import('../Data/Review').indexInfo} indexInfo
 * 
 * @param {{
 *  session: sessionType,
 *  windowSize: size,
 *  indexInfo: indexInfo,
 *  givenReview: review,
 *  reviewCallback: ()=>void
 * }} props
 * */
const ReviewShort = ({windowSize, indexInfo, givenReview=null, reviewCallback=()=>{}}) => {

    const [session,setSession] = React.useState(ss.getCurrentSession());

    React.useEffect(()=>{
        const sessionUpdate = ()=>{
            setSession(ss.getCurrentSession());
        }
        ss.registerSessionCallback(sessionUpdate)
    },[]);

    const [uniqueId,] = React.useState(randomString(16));

    /** @type {review} */
    const [review, setReview] = React.useState(givenReview || { ...indexInfo, addressid: session.addressid});
    const [isEditable, setIsEditable] = React.useState(session && review && (review.addressid === session.addressid || review.addressid === session.userid));
    React.useEffect(()=>{
        setIsEditable(session && review && (review.addressid === session.addressid || review.addressid === session.userid));
    },[session, review]);
    const [isWaiting, setIsWating] = React.useState(givenReview === null || givenReview.title === null || givenReview.title === undefined || givenReview.title === "");
    React.useEffect(()=>{
        setReview(givenReview || { ...indexInfo, addressid: session.addressid});
        setIsWating(givenReview === null || givenReview.title === null || givenReview.title === undefined || givenReview.title === "");
        if (givenReview && givenReview.rating){
            document.querySelector(`#${uniqueId} .ratingColor`).style.width = `${givenReview.rating*100}%`;
            document.querySelector(`#${uniqueId} .ratingColor`).style['background-color']= `rgba(${214 + (244-214)*givenReview.rating},${192 + (48-192)*givenReview.rating},${129 + (41-129)*givenReview.rating},1)`;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[givenReview, session]);
    
    const [domRect, setDomRect] = React.useState({});
    React.useEffect(()=>{
        setDomRect(getOffsetInScreen(document.querySelector(`#${uniqueId} .RatingContainer`)));
    }, [uniqueId])

    return (<div id={uniqueId} className={`ReviewShort ${review.rating?'haveRating':''} ${isWaiting?'isWaiting':''} ${isEditable?'isEditable':''}`} >
        <div className={'User'}>
            <UserAvatar
                addressid={review.addressid}
                userImage={review.userImage}
                style={{width:40, height:40, margin:10}}
                goto={()=>{}}
            />
        </div>
        <div className={'ReviewBody'} >
            <div className={'Rating'} 
                onMouseEnter={(event)=>{
                    setDomRect(getOffsetInScreen(document.querySelector(`#${uniqueId} .RatingContainer`)));
                }}
                onMouseMove={(event)=>{
                    if (review.rating || !isEditable){
                        return;
                    }
                    let rating = Math.min(Math.max(0,(event.clientX - domRect.left) / domRect.width),1);
                    document.querySelector(`#${uniqueId} .ratingColor`).setAttribute('data-rating', rating);
                    document.querySelector(`#${uniqueId} .ratingColor`).style['background-color']= `rgba(${214 + (244-214)*rating},${192 + (48-192)*rating},${129 + (41-129)*rating},1)`;
                    document.querySelector(`#${uniqueId} .ratingColor`).style.width = `${rating*100}%`;
                }}
                onClick={(event)=>{
                    if (hasTouch || !isEditable){
                        return;
                    }
                    if (review.rating){
                        setReview({ ...review, ...{rating: null}});
                        return;
                    }
                    let rating = Math.min(Math.max(0,Math.round(10 * document.querySelector(`#${uniqueId} .ratingColor`).getAttribute('data-rating')) / 10),1);
                    document.querySelector(`#${uniqueId} .ratingColor`).setAttribute('data-rating', rating);
                    document.querySelector(`#${uniqueId} .ratingColor`).style.width = `${rating*100}%`;
                    logBehavior('review:rating', { ...review, rating });
                    createReview({ ...{title: "(별점 리뷰)"}, ...review, rating});
                    setReview({ ...review, rating });
                    setIsWating(true);
                }}
                onTouchMove={(event)=>{
                    hasTouch = true;
                    if (review.rating || !isEditable){
                        return;
                    }
                    let rating = Math.min(Math.max(0,(event.touches[0].clientX - domRect.left) / domRect.width),1);
                    document.querySelector(`#${uniqueId} .ratingColor`).setAttribute('data-rating', rating);
                    
                    document.querySelector(`#${uniqueId} .ratingColor`).style['background-color']= `rgba(${214 + (244-214)*rating},${192 + (48-192)*rating},${129 + (41-129)*rating},1)`;
                    document.querySelector(`#${uniqueId} .ratingColor`).style.width = `${rating*100}%`;
                }}
                onTouchStart={(event)=>{
                    setDomRect(getOffsetInScreen(document.querySelector(`#${uniqueId} .RatingContainer`))); 
                    hasTouch = true;
                    if (review.rating){
                        return;
                    }
                    let rating = Math.min(Math.max(0,(event.touches[0].clientX - domRect.left) / domRect.width),1);
                    document.querySelector(`#${uniqueId} .ratingColor`).setAttribute('data-rating', rating);
                    
                    document.querySelector(`#${uniqueId} .ratingColor`).style['background-color']= `rgba(${214 + (244-214)*rating},${192 + (48-192)*rating},${129 + (41-129)*rating},1)`;
                    document.querySelector(`#${uniqueId} .ratingColor`).style.width = `${rating*100}%`;
                }}
                onTouchEnd={(event)=>{
                    if (!isEditable){
                        return;
                    }
                    if (review.rating){
                        setReview({ ...review, ...{rating: null}});
                        return;
                    }
                    let rating = Math.min(Math.max(0,Math.round(10 * document.querySelector(`#${uniqueId} .ratingColor`).getAttribute('data-rating')) / 10),1);
                    document.querySelector(`#${uniqueId} .ratingColor`).setAttribute('data-rating', rating);
                    document.querySelector(`#${uniqueId} .ratingColor`).style.width = `${rating*100}%`;
                    logBehavior('review:rating', { ...review, rating });
                    if (!givenReview){
                        createReview({ ...{title: "(별점 리뷰)"}, ...review, rating});
                    }else{
                        updateReview(review);
                    }
                    setReview({ ...review, rating });
                    setIsWating(true);
                }}
            >
                <div className={"RatingContainer"} >
                    <div className={"bg RatingColor1"}></div>
                    <div className={"ratingColor RatingColor2"}></div>
                    <div className={"RatingMask"}>
                        <img src={Star} className={"Img"} onDragStart={()=>false} alt={"star"}/>
                        <img src={Star} className={"Img"} onDragStart={()=>false} alt={"star"}/>
                        <img src={Star} className={"Img"} onDragStart={()=>false} alt={"star"}/>
                        <img src={Star} className={"Img"} onDragStart={()=>false} alt={"star"}/>
                        <img src={Star} className={"Img"} onDragStart={()=>false} alt={"star"}/>
                    </div>
                    <div className={"RatingText"}>
                        {`${Math.floor(10*(review.rating || 10))}/10`}
                    </div>
                </div>
            </div>
            <div className={"ReviewEdit"}>
                <div className={"Title"} >
                    <TextField value={review.title || ""} fullWidth onChange={(event)=>{
                        if (!review.title){
                            logBehavior('review:title', {review});
                        }
                        setReview({ ...review, title: event.target.value })
                    }} style={{padding:0, margin:0}} inputProps={{style:{padding:2,margin:0}}}/>
                </div>
                <Button className={"Submit"} onClick={()=>{
                    (async ()=>{
                        await updateReview(review);
                        reviewCallback();
                    })();
                    setIsWating(false);
                    logBehavior('review:create', {review});
                    
                }}>{"완료"}</Button>
            </div>
            <div className={"Review"} onClick={()=>{setIsWating(isEditable && true)}}>
                <div >
                    {review.title}
                </div>
            </div>
        </div>
        {(isEditable && !isWaiting)
        ?<IconButton onClick={()=>{
            (async()=>{
                logBehavior('review:delete', {review});
                await deleteReview(review);

                reviewCallback();
            })();
        }} style={{marginTop: 6, marginBottom: 6}}>
            <DeleteForeverOutlined/>
        </IconButton>:<></>}
    </div>);
}

export default ReviewShort;