import axios from "axios";
import ss from '../../../../Data/Session';

export const getCampaigns = async ({serviceId, titleId}) => {
    let ret = [];

    let res = await axios.post(`https://challenge-api.webtoon.today/ad/campaign`, {
        serviceId, titleId
    }, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if(res.data && res.data.code === 200){
        ret = res.data.data;
    }else{
        console.error(res.code, res.data);
    }

    return ret;
}

export const createOrUpdateCampaign = async ({serviceId, titleId, network, campaignId, commissionRate, vatRate}) => {
    try{
        let res = await axios.put('https://challenge-api.webtoon.today/ad/campaign', {
            serviceId, titleId, network, campaignId, commissionRate, vatRate
        }, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
    
        if (res.data && res.data.code === 200){
            
            return res.data.data.key;
    
        }else{
            return null;
        }
    }catch(e){
        return null;
    }

}