import axios from 'axios';
import ss from './Session';

/**
 * 
 * @param {string} email
 * @returns {Promise<OwnershipType[]>}
 */
export async function askSecret (email, message) {
    try{
        let res = await axios.post('https://api.webtoon.today/ask-permission', {email: 'sugeul.jin@webtoon.today', message}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return true;

        }else{
            return false;
        }
    }catch(e){
        return false;
    }
}


/**
 * 
 * @param {string} email
 * @param {string} secret
 * @returns {Promise<OwnershipType[]>}
 */
 export async function validateSecret (email, secret) {
    try{
        let res = await axios.post('https://api.webtoon.today/ask-permission', {email: 'sugeul.jin@webtoon.today', secret, type: 'approve'}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && !res.data.code){
            
            return true;

        }else{
            return false;
        }
    }catch(e){
        return false;
    }
}
