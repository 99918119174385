import axios from "axios";
import ss from '../../../../Data/Session';

export const getMailReadLog = async () => {
    try{
        let res = await axios.get(`https://challenge-api.webtoon.today/monitor/mail-read-log`,{
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            }
        })

        if (res.data.code === 200 && res.data.data) {
            return res.data.data;
        } else {
            return null;
        };
    }catch(e){
        return null;
    }
}