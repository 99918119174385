import React from 'react';

import {Button, IconButton, TextField, } from '@material-ui/core';
import { DeleteForever, PersonAdd } from '@material-ui/icons';

import KakaoTalkChatRow from './KakaoTalkChatRow';
import LocalImageReader from '../../Component/LocalImageReader';
import Header from '../../Component/Header';

import './KakaoTalkList.scss';

const KakaoTalkList = () => {

    const [chatData, setChatData] = React.useState([
        {title: null, preview: "", updatedAt: "어제", numberOfUnread: 0, members: [
            {name: "(알 수 없음)", url: "https://static.webtoon.today/ddah/template/kakaotalk/noimage.jpg"}
        ],}, 
    ]);

    return (<div className={"KakaoTalkList"}>
        <Header pageName={"카카오톡 대화목록 만들기"} rightButton={<div style={{width:100, height:10}}></div>}/>
        <div className={"Body"}>
            <img className={"Notibar"} src={"https://static.webtoon.today/ddah/template/kakaotalk/notiBar.jpg"} alt={"Notibar"}/>
            <div className={"SearchBar"}>
                {"채팅"}
                <img src="https://static.webtoon.today/ddah/template/kakaotalk/searchButtons.jpg" alt={"Buttons"}/>
            </div>
            <div className={"AdArea"}>

            </div>
            <div className={"ChatRows"}>
                {chatData.map((chat, chatIndex) => <KakaoTalkChatRow key={`kk-${chatIndex}`} {...chat}/>)}
            </div>
            <img className={"BottomButtons"} src="https://static.webtoon.today/ddah/template/kakaotalk/bottomButtons.jpg" alt={"BottomButtons"}/>
            {chatData.map(row => Number(row.numberOfUnread)).reduce((a,b)=>a+b,0)>0
                ?<div style={{
                    position:'absolute', bottom: 30, left: 110,
                    backgroundColor:'red', borderRadius:'1rem', fontSize: "0.6rem", color: 'white', textAlign:'center',
                    padding:'0 5px 0 5px'
                }}>{chatData.map(row => Number(row.numberOfUnread)).reduce((a,b)=>a+b,0)}</div>
                :<></>}
        </div>
        <div className={"ChatList"}>
            <Button variant={"contained"} color={"primary"} fullWidth onClick={()=>{
                    setChatData([
                        ...chatData,
                        {title: null, preview: "", updatedAt: "어제", numberOfUnread: 0, members: [
                            {name: "(알 수 없음)", url: "https://static.webtoon.today/ddah/template/kakaotalk/noimage.jpg"}
                        ]}
                    ])
                }}>{"대화 추가"}</Button>
            {chatData.map( (chat, chatIndex) => <div key={`${chatIndex}`} className={"ChatData"}>
                <div className={"Members"}>
                    {(chat.members || []).map( (member,memberIndex) => <div key={`${chatIndex}-${memberIndex}`} className={"MemberData"}>
                        <LocalImageReader style={{width:30, height:30, border:'2px lightgray dashed'}}
                            dragOnMessage={member.url?<img style={{width:30, height:30}} src={member.url} alt={"member"}/>:null}
                            callBack={(data)=>{
                                let newChatData = [...chatData];
                                newChatData[chatIndex].members[memberIndex].url=data;
                                setChatData(newChatData)
                            }}
                        />
                        <TextField value={member.name} onChange={(event)=>{
                            let newChatData = [...chatData];
                            newChatData[chatIndex].members[memberIndex].name = event.target.value;
                            setChatData(newChatData)
                        }}/>
                    
                        <IconButton size={"small"} onClick={()=>{
                            let newChatData = [...chatData];
                            newChatData[chatIndex].members = [
                                ...newChatData[chatIndex].members.slice(0, memberIndex),
                                ...newChatData[chatIndex].members.slice(memberIndex+1),
                            ];
                            setChatData(newChatData)
                        }}>
                            <DeleteForever/>
                        </IconButton>
                    </div>)}
                    <Button fullWidth onClick={()=>{
                            setChatData([
                                ...chatData.slice(0, chatIndex),
                                {...chat, members:[ 
                                    ...chat.members,
                                    {name: "(알 수 없음)", url: "https://static.webtoon.today/ddah/template/kakaotalk/noimage.jpg"}
                                ]},
                                ...chatData.slice(chatIndex+1), 
                            ])
                    }}>{"멤버 추가"}<PersonAdd/> </Button>
                </div>
                <div className={"Title"}>
                    <div className={"Label"}>{"채팅방 제목"}</div>
                    <TextField value={chat.title || ""}
                        placeholder={chat.members.length>0?(chat.members || []).map(member => member.name).join(', '):"(대화 상대 없음)"}
                        onChange={event => {
                            let newChatData = [...chatData];
                            newChatData[chatIndex].title = event.target.value;
                            setChatData(newChatData)
                        }
                    }/>
                </div>
                <div className={"Preview"}>
                    <div className={"Label"}>{"미리보기"}</div>
                    <TextField vlaue={chat.preview} onChange={event => {
                        let newChatData = [...chatData];
                        newChatData[chatIndex].preview = event.target.value;
                        setChatData(newChatData)
                    }}/>
                </div>
                <div className={"UpdatedAt"}>
                    <div className={"Label"}>{"마지막 대화"}</div>
                    <TextField value={chat.updatedAt} placeholder={"어제"} onChange={event => {
                        let newChatData = [...chatData];
                        newChatData[chatIndex].updatedAt = event.target.value;
                        setChatData(newChatData)
                    }}/>
                </div>
                <div className={"NumberOfUnread"}>
                    <div className={"Label"}>{"안읽은 대화 수"}</div>
                    <TextField value={chat.numberOfUnread} type={"number"} placeholder={"어제"} onChange={event => {
                        let newChatData = [...chatData];
                        newChatData[chatIndex].numberOfUnread = event.target.value;
                        setChatData(newChatData)
                    }}/>
                </div>
                <Button fullWidth onClick={event=>{
                        let newChatData = [
                            ...chatData.slice(0, chatIndex),
                            ...chatData.slice(chatIndex+1),
                        ];
                        setChatData(newChatData)
                }}
                >{"대화방 삭제"}<DeleteForever/></Button>
            </div>)}
        </div>
        <div className={"Example"}>
            <span style={{paddingLeft:10, fontWeight:'bold'}}>{"사용법"}</span>
            <ul>
                <li><b>{"대화 추가"}</b>{"를 눌러 대화목록을 추가하세요."}</li>
                <li>{"채팅방의 이름, 한줄 미리보기, 마지막 대화시점을 입력할 수 있습니다."}</li>
                <li><b>{"멤버 추가"}</b>{"를 눌러 대화에 참여하는 사람을 추가할 수 있습니다."}</li>
                <li>{"프로필 사진을 드래그해서 작은 상자에 넣으면 프로필을 바꿀 수 있습니다."}</li>
                <li>{"다음 이미지를 드래그해서 넣을 수도 있습니다."}</li>
                <img src="https://static.webtoon.today/ddah/template/kakaotalk/kakaoTalk.jpg" alt={"kakaotalk"}/>
                <img src="https://static.webtoon.today/ddah/template/kakaotalk/noimage.jpg" alt={"kakaotalk"}/>
                <img src="https://static.webtoon.today/ddah/template/kakaotalk/gift.jpg" alt={"kakaotalk"}/>
                <img src="https://static.webtoon.today/ddah/logo-04.png" alt={"kakaotalk"}/>
                <li>{"편집이 완료되면 카카오톡 대화방 영역을 캡쳐합니다."}</li>
                <li>{"본 편집기능으로 제작한 이미지는 별도의 저작권 표시 없이 이용이 가능합니다."}</li>
                {"단, 최종 작업물에 포함된 상표, 이미지, 인물 등에 대해서는 사용자 스스로 권리관계를 확인해야 합니다."}
            </ul>
        </div>
    </div>)
}

export default KakaoTalkList;