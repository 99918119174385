import { AddPhotoAlternate } from '@material-ui/icons';
import React from 'react';
import './ImageHolder.scss';

import EditableFileField from './Table/EditableFileField';

const ImageHolder = ({img: givenImage, update=()=>{}, insertTopCallback=()=>{}, insertBottomCallback=()=>{}, onLoad=()=>{}, accept=null, style={}}) => {

    const [img, setImg] = React.useState(givenImage);
    React.useEffect(()=>{
        setImg(givenImage);
    },[givenImage]);

    return (<div className={"ImageHolder"} style={style}>
        <EditableFileField
            className={"ImageHolderContainer"}
            isEditing={true}
            defaultValue={img}
            field={"image"}
            alt={img}
            update={(newValue)=>{
                update(newValue);
                setImg(newValue.image);
            }}
            accept={accept}
            onLoad={onLoad}
        />
        <div className={"Insert Top"} style={!img?{display:'none'}:{}} onClick={insertTopCallback}><AddPhotoAlternate/></div>
        <div className={"Insert Bottom"} style={!img?{display:'none'}:{}} onClick={insertBottomCallback}><AddPhotoAlternate/></div>
    </div>);
}

export default ImageHolder;