import axios from 'axios'
import ss from '../../../../Data/Session';

/**
 * @typedef {{
 *  serviceId: string,
 *  titleId: string,
 *  episodeId: string,
 *  snapshotId: string,
 *  email: string,
 *  isValid: boolean,
 *  isWinner: string,
 *  rejectReason: string,
 *  validatedAt: number,
 *  testStartedAt: number,
 *  resultConfirmedAt: number,
 *  resultNotifiedAt: number
 * }} competition
 * */


/**
 * 
 * @param {{
 *  serviceId: string,
 *  titleId: string,
 *  episodeId: string,
 *  snapshotId: string
 * }} indexInfo
 * @returns {Promise<competition[]>}
 */
export const listContiAnalyticsProcess = async (indexInfo) => {
    
    let res = await axios.post('https://challenge-api.webtoon.today/process-conti-analytics', indexInfo, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data.map(row => ({...row, isValid: !!row.isValid}));
    }else {
        return [];
    }
}

/**
 * 
 * @param {competition} competition
 * @returns {Promise<string|false>}
 */
export const createContiAnalyticsProcess = async (competition) => {
    if (!ss.getCurrentSession().userid || !competition.serviceId || !competition.titleId || !competition.episodeId || !competition.snapshotId){
        return false;
    }
    
    let res = await axios.put('https://challenge-api.webtoon.today/process-conti-analytics', competition, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.id;
    }else{
        return false;
    }
}

/**
 * 
 * @param {import('./Session').sessionType} session 
 * @param {competition} competition
 * @returns {Promise<string|false>}
 */
export const updateContiAnalyticsProcess = async (competition) => {
    if (!ss.getCurrentSession().userid || !competition.serviceId || !competition.titleId || !competition.episodeId || !competition.snapshotId){
        return false;
    }
    
    let res = await axios.patch('https://challenge-api.webtoon.today/process-conti-analytics', competition, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return true;
    }else{
        return false;
    }
}

/**
 * 
 * @param {{
 *  serviceId: string,
 *  titleId: string,
 *  episodeId: string,
 *  snapshotId: string
 * }[]} indexInfo
 * @returns {Promise<{
 *  serviceId: string,
 *  titleId: string,
 *  episodeId: string,
 *  snapshotId: string,
 *  CTR: number,
 *  fullReadRate: number,
 *  nextClickRate: number
 * }[]>}
 */
export const readContiAnalyticsProcessResult = async (indexInfo) => {
    
    let res = await axios.post('https://challenge-api.webtoon.today/competition-result', {key: indexInfo}, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data.map(row => ({...row}));
    }else {
        return [];
    }
}


/**
 * 
 * @param {{
*  serviceId: string,
*  titleId: string,
*  episodeId: string,
*  snapshotId: string
* }} indexInfo
* @returns {Promise<competition[]>}
*/
export const readContiAnalyticsProcess = async ({serviceId, titleId}) => {
   
   let res = await axios.post('https://challenge-api.webtoon.today/process-conti-analytics', {serviceId, titleId}, {
       headers: {
           Authorization: `Bearer ${ss.getCurrentSession().jwt}`
       },
       withCredentials: true
   });

   if (res.data && res.data.code === 200){
       return res.data.data.map(row => ({...row, isValid: !!row.isValid}));
   }else {
       return [];
   }
}
