
import axios from 'axios';
import ss from '../../../../Data/Session';


export const getTitle = async (serviceId, titleId, dispatch = null) => {
    // if (dispatch) {
    //     return await dispatch(getTitlesThunk([[serviceId, titleId]], false));
    // }
    
    try{
        let res = await axios.post('https://challenge-api.webtoon.today/webtoon/title', {key : [serviceId, titleId].join(':')}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data && res.data.code === 200){
            
            return res.data.data;

        } else {
            return [];
        };

    }catch(e){
        if (e.response && e.response.status === 404) {
            console.log('404 Error')
            return [];
        }
        return [];
    };
};

/**
 * 
 * @param {{serviceId: string, titleId: string}[]} titles 
 * @param {*} dispatch 
 * @returns 
 */
export const getTitles = async (titles, dispatch = null) => {
    // if (dispatch) {
    //     return await dispatch(getTitlesThunk([[serviceId, titleId]], false));
    // }
    
    try{
        let res = await axios.post('https://challenge-api.webtoon.today/webtoon/title', {key : titles.map(({serviceId, titleId})=>[serviceId, titleId].join(':')).join(",")}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data && res.data.code === 200){
            
            return res.data.data;

        } else {
            return [];
        };

    }catch(e){
        return [];
    };
};


export const readSortedTitles = async ({method = ''}) => {
    
    try{
            
        let res = await axios.get(`https://cached-challenge-api.webtoon.today/webtoon/title?method=${method}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data.code === 200 && res.data.data.length > 0) {
            return res.data.data;
        } else {
            return []
        };
    }catch(e){
        if (e.response && e.response.status === 404) {
            console.log(e);
            return [];
        };
        console.error(e);
        return [];
    };
};

export const readTitlesByQuery = async (type, query, limit, managerMode=true) => {
    
    try{
        let res = await axios.post(`https://challenge-api.webtoon.today/webtoon/search`, {
            type, query, limit, managerMode
        }, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data && res.data.code === 200){
            return res.data.data;
        } else {
            throw new Error( res.data.message );
        };
    }catch(e){
        console.error(e);
        return [];
    };
};

export const createTitle = async (title) => {

    try{
        let res = await axios.put('https://challenge-api.webtoon.today/webtoon/title', title, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredential: true
        });
        
        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        };

    }catch(e){
        return null;
    };
};

export async function clearTitle (serviceId, titleId, dispatch = null) {
    // if (!dispatch){
    //     return;
    // }

    // return await dispatch(clearTitlesThunk(serviceId, titleId));
};

export const updateTitle = async (title) => {

    try{
        let res = await axios.patch('https://challenge-api.webtoon.today/webtoon/title', title, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        // await dispatch(clearTitlesThunk([title.serviceId, title.titleId]))
        if (res.data && res.data.code === 200){

            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        console.error(e)
        return null;
    }
};

export const deleteTitle = async ({serviceId, titleId}) => {

    try{
        let res = await axios.delete(`https://challenge-api.webtoon.today/webtoon/title?serviceId=${serviceId}&titleId=${titleId}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }

    }catch(e){
        return null;
    };
};


// const initialGetTitlesState = {
//     loading: false,
//     data: [],
//     error: null,
// };

// const GET_TITLES_START = "GET_TITLES_START";
// const GET_TITLES_SUCCESS = 'GET_TITLES_SUCCESS';
// const GET_TITLES_FAIL = 'GET_TITLES_FAIL';

// const getTitlesStart = () => {
//     return {
//         type: GET_TITLES_START,
//     };
// };

// const getTitlesSuccess = () => {
//     return {
//         type: GET_TITLES_SUCCESS,
//     };
// };

// const getTitlesFail = () => {
//     return {
//         type: GET_TITLES_FAIL,
//     };
// };

// const CLEAR_TITLES_START = 'CLEAR_TITLES_START';
// const CLEAR_TITLES_SUCCESS ='CLEAR_TITLES_SUCCESS';
// const CLEAR_TITLES_FAIL = 'CLEAR_TITLES_FAIL';

// const clearTitlesStart = () => {
//     return{
//         type: CLEAR_TITLES_START
//     };
// };

// const clearTitlesSuccess = (res) => {
//     return {
//         type: CLEAR_TITLES_SUCCESS,
//         data: res
//     };
// };

// const clearTitlesFail = (error) => {
//     return {
//         type: CLEAR_TITLES_FAIL,
//         error
//     };
// };

// const getTitlesThunk = ( serviceId, titleId ) => {
//     return async ( dispatch, getState ) => {

//     }
// }

// const clearTitlesThunk = (serviceId, titleId) => {
//     return async ( dispatch, getState ) => {
        
//     };
// };

// export const titlesReducer = (state = initialGetTitlesState, action) => {
//     if(action.type === GET_TITLES_START){
//         return {
//             ...state,
//             loading: true,
//             error: null
//         };
//     };
//     if(action.type === GET_TITLES_SUCCESS){
//         return {
//             ...state,
//             loading: false,
//             data: {
//                 ...state.data,
//                 ...action.data
//             },
//         };
//     };
//     if(action.type === GET_TITLES_FAIL){
//         return {
//             ...state,
//             loading: false,
//             data: {
//                 ...state.data
//             },
//         };
//     };

//     if(action.type === CLEAR_TITLES_START){
//         return {
//             ...state,
//             error: null
//         };
//     };
//     if(action.type === CLEAR_TITLES_SUCCESS){
//         return {
//             ...state,
//             data: {
//                 ...state.data,
//                 ...action.data
//             },
//         };
//     };
//     if(action.type === CLEAR_TITLES_FAIL){
//         return {
//             ...state
//         };
//     };
    
//     return state;
// }a