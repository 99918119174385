import React from 'react';

import './MenuBar.scss';

import { fn, getOffsetInScreen } from '../../../Functions';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';

const MenuBar = () => {

    const [isBarColorChange, setIsBarColorChange] = React.useState(true);

    React.useEffect(()=>{
        const barColorChanger = (event)=>{
            let homeDOM = document.getElementById('Home')

            let currentTopPos = getOffsetInScreen(homeDOM).top;
            
            let currentBottomPos = getOffsetInScreen(homeDOM).height + currentTopPos;
            if( window.innerHeight > 0 && currentBottomPos - 75 > 0){
                if(!isBarColorChange){
                    setIsBarColorChange(true);
                }
            } else {
                if(isBarColorChange){
                    setIsBarColorChange(false);
                }   
            }
        }

        window.addEventListener('scroll', barColorChanger);
        return () => {
            window.removeEventListener('scroll', barColorChanger);
        }
    },[isBarColorChange]);
    
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div className={'MenuBar'}>
            <div className={`SubmitHeader ${isBarColorChange?"ColorOn":""}`}>
                <div className={"HeadInner"}>
                    <div className={"BarContentArea"}>
                        <a onClick={fn.gotoByAnchor} href={"/"} className={"HashMover"}>
                            <img src={"https://static.webtoon.today/ddah/logo-01.png"} alt={"logo"} />
                        </a>
                        <div className={"Informations"}>
                            <div className={"Information"} >
                                <a href="/notice" className={"HashMover"} onClick={fn.gotoByAnchor}>{"공지사항"}</a>
                            </div>
                            <div className={"Information"} >
                                <a href="#Home" className={"HashMover"}>{"Webtoon Analytics"}</a>
                            </div>
                            <div className={"Information"} >
                                <a href="#PostScript" className={"HashMover"}>{"고객 사례"}</a>
                            </div>
                            <div className={"Information"} >
                                <a href="#Member" className={"HashMover"}>{"팀 소개"}</a>
                            </div>
                            <div className={"Information"} >
                                <a href="#Submit" className={"HashMover"}>{"데이터 분석 신청"}</a>
                            </div>
                            <div className={"Information"} >
                                <a href="#QnA" className={"HashMover"}>{"자주 묻는 질문"}</a>
                            </div>
                        </div>
                        <div className={"InformationMenu"}>
                            <IconButton onClick={handleClick} className={"HashMover"}>
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem className={"Information"} onClick={()=>{
                                    fn.goto('/notice')
                                }}>
                                    <span className={"HashMover"}>{"공지사항"}</span>
                                </MenuItem>
                                <MenuItem className={"Information"} onClick={()=>{
                                    handleClose();
                                    window.location.hash = "#Home";
                                }}>
                                    <span className={"HashMover"}>{"Webtoon Analytics"}</span>
                                </MenuItem>
                                <MenuItem className={"Information"} onClick={()=>{
                                    handleClose();
                                    window.location.hash = "#PostScript";
                                }}>
                                    <span href="#PostScript" className={"HashMover"} >{"고객 사례"}</span>
                                </MenuItem>
                                <MenuItem className={"Information"} onClick={()=>{
                                    handleClose();
                                    window.location.hash = "#Member";
                                }}>
                                    <span href="#Member" className={"HashMover"} >{"팀 소개"}</span>
                                </MenuItem>
                                <MenuItem className={"Information"} onClick={()=>{
                                    handleClose();
                                    window.location.hash = "#Submit";
                                }}>
                                    <span href="#Submit" className={"HashMover"}>{"데이터 분석 신청"}</span>
                                </MenuItem>
                                <MenuItem className={"Information"} onClick={()=>{
                                    handleClose();
                                    window.location.hash = "#QnA";
                                }}>
                                    <span className={"HashMover"} >{"자주 묻는 질문"}</span>
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>
                </div>
            </div>
            {/* 미사용 AppBar 주석 처리 */}
            {/* <div className={'NewsBarHeader'}>
                {currentUnixTime > ceremonyEndUnixTime
                    ?<div className={'NewsBarInner'} onClick={() => fn.goto('https://challenge.webtoon.today/2022university')}>
                        <div className={'NewsBarTitle'}>
                            <div style={{whiteSpace: 'nowrap', margin: '0 5px'}}>
                                {`과제부활전 상금 800만원의 주인공은!`}
                            </div>
                        </div>
                        <div>
                            <div color={'primary'} size={'middle'} style={{backgroundColor: 'rgb(255, 108, 108)', color: 'white', whiteSpace: 'nowrap', padding: '6px 22px', borderRadius: 10, fontWeight: 'bold', fontSize: '1rem'}}>
                                {`당선작 보기`}
                            </div>
                        </div>
                    </div>
                    :<div className={'NewsBarInner'} onClick={() => fn.goto('https://challenge.webtoon.today/2022university')}>
                        <div className={'NewsBarTitle'}>
                            <div style={{whiteSpace: 'nowrap', margin: '0 5px'}}>{`학생이라면 누구나! `}</div>
                            <div style={{whiteSpace: 'nowrap', margin: '0 5px'}}>{`과제부활전 공모전`}</div>
                        </div>
                        <div>
                            <div color={'primary'} size={'middle'} style={{backgroundColor: 'rgb(255, 108, 108)', color: 'white', whiteSpace: 'nowrap', padding: '6px 22px', borderRadius: 10, fontWeight: 'bold', fontSize: '1rem'}}>
                                {'보러가기'}
                            </div>
                        </div>
                    </div>}
            </div> */}
        </div>
    );

}

export default MenuBar;


