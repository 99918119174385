
import axios from 'axios'
import ss from './Session';

export const checkNDA = async () => {
    if ( !ss.getCurrentSession().userid ){
        return false;
    }
    let res = await axios.post('https://api.webtoon.today/nda', {}, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    }else {
        return false;
    }
}


export const putNDA = async ({name, signedAt}) => {
    if ( !ss.getCurrentSession().userid ){
        return false;
    }
    let res = await axios.put('https://api.webtoon.today/nda', {name, email: ss.getCurrentSession().email, signedAt}, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    }else {
        return false;
    }
}