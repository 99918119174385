import axios from 'axios'
import ss from '../../../../Data/Session';

/**
  * @param {{serviceId: string, titleId: string}[]} keys
  * @param {'only'|'include'|'exclude'} nullCase
  * @param {number} page_size
  * @param {number} offset
  * @param {string[] | undefined} platforms
  * @param {number | undefined} year
  */
export const listTopic = async (keys, nullCase, pagse_size = 20, offset = 0, platforms, year) => {
    
    let res = await axios.post('https://challenge-api.webtoon.today/market/topic', {keys, nullCase, pagse_size, offset, platforms, year}, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    }else {
        return [];
    }
}

/**
  * @param {{serviceId: string, titleId: string}[]} keys
  */
export const recommTopic = async (keys) => {
    
    let res = await axios.post('https://challenge-api.webtoon.today/market/topic-recommend', {keys}, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    }else {
        return [];
    }
}

export const listAllTopic = async () => {
    let res = await axios.get('https://challenge-api.webtoon.today/market/topic-recommend', {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    }else {
        return [];
    }
}

/**
  * @param {{serviceId: string, titleId: string, topics: {topic: string, score: number}[]}} data
  */
export const createTopic = async (data) => {
    
    let res = await axios.put('https://challenge-api.webtoon.today/market/topic', data, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    }else{
        return false;
    }
}
