import React from 'react';
import {randomString, MergeAll, getOffsetInScreen} from '../Functions';

/**
 * @typedef {import('../VirtualRouter').point} point
 * 
 * @param {{
 *  style: React.CSSProperties,
 *  width: number,
 *  offset: number,
 *  children: React.Component[],
 *  onScroll: (offset: point, fullWidth: number) => void,
 *  onScrollStart: (position: point) => void,
 *  onScrollEnd: (position: point, offset:point, fullWidth: number) => void,
 * }} props
 */
const Swipe = ({style, width, offset, children, onScroll=(offset)=>{}, onScrollStart=(position)=>{}, onScrollEnd=(position, offset)=>{}}) => {
    const [uniqueId, ] = React.useState('swipe_'+randomString(16));

    const [currentOffset, setCurrentOffset] = React.useState((offset || {x:0}).x);
    React.useEffect(()=>{
        setCurrentOffset((offset||{x:0}).x);
    },[offset]);

    React.useEffect(()=>{
        const elem = document.getElementById(uniqueId);
        if (elem){
            elem.scroll({left:currentOffset,top:0,});
        }
    },[currentOffset, uniqueId]);

    const [isScrolling, setIsScrolling] = React.useState(false);
    const [lastCursorPos, setLastCursorPos] = React.useState({x: 0, y: 0, timestamp: 0});
    const [scrollOrigin, setScrollOrigin] = React.useState({x: 0, y: 0});
    
    React.useEffect(()=>{
        const elem = document.getElementById(uniqueId);
        const scrollListener = async (e) => {
            if (e.which === 0){
                if (isScrolling){
                    const elem = document.getElementById(uniqueId);
                    onScrollEnd(lastCursorPos, {
                        x: elem.scrollLeft,
                        y: elem.scrollTop,
                    }, elem.scrollWidth);
                    setIsScrolling(false);
                }
                return;
            }
            if (!isScrolling){
                setIsScrolling(true);
            }
            let currentCursorPos = {x: 0, y: 0, timestamp: 0};
            if ((e.type === 'mousemove' && isScrolling)){
                currentCursorPos = {x: e.clientX, y: e.clientY, timestamp: new Date().getTime()};
            }else{
                return;
            }
            setLastCursorPos(currentCursorPos);
            setCurrentOffset(Math.min(Math.max(0,scrollOrigin.x - currentCursorPos.x), elem.firstElementChild.scrollWidth - getOffsetInScreen(elem).width));
            onScroll({
                x: elem.scrollLeft,
                y: elem.scrollTop,
            }, elem.scrollWidth);
        };
        const touchMoveListener = (e) => {
            const elem = document.getElementById(uniqueId);
            onScroll({
                x: elem.scrollLeft,
                y: elem.scrollTop,
            }, elem.scrollWidth);
        }
        const touchEndListener = (e) => {
            const elem = document.getElementById(uniqueId);
            setCurrentOffset(elem.scrollLeft);
            onScrollEnd(lastCursorPos, {
                x: elem.scrollLeft,
                y: elem.scrollTop,
            }, elem.scrollWidth);
        }

        const mouseDownListener = (e) => {
            if (e.type === 'mousedown'){
                setScrollOrigin({x: e.clientX + currentOffset, y: e.clientY});
                onScrollStart({x: e.clientX + currentOffset, y: e.clientY});
            }
        };
        const mouseUpListener = (e) => {
            setIsScrolling(false);
            const elem = document.getElementById(uniqueId);
            elem.removeEventListener('mousemove', scrollListener);
            onScrollEnd(lastCursorPos, {
                x: elem.scrollLeft,
                y: elem.scrollTop,
            }, elem.scrollWidth);
        };

        if (!elem){
            return;
        }
        elem.addEventListener('touchmove', touchMoveListener, {capture:false, passive:true});
        elem.addEventListener('touchend', touchEndListener, {capture:false, passive:true});
        elem.addEventListener('mousedown', mouseDownListener,{capture:false, passive:true});
        elem.addEventListener('mouseup', mouseUpListener,{capture:false, passive:true});
        elem.addEventListener('mousemove', scrollListener,{capture:false, passive:true});

        return () => {
            if (!elem){
                return;
            }
            elem.removeEventListener('touchmove', touchMoveListener);
            elem.removeEventListener('touchend', touchEndListener);
            elem.removeEventListener('mousemove', scrollListener);
            elem.removeEventListener('mousedown', mouseDownListener);
            elem.removeEventListener('mouseup', mouseUpListener);
        }
    },[currentOffset, isScrolling, lastCursorPos.timestamp, lastCursorPos.x, lastCursorPos.y, onScroll, onScrollStart, onScrollEnd, width, scrollOrigin.x, scrollOrigin.y, uniqueId, lastCursorPos]);

    return (
        <div id={uniqueId} style={MergeAll({position: 'relative', width, overflowX: 'scroll', overflowY: 'hidden', display: 'flex', alignItems:'center'}, style)}>
            <div className={"slider"} style={{ ...{position: 'relative', paddingRight: 100}, ...(isScrolling?{pointerEvents:'none'}:{})}}>
                {children}
            </div>
        </div>
    );
}

export default Swipe;