import axios from 'axios';
import ss from './Session';

/**
 * @typedef {{
 *   serviceId: string,
 *   titleId: string,
 *   note: string,
 *   createdAt: number
 * }} NoteType
 * 
 * @typedef {{
 *   name: string,
 *   image: string
 * }} AuthorType
 * 
 * @typedef {NoteType | AuthorType} NoteReturnType
 */

/**
 * 
 * @returns  {Promise<NoteReturnType[]>}
 */
 export async function readLatestNotes () {
    
    try{
        let res = await axios.post('https://api.webtoon.today/title-note', {}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        if (res.data && res.data.code === 200){

            return res.data.data;
        } else {
            return [];
        }

    } catch(e) {
        return [];
    }
}

/**
 * 
 * @param {string} serviceId
 * @param {string} titleId 
 * @returns  {Promise<NoteReturnType[]>}
 */
export async function readTitleNotes (serviceId = null, titleId = null) {

    if (!serviceId || !titleId){
        throw new Error("no serviceId or titleId");
    }

    try{
        let res = await axios.post('https://api.webtoon.today/title-note', {serviceId, titleId}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        if (res.data && res.data.code === 200){

            return res.data.data;
        } else {
            return [];
        }

    } catch(e) {
        return [];
    }
}

/**
 * 
 * @param {NoteType} note 
 * @returns  {Promise<string>}
 */
export async function createNote (note) {
    try{
        let res = await axios.put('https://api.webtoon.today/title-note', note, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        if (res.data && res.data.code === 200){

            return res.data.key;
        } else {
            return ;
        }

    } catch(e) {
        return ;
    }
}


/**
 * 
 * @param {string} noteId 
 * @returns {Promise<string>}
 */
export async function updateNote (note) {
    try{
        let res = await axios.patch('https://api.webtoon.today/title-note', note, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        if (res.data && res.data.code === 200){

            return res.data.key;
        } else {
            return ;
        }

    } catch(e) {
        return ;
    }
}