import React from 'react';

const data = {
    title: "오늘의웹툰 서비스 이용 약관"
    ,prefix: "제{n}장"
    ,body: "본 약관은 '주식회사 오늘의웹툰'(이하 '회사'라 한다)에서 운영하는 '자유연재플랫폼'(이하 '오늘의웹툰'이라 한다) '웹툰 애널리틱스'(이하 '애널리틱스'라 한다) 및 이와 관련하여 제공되는 인터넷 관련 서비스(이하 '서비스'라 한다)를 이용함에 있어 회사와 이용자의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다."
    ,nested: [
        {
            title: "총칙",
            prefix: "제{n}조",
            nested: [
                {
                    title: "(목적)"
                    , prefix: "-"
                    , body: "이 약관은 주식회사 오늘의웹툰(이하 '회사'라 한다)가 운영하는 인터넷 웹사이트인 오늘의웹툰(https://challenge.webtoon.tdoay), 웹툰 애널리틱스(https://webtoon.today)(이하 '사이트'라 한다)에서 제공하는 인터넷 관련 서비스(이하 '서비스'라 한다)를 이용함에 있어 서비스와 서비스 이용자의 권리 의무 및 책임사항을 규정함을 목적으로 합니다. ※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.」",
                    nested: [],
                },
                {
                    title: "(용어의 정의)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'회사'라 함은 주식회사 오늘의웹툰을 말합니다.",
                        "'사이트'라 함은 사업자가 재화 또는 용역(이하 '재화 등'이라 함)을 '회원' 에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 운영하고 있는 인터넷 웹사이트 오늘의웹툰(https://challenge.webtoon.tdoay)과 웹툰 애널리틱스(https://webtoon.today) 를 말합니다.",
                        "'이용자'라 함은 '사이트'에 접속하여 이 약관에 따라 '사이트'가 제공하는 서비스를 받는 '회원' 및 '비회원'을 말합니다.",
                        "'회원'이라 함은 '사이트'에 '회원'등록을 한 자로서, 계속적으로 '사이트'가 제공하는 서비스를 이용할 수 있는 자를 말합니다.",
                        "'비회원'이라 함은 '사이트'에 가입하지 않고 '사이트'가 제공하는 서비스를 이용하는 자를 말합니다.",
                        "'작품'이라 함은 '이용자'가 '서비스'를 이용함에 있어 '서비스' 상에 게시한 이미지 형태의 웹툰 원고와 이와 관련된 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.",
                        "'작품 메타정보'라 함은 '이용자'가 '서비스' 상에 게시한 '작품'에서 원고를 제외하고 이와 관련된 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다. 통상적으로 검색 서비스의 정보 제공 범위를 넘지 아니합니다.",
                        "'대중성 분석'이라 함은 '작품'에 대해 회사가 가진 고유한 기술로 '작품'의 통계적 특성을 분석하는 것을 말합니다.",
                        "'피드백'이라 함은 '작품'에 대해 통계적인 것을 제외한 특성이나 개선사항 등을 분석하는 것을 말합니다.",
                        "'유료서비스'라 함은 '회원'이 유료결제를 통해, '사이트' 내의 특정페이지, 정보를 조회할 수 있는 권한, 특정 용역을 요청할 권리를 얻는 것을 의미합니다.",
                        "'데이터 분석'이라 함은 '유료서비스'의 권한을 얻은 '회원'의 '작품'에 대해 '회사'가 '대중성 분석'과 '피드백'을 제공하는 것을 의미합니다.",
                        "'데이터 분석 열람'이라 함은 '회사'가 보유한 '데이터 분석' 결과를 '유료서비스'의 권한을 얻은 '회원'이 열람하는 것을 의미합니다.",
                    ]
                },
                {
                    title: "(약관 등의 명시와 설명 및 개정)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'회사'는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비 자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호 모사전송번호 전자 우편주소, 사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자등을 '회원'이 쉽게 알 수 있도록 '사이트'의 하단에 게시합니다. 다만, 약관의 내용은 '이용자'가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.",
                        "'회사'는 '이용자'가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 중요한 내용을 '이용자'가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 '이용자'의 확인을 구하여야 합니다.",
                        "'회사'는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제 에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.",
                        "'회사'가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 '사이트'의 초기화면에 그 적용 일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, '회원'에게 불리하게 약관내용을 변경하는 경우에는 최소 한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 '회사'는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 '회원'이 알기 쉽도록 표시합니다.",
                        "'회사'가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 '회원'이 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 '회사'에 송신하여 '회사'의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.",
                        "이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.",
                    ]
                },
            ]
        },
        {
            title: "서비스의 이용",
            prefix: "제{n}조",
            nested: [
                {
                    title: "(서비스의 제공 및 변경)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'사이트'는 다음과 같은 업무를 수행합니다.",
                        "작품의 대중성 분석, 피드백",
                        "작품의 자유연재 및 조회가 가능한 게시판 형 플랫폼",
                        "데이터 분석결과 열람 서비스",
                        "커뮤니티 서비스",
                        "기타 '사업자'가 자체 개발하거나 다른 기관과의 협력계약 등을 통해 '회원'들에게 제공할 일체의 서비스",
                        "'회사'는 재화 또는 용역의 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.",
                        "'회사'가 제공하기로 '회원'와 계약을 체결한 서비스의 내용을 재화 등의 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 '회원'에게 통지 가능한 주소로 즉시 통지합니다.",
                        "전항의 경우 '회사'는 이로 인하여 '회원'이 입은 손해를 보상합니다. 다만, '회사'가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.",
                    ]
                },
                {
                    title: "(서비스의 해외이용)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'회사'는 '이용자'가 대한민국의 영토 이외의 지역에서 '사이트'를 이용하고자 하는 경우 '사이트'의 품질 또는 사용성을 보장하지 않습니다.",
                        "'이용자'는 대한민국의 영토 이외의 지역에서 '사이트'를 이용하고자 하는 경우 스스로의 판단과 책임에 따라서 이용 여부를 결정하여야 하고, 특히 '사이트'의 이용과정에서 현지 법령을 준수할 책임은 '이용자'에게 있습니다.",
                    ]
                },
                {
                    title: "(서비스의 중단)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'회사'는 컴퓨터 등 정보통신설비의 보수점검 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.",
                        "'회사'는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 '회원' 또는 제3자가 입은 손해에 대하여 배상합니다. 단, '회사'가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.",
                        "사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 '회사'는 -에 정한 방법으로 '회원'에게 통지하고 당초 '회사'에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, '회사'가 보상기준 등을 고지하지 아니한 경우에는 '회원'들의 마일리지 또는 적립금 등을 '사이트'에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 '회원'에게 지급합니다.",
                    ]
                },
                {
                    title: "(데이터 분석 서비스의 이용)"
                    , prefix: "-"
                    , body: "'사이트'에서 데이터 분석 서비스의 범위로 지정한 서비스는 다음과 같습니다."
                    , nested: [
                        "'회원'의 '작품' 업로드하는 기능",
                        "'회원'의 '작품'을 불특정 다수의 이용자가 열람하고, 별점, 댓글 등을 남기는 기능",
                        "'작품'을 열람한 이용자가 남긴 로그를 수집하여 '통계적 분석'과 '피드백'을 제공하는 기능",
                        "'데이터 분석' 신청 여부는 '작품'에 대한 저작권에 일체의 영향을 주지 않으며, '회사'가 작성한 '통계적 분석'과 '피드백'의 저작권은 '회사'에 있습니다.",
                        "'회원'은 '유료 서비스'를 통해 '회사'가 작성한 '통계적 분석'과 '피드백'의 비공개를 요청할 수 있습니다.",
                    ]
                },
                {
                    title: "(데이터 분석 열람 서비스의 이용)"
                    , prefix: "-"
                    , body: "'사이트'에서 데이터 분석 서비스의 범위로 지정한 서비스는 다음과 같습니다."
                    , nested: [
                        "'회원'이 '유료 서비스'를 통해 '회사'가 '작품' 보유한 '통계적 분석'과 '피드백'을 열람하는 기능",
                        "'회사'가 '통계적 분석'과 '피드백'을 보유하지 않은 '작품'에 대해 데이터 분석 제안 및 일체의 용역을 대리하는 기능",
                    ]
                },
                {
                    title: "(자유연재 플랫폼 서비스의 이용)"
                    , prefix: "-"
                    , body: "'사이트'에서 자유연재 플랫폼 서비스의 범위로 지정한 서비스는 다음과 같습니다."
                    , nested: [
                        "'회원'의 '작품' 게시하는 기능",
                        "'회원'의 '작품'을 다른 이용자가 열람하고, 별점, 댓글 등을 남기는 기능",
                        {
                            title: "'회원'이 작성한 '작품'에 대한 모든 권리 및 책임은 이를 게시한 '회원'에게 있으며, '회사'는 다음 각 호에 해당한다고 판단되는 경우에 사전통지 없이 삭제할 수 있고, 이에 대하여 '회사'는 어떠한 책임도 지지 않습니다.",
                            nested: [
                            "본 약관에 위배되거나 상용 또는 불법, 음란, 저속하다고 판단되는 '작품'을 게시한 경우",
                            "다른 '회원' 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우",
                            "공서양속에 반하는 내용인 경우",
                            "범죄적 행위에 결부된다고 인정되는 내용일 경우",
                            "제 3자의 저작권 등 기타 권리를 침해하는 내용인 경우",
                            "'회원'의 '게시물'에 대한 '이용자'의 신고가 '회사'에 접수되고 '회사'가 신고사유가 합당하다고 판단하는 경우",
                            "기타 관계 법령에 위배되는 경우",
                        ]}
                    ]
                },
                {
                    title: "(회원가입)"
                    , prefix: "-"
                    , body: "오늘의 웹툰은 쿠키를 이용하여 웹서비스 사용 정보를 수집합니다. 유저는 쿠키 수집에 대해 선택권이 있습니다. 브라우저 설정을 통해 사이트의 모든 쿠키 수집을 차단할 수 있으며, 저장된 시점에 무엇이 저장되었는지를 확인하거나 저장되지 않도록 할 수 있습니다: 설명한 바와 같이, 쿠키를 사용하지 않도록 하는 것은 사용자를 식별하기 어렵게 만들며 온전한 서비스를 제공받을 수 없음을 의미합니다."
                    , nested: [
                        "'회원'은 '회사'가 정한 가입 양식에 따라 '회원'정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 '회원'가입을 신청합니다.",
                        "'회사'는 제1항과 같이 '회원'으로 가입할 것을 신청한 '회원' 중 다음 각 호에 해당하지 않는 한 '회원'으로 등록합니다.",
                        "가입신청자가 이 약관 -제3항에 의하여 이전에 '회원'자격을 상실한 적 이 있는 경우, 다만 -제3항에 의한 '회원'자격 상실 후 3년이 경과한 자 로서 '회사'의 '회원'재가입 승낙을 얻은 경우에는 예외로 합니다.",
                        "등록 내용에 허위, 기재누락, 오기가 있는 경우",
                        "기타 '회원'으로 등록하는 것이 '사이트'의 기술상 현저히 지장이 있다고 판단되는 경우",
                        "'회원'가입 계약의 성립 시기는 '회사'의 승낙이 '회원'에게 도달한 시점으로 합니다.",
                        "'회원'은 '회원'가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 '회사'에 대하여 '회원'정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.",
                        "'서비스' 이용계약의 성립 시기는 '회원'으로 가입할 것을 신청하고 서비스의 이용에 필요한 개인정보 수집 및 이용, 개인정보 제3자 제공, 개인정보 위탁에 동의한 후 '회사'의 승낙이 '회원'에게 도달한 시점으로 합니다.",
                    ]
                },
                {
                    title: "(회원 정보의 변경)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'회원'은 가입신청 시 기재한 사항이 변경되었을 경우 '사이트'에서 수정을 하거나 전자우편 기타 방법으로 '회사'에게 그 변경사항을 알려야 합니다.",
                        "제1항의 변경사항을 '회사'에 알리지 않아 발생한 불이익에 대하여 '회사'는 책임지지 않습니다.",
                    ]
                },
                {
                    title: "(회원 탈퇴 및 자격 상실 등)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'회원'은 '사이트'에 언제든지 탈퇴를 요청할 수 있으며 '회사'는 즉시 '회원'탈퇴를 처리합니다.",
                        "'회원' 탈퇴가 이루어진 경우 -제1항에서 규정하는 서비스 범위의 등록된 게시물을 제외한 게시물 일체는 삭제됩니다.",
                        "'회원'이 다음 각 호의 사유에 해당하는 경우, '회사'는 '회원'자격을 제한 및 정지시킬 수 있습니다.",
                        "가입 신청 시에 허위 내용을 등록한 경우",
                        "'사이트'을 이용하여 구입한 재화 등의 대금, 기타 '사이트' 사용에 관련하여 '회원'이 부담하는 채무를 기일에 지급하지 않는 경우",
                        "다른 사람의 '사이트' 이용을 방해하거나 그 정보를 도용하는 등 질서를 위협하는 경우",
                        "'사이트'을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우",
                        "'회사'가 '회원'자격을 제한정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일이내에 그 사유가 시정되지 아니하는 경우 '회사'는 '회원'자격을 상실시킬 수 있습니다.",
                        "'회사'가 '회원'자격을 상실시키는 경우에는 '회원'등록을 말소합니다. 이 경우 '회원'에게 이를 통지하고, '회원'등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.",
                    ]
                },
                {
                    title: "(회원에 대한 통지)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'회사'가 '회원'에 대한 통지를 하는 경우, '회원'이 '회사'와 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.",
                        "'회사'는 불특정다수 '회원'에 대한 통지의 경우 1주일이상 '사이트' 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, '회원' 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.",
                    ]
                },
                {
                    title: "(연결 사이트와 피연결 사이트 간의 관계)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "상위 사이트와 하위 사이트가 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동영상 등이 포함됨)방식 등으로 연결된 경우, 전자를 연결 사이트라고 하고 후자를 피연결 사이트라고 합니다.",
                        "연결 사이트는 피연결 사이트가 독자적으로 제공하는 재화 등에 의하여 이용자와 행하는 거래에 대해서는 보증책임을 지지 않는다는 뜻을 연결 사이트의 초기화면 또는 연결되는 시점의 팝업화면으로 명시한 경우에는 그 거래에 대한 보증책임을 지지 않습니다.",
                    ]
                },
                {
                    title: "(저작권의 귀속)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'회사'가 작성한 저작물에 대한 저작권 기타 지적재산권은 '회사'에 귀속합니다.",
                        "'회원'이 작성한 게시물에 대한 모든 권리 및 책임은 이를 게시한 '회원'에게 있습니다.",
                        "'회원'은 '사이트'을 이용함으로써 얻은 정보 중 '회사'에게 지적재산권이 귀속된 정보를 '회사'의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.",
                        "'회사'는 '회원'이 작성한 게시물 중 '작품'을 제외한 공개적인 게시물을 사용ㆍ복제ㆍ수정ㆍ출판ㆍ배포 할 수 있습니다.",
                        "'회사'는 '회원'이 작성한 '작품' 중 '메타정보'에 한하여 편집하지 않은 형태로 사용 할 수 있습니다. ",
                    ]
                },
                {
                    title: "(이용제한)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'회사'는 '이용자'가 본 약관의 의무, '회사'의 운영정책 등을 위반하거나 서비스의 정상적인 운영을 방해한 것으로 볼 상당한 이유가 있는 경우, 서비스 이용을 경고, 일시정지, 계약해지로 제한할 수 있습니다. 이러한 서비스 이용 제한에 따른 '이용자'의 손해에 대하여 '회사'는 별도로 보상하지 않습니다",
                        "'회사'는 제1항의 규정에도 불구하고, '이용자'가 주민등록법을 위반한 명의도용 및 결제도용, 저작권법 및 컴퓨터프로그램보호법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위, 타인의 개인정보 부당 수령, 위법행위 수행에 이용 등과 같이 관련법령 위반, 미풍양속 위반의 경우에는 즉시 계약을 해지할 수 있습니다. 이에 따라 서비스 이용을 통해 획득한 혜택 등은 모두 소멸되며, '회사'는 이에 대해 별도로 보상하지 않습니다.",
                        "'회사'가 '회원'의 서비스 이용을 제한하는 경우, 회원정보에 등록된 이메일로 사전통지 됩니다. 통지 후 15일 영업일 내 이의신청이 없을 시 이용제한이 조치됩니다. '비회원'의 서비스 이용제한은 별도의 통지 없이 해당 접속경로에 대한 이용이 제한됩니다.",
                        "'회원'은 '회사'의 이용제한 조치 등에 대하여 '회사'가 정한 절차에 따라 이의신청을 할 수 있습니다. '회원'의 이의 사유가 정당하다고 인정되는 경우 '회사'는 즉시 '회원'의 서비스 이용을 재개합니다. 단, 이 경우에도 '회사'의 이용제한 조치가 '회사'의 고의 또는 중과실로 인한 것이 아닌 한 이용제한으로 발생한 손해에 대하여 '회사'는 별도로 보상하지 않습니다.",
                    ]
                },
            ]
        },
        {
            title: "'유료서비스' 이용",
            prefix: "제{n}조",
            nested: [
                {
                    title: "('유료서비스' 이용계약의 성립)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'유료서비스' 이용계약은 '회사'가 운영하는 서비스상에서 본 약관에 동의 후 '유료서비스' 결제가 완료되거나, '회사'와의 개별적인 계약이 완료될 경우 성립합니다.",
                        "'유료서비스' 이용요금 및 계약기간은 해당서비스 '가격페이지'에 명시되어 있는 별도의 규정을 따릅니다.",
                        "'회사'는 운영상, 기술상의 필요에 따라 공급하는 '유료서비스' 내용(구독금액, 제공 서비스 내역 등)을 변경할 수 있으며,  2항에 따라 유료회원에게 사전 공지합니다.",
                    ]
                },
                {
                    title: "(이용요금 및 결제방식)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'유료서비스' 이용요금 및 결제방식은 '회사'의 가격정책에 따라 서비스 '가격페이지'에 명시된 내용을 따릅니다.",
                        "'회사'는 정하는 바에 따라 이용요금을 감면 또는 할인할 수 있습니다. 이 때, 감면 또는 할인의 구체적인 대상 및 적용기준은 '회사'가 정하는 바에 따릅니다.",
                        "'유료서비스' 결제방식은 기본적으로 '회원'명의의 카드결제 방법이나, '회사'에서 현금입금 결제방식만 제공할 시 '회사'측에 별도의 문의가 필요하고, 납입방법은 매월 이용요금을 납입하는 월납의 방법과 계약 시 1년 이용요금을 한번에 납입하는 연납의 방법 중 '회사'에서 허용한 방법으로 선택할 수 있습니다. 모든 '유료서비스'는 선납을 원칙으로 합니다.",
                    ]
                },
                {
                    title: "(이용요금의 청구와 납입)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'회사'는, 별도로 정하는 경우를 제외하고는, '회원'의 '회원'ID를 기준으로 이용요금을 청구합니다. 따라서, '회사'가 별도로 동의하지 않는 한, 이용요금 납입자는 '회원'을 원칙으로 합니다.",
                        "'회원'은 '회사'와 계약된 신용카드사를 이용하여 별도의 매출전표에 서명하지 않고 이용요금을 납입할 수 있습니다. 단, 카드분실 등에 따른 불이익에 대해서는 '회원'의 책임으로 합니다. '회원'은 신용카드의 유효기간 만료 · 분실 · 재발행 등으로 인해 카드번호가 바뀔 경우에 반드시 '회사'에 통보하거나 별도 페이지에서 수정해야 하며, 이 경우에는 변경된 카드에 요금이 부과됩니다. 한편, '회원'의 요청에 따라 '회사'가 승낙한 경우에는 다른 방법에 의해서도 이용요금 납부가 가능합니다.",
                        "'회사'는 '회원'이 본 약관에서 정한 사항을 위반하여 '유료서비스'를 이용하거나, 불법으로 이용요금 등을 면탈한 경우에는 '회사'는 그 사유를 명시하여 관련 법정이자율에 의한 지연손해금을 일할 계산하여 적용한 금액을 청구합니다.",
                        "'회사'가 정한 기간 동안 '회원'이 이용요금을 체납할 경우, '회사'는 신용정보사업자 또는 신용정보집중 기관에 '회원'의 개인신상정보 등을 제공할 수 있습니다. '회원' 본인의 신고누락 등으로 인하여 발생하는 불이익에 대하여 '회사'는 책임지지 않습니다.",
                        "계약기간 중 결제방식 혹은 납입방법을 변경한 경우 변경된 방법에 의한 이용요금의 청구는 변경일의 익월에 대한 이용요금 청구시점부터 적용됩니다.",
                    ]
                },
                {
                    title: "('유료서비스' 일반)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'회원'의 '유료서비스'는 '가격페이지'에 명시된 내용을 따릅니다.",
                        "'회원'의 요청에 따라 '회사'가 승낙한 경우에는 별도의 권한이 부여될 수 있습니다.",
                        "각 '회원' 또는 '비회원'은 '가격페이지'에 명시된 정책에 따라 자신이 구독 또는 접근할 수 있는 데이터, 서비스만 이용할 수 있습니다. 이 외의 데이터, 서비스를 스크래핑, 크롤링, 매크로, 해킹 등의 방법으로 '사이트'를 이용하거나 적발, 의심 시, 경고조치 후 '회원' 또는 '이용자' 자격을 박탈당할 수 있습니다.",
                        "'회사'는 계정정보를 통하여 당해 '회원'의 '유료서비스' 이용가능여부 등 제반 이용자 관리 업무를 수행하며, 하나의 계정정보를 통해 사용할 수 있는 '유료서비스' 이용자는 1인(자연인)에 한정합니다.",
                        "계정공유는 동일 법인 내 근무하는 임직원 간에도 엄격하게 금지됩니다. 계정공유 금지정책의 준수 및 개인정보 보호 등을 위하여, 회원은 자신의 계정정보를 선량한 관리자로서의 주의의무를 다하여 관리하여야 합니다. 회원이 본인의 계정정보를 소홀히 관리하거나 제3자에게 이용을 승낙하는 등으로 발생하는 손해에 대하여는 회원에게 책임이 있습니다.",
                    ]
                },
                {
                    title: "('유료서비스' 보고서 열람 권한)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "보고서 열람 권한이란, '유료서비스'의 일종으로 특별히 지정된 보고서를 열람할 수 있는 권한입니다.",
                        "'회원'의 요청에 따라 '회사'가 승낙한 경우에는 별도의 보고서 열람 권한이 부여될 수 있습니다.",
                        "'회원'의 '유료서비스' 보고서 열람의 구체적인 권한은 '가격페이지'에 명시된 내용을 따릅니다.",
                    ]
                },
                {
                    title: "(보고서 열람 권한의 환불 정책)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'유료서비스'의 결제는 디지털 콘텐츠 서비스의 특성상 '보고서 열람' 기록이 존재할 경우 환불이 불가합니다.",
                        "단, '유료서비스'의 데이터 조회 권한을 부여받은 계정이 잔여 열람 기한 또는 횟수가 남아있는 경우, 계약 금액의 10%를 명목 위약금으로 제한 후, 전체 계약 기한 또는 횟수 중 남은 기한 또는 횟수만큼의 비율만큼 환불됩니다.",
                    ]
                },
                {
                    title: "('유료서비스' 작품 소장권)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "작품 소장권은 '유료서비스'의 일종으로 특정된 작품의 특정된 회차를 열람할 수 있는 권한입니다.",
                        "'회원'의 요청에 따라 '회사'가 승낙한 경우에는 별도의 작품 소장권이 부여될 수 있습니다.",
                        "'회원'의 '유료서비스' 작품 소장권의 내용, 즉 열람권한이 부여될 특정 작품과 특정 회차는 '가격페이지'에 명시된 내용을 따릅니다.",
                        "'회원'은 구매한 작품 소장권에 따라 특정 작품과 특정 회차를 열람할 권리를 부여 받습니다.",
                    ]
                },
                {
                    title: "(작품 소장권의 환불 정책)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'유료서비스'의 결제는 디지털 콘텐츠 서비스의 특성상 '작품 열람' 기록이 존재할 경우 환불이 불가합니다.",
                        "단, 작품 소장권을 구매한 계정이 아직 해당하는 특정 작품의 특정 회차 중 어느 하나라도 열람 하지 않은 경우 환불이 가능합니다.",
                        "'회사'는 '회원'이 환불 신청을 한 경우 신청한 날로부터 5영업일 이내에 그 결제와 동일한 방법으로 환불합니다."
                    ]
                },
                {
                    title: "('유료서비스' 웹툰 메트릭)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "웹툰 메트릭은 '유료서비스'의 일종으로 특정된 작품의 각종 분석 결과를 열람할 수 있는 서비스입니다.",
                        "'회원'의 요청에 따라 '회사'가 승낙한 경우에는 별도의 데이터 열람권한이 부여될 수 있습니다.",
                        "'회원'의 '유료서비스' 웹툰 메트릭의 내용, 즉 열람권한이 부여될 특정 데이터와 구좌의 수는 '가격페이지'에 명시된 내용을 따릅니다.",
                        "'회원'은 구매한 웹툰 메트릭의 범위 및 구좌의 수에 따라 특정 데이터를 열람할 권리를 본인 또는 다른 '회원'에게 부여할 수 있습니다.",
                    ]
                },
                {
                    title: "(웹툰 메트릭의 환불 정책)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'유료서비스'의 결제는 디지털 콘텐츠 서비스의 특성상 '이용' 기록이 존재할 경우 환불이 불가합니다.",
                        "단, 웹툰 메트릭을 구매한 계정 또는 권한을 부여받은 계정이 아직 해당하는 특정 작품 중 어느 하나라도 열람 하지 않은 경우 해당 구좌에 대해 환불이 가능합니다.",
                        "'회사'는 '회원'이 환불 신청을 한 경우 신청한 날로부터 5영업일 이내에 그 결제와 동일한 방법으로 환불합니다."
                    ]
                },
                {
                    title: "('유료서비스' 광고 예치금)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "광고 예치금은 '유료서비스'의 일종으로 편리하게 광고를 집행할 수 있도록 가상의 구좌에 미리 비용을 결제해 둘 수 있는 서비스입니다.",
                        "'회원'의 요청에 따라 '회사'가 승낙한 경우에는 추가적인 예치금이 부여될 수 있습니다.",
                        "'회원'의 결제애 의해 '유료서비스' 예치금이 부여되는 양은 '가격페이지'에 명시된 내용을 따릅니다.",
                        "'회원'은 구매한 예치금을 본인이 포함된 '팀'에 부여할 수 있습니다.",
                        "'회사'는 '회원'의 예치금의 적립과 사용 내역을 관리하며 '회원'이 열람할 수 있도록 합니다.",
                        "'회사'가 보유한 예치금은 최대 5년간 보존되며 이 기간을 초과하거나 '회원'이 탈퇴한 경우 소멸됩니다.",
                    ]
                },
                {
                    title: "(광고 예치금의 환불 정책)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'회원'이 구매한 후 아직 사용하지 않은 예치금은 이미 발생한 결제 수수료와 환불 수수료를 제하고 환불이 가능합니다.",
                        "'회사'는 '회원'이 환불 신청을 한 경우 신청한 날로부터 5영업일 이내에 그 결제와 동일한 방법으로 환불합니다."
                    ]
                },
            ]
        },
        {
            title: "계약당사자의 의무",
            prefix: "제{n}조",
            nested: [
                {
                    title: "('회사'의 의무)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'회사'는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화 용역을 제공하는데 최선을 다하여야 합니다.",
                        "'회사'는 '회원'이 안전하게 인터넷 서비스를 이용할 수 있도록 '회원'의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.",
                        "'회사'가 상품이나 용역에 대하여 「표시 광고의 공정화에 관한 법률」 - 소정의 부당한 표시 광고행위를 함으로써 '회원'이 손해를 입은 때에는 이를 배상할 책임을 집니다.",
                        "'회사'는 '회원'이 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.",
                    ]
                },
                {
                    title: "('회원'의 ID 및 비밀번호에 대한 의무)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "ID와 비밀번호에 관한 관리책임은 '회원'에게 있습니다.",
                        "'회원'은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.",
                        "'회원'이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 '회사'에 통보하고 '회사'의 안내가 있는 경우에는 그에 따라야 합니다.",
                    ]
                },
                {
                    title: "('회원'의 의무)"
                    , prefix: "-"
                    , body: "'회원'은 다음 행위를 하여서는 안됩니다."
                    , nested: [
                        "신청 또는 변경시 허위 내용의 등록",
                        "타인의 정보 도용",
                        "'사이트'에 게시된 정보의 변경",
                        "'회사'가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시",
                        "'회사' 및 기타 제3자의 저작권 등 지적재산권에 대한 침해",
                        "'회사' 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위",
                        "외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 '사이트'에 공개 또는 게시하는 행위",
                    ]
                },
                {
                    title: "(개인정보보호)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'회사'는 '회원'의 개인정보 수집시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.",
                        "'회사'는 '회원'의 개인정보를 수집 이용하는 때에는 당해 '회원'에게 그 목적을 고지하고 동의를 받습니다.",
                        "'회사'는 수집된 개인정보를 목적외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용 제공단계에서 당해 '회원'에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.",
                        "'회사'가 제2항과 제3항에 의해 '회원'의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용 자는 언제든지 이 동의를 철회할 수 있습니다.",
                        "'회원'은 언제든지 '회사'가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 '회사'는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. '회원'이 오류의 정정을 요구한 경우에는 '회사'는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.",
                        "'회사'는 개인정보 보호를 위하여 '회원'의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 '회원'의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 '회원'의 손해에 대하여 모든 책임을 집니다.",
                        "'회사' 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.",
                        "'회사'는 개인정보의 수집 이용 제공에 관한 동의란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집 이용 제공에 관한 '회원'의 동의거절시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집 이용 제공에 관한 '회원'의 동의 거절을 이유로 '회원'가입 등 서비스 제공을 제한하거나 거절하지 않습니다.",
                        "'회사'는 회원의 개인정보를 보호하기 위해 '개인정보처리방침'을 수립하고 개인정보 보호 책임자를 지정하여 이를 게시하고 운영합니다.",
                    ]
                },
            ]
        },
        {
            title: "과오금, 피해보상 등",
            prefix: "제{n}조",
            nested: [
                {
                    title: "(과오금)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'회사'는 '유료서비스' 결제와 관련하여 과오금이 발생한 경우 이용대금의 결제와 동일한 방법으로 그 과오금 반환합니다. 다만, 동일한 방법으로 환불이 불가능할 때는 이를 사전에 고지합니다.",
                        "'회사'는 '회원'이 요구하는 과오금에 대하여 환불을 거부할 경우, 정당하게 '유료서비스' 요금이 부과되었음을 입증할 책임을 부담합니다.",
                    ]
                },
                {
                    title: "(면책조항)"
                    , prefix: "-"
                    , body: "'회원'이 '회사'의 서비스(이하 '유료서비스' 포함) 제공으로부터 기대되는 이익을 얻지 못하였거나 서비스 자료에 대한 취사선택 또는 이용으로 발생하는 불이익에 대하여 '사업자'는 책임을 지지 아니합니다."
                    , nested: [
                        "'사업자'는 '회원' 상호간 또는 '회원'와 제3자 간에 게시하거나 제공한 정보로 발생한 분쟁 등에 대하여 책임을 지지 않습니다.",
                        "'회원'이 '서비스'를 이용함에 있어 행한 불법행위나 본 약관 위반행위로 인하여 '사업자'가 당해 '회원' 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 '회원'은 자신의 책임과 비용으로 '사업자'를 면책시켜야 하며, '사업자'가 면책되지 못한 경우 당해 '회원'은 그로 인하여 '사업자' 발생한 모든 손해를 배상하여야 합니다.",
                        "'회사'의 서비스에 포함된 모든 정보, 소프트웨어, 제품 및 기타 서비스에는 부정확함이나 오류가 있을 수 있습니다. '회사'는 언제든지 본 사이트를 개선하거나 변경할 수 있습니다. 위와 같은 부정확함이나 오류가 '회사'의 고의 또는 중과실에 기한 것이 아닌 경우 '회사'는 그로 인한 책임을 면합니다.",
                        "'회사'는, 전시, 사변 등 국가비상사태, 천재지변, 기간통신사업자의 서비스 제공 중단, 한전으로부터의 전력공급 중단, 해커의 침입, 컴퓨터 바이러스 기타 이와 유사한 사정으로 인한 '회사' 시스템의 작동불능 등 '회사'의 귀책사유 없이 '회사'가 서비스를 제공할 수 없는 경우 그로 인한 책임을 면합니다.",
                        "이용'회원'의 귀책사유로 인하여 서비스 제공 및 이용의 장애가 발생한 경우, '회사'는 그로인한 책임을 면합니다.",
                        "'회사'는 어떠한 목적으로든 본 '회사'의 사이트에 있는 정보, 소프트웨어, 제품, 서비스 및 관련 그래픽의 적합성, 신빙성, 시의성 및 정확성에 관한 어떠한 보증도 하지 아니합니다. 정보의 사용은 전적으로 '회원'의 책임과 권한으로 수행됩니다.",
                        "'회사'는 작품 관련 데이터와 데이터 분석 서비스는 정보제공의 목적으로 제공되는 것이며 해당 정보를 '회원' 또는 유관한 제3자가 사용할 경우 정보의 오류 및 지연으로 인한 피해에 대해 '회사'는 어떠한 책임도 없습니다.",
                    ]
                },
                {
                    title: "(최소 사용 환경)"
                    , prefix: "-"
                    , body: "최소 사용 환경을 충족하지 못하는 환경에서 서비스를 이용하거나, '회사'와 관련없는 보안 프로그램 사용이나 보안 조치가 취해진 환경으로 인해 발생하는 문제에 대해 '회사'는 책임지지 않습니다."
                    , nested: [
                        "이용자는 아래 각 호에 해당하는 최소 사용 환경에서 서비스를 이용해야 합니다.",
                        "운영체제 : Mac(Mavericks 이상), Windows7 이상",
                        "브라우저 : Edge(25.1 이상), Chrome(49 이상), Safari(9.0 이상) Opera(37 이상), Firefox(43.0 이상)",
                        "쿠키 : 모든 쿠키 허용",
                    ]
                },
                {
                    title: "(분쟁해결)"
                    , prefix: "-"
                    , body: ""
                    , nested: [
                        "'회사'는 '회원'이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치 운영합니다.",
                        "'회사'는 '회원'으로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 '회원'에게 그 사유와 처리일정을 즉시 통보해 드립니다.",
                        "'회사'와 '회원'간에 발생한 서비스 이용에 관한 분쟁에 대하여는 대한민국 법을 적용하며, 본 분쟁으로 인한 소는 민사소송법상의 관할을 가지는 대한민국의 법원에 제기합니다.",
                    ]
                },
            ]
        },
        {
            title: "부칙"
            , prefix: "-"
            , body: "본 약관은 2023년 7월 26일부터 적용합니다."
            , nested: [
            ]
        },
    ]
};
/*
        {
            title: ""
            , prefix: "-"
            , body: ""
            , nested: [
            ]
        }
 */

const DrawData = (data, prefix, depth, idx) => {

    const romanNumber = ['i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii', 'viii', 'ix'];

    if (typeof(data) === 'string'){
        
        return (
            <div style={{paddingLeft: 20, fontSize: 120*Math.pow(0.9, depth)+'%' }}>
                <p>
                {prefix?`${prefix.replace('{n}',idx+1).replace('{i}', 'x'.repeat(Math.floor(idx/10))+romanNumber[idx%10] )}`:''} {data || ""}
                </p>
            </div>
        );
    };

    return (
        <div style={{paddingLeft: 20, fontSize: 120*Math.pow(0.9, depth)+'%' }}>
            <p>
                {prefix?`${prefix.replace('{n}',idx+1).replace('{i}', 'x'.repeat(Math.floor(idx/10))+romanNumber[idx%10] )}`:''} {data.title || ""}
            </p>
            {data.body || ""}
            {data.nested?data.nested.map( (row,idx) => DrawData(row, data.prefix, depth+1, idx )):''}
        </div>
    );
}


export default function AnalyticsTermsOfUseKr (props) {

    window.canonicalPath = '/termsofuse/analytics/kr';
    document.title = "웹툰 애널리틱스 이용 약관";

    return (
        <div style={{textAlign:'left', wordBreak: 'keep-all', padding: 30}}>
            {DrawData(data, "", 1)}
        </div>
    );
}