
import axios from 'axios'
import ss from '../../../../Data/Session';

/**
 * @typedef {{
 *  path: string,
 *  destination: string,
 * }} Shortcut
 * */


/**
 * 
 * @returns {Promise<Shortcut[]>}
 */
export const listShortcuts = async () => {
    if ( !ss.getCurrentSession().userid ){
        return [];
    }
    let res = await axios.post('https://challenge-api.webtoon.today/shortener', {readAll: true}, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    }else {
        return [];
    }
}

/**
 * 
 * @param {Shortcut} shortcut
 * @returns {Promise<boolean>}
 */
export const createShortcut = async (shortcut) => {
    if (!ss.getCurrentSession().userid || !shortcut.path || !shortcut.destination){
        return false;
    }
    
    let res = await axios.put('https://challenge-api.webtoon.today/shortener', shortcut, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return true;
    }else{
        return false;
    }
}

/**
 * 
 * @param {Shortcut} shortcut
 * @returns {Promise<boolean>}
 */
export const updateShortcut = async (shortcut) => {
    if (!ss.getCurrentSession().userid || !shortcut.path || !shortcut.destination){
        return false;
    }
    
    let res = await axios.patch('https://challenge-api.webtoon.today/shortener', shortcut, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return true;
    }else{
        return false;
    }
}
