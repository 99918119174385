/**
 * 
 * @param  {...any} props 
 * @return {{any}}
 */
export const MergeAll = (...props) => {
    if (props.length < 1){
        return {};
    }else if (props.length === 1){
        return Object.assign({}, props[0]);
    }else {
        return Object.assign(Object.assign({}, props[0]), MergeAll(...(props.slice(1))))
    }
}

/**
 * 
 * @param {any[][]} param0 
 * @returns {any[]}
 */
export const ConcatAll = ([...props]) => {
    if (props.length === 0){
        return [];
    }else if (props.length === 1){
        return props[0];
    }else{
        return props[0].concat(ConcatAll(props.slice(1)))
    }
}

/**
 * 
 * @param {number} rem 
 * @returns {number} fontSize * rem in pixel
 */
export const pixelize = (rem) => (
    rem * parseFloat(window.getComputedStyle(document.documentElement).fontSize || 16)
);

/**
 * @typedef {{x: number, y: number}} point 
 * @typedef {{width: number, height: number}} size 
 */

/**
 * @param {number} total
 * @param {number} index 
 * @param {point} origin 
 * @param {size} size 
 * @param {number} padding 
 */
export const gridAllocation = (total, index, origin, size, padding) => {
    /** @type {point} */
    
    let grids = [];

    let item_in_a_row = Math.ceil(Math.sqrt(total))
    let center = {x: Math.floor(item_in_a_row/2), y: Math.floor(item_in_a_row/2)};
    
    for (let i = 0; i < item_in_a_row; i++){
        for(let j = 0; j < item_in_a_row; j++){
            grids.push({
                distance: Math.pow(i - center.x, 2) + Math.pow(j - center.y, 2),
                point: {x: i - center.x, y: j - center.y}
            })
        }
    }

    let sorted_grids = grids.sort((a,b) => a.distance - b.distance);
    let cssPoint = {
        left: origin.x + sorted_grids[index].point.x * (size.width + padding),
        top: origin.y + sorted_grids[index].point.y * (size.height + padding)
    }
    
    return MergeAll(cssPoint, size, {padding});
}

/**
 * 
 * @param {object} key 
 * @param {{
 *   validator: (object) => boolean,
 *   sublogic?: logic | object,
 *   defaultValue: object
 * }[]} logic 
 * @param {object} defaultValue
 * @return {object | null}
 */
export const BranchFunction = (key, logic, defaultValue) => {

    if (logic instanceof Array){
        for(const {validator, sublogic, defaultValue} of logic){
            if (validator(key)){
                if (sublogic && sublogic instanceof Array && sublogic.length > 0){
                    return BranchFunction(key, sublogic, defaultValue);
                }else{
                    return defaultValue;
                }
            }else {
            }
        }
    }
    return defaultValue;

}

export const randomString = (length) =>
  new Array(length).fill(0)
    .map((x) => String.fromCharCode(65 + Math.floor(Math.random() * 2) * 32 + Math.floor(Math.random() * 26)))
    .join("");

export const fn = {
    goto: ()=>{},
    gotoByAnchor: (event)=> {
        event.preventDefault();
        /**
         * @type {HTMLElement}
         */
        let target = event.target
        
        while (target.tagName !== 'body' && !target.getAttribute('href')){
            target = target.parentElement
        }
        if (target.tagName === 'body'){
            return;
        }else{
            fn.goto(target.getAttribute('href'));
        }
    }
};

/**
 * 
 * @param {boolean} proxy 
 * @param {string} url 
 */
export const thumbnailize = (url,max_width=0,max_height=0) => (
    !url
        ?url
        :`https://cached-api.webtoon.today/thumb?u=${encodeURIComponent(url)}&agent${max_width>0?`&mw=${max_width*2}`:''}${max_height>0?`&mh=${max_height*2}`:''}`
);
/**
 * 
 * @param {HTMLElement} DOM 
 * @returns {{top: number, left: number, height: number, width: number}}
 */
export const getOffsetInScreen = (DOM) => {
    if (!DOM){
        return {};
    }
    return DOM.getBoundingClientRect();
}

/**
 * 
 * @param {Date} date 
 */
export const dateFormat = (date) =>{
    let todayZeroAM = new Date();
    todayZeroAM.setHours(0,0,0,0);

    if (todayZeroAM - date < 2 * 24 * 60 * 60 * 1000){
        if (todayZeroAM - date < 0 ){
            return `오늘`;
        }else if (todayZeroAM - date < 1 * 24 * 60 * 60 * 1000){
            return `어제`;
        } else {
            return `이틀 전`;
        }
    }
    
    let thisMonthFirst = new Date();
    thisMonthFirst.setHours(0,0,0,0);
    thisMonthFirst.setDate(1);

    if (todayZeroAM - date < (365 - 31) * 24 * 60 * 60 * 1000){
        return `${('00'+(date.getMonth()+1)).substr(-2)}/${('00'+date.getDate()).substr(-2)}`
    }

    return `${date.getFullYear()}`;

}

export const decodeEntities = (str) => {
        // this prevents any overhead from creating the object each time
        let element = document.createElement('div');
    
        const decodeHTMLEntities = (str) => {
            if(str && typeof str === 'string') {
                // strip script/html tags
                str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
                str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
                element.innerHTML = str;
                str = element.textContent;
                element.textContent = '';
            }
        
            return str;
        }
    
        return decodeHTMLEntities(str);
};

/**
 * @typedef T
 * 
 * @param {T} obj 
 * @param {number} index 
 * @param {T[]} arr 
 */
export const unique = (obj, index, arr) => {
    if (index === arr.indexOf(obj)){
        return true;
    }else {
        return false;
    }
}

const PrevResult = {};

export const waitImageLoaded = (imageUrl) => {
    if (imageUrl in PrevResult){
        return PrevResult[imageUrl];
    }

    let smallImage = document.createElement('img');
    smallImage.setAttribute('style', 'width:1px; opacity:0; position:absolute;');
    const imagePromise = new Promise((resolve,reject) => {
        smallImage.onload = () => {
            PrevResult[imageUrl] = {width: smallImage.naturalWidth || 16, height: smallImage.naturalHeight || 9};
            resolve({width: smallImage.naturalWidth || 16, height: smallImage.naturalHeight || 9})
            document.body.removeChild(smallImage)
        }
        smallImage.onerror = (error) => {
            reject(error)
            document.body.removeChild(smallImage)
        }
    })
    document.body.appendChild(smallImage);
    smallImage.setAttribute('src', imageUrl);

    return imagePromise;
}

export const parseUrl = (url) => {
    const [fullUrl, scheme, domain, path, query, hash] = /(https|http):\/\/([^/]+)(\/?[^?]+)?\??([^#]+)?(#?.+)?$/.exec(url)
    return {
        fullUrl, scheme, domain, path, query: Object.fromEntries(query.split('&').map(pair => pair.split('=').map(term => decodeURIComponent(term)))), hash
    }
}

/**
 * 
 * @param { string } a 
 * @param { string } b 
 * @returns { 1 | -1 | 0 }
 * @description {@link https://gist.github.com/flyskyne/b6b310187ec26581f7ee548656473e72}의 오픈소스를 참고하였습니다.
 */
export const koreanFirstSort = (a, b) => {

    const addOrderPrefix = (text) => {
            
        const code = text.toLowerCase().charCodeAt(0);
        let prefix = "";

        if (0xac00 <= code && code <= 0xd7af) {prefix = '1'} 
        else if (0x3130 <= code && code <= 0x318f) {prefix = '2'} 
        else if (0x61 <= code && code <= 0x7a) {prefix = '3'} 
        else {prefix = '9'}

        return prefix + text;
    }
    
    a = addOrderPrefix(a);
    b = addOrderPrefix(b);

    if (a < b) {return -1}
    if (a > b) {return 1}
    return 0;
}

/**
 * 
 * @param {string} syllable 
 * @returns {string}
 * @description {@link https://taegon.kim/archives/9919}의 오픈소스를 참고하였습니다.
 */
const syllablePattern = (syllable) => {
    const offset = 44032;

    if(/[가-힣]/.test(syllable)){
        const syllableCode = syllable.charCodeAt(0) - offset;

        if ( syllableCode % 28 > 0 ) {
            return syllable;
        }

        const begin = Math.floor(syllableCode / 28) * 28 + offset;
        const end = begin + 27;
        return `[\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
    }

    if (/[ㄱ-ㅎ]/.test(syllable)) {

        const vowelToSyllable = {
            ㄱ: "가".charCodeAt(0),
            ㄲ: "까".charCodeAt(0),
            ㄴ: "나".charCodeAt(0),
            ㄷ: "다".charCodeAt(0),
            ㄸ: "따".charCodeAt(0),
            ㄹ: "라".charCodeAt(0),
            ㅁ: "마".charCodeAt(0),
            ㅂ: "바".charCodeAt(0),
            ㅃ: "빠".charCodeAt(0),
            ㅅ: "사".charCodeAt(0)
        };

        const begin =
            vowelToSyllable[syllable] ||
            (syllable.charCodeAt(0) - 12613) * 588 + vowelToSyllable["ㅅ"];
        const end = begin + 587;

        return `[${syllable}\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
    }

    return syllable;
}

/**
 * 
 * @param {string} input 
 * @returns {RegExp}
 * @description {@link https://taegon.kim/archives/9919}의 오픈소스를 참고하였습니다.
 */
export const createFuzzyMatcher = (input) => {
    const pattern = input.split('').map(character => { return syllablePattern(character) }).join('.*?');
    
    return new RegExp(pattern);
}


export const validateEmailForm = (infomation) => {
        
    const regExp = /^([-_.+0-9a-zA-Z])*@([-_.0-9a-zA-Z])*\.[a-zA-Z]{2,10}$/i;

    if (regExp.test(infomation)) {

        return true;

    } else {

        return false;
    }

}