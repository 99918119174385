import axios from 'axios';
import ss from './Session';

export async function readSurvey (surveyId) {
    try {
        let res = await axios.get(`https://api.webtoon.today/survey?surveyId=${surveyId}`, {
            headers: {  
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            return res.data.data;
        } else {
            return [];

        }
    }catch(e){
        console.error("couldn't read survey results!",e);
    }
}


export async function sendSurveyResponse ({surveyId, data}) {
    
    try{
        let res = await axios.put(`https://api.webtoon.today/survey`, {surveyId, data} , {
            headers: {  
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })
        
        if (res.data && res.data.code === 200){
            return true;

        } else {
            return false;

        }
    }catch(e){
        return false;
    }
}