import React from "react";
import {readSurveyForm, createOrUpdateSurveyForm} from './Data/SurveyForm';
import SurveyFormView from './SurveyFormView';

import "./SurveyEditor.scss";
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, MenuItem, Paper, Select, TextField } from "@material-ui/core";
import { Add, CheckBox, Close, RadioButtonChecked } from "@material-ui/icons";

import {randomString} from '../../../Functions';

/**
 * @typedef {import("./Data/SurveyForm").Question} Question
 * @typedef {Question[]} Questionaire
 */

const makeAQuestion = () => {
    return {key: randomString(6), title: '', subtitle: '', type: 'subjective', indent: 0, isRequired: false};
}

const tryParse = (json, defaultValue) => {
    try{
        return JSON.parse(json);
    }catch(e){
        return defaultValue;
    }
}

const SurveyEditor = ({surveyId = '2022univ'}) => {

    const [askingOptionalClauseFor, setAskingOptionalClauseFor] = React.useState(null);
    /** @type {[Questionaire, React.Dispatch<React.SetStateAction<Questionaire>>]} */ 
    const [questionaire, setQuestionaire] = React.useState(([makeAQuestion()]));
    React.useEffect(()=>{
        (async()=>{
            let {data} = await readSurveyForm(surveyId)

            if (data.length > 0){
                setQuestionaire(tryParse(data, []));
            }else{
                setQuestionaire([makeAQuestion()]);
            }
        })();
    },[surveyId]);

    /**
     * @param {Question} row 
     * @param {number} idx 
     */
    const changeQuestionaireRow = (row, idx) => {
        setQuestionaire([
            ...questionaire.slice(0,idx),
            row,
            ...questionaire.slice(idx+1)
        ]);
    }

    /**
     * @param {number} idx 
     */
    const removeQuestionaireRow = (idx) => {
        setQuestionaire([
            ...questionaire.slice(0,idx),
            ...questionaire.slice(idx+1)
        ]);
    }
    
    /**
     * @param {Question} row 
     * @param {number} idx 
     */
    const insertQuestionaireRow = (row,idx) => {
        setQuestionaire([
            ...questionaire.slice(0,idx),
            row,
            ...questionaire.slice(idx)
        ]);
    }

    return (<div className={"SurveyEditor"}>
        <div className={"BigColumn"}>
            <div style={{margin: '0 10px', position: 'sticky', top: 0, backgroundColor: 'white', zIndex:1}}>
                <Button fullWidth onClick={()=>{ createOrUpdateSurveyForm(surveyId, questionaire)}}>{"저장"}</Button>
            </div>
            {questionaire.map( (row,index) =>
            <div key={row.key}>
                <Paper className={"QuestionCell"}>
                    <div className={"Row"} style={{justifyContent:'flex-end'}}>
                        <IconButton size={"small"} onClick={()=>{removeQuestionaireRow(index)}}><Close/></IconButton>
                    </div>
                    <div className={"Row"}>
                        <Select fullWidth value={row.type} onChange={event => changeQuestionaireRow({...row, type: event.target.value}, index)}>
                            <MenuItem value={"notice"} key={"notice"}>{"공지"}</MenuItem>
                            <MenuItem value={"subjective"} key={"subjective"}>{"주관식"}</MenuItem>
                            <MenuItem value={"range"} key={"range"}>{"범위형"}</MenuItem>
                            <MenuItem value={"single"} key={"single"}>{"객관식(택일)"}</MenuItem>
                            <MenuItem value={"multiple"} key={"multiple"}>{"객관식(다중)"}</MenuItem>
                        </Select>
                        <Select fullWidth value={row.indent || 0} onChange={(e) => changeQuestionaireRow({...row, indent: e.target.value}, index)}>
                            <MenuItem value={0}>{"탭0"}</MenuItem>
                            <MenuItem value={1}>{"탭1"}</MenuItem>
                            <MenuItem value={2}>{"탭2"}</MenuItem>
                            <MenuItem value={3}>{"탭3"}</MenuItem>
                        </Select>
                        <FormControlLabel
                            label={<span style={{whiteSpace: 'nowrap'}}>{"필수"}</span>}
                            control={<Checkbox size={"small"} style={{padding: 5}} checked={row.isRequired} onChange={(event, checked) => changeQuestionaireRow({...row, isRequired: checked}, index)}/>}
                        />
                    </div>
                    <TextField className="Row" label={"질문"} value={row.title}    onChange={event => changeQuestionaireRow({...row, title: event.target.value}, index)} fullWidth />
                    <TextField className="Row" label={"설명"} value={row.subtitle} onChange={event => changeQuestionaireRow({...row, subtitle: event.target.value}, index)} fullWidth multiline maxRows={5} />
                    {(row.type === 'single' || row.type === 'multiple')?<div className={"Row"}>
                        <div className={"Label"}>
                            {"옵션"}<br/>
                            {<IconButton size={"small"} onClick={(event) => changeQuestionaireRow({...row, isToggle: !row.isToggle}, index)}>{row.isToggle?<CheckBox/>:<RadioButtonChecked/>}</IconButton>}
                        </div>
                        <TextField
                            elevation={2} className="Col Depth"
                            label={"엔터키로 옵션 추가"}
                            value={row.options?.join('\n')}
                            onChange={event => changeQuestionaireRow({...row, options: event.target.value.split('\n').filter( (row, i, arr) => row || i === arr.length - 1)}, index)}
                            multiline maxRows={10} fullWidth
                        >
                        </TextField>
                    </div>:<></>}
                    {(row.type === 'multiple' && !row.isToggle)?<div className="Row">
                        <TextField label={"항목1 이름"} value={row.caption_true || ""}  onChange={event => changeQuestionaireRow({...row, caption_true: event.target.value}, index)} fullWidth />
                        <TextField label={"항목2 이름"} value={row.caption_false || ""} onChange={event => changeQuestionaireRow({...row, caption_false: event.target.value}, index)} fullWidth />
                    </div>:<></>}
                    {(row.type === 'range')?<div className="Row">
                        <TextField label={"항목1 이름"} value={row.caption_min || ""}  onChange={event => changeQuestionaireRow({...row, caption_min: event.target.value}, index)} fullWidth />
                        <TextField label={"항목2 이름"} value={row.caption_max || ""} onChange={event => changeQuestionaireRow({...row, caption_max: event.target.value}, index)} fullWidth />
                    </div>:<></>}
                    {!!row.optional_clause
                    ?<div style={{fontSize:'0.9rem'}}>
                        <div>
                            <h3 >{"노출 기준 문항"}</h3>{`${row.optional_clause.key}: ${questionaire.filter(qu => qu.key === row.optional_clause.key)[0]?.title}`}
                            <IconButton size={"small"} onClick={()=> changeQuestionaireRow({...row, optional_clause: null}, index)}><Close/></IconButton>
                        </div>
                        <div><h3 >{"노출 기준 답변"}</h3>{row.optional_clause.values?.join(' / ')}</div>
                    </div>:<div style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap', alignItems: 'center', justifyContent:'space-between'}}>
                        <Button variant="outlined" onClick={()=>setAskingOptionalClauseFor({...row, optional_clause: row.optional_clause || {key: null, values: []}})} fullWidth>{"노출 조건 부여"}</Button>
                    </div>}
                    
                </Paper>
                <div className="AddAQuestion" onClick={()=>insertQuestionaireRow(makeAQuestion(), index+1)}>
                    <Add/>
                </div>
            </div>)}
        </div>
        <SurveyFormView className={"BigColumn"} questionaire={questionaire}/>
        <AskingDialog 
            askingOptionalClauseFor={askingOptionalClauseFor}
            setAskingOptionalClauseFor={setAskingOptionalClauseFor}
            questionaire={questionaire}
            updateQuestion={ (question) => changeQuestionaireRow(question, questionaire.map(row => row.key).indexOf(question.key)) }
        />
    </div>);
}

/**
 * @param {{
 *   askingOptionalClauseFor: Question,
 *   setAskingOptionalClauseFor: (Question) => void,
 *   questionaire: Questionaire
 * }} param0 
 */
const AskingDialog = ({askingOptionalClauseFor, setAskingOptionalClauseFor, questionaire, updateQuestion}) => {

    const [targetKey, setTargetKey] = React.useState(askingOptionalClauseFor?.optional_clause?.key || null);

    return (
    <Dialog open={!!askingOptionalClauseFor} onClose={()=>setAskingOptionalClauseFor(null)}>
        <DialogTitle>{"조건문 부여"}</DialogTitle>
        <DialogContent>
        {askingOptionalClauseFor?.optional_clause?<>
            <div style={{width:500, display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <label style={{whiteSpace:'nowrap'}}>{"기준문항"}</label>
                <Select value={targetKey || ""} onChange={(e)=>setTargetKey(e.target.value)} fullWidth>
                    {questionaire.filter(row =>
                        row.key !== askingOptionalClauseFor.key
                        && ['notice', 'subjective'].indexOf(row.type) < 0
                    ).map(row =>
                        <MenuItem value={row.key} key={row.key} ><div style={{textOverflow: 'ellipsis', overflowX:'hidden', width: 400}}>{`${row.key}: ${row.title}`}</div></MenuItem>
                    )}
                </Select>
            </div>
            <div style={{width:500, display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <label style={{whiteSpace:'nowrap'}}>{"허용답변"}</label>
                <div>
                    {(() => {
                        const target = questionaire.filter(row => row.key === targetKey)[0];

                        if (!target){
                            return <></>;
                        }else if (target.type === 'range') {
                            let min = Number((target.range || [])[0]);
                            min = Number.isNaN(min)?0:min;
                            let max = Number((target.range || [])[1]);
                            max = Number.isNaN(max)?10:max;
                            return <>
                                {Array(max - min + 1).fill(null).map((_, i) => 
                                    <Chip
                                        key={i+min}
                                        style={{margin: 5}}
                                        color={askingOptionalClauseFor.optional_clause.values.indexOf(i+min)>=0?'primary':'default'}
                                        label={i+min}
                                        onClick={()=>setAskingOptionalClauseFor({
                                            ...askingOptionalClauseFor,
                                            optional_clause: {
                                                key: targetKey,
                                                values: askingOptionalClauseFor.optional_clause.values.indexOf(i+min)>=0
                                                    ?askingOptionalClauseFor.optional_clause.values.filter(row => row !== i+min)
                                                    :askingOptionalClauseFor.optional_clause.values.concat(i+min)
                                            }
                                        })}
                                    />
                                )}
                            </>;
                        }else {
                            return <>
                                {target.options?.map((v, i) => 
                                    <Chip
                                        key={v}
                                        style={{margin: 5}}
                                        color={askingOptionalClauseFor.optional_clause.values.indexOf(v)>=0?'primary':'default'}
                                        label={v}
                                        onClick={()=>setAskingOptionalClauseFor({
                                            ...askingOptionalClauseFor,
                                            optional_clause: {
                                                key: targetKey,
                                                values: askingOptionalClauseFor.optional_clause.values.indexOf(v)>=0
                                                    ?askingOptionalClauseFor.optional_clause.values.filter(row => row !== v)
                                                    :askingOptionalClauseFor.optional_clause.values.concat(v)
                                            }
                                        })}
                                    />
                                )}
                            </>
                        }
                    })()}
                </div>
            </div>
        </>:<></>}
        </DialogContent>
        <DialogActions>
        {askingOptionalClauseFor?.optional_clause?<>
            <Button color={"primary"}  variant="contained" style={{color: 'white'}}
                disabled={(clause => !clause || !clause.key || clause.values === undefined || clause.values.length === 0)(askingOptionalClauseFor.optional_clause)}
                onClick={()=>{updateQuestion(askingOptionalClauseFor); setAskingOptionalClauseFor(null)}}
            >{"저장"}</Button>
            <Button color={"secondary"} onClick={()=>{setAskingOptionalClauseFor(null)}}>{"취소"}</Button>
        </>:<></>}
        </DialogActions>
    </Dialog>);

}

export default SurveyEditor;