import { Button, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { fn } from "../../../Functions";
import './Insight.scss';

const Insight = () => {

    const [serviceId, setServiceId] = useState('');
    const [titleId, setTitleId] = useState(0);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!serviceId || !titleId){
            return;
        }

        fn.goto(`/challenge/insight/${serviceId}/${titleId}`)
    }

    return (
        <div className={"InsightSearch"}>
            <form className={'TitleForm'} onSubmit={handleSubmit}>
                <TextField 
                    label={'serviceId를 입력해주세요. (예: naver, kakao)'} required  
                    onChange={(event)=>setServiceId(event.target.value)}
                />
                <TextField 
                    label={'titleId를 숫자로 입력해주세요. (예:61143224)'} type='number' required  
                    onChange={(event)=>setTitleId(event.target.value)}
                />
                <Button type={'submit'}>{'작품으로 이동하기'}</Button>
            </form>
        </div>
    );
}


export default Insight;