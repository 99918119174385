import React from 'react';
import Input from '@material-ui/core/Input';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import { createImageWithS3, getSizeOf } from '../../../Data/Image';
import { Delete as DeleteIcon, Visibility as VisibilityIcon } from '@material-ui/icons';

import { Dialog } from '@material-ui/core';
import { randomString } from '../../../Functions';

/**
 * 
 * @param {{
 *  isEditing: true|false,
 *  defaultValue: any,
 *  field: string,
 *  update: () => void,
 *  onLoad: (dom: DOMElement) => void,
 *  style: React.StyleHTMLAttributes,
 *  buttonStyle: React.StyleHTMLAttributes,
 *  accept: string,
 * }} props 
 */
const SubmitFileField = ({
    isEditing: givenIsEditing, defaultValue, field,
    update, onLoad, loading,
    className, style, buttonStyle,
    accept, isVisible=false, disabled=false,
    innertext, altertext=""
}) => {

    const [uniqueId,] = React.useState(`ImageHolder_${randomString(20)}`);

    const [isEditing, setIsEditing] = React.useState(givenIsEditing || "");
    React.useEffect(()=>{
        setIsEditing(isEditing);
    },[isEditing]);
    
    const [value, setValue] = React.useState(defaultValue);
    React.useEffect(()=>{
        setValue(defaultValue);
    },[defaultValue]);
    
    const onImagePutIn = React.useMemo(()=> async (event)=> {

        loading(true)

        let orgFilename = event.target.files[0].name

        try{
            event.target.setAttribute('disabled', true);
            let filename = await createImageWithS3(event.target, (event)={
                //setProgress(event.loaded / event.)
            });

            let newValue = {};
            newValue[field] = filename;
            setValue(filename);

            let fileSize = await getSizeOf(filename.replace("https://static.webtoon.today/",""));

            if (fileSize.ContentLength === 0){
                throw(new Error("빈파일이 업로드 됨"));
            }

            await update(newValue, orgFilename)
            
        }catch(e){
            console.error(e)
            if (document.getElementById(uniqueId)){
                document.getElementById(uniqueId).removeAttribute('disabled');
                document.getElementById(uniqueId).files = [];
            }
            setValue(defaultValue);
            await update([null, null], undefined);
            alert("파일 업로드 중 문제가 발생했습니다. 다시 시도해 주세요.: "+e.message);
        }

        loading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field, uniqueId, update]);

    const onImageDragIn = async (event) => {

        loading(true)

        let orgFilename = event.dataTransfer.files[0].name

        const target = document.getElementById(uniqueId)
        target.files = event.dataTransfer.files;

        try{
            event.target.setAttribute('disabled', true);
            let filename = await createImageWithS3(target, (event)={
                //setProgress(event.loaded / event.)
            });

            let newValue = {};
            newValue[field] = filename;
            setValue(filename);

            let fileSize = await getSizeOf(filename.replace("https://static.webtoon.today/",""));

            if (fileSize.ContentLength === 0){
                throw(new Error("빈파일이 업로드 됨"));
            }

            await update(newValue, orgFilename)
            
        } catch(e) {
            console.error(e)
            if (document.getElementById(uniqueId)){
                document.getElementById(uniqueId).removeAttribute('disabled');
                document.getElementById(uniqueId).files = [];
            }
            setValue(defaultValue);
            await update([null, null], undefined);
            alert("파일 업로드 중 문제가 발생했습니다. 다시 시도해 주세요.: "+e.message);
        }

        loading(false)
    }
    
    const [ modalOpen, setModalOpen ] = React.useState(false);

    const openModal = () => {
        setModalOpen(true)
    }
    const closeModal = () => {
        setModalOpen(false);
    }

    const [isDragging, setIsDragging] = React.useState(false);
    
    return (<>
        {!isEditing
            ?(!value || ['jpg', 'jpeg', 'png', 'svg', 'gif'].indexOf(`.${value}`.split('.').reverse()[0]) >=0)
                ?<div className={className} style={{position: 'relative', overflow: 'hidden', backgroundImage: `url('${value || "https://static.webtoon.today/noimage.jpg"}')`, backgroundSize: 'cover', backgroundPosition: 'center', ...style}}>
                    <img
                        style={{
                            width: '100%', height: '100%',
                            objectFit: 'contain', backdropFilter: 'blur(10px) brightness(0.8)'
                        }}
                        target={"blank"}
                        src={value || "https://static.webtoon.today/noimage.jpg"} alt={value}
                        onLoad={onLoad || (()=>{})} onError={()=>{
                            if (this) {
                                this.src="https://static.webtoon.today/noimage.jpg"
                            }
                        }}
                    />
                </div>
                :['zip'].indexOf(`.${value}`.split('.').reverse()[0]) >=0
                    ?<img style={{width: '100%', height: '100%', objectFit: 'contain'}} target={"blank"} src={'https://static.webtoon.today/loading.gif'} alt={'https://static.webtoon.today/loading.gif'} onLoad={onLoad || (()=>{})}/>
                    :<div className={"SlideThumb"}>{`.${value}`.split('.').reverse()[0]}</div>
            :value
                ?<div className={className} style={{display: 'block', position: 'relative', overflow: 'hidden', ...style}}>
                    <Tooltip title={altertext}>
                        {(value && ['jpg', 'jpeg', 'png', 'svg', 'gif'].indexOf(`.${value}`.split('.').reverse()[0]) >=0)
                        ?<img
                            style={{
                                width: '100%', height: '100%',
                                objectFit: 'contain',
                            }}
                            target={"blank"}
                            src={value} alt={value}
                            onLoad={onLoad || (()=>{})}
                        />
                        :['zip'].indexOf(`.${value}`.split('.').reverse()[0]) >=0
                            ?<img style={{width: '100%', height: '100%', objectFit: 'contain'}} target={"blank"} src={'https://static.webtoon.today/ddah/zipIcon.png'} alt={'https://static.webtoon.today/loading.gif'} onLoad={onLoad || (()=>{})}/>
                            :<div className={"SlideThumb"}>{`.${value}`.split('.').reverse()[0]}</div>
                        }
                    </Tooltip>
                    <IconButton
                        style={{position: 'absolute', top:-5, right:-5, width: 35, height: 35, border:'1px lightgray solid', backgroundColor: 'rgba(255,255,255,0.5)'}}
                        disabled={disabled}
                        onClick={async ()=>{
                            let newValue = {};
                            newValue[field] = null;
                            setValue(null);
                            await update(newValue)
                        }}
                    >
                        <DeleteIcon style={{fontSize: '1rem'}}/>
                    </IconButton>
                    {isVisible
                    ?<div>
                        <IconButton
                            style={{position: 'absolute', bottom:-5, right:-5, width: 35, height: 35, border:'1px lightgray solid', backgroundColor: 'rgba(255,255,255,0.5)'}}
                            disabled={disabled}
                            onClick={async ()=>{
                                openModal()
                            }}
                        >
                            <VisibilityIcon style={{fontSize: '1rem'}}/>
                        </IconButton>
                        <Dialog open={ modalOpen } onClose={ closeModal } >
                            {([value || ""].map( (slide, idx) => 
                                <div key={`Modal${idx}`}>
                                    <img
                                        style={{
                                            width: '280px', height: '320px',
                                            objectFit: 'contain',
                                        }}
                                        target={"blank"}
                                        src={!(value.slice(-3) === 'zip')?slide:`https://static.webtoon.today/ddah/zipIcon.png`}
                                        alt={"압축파일입니다."}
                                        className={"ModalImages"}
                                    />
                                </div>
                            ))}
                        </Dialog>
                    </div>
                    :<></>}
                </div>
                :<>
                    <Button variant={"outlined"} className={`AddNewSlide SlideThumb ${isDragging?'Dragging':''}`} style={buttonStyle} disabled={disabled} onClick={()=>{
                        document.getElementById(uniqueId).click();
                    }}
                    onDragOver={(event)=>{
                        event.preventDefault();
                        event.stopPropagation();

                        setIsDragging(true)
                    }}
                    onDrop={(event)=>{
                        event.preventDefault();
                        event.stopPropagation();
                        onImageDragIn(event)

                        setIsDragging(false)
                    }}
                    onDragLeave={(event)=>{
                        event.preventDefault();
                        event.stopPropagation();

                        setIsDragging(false)
                    }}
                    >
                        {innertext?innertext:"+"}
                    </Button>
                    <Input
                        id={uniqueId}
                        disabled={disabled}
                        className={className}
                        type={"file"}
                        style={{width:1,height:1, display:'none'}}
                        onChange={onImagePutIn}
                        name={"file"}
                        inputProps={{
                            accept: accept || ".gif,.jpg,.jpeg,.png,.svg"
                        }}
                    >
                    </Input>
                </>
        }
        </>
        );
    }

export default SubmitFileField;
