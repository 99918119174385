import React from 'react';
import { TextField } from '@material-ui/core';

const EditableField = (props) => {

    const [value, setValue] = React.useState(props.defaultValue);

    React.useEffect(()=>{
        setValue(props.defaultValue);
    },[props]);

    if (props.isEditing){
        return (
            <TextField
                style={props.style || {}}
                fullWidth={false}
                value={value || ""}
                onChange={(event)=>setValue(event.target.value)}
                onKeyDown={(event)=> {
                    if (event.key === "Enter") {
                        props.update({[props.field]: event.target.value})
                    };
                }}
                onBlur={(event)=> {
                    props.update({[props.field]: event.target.value});
                }}
            />
        );
    }

    return <div style={props.style}>{props.defaultValue}</div>;
}

export default EditableField;