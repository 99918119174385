import { Button, FormControlLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import React from "react";

import './SingleChoice.scss';

/**
 * 
 * @param {{
 *   options: string[],
 *   response: string | null,
 *   setResponse: (response:string) => void,
 *   title: string, subtitle: string, isRequired: boolean, isToggle: boolean,
 *   allowOtherOption: boolean,
 * }} param 
 */
const SingleChoice = ({
    options=[],
    response, setResponse,
    title = "제목", subtitle= null,
    isRequired = false,
    isToggle = false,
    allowOtherOption = false,
}) => {
    const [isOpenedOtherOption, setIsOpenedOtherOption] = React.useState(false);
    const [otherResponse, setOtherResponse] = React.useState("");

    if (isToggle) {
        return (<div className="MultipleChoice QuestionBox">
            <div className={"Title"}>{title}{isRequired?<span className={"RedAsterisk"}>{" *"}</span>:<></>}</div>
            {subtitle?<div className={"Subtitle"}>{subtitle}</div>:<></>}
            {options.map((option, index) =>
                <Button fullWidth key={option}
                    className={"SubjectiveButton"}
                    variant={response === option
                        ?"contained":"outlined"}
                    color={"primary"}
                    onClick={()=>{
                        if ( response === option ){
                            setResponse(null);
                        }else {
                            setResponse(option);
                        }
                        setOtherResponse(null);
                        setIsOpenedOtherOption(false);
                    }}
                >
                    {option}
                </Button>)}
                {!allowOtherOption?<></>
                :isOpenedOtherOption
                    ?<TextField
                        fullWidth
                        value={otherResponse}
                        onChange={(event)=>{
                            setOtherResponse(event.target.value)
                        }}
                        onKeyDown={(event)=>{
                            if(event.key === 'Enter'){
                                event.target.blur();
                            }
                        }}
                        onBlur={()=>{
                            setResponse(otherResponse)
                        }}
                    />
                    :<Button fullWidth
                        className={"SubjectiveButton"}
                        variant={"outlined"}
                        color={"primary"}
                        onClick={()=>{
                            setIsOpenedOtherOption(true);
                            setResponse(null);
                        }}
                    >
                        {"기타"}
                    </Button>}
        </div>);

    }else{
        return (<div className="MultipleChoice QuestionBox">
            <div className={"Title"}>{title}{isRequired?<span className={"RedAsterisk"}>{" *"}</span>:<></>}</div>
            {subtitle?<div className={"Subtitle"}>{subtitle}</div>:<></>}
            <RadioGroup className={"HorizontalRadioGroup"}
                value={response}
                onChange={(event, value)=>{
                    setResponse(value)
                }}
            >
                {options.map( (option)=> 
                    <FormControlLabel
                        key={`Radio${option}`}
                        className={"RadioCell"}
                        control={<Radio className={"Radio"}/>}
                        label={`${option}`}
                        labelPlacement={"top"}
                        value={option}
                    />)}
            </RadioGroup>
    
        </div>);

    }

}

export default SingleChoice;