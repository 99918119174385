import { useState, useEffect, useMemo } from "react";
import { FolderOpen, InsertDriveFileOutlined, SearchOutlined, RefreshOutlined, GetAppOutlined, ArrowBackIosOutlined, ArrowForwardIosOutlined } from "@material-ui/icons";
import { listObjects, getObjectDownloadURL, uploadFileToS3 } from "./Data/Cloud";
import "./Cloud.scss";
import { randomString } from "../../../Functions";

import { Button, Input } from "@material-ui/core";

import {fn} from '../../../Functions';

const numberWith2DecimalPart = (n) => {
    const parts = `${n}`.split('.');
    if (!parts[1] || parts[1].length === 0){
        return parts[0];
    }
    return `${parts[0]}.${parts[1].substring(0,2)}`;
}

const Cloud = ({hash}) => {
    const [ prefix, setPrefix ] = useState(decodeURIComponent(hash || '/').slice(1).replace(/\+/g," "));
    useEffect(()=>{
        setPrefix(decodeURIComponent(hash || '/').slice(1).replace(/\+/g," "));
    },[hash])

    const [ objectList, setObjectList ] = useState([]);
    const [ page, setPage ] = useState(1);
    const tableObjectList = objectList.slice(300*(page-1),300*page);
    console.log(tableObjectList)
    const [ isTruncated, setIsTruncated ] = useState(true);

    const [ files, setFiles ] = useState(null);

    const uniqueId = useMemo(()=>randomString(32),[]);

    const [ selectedIdx, setSelectedIdx ] = useState(null);

    useEffect(()=>{
        document.title = `공유 드라이브`;
    },[]);

    useEffect(()=>{
        (async() => {
            const result = await listObjects({bucket: 'cloud.webtoon.today', maxKeys: 300, prefix: prefix});
            setObjectList(result);
            setIsTruncated(result.isTruncated);

            if(result.isTruncated){
                const addtionalObject = await listObjects({bucket: 'cloud.webtoon.today', maxKeys: 1000, prefix});
                setObjectList(addtionalObject);
                setIsTruncated(addtionalObject.isTruncated);
            }
        })();
    },[prefix]);

    useEffect(()=>{
        setSelectedIdx(null);

        const getAdditionalObject = async () => {
            const addtionalObject = await listObjects({bucket: 'cloud.webtoon.today', maxKeys: 1000, prefix});
            setObjectList(addtionalObject);
            setIsTruncated(addtionalObject.isTruncated);
        }
        
        if(page !== 1 && page >= Math.ceil(objectList.length/300)){
            (async()=>await getAdditionalObject())();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page])

    const formatExtension = (fileName) => {

        if(!fileName){
            return '-';
        }

        const tmp = fileName.split('.');
        const tmpFile = fileName.split('/');

        if(tmp.length > 1){
            return tmp.pop();
        }

        if(tmpFile.length > 1){
            return 'Folder';
        }

        return '-';
    }

    const formatBytes = (bytes, decimals = 2) => {
        if (!+bytes) return '0 Bytes'
    
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    const paginationFormat = (totalPage, selectedPage, isTruncated) => {
       
        let paginationList = new Array(totalPage).fill(0).map((_, idx)=>idx+1);

        if(selectedPage>=6){
            for(let i=2;i<=selectedPage-2-((totalPage-selectedPage<3)?3-(totalPage-selectedPage):0);i++){
                const idx = paginationList.indexOf(i);
                if(idx>-1) paginationList.splice(idx, 1);
            }
            paginationList.splice(1,0,'...');

        }

        if(totalPage-selectedPage>=4){
            paginationList = paginationList.slice(0,-(totalPage-selectedPage-3));
        }

        if(isTruncated){
            paginationList.push('...');
        }

        return paginationList;
    }

    const interleave = (arr, thing) => [].concat(...arr.map(n => [n, thing])).slice(0, -1)

    return (
    <div style={{backgroundColor:'#f2f3f3'}}>
        <div className={'BucketRoute'}>
            {
                interleave(['cloud.webtoon.today',...prefix.split('/')].filter(row=>row!==''),'ArrowForwardIosOutlined').map(row=>{
                    if(row==='ArrowForwardIosOutlined'){
                        return (<div><ArrowForwardIosOutlined style={{paddingTop: '3px', display: 'flex', alignItems: 'center', width: '15px', height: '15px'}} /></div>);
                    }else{
                        return (<div style={{cursor:'pointer'}} onClick={()=>{
                            const parsedPrefix = prefix.split('/');
                            if(row === 'cloud.webtoon.today'){
                                fn.goto(`/challenge/cloud`);
                            }else{
                                fn.goto(`/challenge/cloud/#/${parsedPrefix.slice(0,parsedPrefix.indexOf(row)+1).join('/')+'/'}`);
                            }
                        }}>{row}</div>);
                    }
                })
            }
        </div>
        <div className={'BucketName'}>
            cloud.webtoon.today
        </div>
        <div className={'DropZone'}
            onClick={()=>{
                document.getElementById(uniqueId)?.click();
            }}
            onDragOver={(event)=>{event.preventDefault();}}
            onDrop={(event)=>{
                event.preventDefault();
                (document.getElementById(uniqueId)).files = event.dataTransfer?.files;
                setFiles(event.dataTransfer?.files);
            }}
        >
        <Input fullWidth type={'file'} onChange={(e)=>setFiles(e.target.files)}
            inputProps={{id: uniqueId, webkitdirectory: '', mozdirectory: '', directory: '', multiple: true}}
        />
        {files && files.length>0?<div style={{width:'calc(100% - 20px)', margin:'10px'}}><table style={{width:'100%'}}>
        <thead>
            <tr style={{textAlign:'center', position: 'sticky', top: 0, backgroundColor: 'white'}}>
                <td style={{borderRight: '1px lightgray solid', width: '30%'}}>{"폴더이름"}</td>
                <td style={{borderRight: '1px lightgray solid', width: '50%'}}>{"파일이름"}</td>
                <td>{"파일크기"}</td>
            </tr>
        </thead>
        <tbody>
        {Array(files?.length).fill(null).map( (_,i) => files[i]).sort().map( (file) =>
            <tr key={file.webkitRelativePath || file.name}>
                <td style={{whiteSpace:'nowrap', padding: '0 10px'}}>
                    {(name => name.length<15?name:name.substring(0,5)+'...'+name.substring(name.length-7))(file.webkitRelativePath.split('/').slice(0,-1).join('/'))}
                </td>
                <td style={{textAlign:'right', padding: '0 10px'}}>
                    {(name => name.length<15?name:name.substring(0,5)+'...'+name.substring(name.length-7))(file.name)}
                </td>
                <td style={{whiteSpace: 'nowrap', textAlign:'right', padding: '0 10px'}}>
                    {file.size > 1024 * 1024
                        ?`${numberWith2DecimalPart(file.size / 1024 / 1024)} MB`
                        :`${numberWith2DecimalPart(file.size        / 1024)} KB`}
                </td>
            </tr>
        
        )}</tbody></table></div>
        :<div style={{width:'100%', height: '100%', display:'flex', justifyContent:'center', alignItems:' center'}}>
            <div style={{color: 'black', textAlign: 'center', fontSize:'1.2rem'}}>
                {"파일 업로드"}<br/>
                <div style={{textAlign: 'center', color: 'rgb(144,144,144)', fontSize: '0.9rem', marginTop: 20}}>
                    {".jpg, .jpeg, .png, .gif 등 이미지가 담긴 폴더를 선택해주세요."}
                </div>
            </div>
        </div>}
        </div>
        {files && files.length>0?<div className={'UploadButton'}>
            <Button style={{backgroundColor: 'rgb(0,176,240)', marginLeft: 'auto', marginRight: '5px'}} onClick={()=>{
                (async()=>{
                    for(let file of files){
                        (async()=>{
                            await uploadFileToS3({bucket: 'cloud.webtoon.today', key: prefix+(file.webkitRelativePath?file.webkitRelativePath:file.name), file});
                        })()
                    }
                })();
            }}>업로드</Button>
        </div>:<></>}
        <div className={'ObjectTable'} style={{backgroundColor: '#FAFAFA'}}>
            <div className={'TableHeaderArea'}>
                <div className={'ObjectCountArea'}>
                    <span>Objects </span><span className={'Count'}>{`(${(objectList.length>=1000)?`999+`:`${objectList.length}`})`}</span>
                </div>
                <div className={'ButtonArea'} style={{display: 'flex'}}>
                    <button className={'Button Enabled'} placeholder={'Refresh'} type={'button'} onClick={()=>{
                        window.location.reload();
                    }}>
                        <RefreshOutlined style={{display: 'flex'}}/>
                    </button>
                
                    <button className={`Button ${selectedIdx!==null?`Enabled`:`Disabled`}`} placeholder={'Refresh'} type={'button'} style={{display: 'flex'}} onClick={()=>{
                        (async()=> {
                            let result = await getObjectDownloadURL({bucket:"cloud.webtoon.today",filename: decodeURIComponent(tableObjectList[selectedIdx].key).replace(/\+/g," ")});
                            window.open(result);
                        })();
                    }}>
                        <GetAppOutlined style={{display: 'flex', gap: '3px'}}/>
                        Download
                    </button>
                </div>
                <div className={'SearchAndPaginationArea'}>
                    <div className={'SearchBox'}>
                        <SearchOutlined style={{width:16, height:22, display: 'flex', color: '#687078'}} />
                        <input type={'search'} onKeyDown={(event)=>{
                            if(event.key === 'Enter'){
                                fn.goto(`/challenge/cloud/#/${event.target.value}`);
                                //setPrefix(event.target.value);
                            }
                        }} autoComplete={'off'} placeholder={'Find objects by prefix'}/>
                    </div>
                    <div className={'Pagination'}>
                        <ArrowBackIosOutlined style={{width: '15px', height: '15px'}}/>
                        {paginationFormat(Math.ceil(objectList.length/300),page,isTruncated).map(idx=>{
                            return (<div className={`Page ${page===idx?`Selected`:``}`} id={`${idx}`} onClick={()=>{setPage(idx)}}>{idx}</div>)
                        })}
                        <ArrowForwardIosOutlined style={{width: '15px', height: '15px'}} />
                    </div>
                </div>
            </div>
            <div className={'ObjectTableArea'}>
                <table>
                    <thead>
                        <tr>
                            <th className={'SelectButtonArea'}>
                                <label className={'SelectButtonAreaLabel'} title={'Table Selection'}>
                                    {/* <input type={'checkbox'} checked={allObjectSelected} onClick={()=>{
                                        setObjectSelected(new Array(objectSelected.length).fill(!allObjectSelected));
                                        setAllObjectSelected(!allObjectSelected);
                                    }}/> */}
                                </label>
                            </th>
                            <th className={'ObjectName'}>
                                {'Name'}
                            </th>
                            <th className={'ObjectType'}>
                                {'Type'}
                            </th>   
                            <th className={'LastModified'}>
                                {'Last modified'}
                            </th>
                            <th className={'Size'}>
                                {'Size'}
                            </th>
                            <th className={'StorageClass'}>
                                {'Storage class'}
                            </th>
                        </tr>
                    </thead>
                    <tbody style={{backgroundColor:'rgba(255,255,255,1)'}}>
                        {tableObjectList.map((row, idx) => {
                            if(row.prefix){
                                return(
                                    <tr className={`FolderRow ${(selectedIdx===idx)?`Checked`:``}`}>
                                        <td>
                                            <input type={'checkbox'} disabled checked={selectedIdx===idx} onClick={()=>{
                                                if(selectedIdx===idx){
                                                    setSelectedIdx(null);
                                                }else{
                                                    setSelectedIdx(idx);
                                                }
                                            }}/>
                                        </td>
                                        <td className={'ObjectNameInner'} onClick={()=>{
                                            fn.goto(`/challenge/cloud/#/${row.prefix}`);
                                            // setPrefix(decodeURIComponent(row.prefix).replace(/\+/g," "));
                                        }}>
                                            <FolderOpen style={{display:'flex'}}/>
                                            <div className={'FolderName'}>{decodeURIComponent(row.prefix.split('/').filter(row=>row!=='').slice(-1)+'/')}</div>
                                        </td>
                                        <td>
                                            {formatExtension(row.prefix)}
                                        </td>
                                        <td>
                                            {'-'}
                                        </td>
                                        <td>
                                            {'-'}
                                        </td>
                                        <td>
                                            {'-'}
                                        </td>
                                    </tr>
                                )
                            }else{
                                return(<tr className={`FileRow ${(selectedIdx===idx)?`Checked`:``}`}>
                                    <td>
                                        <input type={'checkbox'} checked={selectedIdx===idx} onClick={()=>{
                                            if(selectedIdx===idx){
                                                setSelectedIdx(null);
                                            }else{
                                                setSelectedIdx(idx);
                                            }
                                        }}/>
                                    </td>
                                    <td className={'ObjectNameInner'}>
                                        <InsertDriveFileOutlined />
                                        <div className={'FileName'}>{decodeURIComponent(row.key.split('/')[row.key.split('/').length-1].replace(/\+/g," "))}</div>
                                    </td>
                                    <td>
                                        {formatExtension(row.key)}
                                    </td>
                                    <td>
                                        {new Date(row.lastModified).toLocaleString()}
                                    </td>
                                    <td>
                                        {formatBytes(row.size)}
                                    </td>
                                    <td>
                                        {row.storageClass}
                                    </td>
                                </tr>)
                            }
                             
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </div>)
}

export default Cloud;