import React, { useEffect } from 'react';
import {Button, Chip, Tooltip, } from '@material-ui/core';
import {WatchLaterOutlined as WatchLater, FavoriteBorderOutlined as Favorite, Check, ArrowDownward } from '@material-ui/icons';

import { getTitle, readTitles } from '../Data/Title';
import { listRecomm } from '../Data/Recomm';
import { readEpisodes } from '../Data/Episode';
import { logBehavior } from '../Data/Behavior';
import ss from '../Data/Session';

import Swipe from '../Component/Swipe';
import ExpandableGrid from '../Component/ItemCard';
import ItemDetailInfo from '../Component/ItemDetailInfo';

import { decodeEntities, thumbnailize, unique, waitImageLoaded } from '../Functions';

import './Detail.scss';
import Header from '../Component/Header';
import { useDispatch } from 'react-redux';

const getCookie = () => {
    let cookies = document.cookie.split(';').map(pair => pair.trim().split('='));
    let cookieDict = {};
    for(let [key, value] of cookies){
        cookieDict[key] = value;
    }

    return cookieDict;
}

const setCookie = (key, value) => {
    document.cookie = `${key}=${value};`;
}

/**
 * 
 * @typedef {import('../Data/Episode').episodeType} episodeType
 */

/**
 * 
 * @param {import('../VirtualRouter').rountingDataType} props 
    */
const Detail = (props) => {

    const [, serviceId, titleId] = /\/detail\/([^/#]*)\/([^/#]*)/.exec(props.path+'//' || '///')
    window.canonicalPath = `/detail/${serviceId}/${titleId}`;

    const [item, setItem] = React.useState((window.predef_data || [null])[0] || null);
    const dispatch = useDispatch();

    React.useEffect(()=>{
        (async ()=>{
            setItem((await getTitle(serviceId, titleId, dispatch)).concat([null])[0]);
        })();
    
    },[dispatch, serviceId, titleId])

    const [session, setSession] = React.useState(ss.getCurrentSession());

    React.useEffect(()=>{
        const sessionUpdate = ()=>{
            setSession(ss.getCurrentSession());
        }
        ss.registerSessionCallback(sessionUpdate)
    },[]);

    const [readHistory, setReadHistory] = React.useState(new Set((getCookie()['history'] || '').split(',')));

    const [episodes, setEpisodes] = React.useState([]);
    React.useEffect(()=>{
        setEpisodes([]);
        (async ()=>{
            setEpisodes(await readEpisodes(serviceId, titleId));
        })();
    },[serviceId, titleId]);

    let isLiked = item&&props.interests&&props.interests.likeData[`${item.serviceId}:${item.titleId}`]?true:false;
    let isSubscribed = item&&props.interests&&props.interests.subscribeData[`${item.serviceId}:${item.titleId}`]?true:false;
    
    let [imageHeightOverWidth, setImageHeightOverWidth] = React.useState(1);
    useEffect(()=> {
        window.scrollTo(0,0);

        (async ()=>{
            if (item && item.thumbnail){
                let imageSize = await waitImageLoaded(thumbnailize(item.thumbnail));
                setImageHeightOverWidth(Math.max(1, imageSize.height/imageSize.width));
            }
        })();
        
    },[item]);
    useEffect(()=>
        window.scrollTo(0,0)
    ,[item]);

    const [recomm, setRecomm] = React.useState([]);
    React.useEffect(()=>{
        (async ()=>{
            setRecomm(await listRecomm(item, [], [], 5))
            
        })();
    },[item, session])

    const [isDescriptionOpen, setIsDescriptionOpen] = React.useState(false);

    const topicCounts = {};
    if (props.tags){
        props.tags.forEach(pair => {
            topicCounts[pair.tag] = pair.count;
        });
    }

    const [topicTitles, setTopicTitles] = React.useState([]);
    
    React.useEffect(()=>{
        (async()=>{
            if (!item || !item.tags){
                return;
            }
            let newTag = item.tags.split(',')[0];
            let newRecomms = [];
            if (newTag === ""){
            }else{
                newRecomms = await readTitles(newTag, 5, undefined, undefined, undefined, false, dispatch);
            }
            setTopicTitles([[newTag, newRecomms]]);
        })();
    },[dispatch, item]);

    React.useEffect(()=>{
        (async()=>{
            if (!item){
                return;
            }
            if ((item.tags || "").split(',').filter(unique).length > topicTitles.length){
                let newTag = (item.tags || "").split(',').filter(unique)[topicTitles.length];
                let newRecomms = [];
                if (newTag === ""){
                }else{
                    newRecomms = await readTitles(newTag, 5, undefined, undefined, undefined, false, dispatch);
                }
                setTopicTitles([...topicTitles, [newTag, newRecomms]]);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[topicTitles]);


    // place holder
    if (!item) {
        return (
            <div className={"DetailStyle"}>
            </div>
        );
    }
    return (
        <div className={"DetailStyle"}>
            <Header
                pageName={item.title}
                rightButton={<div className={"MenuButton"} style={{opacity: 0}}/>}
            />
            <div className={"DetailHeader"}>
                <div className={"Background"} 
                    style={{
                        backgroundImage: `url(${thumbnailize(item.thumbnail)})`,
                    }}
                ></div>
                <div className={"Slider"} style={{paddingTop: `${imageHeightOverWidth*3/4*100}%`}}>
                    <div className={"Title"}>
                        {item.title}
                    </div>
                    <div className={"Description"} style={isDescriptionOpen?{}:{maxHeight: '2.8rem', overflow:'hidden', textOverflow: 'ellipsis'}}>
                        {decodeEntities(item.description)}
                    </div>
                    {item.description?
                    <div className={"DescriptionCover"} >
                        <Button onClick={()=>{setIsDescriptionOpen(!isDescriptionOpen)}} fullWidth style={{color: 'rgba(220,220,220,1)'}}>{isDescriptionOpen?"줄이기":"더읽기"}</Button>
                    </div>:<></>}


                    <div className={"Tags"}>
                        {(item.tags || "").split(",").filter(unique).map( (topic) =>
                        <Chip
                            key={topic}
                            className={"Chip"}
                            style={{
                                    borderColor: 'rgba(0,0,0,0)',
                                    color: 
                                        !topicCounts[topic] ||
                                        topicCounts[topic]<=3?"#C7DAC7":
                                        topicCounts[topic]<=6?"default":
                                        topicCounts[topic]<=9?"#FFB68C":
                                        topicCounts[topic]<=12?"#FE8E7B":
                                                "#FF6787",
                                }}
                            variant={"outlined"}
                            label={`#${topic}`}
                            clickable
                            onClick={() => {
                            }}
                        />
                    )}
                    </div>
                    <div className={"Buttons"}>
                        <Button
                            className={"Child"}
                            onClick={()=>{
                                if(!isLiked){
                                    logBehavior('like', {titleId:item.titleId, serviceId: item.serviceId});
                                    props.saveAndUpdateInterest([
                                        {type:"like", serviceId: item.serviceId, titleId: item.titleId, detail: {} }
                                    ]);
                                }else{
                                    logBehavior('unlike', {titleId:item.titleId, serviceId: item.serviceId});
                                    props.deleteAndUpdateInterest([
                                        {type:"like", serviceId: item.serviceId, titleId: item.titleId, detail: {} }
                                    ]);
                                }
                            }}
                        >
                            <Tooltip title='관심 작품'><span>
                                <Favorite color={isLiked?"secondary":"action"} />
                            </span></Tooltip>
                            <div className={"BtnTitle"}>{"관심 등록"}</div>
                        </Button>
                        <Button
                            className={"Child"}
                            onClick={()=>{
                                logBehavior('subscribe', {titleId:item.titleId, serviceId: item.serviceId});
                            if (!isSubscribed){
                                props.saveAndUpdateInterest((props.isLiked?[]:[
                                    {type:"like", serviceId: item.serviceId, titleId: item.titleId, detail: {} },
                                ]).concat([
                                    {type:"subscribe", serviceId: item.serviceId, titleId: item.titleId, detail: {} }
                                ]));
                            }else{
                                logBehavior('unsubscribe', {titleId:item.titleId, serviceId: item.serviceId});
                                props.deleteAndUpdateInterest([
                                {type:"subscribe", serviceId: item.serviceId, titleId: item.titleId, detail: {} }
                                ]);
                            }
                            }}
                        >
                            <Tooltip title='알림 설정'><span>
                                <WatchLater secondary={"true"}
                                    color={isSubscribed?"secondary":"action"}
                                />
                            </span></Tooltip>
                            <div className={"BtnTitle"}>{"알림 설정"}</div>
                        </Button>
                        <Button
                            className={"Child"}
                            onClick={() => {
                                props.goto(item.href);
                            }}
                        >
                            <Tooltip title='공식페이지'><span>
                                <Check color={"action"}/>
                            </span></Tooltip>
                            <div className={"BtnTitle"}>{"공식페이지"}</div>
                        </Button>
                    </div>
                    <ItemDetailInfo item={item}/>
                    <Button variant={"outlined"} style={{color:"white", borderColor: 'white', margin: 20, width: 'calc(100% - 40px)'}} onClick={()=>{
                        window.location.hash = "Recomm";
                    }}>
                        <ArrowDownward style={{color:"white"}}/>{"추천 보기"}
                    </Button>
                </div>
            </div>
            <div className={"Episodes"}>
                <div style={{height: 20, borderBottom:'1px lightgray solid'}}></div>
                <div style={{height: 5, }}></div>
                {episodes
                    .map(({episodeId, title, thumbnail, createdAt, href, isFree}) =>
                            ({
                                type: episodeId, typeForLog: "",
                                episodeId, title: decodeEntities(title), thumbnail,
                                detail: new Date((createdAt + 9 * 60 * 60)*1000).toISOString().substr(0,10),
                                href, isFree}))
                    .map(item => {
                        return (
                        <Button key={item.type} className={"Episode"} style={readHistory.has(item.episodeId)?{backgroundColor: 'rgba(0,0,0,0.14)'}:{}} fullwidth
                            onClick={() => {
                                setReadHistory( new Set(readHistory.add(item.episodeId)) );
                                setCookie('history', [...readHistory].join(','));
                                logBehavior('visit', {type: item.typeForLog, titleId, serviceId, episodeId: item.episodeId});
                                props.goto(item.href);
                            }}
                        >
                            <img className={"Thumbnail"}
                                src={thumbnailize(item.thumbnail, 0, 80)}
                                alt={item.title}
                                loading={"lazy"}
                            />
                            <div className={"TextContainer"}>
                                <div className={"Title"}>{item.title}</div>
                                <div className={"Subtitle"}>{item.detail}</div>
                            </div>
                            {!item.isFree
                            ?<div className={"Tag"}>{"유료 회차"}</div>
                            :<></>}
                        </Button>
                    )})
                }
            </div>
            <div id={"Recomm"} className={"Recomm"}>
                
                <div className={"Header"}>{item.title}{": 재밌게 보셨다면"}</div>
                <Swipe
                    width={0}
                    style={{width: 'calc(100% - 24px)', paddingLeft:24, flexShrink: 0}}
                    onScrollEnd={()=>{
                        logBehavior('scrollRecomm', {type:'recomm'});
                    }}
                >
                    <div className={"Body"}>
                        {recomm.slice(0,5).map(title =>
                        <ExpandableGrid
                            key={`${title.serviceId}:${title.titleId}`}
                            item={title}
                            {...props}
                            isLiked={false}
                            isSubscribed={false}
                            recommOrEpisodeTitle={"recomm"}
                        />)}
                    </div>
                </Swipe>
                {topicTitles
                    .filter(([, recomm]) =>
                        recomm
                            .filter(title => item.isAdult || title.isAdult === item.isAdult)
                            .filter(title => item.serviceId !== title.serviceId || item.titleId !== title.titleId).length > 0)
                    .map(([tag, recomm]) =>
                        <div key={`header_${tag}`}>
                            <div className={"Header"}>{`#${tag}`}</div>
                            <Swipe
                                width={0}
                                style={{width: 'calc(100% - 24px)', paddingLeft:24, flexShrink: 0}}
                                onScrollEnd={()=>{
                                    logBehavior('scrollRecomm', {type: `tag:${tag}`});
                                }}
                            >
                                <div className={"Body"}>
                                    {recomm.slice(0,5)
                                    .filter(title => item.serviceId !== title.serviceId || item.titleId !== title.titleId)
                                    .filter(title => item.isAdult || title.isAdult === item.isAdult)
                                    .map(title =>
                                    <ExpandableGrid
                                        key={`${title.serviceId}:${title.titleId}`}
                                        item={title}
                                        {...props}
                                        isLiked={false}
                                        isSubscribed={false}
                                        recommOrEpisodeTitle={"recomm"}
                                    />)}
                                </div>
                            </Swipe>
                        </div>
                )}
            </div>
        </div>
    );
}

export default Detail;