import { Button } from '@material-ui/core';
import React from 'react';
import CommonSectionHeader from '../CommonSectionHeader';
import { fn } from '../../../Functions';
import './Service.scss';

const introductionCardDataList = [
    {
        chip: "데이터 분석",
        chipColor: "Blue",
        serviceName: "webtoon analytics",
        description: "객관적인 지표를 통해 독자의 마음을 데이터로 분석하고,\n디렉터의 코멘트를 받아볼 수 있는 서비스",
        introductionImage: "https://static.webtoon.today/ddah/introduction_page/image/image_homepage_webtoonAnalytics.png",
        href: "https://metric.webtoon.today/"
    },
    {
        chip: "플랫폼",
        chipColor: "Green",
        serviceName: "challenge today",
        description: "자신의 작품을 조건없이 등록하고,\n독자들의 반응을 살펴볼 수 있는 서비스",
        introductionImage: "https://static.webtoon.today/ddah/introduction_page/image/image_homepage_challengeToday.png",
        href: "https://challenge.webtoon.today/"
    },
    {
        chip: "마케팅",
        chipColor: "Sky",
        serviceName: "webtoon metric",
        description: "작품의 마케팅 인사이트 도출부터 광고 집행, 성과 측정까지의\n모든 과정을 관리할 수 있는 서비스",
        introductionImage: "https://static.webtoon.today/ddah/introduction_page/image/image_homepage_webtoonMetric.png",
        href: "https://metric.webtoon.today/",
    }
];

const ServiceIntroductionCard = ({chip, chipColor, serviceName, description, introductionImage, href}) => {
    return (
        <div className={'IntroductionCard'}>
            <div className={'ServiceDetailContainer'}>
                <div className={`Chip ${chipColor}`}>
                    {chip}
                </div>
                <div className={'ServiceName'}>
                    {serviceName}
                </div>
                <div className={'ServiceDescription'}>
                    {description}
                </div>
            </div>
            <Button target='_blank' href={href} className={'LinkButton'} onClick={fn.gotoByAnchor} title={serviceName}>
                <div className={'TextWrapper'}>
                    <span className={'ButtonText'}>{"서비스 보러가기"}</span>
                    <img className={'ArrowRight'} src="https://static.webtoon.today/ddah/icon/arrow_right.svg" alt="arrow right"/>
                </div>
            </Button>
            <div className={'IntroductionImageWrapper'}>
                <img className={'IntroductionImage'} alt={chip} src={introductionImage}/>
            </div>
        </div>
    );
}

const Service = () => {
    return (
        <section>
            <div className='IntroductionCardContainer'>
                <CommonSectionHeader title={'SERVICE'}/>
                {introductionCardDataList.map(data => 
                    <ServiceIntroductionCard key={data.chip} {...data}/>
                )}
            </div>
        </section>
    );
};

export default Service;