import React from 'react';
import { TextField } from '@material-ui/core';

const EditableDateField = (props) => {
    
    if (!props.isEditing){ 
        if (!props.defaultValue){
            return <div style={props.style}></div>
        } 
        if (props.fullDate){
            return <div style={props.style}>{new Date(props.defaultValue * 1000 + 9 * 60 * 60 * 1000).toISOString().substring(0,"2020-08-17T12:00:00".length)}</div>
        }
        if (props.timeOnly){
            return <div style={props.style}>{new Date(props.defaultValue * 1000).toLocaleTimeString()}</div>
        }
        return <div style={props.style}>{new Date(props.defaultValue * 1000 + 9 * 60 * 60 * 1000).toISOString().substring(0,"2020-08-17".length)}</div>
    }

    if (!props.defaultValue){
        if (props.fullDate){
            return (
                <TextField  
                    onBlur={(event)=>{
                        props.update({[props.field]: new Date(event.target.value).getTime()/1000});
                    }}
                    inputProps={props.timeOnly?{step:3600}:{}}
                    type="datetime-local"
                    defaultValue="" 
                />
            );
        }
        if (props.timeOnly){
            return (
                <TextField  
                    onBlur={(event)=>{
                        props.update({[props.field]: new Date(event.target.value).getTime()/1000});
                    }}
                    inputProps={props.timeOnly?{step:3600}:{}}
                    type="time"
                    defaultValue="" 
                />
            );
        }
        if (props.monthOnly){
            return (
                <TextField  
                    onBlur={(event)=>{
                        props.update({[props.field]: new Date(event.target.value).getTime()/1000});
                    }}
                    inputProps={props.timeOnly?{step:3600}:{}}
                    type="month"
                    defaultValue="" 
                />
            );
        }
        return (
            <TextField  
                onBlur={(event)=>{
                    props.update({[props.field]: new Date(event.target.value).getTime()/1000});
                }}
                inputProps={props.timeOnly?{step:3600}:{}}
                type="date"
                defaultValue="" 
            />
        );
    }

    if (props.fullDate){
        return (
            <TextField  
                onBlur={(event)=>{
                    props.update({[props.field]: new Date(event.target.value).getTime()/1000});
                }}
                inputProps={props.timeOnly?{step:3600}:{}}
                type="datetime-local"
                defaultValue={new Date(props.defaultValue * 1000 + 9 * 60 * 60 * 1000).toISOString().substring(0,"2020-08-17T12:00:00".length)} 
            />
        );
    }
    
    if (props.timeOnly){
        return (
            <TextField  
                onBlur={(event)=>{
                    props.update({[props.field]: new Date(event.target.value).getTime()/1000});
                }}
                inputProps={props.timeOnly?{step:3600}:{}}
                type="time"
                defaultValue={new Date(props.defaultValue * 1000 + 9 * 60 * 60 * 1000).toISOString().substring(11,5)} 
            />
        );
    }
    
    if (props.monthOnly){
        return (
            <TextField  
                onBlur={(event)=>{
                    props.update({[props.field]: new Date(event.target.value).getTime()/1000});
                }}
                inputProps={props.timeOnly?{step:3600}:{}}
                type="month"
                defaultValue={new Date(props.defaultValue * 1000 + 9 * 60 * 60 * 1000).toISOString().substring(0,"2020-08".length)} 
            />
        );
    }

    // if (props.type === 'date'){
        return (
            <TextField  
                onBlur={(event)=>{
                    props.update({[props.field]: new Date(event.target.value).getTime()/1000});
                }}
                inputProps={props.timeOnly?{step:3600}:{}}
                type="date"
                defaultValue={new Date(props.defaultValue * 1000 + 9 * 60 * 60 * 1000).toISOString().substring(0,10)} 
            />
        );
    // }
}

export default EditableDateField;