import { Button, ButtonGroup, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import LoadingCircle from "../../../../Component/LoadingCircle";
import EditableTable from "../../../../Component/Table/EditableTable";
import { putInsightReport, readPool } from "../../../../Data/Insight";
import DiagnosisResult from './Components/DiagnosisResult';
import DropDownSelectBox from './Components/DropDownSelectBox';

import './InsightPool.scss';

const nullTitleData = {
    title: "",
    rawData: {
        sales: 0,
        popularity: 0,
        weeklyPopularity: 0,
        download: 0,
        weeklyDownload: 0,
        firstFreeEpLike: 0,
        firstPaidEpLike: 0,
        totalEpisode: 0,
        searchExposure: 0,
    },
    relativePosition:{
        ltv: 0,
        popularity: 0,
    }
}

const initialData = {
    diagnoses: {
        comparison: nullTitleData,
        target: nullTitleData,
        similarTop: nullTitleData,
        genreTop: nullTitleData
    },
    header: {
        summary: "",
        result: ""
    }
}

const diagnosesOption = {
    comparison: "내 작품",
    target: "목표작",
    similarTop: "유사작",
    genreTop: "장르TOP",
}

const compareDiagnosesOption = ['target','similarTop','genreTop']; 
/**
 * @param {'comparison' | 'target' | 'similarTop' | 'genreTop' | 'cancel' } option 'comparison' | 'target' | 'similarTop' | 'genreTop' | 'cancel'
 * @returns {"" | "내 작품" | "목표작" | "유사작" | "장르TOP" | "선택 취소"}
 */
const convertKorDiagnosesOption = (option) => {
    if ([...compareDiagnosesOption, 'comparison'].includes(option)){
        return diagnosesOption[option] 
    }
    if (option === 'cancel'){
        return "선택 취소";
    }
    return ""
};

const InsightPool = ({ serviceId, titleId }) => {

    const [ pool, setPool ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);

    const [ isPoolSelecting, setIsPoolSelecting ] = useState(true);

    const [ data, setData ] = useState(initialData);
    
    useEffect(() => {
        ( async () => {
            setIsLoading(true);
            const res = await readPool(serviceId, titleId);
            if (res) {
                setPool(res);
                const comparisonRow = res.filter( pool => pool.titleId === titleId )[0]
                
                const newData = {...initialData};
                newData.diagnoses['comparison'] = {
                    ...nullTitleData,
                    title: comparisonRow.title,
                    rawData: {
                        ...nullTitleData.rawData,
                        sales: Number(comparisonRow.sales ||0),
                        popularity: Number(comparisonRow.popularity ||0),
                        weeklyPopularity: Number((comparisonRow.popularity/(comparisonRow.period/7||1) ||0).toFixed(0)),
                        download: Number(comparisonRow.saleVolumeCount),
                        weeklyDownload: Number((comparisonRow.saleVolumeCount/(comparisonRow.period/7||1) ||0).toFixed(0)),
                    },
                    relativePosition: {
                        ltv: 0,
                        popularity: 0
                    }
                }
            }
            setIsLoading(false);
        })();
    },[serviceId, titleId]);
    
    /**
     * 
     * @param {titleData} row 
     * @param {'target' | 'similarTop' | 'genreTop' } type 'target' | 'similarTop' | 'genreTop'
     */
    const saveRowToData = (row, type) => {
        let resetDiagnoses = {};
        compareDiagnosesOption.forEach((diagnosesOption)=>{
            if(data.diagnoses[diagnosesOption].title === row.title){
                resetDiagnoses= {[diagnosesOption]: nullTitleData}
            }
        });

        const newData = (prev) => {
            return {
                diagnoses: {
                    ...prev.diagnoses,
                    ...resetDiagnoses,
                    [type]: {
                        title: row?.title ||"",
                        rawData: {
                            ...prev.diagnoses[type].rawData,
                            sales: Number(row.sales ||0),
                            popularity: Number(row.popularity ||0),
                            weeklyPopularity: Number((row.popularity/(row.period/7||1) ||0).toFixed(0)),
                            download: Number(row.saleVolumeCount||0),
                            weeklyDownload: Number((row.saleVolumeCount/(row.period/7||1) ||0).toFixed(0)),
                        },
                        relativePosition: {
                            ltv: 0,
                            popularity: 0,
                        }
                    }
                },
                header: {
                    summary: "",
                    result: "",
                }
            }
        }
        setData(newData);
    }

    const saveData = (newData) => setData(newData);

    const isReadyToAnalysis = data && data?.diagnoses
                           && data.diagnoses?.target     && data.diagnoses?.target?.title  
                           && data.diagnoses?.comparison && data.diagnoses?.comparison?.title;

    return(<div className={'InsightPoolArea'}>
        <div className={'HeaderButtonBox'} >
            <Button 
                className={`SelectingButton ${isPoolSelecting ? 'Selected':""}`} fullWidth
                onClick={()=>setIsPoolSelecting(true)}>{'작품 선택'}</Button>
            <Button 
                className={`SelectingButton ${!isPoolSelecting ? 'Selected':""}`} fullWidth
                onClick={()=>{
                    if (!data?.diagnoses?.target?.title){
                        alert('목표작을 선택해주세요.');
                        return;
                    }
                    setIsPoolSelecting(false);
                }}
            >
                {'미리보기'}
            </Button>
        </div>
        <table className={'SelectedTitlePreviewer'}>
            <tbody className={'OptionTableBody'}>
                {['comparison',...compareDiagnosesOption].map((option)=>
                    <tr className={'OptionTr'} key={option}>
                        <td className='OptionTd'>{convertKorDiagnosesOption(option)}</td>
                        <td className='OptionTd'>{data.diagnoses[option].title || ""}</td>
                    </tr>
                )}
            </tbody>
        </table>
        {!isPoolSelecting
        ? <div>
            <DiagnosesEditor data={data} saveData={ saveData } serviceId={serviceId} titleId={titleId}/>
            {isReadyToAnalysis && <DiagnosisResult data={data} />}
        </div>
        :<EditableTable 
            style={{maxHeight: 1000, overflow: 'auto'}}
            headerButtonHeaders={[]}
            headerButtonsFunction={(row, index)=>[]}
            isDownloadable={true}
            defaultSortCol={[3, "desc"]}
            columnHeaders={['제목','BM','연재일','점수','총 매출(원)','주간 매출','관심독자','LTV','설명','비교대상']}
            columnsFunction={(row, index)=>[
                {isEditing: false, field: "title", type: 'default', style: {width: '200px', height: 40, display: 'flex', alignItems: 'center'},
                    defaultValue: row.title
                },
                {isEditing: false, field: "businessModel", type: 'default', style: {width: '50px'},
                    defaultValue: (row.businessModel==='weekday'?'요일':row.businessModel==='dailyPlus'?'매일+':'완결')
                },
                {isEditing: false, field: "period", type: 'default', style: {display: 'flex', float: 'right', width: '50px', whiteSpace: 'nowrap', textOverflow: 'hidden'},
                    defaultValue: Number(row.period.toFixed(0))
                },
                {isEditing: false, field: "score", type: 'default', style: {width: '50px'},
                    defaultValue: row.score
                },
                {isEditing: false, field: "sales", type: 'default', style: {width: '120px'},
                    defaultValue: row.sales
                },
                {isEditing: false, field: "weeklysales", type: 'default', style: {width: '70px'},
                    defaultValue: Number(((row.sales / row.period * 7) || 0).toFixed(0))
                },
                {isEditing: false, field: "popularity", type: 'default', style: {width: '70px'},
                    defaultValue: row.popularity
                },
                {isEditing: false, field: "ltv", type: 'default', style: {width: '70px'},
                    defaultValue: Number((row.LTV || 0).toFixed(0))
                },
                {isEditing: false, field: "description", type: 'default',
                    defaultValue: <div>{row.description}</div>
                },
                {isEditing: false, field: "diagnosesOption", style: {display: 'flex', justifyContent: 'center', padding: "8px"}, type: 'default', 
                    defaultValue: <DropDownSelectBox 
                                    className={titleId === row.titleId ? "ComparisonRow" : ""}
                                    placeholder={'비교 옵션 선택'}
                                    selectedValue={compareDiagnosesOption.find((option) => data.diagnoses[option]?.title === row.title) || ""} 
                                    data={['cancel', ...compareDiagnosesOption]} 
                                    dataConverter={convertKorDiagnosesOption} 
                                    onChange={(e)=>{
                                        if (e.target.value === 'cancel'){
                                            const prevOption = compareDiagnosesOption.find((option)=>data.diagnoses[option].title === row.title);
                                            setData((prev)=>{
                                                return {
                                                    ...prev,
                                                    diagnoses: {
                                                        ...prev.diagnoses,
                                                        [prevOption]:nullTitleData,
                                                    }
                                                }
                                            });
                                            return;
                                        }
                                        saveRowToData(row, e.target.value);
                                    }}
                                    disabled={titleId === row.titleId}
                                />
                },
            ]}
            updateGenerator={(row, index)=> async (newValue)=>{}}
            data={pool}
            name={"marketing"}
        />}
        <LoadingCircle show={isLoading} />
    </div>);
}

const DiagnosesEditor = ({data, saveData, serviceId, titleId}) => {
    
    const diagnosesOption = ['comparison', ...compareDiagnosesOption];
    
    const [selectedOption, setSelectedOption] = useState('comparison');

    /**
     * 
     * @param {string} headerDataValue 
     * @param {'summary' | 'result'} key 'summary' | 'result' 
     */
    const handleDataHeaderChange = (headerDataValue, key) => {
        if (!['summary', 'result'].includes(key)){
            return;
        }
        saveData({
            ...data,
            header:{
                ...data.header,
                [key]: headerDataValue
            }
        });
    }

    /**
     * 
     * @param {number} rawDataValue 
     * @param {'comparison' | 'target' | 'similarTop' | 'genreTop' } key 'comparison' | 'target' | 'similarTop' | 'genreTop'
     * @param {'searchExposure' | 'firstFreeEpLike' | 'firstPaidEpLike' | 'totalEpisode'} textFieldKey 'searchExposure' | 'firstFreeEpLike' | 'firstPaidEpLike' | 'totalEpisode'
     */
    const handleDiagnosesChange = (rawDataValue, key, textFieldKey) => {
        if (!diagnosesOption.includes(key) || !['searchExposure', 'firstFreeEpLike', 'firstPaidEpLike', 'totalEpisode'].includes(textFieldKey)){
            return;
        }
     
        saveData({
                ...data,
                diagnoses: {
                    ...data.diagnoses,
                    [key]: {
                        ...data.diagnoses[key],
                        rawData: {
                            ...data.diagnoses[key].rawData,
                            [textFieldKey]: rawDataValue
                        }
                    }
                }
            });
    }

    const handleSubmit = async () => {
        const res = await putInsightReport(serviceId, titleId, data);
        if (res){
            alert('인사이트 저장에 성공하였습니다.')
        }else{
            alert('인사이트 저장에 실패하였습니다.')
        }
    }                     

    const handleOptionToggle = (option) => {
        setSelectedOption(option);
    }

    const checkFormEmpty = 
                        !data.header.summary || 
                        !data.header.result  || 
                        !diagnosesOption.reduce((acc,option)=> {
                            // 작품이 선택되지 않아서 데이터가 없는 옵션은 disable 여부에 영향을 미치지 않고 누산기의 boolean 값을 그대로 반환
                            if(data.diagnoses[option].title === ""){
                                return Boolean(acc);
                            }
                            
                            // 기존 boolean 값이 true일 때는 데이터 값이 하나라도 없으면 true로 반환(disable 처리), false일 때는 false로 반환 
                            const { searchExposure, firstFreeEpLike, firstPaidEpLike, totalEpisode } = data?.diagnoses[option]?.rawData;
                            if ( acc ) {
                                return Boolean(searchExposure && firstFreeEpLike && firstPaidEpLike && totalEpisode);
                            } else {
                                return false;
                            }
                        },true);

    return (
        <div className={'DiagnoesEditorForm'}>
            <TextField 
                label={'요약에 들어갈 문장을 입력하세요'} required
                multiline
                onChange={(event)=>handleDataHeaderChange(event.target.value, 'summary')}
            />
            <TextField 
                label={'결과에 들어갈 문장을 입력하세요'} required
                multiline
                onChange={(event)=>handleDataHeaderChange(event.target.value, 'result')}
            />
            
            <ButtonGroup className={'SelectOptionButtonGroup'}>
                {diagnosesOption.map((option, index)=>
                    <Button 
                        key={option} 
                        className={`OptionToggleButton${selectedOption === option ? " Selected" : ""}`} 
                        variant='text' 
                        type={'button'}
                        disabled={data.diagnoses[option].title === ""} 
                        onClick={()=>handleOptionToggle(option)}
                    >
                        <span>
                            {convertKorDiagnosesOption(option)}
                        </span>
                    </Button>
                )}
            </ButtonGroup>
                
            {diagnosesOption.filter((item)=>data.diagnoses[item].title !== "").map((option, index)=> 
                <div key={option} className={`DiagnosesOptionWrapper${selectedOption === option ? " Selected":""}`}>
                    <TextField 
                        className={'NumberInputField'} label={'구글 검색 결과를 입력하세요 (숫자)'} type={'number'} required
                        onChange={(event)=>handleDiagnosesChange(Number(event.target.value), option, 'searchExposure')}
                    />
                    <TextField 
                        className={'NumberInputField'} label={'무료 첫 회차 좋아요수를 입력하세요 (숫자)'} type={'number'} required 
                        onChange={(event)=>handleDiagnosesChange(Number(event.target.value), option, 'firstFreeEpLike')}
                    />
                    <TextField 
                        className={'NumberInputField'} label={'유료 첫 회차 좋아요수를 입력하세요 (숫자)'} type={'number'} required 
                        onChange={(event)=>handleDiagnosesChange(Number(event.target.value), option, 'firstPaidEpLike')}
                    />
                    <TextField 
                        className={'NumberInputField'} label={'총 회차를 입력하세요 (숫자)'} type={'number'} required 
                        onChange={(event)=>handleDiagnosesChange(Number(event.target.value), option, 'totalEpisode')}
                    />
                </div>
            )}    
            <Button className={'DiagnoseEditorButton'} disabled={checkFormEmpty} onClick={handleSubmit} type={'button'}>
                {'저장'}
            </Button>
        </div>
    );
}

export default InsightPool;