import axios from 'axios';
import ss from './Session';

/**
 * @typedef {{
 *   id: string,
 *   data: {},
 * }} ContentsType
 * 
 * @typedef {import('./Session').sessionType} sessionType
 */

/**
 * 
 * @param {sessionType} session 
 * @param {string} query 
 * @param {number} limit 
 * @returns {Promise<ContentsType[]>}
 */
export async function readContents (serviceId, titleId, episodeId, snapshotId) {
    try{
        let res = await axios.post('https://api.webtoon.today/contents', {serviceId, titleId, episodeId, snapshotId,}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            if (res.data.siblings){
                res.data.siblings = res.data.siblings.map(num => `${num}`)
            }
            return res.data;

        }else{
            return res;
        }
    }catch(e){
        console.error(e)
        return { data:{ images:[]}, siblings: [], ...(e.response),};
    }
}

/**
 * 
 * @param {{
 *  session: sessionType
 *  serviceId: string,
 *  titleId: string,
 *  snapshotId: string,
 *  data: {},
 * @returns {Promise<string>}
 */
export async function createContents ({contents, }) {
    try{
        let res = await axios.put('https://api.webtoon.today/contents', contents,{
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            }
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}

/**
 * 
 * @param {{
*  session: sessionType
*  contents: ContentsType,
* @returns {Promise<string>}
*/
export async function updateContents ({contents,}) {
    try{
        let res = await axios.patch('https://api.webtoon.today/contents', contents,{
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            }
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}

/**
* 
* @param {{
*  session: sessionType
*  contents: ContentsType,
* @returns {Promise<string>}
*/
export async function deleteContents ({contents}) {
    try{
        let res = await axios.delete(`https://api.webtoon.today/contents?serviceId=${contents.serviceId}&titleId=${contents.titleId}&episodeId=${contents.episodeId}&snapshotId=${contents.snapshotId}`,{
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            }
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}

/**
 * 
 * @param {{serviceId: string, titleId:string}} props
 * @returns {Promise<string|null>}
 */
 export async function createContentsFromChallenge ({serviceId, titleId, episodeId, source, ...others}) {
    try{
        let res = await axios.put('https://api.webtoon.today/create-episode-from-challenge', {
            serviceId, titleId, episodeId, source, ...others
        }, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.zipFileLocation;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}