import React from 'react';
import './Header.scss';
import { KeyboardArrowLeft, KeyboardArrowRight, Menu} from '@material-ui/icons';
import { Button, } from '@material-ui/core';
import {fn} from '../Functions';

/**
 * 
 * @param {{
 *  pageName: string,
 *  rightButton: JSX.Element,
 *  forceToShow?: boolean,
 *  hideClean?: boolean,
 *  nextOnClick: ()=> void,
 *  prevOnClick: ()=> void,
 * }} props 
 */
const ContentsNavigator = ({pageName, rightButton, forceToShow, hideClean, nextOnClick, prevOnClick, }) => {

    return (<div style={{position: 'fixed', width: '100%', height:'100%', left:0, top:0, pointerEvents: 'none', display:'flex', justifyContent:'center', alignItems: 'center', zIndex: 99}} onClick={()=>{
    }}>
        <div className={`SiteHeader ${forceToShow?'ForceToShow':''} ${hideClean?'HideClean':''}`}>
            {window.location.pathname !== "/"
                ?<Button className={"MenuButton"} onClick={()=>{
                    fn.goto('/')
                }}></Button>
                :<>
                    <img className={"Logo"} src={"https://cached-api.webtoon.today/thumb?u=https://detail.webtoon.today/logo512.png&mw=80"} alt={"logo"} onClick={()=>fn.goto("/")}/>
                    <img className={"LogoSmall"} src={"https://cached-api.webtoon.today/thumb?u=https://static.webtoon.today/push_96.png&mw=80"} alt={"logo"} onClick={()=>fn.goto("/")}/>
                </>}
            <div className={"PageName"}>{pageName || "오늘의 웹툰"}</div>
            {rightButton }
        </div>
        <div className={`BottomButtonBar ${forceToShow?'ForceToShow':''} ${hideClean?'HideClean':''}`}>
            <Button className={"Button Left"} fullWidth onClick={(event)=>{
                event.stopPropagation();
                prevOnClick(event);
            }}><KeyboardArrowLeft/>{"이전"}</Button>
            <Button className={"Button NoCircle"} onClick={(event)=>{
                event.stopPropagation();
                let [, serviceId, titleId, ] = /view\/([^/]+)\/([^/]+)\/([^/]+)\/?/.exec(window.location.href);

                fn.goto(`/detail/${serviceId}/${titleId}`);
            }}><Menu/></Button>
            <Button className={"Button Right"} fullWidth onClick={(event)=>{
                event.stopPropagation();
                nextOnClick(event);
            }}>{"다음"}<KeyboardArrowRight/></Button>
        </div>
    </div>);

}

export default ContentsNavigator;