import React from 'react';
import './TextBox.scss'

const TextBox = ({ Title, SubTitle, Content, NextToBarText, ButtonAdd, ChartAdd }) => {

  return (
    <div className={"SimpleTextBox"}>
        <div className={"TextBoxHeader"}>   
            <div className={"TitleText"}>
                {Title}
            </div>
            <div className={"SubTitleText"}>
                {SubTitle}
            </div>
        </div>
        <div>
            {ChartAdd}
        </div>
        <div className={"TextBoxBody"}>
            <div className={"ContentText"}>
                {Content}
            </div>
            <div className={"NextToBarText"}>
                {NextToBarText}
            </div>
        </div>
        <div className={"TextBoxFooter"}>
            <div className={"SeeMoreButton"}>
                {ButtonAdd}
            </div>
        </div>
    </div>


  )

}

export default TextBox;