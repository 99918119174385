
import { Avatar, Button, Card, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Select, TextField } from '@material-ui/core';
import { Add, Create, Delete, Edit, Help, Visibility, VpnKey } from '@material-ui/icons';
import React from 'react';
import LoadingCircle from '../../Component/LoadingCircle';
import { readMemberships, createTeam, createMembership, updateMembership, deleteMembership, renameTeam } from '../../Data/Membership'
import { validateEmailForm } from '../../Functions';

import ss from '../../Data/Session';
import './Team.scss';
import { readProfile } from '../../Data/Profile';

const Team = ({serviceId, titleId, title, value = 0, thumbnail, className="", onClick, onDelete=null}) => {

    const [teamData, setTeamData] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false)
    const [userName, setUserName] = React.useState({})

    React.useEffect(()=>{
        document.title = `팀 편집`;
        
        (async()=>{

            setIsLoading(true)

            const res = await readMemberships()

            const users = {}
            for (const team of res) {
                if (!users[team.userid] && team) {
                    const newProfile = await readProfile(`${team.userid}`)
                    users[`${team.userid}`] = newProfile.name
                }
            }

            setTeamData(res)
            setUserName(users)

            setIsLoading(false)

        })()

    },[])

    const [isAskingAddMember, setIsAskingAddMember] = React.useState(false);
    const [isAskingCreateTeam, setIsAskingCreateTeam] = React.useState(false);
    const [isAskingEditTeamName, setIsAskingEditTeamName] = React.useState(false);

    const [pendingMembership, setPendingMembership] = React.useState({authority: 'write'});

    const [editAuthorityAnchor, setEditAuthorityAnchor] = React.useState(null);
    const [editingMember, setEditingMember] = React.useState(null);

    const teams = {}
    for (const membership of teamData) {
        if (!teams[`${membership.teamid}:${membership.name}:${membership.teamemail}`]) {
            teams[`${membership.teamid}:${membership.name}:${membership.teamemail}`] = [membership]
        } else {
            teams[`${membership.teamid}:${membership.name}:${membership.teamemail}`].push(membership)
        }
    }
    
    const addNewMembership = () => {

        (async ()=>{
            setIsLoading(true);
            
            const newMembership = await createMembership(pendingMembership)
            
            const newTeamData = [...teamData]
            const newUsers = {...userName}

            if(newMembership){
                let [teamid, userid] = newMembership.split(':');
                let existingTeam = teamData.filter(team => team.teamid === teamid).concat([{}])[0];

                newTeamData.push({
                    teamid,
                    teamemail: existingTeam.teamemail || '',
                    name: pendingMembership.name,
                    userid,
                    authority: pendingMembership.authority
                })
                
                if (!userName[`${newMembership.split(':')[1]}`]) {
                    const newProfile = await readProfile(newMembership.split(':')[1])
                    newUsers[`${newMembership.split(':')[1]}`] = newProfile.name
                }

            } else {
                alert('팀원 추가에 실패했습니다.')
            }

            setUserName(newUsers)
            setTeamData(newTeamData)
            setIsLoading(false);
        })();

        setPendingMembership({authority: 'write'})
        setIsAskingAddMember(false);

    }

    const generateNewTeam = () => {
        
        (async ()=>{
            setIsLoading(true);

            const newTeamId = await createTeam(pendingMembership.name, pendingMembership.teamemail)

            const newTeamData = [...teamData]
            const newUsers = {...userName}

            if (newTeamId){
                const [teamid, userid] = newTeamId.split(":");
                newTeamData.push({
                    ...pendingMembership,
                    teamid, userid, authority: 'owner'
                })
                
                if (!userName[`${ss.getCurrentSession().userid}`]) {
                    const loginUser = await readProfile(ss.getCurrentSession().userid)
                    newUsers[`${ss.getCurrentSession().userid}`] = loginUser.name
                }

            } else {
                alert('팀 생성에 실패했습니다.')
            }

            setUserName(newUsers)
            setTeamData(newTeamData)
            setIsLoading(false);
        })();

        setPendingMembership({authority: 'write'})
        setIsAskingCreateTeam(false);
    }

    const changeTeamInfo = () => {

        (async () => {
            setIsLoading(true);

            const newTeamName = await renameTeam(pendingMembership, pendingMembership.name, pendingMembership.teamemail);

            const newTeamData = [...teamData];

            if (newTeamName){
                for (const newMembership of newTeamData){
                    if(newMembership.teamid === pendingMembership.teamid){
                        newMembership.name = pendingMembership.name
                    }
                }
            }

            setTeamData(newTeamData)
            setIsLoading(false)
        })();

        setPendingMembership({authority: 'write'})
        setIsAskingEditTeamName(false);
    }

    const changeAuthority = async(authority)=> {
        const updatedMembership = await updateMembership({
            ...editingMember,
            authority
        })

        const newTeamData = [...teamData]
        
        for (const membership of newTeamData) {
            if (`${membership.teamid}:${membership.userid}` === updatedMembership) {
                membership.authority = authority
            }
        }

        setEditAuthorityAnchor(null)
        setTeamData(newTeamData)
        setEditingMember(null)
    }
    
    return(
        <div>
            <div className={'TeamAuthority'}>
                <div className={'Title'}>
                    {"팀 관리"}
                </div>
                <div className={'TeamAuthorityArea'}>
                    <div className={'BelongTeams'}>
                        {Object.entries(teams).map(([teamKey, memberships]) =>
                        <Card key={`TeamCard${teamKey.split(':')[0]}`} className={`TeamBox`}>
                            <IconButton 
                                onClick={()=>{
                                    const newMembership = {...pendingMembership,
                                        teamid: teamKey.split(':')[0],
                                        name: teamKey.split(':')[1],
                                        teamemail: teamKey.split(':')[2],
                                    }
                                    setPendingMembership(newMembership)
                                    setIsAskingEditTeamName(true)
                                }} 
                                className={'EditIcon'} size={'small'}
                            >
                                <Edit style={{fontSize: '1rem'}}/>
                            </IconButton>
                            <div className={'TeamName'}>
                                {`${teamKey.split(':')[1]}`}
                            </div>
                            <div className={'TeamEmail'}>
                                {`${teamKey.split(':')[2]}`}
                            </div>
                            <div className={'Chips'}>
                                <div className={'ChipBox'}>
                                    {memberships.map((membership, memberIndex)=>(`${membership.teamid}:${membership.name}:${membership.teamemail}` === teamKey
                                    ?<Chip key={`Chip${membership.teamid}:${membership.userid}`} className={"Chip"} 
                                        avatar={<Avatar>
                                            {membership.authority === 'owner'
                                            ?<Button
                                            disabled={membership.userid === ss.getCurrentSession().userid && teamData.filter(({teamid})=>teamid === membership.teamid).length > 1}
                                                onClick={(event)=>{
                                                    setEditingMember(membership)
                                                    setEditAuthorityAnchor(event.currentTarget)
                                                }}>     
                                                <VpnKey className={"Avatar"} /> 
                                            </Button>
                                            :membership.authority === 'write'
                                            ?<Button
                                            disabled={teamData.filter(({teamid, userid, authority})=>teamid === membership.teamid && userid === ss.getCurrentSession().userid && authority !== 'owner').length === 1}
                                                onClick={(event)=>{
                                                    setEditingMember(membership)
                                                    setEditAuthorityAnchor(event.currentTarget)
                                                }}>     
                                                <Create className={"Avatar"}/> 
                                            </Button>
                                            :membership.authority === 'read'
                                            ?<Button
                                            disabled={teamData.filter(({teamid, userid, authority})=> userid === ss.getCurrentSession().userid && membership.teamid === teamid && authority !== 'owner').length === 1}
                                                onClick={(event)=>{
                                                    setEditingMember(membership)
                                                    setEditAuthorityAnchor(event.currentTarget)
                                                }}>     
                                                <Visibility className={"Avatar"}/> 
                                            </Button>
                                            :<Help className={"Avatar"}/>}
                                        </Avatar>} 
                                        label={
                                            <div className={"Label"}>
                                                <div className={"Text"}>{(userName[membership.userid] || "")}</div>
                                            </div>
                                        } color={
                                            membership.authority === 'owner'?'primary':
                                            membership.authority === 'write'?'secondary':
                                            membership.authority === 'read'?'default':'default'
                                        }
                                    />
                                    :<></>
                                    ))}
                                </div>
                            </div>
                            <div className={"AddTeamBox"}>
                                <IconButton
                                    size={'small'}
                                    disabled={teamData.filter(({teamid, userid, authority})=>teamid === teamKey.split(':')[0] && userid === ss.getCurrentSession().userid && authority !== 'owner').length === 1}
                                    onClick={ ()=>{
                                        const newMembership = {...pendingMembership, teamid: teamKey.split(":")[0], name: teamKey.split(":")[1]}
                                        setPendingMembership(newMembership)
                                        setIsAskingAddMember(true)
                                    }}
                                >
                                    <Add className={'AddButton'}/>
                                </IconButton>
                            </div>
                        </Card>
                        )}
                        <Card className={`AddNewCard`}>
                            <IconButton size={'small'} onClick={()=>setIsAskingCreateTeam(true)} >
                                <Add className={'AddButton'}/>
                            </IconButton>
                            <span className={"TeamText"} onClick={()=>{
                                setIsAskingCreateTeam(true)
                            }} >{'팀 추가'}</span>
                        </Card>
                    </div>
                </div>
            </div>
            <div>
                <Menu
                    anchorEl={editAuthorityAnchor}
                    keepMounted
                    elevation={1}
                    open={Boolean(editAuthorityAnchor)}
                    onClose={()=>setEditAuthorityAnchor(null)}
                >
                    <MenuList>
                        <MenuItem
                            dense={true}
                            disabled={editingMember?editingMember['authority'] === 'owner':false}
                            onClick={()=>changeAuthority('owner')}
                        > 
                            <ListItemIcon>
                                <VpnKey fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="팀장" />
                        </MenuItem>
                        <MenuItem 
                            dense={true}
                            disabled={editingMember
                            ?editingMember['authority'] === 'write' || (
                                (editingMember.teamid === ss.getCurrentSession().userid && editingMember.authority === 'owner')
                                && teamData.filter(({teamid, userid, authority})=>(teamid === editingMember.teamid)).length === 1
                            )
                            :false}
                            onClick={()=>changeAuthority('write')}
                        >
                            <ListItemIcon>
                                <Create fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="관리자" />
                        </MenuItem>
                        <MenuItem 
                            dense={true}
                            disabled={editingMember
                            ?editingMember['authority'] === 'read' || (
                                (editingMember.teamid === ss.getCurrentSession().userid && editingMember.authority === 'owner')
                                && teamData.filter(({teamid, userid, authority})=>(teamid === editingMember.teamid)).length === 1
                            )
                            :false}
                            onClick={()=>changeAuthority('read')}
                        >
                            <ListItemIcon>
                                <Visibility fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="팀원" />
                        </MenuItem>
                        <MenuItem
                            dense={true}
                            onClick={async()=>{
                                const deletedMembership = await deleteMembership({teamid: editingMember.teamid, userid: editingMember.userid})
                                
                                const newTeamData = []
                                if (deletedMembership){
                                    for (const membership of teamData) {
                                        if(`${membership.teamid}:${membership.userid}` !== deletedMembership){
                                            newTeamData.push(membership)
                                        }
                                    }
                                } else {
                                    alert('팀원 삭제를 실패했습니다.')
                                }

                                setEditAuthorityAnchor(null)
                                setTeamData(newTeamData)
                                setEditingMember(null)
                            }}
                        >
                            <ListItemIcon>
                                <Delete fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="삭제" />
                        </MenuItem>
                    </MenuList>
                </Menu>
                <Dialog open={isAskingAddMember}>
                    <DialogTitle>{"팀원 추가하기"}</DialogTitle>
                    <DialogContent style={{display:'flex', flexDirection:'row', alignItems: 'flex-end'}}>
                        <TextField label={"이메일"} value={pendingMembership.email || ''} onChange={(event)=>{
                            const newMembership = {...pendingMembership}
                            newMembership[`email`] = event.target.value
                            setPendingMembership(newMembership)
                        }} autoFocus></TextField>
                        <Select label={"권한"} value={pendingMembership.authority} onChange={(event)=>{
                            const newMembership = {...pendingMembership}
                            newMembership[`authority`] = event.target.value
                            setPendingMembership(newMembership)
                        }}>
                            <MenuItem value={"owner"}>{"팀장"}</MenuItem>
                            <MenuItem value={"write"}>{"관리자"}</MenuItem>
                            <MenuItem value={"read"}>{"팀원"}</MenuItem>
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"contained"} color={"primary"} 
                        disabled={!validateEmailForm(pendingMembership.email)}
                        onClose={()=> setIsAskingAddMember(false)} 
                        onClick={addNewMembership}>{"추가"}</Button>
                        <Button color={"secondary"} onClick={()=>{

                            setPendingMembership({authority: 'write'})
                            setIsAskingAddMember(false);

                        }}>{"취소"}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={isAskingCreateTeam}>
                    <DialogTitle>{"팀 생성하기"}</DialogTitle>
                    <DialogContent style={{display:'flex', flexDirection:'column', alignItems: 'flex-end'}}>
                        <TextField label={"팀 이름"} value={pendingMembership.name || ''} onChange={(event)=>{
                            setPendingMembership({...pendingMembership, name: event.target.value})
                        }}></TextField>
                        <TextField label={"팀 메일링"} value={pendingMembership.teamemail || ''} onChange={(event)=>{
                            setPendingMembership({...pendingMembership, teamemail: event.target.value})
                        }}></TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"contained"} color={"primary"} onClick={generateNewTeam}>{"생성"}</Button>
                        <Button color={"secondary"} onClick={()=>{

                            setPendingMembership({authority: 'write'})
                            setIsAskingCreateTeam(false);

                        }}>{"취소"}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={isAskingEditTeamName}>
                    <DialogTitle>{"팀 이름/메일링 변경"}</DialogTitle>
                    <DialogContent style={{display:'flex', flexDirection:'column', alignItems: 'flex-end'}}>
                        <TextField label={"팀 이름"} value={pendingMembership.name || ''} onChange={(event)=>{
                            setPendingMembership({...pendingMembership, name: event.target.value})
                        }}></TextField>
                        <TextField label={"팀 메일링"} value={pendingMembership.teamemail || ''} onChange={(event)=>{
                            setPendingMembership({...pendingMembership, teamemail: event.target.value})
                        }}></TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"contained"} color={"primary"} onClick={()=>{
                            if(!validateEmailForm(pendingMembership.teamemail)){
                                alert("이메일이 잘못되었습니다.");
                            }
                            changeTeamInfo();
                        }}>{"변경"}</Button>
                        <Button color={"secondary"} onClick={()=>{

                            setPendingMembership({authority: 'write'})
                            setIsAskingEditTeamName(false);

                        }}>{"취소"}</Button>
                    </DialogActions>
                </Dialog>
            </div>
            <LoadingCircle show={isLoading} />
        </div>
    )
}

export default Team;
