import React from 'react';

import Header from './Image/KakaoTalkHead.png';
import TopArrow from './Image/TopArrow.png';
import TopIcons from './Image/TopIcons.png';
import Footer from './Image/KakaoChatBottom.png';

import './KakaoTalkChat.scss';
import { Button, Select, TextField, IconButton, MenuItem, Menu, Dialog, DialogTitle, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import { randomString } from '../../Functions';
import LocalImageReader from '../../Component/LocalImageReader';
import { Add, DeleteForever, PersonAdd, SubdirectoryArrowRight } from '@material-ui/icons';
import moment from 'moment';


const KakaoTalkChat = () => {

    const loading = randomString(32);
    
    const [chatRoomData, setChatRoomData] = React.useState({
        roomName: null,
        chatting: [],
        members: {},
        owner: null
    })

    const isObjectEmpty = (obj) => {
        for (const prop in obj) {
             if (obj.hasOwnProperty(prop)) return false;
        }
        return true;
    }
    
    React.useEffect(()=>{
        if (isObjectEmpty(chatRoomData.members)){
            const initialRoomData = {...chatRoomData}
            initialRoomData.members[`${loading}`] = {
                name: "(알 수 없음)",
                url: "https://static.webtoon.today/ddah/template/kakaotalk/noimage.jpg",
            }
            initialRoomData.owner = loading
            setChatRoomData(initialRoomData)
            setChattingPerson(initialRoomData.owner)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const ChatMembers = []
    
    for (const member of Object.values(chatRoomData.members)) { 
        ChatMembers.push(member.name)
    }

    const [uniqueid, setUniqueid] = React.useState(randomString(32))

    const [chattingPerson, setChattingPerson] = React.useState(chatRoomData.owner || "")
    const [writingChat, setWritingChat] = React.useState("")
    
    const types = [
        'normal',
        'timeLine',
        'image',
        'reply'
    ]

    const [unReadingCounts, setUnReadingCounts] = React.useState({});
    
    const [addChattingElement, setAddChattingElement] = React.useState(null);
    const [indexForAddChatting, setIndexForAddChatting] = React.useState(null);

    const [isOpenReplyDialog, setIsOpenReplyDialog] = React.useState(false);

    const allChats = []
    for (const chat of chatRoomData.chatting) {
        for (const oneMessage of chat.message) {
            allChats.push({
                user: chat.user,
                text: oneMessage.text,
                type: oneMessage.type
            })
        }
    }
    const [ chattingIndexForReplyOrder, setChattingIndexForReplyOrder ] = React.useState(null);
    const [ replyMessages, setReplyMessages ] = React.useState({});
    
    return(
        <>
            <div className={"KakaoTalkChat"}>
                <div className={"ChatBody"}>
                    <div className={"Header"}>
                        <img src={Header} alt={"Header"} />
                    </div>
                    <div className={"Top"}>
                        <div className={"Distinguisher"}>
                            <img src={TopArrow} alt={"TopArrow"} />
                            <div className={"ChatRoomName"}>
                                {chatRoomData.roomName?`${chatRoomData.roomName}`
                                :ChatMembers.length >= 3?`그룹채팅`
                                :ChatMembers.length === 2?`${ChatMembers[1]}`
                                :ChatMembers.length === 1?`${ChatMembers[0]}`
                                :<></>
                                }
                                {ChatMembers.length >= 3?
                                    <div className={"MemberCounts"}>{` ${ChatMembers.length}`}</div>
                                :<></>}
                            </div>
                        </div>
                        <img src={TopIcons} alt={"TopIcons"} />
                    </div>
                    <div className={"Body"}>
                        {chatRoomData.chatting.map((chatting, index)=>
                            <div className={`${chatting.user === chatRoomData.owner?'OwnerContainer':'GuestContainer'}`} key={`container${index}`}>
                                <div hidden={chatting.user === chatRoomData.owner}>
                                    <img src={chatRoomData.members[`${chatting.user}`].url} alt={'profile'} style={{width: '30px', height: '30px', borderRadius: '10px', border: '0.2px solid rgb(180, 180, 180)'}} />
                                </div>
                                <div>
                                    <div className={"MessageUserName"} hidden={chatting.user === chatRoomData.owner} >
                                        {chatRoomData.members[chatting.user].name}
                                    </div>
                                    {chatting.message.map((eachMessage, idx) =>
                                    <div className={`ChatBalloon ${eachMessage.type === 'timeLine'?'timeLine':''}`} key={`ChatBalloon${idx}`}>
                                        {eachMessage.type !== 'timeLine'
                                        ?<span className={`${chatting.user === chatRoomData.owner?'OwnerCountsAndTimes':'GuestCountsAndTimes'}`}>
                                            <span className={`unReadingPeopleCount`}>
                                                {unReadingCounts[`count${index}-${idx}`]?unReadingCounts[`count${index}-${idx}`]:""}
                                            </span>
                                            {idx === chatting.message.length - 1 || (`${moment(chatting.message[idx + 1].time).format("hh:mm")}` !== `${moment(eachMessage.time).format("hh:mm")}`) || `${moment(chatting.message[idx + 1].time).format("A")}` !== `${moment(eachMessage.time).format("A")}`
                                            ?<span>
                                                {`${moment(eachMessage.time).format("A hh:mm").replace("AM","오전").replace("PM","오후")}`}
                                            </span>
                                            :<></>}
                                        </span>
                                        :<></>}
                                        {eachMessage.type === 'normal'
                                        ?<div 
                                            className={`${chatting.user === chatRoomData.owner?'Owner':'Guest'} ${idx === 0?chatting.user === chatRoomData.owner?'OwnerTail':'GuestTail':''}`}
                                            onClick={()=>{
                                                if (Object.keys(chatRoomData.members).length < 2) {
                                                    alert("멤버가 2명 이상일 때만 사용할 수 있습니다.")
                                                } else if (!(unReadingCounts[`count${index}-${idx}`])){
                                                    const newCount = {}
                                                    newCount[`count${index}-${idx}`] = 1
                                                    let newUnReadingCounts = {...unReadingCounts, ...newCount}
                                                    setUnReadingCounts(newUnReadingCounts)
                                                } else if (unReadingCounts[`count${index}-${idx}`] === Object.keys(chatRoomData.members).length - 1) {
                                                    let newUnReadingCounts = {...unReadingCounts}
                                                    newUnReadingCounts[`count${index}-${idx}`] = 0
                                                    setUnReadingCounts(newUnReadingCounts)
                                                } else {
                                                    let newUnReadingCounts = {...unReadingCounts}
                                                    newUnReadingCounts[`count${index}-${idx}`] ++
                                                    setUnReadingCounts(newUnReadingCounts)
                                                }
                                            }}
                                        >
                                            <span>{eachMessage.text || "."}</span>
                                        </div>
                                        :eachMessage.type === "timeLine"
                                        ?<div>
                                            <span>{`${eachMessage.text}`}</span>
                                        </div>
                                        :eachMessage.type === "image"
                                        ?<img style={{background: 'rgb(255,255,255)', maxWidth: '240px', objectFit: 'contain', borderRadius: '10px', margin: '3px 6px 3px 3px'}} src={eachMessage.text} alt={'이미지를 업로드 해주세요'} />
                                        :eachMessage.type === "reply"
                                        ?<div className={`${chatting.user === chatRoomData.owner?'Owner':'Guest'} ${idx === 0?chatting.user === chatRoomData.owner?'OwnerTail':'GuestTail':''}`}>
                                            <span className={"ReplyBalloon"}>
                                                <div className={"Person"} >
                                                    {`${replyMessages[`reply${index}-${idx}`]
                                                    ?(replyMessages[`reply${index}-${idx}`].user === chatting.user
                                                    ?"나"
                                                    :chatRoomData.members[replyMessages[`reply${index}-${idx}`].user].name)
                                                    :""}에게 답장`}
                                                </div>
                                                {(replyMessages[`reply${index}-${idx}`] && replyMessages[`reply${index}-${idx}`].type === "image"
                                                ?<img style={{width: '20px', objectFit: 'contain', borderRadius: '10px'}} src={replyMessages[`reply${index}-${idx}`].text} alt={'ReplyImage'} />
                                                :<div className={"SelectMessage"}>{replyMessages[`reply${index}-${idx}`]?replyMessages[`reply${index}-${idx}`].text:""}</div>)}
                                                <div className={"Line"}/>
                                                <div className={"ReplyText"}>{eachMessage.text || "."}</div>
                                            </span>
                                        </div>
                                        :<></>}
                                    </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>  
                    <div className={"Footer"}>
                        <img src={Footer} alt={"Footer"} />
                    </div>
                </div>
                <div className={"AddArea"}>
                    <div className={"Members"}>
                        {Object.entries(chatRoomData.members || {}).map( ([key, infomation],memberIndex) => 
                        <div key={`ScreenshotArea${memberIndex}`} className={"MemberData"}>
                            <LocalImageReader style={{width:30, height:30, border:'2px lightgray dashed'}}
                                dragOnMessage={infomation.url?<img style={{width:30, height:30}} src={infomation.url} alt={"member"}/>:null}
                                callBack={(data)=>{
                                    let newChatRoomData = {...chatRoomData};
                                    newChatRoomData.members[`${key}`].url=data;
                                    setChatRoomData(newChatRoomData)
                                }}
                            />
                            <TextField className={"MemberName"} value={infomation.name} onChange={(event)=>{
                                let newChatRoomData = {...chatRoomData};
                                newChatRoomData.members[`${key}`].name = event.target.value;
                                setChatRoomData(newChatRoomData)
                            }}/>
                        
                            <IconButton size={"small"} onClick={()=>{
                                let newChatRoomData = {...chatRoomData};
                                delete newChatRoomData.members[`${key}`]
                                let newChattings = []
                                for (const chatting of newChatRoomData.chatting) {
                                    if (chatting.user !== key) {
                                        newChattings.push(chatting)
                                    }
                                }
                                newChatRoomData.chatting = newChattings
                                newChatRoomData.owner = Object.keys(newChatRoomData.members)[0]
                                setChattingPerson(newChatRoomData.owner)
                                setChatRoomData(newChatRoomData)
                            }}>
                                <DeleteForever/>
                            </IconButton>
                        </div>)}
                        <Button fullWidth onClick={()=>{
                                let newChatRoomData = {...chatRoomData}
                                newChatRoomData.members[`${uniqueid}`]   = {name: `(알 수 없음)`, url: "https://static.webtoon.today/ddah/template/kakaotalk/noimage.jpg"}
                                setChatRoomData(newChatRoomData)
                                setUniqueid(randomString(32))
                        }}>{"멤버 추가"}<PersonAdd/> </Button>
                    </div>
                    <div className={"ChatOnwer"}>
                        <div className={"Label"}>{"채팅방 '나' 설정"}</div>
                        <Select
                            autoWidth
                            margin={'dense'}
                            className={"OwnerSelector"}
                            variant={"outlined"} 
                            value={chatRoomData.owner || ""} 
                            onChange={(event)=>setChatRoomData({...chatRoomData, owner: event.target.value})} 
                        >
                            {(Object.keys(chatRoomData.members) || []).map( (user,index) => (
                                <MenuItem className={`KakaoChatNameLists`} key={`nameList${index}`} value={`${user}`}>
                                    {chatRoomData.members[`${user}`].name || ""}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className={"Title"}>
                        <div className={"Label"}>{"채팅방 이름"}</div>
                        <TextField 
                            value={chatRoomData.roomName || ""}
                            placeholder={Object.keys(chatRoomData.members).length > 0
                            ?Object.values(chatRoomData.members).map(member => member.name).join(', ')
                            :"(대화 상대 없음)"}
                            onChange={event => {
                                let newChatRoomData = {...chatRoomData};
                                newChatRoomData.roomName = event.target.value;
                                setChatRoomData(newChatRoomData)
                            }}
                        />
                    </div>
                    <div className={"Preview"}>
                        <div className={"SetWriter"}>
                            <div className={"Label"}>{"작성자"}<span>{" "}</span></div>
                            <Select
                                autoWidth
                                variant={'outlined'}
                                margin={'dense'}
                                value={chattingPerson}
                                onChange={(event)=>setChattingPerson(event.target.value)}
                            >
                                {(Object.keys(chatRoomData.members) || []).map( (user,index) => (
                                    <MenuItem className={`KakaoChatNameLists`} key={`user ${index}`} value={`${user}`}>
                                        {chatRoomData.members[`${user}`].name || ""}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <TextField 
                            fullWidth
                            margin={'dense'}
                            variant="outlined" 
                            multiline 
                            rows={2} 
                            value={writingChat}
                            placeholder={"채팅을 입력하고 Enter키를 눌러 입력해주세요."}
                            onChange={(event)=>setWritingChat(event.target.value)}
                            onKeyDown={(e)=>{
                                if ((e.key === 'Enter' && !e.shiftKey) && chattingPerson.length > 0){
                                    let newChatRoomData = {...chatRoomData}
                                    if (!(writingChat.replace(/\s/gi,"").length > 0)){
                                        alert("채팅 내용이 없습니다.")
                                    } else {
                                        if (!newChatRoomData.chatting[0] || newChatRoomData.chatting[newChatRoomData.chatting.length - 1].user !== chattingPerson){
                                            newChatRoomData.chatting[newChatRoomData.chatting.length] = {
                                                user: chattingPerson,
                                                message: [{text: writingChat, time: new Date().getTime(), type: 'normal'}]
                                            }
                                        } else if (newChatRoomData.chatting[newChatRoomData.chatting.length - 1].user === chattingPerson) {
                                            newChatRoomData.chatting[newChatRoomData.chatting.length - 1].message.push({
                                                text: writingChat, time: new Date().getTime(), type: 'normal'
                                            })
                                        }
                                    }
                                    setChatRoomData(newChatRoomData)
                                    e.preventDefault();
                                    setWritingChat("")
                                } 
                            }}
                        />
                    </div>
                    <div className={"ChattingArea"} hidden={chatRoomData.chatting.length === 0}>
                        {chatRoomData.chatting.map((chatting, index)=>
                            <div className={`ChatBox ${index === chatRoomData.chatting.length - 1?'LastChatBox':''}`} key={`ChatPeopleList${index}`}>
                                <div className={"UserInfomations"}>
                                    <img style={{width: '20px', height: '20px'}} src={chatRoomData.members[`${chatting.user}`].url} alt={'profile'} />
                                    <div className={"UserNameInChat"}>
                                        {chatRoomData.members[chatting.user].name}
                                    </div>
                                </div>
                                <div className={"Chattings"}>
                                    {chatting.message.map((eachMessage, idx) =>
                                    <div key={`messages${index}-${idx}`} className={"ChattingOutline"}>
                                        {eachMessage.type === 'reply'
                                        ?<IconButton
                                            size={'small'}
                                            className={'ReplyChooseButton'}
                                            onClick={()=>{
                                            setChattingIndexForReplyOrder(`${index}-${idx}`)
                                            setIsOpenReplyDialog(true)
                                        }}>
                                            <SubdirectoryArrowRight />
                                        </IconButton>
                                        :<></>}
                                        {eachMessage.type !== 'image' 
                                        ?<TextField variant="outlined" multiline rows={2} value={eachMessage.text} margin={'dense'}
                                            onChange={(event)=>{
                                                let newChatRoomData = {...chatRoomData}
                                                newChatRoomData.chatting[index].message[idx].text = event.target.value
                                                setChatRoomData(newChatRoomData)
                                            }}
                                        />
                                        :<LocalImageReader style={{width: '185px', height: '55px', border:'2px lightgray dashed', cursor: 'pointer'}}
                                            dragOnMessage={eachMessage.text.includes('data')?<img style={{objectFit: 'contain', width: '100%', height: '100%'}} src={eachMessage.text} alt={"member"}/>:null}
                                            callBack={(data)=>{
                                                let newChatRoomData = {...chatRoomData};
                                                newChatRoomData.chatting[index].message[idx].text=data;
                                                setChatRoomData(newChatRoomData)
                                            }}/>
                                        }
                                        <div className={"TimePicker"}>
                                            <TextField
                                                id="time"
                                                type='time'
                                                disabled={eachMessage.type === 'timeLine'}
                                                value={`${moment(eachMessage.time).format("HH:mm")}`}
                                                onChange={(e)=>{
                                                    let newChatRoomData = {...chatRoomData}
                                                    const values = e.target.value.split(":")
                                                    newChatRoomData.chatting[index].message[idx].time = Number(`${moment(eachMessage.time).set('hour', Number(values[0])).set('minute', Number(values[1])).unix()}`) * 1000
                                                    setChatRoomData(newChatRoomData)
                                                }}
                                            />
                                            <Select
                                                autoWidth
                                                value={eachMessage.type}
                                                onChange={(event)=>{
                                                    let newChatRoomData = {...chatRoomData}
                                                    if (eachMessage.type === 'image') {
                                                        newChatRoomData.chatting[index].message[idx].text = "."
                                                    }
                                                    newChatRoomData.chatting[index].message[idx].type = event.target.value
                                                    if (event.target.value === 'timeLine') {
                                                        newChatRoomData.chatting[index].message[idx].time = eachMessage.time - 60*60*24*7*1000
                                                    } else {
                                                        newChatRoomData.chatting[index].message[idx].time = eachMessage.time + 60*60*24*7*1000
                                                    }
                                                    setChatRoomData(newChatRoomData)
                                                }} 
                                            >
                                                {types.map((type, typeIndex)=>(
                                                    <MenuItem 
                                                        className={`KakaoChatTypeList ${idx===0 && type==='timeLine'?'KakaoChatTypeTimeLineHidden':''}`} 
                                                        key={`type${idx}-${typeIndex}`} 
                                                        value={`${type}`}>
                                                        {type}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className={"DeleteAddFunctions"}>
                                            <IconButton className={"DeleteMessageButton"} size={'small'} onClick={event=>{
                                                    let newChatRoomData = {...chatRoomData};
                                                    if (newChatRoomData.chatting[index].message.filter((ele, newIndex) => ele.text !== eachMessage.text || newIndex !== idx).length === 0){
                                                        newChatRoomData.chatting = newChatRoomData.chatting.filter((element, elementIndex)=>index !== elementIndex)
                                                        setChatRoomData(newChatRoomData)
                                                    }else{
                                                        newChatRoomData.chatting[index].message = [...newChatRoomData.chatting[index].message.filter((ele,order) => ele.text !== eachMessage.text || order !== idx)]
                                                        setChatRoomData(newChatRoomData)
                                                    }
                                                }}
                                            >
                                                <DeleteForever/>
                                            </IconButton>

                                            <IconButton size={'small'} className={'AddMessageButton'} onClick={()=>{
                                                let newChatRoomData = {...chatRoomData}
                                                newChatRoomData.chatting[index].message = newChatRoomData.chatting[index].message.slice(0,idx + 1).concat({
                                                    text: ".", time: chatRoomData.chatting[index].message[idx].time, type: "normal"
                                                }).concat(newChatRoomData.chatting[index].message.slice(idx + 1, newChatRoomData.chatting[index].message.length))
                                                setChatRoomData(newChatRoomData)
                                            }}>
                                                <Add/>
                                            </IconButton>
                                        </div>
                                    </div>
                                    )}
                                    {index !== chatRoomData.chatting.length - 1
                                    ?<div className={"AddChattingClickBox"}>
                                        <IconButton style={{width: 20, height: 20}} onClick={(event)=>{
                                            setIndexForAddChatting(index)
                                            setAddChattingElement(event.currentTarget)
                                        }}>
                                            <Add/>
                                        </IconButton>
                                        <Menu 
                                            anchorEl={addChattingElement} 
                                            keepMounted 
                                            open={Boolean(addChattingElement)}
                                            onClose={()=>setAddChattingElement(null)}
                                        >
                                            {(Object.keys(chatRoomData.members) || []).map((user,menuIndex) => (
                                                <MenuItem key={`AddChatting${menuIndex}`} value={`${user}`} onClick={()=>{
                                                    let newChatRoomData = {...chatRoomData}
                                                    newChatRoomData.chatting = newChatRoomData.chatting.slice(0,indexForAddChatting + 1).concat({
                                                        user: `${user}`,
                                                        message:[{text: ".", time: new Date().getTime(), type: "normal"}]
                                                    }).concat(newChatRoomData.chatting.slice(indexForAddChatting + 1, newChatRoomData.chatting.length))
                                                    setChatRoomData(newChatRoomData)
                                                    setAddChattingElement(null)
                                                }}>
                                                    {chatRoomData.members[`${user}`].name || ""}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </div>
                                    :<></>}
                                    
                                </div>
                            </div>
                        )}
                        <Dialog open={isOpenReplyDialog}>
                            <DialogTitle>{"답장 메시지 선택하기"}</DialogTitle>
                            <List className={"KakaoChatDialogReplyList"}>
                                {allChats.map((chatting, index)=>
                                <ListItem button onClick={() => {
                                    let newReplyMessages = {...replyMessages}
                                    newReplyMessages[`reply${chattingIndexForReplyOrder}`] = chatting
                                    setReplyMessages(newReplyMessages)
                                    setChattingIndexForReplyOrder(null)
                                    setIsOpenReplyDialog(false)
                                    }} key={`Dialog${index}`}>
                                    <ListItemAvatar>
                                        <Avatar style={{display: 'flex', flexDirection: 'column'}}>
                                            <img style={{width: '20px'}} src={chatRoomData.members[`${chatting.user}`].url} alt={"sdjgiel"} />
                                            <div style={{fontSize: '0.6rem'}}>{`${chatRoomData.members[`${chatting.user}`].name}`}</div>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText className={'KakaoChatDialogListReplyText'} primary={chatting.text} />
                                </ListItem>
                                )}
                            </List>
                        </Dialog>
                    </div>
                </div>
                
                <div className={"Example"}>
                    <span className={"ExampleTitle"}>{"사용법"}</span>
                    <ul>
                        <li><b>{"멤버 추가"}</b>{"를 눌러 채팅방에 참여하는 사람을 추가할 수 있습니다."}</li>
                        <li>{"멤버가 2명 이상일 때 채팅을 클릭하면 최대 (멤버수-1)명의 읽지않은 사람의 수를 표시할 수 있습니다."}</li>
                        <li>{"채팅방의 '나'를 멤버중에서 설정할 수 있습니다."}</li>
                        <li>{"채팅방의 이름을 설정할 수 있습니다."}</li>
                        <li>{"채팅의 작성자를 선택하고 채팅을 입력한 후 엔터 키를 눌러서 입력할 수 있습니다."}</li>
                        <li>{"원하는 채팅의 내용, 시간을 변경할 수 있습니다."}</li>
                        <li>{"normal은 일반 메시지이고 timeLine은 날짜선이며, 채팅 입력으로 시간을 설정할 수 있습니다."}</li>
                        <li>{"image는 채팅 내용을 초기화하고 이미지 파일을 업로드해 나타냅니다."}</li>
                        <li>{"reply는 좌측의 화살표를 눌러 원하는 채팅에 답장을 설정할 수 있습니다"}</li>
                        <li>{"각 채팅의 우측에 있는 삭제 버튼은 해당메시지를 삭제하고 추가 버튼은 해당 메시지의 다음에 메시지를 추가합니다."}</li>
                        <li>{"프로필 위쪽의 추가 버튼으로 원하는 멤버의 채팅을 원하는 곳에 생성할 수 있습니다."}</li>
                        <li>{"다음 이미지를 드래그해서 넣을 수도 있습니다."}</li>
                        <img src="https://static.webtoon.today/ddah/template/kakaotalk/kakaoTalk.jpg" alt={"kakaotalk"}/>
                        <img src="https://static.webtoon.today/ddah/template/kakaotalk/noimage.jpg" alt={"kakaotalk"}/>
                        <img src="https://static.webtoon.today/ddah/template/kakaotalk/gift.jpg" alt={"kakaotalk"}/>
                        <img src="https://static.webtoon.today/ddah/logo-04.png" alt={"kakaotalk"}/>
                        <li>{"편집이 완료되면 카카오톡 채팅방 영역을 캡쳐합니다."}</li>
                        <li>{"본 편집기능으로 제작한 이미지는 별도의 저작권 표시 없이 이용이 가능합니다."}</li>
                        {"단, 최종 작업물에 포함된 상표, 이미지, 인물 등에 대해서는 사용자 스스로 권리관계를 확인해야 합니다."}
                    </ul>
                </div>
            </div>
            
        </>
    )
}

export default KakaoTalkChat ;