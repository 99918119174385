
import React, { useEffect, useRef, useState } from 'react';
import './SummaryTable.scss';
import { numericUnitConverter } from '../Util/Functions';

const informationLabel = {
    "target": `내 작품보다 높은 매출을 내고 있는 유사작으로\n목표로 삼을만한 작품입니다.`,
    "similarTop": `내 작품의 유사작 중 가장 높은 매출을 내는 작품입니다.`
}

const SummaryTable = ({ 
    data, labels 
}) => {
    const hasSimilarTop = data.similarTop.title
                            ? ["target", "similarTop"]
                            : ["comparison", "target"]    

    return(<div className={'SummaryTableArea'}>
        {hasSimilarTop
            .map( (type, idx) => {
                const info = data[`${type}`];
            
                return <div className={'WebtoonDiagnosis'} key={`${type}`} >
                    <div className={'WebtoonTitle'}>
                        <div className={'TitleText'}>
                            {labels[type] && <span className={'Target'}>{`${labels[type]}`}</span>}
                            <span className={'Title'}>{`${info.title || "-"}`}</span>
                        </div>
                        {type !== "comparison" && <IconContainer text={informationLabel[type]} />}
                    </div>
                    <div className={'WebtoonResults'} >
                        <div className={'ExposureResult'} >
                            <div className={'Exposure Row'}  >
                                <div className={'TrendBox'} >
                                    <img className={'ArrowUpIcon'} src={'https://static.webtoon.today/ddah/icon/arrow_up_sm.svg'} alt={'arrow_up_sm'} width={15} />
                                    <span className={'TrendValue'} >{`${type===hasSimilarTop[0]?"준수한":type===hasSimilarTop[1]?"높은":""}`}</span>
                                </div>
                                <span className={'StandardName'}>{'인지도'}</span>
                            </div>
                            <div className={'SearchResult Row'} >
                                <div className={'StandardName'}>{'검색결과'}</div>
                                <div className={'SearchResultFlexCell'} >
                                    <span className={'StandardName'}>{'구글'}</span>
                                    <span className={'StandardValue'}>{`${numericUnitConverter(Number(info?.rawData?.searchExposure || 0), 1)} 개`}</span>
                                </div>
                            </div>
                            <div className={'Subscribers Row'} >
                                <div className={'StandardName'}>{'관심독자'}</div>
                                <div className={'SearchResultFlexCell'}>
                                    <span className={'StandardValue'}>{`${numericUnitConverter(Number((info.rawData.popularity || 0)), 1)} 명`}</span>
                                </div>
                            </div>
                        </div>
                        <div className={'ProfitabilityResults'}>
                            <div className={'Profitability Row'} >
                                <div className={'TrendBox'} >
                                    <img className={'ArrowUpIcon'} src={'https://static.webtoon.today/ddah/icon/arrow_up_sm.svg'} alt={'arrow_up_sm'} width={15} />
                                    <span className={'TrendValue'}>{`${type===hasSimilarTop[0]?"준수한":type===hasSimilarTop[1]?"높은":""}`}</span>
                                </div>
                                <span className={'StandardName'} >{'수익성'}</span>
                            </div>
                            <div className={'Conversion Row'} style={{display: 'flex', flexDirection: 'column'}}>
                                <div className={'StandardName'}>{'전환율'}</div>
                                <div className={'StandardValue'} >{`${((info.rawData.firstPaidEpLike||0) / (info.rawData.firstFreeEpLike||1)*100).toFixed(1)||100} %`}</div>
                            </div>
                            <div className={'Subscribers Row'} >
                                <div className={'StandardName'}>{'인 당 다운로드 수'}</div>
                                <div className={'StandardValue'}>{`${Number((info.rawData.download/info.rawData.popularity || 0).toFixed(1))} 회`}</div>
                            </div>
                        </div>
                    </div>
                </div>
            })}
    </div>)
}

const IconContainer = ({ text }) => {

    const [isInfoShown, setIsInfoShown] = useState(false);
    const infoBoxRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (infoBoxRef.current && !infoBoxRef.current.contains(event.target)) {
                setIsInfoShown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return(<div className={'InfoContainer'}>
        <img 
            className={'InfoCircleIcon'} 
            src={'https://static.webtoon.today/ddah/icon/information_circle.svg'} 
            alt={'information_circle'}
            onClick={() => setIsInfoShown(true)}
        />
        {isInfoShown &&
            <div className={`FloatMessage`} ref={infoBoxRef} >
                {text}
            </div>
        }
    </div>)
}

export default SummaryTable;