
import axios from 'axios'
import ss from './Session';

/**
 * @typedef {{
 *  serviceId: string,
 *  titleId: string,
 *  process: string,
 *  upside: number,
 *  downside: number
 * }} process
 * */


/**
 * 
 * @returns {Promise<process[]>}
 */
export const listProcess = async () => {
    let res = await axios.get('https://api.webtoon.today/process', {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    }else {
        return [];
    }
}

/**
 * 
 * @param {process} process
 * @returns {Promise<string|false>}
 */
export const createProcess = async (process) => {
    if (!ss.getCurrentSession().userid || !process.serviceId || !process.titleId || !process.process){
        return false;
    }
    
    let res = await axios.put('https://api.webtoon.today/process', process, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.id;
    }else{
        return false;
    }
}

/**
 * 
 * @param {import('./Session').sessionType} session 
 * @param {process} process
 * @returns {Promise<string|false>}
 */
export const updateProcess = async (process) => {
    if (!ss.getCurrentSession().userid || !process.serviceId || !process.titleId || !process.process){
        return false;
    }
    
    let res = await axios.patch('https://api.webtoon.today/process', process, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.id;
    }else{
        return false;
    }
}


/**
 * 
 * @param {import('./Session').sessionType} session 
 * @param {process} process
 * @returns {Promise<string|false>}
 */
export const deleteProcess = async (process) => {
    if (!ss.getCurrentSession().userid || !process.serviceId || !process.titleId || !process.process){
        return false;
    }
    
    let res = await axios.delete(`https://api.webtoon.today/process?serviceId=${process.serviceId}&titleId=${process.titleId}&process=${process.process}`, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.id;
    }else{
        return false;
    }
}