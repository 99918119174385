import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, MenuItem, Select, TextField } from '@material-ui/core';

import React from 'react';
import EditableTable from '../../Component/Table/EditableTable';

import {getTitles, createTitle, updateTitle} from '../../Data/Title';
import ss from '../../Data/Session';

import {fn, koreanFirstSort, createFuzzyMatcher} from '../../Functions';
import { Add, EditRounded, ListAlt, WarningRounded, Search as SearchIcon } from '@material-ui/icons';

import './TitleList.scss';
import LoadingCircle from '../../Component/LoadingCircle';
import EditableFileField from '../../Component/Table/EditableFileField';

const TitleAdmin = ({serviceId}) => {

    const [titles, setTitles] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const refreshTitles = async (serviceId) => {
        setIsLoading(true);
        let newtitles = await getTitles([], true, null);
        setTitles(newtitles);
        setIsLoading(false);
    }
    React.useEffect(()=>{
        refreshTitles(serviceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serviceId]);

    const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
    const [, setInnerWidth] = React.useState(window.innerWidth);
    React.useEffect(()=>{
        const resizeListener = () => {
            setInnerHeight(window.innerHeight)
            setInnerWidth(window.innerWidth);
        }
        window.addEventListener('resize', resizeListener);

        return ()=>{
            window.removeEventListener('resize', resizeListener);
        }
    },[])

    const [newServiceId, setNewServiceId] = React.useState(serviceId);
    const [newTitleId, setNewTitleId] = React.useState("");
    const [errorMessageForTitleId, setErrorMessageForTitleId] = React.useState("");
    const [errorMessageForServiceId, setErrorMessageForServiceId] = React.useState("");
    const [askNewTitleId, setAskNewTitleId] = React.useState(false);

    const [mode, setMode] = React.useState(localStorage.getItem('titleList:mode') || 'tile');
    const [sorting, setSorting] = React.useState("lastUpdatedAt");
    const [keyword, setKeyword] = React.useState("");
    
    const [maximumTitles, setMaximumTitles] = React.useState(20);

    return (<div className={"TitleList"}>
        <div hidden={mode === 'table'}>
            <div className={"SearchArea"}>
                <div className={"SearchBar"}>
                    <div className={"SearchIcon"}>
                        <SearchIcon/>
                    </div>
                    <TextField className={"SearchField"} placeholder={"Search..."} value={keyword} onChange={(event)=>{
                        setKeyword(event.target.value);
                    }} />
                </div>
            </div>
            <div className={"SortingFunctions"} >
                <div>
                    {[["작품 등록 최신순", "lastUpdatedAt"], ["가나다순","descending"] , ["가나다역순","ascending"], ["첫 편 최신순","upToDateFirstEpisode"], ["마지막 편 최신순","upToDateLastEpisode"]]
                    .map(([name, method]) =>
                        <Button key={method} className={`${sorting === `${method}`?"Focusing":""}`} onClick={() => setSorting(method)}>
                            {name}
                        </Button>)}
                </div>
                <div className={"SelectBox"}>
                    <FormControl>
                        <Select 
                            value={maximumTitles} 
                            onChange={(event) => setMaximumTitles(event.target.value)}
                        >
                            <MenuItem value={20}>{"최대 20개"}</MenuItem>
                            <MenuItem value={40}>{"최대 40개"}</MenuItem>
                            <MenuItem value={60}>{"최대 60개"}</MenuItem>
                            <MenuItem value={`${Number(titles.length)}`}>{"전체보기"}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
        <div className={"TitleListContainer"}>
            {mode === 'table'
                ?<EditableTable
                    style={{maxHeight: innerHeight - 130, overflow: 'auto'}}
                    headerButtonHeaders={[
                    ]}
                    columnHeaders={['썸네일', '제목', '설명', '생성일', '수정']}
                    defaultSortCol={[3, 'desc']}
                    headerButtonsFunction={(row, index)=>[]}
                    columnsFunction={(row, index)=>[
                        {isEditing: false, type: 'file', field: 'thumbnail', options:[], style: {width: 150, height:80, margin: 5}, defaultValue: row.thumbnail},
                        {isEditing: false, type: 'dummy', field: 'title', options:[], style: {whiteSpace: 'nowrap', wordBreak: 'none'}, defaultValue: <div><div style={{fontWeight:"bold"}}>{row.title || "(제목 없음)"}</div><div style={{fontSize:'0.8rem', color: 'rgb(40,40,40)'}}>{`${row.serviceId}/${row.titleId}`}</div></div>},
                        {isEditing: false, type: 'string', field: 'description', options:[], style:{width: 400, height:90, overflow: 'hidden', textOverflow: 'ellipsis'}, defaultValue: row.description},
                        {isEditing: false, type: 'date', field: 'grantedAt', defaultValue: row.firstEpisodeUpdatedAt},
                        {isEditing: false, type: 'dummy', field: 'gotoEpisodeEdit', defaultValue: <IconButton href={`/home/contents/${row.serviceId}/${row.titleId}#edit`} onClick={fn.gotoByAnchor}><EditRounded/></IconButton>},
                    ]}
                    updateGenerator={(row, index) => async (newValue) => {
                        updateTitle({...row, ...newValue})
                        for(const [key, value] of Object.entries(newValue)){
                            row[key] = value;
                        }
                    }}
                    data={titles}
                    name={"titles"}

                />
                :<div className={"CellContainer"}>
                    {titles
                    .filter( title => createFuzzyMatcher(`${keyword.toLowerCase()}`).test((title.title || "").toLowerCase()) )
                    .sort((a,b) => {
                        if (sorting === "lastUpdatedAt") {return b.lastUpdatedAt.localeCompare(a.lastUpdatedAt)}
                        else if (sorting === "descending") { return koreanFirstSort(a.title, b.title); }
                        else if (sorting === "ascending") { return koreanFirstSort(b.title, a.title); }
                        else if (sorting === "upToDateFirstEpisode") { return b.firstEpisodeUpdatedAt - a.firstEpisodeUpdatedAt; }
                        else if (sorting === "upToDateLastEpisode") { return b.lastEpisodeUpdatedAt - a.lastEpisodeUpdatedAt; }
                        return b.firstEpisodeUpdatedAt - a.firstEpisodeUpdatedAt;
                    })
                    .slice(0, maximumTitles)
                    .map(title =>
                        <Button key={`${title.serviceId}:${title.titleId}`} className={"TitleCell"} onClick={fn.gotoByAnchor} href={`/home/contents/${title.serviceId}/${title.titleId}`}>
                            <EditableFileField style={{width: 50, height: 50, marginLeft: 35, marginRight: 35, marginTop: 20, marginBottom: 0}} isEditing={false} defaultValue={title.thumbnail}/>
                            <div className={"CellTitle"}>{title.title}</div>
                        </Button>)}
                </div>}
        </div>
        <div className={"TitleListTail"}>
            {!isLoading && titles.length === 0
            ?<div className={"NoTitleWarning"}>
                <WarningRounded/>
                {"아직 생성된 작품이 없습니다."}
            </div>:<></>}
            
            <div style={{display: "flex", flexDirection:"row"}}>
            <Button onClick={()=>setAskNewTitleId(true)}>
                <Add/>{"새로운 작품"}
            </Button>
            <Button onClick={()=>{
                if (mode === "tile"){
                    setMode("table");
                    localStorage.setItem("titleList:mode", "table");
                }else{
                    setMode("tile");
                    localStorage.setItem("titleList:mode", "tile");
                }
            }}>
                <ListAlt/>{"목록보기"}
            </Button>
            </div>
        </div>
        <Dialog open={askNewTitleId}>
            <DialogTitle>{"새 작품 코드를 입력해주세요."}</DialogTitle>
            <DialogContent>
                <div>
                    {"띄어쓰기 없이 영어대소문자, '_', '-', 숫자만을 이용해서 작가코드를 입력해주세요."}
                </div>
                <TextField value={newServiceId} onChange={(event)=>{
                    if (!ss.getCurrentSession().email || !(ss.getCurrentSession().email.endsWith('@webtoon.today') || ss.getCurrentSession().email.endsWith('@webtoontoday.com')) ){
                        setErrorMessageForServiceId("서비스ID 수정 권한이 없습니다.")
                        return;
                    }

                    let newValue = event.target.value
                    if (/[^0-9a-zA-Z\-_]/.exec(newValue)){
                        setErrorMessageForServiceId("허용되지 않은 문자가 있습니다.")
                        return;
                    }
                    setErrorMessageForServiceId("");
                    setNewServiceId(newValue);
                }} error={errorMessageForServiceId !== ""} helperText={errorMessageForServiceId}/>
                <div>
                    {"띄어쓰기 없이 영어대소문자, '_', '-', 숫자만을 이용해서 작품코드를 입력해주세요."}
                </div>
                <TextField value={newTitleId} onChange={(event)=>{
                    let newValue = event.target.value
                    if (/[^0-9a-zA-Z\-_]/.exec(newValue)){
                        setErrorMessageForTitleId("허용되지 않은 문자가 있습니다.")
                        return;
                    }
                    setErrorMessageForTitleId("");
                    setNewTitleId(newValue);
                }} error={errorMessageForTitleId !== ""} helperText={errorMessageForTitleId}/>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"primary"} onClick={async ()=>{
                    let res = await createTitle({
                        serviceId: newServiceId,
                        titleId: newTitleId,
                    });
                    console.log(res)
                    refreshTitles(serviceId);
                    setAskNewTitleId(false);
                }}>{"제출"}</Button>
                <Button color={"secondary"} onClick={()=>{
                    setNewTitleId("");
                    setErrorMessageForServiceId("");
                    setErrorMessageForTitleId("");
                    setAskNewTitleId(false);
                }}>{"취소"}</Button>
            </DialogActions>
        </Dialog>
        <LoadingCircle show={isLoading}/>
    </div>);
}

export default TitleAdmin;