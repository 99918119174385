
import axios from 'axios'
import ss from '../../../../Data/Session';

/**
 * @typedef {{
 *  serviceId: string,
 *  titleId: string,
 *  episodeId?: string,
 *  snapshotId?: string,
 *  ctr_rank: number,
*  fullread_rank: number,
 *  retention: number
* }} titleRankType
 * */


/**
 * 
 * @param {{
 *  serviceId: string,
 *  titleId: string,
 *  episodeId: string,
 *  snpashotId: string
 * }} indexInfo
* @returns {Promise<titleRankType[]>}
 */
export const listTitleRank = async (indexInfo) => {
    if ( !indexInfo.serviceId || !indexInfo.titleId ){
        console.log(ss.getCurrentSession(), indexInfo)
        return [];
    }
    let res = await axios.post('https://challenge-api.webtoon.today/title-rank', indexInfo, {
    headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    }else {
        return [];
    }
}
