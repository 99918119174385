import axios from "axios";
import ss from '../../../../Data/Session';

export const getAllProductInfo = async () => {
    let ret = [];

    let res = await axios.get(`https://challenge-api.webtoon.today/webtoon/product?type=all`, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if(res.data && res.data.code === 200){
        ret = res.data.data;
    }else{
        console.error(res.code, res.data);
    }

    return ret;
}

export const createOrUpdateProduct = async ({id, name, detail, amount, finalAmount, image}) => {
    try{
        let res = await axios.put('https://challenge-api.webtoon.today/webtoon/product', [{
            id, name, detail, amount, finalAmount, image
        }], {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
    
        if (res.data && res.data.code === 200){
            
            return res.data.data;
    
        }else{
            return null;
        }
    }catch(e){
        return null;
    }

}