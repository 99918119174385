import axios from 'axios';

/**
 * @typedef {{
 *  title: string,
 *  descrption: string,
 *  thumbnail: string,
 *  author: string,
 *  url: string,
 * }} challengeDataType
 */

/**
 * 
 * @param {string} endPage
 * @returns {Promise<challengeDataType>}
 */
export const readChallengeData = async (endPage) => {

    try{
        let res = await axios.get(`https://cached-api.webtoon.today/read-from-challenge?endPage=${endPage}`, );
        
        if (res.data && res.data.code === 200){
            return res.data.data;
        }else {
            return {}
        }
    }catch(e){
        console.error(e)
        return {};   
    }
}