import React from 'react';
import { Avatar, Button, IconButton, Paper, Tooltip } from '@material-ui/core';
import { useDrag, useDrop } from 'react-dnd';

import { listMyOwnTitles } from '../../Data/Ownership';
import { getTitles } from '../../Data/Title';
import { deleteProcess, listProcess, updateProcess } from '../../Data/Process';

import './Process.scss';
import { Delete, FolderOpen } from '@material-ui/icons';
import LoadingCircle from '../../Component/LoadingCircle';


const DragableIcon = ({serviceId, titleId, title, value = 0, thumbnail, className="", onClick, onDelete=null}) => {

    const [{opacity}, dragRef] = useDrag(
        ()=> ({
            type: 'icon',
            item: {serviceId, titleId, title, thumbnail},
            collect: (monitor) => ({
                opacity: monitor.isDragging()?0.5:1
            })
        }), [])
    
    let BodyComponent = onDelete?Paper:Button;

    return <Tooltip title={title}>
            <BodyComponent ref={dragRef} style={{opacity}} elevation={0} className={"ButtonHolder"} {...(onDelete?{}:{onClick})}>
            <Avatar className={className} src={thumbnail}></Avatar>
            <div className={"ButtonText"}>{title}</div>
            {onDelete
                ?<IconButton className={"DeleteButton"} onClick={onDelete}><Delete style={{fontSize: 12}}/></IconButton>
                :<></>}
        </BodyComponent>
    </Tooltip>;
}

const DropBox = ({row, col, processes, setProcesses, children}) => {
    const [, drop] = useDrop(() => ({
        accept: 'icon',
        drop: (item, mointor) => {
            let newProcesses = processes.filter(row => row.serviceId !== item.serviceId || row.titleId !== item.titleId || row.process !== `${col}`);
            newProcesses.push({
                ...item,
                process: `${col}`,
                upside: row, downside: row,
            })
            setProcesses(newProcesses)

            updateProcess({
                ...item,
                process: `${col}`,
                upside: row, downside: row,
            })
        }
    
    }), [processes, setProcesses])
  
    return <div ref={drop} className={"Col"}>{children}</div>
}

const processLabel = {
    0: "최초 미팅",
    1: "기획 검토",
    2: <div>{"기획 개발"}<br/><span style={{fontSize:'0.7rem'}}>{"(관리 방식 합의)"}</span></div>,
    3: <div>{"샘플 제작"}<br/><span style={{fontSize:'0.7rem'}}>{"(체크리스트 통과)"}</span></div>,
    4: <div>{"작품 개발"}<br/><span style={{fontSize:'0.7rem'}}>{"(연독률70% 통과)"}</span></div>,
    5: "투고",
    6: "준비 종료",
    7: "연재중",
}

const valueLabel = {
    [-3]: "연재 포기",
    [-2]: "역마진 감수",
    [-1]: "비독점 불가",
    0: "회당 40만원↓",
    1: "회당 60만원↓",
    2: "회당 90만원↓",
    3: "회당 90만원↑",
}

const Process = () => {

    
    const [titles, setTitles] = React.useState([]);
    const [processes, setProcesses] = React.useState([]);
    const [displayableProcesses, setDisplayableProcesses] = React.useState([]);
    const [processKeys, setProcessKeys] = React.useState(new Set([]));
    const [isLoading, setIsLoading] = React.useState(true);
    const [focusOn, setFocusOn] = React.useState(null);
    const [deleteMode, setDeleteMode] = React.useState(false);

    const [isShowingCandidates, setIsShowingCandidates] = React.useState(false);

    const refreshTitles = async () => {
        let myOwnTitles = await listMyOwnTitles();

        let newtitles = await getTitles(myOwnTitles.map(title => [title.serviceId, title.titleId]), false);
        setTitles(newtitles);

        let newProcess = await listProcess();

        let titlesDict = Object.fromEntries(newtitles.map(title => [`${title.serviceId}:${title.titleId}`, title]))

        newProcess = newProcess.map(process => ({
                ...process,
                thumbnail: (titlesDict[`${process.serviceId}:${process.titleId}`] || {}).thumbnail || '',
                title: (titlesDict[`${process.serviceId}:${process.titleId}`] || {}).title || '',
            }))
        
        setProcesses(newProcess);
    }
    React.useEffect(()=>{
        setProcessKeys(new Set(processes.map(row => `${row.serviceId}:${row.titleId}`)));
    },[processes])

    React.useEffect(()=>{
        let newDisplayable = [];
        if (focusOn){
            newDisplayable = processes.filter(item => `${item.serviceId}:${item.titleId}` === focusOn)
        }else {
            let uniqueProcess = {};

            processes.forEach(item => {
                let old = uniqueProcess[`${item.serviceId}:${item.titleId}`];
                if (!old || old.process < item.process){
                    uniqueProcess[`${item.serviceId}:${item.titleId}`] = item;
                }
            })
            newDisplayable = Object.values(uniqueProcess)
        }
        setDisplayableProcesses(newDisplayable)
    },[focusOn, processes])

    React.useEffect(()=>{
        (async ()=> {
            setIsLoading(true);
            await refreshTitles();
            setIsLoading(false);
        })();
    },[]);

    return (
        <div className={"Process"}>
            <div className={"ControlBox"}>
                <Tooltip title={"전체 작품 보기"}>
                    <Button
                        onClick={()=>setIsShowingCandidates(!isShowingCandidates)}
                    >
                        <FolderOpen/>
                        {"전체 작품 보기"}
                    </Button>
                </Tooltip>
                <Tooltip className={"HiddenForNarrowDevice"} title={"삭제모드"}>
                    <Button
                        onClick={()=>setDeleteMode(!deleteMode)}
                    >
                        <Delete/>
                        {"삭제모드"}
                    </Button>
                </Tooltip>
            </div>
            <div className={"Chart"}>
                
                {Array(7).fill(0).map((v,i) => i-3).reverse().map(row =>
                    (!isShowingCandidates && row === -3)?<div key={`-3`}></div>:
                    <div key={row} className={"Row"}>
                        <div className={"Header Col"}>
                            {valueLabel[row]}
                        </div>
                        {Array(8).fill(0).map((v,i) => i).map(col => 
                            <DropBox key={`${row}:${col}`} {...{row, col, processes, setProcesses}}>
                                {displayableProcesses.filter(process => `${process.process}` === `${col}` && (process.upside + process.downside) /2 === row).map(item =>
                                    <DragableIcon
                                        key={`${item.serviceId}:${item.titleId}:${item.process}`}
                                        serviceId={item.serviceId} titleId={item.titleId}
                                        title={item.title}
                                        className={"Avatar"}
                                        {...item}
                                        onClick={()=>{
                                            if (focusOn){
                                                setFocusOn(null)
                                            }else{
                                                setFocusOn(`${item.serviceId}:${item.titleId}`)
                                            }
                                        }}
                                        onDelete={deleteMode?()=>{
                                            (async()=>{
                                                setIsLoading(true);
                                                await deleteProcess({
                                                    ...item,
                                                    process: `${col}`,
                                                })
                                                await refreshTitles();
                                                setIsLoading(false);
                                            })();
                                        }:null}
                                    />
                                )}
                            </DropBox>
                        )}
                    </div>
                )}
                <div className={"Row"}>
                    <div className={"Header Col"}>
                    </div>
                    
                    {Array(8).fill(0).map((v,i) => i).map(col => 
                        <div key={col} className={"Col Header"}>{processLabel[col]}</div>
                    )}
                </div>
            </div>
            <div className={"List"}>
                {Object.entries(processLabel).map(([key, label]) => <div key={key}>
                    <h2 className={"AreaHeader"}>{label}</h2>
                    <div className={"AreaBody"}>
                        {displayableProcesses
                            .filter(process => process.process === `${key}`
                                            && (process.upside > -3 || isShowingCandidates) )
                            .sort((a,b) => b.upside - a.upside)
                            .map(item =>
                                <DragableIcon
                                    key={`${item.serviceId}:${item.titleId}:${item.process}`}
                                    serviceId={item.serviceId} titleId={item.titleId}
                                    title={item.title}
                                    className={"Avatar"}
                                    {...item}
                                />
                            )}
                    </div>
                </div>)}
            </div>
            <div className={`StanbyZone ${isShowingCandidates?"Show":""}`}>
                {isShowingCandidates?titles.filter(item => !processKeys.has(`${item.serviceId}:${item.titleId}`)).map(item =>
                    <DragableIcon key={`${item.serviceId}:${item.titleId}:stanby`} serviceId={item.serviceId} titleId={item.titleId} title={item.title} className={"Avatar"} {...item} />
                ):[]}
            </div>
            <LoadingCircle show={isLoading}/>
        </div>);
}

export default Process;