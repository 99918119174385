import axios from 'axios';
import ss from './Session';

/**
 * @typedef {{
 *   teamid: string,
 *   userid: string,
 *   authority: 'owner' | 'write' | 'read',
 * }} MembershipType
 */

/**
 * 
 * @returns {Promise<MembershipType[]>}
 */
export async function readMemberships () {
    try{
        let res = await axios.post('https://api.webtoon.today/membership', {}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.data;

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}

/**
 * 
 * @param {string} name
 * @returns {Promise<string?>}
 */
export async function createTeam (name, teamemail) {
    try{
        let res = await axios.put('https://api.webtoon.today/membership', {name, teamemail}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}

/**
 * 
 * @param {MembershipType} membership 
 * @param {string} name 
 * @returns 
 */
export async function renameTeam (membership, name, teamemail) {
    try{
        let res = await axios.patch('https://api.webtoon.today/membership', {teamid: membership.teamid, name, teamemail}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}


/**
 * 
 * @param {MembershipType} membership
 * @returns {Promise<string?>}
 */
export async function createMembership (membership) {
    try{
        let res = await axios.put('https://api.webtoon.today/membership', membership, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}

/**
 * 
 * @param {MembershipType} membership
 * @returns {Promise<string?>}
 */
export async function updateMembership (membership) {
    try{
        let res = await axios.patch('https://api.webtoon.today/membership', membership, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}

/**
* 
* @param {MembershipType} membership
* @returns {Promise<string?>}
*/
export async function deleteMembership (membership) {
    try{
        let res = await axios.delete(`https://api.webtoon.today/membership?teamid=${membership.teamid}&userid=${membership.userid}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}