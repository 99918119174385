import { FormControl, MenuItem, Select } from '@material-ui/core';
import './DropDownSelectBox.scss';
/** 
    @param {{
        selectedValue: string;
        data: string[];
        onChange: (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: unknown;
            }>,
            child: React.ReactNode
        ) => void;
        onOpen?: (event: React.ChangeEvent<{}>) => void;
        onClose?: (event: React.ChangeEvent<{}>) => void;
        placeholder?: string;
        required?: boolean;
        style?: React.CSSProperties;
        className?: string;
        disabled?: boolean;
        dataConverter?: (item:string) => string;
    }} params0
*/
const DropDownSelectBox = ({
    selectedValue,
    data,
    onChange,
    onOpen,
    onClose,
    placeholder = '',
    required = false,
    style = {},
    className = '',
    disabled = false,
    dataConverter = (item)=>item,
}) => {
    return (
        <div className={`DropDownSelectBox ${className}`} style={{ ...style }}>
            <FormControl className={'SelectFormControl'} variant={'filled'} required={required}>
                <Select
                    disabled={disabled}
                    value={selectedValue}
                    onChange={onChange}
                    onOpen={onOpen}
                    onClose={onClose}
                    displayEmpty
                    className={`SelectBox${selectedValue ? '' : ' Placeholder'}`}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                        className: "PopoverDropDownBackground"
                    }}
                    placeholder={placeholder}
                >
                    {placeholder && (
                        <MenuItem value={''} disabled className={'OptionBox PlaceholderBox'}>
                            {placeholder}
                        </MenuItem>
                    )}
                    {data.map((item, index) => (
                        <MenuItem key={item} value={item} className={'OptionBox'} tabIndex={index}>
                            {dataConverter(item)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default DropDownSelectBox