import axios from 'axios';
import ss from '../../../../Data/Session';


/**
 * @typedef {{
 *  serviceId: string,
 *  titleId: string,
 *  email: string,
 *  author: string,
 *  isValid: boolean, // 진행상황에 따라 체크 됐는지
 *  isSubmitted: boolean, // 투고 여부
 *  rejectReason: string, // 거절 이유
 *  validatedAt: number, 
 *  resultConfirmedAt: number,
 *  resultNotifiedAt: number
 * }} marketAnalysis
 * */


/**
 * 
 * @param {{
 *  serviceId: string,
 *  titleId: string,
 * }} indexInfo
 * @returns {Promise<marketAnalysis[]>}
 */
export const listMarketAnalysis = async (indexInfo) => {
    
    let res = await axios.post('https://challenge-api.webtoon.today/process-market-analytics', indexInfo, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });
    
    if (res.data && res.data.code === 200){
        return res.data.data.map(row => ({...row, isValid: !!row.isValid }));
    }else {
        return [];
    }
}

/**
 * 
 * @param {{
 *   deployment: string | null,
 *   drawingStyle: string | null,
 *   fictionalSetting: string | null,
 *   genre: string | null,
 *   historicalSetting: string | null,
 *   perspective: string | null,
 *   serviceId: string,
 *   titleId: string
 * }} marketAnalysis 
 * @returns {Promise<boolean>}
 */
export const updateMarketAnalyticsMeta = async (marketAnalysis) => {

    if (!ss.getCurrentSession().userid || !marketAnalysis.serviceId || !marketAnalysis.titleId){
        return false;
    }
    let res = await axios.put('https://challenge-api.webtoon.today/meta', marketAnalysis, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true,
    });

    if (res.data && res.data.code === 200) {
        return true;
    } else {
        return false;
    }
}




/**
 * 
 * @param { {serviceId: string, titleId: string}[] } keys 
 * @returns {Promise<{serviceId: string, titleId: string, topic: string[]}[]>}
 */
export const readTitleTopic = async (keys) => {
    if (!ss.getCurrentSession().userid){
        return false;
    }
    let res = await axios.post('https://challenge-api.webtoon.today/topic', keys, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true,
    });

    if (res.data && res.data.code === 200) {
        return res.data.data;
    } else {
        return [];
    }
}



/**
 * 
 * @param { {serviceId: string, titleId: string, topic: string[]} } key
 * @returns {Promise<boolean>}
 */
export const updateTitleTopic = async (key) => {
    if (!ss.getCurrentSession().userid){
        return false;
    }
    let res = await axios.put('https://challenge-api.webtoon.today/topic', key, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true,
    });

    if (res.data && res.data.code === 200) {
        return true;
    } else {
        return false;
    }
}

/**
 * 
 * @param {import('../../../../Data/Session').sessionType} session
 * @param {marketAnalysis} marketAnalysis 
 * @returns {Promise<string|false>}
 */
export const updateMarketAnalysis = async (marketAnalysis) => {
    console.log(ss.getCurrentSession().userid, marketAnalysis.serviceId, marketAnalysis.titleId)
    if (!ss.getCurrentSession().userid || !marketAnalysis.serviceId || !marketAnalysis.titleId){
        return false;
    }
    let res = await axios.patch('https://challenge-api.webtoon.today/process-market-analytics', marketAnalysis, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true,
    });

    if (res.data && res.data.code === 200) {
        return true;
    } else {
        return false;
    }
}



/**
 * 
 * @param { {serviceId: string, titleId: string, topic: string[]} } params
 * @returns {Promise<boolean>}
 */
export const deleteTitleTopic = async ({serviceId, titleId, topic}) => {
    if (!ss.getCurrentSession().userid){
        return false;
    }
    let res = await axios.delete(`https://challenge-api.webtoon.today/topic?serviceId=${serviceId}&titleId=${titleId}&topic=${topic}`, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true,
    });

    if (res.data && res.data.code === 200) {
        return true;
    } else {
        return false;
    }
}

