import React from 'react';
import {FormControlLabel, Paper, Radio, RadioGroup, Button, TextField} from '@material-ui/core';

import './Survey.scss';
import LoadingCircle from '../Component/LoadingCircle';
import { sendSurveyResponse } from '../Data/Survey';

const SurveyLogo = 'https://static.webtoon.today/ddah/logo-01.png';
const EventCoffee = 'https://static.webtoon.today/application/EventCoffee.png';
const EventWebtoonToday = 'https://static.webtoon.today/ddah/logo-03.png ';

const survey_options = {

    satisfaction_points: [
        "디렉터의 피드백",
        "성별, 연령 분석",
        "독자 유입률, 연독률 분석",
        "컷별 체류시간, 이탈 그래프"
    ],

    recommend_reasons: [
        "독자들의 객관적인 평가를 받아볼 수 있음",
        "데이터 분석 결과가 명료하고 직관적임",
        "디렉터의 피드백이 작품 개발 방향 설정에 도움이 됨",
        "동기부여가 됨"
    ],

    no_recommend_reasons: [
        "분석이 별로 객관적이지 않음",
        "데이터 분석 결과가 이해하기 어려움",
        "디렉터의 피드백에 동의할 수 없고 도움이 되지 않음",
        "의욕을 잃게 함"
    ],

    approve_reasons: [
        "작품 개발 방향을 설정하기 위해서",
        "독자들의 객관적인 평가를 받고 싶어서",
        "연재 협의에 도움이 될 것 같아서",
        "과정을 진행해준 디렉터가 믿음이 가서",
        "회사가 신뢰가 가서",
        "주변에서 추천해줘서",
    ],

    subject_achives: [
        "작품 개발 방향 설정",
        "독자들의 객관적인 평가",
        "연재 협의에 도움이 됨(또는 될것 같음)",
        "디렉터에게 계속 도움을 받음(또는 받을 것 같음)",
        "회사와 계약을 맺을 의사가 생김",
    ],

}

const Survey = () => {
    
    React.useEffect(()=>{
        document.title = "데이터 분석 평가설문";
    },[])

    const [response, setResponse] = React.useState({
        satisfaction_score: 5,
        satisfaction_points: {},
        improvement_for_satisfaction_points: '',
        recommend_score: 5,
        recommend_reasons: [],
        no_recommend_reasons: [],
        improvement_for_recommend_points: '',
        approve_reasons: '',
        subject_achives: [],

    });
    const [disabled, setDisabled] = React.useState(false);
    const [submmited, setSubmmited] = React.useState(false);

    const [didRecommendElseClicked, setDidRecommendElseClicked] = React.useState(false);
    const [recommendElseReason, setRecommendElseReason] = React.useState([]);
    const [didNoRecommendElseClicked, setDidNoRecommendElseClicked] = React.useState(false);
    const [noRecommendElseReason, setNoRecommendElseReason] = React.useState([]);
    
    const surveySubmit = async () => {
        setDisabled(true)

        if(
            (response.satisfaction_points !== {} && Object.keys(response.satisfaction_points).length === 4)
            && (response.approve_reasons && response.approve_reasons !== '')
        ){

            const result = await sendSurveyResponse({
                surveyId: "analyticsSurvey",
                data:{
                    ...response,
                    recommend_reasons: [...response.recommend_reasons, ...recommendElseReason],
                    no_recommend_reasons: [...response.no_recommend_reasons, ...noRecommendElseReason],
                }
            });
            if (result) {
                setSubmmited(true);
    
            } else{
                setSubmmited(false);
                alert("요청이 실패하였습니다.");
            }
        
        } else {
            setSubmmited(false);
            alert("* 필수 항목을 입력해주세요. *")
        }

        setDisabled(false)
    }
    
    return (
        <>
            <div>
                {!submmited?
                    <div className={"SurveyBody"}>
                        <div className={"SurveyContainer"}>
                            <div elevation={0} className={"Section SurveyLogo"}>
                                <div className={"Inner"}>
                                    <img className={"WebtoonToday"} src={SurveyLogo} alt="logo"/>
                                </div>
                            </div>
                            <Paper elevation={0} className={"Section"}>
                                <div className={"Inner"}>
                                    <div className={"Title"}>{"웹툰 애널리틱스(데이터 분석) 평가 설문"}</div>
                                    <div className={"Subtitle"}>
                                        {"오늘의웹툰 데이터 분석을 이용해주셔서 감사합니다."} <span style={{fontWeight: 'bold'}}>{"약 3분 정도"}</span> {"소요되는 간단한 설문입니다."}<br/>
                                        {" 본 평가의견은 익명으로 관리되며 향후 서비스 개선에 사용됩니다."}
                                    </div>
                                </div>
                            </Paper>
                            <Paper elevation={0} className={"Section"}>
                                <div className={"Inner"}>
                                    <div className={"Title"}>{"1. 오늘의웹툰 데이터 분석은 만족스러우셨나요?"}<span className={"RedAsterisk"}>{" *"}</span></div>
                                    <RadioGroup className={"HorizontalRadioGroup"}
                                        value={response.satisfaction_score}
                                        onChange={(event, value)=>{
                                            setResponse({
                                                ...response,
                                                satisfaction_score: Number(value)
                                            })
                                        }}
                                    >
                                        <div className={"OptionText"}>{"아주 불만족스러움"}</div>
                                        {Array(11).fill(0).map( (v,i)=> 
                                            <FormControlLabel
                                                key={`Radio${i}`}
                                                className={"RadioCell"}
                                                control={<Radio className={"Radio"}/>}
                                                label={`${i}`}
                                                labelPlacement={"top"}
                                                value={i}
                                            />)}
                                        <div className={"OptionText"}>{"아주 만족스러움"}</div>
                                    </RadioGroup>
                                </div>
                            </Paper>
                            <Paper elevation={0} className={"Section"}>
                                <div className={"Inner"}>
                                    <div className={"Title"}>{"1-1. 어떤점이 만족, 또는 불만족스러우셨나요?"}<span className={"RedAsterisk"}>{" *"}</span></div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>
                                                </td>
                                                <td>
                                                    {"만족"}
                                                </td>
                                                <td>
                                                    {"불만족"}
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {survey_options.satisfaction_points.map((reason, index) =>
                                                <tr key={reason}>
                                                    <td className={'OptionText'}>{reason}</td>
                                                    <td>
                                                        <Radio 
                                                            checked={response.satisfaction_points[reason] === true}
                                                            onChange={()=>
                                                                setResponse({
                                                                    ...response, 
                                                                    satisfaction_points: {
                                                                        ...(response.satisfaction_points),
                                                                        [reason]: true
                                                                    },
                                                                })
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <Radio 
                                                            checked={response.satisfaction_points[reason] === false}
                                                            onChange={()=>
                                                                setResponse({
                                                                    ...response, 
                                                                    satisfaction_points: {
                                                                        ...(response.satisfaction_points),
                                                                        [reason]: false
                                                                    },
                                                                })
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Paper>
                            <Paper elevation={0} className={"Section"}>
                                <div className={"Inner"}>
                                    <div className={"Title"}>{"1-2. 만족스럽지 않은 부분이 있었다면, 어떻게 개선하면 좋을까요?"}</div>
                                    <TextField
                                        fullWidth
                                        value={response.improvement_for_satisfaction_points}
                                        onChange={(event) => {
                                            setResponse({
                                                ...response,
                                                improvement_for_satisfaction_points: event.target.value
                                            })
                                        }}
                                    />
                                </div>
                            </Paper>
                            <Paper elevation={0} className={"Section"}>
                                <div className={"Inner"}>
                                    <div className={"Title"}>{"2. 지인에게 오늘의웹툰 데이터 분석에 대해 추천할 의향이 있습니까?"}
                                        <span className={"RedAsterisk"}>{" *"}</span>
                                    </div>
                                    <RadioGroup className={"HorizontalRadioGroup"} 
                                        value={response.recommned_score}
                                        onChange={(event, value)=>{
                                            setResponse({
                                                ...response,
                                                recommend_score: Number(value)
                                            })
                                        }}
                                    >
                                        <div className={"OptionText"}>{"적극 비추천"}</div>
                                        {Array(11).fill(0).map( (v,i)=> 
                                            <FormControlLabel
                                                key={`Radio${i}`}
                                                className={"RadioCell"}
                                                control={<Radio className={"Radio"}/>}
                                                label={`${i}`}
                                                labelPlacement={"top"}
                                                value={`${i}`}
                                            />)}
                                        <div className={"OptionText"}>{"적극 추천"}</div>
                                    </RadioGroup>
                                </div>
                            </Paper>
                            <Paper elevation={0} className={"Section"}>
                                <div className={"Inner"}>
                                    <div className={"Title"}>{"2-1. 추천하신다면 그 이유는 무엇인가요?"}</div>
                                    {survey_options.recommend_reasons.map(reason => 
                                        <Button fullWidth key={reason}
                                            className={"SubjectiveButton"}
                                            variant={response.recommend_reasons.filter(value => value === reason).length>0
                                                ?"contained":"outlined"}
                                            color={"primary"}
                                            onClick={()=>{
                                                if (response.recommend_reasons.filter(value => value === reason).length>0){
                                                    setResponse({
                                                        ...response,
                                                        recommend_reasons: response.recommend_reasons.filter(value => value !== reason).sort()
                                                    })
                                                }else{
                                                    setResponse({
                                                        ...response,
                                                        recommend_reasons: response.recommend_reasons.filter(value => value !== reason).concat([reason]).sort()
                                                    })
                                                }
                                            }}
                                        >
                                            {reason}
                                        </Button>
                                    )}
                                    {didRecommendElseClicked
                                    ?<TextField
                                        fullWidth
                                        value={recommendElseReason[0] || ""}
                                        onChange={(event)=>{
                                            setRecommendElseReason([event.target.value].filter(val => val));
                                        }}
                                    />
                                    :<Button fullWidth
                                        className={"SubjectiveButton"}
                                        variant={"outlined"}
                                        color={"primary"}
                                        onClick={()=>{
                                            setDidRecommendElseClicked(true);
                                        }}
                                    >
                                        {"기타"}
                                    </Button>}
                                </div>
                            </Paper>
                            <Paper elevation={0} className={"Section"}>
                                <div className={"Inner"}>
                                    <div className={"Title"}>{"2-2. 비추천하신다면 그 이유는 무엇인가요?"}</div>
                                    <div>
                                        {survey_options.no_recommend_reasons.map(reason => 
                                            <Button fullWidth key={reason}
                                                className={"SubjectiveButton"}
                                                variant={response.no_recommend_reasons.filter(value => value === reason).length>0
                                                    ?"contained":"outlined"}
                                                color={"primary"}
                                                onClick={()=>{

                                                    if (response.no_recommend_reasons.filter(value => value === reason).length>0){
                                                        setResponse({
                                                            ...response,
                                                            no_recommend_reasons: response.no_recommend_reasons.filter(value => value !== reason).sort()
                                                        })
                                                    }else{
                                                        setResponse({
                                                            ...response,
                                                            no_recommend_reasons: response.no_recommend_reasons.filter(value => value !== reason).concat([reason]).sort()
                                                        })
                                                    }
                                                }}
                                            >
                                                {reason}
                                            </Button>
                                        )}
                                        {didNoRecommendElseClicked
                                        ?<TextField
                                            fullWidth
                                            value={noRecommendElseReason[0] || ""}
                                            onChange={(event)=>{
                                                setNoRecommendElseReason([event.target.value].filter(val => val));
                                            }}
                                        />
                                        :<Button fullWidth
                                            className={"SubjectiveButton"}
                                            variant={"outlined"}
                                            color={"primary"}
                                            onClick={()=>{
                                                setDidNoRecommendElseClicked(true);
                                            }}
                                        >
                                            {"기타"}
                                        </Button>}
                                    </div>
                                </div>
                            </Paper>
                            <Paper elevation={0} className={"Section"}>
                                <div className={"Inner"}>
                                    <div className={"Title"}>{"2-3. 추천할만한 데이터 분석을 제공하기 위해서, 어떤 점을 개선하면 좋을까요?"}</div>
                                    <TextField
                                        fullWidth
                                        value={response.improvement_for_recommend_points}
                                        onChange={(event) => {
                                            setResponse({
                                                ...response,
                                                improvement_for_recommend_points: event.target.value
                                            })
                                        }}
                                    />
                                </div>
                            </Paper>
                            <Paper elevation={0} className={"Section"}>
                                <div className={"Inner"}>
                                    <div className={"Title"}>{"3. 데이터 분석 제공을 수락한 이유는 무엇인가요?"}<span className={"RedAsterisk"}>{" *"}</span></div>
                                    <TextField
                                        fullWidth
                                        value={response.approve_reasons}
                                        onChange={(event) => {
                                            setResponse({
                                                ...response,
                                                approve_reasons: event.target.value
                                            })
                                        }}
                                    />
                                </div>
                            </Paper>
                            <Paper elevation={0} className={"Section"}>
                                <div className={"Inner"}>
                                    <div className={"Title"}>{"3-1. 데이터 분석 이후 목적이 달성되었나요?"}</div>
                                    <div>
                                        {survey_options.subject_achives.map(reason => 
                                            <Button fullWidth key={reason}
                                                className={"SubjectiveButton"}
                                                variant={response.subject_achives.filter(value => value === reason).length>0
                                                    ?"contained":"outlined"}
                                                color={"primary"}
                                                onClick={()=>{

                                                    if (response.subject_achives.filter(value => value === reason).length>0){
                                                        setResponse({
                                                            ...response,
                                                            subject_achives: response.subject_achives.filter(value => value !== reason).sort()
                                                        })
                                                    }else{
                                                        setResponse({
                                                            ...response,
                                                            subject_achives: response.subject_achives.filter(value => value !== reason).concat([reason]).sort()
                                                        })
                                                    }
                                                }}
                                            >
                                                {reason}
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </Paper>
                            <div className={"ButtonBox"}>
                                <Button 
                                    className={"Submit"}
                                    variant={"contained"}
                                    color={"primary"}
                                    onClick={()=>surveySubmit()}
                                >
                                    {" 제출하기 "}
                                </Button>
                            </div>
                        </div>
                    </div>
                :(submmited && (Number(response.recommend_score) > 8)?
                    <>
                        <div className={"SurveyBody"}>
                            <div className={"SurveyContainer"}>
                                <Paper elevation={0} className={"Section SubmittedPaper"}>
                                    <div className={"Inner"}>
                                        <div className={"SubmittedTitle"}>
                                            {"설문에 응해주셔서 감사합니다."}<br/>
                                            {"작가님을 위한"}<span className={"SignatureColor"}>{" 깜짝 추천인 이벤트🎁"}</span>
                                            {"를 준비했어요."}
                                        </div>
                                        <div className={"Image"} >
                                            <img src={EventCoffee} alt={"EventCoffee"}/>
                                        </div>
                                        <div className={"SubmittedContents"}>
                                            {"데이터 분석 신청 시 입력한"}
                                            <span className={"BoldText"}>{" 이메일 아이디를 지인에게"}</span>
                                            {"전달해 주세요."}<br/>
                                            {"추천인과 추천받은 지인 모두에게"}
                                            <span className={"BoldText"}>{" 스타벅스 기프티콘"}</span>
                                            {"을 드립니다."}
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        </div>
                    </>
                    :<>
                        <div className={"SurveyBody"}>
                            <div className={"SurveyContainer"}>
                                <Paper elevation={0} className={"Section SubmittedPaper"}>
                                    <div className={"Inner"}>
                                        <div className={"SubmittedTitle"}>
                                            {"설문에 응해주셔서 감사합니다."}
                                        </div>
                                        <div className={"LogoImage"} >
                                            <div className={"LogoBackground"}>
                                                <img className={"Logo"} src={EventWebtoonToday} alt={"EventCoffee"}/>
                                            </div>
                                        </div>
                                        <div className={"SubmittedContents"}>
                                            {"남겨주신 피드백으로"}<br/>
                                            {"더 좋은 데이터 분석을 제공하겠습니다."}
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        </div>
                    </>)
                }
                <LoadingCircle show={disabled} />
            </div>
        </>
    );
}

export default Survey;