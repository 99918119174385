import { Paper } from '@material-ui/core';
import React from 'react';

import { readSurvey } from '../../Data/Survey';


import './SurveyView.scss';
import LoadingCircle from '../../Component/LoadingCircle';

import EditableTable from '../../Component/Table/EditableTable';
import { getTitles } from '../../Data/Title';

const SurveyView = ( props ) => {
    
    let surveyId = "challengeAgree"
    
    const [disabled, setDisabled] = React.useState(false);
    const [survey, setSurvey] = React.useState([]);

    const [titles, setTitles] = React.useState([]);

    React.useEffect(()=>{

        (async()=>{
            setDisabled(true)

            const res = await readSurvey(surveyId)

            const res2 = res.map(row => {
                const {createdAt} = row;
                const {email, phonenumber} = row.data;
                return (
                    Object.entries(row.data)
                        .filter(([key,value]) => ['email', 'phonenumber'].indexOf(key) < 0 && value > 0)
                        .map(([key,value]) => key.split(":")).map(([serviceId, titleId]) => ({serviceId, titleId, email, phonenumber, createdAt}))
                )
            }).flat()

            setSurvey(res2)

            setDisabled(false)
        })();

        (async()=>{
            let titles = await getTitles([], true, null);
            setTitles(titles);
        })();

    }, [surveyId])

    return(
        <>
            <div className={"SurveyBody"}>
                <div className={"SurveyContainer"}>
                    <Paper className={"SurveyHeader"}>
                        <div className={"HeadText"}>{"작품 이전 동의 목록(중복있음)"}</div>
                        <div className={"SubText"}> {`응답: ${(survey).length}개`}</div>
                    </Paper>

                    <Paper className={"EachBox"}>
                        <EditableTable
                            headerButtonHeaders={[]}
                            columnHeaders={["serviceId", "titleId", "제목", "전화번호", "이메일", "동의일시"]}
                            headerButtonsFunction={(row, index)=>[]}
                            columnsFunction={(row, index)=>[
                                {isEditing: false, type: "default", field: 'serviceId', defaultValue: row.serviceId},
                                {isEditing: false, type: "default", field: 'titleId', defaultValue: row.titleId},
                                {isEditing: false, type: "select", field: 'title', defaultValue: `${row.serviceId}:${row.titleId}`,
                                    options: titles.map(title => ({key: title.title, value: `${title.serviceId}:${title.titleId}`}))},
                                {isEditing: false, type: "default", field: 'phonenumber', defaultValue: ` ${row.phonenumber}`},
                                {isEditing: false, type: "default", field: 'email', defaultValue: row.email},
                                {isEditing: false, type: "date", fullDate: true, field: 'date', defaultValue: row.createdAt},
                            ]}
                            updateGenerator={()=>()=>{}}    
                            data={survey}
                            defaultSortCol={[0,"asc"]}
                            isDownloadable={true}
                            name={"challenge upload agrees"}
                        />
                    </Paper>
                </div>
                <LoadingCircle show={disabled} />
            </div>

        </>
    )
}

export default SurveyView;