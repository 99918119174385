import { Button, FormControlLabel, Radio, RadioGroup, TextField, Checkbox, Box, Typography, Container, } from '@material-ui/core';
import React from 'react';
import {logBehavior} from '../../Data/Behavior';
import { fn, validateEmailForm } from '../../Functions';
import './PreLaunchPage.scss';

const concept_title = {
    cursed_boardgame: '저주받은 보드게임',
    roll_the_spirit_dice: '영혼의 주사위를 굴려라',
    horror_tales_of_school: '우리 학교 괴담',
    a_game_with_ghost: '귀신과 게임 한판',
    penalty: '벌칙',
    deathgame_of_blood: '피의 데스게임',
    hide_and_seek_with_insane_murderer: '미친 살인마와 술래잡기',
    game_console: '게임기',
}

const PreLaunchPage = ({concept}) => {

    const [ emailAddress, setEmailAddress ] = React.useState('')
    
    const [ gender, setGender ] = React.useState(null)

    const webtoonFlatFormArray = [
        "네이버웹툰", 
        "카카오페이지",
        "카카오웹툰",
        "네이버시리즈",
        "레진코믹스",
        "기타",
    ]

    const ageArray = [
        '19세 이하',
        '20-24',
        '25-29',
        '30-34',
        '35-39',
        '40세 이상'
    ]

    const webtoonFrequencyArray = [
        ['almost', '거의 매일'],
        ['often', '자주'],
        ['sometimes', '가끔'],
        ['rarely', '아주 가끔']
    ]

    const getFrequencyIndex = (text) => {
        const frequencyTextArray = [
            'almost', 'often', 'sometimes', 'rarely'
        ]
        
        const Index = frequencyTextArray.indexOf(text)
        return Index;
    }

    const [ emailSubmitted, setEmailSubmitted ] = React.useState(false)


    const [ oftenUsedSites , setOftenUsedSites ] = React.useState([]);

    const [ focusedValue, setFocusedValue ] = React.useState(null);

    const [ isEmailInputFocused, setIsEmailInputFocused ] = React.useState(false);

    const [ age, setAge ] = React.useState(null)
    
    return(
        <div className={'PrelaunchBG'}>
            <div className={'PrelaunchPage'}>
                <div className={'PrelaunchArea'}>
                    <img src={`https://static.webtoon.today/ddah/prelaunch/${concept}.png`} alt={'webtoonImage'} style={{width: '100%', objectFit: 'contain'}} />
                    <div className={'BodyContainer'}>
                        <div className={'Section'}>
                            <div className={'Line'} >
                                <div className={'LineText'} >{'알림 신청하고 먼저 감상하세요!'}</div>
                                <div style={{height: '0px', borderTop: '1px solid white'}}></div>
                            </div>
                            <div className={'MainText'} >
                                {`현실에서 펼쳐지는 참혹한 보드게임
                                <${concept_title[concept]}> 제작 준비 중!
                                (올해 12월 목표)

                                이메일 주소를 남기시면 
                                웹툰이 나올 때 알려드려요.`}
                            </div>
                            <div className={'ButtonBox'}>
                                <Button variant={'contained'} fullWidth onClick={()=>{
                                    window.location.hash = '#Survey';
                                }}>{'5초만에 설문하고 알림 신청하기'}</Button>
                                <div style={{color: 'rgba(255,255,255,0.64)', fontSize: '0.8rem', lineHeight: '24px', wordBreak: 'keep-all'}}>
                                    {`※독자님의 소중한 정보는 해당 작품을 만드는 과정에서만 활용됩니다.`}
                                </div>
                            </div>
                        </div>
                        <div className={'Section'} id={'Survey'} >
                            <div className={'Line'}>
                                <div className={'LineText'} >{`이 웹툰에 관심있는 당신은 어떤 사람인가요?`}</div>
                                <div style={{height: '0px', borderTop: '1px solid white'}}></div>
                            </div>
                            <div className={'GenderBox'}>
                                <div className={'BoxLabel'}>
                                    {'성별'}
                                </div>
                                <div className={'BoxInner'} >
                                    <div className={'ToggleBackground'} style={{width: '150px', height: '35px', border: '2px solid white', borderRadius: '13px'}} >
                                        <Button className={`ToggleButton ${gender === 'male'?'Focused':''}`} onClick={()=>setGender('male')} disabled={emailSubmitted} >
                                            {'남성'}
                                        </Button>
                                        <Button className={`ToggleButton ${gender === 'female'?'Focused':''}`} onClick={()=>setGender('female')} disabled={emailSubmitted} >
                                            {'여성'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className={'AgeBox'}>
                                <div className={'BoxLabel'}>
                                    {'나이'}
                                </div>
                                <div className={'AgeButtonContainer'} style={{display: 'flex', flexDirection: 'row'}}>
                                    {ageArray.map(ageText => (
                                        <div className={`ButtonWrapper`} key={ageText}>
                                            <Button className={`AgeButton ${ age === ageText?'Focused':''}`} onClick={()=>setAge(ageText)} disabled={emailSubmitted} >
                                                {ageText}
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={'WebtoonFrequencyBox'}>
                                <div className={'BoxLabel'}>
                                    {'웹툰 이용 빈도'}
                                </div>
                                <div className={'FrequencyInner'} style={{position: 'relative'}}>
                                    <RadioGroup className={'RadioGroup'} style={{flexDirection: 'row', width: '100%'}} >
                                        {webtoonFrequencyArray.map(([value, text]) => (
                                        <FormControlLabel className={`ButtonWrapper ${value===focusedValue?'FocusColor':'unFocusColor'}`} value={value} key={value}
                                            control={<Radio disabled={emailSubmitted} className={'FrequencyButton'} />} label={text} labelPlacement={'bottom'} onFocus={()=>setFocusedValue(value)} 
                                        />
                                        ))}
                                    </RadioGroup>
                                    <div style={{borderTop: '2px solid white', height: 0, width: '100%', position: 'absolute', top: '11px'}}></div>
                                </div>
                            </div>
                            <div className={'FlatformBox'}>
                                <div className={'BoxLabel'}>
                                    {'자주 사용하는 웹툰 플랫폼'}
                                </div>
                                <div className={'GridTemplate'} >
                                    {webtoonFlatFormArray.map(site => (
                                    <div key={site} style={{paddingBottom: '10px'}}>
                                        <FormControlLabel control={
                                            <Checkbox size={'small'} style={{padding: 0, color: 'white'}} disabled={emailSubmitted}
                                                checked={oftenUsedSites.includes((site))} 
                                                onChange={ (e) => {
                                                    if (e.target.checked){
                                                        setOftenUsedSites(oftenUsedSites.concat(site)) 
                                                    } else {
                                                        setOftenUsedSites(oftenUsedSites.filter(element => element !== site))
                                                    }
                                                }} />
                                        } label={site} />
                                    </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={'EmailSection'}>
                            <div className={'Title'} >
                                {'이메일 주소'}
                            </div>
                            <div className={'InputField'}>
                                <label className={`EmailFieldPlaceHolder ${isEmailInputFocused?'Focused':''}`} style={{}}>{"웹툰이 나올 때 이 주소로 알려드릴게요."}</label>
                                <TextField className={'EmailField'} value={emailAddress} onChange={(e)=>setEmailAddress(e.target.value)}
                                    inputProps={{style:{padding: '7.5px 3px 8px'}}} InputProps={{style:{color:'rgba(255,255,255,0.64)'}}}
                                    onBlur={()=>{ setIsEmailInputFocused(!!emailAddress) }}
                                    onFocus={()=>{ setIsEmailInputFocused(true) }}
                                    disabled={emailSubmitted}
                                />
                                <Button className={'RegistButton'} disabled={emailSubmitted} onClick={()=>{
                                    if (!validateEmailForm(emailAddress)){
                                        alert("이메일이 올바르지 않습니다.")
                                        return;
                                    }

                                    if (!concept) {
                                        alert("콘셉트가 입력되지 않습니다.")
                                        return;
                                    }
                                    if (!gender) {
                                        alert("성별이 선택되지 않습니다.")
                                        return;
                                    }
                                    if (!age) {
                                        alert("나이가 선택되지 않습니다.")
                                        return;
                                    }
                                    if (!focusedValue) {
                                        alert("웹툰 이용 빈도가 선택되지 않았습니다.")
                                        return;
                                    }
                                    if ((oftenUsedSites || []).length === 0 || !oftenUsedSites) {
                                        alert("자주 사용하는 웹툰 플랫폼이 선택되지 않았습니다.")
                                        return;
                                    }

                                    (async()=>{
                                        const frequency = getFrequencyIndex(focusedValue)
                                        await logBehavior('concept-subscribe',{
                                            concept, email: emailAddress, gender, age, frequency, oftenUsedSites
                                        })
                                        alert("등록되었습니다!")
                                        setEmailSubmitted(true)
                                    })();
                                }} >
                                    {'등록'}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={"CopyrightSubRoot"}>
                        <Container maxWidth="lg" className={"Copyrights"}>
                            <div>
                                <Box
                                    component="img"
                                    alt="logo"
                                    src="https://static.webtoon.today/ddah/logo-01.png"
                                    height={55}
                                    className={"Img"}
                                    sx={{ mb: 1, mx: "auto" }}
                                />
                            </div>
                            <Typography variant="caption" className={"Address"}>
                                {"주식회사 오늘의웹툰"}
                                <br/>
                                {"765-81-02007 | 대표자 진수글 | "}<a href={"/privacypolicy/kr"} onClick={fn.gotoByAnchor}>{"개인정보처리방침"}</a>
                                <br/>
                                {"서울시 강남구 강남대로 78길 33-10, 3층"}
                            </Typography>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreLaunchPage;
