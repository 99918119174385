import React, { useEffect } from 'react';
import Intro from './Section/Intro';
import Notice from './Section/Notice';
import Service from './Section/Service';
import Works from './Section/Works';
import { fn } from '../../Functions';
import './Introduction.scss';
import Footer from './Section/Footer';

const HomeHeader = () => {
    return (
        <header className={'HomeHeader'}>
            <div className={'HeaderContentContainer'}>
                <a className={'WebtoonTodayLogo'} href={'/'} onClick={fn.gotoByAnchor} >
                    <img src={'https://static.webtoon.today/ddah/logo-03.png'} alt={'logo'} />
                </a>
            </div>
        </header>
    );
};


const Introduction = () => {
    useEffect(()=>{
        document.title = "데이터 + 웹툰 = ❤️";
    },[]);

    return (
        <div className={'IntroductionPage'}>
            <HomeHeader />
            <Intro />
            <Works />
            <Service />
            <Notice />
            <Footer />
        </div>
    );
}

export default Introduction;