import { Avatar, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, Input, MenuItem, Paper, Radio, RadioGroup, Select, TextField } from '@material-ui/core';
import React from 'react';
import EditableTable from '../../../Component/Table/EditableTable';
import EditableFileField from '../../../Component/Table/EditableFileField';

import {readEpisodes, createEpisode, updateEpisode, deleteEpisode} from './Data/Episode';
import {fn} from '../../../Functions';

import './EpisodeList.scss';
import { deleteTitle, getTitle, updateTitle } from './Data/Title';
import {readOwnerships, createOwnership, deleteOwnership, listMyOwnTitles} from './Data/Ownership';
import {readProfile} from '../../../Data/Profile';
import {listAds} from './Data/Ad';
import ss from '../../../Data/Session';

import DragAndDrop from '../../Landing/Component/DragAndDrop';

import LoadingCircle from '../../../Component/LoadingCircle';
import { Add, Close, Create, Help, Visibility, VpnKey } from '@material-ui/icons';

import { useDispatch } from "react-redux";
import { updateTitleTopic, deleteTitleTopic, listMarketAnalysis, readTitleTopic, updateMarketAnalyticsMeta } from '../Market/Data/MarketAnalyticsProcess';
import { AnalyticsSubmit } from './Data/AnalyticsSubmit';
import { readContiAnalyticsProcess } from './Data/ContiAnalyticsProcess';

const topicListByConsonants = {
    'ㄱ': ['가족', '게임', '고전', '기억상실'],
    'ㄴ': ['나르시시즘', '나비', '나쁜남자'],
    'ㄷ': ['다정남주', '달달함'],
    'ㄹ': ['로맨스판타지'],
    'ㅁ': ['마법', '마술'],
    'ㅂ': ['복수', '배신', '바람'],
    'ㅅ': [],
    'ㅇ': [],
    'ㅈ': [],
    'ㅊ': [],
    'ㅋ': [],
    'ㅌ': [],
    'ㅍ': [],
    'ㅎ': [],
}

const metaTagLabels = {
    historicalSetting: '시대 배경',
    fictionalSetting: '현실성',
    genre: '장르',
    perspective: '주 독자층',
    deployment: '주인공 구성',
    drawingStyle: '그림체',
}

const metaTagList = {
    historicalSetting: {
        middleAgeEast: '중세 동양',
        modernEast: '근대 동양',
        middleAgeWest: '중세 서양',
        modernWest: '근대 서양',
        contemporary: '현대',
        future: '미래',
    },
    fictionalSetting: {
        fantasy: '판타지',
        scifi: '공상과학',
        nonfiction: '현실적',
        nearnonfiction: '대체로 현실적',
    },
    genre: {
        romance: '로맨스',
        growingup: '성장',
        thriller: '스릴러',
        action: '액션',
        comedy: '코미디',
        healing: '힐링'
    },
    perspective: {
        boy: '남자',
        girl: '여자',
    },
    deployment: {
        ensenble: '다수',
        mono: '단일',
    },
    drawingStyle: {
        hyperreal: '극실사체',
        real: '실사체',
        semimanga: '가벼운 일본 만화체',
        deformermanga: '과장된 일본 만화체',
        semicartoon: '가벼운 서양 만화체',
        deformercartoon: '과장된 서양 만화체',
        deformerelse: '특이한 그림체',
        semielse: '과장된 특이한 그림체',
    },
}

const consentList = [
    "독자 반응 수집을 위한 SNS 퍼포먼스 마케팅(광고 게재)에 동의합니다.",
    "접수된 작품의 저작권이 본인의 소유임을 확인합니다.",
    "데이터 분석 결과 및 피드백의 저작권은 오늘의웹툰에 있음을 확인합니다.",
    "접수 시 오늘의웹툰 자유연재플랫폼에 작품이 게재됨을 확인합니다.",
];
const currentTermsofuseVersion = "/termsofuse/termsofuse/analytics/20220202/kr";

/**
 * 
 * @param {{
 *   serviceId: string,
 *   titleId: string,
 *   readyCallback: () => void,
 *   hash: string,
 * }} props
 * @returns 
 */
const EpisodeList = ({serviceId, titleId, readyCallback, hash}) => {
    
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);

    const [givenTitle, setGivenTitle] = React.useState({});
    const [title, setTitle] = React.useState({});

    const [askApplyChange, setAskApplyChange] = React.useState(false);
    const [confirmChange, setConfirmChange] = React.useState(false);

    const [ads, setAds] = React.useState([]);
    const [episodes, setEpisodes] = React.useState([]);

    const [givenMetaTags, setGivenMetaTags] = React.useState({});
    const [metaTags, setMetaTags] = React.useState({});
    React.useEffect(()=>{
        (async()=>{
            setIsLoading(true);
            let newTitle = await getTitle(serviceId, titleId, dispatch) || [{}];
            setGivenTitle(newTitle[0] || {});
            
            document.title = `작품정보 수정 - ${(newTitle[0] || {}).title || ""}`;
            setIsLoading(false);
        })();
        (async()=>{
            let newEpisodes = await readEpisodes(serviceId, titleId);
            setEpisodes(newEpisodes);
        })();
        (async()=>{
            let newAds = await listAds({serviceId, titleId});
            setAds(newAds);
        })();
        (async()=>{
            let titleMetaTags = await listMarketAnalysis({serviceId, titleId});
            
            setMetaTags(titleMetaTags[0]); 
            setGivenMetaTags(titleMetaTags[0]);
        })();
        (async () => {
            if (hash !== 'new') return;

            let titleTopics = await readTitleTopic([{serviceId, titleId}]);

            if (titleTopics.length > 0) {
                setTopics(titleTopics[0].topic);
            } else {
                setTopics([]);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serviceId, titleId]);

    React.useEffect(()=>{
        if (Object.keys(title).length === 0){
            setTitle(givenTitle);
        } else if (JSON.stringify(title) !== JSON.stringify(givenTitle)){
            setAskApplyChange(true);
        } else {
            setTitle(givenTitle);
        }
    },[title, givenTitle])

    React.useEffect(()=>{
        if (askApplyChange && confirmChange){
            setAskApplyChange(false);
            setConfirmChange(false);

            setTitle(givenTitle);
        }
    },[askApplyChange, confirmChange, givenTitle])

    const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
    const [, setInnerWidth] = React.useState(window.innerWidth);
    React.useEffect(()=>{
        const resizeListener = () => {
            setInnerHeight(window.innerHeight);
            setInnerWidth(window.innerWidth);
        }
        window.addEventListener('resize', resizeListener);

        return ()=>{
            window.removeEventListener('resize', resizeListener);
        }
    },[])

    const [itemAuthority, setItemAuthority] = React.useState('none');
    React.useEffect(()=>{
        (async()=>{
            let ownTitles = await listMyOwnTitles();

            let matched = ownTitles.filter(row => row.serviceId === serviceId && row.titleId === titleId)

            if (matched.length > 0){
                setItemAuthority(matched[0].authority);
            }
        })();
    },[serviceId, titleId])

    const [ownershipList, setOwnershipList] = React.useState([]);
    const [ownerProfiles , setOwnerProfiles] = React.useState({});
    React.useEffect(()=>{
        (async ()=>{
            let newOwnershipList = await readOwnerships(serviceId, titleId);

            let newProfiles = await readProfile(newOwnershipList.map(row => row.userid).join(','))

            setOwnerProfiles(
                ((newProfiles instanceof Array)?newProfiles:[newProfiles]).map(row => {
                    let ret = {};
                    ret[row.userid] = row;
                    return ret;
                }).reduce((a,b) => Object.assign(a,b),{})
            )
    
            setOwnershipList(newOwnershipList);
        })();
    },[serviceId, titleId]);
    
    const [newEpisodeId, setNewEpisodeId] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [askNewEpisodeId, setAskNewEpisodeId] = React.useState(false);
    const [askDeleteTitle, setAskDeleteTitle] = React.useState(false);
    const [askAddOwnership, setAskAddOwnership] = React.useState(false);
    const [askRemoveOwnership, setAskRemoveOwnership] = React.useState(false);
    const [pendingDeleteOwnership, setPendingDelteOwnership] = React.useState({});
    const [pendingAddOwnershipEmail, setPendingAddOwnershipEmail] = React.useState(null);
    const [pendingAddOwnershipAuthority, setPendingAddOwnershipAuthority] = React.useState('read');

    const [ topics, setTopics ] = React.useState([]);
    const [ onTopicAdds, setOnTopicAdds ] = React.useState(false);
    
    const [ isSurveyRequired, setIsSurveyRequired ] = React.useState('');
    const [ informSubmitResult, setInformSubmitResult ] = React.useState('');
    
    return (<>
        <div className={"TitleEdit"}>
            <div className={"HeaderBar"}>
                <h2 style={{paddingLeft: '0.5rem'}}>{"작품 상세"}</h2>
                <div className={"Btns"}>
                    <Button className={"Btn"} color={"primary"} variant={"contained"} disabled={
                        (JSON.stringify(title) === JSON.stringify(givenTitle)
                        || Object.keys(title) === 0
                        || ['owner', 'write'].indexOf(itemAuthority) < 0)
                        
                        && (JSON.stringify(metaTags) === JSON.stringify(givenMetaTags))
                    } onClick={async ()=>{
                        setIsLoading(true);
                        if (JSON.stringify(metaTags) !== JSON.stringify(givenMetaTags)) {
                            const { historicalSetting, fictionalSetting, genre, perspective, deployment, drawingStyle } = metaTags
                            const isUpdated = await updateMarketAnalyticsMeta({serviceId, titleId, historicalSetting, fictionalSetting, genre, perspective, deployment, drawingStyle });
                            
                            if (isUpdated) {
                                const newGivenMetaTags = await listMarketAnalysis({serviceId, titleId});
                                setGivenMetaTags(newGivenMetaTags[0]);
                            } 
                        };

                        if (JSON.stringify(title) !== JSON.stringify(givenTitle)
                            && Object.keys(title) !== 0
                            && ['owner', 'write'].indexOf(itemAuthority) >= 0) {

                            let resultKey = await updateTitle(title, dispatch);
                            let newGivenTitle = await getTitle(resultKey.split(':')[0], resultKey.split(':')[1], dispatch);
                            setGivenTitle(newGivenTitle);
                        }

                        setIsLoading(false);
                    }}>{"저장"}</Button>
                    <Button className={"Btn"} color={"default"} variant={"contained"} disabled={
                        JSON.stringify(title) === JSON.stringify(givenTitle)
                        || Object.keys(title) === 0
                        || ['owner', 'write'].indexOf(itemAuthority) < 0
                    } onClick={()=>setTitle(givenTitle)}>{"초기화"}</Button>
                    <Button className={"Btn"} color={"secondary"} variant={"contained"} disabled={itemAuthority !== 'owner'} onClick={()=>{
                        setAskDeleteTitle(true);
                    }}>{"삭제"}</Button>
                </div>
            </div>
            <div className={"TitleEditContainer"}>
                <div className={"Col WithThumb"}>
                    <h3 style={{paddingLeft: '0.5rem' }}>{"대표 이미지"}</h3>
                    <Paper variant={"outlined"} className={"MainThumbContainer"} style={{backgroundImage: `url('${title.thumbnail}')`}}>
                        <div className={"MainThumbBackdrop"}>
                            <EditableFileField
                                className={"MainThumb"}
                                defaultValue={title.thumbnail}
                                update={(newValue)=>{
                                    setTitle({...title, thumbnail: newValue.thumbnail})
                                }}
                                onLoad={()=>{}}
                                field={"thumbnail"}
                                isEditing={true}
                            />
                        </div>
                    </Paper>
                    <h3 style={{paddingLeft: '0.5rem' }}>{"노출 설정"}</h3>
                    <Paper variant={"outlined"} className={"VisibilityInfo"}>
                        <Select fullWidth value={title.exposure || ''} onChange={(event)=>{
                                    setTitle({...title, exposure: event.target.value})
                        }}>
                            {[["나만 보기","private"], ["목록 제외","unlist"], ["공개","public"]].map(([label, value])=> 
                                <MenuItem key={value} className={"MenuItem"} value={value}>{label}</MenuItem>
                            )}
                        </Select>
                    </Paper>
                    <h3 style={{paddingLeft: '0.5rem' }}>{"댓글 설정"}</h3>
                    <Paper variant={"outlined"} className={"VisibilityInfo"}>
                        <Select fullWidth value={title.reviewPolicy || ''} onChange={(event)=>{
                                    setTitle({...title, reviewPolicy: event.target.value})
                        }}>
                            {[["허용 안함","notAllowed"], ["승인 후 게재","stopAndGo"], ["자동 승인","approveAll"]].map(([label, value])=> 
                                <MenuItem key={value} className={"MenuItem"} value={value}>{label}</MenuItem>
                            )}
                        </Select>
                    </Paper>
                    <h3 style={{paddingLeft: '0.5rem' }}>{"보고서 바로가기"}</h3>
                    <Paper variant={"outlined"} className={"VisibilityInfo"}>
                        <Select fullWidth value={title.reviewPolicy || ''} onChange={(event)=>{
                                    let [serviceId, titleId, episodeId, snapshotId] = (event.target.value || "").split(":")
                                    if (snapshotId){
                                        fn.goto(`/challenge/report/${serviceId}/${titleId}/${episodeId}/${snapshotId}`);
                                    }
                        }}>
                            <MenuItem key={""} className={"MenuItem"} value={""}>{"　"}</MenuItem>
                            {ads.map(ad => 
                                <MenuItem key={ad.adId} className={"MenuItem"} value={`${ad.serviceId}:${ad.titleId}:${ad.episodeId}:${ad.snapshotId}`}>{`${ad.episodeId}화 (${ad.snapshotId}호)`}</MenuItem>
                            )}
                        </Select>
                    </Paper>
                    {ss.getCurrentSession?<>
                        <h3 style={{paddingLeft: '0.5rem' }}>{"콘티 분석"}</h3>
                        <Select fullWidth value={isSurveyRequired || ''} onChange={(event)=>{
                            setIsSurveyRequired(event.target.value);
                            (async () => {
                                setIsLoading(true);

                                const { author, serviceId, titleId, description, slides } = title
                                
                                const readContiAnalytics = await readContiAnalyticsProcess({serviceId, titleId});

                                if ( (readContiAnalytics || []).length > 0 ) {
                                    setInformSubmitResult('이미 콘티 분석이 신청된 작품입니다.');
                                    setIsLoading(false);
                                    return;
                                }
                                
                                const episodeRange = [
                                    episodes[0].episodeId,
                                    episodes.length > 1 ? episodes[episodes.length - 1].episodeId : episodes[0].episodeId
                                ]

                                const res = await AnalyticsSubmit({
                                    name: author, episodeRange,
                                    title: title.title, serviceId, titleId, introduction: description,
                                    advertisementCuts: slides, service: "contiAnalytics", consentList,
                                    requestCreatives: false, currentTermsofuseVersion, isSurveyRequired: event.target.value
                                });
                                
                                if ( res ) {
                                    setInformSubmitResult('콘티 분석 신청이 완료되었습니다.');
                                } else {
                                    setInformSubmitResult('콘티 분석 신청이 실패했습니다.');
                                }
                                
                                setIsLoading(false);
                            })()
                        }}>
                            {[["콘티 분석 + 설문조사",true], ["콘티 분석",false]].map(([label, value])=> 
                                <MenuItem key={value} className={"MenuItem"} value={value}>{label}</MenuItem>
                            )}
                        </Select>
                    </>:<></>}
                </div>
                <div className={"Col"}>
                    <h3 style={{paddingLeft: '0.5rem' }}>{"제목, 소개글"}</h3>
                    <TextField
                        className={"TextField Title"} fullWidth variant={"outlined"}
                        label={"작품 제목"}
                        value={title.title || ''}
                        onChange={(event)=>{
                            setTitle({...title, title: event.target.value})
                        }}
                    />
                    <TextField
                        className={"TextField Description"} fullWidth variant={"outlined"}
                        label={"작품 설명"}
                        multiline minRows={4}
                        value={title.description || ''}
                        onChange={(event)=>{
                            setTitle({...title, description: event.target.value})
                        }}
                    />
                    <div style={{display:"flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                        <TextField
                            className={"TextField Title"} variant={"outlined"}
                            label={"작가명"}
                            value={title.author || ''}
                            onChange={(event)=>{
                                setTitle({...title, author: event.target.value})
                            }}
                        />
                        <TextField
                            className={"TextField Title"} variant={"outlined"}
                            label={"이메일"}
                            value={title.email || ''}
                            onChange={(event)=>{
                                setTitle({...title, email: event.target.value})
                            }}
                        />
                    </div>
                    <TextField
                        className={"TextField Title"} fullWidth variant={"outlined"}
                        label={"태그"}  
                        value={title.tags || ''}
                        onChange={(event)=>{
                            setTitle({...title, tags: event.target.value.replace(/ /g,'')})
                        }}
                    />
                    <h3 style={{paddingLeft: '0.5rem', marginBlockEnd: '0.5rem',}}>{"홍보용 컷(600 x 600, 8컷 이내)"}</h3>
                    <Paper variant={"outlined"} className={"Slides"}>
                        <DragAndDrop
                            maximum={10}
                            isEditable={true}
                            file={title.slides || []}
                            setFile={newData => {
                                setTitle({...title, slides: newData});
                                console.log(newData)
                            }}
                            setIsImagesLoading={()=>{}}
                        />
                    </Paper>
                    <h3 style={{paddingLeft: '0.5rem', marginBlockEnd: '0.5rem',}}>{"작성 권한 관리"}</h3>
                    <Paper variant={"outlined"} className={"Ownerships"}>
                        {ownershipList.map(ownership => <Chip key={ownership.userid} style={{marginRight: 5}}
                            avatar={
                                <Avatar>
                                    {ownership.authority==='owner'?<VpnKey className={"Avatar"}/>:
                                    ownership.authority==='write'?<Create className={"Avatar"}/>:
                                    ownership.authority==='read'?<Visibility className={"Avatar"}/>:<Help className={"Avatar"}/>
                                }
                                </Avatar>
                            } label={
                                <div className={"Label"}>
                                    <Avatar className={"UserAvatar"} src={(ownerProfiles[ownership.userid] || {}).image}/>
                                    <div className={"Text"}>{(ownerProfiles[ownership.userid] || {}).name || ownership.userid}</div>
                                </div>
                            } color={
                                ownership.authority==='owner'?'primary':
                                    ownership.authority==='write'?'secondary':
                                    ownership.authority==='read'?'default':'default'
                            } onDelete={()=>{
                                setAskRemoveOwnership(ownership)
                                setPendingDelteOwnership(ownership)
                            }}
                            />
                        )}
                        <IconButton
                            disabled={['owner'].indexOf(itemAuthority) < 0}
                            onClick={()=>{setAskAddOwnership(true)}}
                        ><Add style={{border:'1px lightgray solid', borderRadius: '100%'}}/></IconButton>
                    </Paper>
                </div>
            </div>
            {hash === 'meta'?<div className={"TitleDetailContainer"}>
                <div className={'EditingDetails'} style={{position: 'relative'}}>
                    <h3 style={{paddingLeft: '0.5rem', marginBlockEnd: '0.5rem',}}>{'작품 세부 정보'}</h3>
                    <div className={'DetailBox'} >
                        <div className={'Label'} >
                            {'토픽'}
                        </div>
                        <div style={{display: 'flex', whiteSpace: 'nowrap', flex: 1}}>
                            {topics.map((item, index) => (
                                <div className={'TopicItem'} key={`topicBox:[${index}]`} >
                                    <div className={'Text'}>
                                        {item}
                                    </div>
                                    <Close className={'DeleteTagButton'} onClick={ async () => {
                                        
                                        const res = await deleteTitleTopic({serviceId, titleId, topic: [item]});

                                        if (res) {
                                            setTopics(topics.filter(topic => topic !== item))
                                        }
                                    }}  />
                                </div>
                            ))}
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Button onClick={ () => setOnTopicAdds(!onTopicAdds) } >
                                {'추가하기'}
                            </Button>
                        </div>
                    </div>
                    {onTopicAdds
                        ?<div className={'TopicList'} >
                            <Paper className={'ListBackground'} >
                                {['ㄱ','ㄴ','ㄷ','ㄹ','ㅁ','ㅂ','ㅅ','ㅇ','ㅈ','ㅊ','ㅋ','ㅌ','ㅍ','ㅎ'].map( consonant => (
                                    <div key={consonant}>
                                        <div className={'ConsonantTitle'} >
                                            {consonant}
                                        </div>
                                        <div className={'TopicsOfConsonant'}>
                                            {topicListByConsonants[consonant].map( topic => (
                                                <div 
                                                    className={`TopicItem ${topics.indexOf(topic) >= 0?'Disabled':''}`} key={`${consonant}:${topic}`}
                                                    onClick={ async (e) => {
                                                        const res = await updateTitleTopic({serviceId, titleId, topic: topics.concat(topic)})

                                                        if ( res && !topics.includes(topic) ) {
                                                            setTopics( topics.concat(topic) )
                                                        };

                                                        setOnTopicAdds(false);
                                                    }}
                                                >
                                                    {topic}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </Paper>
                        </div>:<></>}
                </div>
                <div className={'EditingDetails'} >
                    <div className={'DetailBox'} >
                        <div className={'Label'} >
                            {'메타 태그'}
                        </div>
                        <div className={'MetaTagTable'} >
                            {Object.entries(metaTagLabels).map( ([key, label]) => (
                                <div className={'MetaBox'} key={`${key}:${label}`} >
                                    <div className={'MetaLabel'} >
                                        {label}
                                    </div>
                                    <FormControl style={{display: 'flex', flexDirection:'column'}} >
                                        <RadioGroup onChange={(e) => {
                                            setMetaTags({...metaTags, [key]: e.target.value})
                                        }} >
                                        {Object.entries(metaTagList[key]).map( ([metaKey, metaLabel]) => (
                                            <FormControlLabel 
                                                key={`${key}:${metaLabel}`} value={metaKey}
                                                checked={metaTags[key] === metaKey}
                                                control={<Radio size={'small'} />} label={metaLabel} 
                                            />
                                        ))}
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            )) }
                        </div>
                    </div>
                </div>
            </div>:<></>}
            <h2 style={{paddingLeft: '0.5rem', marginBlockEnd: '0.5rem'}}>{"에피소드"}</h2>
            <div style={{maxWidth: 1500, display: 'flex', justifyContent:'center', alignItems:'center'}}>
                <EditableTable
                    style={{maxHeight: innerHeight-120, overflowY: 'auto' }}
                    headerButtonHeaders={[
                        <Button
                            color={"primary"} variant={"contained"}
                            disabled={['owner', 'write'].indexOf(itemAuthority) < 0}
                            onClick={()=>setAskNewEpisodeId(true)} style={{padding:0}}>
                            {"추가"}
                        </Button>
                    ]}
                    columnHeaders={[ '썸네일', 'episodeId', '제목', '생성 일자', '본문편집']}
                    defaultSortCol={[1, 'asc']}
                    headerButtonsFunction={(row, index)=>[
                        {name: '삭제', color: 'secondary', props:{ style: {paddingTop:0, paddingBottom:0, marginLeft: 5}, fullWidth: true, disabled: ['owner', 'write'].indexOf(itemAuthority) < 0 }, onClick:async ()=>{
                            await deleteEpisode({
                                serviceId, titleId, ...row
                            })
                            let newEpisodes = await readEpisodes(serviceId, titleId);
                            setEpisodes(newEpisodes);
                            
                        }}
                    ]}
                    columnsFunction={(row, index)=>[
                        {isEditing: true && ['owner', 'write'].indexOf(itemAuthority) >= 0, type: 'file', field: 'thumbnail', style: {maxWidth:200, maxHeight:150}, defaultValue: row.thumbnail || undefined},
                        {isEditing: false, type: 'string', field: 'episodeId', options:[], defaultValue: row.episodeId},
                        {isEditing: true && ['owner', 'write'].indexOf(itemAuthority) >= 0, type: 'string', field: 'title', options:[], defaultValue: row.title || undefined},
                        {isEditing: false, type: 'date', field: 'createdAt', options:[], defaultValue: row.createdAt},
                        {isEditing: false, type: 'dummy', field: 'gotoEpisodes', defaultValue: <Button fullWidth href={`/challenge/contents/${serviceId}/${titleId}/${row.episodeId}`} onClick={fn.gotoByAnchor}>{"이동"}</Button>},
                    ]}
                    updateGenerator={(row, index) => async (newValue) => {
                        await updateEpisode( {episode: {serviceId, titleId, ...row, ...newValue}} );
                        for (const [key,value] of Object.entries(newValue)){
                            row[key] = value;
                        }
                    }}
                    data={episodes}
                    name={"episodes"}

                />
            </div>
        </div>
        <Dialog open={askNewEpisodeId}>
            <DialogTitle>{"새 에피소드 코드를 입력해주세요."}</DialogTitle>
            <DialogContent>
                <div>
                    {"띄어쓰기 없이 영어대소문자, '_', '-', 숫자만을 이용해서 입력해주세요. 비워두시면 자동으로 코드를 생성합니다."}
                </div>
                <Input value={newEpisodeId} onChange={(event)=>{
                    let newValue = event.target.value
                    if (/[^0-9a-zA-Z\-_]/.exec(newValue)){
                        setErrorMessage("허용되지 않은 문자가 있습니다.")
                        return;
                    }
                    setErrorMessage("");
                    setNewEpisodeId(newValue);
                }} error={errorMessage !== ""} errormessage={errorMessage} />
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"primary"} onClick={()=>{
                    (async ()=>{
                        await createEpisode({
                            serviceId,
                            titleId,
                            episodeId: newEpisodeId,
                        });
                        let newEpisodes = await readEpisodes(serviceId, titleId);
                        setEpisodes(newEpisodes);
                    })();
                    setAskNewEpisodeId(false);
                }}>{"제출"}</Button>
                <Button color={"secondary"} onClick={()=>{
                    setNewEpisodeId("");
                    setErrorMessage("");
                    setAskNewEpisodeId(false);
                }}>{"취소"}</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={askDeleteTitle}>
            <DialogTitle>{"정말로 삭제하시겠어요?"}</DialogTitle>
            <DialogContent>
                <div>
                    {"한번 삭제하면 복구할수 없습니다!"}
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"primary"} onClick={()=>{
                    (async ()=>{
                        setIsLoading(true);
                        await deleteTitle(title);
                        fn.goto(`/challenge/contents/${serviceId}`);
                    })();
                    setAskDeleteTitle(false);
                }}>{"제출"}</Button>
                <Button color={"secondary"} onClick={()=>{
                    setAskDeleteTitle(false);
                }}>{"취소"}</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={askRemoveOwnership}>
            <DialogTitle>{"정말로 삭제하시겠어요?"}</DialogTitle>
            <DialogContent>
                <div>
                    <Avatar src={(ownerProfiles[pendingDeleteOwnership.userid] || {}).image}/>
                    {(ownerProfiles[pendingDeleteOwnership.userid] || {}).name}
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"primary"} onClick={()=>{
                    (async ()=>{
                        setIsLoading(true);
                        await deleteOwnership(pendingDeleteOwnership);
                        
                        let newOwnershipList = await readOwnerships(serviceId, titleId);

                        let newProfiles = await readProfile(newOwnershipList.map(row => row.userid).join(','))

                        setOwnershipList([...(ownershipList.filter(row => row.userid !== pendingDeleteOwnership.userid))])
                        setOwnerProfiles(
                            ((newProfiles instanceof Array)?newProfiles:[newProfiles]).map(row => {
                                let ret = {};
                                ret[row.userid] = row;
                                return ret;
                            }).reduce((a,b) => Object.assign(a,b),{})
                        )
                
                        setOwnershipList(newOwnershipList);
                        setIsLoading(false);
                    })();
                    setAskRemoveOwnership(false);
                }}>{"제출"}</Button>
                <Button color={"secondary"} onClick={()=>{
                    setAskRemoveOwnership(false);
                }}>{"취소"}</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={askAddOwnership}>
            <DialogTitle>{"작품 관리자 추가하기"}</DialogTitle>
            <DialogContent style={{display:'flex', flexDirection:'row', alignItems: 'flex-end'}}>
                <TextField label={"이메일"} value={pendingAddOwnershipEmail || ''} onChange={(event)=>setPendingAddOwnershipEmail(event.target.value)}></TextField>
                <Select label={"권한"} value={pendingAddOwnershipAuthority} onChange={(event)=>setPendingAddOwnershipAuthority(event.target.value)}>
                    <MenuItem value={"owner"}>{"소유자"}</MenuItem>
                    <MenuItem value={"write"}>{"수정권한"}</MenuItem>
                    <MenuItem value={"read"}>{"읽기권한"}</MenuItem>
                </Select>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"primary"} disabled={!pendingAddOwnershipEmail} onClick={()=>{
                    (async ()=>{
                        setIsLoading(true);
                        await createOwnership({serviceId, titleId, email: pendingAddOwnershipEmail, authority: pendingAddOwnershipAuthority});
                        
                        let newOwnershipList = await readOwnerships(serviceId, titleId);

                        let newProfiles = await readProfile(newOwnershipList.map(row => row.userid).join(','))

                        setOwnerProfiles(
                            ((newProfiles instanceof Array)?newProfiles:[newProfiles]).map(row => {
                                let ret = {};
                                ret[row.userid] = row;
                                return ret;
                            }).reduce((a,b) => Object.assign(a,b),{})
                        )
                
                        setPendingAddOwnershipAuthority('read');
                        setPendingAddOwnershipEmail(null);
                        setOwnershipList(newOwnershipList);
                        setIsLoading(false);
                    })();
                    setAskAddOwnership(false);
                }}>{"제출"}</Button>
                <Button color={"secondary"} onClick={()=>{
                    setAskAddOwnership(false);
                }}>{"취소"}</Button>
            </DialogActions>
        </Dialog>
        <Dialog 
            open={informSubmitResult} 
            onClose={ () => {
                setInformSubmitResult('');
                setIsSurveyRequired('');
            }} 
        >
            <DialogTitle>{"콘티 분석 신청하기"}</DialogTitle>
            <DialogContent>
                {informSubmitResult}
            </DialogContent>
            <DialogActions>
                <Button onClick={ () => {
                    setInformSubmitResult('');
                    setIsSurveyRequired('');
                }} >
                    {'확인'}
                </Button>
            </DialogActions>
        </Dialog>
        <LoadingCircle show={isLoading}/>
    </>);
}

export default EpisodeList;