import axios from 'axios';
import ss from '../../../Data/Session';

export const readPost = async (postId = null) => {
    try{
        let res = await axios.post('https://challenge-api.webtoon.today/post', {postId}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        if (res.data && res.data.code === 200){
            return res.data.data;
        } else {
            return [];
        }

    } catch(e) {
        return [];
    }
}