import axios from 'axios';
import ss from './Session';

/**
 * @typedef {{
 *   episodeId: string,
 *   title: string,
 *   thumbnail: string,
 *   href: string,
 *   isFree: number,
 *   createdAt: number,
 *   freeFrom: number,
 * }} episodeType
 */

/**
 * 
 * @param {sessionType} session 
 * @param {string} query 
 * @param {number} limit 
 * @returns {Promise<episodeType[]>}
 */
export async function readEpisodes (serviceId, titleId, ) {
    try{
        let res = await axios.post('https://api.webtoon.today/episodes', {serviceId, titleId,}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.data.sort( (a,b) => a.episodeId - b.episodeId);

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}

/**
 * 
 * @param {{
 *  session: sessionType
 *  serviceId: string,
 *  titleId: string,
 *  episodeId: string,
 * @returns {Promise<string>}
 */
export async function createEpisode ({serviceId, titleId, episodeId}) {
    try{
        let res = await axios.put('https://api.webtoon.today/episodes', {serviceId, titleId, episodeId}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}

/**
 * 
 * @param {{
*  session: sessionType
*  serviceId: string,
*  titleId: string,
*  episodeId: string,
* @returns {Promise<string>}
*/
export async function updateEpisode ({episode}) {
    try{
        let res = await axios.patch('https://api.webtoon.today/episodes', episode, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}

/**
* 
* @param {{
*  session: sessionType
*  serviceId: string,
*  titleId: string,
*  episodeId: string,
* @returns {Promise<string>}
*/
export async function deleteEpisode ({episode}) {
    try{
        let res = await axios.delete(`https://api.webtoon.today/episodes?serviceId=${episode.serviceId}&titleId=${episode.titleId}&episodeId=${episode.episodeId}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}