import React from 'react';
import { useDispatch } from 'react-redux';

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip} from '@material-ui/core';

import {listAnalyticsProcess, updateAnalyticsProcess, readAnalyticsProcessResult} from '../../Data/AnalyticsProcess';
import {getTitles} from '../../Data/Title';
import LoadingCircle from '../../Component/LoadingCircle';
import EditableTable from '../../Component/Table/EditableTable';

import {FilteredApplication, FeedbackResult} from '../Email';

import './AnalyticsProcess.scss';
import moment from 'moment-timezone';

import {fn, randomString} from '../../Functions';

const currentStartingFriday = ((curr)=>(new Date(curr - ( ( ( (curr.getDay() + 2) * 24 + curr.getHours() ) * 60 + curr.getMinutes() ) * 60 + curr.getSeconds() ) * 1000 - curr.getMilliseconds() ) ) )(new Date())
const competitionGroups = Array(54).fill(0).map((row, idx) => [
    new Date(currentStartingFriday - (idx - 1) * 7 * 24 * 3600 * 1000).getTime() / 1000,
    new Date(currentStartingFriday - (idx - 1 - 1) * 7 * 24 * 3600 * 1000).getTime() / 1000,
    `${(new Date(currentStartingFriday - (idx - 1 - 1) * 7 * 24 * 3600 * 1000).getMonth() + 1)}월 ${Math.ceil(new Date(currentStartingFriday - (idx - 1 - 1) * 7 * 24 * 3600 * 1000).getDate() / 7)}주차`
])

const AnalyticsProcess = () => {
    let dispatch = useDispatch();

    const [isLoading, setIsLoading] = React.useState(false);

    const [candidates, setCandidates] = React.useState([]);
    const [titleInfo, setTitleInfo] = React.useState({});

    const [refreshTimer, setRefreshTimer] = React.useState(null);

    const [results, setResults] = React.useState([]);

    const [emailCheck, setEmailCheck] = React.useState(false);
    const [emailSendCallback, setEmailSendCallback] = React.useState(()=>()=>{});
    const [currentEmailTarget, setCurrentEmailTarget] = React.useState({});
    const [currentEmailProgress, setCurrentEmailProgress] = React.useState('');

    const [askOnceAgain, setAskOnceAgain] = React.useState(false);
    const [postponedAction, setPostonedAction] = React.useState(()=>()=>{});
    const [postponedMessage, setPostponedMessage] = React.useState("");
    
    React.useEffect(()=>{
        document.title = `분석 및 피드백`;

        (async ()=>{
            setIsLoading(true);

                let candi = await listAnalyticsProcess();
                setCandidates(candi);

                let titleList = await getTitles(candi.map(row => [row.serviceId, row.titleId]), true, dispatch);

                let titleObj = titleList.map(row => ({[`${row.serviceId}:${row.titleId}`]: row})).reduce((a,b)=> Object.assign(a,b), {});
                setTitleInfo(titleObj);

                let resultList = await readAnalyticsProcessResult(candi);
                let resultObj = resultList.map(({serviceId, titleId, episodeId, snapshotId, ...other}) => ({[[serviceId, titleId, episodeId, snapshotId].join(':')]: {serviceId, titleId, episodeId, snapshotId, ...other}})).reduce((a,b)=> Object.assign(a,b), {});
                setResults(resultObj);
            setIsLoading(false);
        })();
        
    },[dispatch])

    const updateRow = async (row) => {

        const index = candidates.map(
            ({serviceId, titleId, episodeId, snapshotId}) => [serviceId, titleId, episodeId, snapshotId].join(':')
        ).indexOf(`${row.serviceId}:${row.titleId}:${row.episodeId}:${row.snapshotId}`)

        if (refreshTimer){
            clearTimeout(refreshTimer);
            setRefreshTimer(null);
        }

        setCandidates([
            ...candidates.slice(0,index),
            row,
            ...candidates.slice(index+1)
        ])
        updateAnalyticsProcess(row);
        
        const timer = setTimeout(()=>{
            (async () => {
                setIsLoading(true);
    
                let candi = await listAnalyticsProcess();
                setCandidates(candi);
        
                setIsLoading(false);

                setRefreshTimer(null);
            })();
        },2000)

        setRefreshTimer(timer);
    }

    return (<div className={"AnalyticsProcess"}>
        {competitionGroups.map(([startTS, endTS, weekIter]) => {
            let subsetCandidates = candidates.filter(row => startTS < row.createdAt && row.createdAt < endTS && titleInfo[`${row.serviceId}:${row.titleId}`]);

            if (subsetCandidates.length === 0){
                return <div key={randomString(16)}></div>;
            }

            return <div key={`${weekIter} subset`} className={"AnalyticsProcessSubset"}>
                <h2 className={"SubsetHeader"}>{weekIter}</h2><span>{`${
                        ((num)=>(num>0?`심의중: ${num} 개`:``))(subsetCandidates.filter(row => !row.validatedAt).length)
                    } ${
                        ((num)=>(num>0?`분석대기: ${num} 개`:``))(subsetCandidates.filter(row => !row.testStartedAt && row.vaildatedAt).length)
                    } ${
                        ((num)=>(num>0?`공지대기: ${num} 개`:``))(subsetCandidates.filter(row => !row.resultConfirmedAt && row.testStartedAt && row.validatedAt).length)
                    } ${
                        ((num)=>(num>0?`처리완료: ${num} 개`:``))(subsetCandidates.filter(row => row.resultConfirmedAt || (row.validatedAt && !row.isValid)).length)
                    } `}</span>
                <EditableTable
                    headerButtonHeaders={[]}
                    headerButtonsFunction={()=>[]}
                    defaultSortCol={[1, "asc"]}
                    data={subsetCandidates
                        .filter(candidate => titleInfo[`${candidate.serviceId}:${candidate.titleId}`])
                        .map(candidate => {
                            let title = titleInfo[`${candidate.serviceId}:${candidate.titleId}`];
                            let result = results[[candidate.serviceId, candidate.titleId, candidate.episodeId, candidate.snapshotId].join(':')] || {};
                            return {...title, ...candidate, ...result};
                        })}
                    columnHeaders={["제목", "생성일", "광고컷", "필터링", "테스트 집행", "결과 검토"]}
                    columnsFunction={(row, index) => [
                        {isEditing: false, field: "title", type: 'default', style: {maxWidth: 150, padding: 5, whiteSpace: 'nowrap'},
                            defaultValue: <Tooltip title={row.title}><div style={{display:'flex', flexDirection: 'row'}}>
                                <div style={{whiteSpace:'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis',}}>{row.title}</div>
                                <IconButton size={"small"} href={`/home/contents/${row.serviceId}/${row.titleId}`} onClick={fn.gotoByAnchor}>📃</IconButton>
                                <IconButton href={`/report/${row.serviceId}/${row.titleId}/${row.episodeId}/${row.snapshotId}`} onClick={fn.gotoByAnchor} size={"small"}>🎢</IconButton>
                            </div></Tooltip> },
                        {isEditing: false, field: "createdAt", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',}, defaultValue: moment(row.createdAt * 1000).format('YYYY-MM-DD')},
                        {isEditing: false, field: "slides", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',},
                            defaultValue: (!row.slides || !row.slides.length)?"🚫":"🟢",},
                        {isEditing: false, field: "validatedAt", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',},
                            defaultValue: !row.validatedAt?<>
                                <IconButton size={"small"} onClick={()=>{updateRow({...row, validatedAt: new Date().getTime() / 1000, isValid: true})}}>✅</IconButton>
                                <IconButton size={"small"} onClick={()=>{
                                    setCurrentEmailProgress("filtered");
                                    setCurrentEmailTarget({...row, weekIter});
                                            
                                    setEmailSendCallback(()=>
                                        (success, message)=>{
                                            updateRow({...row, validatedAt: new Date().getTime() / 1000, isValid: false, rejectReason: message})
                                            setEmailCheck(false);
                                        }
                                    );

                                    setEmailCheck(true);
                                    
                                }}>❌</IconButton>
                                <IconButton size={"small"} onClick={()=>{
                                    setAskOnceAgain(true);
                                    setPostonedAction(()=>()=>{
                                        updateRow({...row, validatedAt: new Date().getTime() / 1000, isValid: false, rejectReason: "무시(메일X)"})
                                    })
                                    setPostponedMessage(<div>
                                        {row.title}<br/>
                                        {"이메일을 발송하지 않고 무시처리 합니다."}<br/>
                                    </div>)
                                }} > 🟠 </IconButton>
                            </>:<>
                                {row.isValid?"🟢":<>
                                    {"🚫"}
                                    <Tooltip title={row.rejectReason || ""}><span style={{display: "inline-block", whiteSpace:"nowrap", verticalAlign: "middle", maxWidth: 90, overflow: 'hidden', textOverflow: 'ellipsis'}}>{row.rejectReason||""}</span></Tooltip>
                                </>}
                                <IconButton size={"small"} style={{fontSize: '0.6rem'}} onClick={()=>{
                                    setAskOnceAgain(true);
                                    setPostonedAction(()=>()=>{
                                        updateRow({...row, validatedAt: null, isValid: null})
                                    })
                                    setPostponedMessage(<div>
                                        {row.title}<br/>
                                        {row.isValid?"🟢":"🚫"}<br/>
                                        {"원고 심의결과를 취소하고 다시 검토합니다."}<br/>
                                    </div>)
                                }}>🔙</IconButton>
                            </>},
                        {isEditing: false, field: "testStartedAt", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',},
                            defaultValue: !row.testStartedAt?"📩":(new Date().getTime() / 1000 - row.testStartedAt < 24 * 60 * 60)?"🕝":"🟢",},
                        {isEditing: false, field: "resultConfirmedAt", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center', width: 150, marginLeft: 'auto', marginRight: 'auto'},
                            defaultValue: !row.resultConfirmedAt?
                            !row.isValid?
                            !row.validatedAt?<>
                                {"필터링 대기중"}
                            </>:<>
                                {"제외 대상"}
                            </>:
                            !row.CTR?<>
                                {"대기중"}
                            </>:<>
                                {`${('' + row.CTR * 100).substring(0,3)}% / ${('' + row.fullReadRate * 100).substring(0,4)}% / ${('' + row.nextClickRate * 100).substring(0,4)}%`}
                                <IconButton size={"small"} onClick={()=>{
                                    setCurrentEmailProgress("resultConfirmed");
                                    setCurrentEmailTarget({...row, weekIter});
                                            
                                    setEmailSendCallback(()=>
                                        ()=>{
                                            updateRow({...row, resultConfirmedAt: new Date().getTime() / 1000})
                                            setEmailCheck(false);
                                        }
                                    );

                                    setEmailCheck(true);
                                }}>✅</IconButton>
                            </>:<>
                                {`${('' + row.CTR * 100).substring(0,3)}% / ${('' + row.fullReadRate * 100).substring(0,4)}% / ${('' + row.nextClickRate * 100).substring(0,4)}%`}
                                <IconButton size={"small"} style={{fontSize: '0.6rem'}} onClick={()=>{
                                    setAskOnceAgain(true);
                                    setPostonedAction(()=>()=>{
                                        updateRow({...row, resultConfirmedAt: null})
                                    })
                                    setPostponedMessage(<div>
                                        {row.title}<br/>
                                        {`${('' + row.CTR * 100).substring(0,3)}% / ${('' + row.fullReadRate * 100).substring(0,4)}% / ${('' + row.nextClickRate * 100).substring(0,4)}%`}<br/>
                                        {"분석결과 공지를 취소하고 다시 검토합니다."}<br/>
                                    </div>)
                                }}>🔙</IconButton>
                            </>},
                    ]}
                    updateGenerator={(row, index)=> (newValue)=>{
                        updateRow({...row, ...newValue})
                    }}
                />
            </div>
        })}

        <Dialog open={emailCheck} maxWidth={"md"} onClose={()=>{setEmailCheck(false)}}>
            <DialogTitle>{"이메일 발송"}</DialogTitle>
            <DialogContent style={{backgroundColor:'rgb(233,233,233)'}}>
                <div>
                    {currentEmailProgress === "filtered"?<FilteredApplication
                        email={currentEmailTarget.email} title={currentEmailTarget.title} author={currentEmailTarget.author}
                        sendCallback={emailSendCallback}
                    />
                    :currentEmailProgress === "resultConfirmed"?<FeedbackResult
                        email={currentEmailTarget.email} title={currentEmailTarget.title} author={currentEmailTarget.author}
                        analysisResultLocation={`https://webtoon.today/report/${currentEmailTarget.serviceId}/${currentEmailTarget.titleId}/${currentEmailTarget.episodeId}/${currentEmailTarget.snapshotId}`}
                        sendCallback={emailSendCallback}
                    />:<></>}
                </div>
            </DialogContent>
        </Dialog>
        <Dialog open={askOnceAgain}>
            <DialogTitle>{"다음 동작을 확인해주세요."}</DialogTitle>
            <DialogContent>{postponedMessage}</DialogContent>
            <DialogActions>
                <Button color={"primary"} variant={"contained"} onClick={()=>{
                    postponedAction();
                    setPostonedAction(()=>()=>{});
                    setPostponedMessage("");
                    setAskOnceAgain(false);
                }}>{"실행"}</Button>
                <Button color={"secondary"} variant={"outlined"} onClick={()=>{
                    setPostonedAction(()=>()=>{});
                    setPostponedMessage("");
                    setAskOnceAgain(false);
                }}>{"취소"}</Button>
            </DialogActions>
        </Dialog>
        <LoadingCircle show={isLoading}/>
    </div>);
}

export default AnalyticsProcess;