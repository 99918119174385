import React from 'react';

import './CommonSectionHeader.scss'

const CommonSectionHeader = ({title}) => {
    return (
        <div className={'HeaderContainer'}>
            <div className={'HeaderTitle'}>
                <span>{title}</span>
                <span className={'Dot'} />
            </div>
        </div>
    );
} 

export default CommonSectionHeader;
