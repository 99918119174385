import {useEffect, useState} from "react";
import EditableTable from '../../../Component/Table/EditableTable';

import LoadingCircle from "../../../Component/LoadingCircle";

import { getCampaigns, createOrUpdateCampaign } from "./Data/Campaign";

const Campaign = ({serviceId, titleId}) => {

    const [campaigns, setCampaigns] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const refreshCampaigns = async ({serviceId, titleId}) => {
        setIsLoading(true);
        let campaigns = await getCampaigns({serviceId, titleId});
        setCampaigns(campaigns);
        setIsLoading(false);
    }

    useEffect(()=>{
        refreshCampaigns({serviceId, titleId});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serviceId, titleId]);

    const [innerHeight, setInnerHeight] = useState(window.innerHeight);
    const [, setInnerWidth] = useState(window.innerWidth);
    useEffect(()=>{
        const resizeListener = () => {
            setInnerHeight(window.innerHeight)
            setInnerWidth(window.innerWidth);
        }
        window.addEventListener('resize', resizeListener);

        return ()=>{
            window.removeEventListener('resize', resizeListener);
        }
    },[])

    const [editingIdx, setEditingIdx] = useState(null);
    
    return (<div className={"CampaignList"}>
        <div className={"CampaignListContainer"}>
            <EditableTable
                style={{maxHeight: innerHeight - 130, overflow: 'auto'}}
                headerButtonHeaders={[
                    ""
                ]}
                columnHeaders={['제목', '채널', '캠페인id', '수수료율(%)', '부가세율(%)']}
                headerButtonsFunction={(row, index)=>[
                    (editingIdx !== null && editingIdx === index)
                    ?{
                        name: '완료', color: 'primary', props:{ style: {paddingTop:0, paddingBottom:0, marginLeft: 5}, fullWidth: true, },
                        onClick:()=>{ setEditingIdx(null);}
                    } 
                    :{
                        name: '수정', color: 'primary', props:{ style: {paddingTop:0, paddingBottom:0, marginLeft: 5}, fullWidth: true, },
                        onClick:()=>{ setEditingIdx(index);}
                    }
                ]}
                columnsFunction={(row, index)=>[
                    {isEditing: false, type: 'string', field: 'path', style:{whiteSpace: 'nowrap', fontSize: '0.8rem'}, defaultValue: row.title},
                    {isEditing: false, type: 'string', field: 'path', style:{whiteSpace: 'nowrap', fontSize: '0.8rem'}, defaultValue: row.network},
                    {isEditing: false, type: 'string', field: 'path', style:{whiteSpace: 'nowrap', fontSize: '0.8rem'}, defaultValue: row.campaignId},
                    {isEditing: index === editingIdx, type: 'string', field: 'commissionRate', style:{whiteSpace: 'nowrap', fontSize: '0.8rem', width: '100%'}, defaultValue: row.commissionRate},
                    {isEditing: index === editingIdx, type: 'string', field: 'vatRate', style:{whiteSpace: 'nowrap', fontSize: '0.8rem', width: '100%'}, defaultValue: row.vatRate},
                    
                ]}
                updateGenerator={(row, index) => async (newValue) => {
                    createOrUpdateCampaign({...row, ...newValue})
                    for(const [key, value] of Object.entries(newValue)){
                        row[key] = value;
                    }
                }}
                isDownloadable={true}
                data={campaigns}
                name={"campaigns"}

            />
        </div>
        <LoadingCircle show={isLoading}/>
    </div>);
}

export default Campaign;