import axios from 'axios';
import ss from './Session';

export const getSizeOf = async (key) => {
    let ret = null;

    const req = {key};

    let res = await axios.post(`https://api.webtoon.today/image`, req, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        }
    })
    
    if (res.data && res.data.code === 200){
        return res.data.data;
    }else{
        console.error(res.code, res.data);
    }

    return ret;
};

export const updateImage = async (imageComponent, row) => {
    let ret = null;

    let req = row;
    if (row instanceof Array){
        req = {records: row};
    }
    req.session = ss.getCurrentSession();

    let formData = new FormData();
    formData.append("image", imageComponent.files[0]);
    let res = await axios.put(`https://api.webtoon.today/image`, formData, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`,
            'Content-Type': 'multipart/form-data'
        }
    })

    if (res.data && res.data.code === 200){
        return res.data.data.id;
    }else{
        console.error(res.code, res.data);
    }

    return ret;
};

export const createImageWithBase64 = async (imageComponent, onProgress = ()=>{}) => {
    let ret = null;

    let res = await axios.put(`https://api.webtoon.today/image`, {
        file: await toBase64(imageComponent.files[0])
    }, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    })

    if (res.data && res.data.code === 200){
        return 'https://static.webtoon.today/'+res.data.data.filename;
    }else{
        console.error(res.code, res.data);
    }

    return ret;
}

export const createImageWithForm = async (imageComponent, onProgress = ()=>{}) => {
    let formData = new FormData();
    let ret = null;

    formData.append('file', imageComponent.files[0])
    
    let res = await axios.put(`https://api.webtoon.today/image`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    })

    if (res.data && res.data.code === 200){
        return 'https://static.webtoon.today/'+res.data.data.filename;
    }else{
        console.error(res.code, res.data);
    }

    return ret;
}

export const createImageWithS3 = async (imageComponent, onProgress = ()=>{}) => {
    let formData = new FormData();
    let ret = null;

    let S3Signed = await axios.put(`https://api.webtoon.today/image`, {
        filename: imageComponent.files[0].name
    }, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    })

    formData.append('file', imageComponent.files[0])
    
    let res = await axios.put(S3Signed.data.data.uploadURL, imageComponent.files[0], {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        withCredentials: true
    })

    if (res.status === 200){
        ret = 'https://static.webtoon.today/'+S3Signed.data.data.Key;
    }

    return ret;
}

export const createImage = createImageWithForm

export const hookSpreadZip = async ({zipName, serviceId, titleId, episodeId, snapshotId}) => {
    let ret = [];

    try{
        let res = await axios.post(`https://api.webtoon.today/spread-zip`, {zipName, serviceId, titleId, episodeId, snapshotId}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`,
            }
        })
        if (res.data && res.data.code === 200){
            return res.data.data;
        }else{
            console.error(res.code, res.data);
        }
    }catch(e){
        console.error(e);
        throw e;
    }


    return ret;
}

export const spreadZip = async ({zipName, serviceId, titleId, episodeId, snapshotId}) => {
    let ret = [];

    try{
        let res = await axios.post(`https://api.webtoon.today/spread-zip`, {zipName, serviceId, titleId, episodeId, snapshotId, type: 'immediate'}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`,
            }
        })
        if (res.data && res.data.code === 200){
            return res.data.data;
        }else{
            console.error(res.code, res.data);
        }
    }catch(e){
        console.error(e);
        throw e;
    }


    return ret;
}

/**
 * 
 * @param {Blob} file 
 */
export const fileToBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const toBase64 = (file, onProgress) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
    reader.onprogress = onProgress
});