
import axios from 'axios'
import ss from './Session';

/**
 * @typedef {{
 *  serviceId: string,
 *  titleId: string,
 *  episodeId?: string,
 *  snapshotId?: string,
 *  ctr_rank: number,
*  fullread_rank: number,
 *  retention: number
* }} titleRankType
 * */


/**
 * 
 * @param {{
 *  serviceId: string,
 *  titleId: string,
 *  episodeId: string,
 *  snpashotId: string
 * }} indexInfo
* @returns {Promise<titleRankType[]>}
 */
export const listTitleRank = async (indexInfo) => {
    if ( !indexInfo.serviceId || !indexInfo.titleId ){
        console.log(ss.getCurrentSession(), indexInfo)
        return [];
    }
    let res = await axios.post('https://api.webtoon.today/title-rank', indexInfo, {
    headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    }else {
        return [];
    }
}

/**
 * 
* @param {titleRankType} titleRank
 * @returns {Promise<string|false>}
 */
export const createTitleRank = async (titleRank) => {
    if (!ss.getCurrentSession().userid || !titleRank.serviceId || !titleRank.titleId || !titleRank.episodeId || !titleRank.snapshotId){
        return false;
    }
    
    let res = await axios.put('https://api.webtoon.today/title-rank', titleRank, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.id;
    }else{
        return false;
    }
}

/**
 * 
 * @param {import('./Session').sessionType} session 
* @param {titleRankType} titleRank
 * @returns {Promise<string|false>}
 */
export const updateTitleRank = async (titleRank) => {
    if (!ss.getCurrentSession().userid || !titleRank.serviceId || !titleRank.titleId || !titleRank.episodeId || !titleRank.snapshotId){
        return false;
    }
    
    let res = await axios.patch('https://api.webtoon.today/title-rank', titleRank, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.id;
    }else{
        return false;
    }
}


/**
 * 
 * @param {import('./Session').sessionType} session 
* @param {titleRankType} titleRank
 * @returns {Promise<string|false>}
 */
export const deleteTitleRank = async (titleRank) => {
    if (!ss.getCurrentSession().userid || !titleRank.serviceId || !titleRank.titleId || !titleRank.episodeId || !titleRank.snapshotId){
        return false;
    }
    
    let res = await axios.delete(`https://api.webtoon.today/title-rank?serviceId=${titleRank.serviceId}&titleId=${titleRank.titleId}&episodeId=${titleRank.episodeId}&snapshotId=${titleRank.snapshotId}`, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.id;
    }else{
        return false;
    }
}