import React from 'react';
import './Home.scss';

import {randomString, fn} from '../Functions';
import { Button, IconButton } from '@material-ui/core';
import { ArrowDownward, ArrowUpward, FastForwardOutlined, MailOutlineOutlined } from '@material-ui/icons';

import {logBehavior} from '../Data/Behavior';

let lastKnownTouch = {};
let eventQueue = [];

const Home = (props) => {
    
    React.useEffect(()=>{
        document.title = "오늘의웹툰: 데이터 기반 에이전시";
    },[])

    const [uniqueId,] = React.useState(randomString(16));
    const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
    const [currentPage, setCurrentPage] = React.useState(0);

    /** @type {['artist'|'partner', React.Dispatch<React.SetStateAction<'artist'|'partner'>>]} */
    const [mode, setMode] = React.useState('artist');

    const contents = React.useMemo(()=>({
        artist: [
            <></>,
            <>
                <div className={"Background Lightblue"} style={{top: 'initial', marginTop: 'auto', marginBottom: 'auto', height: 'initial', paddingTop: '100%'}}></div>
                <img src={"https://static.webtoon.today/ddah/storyboard.jpg"} style={{width: '70%', maxWidth: 500, position: 'absolute', left:0, bottom: '60%'}} alt={"storyboard"}/>
                <div className={"Text"} style={{position: 'absolute', right: 0, top: '60%', textAlign: 'left', backgroundColor: 'rgba(66,66,66,1)', paddingLeft: 10, paddingTop: 10, paddingBottom: 10, paddingRight:30}}>
                    {"\"그림 열심히 그리면 데뷔할 수 있을까요?\""}<br/>
                    {"\"스토리가 재밌으면 반드시 성공할까요?\""}<br/>
                    {"\"내 작품, 재미있긴한데 독자들이 좋아할까요?\""}<br/>
                    {"\"플랫폼이 내 작품을 알아봐줄까요?\""}
                </div>
            </>,
            <>
                <div className={"Text"} style={{backgroundColor: 'rgba(66,66,66,1)', padding: 10, color: 'white'}}>
                    {"독자들이 내 작품을 "}<span style={{fontWeight:"bold", color: '#00B0F0', textShadow: '0 0 2px black'}}>{"얼마나"}</span>{" 재미있게 보고 있는지"}<br/>
                    {"내가 생각하는 심쿵 포인트가 "}<span style={{fontWeight:"bold", color: '#00B0F0', textShadow: '0 0 2px black'}}>{"얼마나"}</span>{" 먹히는지"}<br/>
                    <span style={{fontWeight:"bold", color: '#00B0F0', textShadow: '0 0 2px black'}}>{"알고"}</span>{" 그리면 더 잘 그릴 수 있지 않을까요?"}
                </div>
            </>,
            <>
                <div className={"Background Red"} style={{marginTop: 'auto', marginBottom: 'auto', maxHeight: 100, top: 'inherit'}}></div>
                <div className={"Text Subheader"}>
                    {"오늘의웹툰은 "}<span style={{fontWeight:"bold", color: '#00B0F0', textShadow: '0 0 2px black'}}>{"데이터-기반"}</span>{" 에이전시입니다."}<br/>
                </div>
            </>,
            <div className={"TextAndSlideShow"}>
                <div className={"Background"} style={{backgroundColor:'rgba(255,255,255,1)', marginTop: 'auto', marginBottom: 'auto', maxHeight: 400, top: 'inherit'}}></div>
                <div className={"Text"} style={{color: 'black', zIndex:3}}>
                    {"유저의 활동을 컷단위로 분석하여 데이터를 만들고 몰입되는 작품을 그리게 도와줍니다. 더 재미있고, 더 시장성있게 바꿀 수 있습니다."}
                </div>
                <div className={"SlideShow"}>
                    <img src={"https://static.webtoon.today/ddah/data_analysis_6.jpg"} alt={"data analysis"}/>
                    <img src={"https://static.webtoon.today/ddah/data_analysis_5.jpg"} alt={"data analysis"}/>
                    <img src={"https://static.webtoon.today/ddah/data_analysis_4.jpg"} alt={"data analysis"}/>
                    <img src={"https://static.webtoon.today/ddah/data_analysis_3.jpg"} alt={"data analysis"}/>
                    <img src={"https://static.webtoon.today/ddah/data_analysis_2.jpg"} alt={"data analysis"}/>
                    <img src={"https://static.webtoon.today/ddah/data_analysis_1.jpg"} alt={"data analysis"}/>
                </div>
            </div>,
            <>
                <div className={"Background Red"} style={{marginTop: 'auto', marginBottom: 'auto', maxHeight: 100, top: 'inherit'}}></div>
                <div className={"Text Subheader"}>
                    {"오늘의웹툰은 "}<span style={{fontWeight:"bold", color: '#00B0F0', textShadow: '0 0 2px black'}}>{"작가를 양성하는"}</span>{" 에이전시입니다."}<br/>
                </div>
            </>,
            <div className={"TextAndSlideShow"}>
                <div className={"Background"} style={{backgroundColor:'rgba(255,255,255,1)', marginTop: 'auto', marginBottom: 'auto', maxHeight: 400, top: 'inherit'}}></div>
                <div className={"SlideShow"}>
                    <img src={"https://static.webtoon.today/ddah/cowork_6.jpg"} alt={"cowork"}/>
                    <img src={"https://static.webtoon.today/ddah/cowork_5.jpg"} alt={"cowork"}/>
                    <img src={"https://static.webtoon.today/ddah/cowork_4.jpg"} alt={"cowork"}/>
                    <img src={"https://static.webtoon.today/ddah/cowork_3.jpg"} alt={"cowork"}/>
                    <img src={"https://static.webtoon.today/ddah/cowork_2.jpg"} alt={"cowork"}/>
                    <img src={"https://static.webtoon.today/ddah/cowork_1.jpg"} alt={"cowork"}/>
                </div>
                <div className={"Text"} style={{color: 'black', }}>
                    {"경력 작가가 튜터로 참여하여 내 오리지널 작품의 개발을 돕습니다. 내 작품의 개성을 존중받으면서 상업성을 살릴 수 있게, 스토리 쓰기부터 작화에 이르기까지 망망대해에서 혼자 헤매지 않아도 됩니다."}
                </div>
            </div>,
            <>
                <div className={"Background"} style={{}}></div>
                <div className={"Glass"} style={{width: '100%', paddingTop: '100%', borderRadius: '50%', backgroundColor: 'black'}}></div>
                <div style={{position: 'absolute', left: 200, top: 150, width: 100, height: 100, borderRadius:50}} className={"Glass GradientSkyblueToSkygreen"}></div>
                <div style={{position: 'absolute', right: 200, bottom: 100, width: 80, height: 80, borderRadius:40}} className={"Glass GradientSkyredToSkyorange"}></div>
                <div className={"Text WithLogo"}>
                    <img style={{width: 247, height: 64}} src={"https://static.webtoon.today/ddah/logo-01.png"} alt={"logo"}/>
                    <span>{"이 당신을 기다립니다."}</span>
                </div>
            </>,
            <>
                <div className={"Background White"}></div>
                <img className={"BigLogo"} src={"https://static.webtoon.today/ddah/logo-01.png"} alt={"logo"}/>
                <div className={"Text"} style={{position: 'inherit', color: 'black', marginTop: 20, maxWidth: 600}}>
                    {"오른쪽 아래 메일쓰기 버튼("}<a href="mailto:artist@webtoon.today" onClick={fn.gotoByAnchor}>{"artist@webtoon.today"}</a>{")을 이용해 아래 항목을 첨부하여 보내주세요. 영업일 기준 최장 2일 이내에 반드시 회신드리겠습니다."}
                    <ul style={{textAlign:"left", maxWidth: 400}}>
                        <li>{"간단한 자기소개"}</li>
                        <li>{"연락처"}</li>
                        <li>{"완성원고 1화"}</li>
                        <li>{"콘티 2화(총 3화까지)"}</li>
                        <li>{"시놉시스(최대 A4 용지 한장)"}</li>
                        <li>{"캐릭터 디자인/설정"}</li>
                    </ul>
                    {"오늘의웹툰은 변호사 검수를 받은 표준 계약서를 운영하고 있으며, 1:1로 작가님의 상황에 맞추어 공정하게 계약을 진행합니다."}
                </div>
            </>
        ],
        partner: [
            <></>,
            <>
                <div className={"Background Lightblue"} style={{top: 'initial', marginTop: 'auto', marginBottom: 'auto', height: 'initial', paddingTop: '100%'}}></div>
                <img src={"https://static.webtoon.today/ddah/storyboard.jpg"} style={{width: '70%', maxWidth: 500, position: 'absolute', left:0, bottom: '60%'}} alt={"storyboard"}/>
                <div className={"Text"} style={{position: 'absolute', right: 0, top: '60%', textAlign: 'left', backgroundColor: 'rgba(66,66,66,1)', paddingLeft: 10, paddingTop: 10, paddingBottom: 10, paddingRight:30}}>
                    {"\"그림이 예쁘면 대박일까요?\""}<br/>
                    {"\"스토리가 재밌으면 반드시 성공할까요?\""}<br/>
                    {"\"이 작품, 나는 재미있는데 독자들도 좋아할까요?\""}<br/>
                    {"\"어떤 작품을 골라야 성공할 수 있을까요?\""}
                </div>
            </>,
            <>
                <div className={"Text"} style={{backgroundColor: 'rgba(66,66,66,1)', padding: 10, color: 'white'}}>
                    {"독자들이 작품을 얼마나 "}<span style={{fontWeight:"bold", color: '#00B0F0', textShadow: '0 0 2px black'}}>{"몰입도"}</span>{" 높게 읽는지"}<br/>
                    {"얼마나 많은 독자가 "}<span style={{fontWeight:"bold", color: '#00B0F0', textShadow: '0 0 2px black'}}>{"정주행"}</span>{"으로 전환되는지"}<br/>
                    <span style={{fontWeight:"bold", color: '#00B0F0', textShadow: '0 0 2px black'}}>{"알고"}</span>{" 고르면 더 좋은 작품을 찾을 수 있지 않을까요?"}
                </div>
            </>,
            <>
                <div className={"Background Red"} style={{marginTop: 'auto', marginBottom: 'auto', maxHeight: 100, top: 'inherit'}}></div>
                <div className={"Text Subheader"}>
                    {"오늘의웹툰은 "}<span style={{fontWeight:"bold", color: '#00B0F0', textShadow: '0 0 2px black'}}>{"데이터-기반"}</span>{" 에이전시입니다."}<br/>
                </div>
            </>,
            <div className={"TextAndSlideShow"}>
                <div className={"Background"} style={{backgroundColor:'rgba(255,255,255,1)', marginTop: 'auto', marginBottom: 'auto', maxHeight: 400, top: 'inherit'}}></div>
                <div className={"SlideShow"}>
                    <img src={"https://static.webtoon.today/ddah/data_analysis_6.jpg"} alt={"data analysis"}/>
                    <img src={"https://static.webtoon.today/ddah/data_analysis_5.jpg"} alt={"data analysis"}/>
                    <img src={"https://static.webtoon.today/ddah/data_analysis_4.jpg"} alt={"data analysis"}/>
                    <img src={"https://static.webtoon.today/ddah/data_analysis_3.jpg"} alt={"data analysis"}/>
                    <img src={"https://static.webtoon.today/ddah/data_analysis_2.jpg"} alt={"data analysis"}/>
                    <img src={"https://static.webtoon.today/ddah/data_analysis_1.jpg"} alt={"data analysis"}/>
                </div>
                <div className={"Text"} style={{color: 'black', zIndex:3}}>
                    {"퍼포먼스 마케팅을 활용, 유저의 활동을 컷단위로 분석하고 정주행 전환을 측정하여 작품의 생애가치를 분석합니다."}
                </div>
            </div>,
            <>
                <div className={"Background Red"} style={{marginTop: 'auto', marginBottom: 'auto', maxHeight: 100, top: 'inherit'}}></div>
                <div className={"Text Subheader"}>
                    {"오늘의웹툰은 "}<span style={{fontWeight:"bold", color: '#00B0F0', textShadow: '0 0 2px black'}}>{"작품 창작에 기여하는"}</span>{" 에이전시입니다."}<br/>
                </div>
            </>,
            <div className={"TextAndSlideShow"}>
                <div className={"Background"} style={{backgroundColor:'rgba(255,255,255,1)', marginTop: 'auto', marginBottom: 'auto', maxHeight: 400, top: 'inherit'}}></div>
                <div className={"Text"} style={{color: 'black', }}>
                    {"경력 작가가 튜터로 참여하여 작품 개발을 돕습니다. 스토리 쓰기, 작화에 대해 완결까지 이어지는 높은 품질을 기대할 수 있습니다."}
                </div>
                <div className={"SlideShow"}>
                    <img src={"https://static.webtoon.today/ddah/cowork_6.jpg"} alt={"cowork"}/>
                    <img src={"https://static.webtoon.today/ddah/cowork_5.jpg"} alt={"cowork"}/>
                    <img src={"https://static.webtoon.today/ddah/cowork_4.jpg"} alt={"cowork"}/>
                    <img src={"https://static.webtoon.today/ddah/cowork_3.jpg"} alt={"cowork"}/>
                    <img src={"https://static.webtoon.today/ddah/cowork_2.jpg"} alt={"cowork"}/>
                    <img src={"https://static.webtoon.today/ddah/cowork_1.jpg"} alt={"cowork"}/>
                </div>
            </div>,
            <>
                <div className={"Background"} style={{}}></div>
                <div className={"Glass"} style={{width: '100%', paddingTop: '100%', borderRadius: '50%', backgroundColor: 'black'}}></div>
                <div style={{position: 'absolute', left: 200, top: 150, width: 100, height: 100, borderRadius:50}} className={"Glass GradientSkyblueToSkygreen"}></div>
                <div style={{position: 'absolute', right: 200, bottom: 100, width: 80, height: 80, borderRadius:40}} className={"Glass GradientSkyredToSkyorange"}></div>
                <div className={"Text WithLogo"}>
                    <img style={{width: 247, height: 64}} src={"https://static.webtoon.today/ddah/logo-01.png"} alt={"logo"}/>
                    <span>{"이 당신을 기다립니다."}</span>
                </div>
            </>,
            <>
                <div className={"Background White"}></div>
                <img className={"BigLogo"} src={"https://static.webtoon.today/ddah/logo-02.png"} alt={"logo"}/>
                <div className={"Text"} style={{position: 'inherit', color: 'black', marginTop: 20, maxWidth: 600}}>
                    {"오른쪽 아래 메일쓰기 버튼("}<a href="mailto:partner@webtoon.today" onClick={fn.gotoByAnchor}>{"partner@webtoon.today"}</a>{")을 이용해 협력 제안해주십시오. 영업일 기준 최장 2일 이내에 반드시 회신드리겠습니다."}
                </div>
            </>
        ],
        team: [
            <></>,
            <>
                <div className={"Background Red"} style={{marginTop: 'auto', marginBottom: 'auto', maxHeight: 100, top: 'inherit'}}></div>
                <div className={"Text Subheader"}>
                    {"오늘의웹툰은 "}<span style={{fontWeight:"bold", color: '#00B0F0', textShadow: '0 0 2px black'}}>{"데이터를 잘 아는"}</span>{" 팀입니다."}<br/>
                </div>
            </>,
            <>
                <div className={"ProfileContainer Text"} style={{marginTop: 'auto', marginBottom: 'auto', height: 'initial', top: 'inherit', color: 'black', textAlign: 'left'}}>
                    <img className={"Profile"} src={"https://static.webtoon.today/ddah/sugeul.png"} alt={"수글"}/><br/>
                    <div><span style={{fontWeight:"bold", color: '#00B0F0'}}>{"대표"}</span>{"진수글"}</div>
                    <ul>
                        <li><span style={{fontStyle: 'italic', fontSize: '0.8rem'}}>{"'19.07.~'20.07. "}</span><span>{"브이로거(Vlogr) CTO"}</span></li>
                        <li><span style={{fontStyle: 'italic', fontSize: '0.8rem'}}>{"'16.03.~'19.07. "}</span><span>{"네이버 검색센터 추천연구팀(AiRS) Modeler"}</span></li>
                        <li><span style={{fontStyle: 'italic', fontSize: '0.8rem'}}>{"'15.03.~'15.07. "}</span><span>{"Bain&Company Research Assistant"}</span></li>
                        <li><span style={{fontStyle: 'italic', fontSize: '0.8rem'}}>{"'08.02.~'13.02. "}</span><span>{"카이스트 전산학과 졸"}</span></li>
                    </ul>

                </div>
            </>,
            <>
                <div className={"Background Red"} style={{marginTop: 'auto', marginBottom: 'auto', maxHeight: 100, top: 'inherit'}}></div>
                <div className={"Text Subheader"}>
                    {"오늘의웹툰은 "}<span style={{fontWeight:"bold", color: '#00B0F0', textShadow: '0 0 2px black'}}>{"웹툰을 잘 아는"}</span>{" 팀입니다."}<br/>
                </div>
            </>,
            <>
                <div className={"ProfileContainer Text"} style={{marginTop: 'auto', marginBottom: 'auto', height: 'initial', top: 'inherit', color: 'black', textAlign: 'left'}}>
                    <img className={"Profile"} src={"https://static.webtoon.today/ddah/seojung.png"} alt={"서정"}/><br/>
                    <div><span style={{fontWeight:"bold", color: '#00B0F0'}}>{"디렉터"}</span>{"김서정"}</div>
                    <ul>
                        <li><span style={{fontStyle: 'italic', fontSize: '0.8rem'}}>{"'19.~ "}</span><span>{"유튜브 '두미두미의 웹툰강좌' 운영"}</span></li>
                        <li><span style={{fontStyle: 'italic', fontSize: '0.8rem'}}>{"'18.~ "}</span><span>{"피너툰 '낭만주의자와 쾌락주의자가 만났을 때'"}</span></li>
                        <li><span style={{fontStyle: 'italic', fontSize: '0.8rem'}}>{"'09.~'17. "}</span><span>{"윤지운 작가 어시스턴트"}</span></li>
                        <li><span style={{fontStyle: 'italic', fontSize: '0.8rem'}}>{"'99.~'01. "}</span><span>{"치요다 공과예술 전문학교 만화과 졸"}</span></li>
                    </ul>

                </div>
            </>,
            <>
                <div className={"ProfileContainer Text"} style={{marginTop: 'auto', marginBottom: 'auto', height: 'initial', top: 'inherit', color: 'black', textAlign: 'left'}}>
                    <img className={"Profile"} src={"https://static.webtoon.today/ddah/soonjae.png"} alt={"순제"}/><br/>
                    <div><span style={{fontWeight:"bold", color: '#00B0F0'}}>{"디렉터"}</span>{"권순제"}</div>
                    <ul>
                        <li><span style={{fontStyle: 'italic', fontSize: '0.8rem'}}>{"'19.~ "}</span><span>{"SBS아카데미 웹소설 및 시나리오 강의"}</span></li>
                        <li><span style={{fontStyle: 'italic', fontSize: '0.8rem'}}>{"'20.~ "}</span><span>{"네이버 시리즈 '위대한 폭군' 연재"}</span></li>
                        <li><span style={{fontStyle: 'italic', fontSize: '0.8rem'}}>{"'19.~ "}</span><span>{"넷마블 '제2의 나라'(모바일게임) 설정 및 시나리오 기획"}</span></li>
                        <li><span style={{fontStyle: 'italic', fontSize: '0.8rem'}}>{"'15.~ "}</span><span>{"텐센트(qq닷컴) - '유니콘'(웹툰) 스토리 작가"}<br/>{"그 외 다수 웹툰, 웹소설 연재"}</span></li>
                    </ul>

                </div>
            </>,
            <>
                <div className={"ProfileContainer Text"} style={{marginTop: 'auto', marginBottom: 'auto', height: 'initial', top: 'inherit', color: 'black', textAlign: 'left'}}>
                    <img className={"Profile"} src={"https://static.webtoon.today/ddah/yeongho.png"} alt={"순제"}/><br/>
                    <div><span style={{fontWeight:"bold", color: '#00B0F0'}}>{"디렉터"}</span>{"서영호"}</div>
                    <ul>
                        <li><span style={{fontStyle: 'italic', fontSize: '0.8rem'}}>{"'14.~ 20."}</span><span>{"카툰팝 만화학원 강의"}</span></li>
                        <li><span style={{fontStyle: 'italic', fontSize: '0.8rem'}}>{"'20.~ 21."}</span><span>{"제주 웹툰 캠퍼스 출강"}</span></li>
                        <li><span style={{fontStyle: 'italic', fontSize: '0.8rem'}}>{"'13.~ 21."}</span><span>{"TStore - 소금, 코미코 - 이매용주전 그외 다수 연재"}</span></li>
                        <li><span style={{fontStyle: 'italic', fontSize: '0.8rem'}}>{"'06.~ 12."}</span><span>{"공주대학교 만화 애니메이션과 졸"}</span></li>
                    </ul>

                </div>
            </>,
            <>
                <div className={"Background Red"} style={{marginTop: 'auto', marginBottom: 'auto', maxHeight: 100, top: 'inherit'}}></div>
                <div className={"Text Subheader"}>
                    {"오늘의웹툰은 "}<span style={{fontWeight:"bold", color: '#00B0F0', textShadow: '0 0 2px black'}}>{"빠르게 성장하는 스타트업"}</span>{"입니다."}<br/>
                </div>
            </>,
            <>
                <div className={"ProfileContainer Text"} style={{marginTop: 'auto', marginBottom: 'auto', height: 'initial', top: 'inherit', color: 'black', textAlign: 'left'}}>
                    <div>
                        <span style={{fontWeight:"bold", color: '#00B0F0'}}>{"투자 "}</span>
                        
                    </div>
                    <ul>
                        <li>
                            <div style={{fontStyle: 'italic', fontSize: '0.8rem', width: 100, display: 'inline-block'}}>{"'21.01. (Pre-Seed)"}</div>
                            <span style={{lineHeight: '2.5rem', verticalAlign: 'middle'}}>
                                <img style={{height: '1.5rem', paddingTop: '0.5rem', paddingBottom: '0.5rem', verticalAlign: 'bottom', marginRight: 10}} src={"https://static.webtoon.today/ddah/fastventures.png"} alt={"fastventures"}/>
                            </span>
                        </li>
                        <li>
                            <div style={{fontStyle: 'italic', fontSize: '0.8rem', width: 100, display: 'inline-block'}}>{"'21.04. (Seed)"}</div>
                            <span style={{lineHeight: '2.5rem', verticalAlign: 'middle', fontSize: '0.8rem'}}>
                                <img style={{height: '1.5rem', paddingTop: '0.5rem', paddingBottom: '0.5rem', verticalAlign: 'bottom',}} src={"https://static.webtoon.today/ddah/fastventures.png"} alt={"fastventures"}/>
                                <span style={{fontStyle: 'italic', marginRight: 10}}>{' (후속)'}</span>
                                <img style={{height: '2.5rem', verticalAlign: 'bottom', marginRight: 10}} src={"https://static.webtoon.today/ddah/bass_investment.png"} alt={"bass investment"}/>
                            </span>
                        </li>
                    </ul>

                </div>
            </>,
            <>
                <div className={"Background White"}></div>
                <div className={"TextAndSlideShow"} style={{flexDirection: 'column', width: '80%', height: '80%'}}>
                    <div className={"Text"} style={{width:'100%', textAlign: 'left', color: 'black', fontSize: '2rem', maxWidth: 'initial'}}>{"찾아오시는길"}</div>
                    <div className={"Text"} style={{width:'100%', textAlign: 'left', color: 'black', fontSize: '1rem', maxWidth: 'initial'}}>{"서울특별시 강남구 테헤란로 2길, 27 (역삼동 패스트파이브 빌딩)"}</div>
                    <iframe
                        title={"오늘의웹툰 지도"}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.4853946793037!2d127.02749981608738!3d37.49646840768254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd8b7c75c90855a48!2z7Yyo7Iqk7Yq47YyM7J2067iMIOqwleuCqDXtmLjsoJA!5e0!3m2!1sko!2skr!4v1629369996941!5m2!1sko!2skr"
                        width="100%"
                        height="80%"
                        frameBorder="0"
                        style={{border:0, zIndex:2}} allowFullScreen={""} tabIndex={"0"}
                    ></iframe>
                </div>
            </>,
            <>
                <div className={"Background White"}></div>
                <img className={"BigLogo"} src={"https://static.webtoon.today/ddah/logo-02.png"} alt={"logo"}/>
            </>
        ]
    }),[]);

    React.useEffect(()=>{
        const resizeListener = (event) => {
            setScreenHeight(window.innerHeight)
        };

        const scrollListener = (event) => {
            if (Math.abs(event.deltaY) < 50){
                return;
            }
            if (Math.abs(event.deltaX) > 0.5 && Math.abs(event.deltaX) * 2 > Math.abs(event.deltaY)){
                return;
            }

            eventQueue.push({deltaX: event.deltaX, deltaY: event.deltaY});
        }

        const watchman = () => {
            if (eventQueue.length === 0){
                return;
            }
            let event = eventQueue[0];
            eventQueue = [];
            if (event.deltaY>0){
                document.getElementById(uniqueId).scrollTo({top: Math.min(contents[mode].length-1+1,currentPage+1)*screenHeight, behavior: 'smooth'});
                setCurrentPage(Math.min(contents[mode].length-1+1,currentPage+1));
            }else{
                document.getElementById(uniqueId).scrollTo({top: Math.max(0,currentPage-1)*screenHeight, behavior: 'smooth'});
                setCurrentPage(Math.max(0,currentPage-1));
            }
        }

        const rubbingListener = (event) => {
            if (event.type === 'touchstart'){
                lastKnownTouch = { screenX: event.touches[0].screenX, screenY: event.touches[0].screenY };
                window.addEventListener('touchmove', rubbingListener);
            }else if (event.type === 'touchmove'){
                window.removeEventListener('touchmove', rubbingListener);
                let currentTouch = { screenX: event.touches[0].screenX, screenY: event.touches[0].screenY };
                eventQueue.push({
                    deltaX: lastKnownTouch.screenX - currentTouch.screenX,
                    deltaY: lastKnownTouch.screenY - currentTouch.screenY,
                })
            }
        }

        const watchmanInterval = setInterval(watchman, 200);
        window.addEventListener('resize', resizeListener);
        window.addEventListener('mousewheel', scrollListener);
        window.addEventListener('touchstart', rubbingListener);
        
        return ()=>{
            clearInterval(watchmanInterval);
            window.removeEventListener('resize', resizeListener);
            window.removeEventListener('mousewheel', scrollListener);
            window.removeEventListener('touchstart', rubbingListener);
            window.removeEventListener('touchmove', rubbingListener);
        }
    },[contents, currentPage, mode, screenHeight, uniqueId]);


    return (<div className={`Home At_${currentPage} ${currentPage === contents[mode].length -1+1?'At_Last':''}`} style={{height: screenHeight}} id={uniqueId}>
        <div className={"BackgroundVid"}>
            <video autoPlay loop muted width={"100%"} height={"100%"}>
                <source src={"https://static.webtoon.today/ddah/bgvid.mp4"} type={"video/mp4"}/>
            </video>
        </div>
        <div className={"GridBG"}></div>
        <div key={`Cut_${0}`} className={`Container Cut_0 ${currentPage === 0?'TextVisible':''} ${0 === contents[mode].length -1+1?'Cut_Last':''}`}>
                <div className={"Background White"}></div>
                <div className={"Text WithLogo"}>
                    <img style={{width: 247}} src={"https://static.webtoon.today/ddah/logo-02.png"} alt={"logo"}/>
                </div>
                <div className={"BottomButtons"}>
                    <Button className={"FlatButton"} variant={"outlined"} onClick={()=>{
                        logBehavior('readCompanyInfo', {type: 'artist'});
                        setMode('artist');
                        document.getElementById(uniqueId).scrollTo({top: 2*screenHeight, behavior: 'smooth'});
                        setCurrentPage(2);
                    }}>{"작가지원"}</Button>
                    <Button className={"FlatButton"} variant={"outlined"} onClick={()=>{
                        logBehavior('readCompanyInfo', {type: 'partner'});
                        setMode('partner');
                        document.getElementById(uniqueId).scrollTo({top: 2*screenHeight, behavior: 'smooth'});
                        setCurrentPage(2);
                    }}>{"협력제안"}</Button>
                    <Button className={"FlatButton"} variant={"outlined"} onClick={()=>{
                        logBehavior('readCompanyInfo', {type: 'team'});
                        setMode('team');
                        document.getElementById(uniqueId).scrollTo({top: 2*screenHeight, behavior: 'smooth'});
                        setCurrentPage(2);
                    }}>{"팀 소개"}</Button>
                </div>
        </div>
        {contents[mode].map((content,idx) => 
        <div key={`Cut_${idx+1}`} className={`Container Cut_${idx+1} ${currentPage === idx+1?'TextVisible':''} ${idx+1 === contents[mode].length -1?'Cut_Last':''}`}>
            {content}
        </div>)}
        <div className={"Bottom"}>
            <div className={"CompanyInfo"}>
                {"주식회사 오늘의웹툰"}<br/>
                {"765-81-02007 | 대표자 진수글 | "}<a href={"/privacypolicy/kr"} onClick={fn.gotoByAnchor}>{"개인정보관리지침"}</a><br/>
                {"서울시 강남구 강남대로 78길 33-10, 3층"}<br/>
            </div>
            <div className={"Contact"}>
                <IconButton href={
                      mode === 'artist'   ?'mailto:artist@webtoon.today'
                    /*mode === 'partner'*/:'mailto:partner@webtoon.today'
                } onClick={fn.gotoByAnchor}><MailOutlineOutlined style={{color:"white"}}/></IconButton>
            </div>
        </div>
        <div className={"TopMenu"}>
            <Button className={"Button"} href={"mailto:help@webtoon.today"} onClick={fn.gotoByAnchor}>{"문의하기"}</Button>
        </div>
        <div 
            className={"Controller"}
            style={currentPage===0?{display:'none'}:{}}
        >
            <IconButton disabled={currentPage===0} className={"Button"} onClick={()=>{
                document.getElementById(uniqueId).scrollTo({top: 0, behavior: 'smooth'});
                setCurrentPage(Math.max(0,0));
            }}><FastForwardOutlined style={{transform: 'rotate(-90deg)'}}/></IconButton>
            <IconButton disabled={currentPage===0} className={"Button"} onClick={()=>{
                document.getElementById(uniqueId).scrollTo({top: Math.max(0,currentPage-1)*screenHeight, behavior: 'smooth'});
                setCurrentPage(Math.max(0,currentPage-1));
            }}><ArrowUpward/></IconButton>
            <IconButton disabled={currentPage===contents[mode].length-1+1} className={"Button"} onClick={()=>{
                document.getElementById(uniqueId).scrollTo({top: Math.min(contents[mode].length-1+1,currentPage+1)*screenHeight, behavior: 'smooth'});
                setCurrentPage(Math.min(contents[mode].length-1+1,currentPage+1));
            }}><ArrowDownward/></IconButton>
        </div>
        
    </div>);
}

export default Home;
