import { Divider, IconButton, Paper, Switch } from '@material-ui/core';
import { CheckBox, CloudDownload, FilterList, KeyboardArrowDown, KeyboardArrowUp, SwapVert } from '@material-ui/icons';
import React from 'react';
import { utils, writeFile } from 'xlsx';
import EditableField from './EditableField';
import EditableRow from './EditableRow';

import './EditableTable.scss';

const MAX_NUMBER_RANGE = 999999999;

/**
 * 
 * @param {React.ReactElement} jsx 
 * @returns 
 */
const jsxToString = (jsx) => {
    if (jsx.props && jsx.props.children){
        if (jsx.props.children instanceof Array){
            return jsx.props.children.map(child => jsxToString(child)).join("/")
        }
        return jsxToString(jsx.props.children)
    }
    return String(jsx)
}

const extractValue = (columnData) => {
    if (columnData.type === 'select') {
        return columnData.options.filter(option => option.value === columnData.defaultValue).concat([{key: 0}])[0].key;
    }
    if (columnData.defaultValue && columnData.defaultValue['$$typeof']){
        return jsxToString(columnData.defaultValue);
    }
    return columnData.defaultValue || ''
}

/**
 * 
 * @param {{
 *  headerButtonHeaders: string[],
 *  columnHeaders: string[],
 *  defaultSortCol: [number, 'asc'|'desc'],
 *  headerButtonsFunction: (row: {}, index: number) => [],
 *  columnsFunction: (row: {}, index: number) => [],
 *  updateGenerator: (row: {}, index: number) => async () => void,
 *  data: any[],
 *  name: string,
 *  partial: 'all'|'head'|'body'
 * }} param0 
 */
const EditableTable = ({
    headerButtonHeaders, columnHeaders, headerButtonsFunction, columnsFunction, updateGenerator, data, name="noname", defaultSortCol, style = {}, isDownloadable=false,
    partial="all"
}) => {
    
    const [renderableData, setRenderableData] = React.useState(data);

    /**
     * @type {[((row:{})=>boolean)[], React.Dispatch<React.SetStateAction<((row:{})=>boolean)[]>>]}
     */
    const [ownFilter, setOwnFilter] = React.useState({});
    /**
     * @type {[[number, 'asc'|'desc'] | null, React.Dispatch<React.SetStateAction<[string, 'asc'|'desc'] | null>>]}
     */
    const [ownSortCol, setOwnSortCol] = React.useState(defaultSortCol || [0, 'asc']);
    
    const [filterShow, setFilterShow] = React.useState(false);

    const [filterNumRangeBottom, setFilterNumRangeBottom] = React.useState(0);
    const [filterNumRangeTop   , setFilterNumRangeTop   ] = React.useState(MAX_NUMBER_RANGE);
    const [filterTextPart      , setFilterTextPart      ] = React.useState('');
    const [filterTextNoPart    , setFilterTextNoPart    ] = React.useState('');
    const [filterExcludeEmpty  , setFilterExcludeEmpty  ] = React.useState(false);

    const [contextChanging, setContextChanging] = React.useState(false);

    React.useEffect(()=>{
        if (ownFilter.hasOwnProperty(ownSortCol[0])){
            setFilterNumRangeTop(Number(ownFilter[ownSortCol[0]].filterNumRangeTop));
            setFilterNumRangeBottom(Number(ownFilter[ownSortCol[0]].filterNumRangeBottom));
            setFilterTextPart(ownFilter[ownSortCol[0]].filterTextPart);
            setFilterTextNoPart(ownFilter[ownSortCol[0]].filterTextNoPart);
            setFilterExcludeEmpty(ownFilter[ownSortCol[0]].filterExcludeEmpty);
        }else{
            setFilterNumRangeTop(MAX_NUMBER_RANGE);
            setFilterNumRangeBottom(0);
            setFilterTextPart('');
            setFilterTextNoPart('');
            setFilterExcludeEmpty(false);
        }
        setContextChanging(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ownSortCol]);

    React.useEffect(()=>{
        if (contextChanging){
            return;
        }
        let newFilter = {}
        newFilter[ownSortCol[0]] = {
            filterNumRangeBottom,
            filterNumRangeTop,
            filterTextPart,
            filterTextNoPart,
            filterExcludeEmpty,
        }
        setOwnFilter(Object.assign(ownFilter, newFilter));
    },[filterNumRangeBottom, filterNumRangeTop, filterTextPart, filterTextNoPart, filterExcludeEmpty, columnsFunction, ownSortCol, ownFilter, contextChanging]);

    const makeFilter = (colId) => ({filterNumRangeBottom=0, filterNumRangeTop=999999, filterTextPart='', filterTextNoPart='', filterExcludeEmpty=false})=> {
        return [()=> true].concat(
            filterNumRangeBottom>0              ? [(row) => extractValue(columnsFunction(row,0)[colId]) >= filterNumRangeBottom]:[],
            filterNumRangeTop<MAX_NUMBER_RANGE  ? [(row) => extractValue(columnsFunction(row,0)[colId]) <= filterNumRangeTop]:[],
            filterTextPart.length>0             ? [(row) =>(extractValue(columnsFunction(row,0)[colId])+'').indexOf(filterTextPart) >= 0]:[],
            filterTextNoPart.length>0           ? [(row) =>(extractValue(columnsFunction(row,0)[colId])+'').indexOf(filterTextNoPart) < 0]:[],
            filterExcludeEmpty                  ? [(row) =>(extractValue(columnsFunction(row,0)[colId])+'').length > 0]:[],
        );
    }

    React.useEffect(()=>{
        setRenderableData(data);
        setOwnFilter({});
        setContextChanging(true);
        setOwnSortCol(defaultSortCol || [0, 'asc']);
        setFilterShow(false);
    },[data, defaultSortCol]);

    const HEAD = (
        <thead >
            <tr style={{backgroundColor: 'white', whiteSpace: 'nowrap', zIndex: 1, ...(partial==='all'?{}:{position:'sticky', top: 48})}}>
                { headerButtonHeaders.map((headerButtonHeader) => <th key={headerButtonHeader}>{headerButtonHeader}</th>) }
                { columnHeaders.map((columnHeader, index) => 
                    <th key={columnHeader}>
                        <div className={"InnerContainer"}>
                            {columnHeader}
                            <IconButton
                                className={"IconBtn"}
                                variant={"contained"}
                                onClick={()=> {
                                    setContextChanging(true)
                                    ownSortCol[0] === index
                                        ?setOwnSortCol([index, ownSortCol[1] === 'asc'?'desc':'asc'])
                                        :setOwnSortCol([index, 'asc'])
                                }}
                            >
                                {ownSortCol[0] !== index ? 
                                    <SwapVert style={{fontSize:'0.9rem'}}/> 
                                    : ownSortCol[1] === 'asc'
                                        ?<KeyboardArrowUp style={{fontSize:'0.9rem', color: 'white'}}/>
                                        :<KeyboardArrowDown style={{fontSize:'0.9rem', color: 'white'}}/>
                                }
                            </IconButton>
                            {(ownSortCol[0] === index || ownFilter.hasOwnProperty(index)) &&
                                <>
                                    <IconButton
                                        className={"IconBtn"}
                                        onClick={()=>{
                                            setContextChanging(true)
                                            setOwnSortCol([index, 'asc'])
                                            setFilterShow(!filterShow)
                                        }}
                                    >
                                        <FilterList style={{fontSize:'0.9rem'}}/>
                                    </IconButton>
                                    <div style={{position:'fixed', zIndex:1, top:0, left:0, width: '100%', height:'100%', justifyContent:'center', alignItems:'center', display: filterShow?'flex':'none'}}>
                                        <div style={{position:'fixed', zIndex:1, top:0, left:0, width: '100%', height:'100%', display: filterShow?'block':'none'}} onClick={()=>setFilterShow(false)}/>
                                        <Paper hidden={!filterShow} style={{zIndex:2}}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            필터 해제
                                                        </td>
                                                        <td>
                                                            <IconButton
                                                                disabled={filterNumRangeTop === MAX_NUMBER_RANGE && filterNumRangeBottom === 0 && filterTextPart.length === 0 && filterTextNoPart.length === 0 && !filterExcludeEmpty}
                                                                color={"secondary"}
                                                                onClick={()=>{
                                                                    setFilterNumRangeTop(MAX_NUMBER_RANGE);
                                                                    setFilterNumRangeBottom(0);
                                                                    setFilterTextPart('');
                                                                    setFilterTextNoPart('');
                                                                    setFilterExcludeEmpty(false);
                                                                }}
                                                            >
                                                                <CheckBox/>
                                                            </IconButton>
                                                        </td>
                                                    </tr>
                                                    <tr><td><Divider/></td></tr>
                                                    <tr>
                                                        <td style={{fontWeight:'bold'}}>
                                                            숫자 데이터
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <EditableField
                                                                isEditing={true}
                                                                defaultValue={filterNumRangeBottom}
                                                                field={'data'}
                                                                update={newValue => setFilterNumRangeBottom(Number(newValue.data))}
                                                            />
                                                        </td>
                                                        <td>
                                                            이상
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <EditableField
                                                                isEditing={true}
                                                                defaultValue={filterNumRangeTop}
                                                                field={'data'}
                                                                update={newValue => setFilterNumRangeTop(Number(newValue.data))}
                                                            />
                                                        </td>
                                                        <td>
                                                            이하
                                                        </td>
                                                    </tr>
                                                    <tr><td><Divider/></td></tr>
                                                    <tr>
                                                        <td style={{fontWeight:'bold'}}>
                                                            문자열 데이터
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <EditableField
                                                                isEditing={true}
                                                                defaultValue={filterTextPart}
                                                                field={'data'}
                                                                update={newValue => setFilterTextPart(newValue.data)}
                                                            />
                                                        </td>
                                                        <td>
                                                            포함
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <EditableField
                                                                isEditing={true}
                                                                defaultValue={filterTextNoPart}
                                                                field={'data'}
                                                                update={newValue => setFilterTextNoPart(newValue.data)}
                                                            />
                                                        </td>
                                                        <td>
                                                            제외
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            공백 제외
                                                        </td>
                                                        <td>
                                                            <Switch
                                                                checked={filterExcludeEmpty}
                                                                onClick={event => setFilterExcludeEmpty(event.target.checked)}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <Divider/>
                                        </Paper>
                                    </div>
                                </>
                                }
                        </div>
                    </th>)
                }
            </tr>
        </thead>

    );

    const BODY = (
        <tbody>
            {renderableData
            .filter( row => Object.keys(ownFilter).map(key => makeFilter(key)(ownFilter[key])).flat().map(fltr => fltr(row)).reduce( (a,b) => a && b, true ) )
            .map( (a,i) => [a,i] )
            .sort( ([a,ai], [b,bi]) =>
                !ownSortCol
                    ?(ai - bi)
                    :ownSortCol[1] === 'desc'
                        ? extractValue(columnsFunction(a,0)[ownSortCol[0]]) < extractValue(columnsFunction(b,0)[ownSortCol[0]]) ? 1:-1
                        : extractValue(columnsFunction(a,0)[ownSortCol[0]]) > extractValue(columnsFunction(b,0)[ownSortCol[0]]) ? 1:-1 )
            .map( ([a,i]) => a )
            .map( (row,index) => 
                <EditableRow
                    key={index}
                    style={{backgroundColor:index%2===0?"#EEEEEE":"#FFFFFF"}}
                    headerButtons={headerButtonsFunction(row, index)}
                    fields={columnsFunction(row, index)}
                    update={updateGenerator(row, index)}
                />
            )}
        </tbody>
    );

    if (partial === 'head') {
        return HEAD;
    }

    if (partial === 'body') {
        return BODY;
    }

    return (
        <div className={'EditableTableRoot'} style={style}>
            <table>
                {HEAD}
                {BODY}
            </table>
            {isDownloadable && 
            <>
                <table style={{display:"none"}} className={"downloadableTable"}>
                    <tbody>
                        <tr style={{color: "#FFFFFF", backgroundColor: '#333333'}}>
                            {
                                columnHeaders
                                .map((columnHeader,index) => 
                                    <td key={index}>
                                        {columnHeader}
                                    </td>)
                            }
                        </tr>
                        {renderableData
                        .filter( row => Object.keys(ownFilter).map(key => makeFilter(key)(ownFilter[key])).flat().map(fltr => fltr(row)).reduce( (a,b) => a && b, true ) )
                        .map( (a,i) => [a,i] )
                        .sort( ([a,ai], [b,bi]) =>
                            !ownSortCol
                                ?(ai - bi)
                                :ownSortCol[1] === 'desc'
                                    ? extractValue(columnsFunction(a,0)[ownSortCol[0]]) < extractValue(columnsFunction(b,0)[ownSortCol[0]]) ? 1:-1
                                    : extractValue(columnsFunction(a,0)[ownSortCol[0]]) > extractValue(columnsFunction(b,0)[ownSortCol[0]]) ? 1:-1 )
                        .map( ([a,i]) => a )
                        .map( (row,index) => 
                            <EditableRow
                                key={index}
                                style={{backgroundColor:index%2===0?"#EEEEEE":"#FFFFFF"}}
                                headerButtons={[]}
                                fields={columnsFunction(row, index).map(row => Object.assign(row, {isEditing: false}))}
                                update={updateGenerator(row, index)}
                            />
                        )}
                    </tbody>
                </table>
                <IconButton
                    variant={"contained"}
                    style={{
                        position: 'absolute',
                        top: 40, right: 5,
                        display:renderableData.length===0?'none':'block',
                        backgroundColor:'rgba(155,155,155,0.2)',
                        padding: 5,
                    }}
                    onClick={async (event)=>{
                        try{
                            /* convert table 'table1' to worksheet named "Sheet1" */
                            const ws = utils.table_to_sheet(
                                event.target.parentElement.querySelector('.downloadableTable')
                                || event.target.parentElement.parentElement.querySelector('.downloadableTable')
                                || event.target.parentElement.parentElement.parentElement.querySelector('.downloadableTable')
                                || event.target.parentElement.parentElement.parentElement.parentElement.querySelector('.downloadableTable')
                            );

                            /* create new workbook */
                            const workbook = utils.book_new();
                            
                            console.log(workbook)
                            for (const col of 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.substring(0,columnHeaders.length)){
                                ws[col+1].s = {
                                    fill: {
                                        patternType: 'none',
                                        fgColor: { rgb :"FFFFFFFF"},
                                        bgColor: { rgb :"FF000000"},
                                    }
                                }
                            }
                            
                            utils.book_append_sheet(workbook, ws, name.substring(0,31));
                            
                            writeFile(workbook, `${name}-${new Date().toISOString()}.xlsx`)
                        }catch(e){
                            console.error(e)
                        }
                    }}
                    >
                    <CloudDownload/>
                </IconButton>
            </>}
        </div>
    );
}

export default EditableTable;