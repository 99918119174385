import { getMemberList, getMemberInfo } from "../../Data/Member";
import { useState } from "react";
import './Member.scss';

import { Search } from '@material-ui/icons';
import { TextField, Button, Dialog, DialogContent, FormControl, Select, MenuItem } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';

import LoadingCircle from '../../Component/LoadingCircle';

const headerCollection = {
    payment: ['orderId', 'requestAmount', 'finalAmount', 'createdAt', 'updatedAt', 'status'],
    invoice: ['requestedPaymentCount', 'teamName', 'totalAmount'],
    deposit: ['status', 'detail', 'amount', 'finalAmount', 'createdAt'],
    membership: ['userid', 'teamid', 'authority', 'grantedAt']
};
const classification = ['organization', 'team', 'title'];

const memberListHeaders = [
    'image', 'userid', 'addressid', 'name', 'memberState', 'email', 'authority', 
    'birthday', 'gender', 'additionalInfo', 
    'cardType', 'ownerType', 'cardNumber', 'cardCreatedAt'
];

const memberInfoHeaders = {
    organization: [
        'organizationid', 'ownerUid', 'settlementUid',
        'organizationName', 'createdAt',
        'planName', 'planAmount',
        'lastPaymentDate', 'nextPaymentDate',
        'planIsActive',
        'invoiceList'
    ],
    team: [
        'image', 'teamid', 'settlementUid',
        'name', 'email', 'createdAt',
        'depositList',
        'membershipList'
    ],
    title: [
        'thumbnail', 'serviceId', 'titleId', 'contentsNo',
        'title', 'author', 'authority'
    ]
};

const Member = () => {
    const [member, setMember] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className={'PageContainer'}>
            <div className={`MemberList ${member ? 'Detail' : ''}`}>
                <div className={'MemberPage'}> {/* MemberList 조회 페이지 */}
                    <MemeberList setMember={setMember} setIsLoading={setIsLoading}/>
                </div>
                <div className={'MemberPage'}> {/* Member 상세 정보 페이지 */}
                    { member &&
                        <MemberInfo member={member} setMember={setMember}/>
                    }
                </div>
            </div>
            <LoadingCircle show={isLoading}/>
        </div>
    )
}

// MemberList 조회 페이지
const MemeberList = ({setMember, setIsLoading}) => {
    const [editingKeyword, setEditingKeyword] = useState("");
    const [searchType, setSearchType] = useState('name');

    const [memberList, setMemberList] = useState(null);

    const searchMemberList = async (keyword) => {
        if (keyword) {
            setIsLoading(true);
            const searchParams = searchType === 'name' ? {name: keyword} : {email: keyword};
            const response = await getMemberList(searchParams) || [];
            
            setMemberList(response);
            setIsLoading(false);
        }
    }

    const selectMember = async (userid) => {
        setIsLoading(true);
        const response = await getMemberInfo(userid) || [];
        
        setMember(response);
        setIsLoading(false);
    }

    return(<>
        <div className={"SearchArea"}>
            <div className={"SearchBar"}>
                <IconButton className={'SearchIconButton'} onClick={() => searchMemberList(editingKeyword)}>
                    <Search/>
                </IconButton>
                <TextField className={"SearchField"} placeholder={searchType} value={editingKeyword}
                    onKeyUp={(event) => event.key === 'Enter' ? searchMemberList(editingKeyword) : null }
                    onChange={(event) => setEditingKeyword(event.target.value)}
                />
            </div>
            <div className={"SelectBox"}>
                <FormControl>
                    <Select 
                        value={searchType} 
                        onChange={(event) => setSearchType(event.target.value)}
                    >
                        <MenuItem value="name">{"이름"}</MenuItem>
                        <MenuItem value="email">{"이메일"}</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </div>
        { memberList &&
            <div className={"MemberListContainer"}>
                <table>
                    <thead>
                        <tr>
                            <th colSpan={memberListHeaders.length}>{'Member'}</th>
                        </tr>
                        <tr>
                            { memberListHeaders.map((header) => {
                                return <th key={header}>{header}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                    { memberList.map(({ userid, image, addressid, name, memberState, email, authority, birthday, gender, additionalInfo, 
                        cardType, ownerType, cardNumber, cardCreatedAt }
                    ) => (
                        <tr key={userid} onClick={() => selectMember(userid)}>
                            <td>
                                <div className={'profileImage'}>
                                    <img src={image || "https://static.webtoon.today/noprofile.jpg"} alt={'profile'} />
                                </div>
                            </td>
                            <td>{userid}</td>
                            <td>{addressid}</td>
                            <td>{name}</td>
                            <td>{memberState}</td>
                            <td>{email}</td>
                            <td>{authority}</td>
                            <td>{birthday && new Date(birthday * 1000).toLocaleDateString('ko-KR')}</td>
                            <td>{gender && `${gender === 'M' ? '남성' : '여성'}`}</td>
                            <td>{additionalInfo}</td>
                            <td>{cardType}</td>
                            <td>{ownerType}</td>
                            <td>{cardNumber}</td>
                            <td>{cardCreatedAt && new Date(cardCreatedAt * 1000).toLocaleDateString('ko-KR')}</td>
                        </tr>
                    ))}

                    </tbody>
                </table>
            </div>
        }
    </>)
}

// Member 상세 정보 페이지
const MemberInfo = ({member, setMember}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [listData, setListData] = useState([]);

    const handleDialogOpen = (title, data) => {
        setDialogTitle(title);
        setListData(data);
        setIsDialogOpen(true);
    };
    
    return (
        <div className={'MemberInfoContainer'}>
            <Button className={'BackButton'} onClick={() => setMember(null)}>
                <ArrowBackIcon/>
            </Button>
            <span>{member.profile?.name}</span>
            <div className={'MemberProfile'}>
                <div className={'ProfileImage'}>
                    <img src={member.image || "https://static.webtoon.today/noprofile.jpg"} alt={'profile'}/>
                </div>
                <table>
                    <caption>{'Member'}</caption>
                    <tbody>
                        { memberListHeaders.slice(1, 7).map((header) => // userid ~ authority
                            <tr key={header}>
                                <th>{header}</th>
                                <td>{member.profile?.[header]}</td>
                            </tr>
                        )}
                        <tr>
                            <th>{'birthday'}</th>
                            <td>{member.profile.birthday && new Date(member.profile.birthday * 1000).toLocaleDateString('ko-KR')}</td>
                        </tr>
                        <tr>
                            <th>{'gender'}</th>
                            <td>{member.profile.gender && `${member.profile.gender==='M'? '남성' : ' 여성'}`}</td>
                        </tr>
                        <tr>
                            <th>{'additionalInfo'}</th>
                            <td>{member.profile.additionalInfo}</td>
                        </tr>
                        <tr>
                            <th>{'paymentList'}</th>
                            <td>
                                <button onClick={()=>handleDialogOpen('payment', member.paymentList)}>
                                    {'paymentList'}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <caption>{'Card'}</caption>
                    <tbody>
                        { memberListHeaders.slice(10).map((header) => // cardType ~ cardCreatedAt
                            <tr key={header}>
                                <th>{header}</th>
                                <td>{member[header]}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className={'MemberInfoTable'}>
                <table>
                    <thead>
                        <tr>
                            {classification.map((group) => 
                                <th key={group} colSpan={memberInfoHeaders[group]?.length}>
                                    {group}
                                </th>
                            )}
                        </tr>
                        <tr>
                            { classification.map((group) => 
                                memberInfoHeaders[group].map((header) => <th key={header}>{header}</th>)
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        { member?.organizationList?.map((organization) => {
                            const organizationrowspan = organization.teamList?.reduce((acc, team) => acc + (team.titleList.length || 1), 0) || 1;
                            return (
                                organization.teamList?.length > 0 
                                ? organization.teamList.map((team, teamindex) => 
                                    team.titleList?.length > 0 
                                    ? team.titleList.map((title, titleindex) => 
                                        <tr key={`${team.teamid}${title.titleId}`}>
                                            { teamindex === 0 && titleindex === 0 && <>
                                                <td rowSpan={organizationrowspan}>{organization.organizationid}</td>
                                                <td rowSpan={organizationrowspan}>{organization.ownerUid}</td>
                                                <td rowSpan={organizationrowspan}>{organization.settlementUid}</td>
                                                <td rowSpan={organizationrowspan}>{organization.organizationName}</td>
                                                <td rowSpan={organizationrowspan}>{organization.createdAt && new Date(organization.createdAt * 1000).toLocaleDateString('ko-KR')}</td>
                                                <td rowSpan={organizationrowspan}>{organization.planName}</td>
                                                <td rowSpan={organizationrowspan}>{organization.planAmount?.toLocaleString()}</td>
                                                <td rowSpan={organizationrowspan}>{organization.lastPaymentDate && new Date(organization.lastPaymentDate * 1000).toLocaleDateString('ko-KR')}</td>
                                                <td rowSpan={organizationrowspan}>{organization.nextPaymentDate && new Date(organization.nextPaymentDate * 1000).toLocaleDateString('ko-KR')}</td>
                                                <td rowSpan={organizationrowspan}>{organization.planIsActive === 1 ? '활성' : '비활성'}</td>
                                                <td rowSpan={organizationrowspan}>
                                                    <button onClick={()=>handleDialogOpen('invoice', organization.invoice)}>
                                                        {'invoiceList'}
                                                    </button>
                                                </td>
                                            </>}
                                            { titleindex === 0 && <>
                                                <td rowSpan={team.titleList.length}><div className={'profileImage'}><img src={team.image || "https://static.webtoon.today/noprofile.jpg"} alt={'profile'}/></div></td>
                                                <td rowSpan={team.titleList.length}>{team.teamid}</td>
                                                <td rowSpan={team.titleList.length}>{team.settlementUid}</td>
                                                <td rowSpan={team.titleList.length}>{team.name}</td>
                                                <td rowSpan={team.titleList.length}>{team.email}</td>
                                                <td rowSpan={team.titleList.length}>{team.createdAt && new Date(team.createdAt * 1000).toLocaleDateString('ko-KR')}</td>
                                                <td rowSpan={team.titleList.length}>
                                                    <button onClick={()=>handleDialogOpen('deposit', team.depositList)}>{'depositList'}</button>
                                                </td>
                                                <td rowSpan={team.titleList.length}>
                                                    <button onClick={()=>handleDialogOpen('membership', team.membershipList)}>
                                                        {'membershipList'}
                                                    </button>
                                                </td>
                                            </>}
                                            <td><div className={'profileImage'}><img src={title.thumbnail || "https://static.webtoon.today/noprofile.jpg"} alt={'profile'}/></div></td>
                                            { memberInfoHeaders.title.slice(1).map((header) =>
                                                <td key={header}>{title[header]}</td>                                                
                                            )}
                                        </tr>
                                    ) : <tr key={team.id}>
                                        { teamindex === 0 && <>
                                            <td rowSpan={organization.teamList.length}>{organization.organizationid}</td>
                                            <td rowSpan={organization.teamList.length}>{organization.ownerUid}</td>
                                            <td rowSpan={organization.teamList.length}>{organization.settlementUid}</td>
                                            <td rowSpan={organization.teamList.length}>{organization.organizationName}</td>
                                            <td rowSpan={organization.teamList.length}>{organization.createdAt && new Date(organization.createdAt * 1000).toLocaleDateString('ko-KR')}</td>
                                            <td rowSpan={organization.teamList.length}>{organization.planName}</td>
                                            <td rowSpan={organization.teamList.length}>{organization.planAmount?.toLocaleString()}</td>
                                            <td rowSpan={organization.teamList.length}>{organization.lastPaymentDate && new Date(organization.lastPaymentDate * 1000).toLocaleDateString('ko-KR')}</td>
                                            <td rowSpan={organization.teamList.length}>{organization.nextPaymentDate && new Date(organization.nextPaymentDate * 1000).toLocaleDateString('ko-KR')}</td>
                                            <td rowSpan={organization.teamList.length}>{organization.planIsActive === 1 ? '활성' : '비활성'}</td>
                                            <td rowSpan={organization.teamList.length}>
                                                <button onClick={()=>handleDialogOpen('invoice', organization.invoice)}>
                                                    {'invoiceList'}
                                                </button>
                                            </td>
                                        </>}
                                        <td><div className={'profileImage'}><img src={team.image || "https://static.webtoon.today/noprofile.jpg"} alt={'profile'}/></div></td>
                                        <td>{team.teamid}</td>
                                        <td>{team.settlementUid}</td>
                                        <td>{team.name}</td>
                                        <td>{team.email}</td>
                                        <td>{team.createdAt && new Date(team.createdAt * 1000).toLocaleDateString('ko-KR')}</td>
                                        <td><button onClick={()=>handleDialogOpen('deposit', team.depositList)}>{'depositList'}</button></td>
                                        <td><button onClick={()=>handleDialogOpen('membership', team.membershipList)}>{'membershipList'}</button></td>
                                        {Array(memberInfoHeaders.title.length).fill(<td></td>)}
                                    </tr>
                                )
                                : <tr key={organization.id}>
                                    <td>{organization.organizationid}</td>
                                    <td>{organization.ownerUid}</td>
                                    <td>{organization.settlementUid}</td>
                                    <td>{organization.organizationName}</td>
                                    <td>{organization.createdAt && new Date(organization.createdAt * 1000).toLocaleDateString('ko-KR')}</td>
                                    <td>{organization.planName}</td>
                                    <td>{organization.planAmount?.toLocaleString()}</td>
                                    <td>{organization.lastPaymentDate && new Date(organization.lastPaymentDate * 1000).toLocaleDateString('ko-KR')}</td>
                                    <td>{organization.nextPaymentDate && new Date(organization.nextPaymentDate * 1000).toLocaleDateString('ko-KR')}</td>
                                    <td>{organization.planIsActive === 1 ? '활성' : '비활성'}</td>
                                    <td>
                                        <button onClick={()=>handleDialogOpen('invoice', organization.invoice)}>
                                            {'invoiceList'}
                                        </button>
                                    </td>
                                    {Array(memberInfoHeaders.team.length + memberInfoHeaders.title.length).fill(<td></td>)}
                                </tr>
                            )}
                        )}
                    </tbody>
                </table>
            </div>
            { isDialogOpen && 
                <DialogInMember
                    DialogTitle={dialogTitle}
                    HeaderList={headerCollection[dialogTitle] || []}
                    data={listData || []}
                    onClose={()=> setIsDialogOpen(false)}
                />
            }
        </div>
    )
}

const DialogInMember = ({DialogTitle, HeaderList, data, onClose}) => 
    <Dialog 
        className={'ListInfoDialog'} 
        open={true}
        PaperProps = {{
            className: 'ListInfoDialogPaper'
        }}
    >
        <Button className={'CloseButton'} onClick={onClose}>
            <img src={'https://static.webtoon.today/ddah/icon/x_sm.svg'} alt={'close'}/>
        </Button>
        <DialogTitle>{DialogTitle}</DialogTitle>
        <DialogContent className={'ListInfo'}>
            <table>
                <thead>
                    <tr>
                        {HeaderList.map((header) => <th key={header}>{header}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => 
                        <tr>
                            {HeaderList.map((header) => <td key={`${DialogTitle}${header}`}>{item[header]}</td>)}
                        </tr>
                    )}
                </tbody>
            </table>
        </DialogContent>
    </Dialog>
  

export default Member;