import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React from 'react';

import { readSurvey } from '../Data/Survey';

import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import './SurveyView.scss';
import LoadingCircle from '../Component/LoadingCircle';
import moment from 'moment-timezone';

const SurveyView = ( props ) => {
    
    let surveyId = props.surveyId
    
    const [disabled, setDisabled] = React.useState(false);
    const [survey, setSurvey] = React.useState([]);

    React.useEffect(()=>{

        (async()=>{
            setDisabled(true)

            const res = await readSurvey(surveyId)
            setSurvey((res || []).sort((a,b)=>b.createdAt-a.createdAt))

            setDisabled(false)
        })()

    }, [surveyId])

    const [satisfactionScoreData, setSatisfactionScoreData] = React.useState({})
    const [satisfactionScoreOptions, setSatisfactionScoreOptions] = React.useState({})
    
    const satisfactionScoreObject = {
        1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0
    }

    for (const row of survey) {
        satisfactionScoreObject[row.data.satisfaction_score] ++
    }

    React.useEffect(()=>{
        (async()=>{
            setSatisfactionScoreData({
                labels: Object.keys(satisfactionScoreObject),
                datasets: [
                    {
                        label: '개수',
                        data: Object.values(satisfactionScoreObject),
                        borderColor: 'rgba(54, 162, 235, 1)',
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        borderCapStyle: 'butt',
                        borderWidth: 1,
                    }
                ]
            })

            setSatisfactionScoreOptions({
                plugins: {
                    datalabels: {
                        formatter: (value, ctx) => {
                            return `${value} (${Math.round((value / survey.length)*100, -3)}%)`
                        },
                        align: 'end',
                        anchor: 'end',
                    },
                    legend:{
                        display: false,
                    },
                },
                layout: {
                    padding: {
                        top: 20,
                    },
                },
            })

        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[survey])

    const [pointOfSatisfactionData, setPointOfSatisfactionData] = React.useState({});
    const [pointOfSatisfactionOptions, setPointOfSatisfactionOptions] = React.useState({});

    let satisfaction_points_object = {
        "디렉터의 피드백": 0, 
        "성별, 연령 분석": 0, 
        "독자 유입률, 연독률 분석": 0, 
        "컷별 체류시간, 이탈 그래프": 0
    }

    let unsatisfaction_points_object = {
        "디렉터의 피드백": 0, 
        "성별, 연령 분석": 0, 
        "독자 유입률, 연독률 분석": 0, 
        "컷별 체류시간, 이탈 그래프": 0
    }

    for (const eachSurvey of survey) {
        for (const point in eachSurvey.data.satisfaction_points) {

            if (eachSurvey.data.satisfaction_points[point]){
                satisfaction_points_object[point] ++

            } else if (!eachSurvey.data.satisfaction_points[point]){
                unsatisfaction_points_object[point] ++
            }
        }
    }

    React.useEffect(()=>{
        (async()=>{
        
            setPointOfSatisfactionData({
                labels: Object.keys(satisfaction_points_object),
                datasets:[
                    {
                        label: "만족",
                        data: Object.values(satisfaction_points_object),
                        borderColor: 'rgba(54, 162, 235, 1)',
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        borderCapStyle: 'butt',
                        borderWidth: 1,
                        datalabels: {
                            formatter: (value, ctx) => {
                                return `${value} (${Math.round((value / survey.length)*100, -3)}%)`
                            },
                            align: 'end',
                            anchor: 'end',
                            color: (context) => {
                                return context.dataset.borderColor;
                            },
                        }
                    },
                    {
                        label: "불만족",
                        data:  Object.values(unsatisfaction_points_object),
                        borderColor: 'rgba(255, 99, 132, 1)',
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        borderCapStyle: 'butt',
                        borderWidth: 1,
                        datalabels: {
                            formatter: (value, ctx) => {
                                return `${value} (${Math.round((value / survey.length)*100, -3)}%)`
                            },
                            align: 'end',
                            anchor: 'end',
                            color: (context) => {
                                return context.dataset.borderColor;
                            },
                        }
                    },
                ]
            })

            setPointOfSatisfactionOptions({
                layout: {
                    padding: {
                        top: 20,
                    }
                }
            })
            
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[survey])

    const [recommendScoreData, setRecommendScoreData] = React.useState({});
    const [recommendScoreOptions, setRecommendScoreOptions] = React.useState({});
    
    const recommendScoreObject = {
        1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0
    }

    for (const row of survey) {
        recommendScoreObject[row.data.recommend_score] += 1
    }
    
    React.useEffect(()=>{
        (async()=>{
            
            setRecommendScoreData({
                labels: Object.keys(recommendScoreObject),
                datasets: [
                    {
                        label: '개수',
                        data: Object.values(recommendScoreObject),
                        borderColor: 'rgba(54, 162, 235, 1)',
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        borderCapStyle: 'butt',
                        borderWidth: 1,
                    }
                ]
            })

            setRecommendScoreOptions({
                plugins: {
                    datalabels: {
                        formatter: (value, ctx) => {
                            return `${value} (${Math.round((value / survey.length)*100, -3)}%)`
                        },
                        align: 'end',
                        anchor: 'end',
                    },
                    legend: {
                        display: false,
                    },
                },
                layout: {
                    padding: {
                        top: 20,
                    }
                }
            })
            
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[survey])

    const threeDaysAgo = (new Date()).getTime() - (60*60*24*3*1000)

    let recommend_reasons_object = {
        "독자들의 객관적인 평가를 받아볼 수 있음": 0,
        "데이터 분석 결과가 명료하고 직관적임": 0,
        "디렉터의 피드백이 작품 개발 방향 설정에 도움이 됨": 0,
        "동기부여가 됨": 0
    }

    let recommend_reasons_extra = {}

    let recommend_reasons_object_threedays = {
        "독자들의 객관적인 평가를 받아볼 수 있음": 0,
        "데이터 분석 결과가 명료하고 직관적임": 0,
        "디렉터의 피드백이 작품 개발 방향 설정에 도움이 됨": 0,
        "동기부여가 됨": 0
    }

    let recommend_reasons_extra_threedays = {}

    for (const item of survey){
        for(const reason of item.data.recommend_reasons) {
            
            if(recommend_reasons_object[reason] >= 0){
                recommend_reasons_object[reason] ++

            } else if (!recommend_reasons_object[reason] && reason) {

                if (!recommend_reasons_extra[reason]){
                recommend_reasons_extra[reason] = 0
                }
                recommend_reasons_extra[reason] ++
            }
        }
    }

    for (const item of survey) {
        if (item.createdAt * 1000 > threeDaysAgo){
            for(const reason of item.data.recommend_reasons) {

                if(recommend_reasons_object_threedays[reason] >= 0){
                    recommend_reasons_object_threedays[reason] ++

                } else if (!recommend_reasons_object_threedays[reason] && reason) {

                    if (!recommend_reasons_extra_threedays[reason]){
                        recommend_reasons_extra_threedays[reason] = 0
                    }
                    recommend_reasons_extra_threedays[reason] ++
                }
            }
        }
    }
    
    let no_recommend_reasons_object = {
        '분석이 별로 객관적이지 않음': 0,
        '데이터 분석 결과가 이해하기 어려움': 0,
        '디렉터의 피드백에 동의할 수 없고 도움이 되지 않음': 0,
        '의욕을 잃게 함': 0
    }

    let no_recommend_reasons_extra = {}

    let no_recommend_reasons_object_threedays = {
        '분석이 별로 객관적이지 않음': 0,
        '데이터 분석 결과가 이해하기 어려움': 0,
        '디렉터의 피드백에 동의할 수 없고 도움이 되지 않음': 0,
        '의욕을 잃게 함': 0
    }

    let no_recommend_reasons_extra_threedays = {}

    
    for (const item of survey){
        for(const reason of item.data.no_recommend_reasons) {

            if(no_recommend_reasons_object[reason] >= 0){
                no_recommend_reasons_object[reason] ++

            } else if (!no_recommend_reasons_object[reason] && reason) {

                if (!no_recommend_reasons_extra[reason]){
                    no_recommend_reasons_extra[reason] = 0
                }
                no_recommend_reasons_extra[reason] ++
            }
        }
    }

    for (const item of survey) {
        if (item.createdAt * 1000 > threeDaysAgo){
            for(const reason of item.data.no_recommend_reasons) {

                if(no_recommend_reasons_object_threedays[reason] >= 0){
                    no_recommend_reasons_object_threedays[reason] ++

                } else if (!no_recommend_reasons_object_threedays[reason] && reason) {

                    if (!no_recommend_reasons_extra_threedays[reason]){
                        no_recommend_reasons_extra_threedays[reason] = 0
                    }
                    no_recommend_reasons_extra_threedays[reason] ++
                }
            }
        }
    }


    const [subjectAchivesData, setSubjectAchivesData] = React.useState({});
    const [subjectAchivesOptions, setSubjectAchivesOptions] = React.useState({});

    let subjectAchivesObject = {
        "작품 개발 방향 설정": 0,
        "독자들의 객관적인 평가": 0, 
        "연재 협의에 도움이 됨(또는 될것 같음)": 0,
        "디렉터에게 계속 도움을 받음(또는 받을 것 같음)": 0, 
        "회사와 계약을 맺을 의사가 생김": 0
    }

    for(const row of survey){
        for(const subject of row.data.subject_achives){
            subjectAchivesObject[subject] ++
        }
    }

    React.useEffect(()=>{
        (async()=>{
            
            setSubjectAchivesData({
                labels: Object.keys(subjectAchivesObject),
                datasets: [
                    {
                        label: '예',
                        data: Object.values(subjectAchivesObject),
                        backgroundColor: 'rgba(75,192,192,0.4)',
                        borderCapStyle: 'butt',
                    },
                ]
            })

            setSubjectAchivesOptions({
                indexAxis: 'y',
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        formatter: (value, ctx) => {
                            return ` ${value} (${Math.round((value / survey.length)*100, -3)}%) `
                        },
                        anchor: 'end',
                        align: 'start',
                        clamp: true,
                    },
                },
            })
            
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[survey])

    const createdtimeOfAttribute = ( attribute, address ) => {
        const result = []
        for (const item of survey) {
            if (item.data[address].includes(attribute)){
                result.push(`${moment(item.createdAt * 1000).format('YY.MM.DD. HH:mm')}`)
            }
        }
        return result.join(',');
    }
    
    return(
        <>
            <div className={"SurveyBody"}>
                <div className={"SurveyContainer"}>
                    <Paper className={"SurveyHeader"}>
                        <div className={"HeadText"}>{"웹툰 애널리틱스(데이터 분석) 평가"}</div>
                        <div className={"SubText"}> {`응답: ${(survey).length}개`}</div>
                    </Paper>

                    <Paper className={"EachBox"}>
                        <div className={"BoxTitle"}>
                            {`1. 오늘의웹툰 데이터 분석은 만족스러우셨나요?`}
                            <div className={"SubTotalCount"}>
                                {`응답 ${survey.filter(each=>each.data.satisfaction_score).length}개`}
                            </div>
                        </div>
                        <div className={"ChartArea"}>
                            {satisfactionScoreData.datasets && <Bar
                                className={`SatisfactionBarChart`}
                                data={satisfactionScoreData}
                                options={satisfactionScoreOptions}
                                plugins={[ChartDataLabels]}
                            />}
                        </div>
                    </Paper>
                    <Paper className={"EachBox"}>
                        <div className={"BoxTitle"}>
                            {`1-1. 어떤점이 만족, 또는 불만족스러우셨나요?`}
                        </div>
                        <div className={"ChartArea"}>
                            {pointOfSatisfactionData.datasets && <Bar 
                                className={`SatisfactionPointBarChart`}
                                data={pointOfSatisfactionData}
                                option={pointOfSatisfactionOptions}
                                plugins={[ChartDataLabels]}
                            />}
                        </div>
                    </Paper>
                    <Paper className={"EachBox"}>
                            <div className={"BoxTitle"}>
                                {`1-2. 만족스럽지 않은 부분이 있으셨다면, 어떻게 개선하면 좋을까요?(선택)`}
                            </div>
                            <div className={"ColoredBackgroundArea"}>
                                <div className={"CountSurveyText"}>
                                    {`최근 3일 내 해당 질문의 응답: ${survey.filter(each=>each.createdAt * 1000 > threeDaysAgo && each.data.improvement_for_satisfaction_points).length }`}
                                </div>
                                    {survey.filter(each => each.data.improvement_for_satisfaction_points && each.createdAt * 1000 > threeDaysAgo)
                                        .map((element, index)=>(
                                            <div key={`satisfactionImprovement${index}`} className={"SuggestionText"}>
                                                {`${element.data.improvement_for_satisfaction_points}`}
                                            </div>
                                    ))}
                                <div className={"CountSurveyText"}>
                                    {`전체 응답: ${survey.filter(each => each.data.improvement_for_satisfaction_points).length}`}
                                </div>
                                    {survey.filter(each => each.data.improvement_for_satisfaction_points).map((element, index)=>(
                                        <div key={`satisfactionImprovement${index}`} className={"SuggestionText"}>
                                            {`${element.data.improvement_for_satisfaction_points}`}
                                        </div>
                                    ))}
                            </div>
                    </Paper>
                    <Paper className={"EachBox"}>
                        <div className={"BoxTitle"}>
                            {`2. 지인에게 오늘의웹툰 데이터 분석에 대해 추천할 의향이 있습니까?`}
                            <div className={"SubTotalCount"} >
                                {`응답 ${survey.filter(each=>each.data.recommend_score).length} 개`}
                            </div>
                        </div>
                        <div className={"ChartArea"}>
                            {recommendScoreData.datasets && <Bar
                                className={`RecommendScoreBarChart`}
                                data={recommendScoreData}
                                options={recommendScoreOptions}
                                plugins={[ChartDataLabels]}
                            />}
                        </div>
                    </Paper>
                    <Paper className={"EachBox"}>
                        <div className={"BoxTitle"}>
                            {`2-1. 추천하신다면 그 이유는 무엇인가요?`}
                        </div>
                        <div className={"Windows"}>

                            <Table className={"StickyHeader"}>
                                <TableBody>
                                    <TableRow className={"Box"}>
                                        <TableCell padding={'none'} className={"HeadCount"}>
                                            {"개수"}
                                        </TableCell>
                                        <TableCell padding={'none'} className={"HeadExplanation"} >
                                            {"이유"}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>    
                            </Table>
                            <div className={"CountSurveyText"}>{`최근 3일 내 해당 질문의 응답: 
                                ${survey.filter(each=>each.createdAt * 1000 > threeDaysAgo && each.data.recommend_reasons[0]).length }`}
                            </div>
                            <TableContainer className={"Container"} >
                                <Table className={"ReasonArea"}>
                                    <TableBody aria-label="simple table" className={"Reasons"} >
                                        {Object.keys(recommend_reasons_object_threedays)
                                            .map((cell, index) => 
                                                (Object.values(recommend_reasons_object_threedays)[index] !== 0?
                                                    <TableRow key={`recentRecommend${cell}${index}`} className={"Row"} >
                                                        <TableCell padding={'none'} className={"Count"}>{`${Object.values(recommend_reasons_object_threedays)[index]}`}</TableCell>
                                                        <TableCell padding={'none'} className={"Explanation"}>{cell}</TableCell>
                                                    </TableRow>
                                                :<></>)
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TableContainer className={"Container"}>
                                <Table className={"ReasonArea"}>
                                    <TableBody className={"Reasons"} aria-label="simple table" >
                                        {Object.keys(recommend_reasons_extra_threedays)
                                            .map((cell, index) =>
                                                <TableRow key={`rereExtraReommend${cell}${index}`} className={"Row"} >
                                                    <TableCell padding={'none'} className={"Count"}>{`${Object.values(recommend_reasons_extra_threedays)[index]}`}</TableCell>
                                                    <TableCell padding={'none'} className={"Explanation"}>{cell}
                                                    <span className={"CreatedTime"}> {createdtimeOfAttribute(cell, "recommend_reasons")} </span>
                                                    </TableCell>
                                                </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            
                            <div className={"CountSurveyText"}>
                                {`전체 응답: ${survey.filter(each => each.data.recommend_reasons[0]).length}`}
                            </div>
                            <TableContainer className={"Container"} >
                                <Table className={"ReasonArea"}>
                                    <TableBody aria-label="simple table" className={"Reasons"} >
                                        {Object.keys(recommend_reasons_object)
                                            .map((cell, index) => 
                                                (Object.values(recommend_reasons_object)[index] !== 0?
                                                    <TableRow key={`recommend${cell}${index}`} className={"Row"} >
                                                        <TableCell padding={'none'} className={"Count"}>{`${Object.values(recommend_reasons_object)[index]}`}</TableCell>
                                                        <TableCell padding={'none'} className={"Explanation"}>{cell}</TableCell>
                                                    </TableRow>
                                                :<></>)
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TableContainer className={"Container"}>
                                <Table className={"ReasonArea"}>
                                    <TableBody className={"Reasons"} aria-label="simple table" >
                                        {Object.keys(recommend_reasons_extra).map((cell, index) =>
                                            <TableRow key={`extraRecommend${cell}${index}`} className={"Row"} >
                                                <TableCell padding={'none'} className={"Count"}>{`${Object.values(recommend_reasons_extra)[index]}`}</TableCell>
                                                <TableCell padding={'none'} className={"Explanation"}>{cell}
                                                <span className={"CreatedTime"}> {createdtimeOfAttribute(cell, 'recommend_reasons')} </span>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Paper>
                    <Paper className={"EachBox"}>
                        <div className={"BoxTitle"}>
                            {`2-2. 비추천하신다면 그 이유는 무엇인가요?`}
                        </div>
                        <div className={"Windows"}>
                            
                            <Table className={"StickyHeader"}>
                                <TableBody>
                                    <TableRow className={"Box"}>
                                        <TableCell padding={'none'} className={"HeadCount"}>
                                            {"개수"}
                                        </TableCell>
                                        <TableCell padding={'none'} className={"HeadExplanation"} >
                                            {"이유"}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>    
                            </Table>
                            <div className={"CountSurveyText"}>{`최근 3일 내 해당 질문의 응답:
                                ${survey.filter(each=> each.createdAt * 1000 > threeDaysAgo && each.data.no_recommend_reasons[0]).length}`}
                            </div>
                            <TableContainer className={"Container"} >
                                <Table className={"ReasonArea"}>
                                    <TableBody aria-label="simple table" className={"Reasons"} >
                                        {Object.keys(no_recommend_reasons_object_threedays)
                                            .map((cell, index) => 
                                                (Object.values(no_recommend_reasons_object_threedays)[index] !== 0?
                                                    <TableRow key={`recentNoRecommend${cell}${index}`} className={"Row"} >
                                                        <TableCell padding={'none'} className={"Count"}>{`${Object.values(no_recommend_reasons_object_threedays)[index]}`}</TableCell>
                                                        <TableCell padding={'none'} className={"Explanation"}>{cell}</TableCell>
                                                    </TableRow>
                                                :<></>)
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TableContainer className={"Container"}>
                                <Table className={"ReasonArea"}>
                                    <TableBody className={"Reasons"} aria-label="simple table" >
                                        {Object.keys(no_recommend_reasons_extra_threedays)
                                            .map((cell, index) =>
                                                <TableRow key={`recentExtraNoRecommend${cell}${index}`} className={"Row"} >
                                                    <TableCell padding={'none'} className={"Count"}>{`${Object.values(no_recommend_reasons_extra_threedays)[index]}`}</TableCell>
                                                    <TableCell padding={'none'} className={"Explanation"}>{cell}
                                                    <span className={"CreatedTime"}> {createdtimeOfAttribute(cell, 'no_recommend_reasons')} </span>
                                                    </TableCell>
                                                </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <div className={"CountSurveyText"}>
                                {`전체 응답: ${survey.filter(each => each.data.no_recommend_reasons[0]).length}`}
                            </div>
                            <TableContainer className={"Container"}>
                                <Table className={"ReasonArea"} >
                                    <TableBody className={"Reasons"} aria-label="simple table" >
                                        {Object.keys(no_recommend_reasons_object)
                                            .map((cell, index) => 
                                                (Object.values(no_recommend_reasons_object)[index] !== 0?
                                                    <TableRow key={`noRecommend${cell}${index}`} className={"Row"} >
                                                        <TableCell padding={'none'} className={"Count"}>{`${Object.values(no_recommend_reasons_object)[index]}`}</TableCell>
                                                        <TableCell padding={'none'} className={"Explanation"}>{cell}</TableCell>
                                                    </TableRow>
                                                :<></>)
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TableContainer className={"Container"}>
                                <Table className={"ReasonArea"} >
                                    <TableBody className={"Reasons"} aria-label="simple table" >
                                        {Object.keys(no_recommend_reasons_extra)
                                            .map((cell, index) => 
                                                <TableRow key={`extraNoRecommend${cell}${index}`} className={"Row"} >
                                                    <TableCell padding={'none'} className={"Count"}>{`${Object.values(no_recommend_reasons_extra)[index]}`}</TableCell>
                                                    <TableCell padding={'none'} className={"Explanation"}>{cell}
                                                    <span className={"CreatedTime"}> {createdtimeOfAttribute(cell, 'no_recommend_reasons')} </span>
                                                    </TableCell>
                                                </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Paper>
                    <Paper className={"EachBox"}>
                        <div className={"BoxTitle"}>
                            {`2-3. 추천할만한 데이터 분석을 제공하기 위해서, 어떤 점을 개선하면 좋을까요?`}
                        </div>
                        <div className={"ColoredBackgroundArea"} >
                            <div className={"CountSurveyText"}>
                                {`최근 3일 내 해당 질문의 응답: ${survey.filter(each=>each.createdAt * 1000 > threeDaysAgo && each.data.improvement_for_recommend_points).length }`}
                            </div>
                            <div>
                                {survey.filter( each =>each.createdAt * 1000 > threeDaysAgo && each.data.improvement_for_recommend_points).map((element, index)=>(
                                    <div className={"SuggestionText"} key={`satisfactionImprovement${index}`}>
                                        {`${element.data.improvement_for_recommend_points}`}
                                    </div>
                                ))}
                            </div>
                            <div className={"CountSurveyText"}>
                                {`전체 응답: ${survey.filter(each => each.data.improvement_for_satisfaction_points).length}`}
                            </div>
                            <div>
                                {survey.filter(each => each.data.improvement_for_recommend_points).map((element, index)=>(
                                    <div className={"SuggestionText"} key={`satisfactionImprovement${index}`}>
                                        {`${element.data.improvement_for_recommend_points}`}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Paper>
                    <Paper className={"EachBox"}>
                        <div className={"BoxTitle"}>
                            {`3. 데이터 분석 제공을 수락한 이유는 무엇인가요?`}
                        </div>
                        <div className={"Windows"}> 
                            <Table className={"StickyHeader"}>
                                <TableBody>
                                    <TableRow className={"Box"}>
                                        <TableCell padding={'none'} className={"AnalysisReason"} >
                                            {"이유"}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>    
                            </Table>
                            <div className={"CountSurveyText"}>{`최근 3일 내 해당 질문의 응답: 
                                ${survey.filter(each=> each.createdAt * 1000 > threeDaysAgo && each.data.approve_reasons[0]).length}`}
                            </div>
                            <TableContainer className={"Container"}>
                                <Table className={"ReasonArea"}>
                                    <TableBody className={"Reasons"}>
                                        {survey
                                            .filter(each => each.createdAt * 1000 > threeDaysAgo && each.data.approve_reasons[0])
                                            .map((element, index) => (
                                            <TableRow className={"Row"} key={`analysis${element}${index}`}>
                                                <TableCell className={"FullExplanation"} padding={'none'} >
                                                    {element.data.approve_reasons}
                                                </TableCell>
                                            </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            
                            <div className={"CountSurveyText"}>
                                {`전체 응답: ${survey.filter(each => each.data.approve_reasons[0]).length}`}
                            </div>
                            <TableContainer className={"Container"}>
                                <Table className={"ReasonArea"}>
                                    <TableBody className={"Reasons"}>
                                        {survey.map((element, index) => (
                                            <TableRow className={"Row"} key={`analysis${element}${index}`}>
                                                <TableCell className={"FullExplanation"} padding={'none'} >
                                                    {element.data.approve_reasons}
                                                </TableCell>
                                            </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Paper>
                    <Paper className={"EachBox"}>
                        <div className={"BoxTitle"}>
                            {`3-1. 데이터 분석 이후 목적이 달성되었나요?`}
                        </div>
                        <div className={"ChartArea"}>
                            {subjectAchivesData.datasets && <Bar
                                className={"subjectAchivesBarChart"}
                                data={subjectAchivesData}
                                options={subjectAchivesOptions}
                                plugins={[ChartDataLabels]}
                            />}
                        </div>
                    </Paper>
                </div>
                <LoadingCircle show={disabled} />
            </div>

        </>
    )
}

export default SurveyView;