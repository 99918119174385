import axios from "axios";
import ss from '../../../../Data/Session';


export const readEpisodes = async (serviceId, titleId) => {

    try{
        let res = await axios.post('https://challenge-api.webtoon.today/webtoon/episode', {serviceId, titleId}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.data.sort( (a,b) => Number(a.episodeId) - Number(b.episodeId));

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}



export const createEpisode = async ({serviceId, titleId, episodeId, title, thumbnail, exposure}) => {
    
    try {
        let res = await axios.put('https://challenge-api.webtoon.today/webtoon/episode', {serviceId, titleId, episodeId, title, thumbnail, exposure}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return [];
        }
    } catch(e) {
        return [];
    }
}

export const updateEpisode = async ({episode}) =>{
    try{
        let res = await axios.patch('https://challenge-api.webtoon.today/webtoon/episode', episode, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}

export const deleteEpisode = async ({serviceId, titleId, episodeId}) => {
    try{
        let res = await axios.delete(`https://challenge-api.webtoon.today/webtoon/episode?serviceId=${serviceId}&titleId=${titleId}&episodeId=${episodeId}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}


// const initialGetEpisodesState = {
//     loading: false,
//     data: [],
//     error: null,
// };

// const GET_EPISODES_START = "GET_EPISODES_START";
// const GET_EPISODES_SUCCESS = 'GET_EPISODES_SUCCESS';
// const GET_EPISODES_FAIL = 'GET_EPISODES_FAIL';

// const getEpisodesStart = () => {
//     return {
//         type: GET_EPISODES_START,
//     };
// };

// const getEpisodesSuccess = () => {
//     return {
//         type: GET_EPISODES_SUCCESS,
//     };
// };

// const getEpisodesFail = () => {
//     return {
//         type: GET_EPISODES_FAIL,
//     };
// };

// const CLEAR_EPISODES_START = 'CLEAR_EPISODES_START';
// const CLEAR_EPISODES_SUCCESS ='CLEAR_EPISODES_SUCCESS';
// const CLEAR_EPISODES_FAIL = 'CLEAR_EPISODES_FAIL';

// const clearEpisodesStart = () => {
//     return{
//         type: CLEAR_EPISODES_START
//     };
// };

// const clearEpisodesSuccess = (res) => {
//     return {
//         type: CLEAR_EPISODES_SUCCESS,
//         data: res
//     };
// };

// const clearEpisodesFail = (error) => {
//     return {
//         type: CLEAR_EPISODES_FAIL,
//         error
//     };
// };

// const getTitlesThunk = ( serviceId, titleId ) => {
//     return async ( dispatch, getState ) => {

//     }
// }

// const clearTitlesThunk = (serviceId, titleId) => {
//     return async ( dispatch, getState ) => {
        
//     };
// }

// export const episodesReducer = (state = initialGetEpisodesState, action) => {
//     if(action.type === GET_EPISODES_START){
//         return {
//             ...state,
//             loading: true,
//             error: null
//         };
//     };
//     if(action.type === GET_EPISODES_SUCCESS){
//         return {
//             ...state,
//             loading: false,
//             data: {
//                 ...state.data,
//                 ...action.data
//             },
//         };
//     };
//     if(action.type === GET_EPISODES_FAIL){
//         return {
//             ...state,
//             loading: false,
//             data: {
//                 ...state.data
//             },
//         };
//     };

//     if(action.type === CLEAR_EPISODES_START){
//         return {
//             ...state,
//             error: null
//         };
//     };
//     if(action.type === CLEAR_EPISODES_SUCCESS){
//         return {
//             ...state,
//             data: {
//                 ...state.data,
//                 ...action.data
//             },
//         };
//     };
//     if(action.type === CLEAR_EPISODES_FAIL){
//         return {
//             ...state
//         };
//     };
    
//     return state;
// }