import React, { useState } from 'react';
import { fn } from '../../../Functions';

import "./Footer.scss";

const Footer = () => {

    const [ isOpened, setIsOpened ] = useState(false);

    return (
    <section className={'Footer'}>
        <div className={'FooterMenu'}>
            <span className={'BizInfo'} onClick={(e)=>{
                    setIsOpened(!isOpened);
                }}>
                <span>
                    {'사업자 정보'}
                </span>
                <span className={`DetailButton`}>
                    <img src={'https://static.webtoon.today/ddah/chevron.svg'} alt={'보기'} className={`${isOpened?``:`Closed`}`}/>
                </span>
            </span>
            <a href={'https://webtoon.today/termsofuse/kr'} onClick={fn.gotoByAnchor}>
                <span className={'PrivacyPolicy'}>
                {'이용 약관'}
                </span>
            </a>
            <a href={'https://webtoon.today/privacypolicy/kr'} onClick={fn.gotoByAnchor}>
                <span className={'PrivacyPolicy'}>
                {'개인정보처리방침'}
                </span>
            </a>
        </div>
        {isOpened&&
        <div className={'CompanyDetail'}>
            <div className={'FirstLine'}>
                <span>{'상호: 주식회사 오늘의웹툰'}</span>
                <span>{'대표자 : 진수글'}</span>
                <span>{'사업자등록번호 : 765-81-02007'}</span>
                <span>{'통신판매업신고번호 : 2023-서울강남-04131'}</span>
            </div>
        </div>}
        <div className={'CompanyName'}>
            {'ⓒ 주식회사 오늘의웹툰'}
        </div>
    </section>
    );
}

export default Footer;