import React from 'react';
import { getTitle } from '../../Data/Title';
import { readTitleNotes } from '../../Data/TitleNote';
import './LatestNoteOfTitlesView.scss';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, 
    TimelineContent, TimelineDot, TimelineOppositeContent } from '@material-ui/lab';
import moment from 'moment-timezone';


const ProgressView = ({ serviceId, titleId }) => {
    
    const [currentNotes, setCurrentNotes] = React.useState([]);
    const [noteTitle, setNoteTitle] = React.useState(null);

    React.useEffect(()=>{

        ( async () => {

            const specificNote = await readTitleNotes(serviceId, titleId)
            
            setCurrentNotes(specificNote)
        })()

    },[serviceId, titleId])
    
    React.useEffect(()=>{

        ( async () => {

            const titleResult = await getTitle(serviceId, titleId)
            const currentNoteTitle = titleResult[0].title

            setNoteTitle(currentNoteTitle)
            
        })()

    },[serviceId, titleId])
    

    return(
        <div className={'EachProgressInner'}>
            <div className={'EachProgressContainer'}>
                <div className={'WebtoonTitle'} style={{fontSize: '2rem', textAlign: 'center'}}>
                    {noteTitle}
                </div>
                <div style={{marginTop: '50px'}}>
                    <Timeline align={"left"}>
                        {currentNotes
                        .map(note =>
                            <TimelineItem key={`${[note.serviceId,note.titleId,note.createdAt].join(":")}`}>
                                <TimelineOppositeContent style={{maxWidth: '20%'}}>
                                    {moment(note.createdAt).format('YY.MM.DD. HH:mm')}
                                </TimelineOppositeContent>
                                <TimelineSeparator  >
                                    <TimelineDot color={'primary'} />
                                    <TimelineConnector style={{backgroundColor: 'rgb(0,176,240)'}} />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <span style={{padding: '10px 15px 10px 15px', borderRadius: '10px', backgroundColor: 'lightgray', fontSize: '0.9rem'}}>
                                        {note.note}
                                    </span>
                                    <span style={{display: 'inline-flex', marginLeft: '5px', alignItems: 'center'}}>
                                        <img src={note.image} alt={'Profile'} style={{width: '20px', height: '20px'}} />
                                        {note.name}
                                    </span>
                                </TimelineContent>
                            </TimelineItem>    
                        )}
                    </Timeline>
                </div>
            </div>
        </div>
    )
}


export default ProgressView;