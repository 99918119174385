import React, { useEffect, useRef,useCallback } from "react"
import * as d3 from 'd3';

const labelCoordinate = [-20,20];
const starPath = "M10 0L12.2451 6.90983H19.5106L13.6327 11.1803L15.8779 18.0902L10 13.8197L4.12215 18.0902L6.36729 11.1803L0.489435 6.90983H7.75486L10 0Z"
const ScatteredPlot = ({
    width: givenWidth, height: givenHeight, 
    margin: givenMargin = {
        top: 0, right: 0, bottom: 0, left: 0
    },
    axisLabels, lineLabels,
    dataset, 
    pointData = [],
    maxValue = {},
}) => {
    const margin = givenMargin;
    const width = givenWidth - (margin.right + margin.left);
    const height = givenHeight - (margin.top + margin.bottom);
    let svgRef = useRef(null);

    const [ minValueX = 0, maxValueX = 0 ] = d3.extent([...dataset, ...pointData, maxValue].map( ({x}) => x )); 
    const [ minValueY = 0, maxValueY = 0 ] = d3.extent([...dataset, ...pointData, maxValue].map( ({y}) => y ));
    
    
    const getX = useCallback(( x ) => {
        if ( x < 0 ) return 0;

        return x / (minValueX + maxValueX) * width;
    },[maxValueX, minValueX, width]) 

    const getY = useCallback((y) => {
        if ( y < 0 ) return 0;
        return height - y / (minValueY + maxValueY) * height; 
    },[height, maxValueY, minValueY]) 

    const {top,right,bottom,left} = margin;

    const circleRadius = 5;

    const [xAxisLabel, yAxisLabel] = axisLabels || ["", ""];
    
    useEffect(() => {
        const svg = d3.select(svgRef.current)
            .style('background-color', 'rgba(249, 251, 255, 1)')
            .style('padding', `${top}px ${right}px ${bottom}px ${left}px`)
            .style('overflow', 'visible')
            .style('box-sizing', 'border-box')
            .attr("viewBox", `0 0 ${width} ${height}`)
            .style('width', "100%")
        
        // x축 라벨
        svg.append('text')
            .attr("class", "xAxisLabel")
            .text(xAxisLabel)
            .attr("x", width)
            .attr("y", height)
            .attr("stroke", "rgba(60, 70, 81, 1)")
            .attr("text-anchor", "end")
            .style("font-size", "0.875rem")
            .style("font-weight", "200")

        // y축 라벨
        svg.append('text')
            .attr("class", "yAxisLabel")
            .text(yAxisLabel)
            .attr("x", 10)
            .attr("y", 0)
            .attr("stroke", "rgba(60, 70, 81, 1)")
            .attr("text-anchor", "start")
            .style("font-size", "0.875rem")
            .style("writing-mode", "vertical-lr")
            .style("font-weight", "200")

        return () => {
            svg.selectAll(".xAxisLabel, .yAxisLabel").remove();
        }

    },[xAxisLabel, yAxisLabel, bottom, height, left, right, top, width])

    useEffect(() => {
        const svg = d3.select(svgRef.current)
        // 가로 GRID
        svg.append("line")
            .attr("class", "col-grid")
            .style("stroke", "rgb(111, 108, 142)")
            .style("stroke-dasharray", ("4, 7"))
            .attr("x1", 0)
            .attr("y1", height/2)
            .attr("x2", width)
            .attr("y2", height/2);

        // 세로 GRID
        svg.append("line")
            .attr("class", "row-grid")
            .style("stroke", "rgb(111, 108, 142)")
            .style("stroke-dasharray", ("4, 7"))
            .attr("x1", width/2)
            .attr("y1", 0)
            .attr("x2", width/2)
            .attr("y2", height);
            
        return () => {
            svg.selectAll(".col-grid, .row-grid").remove();
        }
    },[height, width])

    useEffect(() => {
        const svg = d3.select(svgRef.current)
        
        // 화살표 머리
        svg.append("defs").append('marker')
            .attr('id', 'arrowhead')
            .attr('viewBox', '-0 -5.5 10 10')
            .attr('refX',4)
            .attr('refY',0)
            .attr('orient','auto')
            .attr('markerWidth',13)
            .attr('markerHeight',13)
            .append("path")
            .attr("d", "M0,-3 L5 ,0 L0,3")
            .style("fill", "rgba(61, 106, 255, 1)");

        return () => {
            svg.selectAll("#arrowhead").remove();
        }
    },[])

    useEffect(() => {
        
        const svg = d3.select(svgRef.current)
        // 범례
        svg.append('text')
            .attr("class","legends")
            .text(dataset.concat(pointData || []).filter( ({legend}) => legend ).map( ({label, legend}) => `${label} | ${legend || ''}`).join("  "))
            .attr("x", width)
            .attr("y", -30)
            .attr("text-anchor", "end")
            .attr("stroke", "rgba(121, 120, 130, 1)")
            .style("font-weight", "200")
            .style("font-size", '0.75rem')

        // 점을 잇는 선들
        for ( let i = 0; i< dataset.length - 1; i++ ){
            let x1 = getX(dataset[i].x);
            let x2 = getX(dataset[i+1].x);
            let y1 = getY(dataset[i].y);
            let y2 = getY(dataset[i+1].y);

            const angle = Math.atan2(y2-y1, x2-x1)
            const virtualRadiusToDrawLine = circleRadius + 5;

            x1 += virtualRadiusToDrawLine * Math.cos(angle);
            y1 += virtualRadiusToDrawLine * Math.sin(angle);
            
            x2 -= virtualRadiusToDrawLine * Math.cos(angle);
            y2 -= virtualRadiusToDrawLine * Math.sin(angle);

            svg.append("line")
                .attr("class", "data-line")
                .attr("x1", x1 )
                .attr("y1", y1 )
                .attr("x2", x2 )
                .attr("y2", y2 )
                .style("stroke", "rgba(61, 106, 255, 1)")
                .style("stroke-width", "2")
                .attr('marker-end', 'url(#arrowhead)')
        }

        // 데이터 레이블을 잇는 선
        for ( let i = 0; i< dataset.length; i++ ){
            svg.append("line")
                .attr("class", "connect-line")
                .attr("x1", getX(dataset[i].x))
                .attr("y1", getY(dataset[i].y))
                .attr("x2", getX(dataset[i].x) + labelCoordinate[0])
                .attr("y2", getY(dataset[i].y) - labelCoordinate[1])
                .style("stroke", "rgba(60,70,81,1)")
                .style("stroke-width", "1")
        }
        
        dataset.forEach((point) => {
            svg.append("circle")
                .attr("class", "data-point")
                .attr("cx", getX(point.x))
                .attr("cy", getY(point.y))
                .attr("r", `${circleRadius}`)
                .style("fill", "rgba(60,70,81,1)")
        });
        // 점들과 각 선의 레이블
        for ( let i=0; i<dataset.length - 1; i++ ){
            // 선의 중간 지점 계산
            let midX = (getX(dataset[i].x) + getX(dataset[i+1].x)) / 2;
            let midY = (getY(dataset[i].y) + getY(dataset[i+1].y)) / 2;
            const slopeSign = (getY(dataset[i+1].y) - getY(dataset[i].y)) / (getX(dataset[i+1].x) - getX(dataset[i].x)) >= 0 ? +1 : -1

            // 기울기 계산 (단위: 도)
            let angleRad = Math.atan2(getY(dataset[i+1].y ) - getY(dataset[i].y), getX(dataset[i+1].x) - getX(dataset[i].x));   
            let angleDeg;
            
            angleDeg = angleRad * (180 / Math.PI);

            svg.append('text')
                .text(((lineLabels || [])[i]))
                .attr("class", "line-label")
                .attr("x", midX )
                .attr("y", midY )
                .attr("dy", `${0.35 * slopeSign}rem`)
                .attr("transform", `rotate(${angleDeg},${midX},${midY})`)
                .attr("text-anchor", "middle")
                .attr("fill", "rgba(61, 106, 255, 1)")  
        }

        // 데이터 레이블
        svg.selectAll('.label-group')
            .data(dataset)
            .enter()
            .append('g')
                .attr("class","data-label")
                .attr("transform", d => `translate(${getX(d.x) + labelCoordinate[0] - Math.trunc(((d.label || '').replace(/s/g,'')).length/2) * 16 - 8 }, ${getY(d.y) - labelCoordinate[1] - 3 })`)
                .append('text')
                .text(d => d.label || "")

                
        for ( let i=0; i<(pointData || []).length; i++) {
            if ( pointData.length < 1 ) return;

            (pointData || []).forEach((point) => {
                svg.append("path")
                    .attr("class","point-label-line")
                    .attr("d", starPath)
                    .attr("fill", "yellow")
                    .attr("transform", `translate(${getX(point.x)}, ${getY(point.y)})`)
                    .attr("fill", "rgba(60,70,81,1)")
            })
            
            svg.append("line")
                .attr("id", "star-line")
                .attr("x1", getX((pointData || [])[i].x))
                .attr("y1", getY((pointData || [])[i].y))
                .attr("x2", getX((pointData || [])[i].x) + labelCoordinate[0]/2)
                .attr("y2", getY((pointData || [])[i].y) - labelCoordinate[1]/2)
                .style("stroke", "rgba(60,70,81,1)")
                .style("stroke-width", "1")
                
            svg.selectAll('.point-data-label')
                .data(pointData || [])
                .enter()
                .append('g')
                    .attr("class","point-data-label")
                    .attr("transform", d => `translate(${getX((pointData || [])[i].x) - labelCoordinate[0]/2 - Math.trunc(((d.label || '').replace(/s/g,'')).length/2) * 16 - 8 }, ${getY((pointData || [])[i].y) - labelCoordinate[1]/2 - 3 })`)
                    .append('text')
                    .text(d => d.label || pointData[i].label || "")
        }

        return () => {
            svg.selectAll(".legends, .connect-line, .data-line, .data-point, .line-label, .data-label, .start-line, .point-label-line, .point-data-label").remove();
        }
    },[width, height, dataset, getX, getY, pointData, lineLabels])

    return(
        <svg ref={svgRef} />
    )
}
export default ScatteredPlot;
