
import { useEffect, useRef } from 'react';

import * as d3 from 'd3';

const ComparisonSingleBarChart = ({ 
    data,
    width,
    height,
    margin = {
        top: 10,
        right: 20,
        bottom: 20,
        left: 10,
    }
}) => {
    const svgRef = useRef(null);

    const {top, right, bottom, left} = margin;

    const ratio = 0.7

    const { payment, free } = data;
    
    useEffect(( )=> {
        let chartData = [];

        for ( const [name, value] of Object.entries(data)) {
            chartData.push({ tag: name, frequency: value });
        }

        if (!svgRef.current || chartData.length < 1) return;

        const xScale = d3.scaleBand()
            .domain(chartData.map( name => name.tag ))
            .range([left, width - right])
            .paddingInner(0.9)
            .paddingOuter(0.4)
            .align(0.2)
    
        const yScale = d3.scaleLinear()
            .domain([0, 100])
            .range([height - bottom , top ])

        const svg = d3.select(svgRef.current)
            .attr("viewBox", `0 0 ${width} ${height}`)
            
        xScale.domain(chartData.map( datum => datum.tag ));
        yScale.domain([0, 100])
    
        svg.append("g")
            .attr("class", "x-axis")
            .attr("transform", `translate(0,${height - bottom})`)
            .style("color","rgba(236, 237, 243, 1)")
            .style("border", "3px solid red")
            .call(d3.axisBottom(xScale).tickSize(0).tickFormat(() => ""))
    
        svg.append("g")
            .attr("class", "y-axis")
            .attr("opacity", "0")
            .call(d3.axisLeft(yScale))

        svg.append("g")
            .selectAll(".bar")
            .data(chartData)
            .enter()
            .append("rect")
                .attr("class", "bar")
                .attr("fill", "rgb(127, 144, 164)")
                .attr('x', (d) => (xScale(d.tag) || 0))
                .attr('y', (d) => yScale(d.frequency) + (height - bottom - yScale(d.frequency))*(1-ratio)) 
                .attr("width", xScale.bandwidth() )
                .attr("height", (d) => (height - bottom - yScale(d.frequency))*ratio )
        
        const line = d3.line()
            .x((d) => (xScale(d.tag) || 0) + xScale.bandwidth() * 0.35)
            .y((d) => yScale(d.frequency) || 0 )

        svg.append("g")
            .attr("class", "data-line")
            .append('path')
            .datum(chartData.slice(-2).map(({tag, frequency}) => ({tag, frequency: frequency*ratio + 5})))
            .attr('stroke', "rgb(127, 144, 164)")
            .attr('stroke-width', 1.5)
            .attr("marker-start", "url(#circle)")
            .attr("marker-end" , "url(#arrow)") 
            .attr('d', line);
            
        const startData = chartData.slice(-2).map(({tag, frequency}) => ({tag, frequency: frequency*ratio + 5 }))
        
        svg.append('g')
            .append('circle')
                .attr("class", "data-point")
                .attr('cx', (xScale(startData[0].tag) || 0) + xScale.bandwidth() * 0.35)
                .attr('cy', yScale(startData[0].frequency ) )
                .attr('r', 3)
                .attr('fill', "rgb(127, 144, 164)")

        for ( let i=0; i < startData.length; i++ ) {
            svg.append('g')
                .append('text')
                .text(`${[`무료 첫 회차`, `유료 첫 회차`][i]}`)
                    .attr("class", "data-label")
                    .attr('x', xScale(startData[i].tag) || 0 + xScale.bandwidth() * 0.35)
                    .attr('y', yScale(startData[i].frequency) )
                    .attr('dy', -8)
                    .attr("text-anchor", "start")
                    .attr("fill", "rgba(127, 144, 164, 1)")
                    .style("font-size", "0.625rem")
                    .style("font-weight", "600")
                    .style("line-height", "18px")
                    .style("letter-spacing", "0.1px")
        }

        return () => {
            svg.selectAll(".data-line, .x-axis, .y-axis, .bar, .data-line, .data-point, .data-label").remove();
        }
    },[bottom, data, height, left, right, top, width]);

    useEffect(() => {
        const svg = d3.select(svgRef.current)

        svg.append("svg:defs")
            .append("svg:marker")
            .attr("id", "arrow")
            .attr("viewBox", "0 -5 10 10")
            .attr("refX", 6)
            .attr("markerWidth", 5)
            .attr("markerHeight", 5)
            .attr("orient", "auto-start-reverse")
            .append("svg:path")
            .attr("d", "M0,-5L10,0L0,5")
            .attr('fill', "rgb(127, 144, 164)");

        svg.selectAll("textLine")
            .data(["유료 독자 전환율",`${((payment||0) / (free||1) * 100).toFixed(1)}%`])
            .enter()
            .append("text")
                .attr("class", "float-text")
                .attr("x", width - right )
                .attr("y", (d,i) => 10 + 16*i )
                .text( (d) => d )
                .attr("text-anchor", "end")
                .attr("fill", "rgba(60, 70, 81, 1)")
                .attr("alignment-baseline", "text-before-edge")
                .attr("line-height", "100%")
                .attr("font-weight", "500")
                .attr("font-size", (d,i) => `${i%2===0?"0.75rem":"1rem"}`)

        return () => {
            svg.selectAll("#arrow, .float-text").remove();
        }
    },[free, payment, right, width])

    return(<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}} >
        <svg ref={svgRef} />
    </div>)
}

export default ComparisonSingleBarChart;