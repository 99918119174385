import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import CommonSectionHeader from '../CommonSectionHeader';
import {readPost} from '../Data/Post';
import { fn } from '../../../Functions';
import './Notice.scss';

const Notice = () => {
    const [posts, setPosts] = useState([]);

    useEffect(()=>{
        (async()=>{
            const result = await readPost();
            setPosts(result.slice(0,3));
        })();
    },[]);

    return (
        <section className={'GrayColorGg'}>
            <div className={'IntroductionCardContainer NoticeSection'}>
                <CommonSectionHeader title={'ABOUT'} />
                <div className={'PostItemContainer'}>
                    {posts.map(post => 
                        <Button target='_blank' href={`https://challenge.webtoon.today/notice/${post.postId}`} key={post.postId} onClick={fn.gotoByAnchor} title={post.title}>
                            <div className={'PostItem'}>
                                <span className={'ItemText'}>
                                    {post.title}
                                </span>
                                <span className={'ItemText'}>
                                    {new Date(post.updatedAt*1000).toISOString().substring(0,10).replace(/-/g,'.')}
                                </span>
                            </div>
                        </Button>
                    )}
                </div>
                <div className={'LinkButtonWrapper'}>
                    <Button target='_blank' href={'https://challenge.webtoon.today/notice'} className={'LinkButton'} onClick={fn.gotoByAnchor} title={'소식 더보기'}>
                        <div className={'TextWrapper'}>
                            <span className={'ButtonText'}>{"소식 더보기"}</span>
                            <img className={'ArrowRight'} src="https://static.webtoon.today/ddah/icon/arrow_right.svg" alt="arrow right"/>
                        </div>
                    </Button>
                </div>
            </div>
        </section>
    );
}

export default Notice;