import { Avatar, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Input, MenuItem, Paper, Select, TextField } from '@material-ui/core';
import React from 'react';
import EditableTable from '../../Component/Table/EditableTable';
import EditableFileField from '../../Component/Table/EditableFileField';

import {readEpisodes, createEpisode, updateEpisode, deleteEpisode} from '../../Data/Episode';
import {fn} from '../../Functions';

import './EpisodeList.scss';
import { deleteTitle, getTitle, updateTitle } from '../../Data/Title';
import {readOwnerships, createOwnership, deleteOwnership, listMyOwnTitles} from '../../Data/Ownership';
import {readProfile} from '../../Data/Profile';
import {listAds} from '../../Data/Ad';
import ss from '../../Data/Session';

import LoadingCircle from '../../Component/LoadingCircle';
import { Add, Create, Help, Visibility, VpnKey } from '@material-ui/icons';
import { spreadZip } from '../../Data/Image';

import { useDispatch } from "react-redux";
/**
 * 
 * @param {{
 *   serviceId: string,
 *   titleId: string,
 *   readyCallback: () => void,
 * }} props
 * @returns 
 */
const EpisodeList = ({serviceId, titleId, readyCallback}) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);

    const [givenTitle, setGivenTitle] = React.useState({});
    const [title, setTitle] = React.useState({});

    const [askApplyChange, setAskApplyChange] = React.useState(false);
    const [confirmChange, setConfirmChange] = React.useState(false);

    const [ads, setAds] = React.useState([]);
    const [episodes, setEpisodes] = React.useState([]);
    React.useEffect(()=>{
        (async()=>{
            setIsLoading(true);
            let newTitle = await getTitle(serviceId, titleId, dispatch) || [{}];
            setGivenTitle(newTitle[0] || {});
            
            document.title = `작품정보 수정 - ${(newTitle[0] || {}).title || ""}`;
            setIsLoading(false);
        })();
        (async()=>{
            let newEpisodes = await readEpisodes(serviceId, titleId);
            setEpisodes(newEpisodes);
        })();
        (async()=>{
            let newAds = await listAds({serviceId, titleId});
            setAds(newAds);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serviceId, titleId]);

    React.useEffect(()=>{
        if (Object.keys(title).length === 0){
            setTitle(givenTitle);
        } else if (JSON.stringify(title) !== JSON.stringify(givenTitle)){
            setAskApplyChange(true);
        } else {
            setTitle(givenTitle);
        }
    },[title, givenTitle])

    React.useEffect(()=>{
        if (askApplyChange && confirmChange){
            setAskApplyChange(false);
            setConfirmChange(false);

            setTitle(givenTitle);
        }
    },[askApplyChange, confirmChange, givenTitle])

    const [itemAuthority, setItemAuthority] = React.useState('none');
    React.useEffect(()=>{
        (async()=>{
            let ownTitles = await listMyOwnTitles();

            let matched = ownTitles.filter(row => row.serviceId === serviceId && row.titleId === titleId)

            if (matched.length > 0){
                setItemAuthority(matched[0].authority);
            }
        })();
    },[serviceId, titleId])

    const [ownershipList, setOwnershipList] = React.useState([]);
    const [ownerProfiles , setOwnerProfiles] = React.useState({});
    React.useEffect(()=>{
        (async ()=>{
            let newOwnershipList = await readOwnerships(serviceId, titleId);

            let newProfiles = await readProfile(newOwnershipList.map(row => row.userid).join(','))

            setOwnerProfiles(
                ((newProfiles instanceof Array)?newProfiles:[newProfiles]).map(row => {
                    let ret = {};
                    ret[row.userid] = row;
                    return ret;
                }).reduce((a,b) => Object.assign(a,b),{})
            )
    
            setOwnershipList(newOwnershipList);
        })();
    },[serviceId, titleId]);
    
    const [newEpisodeId, setNewEpisodeId] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [askNewEpisodeId, setAskNewEpisodeId] = React.useState(false);
    const [askDeleteTitle, setAskDeleteTitle] = React.useState(false);
    const [askAddOwnership, setAskAddOwnership] = React.useState(false);
    const [askRemoveOwnership, setAskRemoveOwnership] = React.useState(false);
    const [pendingDeleteOwnership, setPendingDelteOwnership] = React.useState({});
    const [pendingAddOwnershipEmail, setPendingAddOwnershipEmail] = React.useState(null);
    const [pendingAddOwnershipAuthority, setPendingAddOwnershipAuthority] = React.useState('read');

    const updateSlideOfTitle = React.useMemo(()=> async (newValue)=>{
        let idx = Object.keys(newValue)[0];

        if (newValue[idx] && newValue[idx].endsWith('.zip')){
            let files = await spreadZip({
                zipName: newValue[idx].replace('https://static.webtoon.today/','')
            });

            setTitle({...title, slides: files.map(fn => `https://static.webtoon.today/`+fn)});
            return;
        }

        let slides = [...(title.slides || [])];
        slides[Number(idx)] = newValue[idx];
        slides = slides.filter(thumb => thumb);
        
        setTitle({...title, ...{slides}});
    }, [title]);

    return (<>
        <div className={"TitleEdit"}>
            <div className={"HeaderBar"}>
                <h2 style={{paddingLeft: '0.5rem'}}>{"작품 상세"}</h2>
                <div className={"Btns"}>
                    <Button className={"Btn"} color={"primary"} variant={"contained"} disabled={
                        JSON.stringify(title) === JSON.stringify(givenTitle)
                        || Object.keys(title) === 0
                        || ['owner', 'write'].indexOf(itemAuthority) < 0
                    } onClick={async ()=>{
                        setIsLoading(true);
                        let resultKey = await updateTitle(title, dispatch);
                        let newGivenTitle = await getTitle(resultKey.split(':')[0], resultKey.split(':')[1], dispatch);
                        setGivenTitle(newGivenTitle);
                        setIsLoading(false);
                    }}>{"저장"}</Button>
                    <Button className={"Btn"} color={"default"} variant={"contained"} disabled={
                        JSON.stringify(title) === JSON.stringify(givenTitle)
                        || Object.keys(title) === 0
                        || ['owner', 'write'].indexOf(itemAuthority) < 0
                    } onClick={()=>setTitle(givenTitle)}>{"초기화"}</Button>
                    <Button className={"Btn"} color={"secondary"} variant={"contained"} disabled={itemAuthority !== 'owner'} onClick={()=>{
                        setAskDeleteTitle(true);
                    }}>{"삭제"}</Button>
                </div>
            </div>
            <div className={"TitleEditContainer"}>
                <div className={"Col WithThumb"}>
                    <h3 style={{paddingLeft: '0.5rem' }}>{"대표 이미지"}</h3>
                    <Paper variant={"outlined"} className={"MainThumbContainer"} style={{backgroundImage: `url('${title.thumbnail}')`}}>
                        <div className={"MainThumbBackdrop"}>
                            <EditableFileField
                                className={"MainThumb"}
                                defaultValue={title.thumbnail}
                                update={(newValue)=>{
                                    setTitle({...title, thumbnail: newValue.thumbnail})
                                }}
                                onLoad={()=>{}}
                                field={"thumbnail"}
                                isEditing={true}
                            />
                        </div>
                    </Paper>
                    <h3 style={{paddingLeft: '0.5rem' }}>{"노출 설정"}</h3>
                    <Paper variant={"outlined"} className={"VisibilityInfo"}>
                        <Select fullWidth value={title.exposure || ''} onChange={(event)=>{
                                    setTitle({...title, exposure: event.target.value})
                        }}>
                            {[["나만 보기","private"], ["목록 제외","unlist"], ["공개","public"]].map(([label, value])=> 
                                <MenuItem key={value} className={"MenuItem"} value={value}>{label}</MenuItem>
                            )}
                        </Select>
                    </Paper>
                    <h3 style={{paddingLeft: '0.5rem' }}>{"댓글 설정"}</h3>
                    <Paper variant={"outlined"} className={"VisibilityInfo"}>
                        <Select fullWidth value={title.reviewPolicy || ''} onChange={(event)=>{
                                    setTitle({...title, reviewPolicy: event.target.value})
                        }}>
                            {[["허용 안함","notAllowed"], ["승인 후 게재","stopAndGo"], ["자동 승인","approveAll"]].map(([label, value])=> 
                                <MenuItem key={value} className={"MenuItem"} value={value}>{label}</MenuItem>
                            )}
                        </Select>
                    </Paper>
                    <h3 style={{paddingLeft: '0.5rem' }}>{"보고서 바로가기"}</h3>
                    <Paper variant={"outlined"} className={"VisibilityInfo"}>
                        <Select fullWidth value={title.reviewPolicy || ''} onChange={(event)=>{
                                    let [serviceId, titleId, episodeId, snapshotId] = (event.target.value || "").split(":")
                                    if (snapshotId){
                                        fn.goto(`/report/${serviceId}/${titleId}/${episodeId}/${snapshotId}`);
                                    }
                        }}>
                            <MenuItem key={""} className={"MenuItem"} value={""}>{"　"}</MenuItem>
                            {ads.map(ad => 
                                <MenuItem key={ad.adId} className={"MenuItem"} value={`${ad.serviceId}:${ad.titleId}:${ad.episodeId}:${ad.snapshotId}`}>{`${ad.episodeId}화 (${ad.snapshotId}호)`}</MenuItem>
                            )}
                        </Select>
                    </Paper>
                    {ss.getCurrentSession?<>
                        <h3 style={{paddingLeft: '0.5rem' }}>{"페이스북 캠페인 정보"}</h3>
                        <Paper variant={"outlined"} className={"VisibilityInfo"}>
                            <TextField fullWidth value={title.campaignId || ""} onChange={(event)=>{
                                setTitle({...title, campaignId: event.target.value});
                            }}/>
                        </Paper>
                    </>:<></>}
                </div>
                <div className={"Col"}>
                    <h3 style={{paddingLeft: '0.5rem' }}>{"제목, 소개글"}</h3>
                    <TextField
                        className={"TextField Title"} fullWidth variant={"outlined"}
                        label={"작품 제목"}
                        value={title.title || ''}
                        onChange={(event)=>{
                            setTitle({...title, title: event.target.value})
                        }}
                    />
                    <TextField
                        className={"TextField Description"} fullWidth variant={"outlined"}
                        label={"작품 설명"}
                        multiline rows={4}
                        value={title.description || ''}
                        onChange={(event)=>{
                            setTitle({...title, description: event.target.value})
                        }}
                    />
                    <div style={{display:"flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                        <TextField
                            className={"TextField Title"} variant={"outlined"}
                            label={"작가명"}
                            value={title.author || ''}
                            onChange={(event)=>{
                                setTitle({...title, author: event.target.value})
                            }}
                        />
                        <TextField
                            className={"TextField Title"} variant={"outlined"}
                            label={"이메일"}
                            value={title.email || ''}
                            onChange={(event)=>{
                                setTitle({...title, email: event.target.value})
                            }}
                        />
                    </div>
                    <TextField
                        className={"TextField Title"} fullWidth variant={"outlined"}
                        label={"태그"}
                        value={title.tags || ''}
                        onChange={(event)=>{
                            setTitle({...title, tags: event.target.value.replace(/ /g,'')})
                        }}
                    />
                    <h3 style={{paddingLeft: '0.5rem', marginBlockEnd: '0.5rem',}}>{"홍보용 컷(600 x 600, 8컷 이내)"}</h3>
                    <Paper variant={"outlined"} className={"Slides"}>
                        {title.slides && title.slides.length > 0
                            ?title.slides.map( (image,idx) =>
                                <EditableFileField
                                    key={idx}
                                    className={"SlideThumb"}
                                    defaultValue={image}
                                    update={updateSlideOfTitle}
                                    field={`${idx}`}
                                    isEditing={true}
                                    accept={".gif,.jpg,.jpeg,.png,.svg,.zip"}
                                />)
                            :<></>}
                            <EditableFileField
                                key={title.slides?title.slides.length:0}
                                className={"SlideThumb"}
                                defaultValue={''}
                                update={updateSlideOfTitle}
                                field={`${title.slides?title.slides.length:0}`}
                                isEditing={true}
                                accept={".gif,.jpg,.jpeg,.png,.svg,.zip"}
                            />
                    </Paper>
                    <h3 style={{paddingLeft: '0.5rem', marginBlockEnd: '0.5rem',}}>{"작성 권한 관리"}</h3>
                    <Paper variant={"outlined"} className={"Ownerships"}>
                        {ownershipList.map(ownership => <Chip key={ownership.userid} style={{marginRight: 5}}
                            avatar={
                                <Avatar>
                                    {ownership.authority==='owner'?<VpnKey className={"Avatar"}/>:
                                    ownership.authority==='write'?<Create className={"Avatar"}/>:
                                    ownership.authority==='read'?<Visibility className={"Avatar"}/>:<Help className={"Avatar"}/>
                                }
                                </Avatar>
                            } label={
                                <div className={"Label"}>
                                    <Avatar className={"UserAvatar"} src={(ownerProfiles[ownership.userid] || {}).image}/>
                                    <div className={"Text"}>{(ownerProfiles[ownership.userid] || {}).name || ownership.userid}</div>
                                </div>
                            } color={
                                ownership.authority==='owner'?'primary':
                                    ownership.authority==='write'?'secondary':
                                    ownership.authority==='read'?'default':'default'
                            } onDelete={()=>{
                                setAskRemoveOwnership(ownership)
                                setPendingDelteOwnership(ownership)
                            }}
                            />
                        )}
                        <IconButton
                            disabled={['owner'].indexOf(itemAuthority) < 0}
                            onClick={()=>{setAskAddOwnership(true)}}
                        ><Add style={{border:'1px lightgray solid', borderRadius: '100%'}}/></IconButton>
                    </Paper>
                </div>
            </div>
        
            <h2 style={{paddingLeft: '0.5rem', marginBlockEnd: '0.5rem'}}>{"에피소드"}</h2>
            <div style={{maxWidth: 1500, display: 'flex', justifyContent:'center', alignItems:'center'}}>
                <EditableTable
                    style={{overflowY: 'auto' }}
                    headerButtonHeaders={[
                        <Button
                            color={"primary"} variant={"contained"}
                            disabled={['owner', 'write'].indexOf(itemAuthority) < 0}
                            onClick={()=>setAskNewEpisodeId(true)} style={{padding:0}}>
                            {"추가"}
                        </Button>
                    ]}
                    columnHeaders={[ '썸네일', 'episodeId', '제목', '생성 일자', '본문편집']}
                    defaultSortCol={[1, 'asc']}
                    headerButtonsFunction={(row, index)=>[
                        {name: '삭제', color: 'secondary', props:{ style: {paddingTop:0, paddingBottom:0, marginLeft: 5}, fullWidth: true, disabled: ['owner', 'write'].indexOf(itemAuthority) < 0 }, onClick:async ()=>{
                            await deleteEpisode({
                                episode: {serviceId, titleId, ...row}
                            })
                            let newEpisodes = await readEpisodes(serviceId, titleId);
                            setEpisodes(newEpisodes);
                            
                        }}
                    ]}
                    columnsFunction={(row, index)=>[
                        {isEditing: true && ['owner', 'write'].indexOf(itemAuthority) >= 0, type: 'file', field: 'thumbnail', style: {maxWidth:200, maxHeight:150}, defaultValue: row.thumbnail || undefined},
                        {isEditing: false, type: 'string', field: 'episodeId', options:[], defaultValue: Number.isNaN(Number(row.episodeId))?row.episodeId:Number(row.episodeId)},
                        {isEditing: true && ['owner', 'write'].indexOf(itemAuthority) >= 0, type: 'string', field: 'title', options:[], defaultValue: row.title || undefined},
                        {isEditing: false, type: 'date', field: 'createdAt', options:[], defaultValue: row.createdAt},
                        {isEditing: false, type: 'dummy', field: 'gotoEpisodes', defaultValue: <Button fullWidth href={`/home/contents/${serviceId}/${titleId}/${row.episodeId}`} onClick={fn.gotoByAnchor}>{"이동"}</Button>},
                    ]}
                    updateGenerator={(row, index) => async (newValue) => {
                        await updateEpisode( {episode: {serviceId, titleId, ...row, ...newValue}} );
                        for (const [key,value] of Object.entries(newValue)){
                            row[key] = value;
                        }
                    }}
                    data={episodes}
                    name={"episodes"}

                />
            </div>
        </div>
        <Dialog open={askNewEpisodeId}>
            <DialogTitle>{"새 에피소드 코드를 입력해주세요."}</DialogTitle>
            <DialogContent>
                <div>
                    {"띄어쓰기 없이 영어대소문자, '_', '-', 숫자만을 이용해서 입력해주세요. 비워두시면 자동으로 코드를 생성합니다."}
                </div>
                <Input value={newEpisodeId} onChange={(event)=>{
                    let newValue = event.target.value
                    if (/[^0-9a-zA-Z\-_]/.exec(newValue)){
                        setErrorMessage("허용되지 않은 문자가 있습니다.")
                        return;
                    }
                    setErrorMessage("");
                    setNewEpisodeId(newValue);
                }} error={errorMessage !== ""} errormessage={errorMessage} />
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"primary"} onClick={()=>{
                    (async ()=>{
                        await createEpisode({
                            serviceId,
                            titleId,
                            episodeId: newEpisodeId,
                        });
                        let newEpisodes = await readEpisodes(serviceId, titleId);
                        setEpisodes(newEpisodes);
                    })();
                    setAskNewEpisodeId(false);
                }}>{"제출"}</Button>
                <Button color={"secondary"} onClick={()=>{
                    setNewEpisodeId("");
                    setErrorMessage("");
                    setAskNewEpisodeId(false);
                }}>{"취소"}</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={askDeleteTitle}>
            <DialogTitle>{"정말로 삭제하시겠어요?"}</DialogTitle>
            <DialogContent>
                <div>
                    {"한번 삭제하면 복구할수 없습니다!"}
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"primary"} onClick={()=>{
                    (async ()=>{
                        setIsLoading(true);
                        await deleteTitle(title);
                        fn.goto(`/home/contents/${serviceId}`);
                    })();
                    setAskDeleteTitle(false);
                }}>{"제출"}</Button>
                <Button color={"secondary"} onClick={()=>{
                    setAskDeleteTitle(false);
                }}>{"취소"}</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={askRemoveOwnership}>
            <DialogTitle>{"정말로 삭제하시겠어요?"}</DialogTitle>
            <DialogContent>
                <div>
                    <Avatar src={(ownerProfiles[pendingDeleteOwnership.userid] || {}).image}/>
                    {(ownerProfiles[pendingDeleteOwnership.userid] || {}).name}
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"primary"} onClick={()=>{
                    (async ()=>{
                        setIsLoading(true);
                        await deleteOwnership(pendingDeleteOwnership);
                        
                        let newOwnershipList = await readOwnerships(serviceId, titleId);

                        let newProfiles = await readProfile(newOwnershipList.map(row => row.userid).join(','))

                        setOwnershipList([...(ownershipList.filter(row => row.userid !== pendingDeleteOwnership.userid))])
                        setOwnerProfiles(
                            ((newProfiles instanceof Array)?newProfiles:[newProfiles]).map(row => {
                                let ret = {};
                                ret[row.userid] = row;
                                return ret;
                            }).reduce((a,b) => Object.assign(a,b),{})
                        )
                
                        setOwnershipList(newOwnershipList);
                        setIsLoading(false);
                    })();
                    setAskRemoveOwnership(false);
                }}>{"제출"}</Button>
                <Button color={"secondary"} onClick={()=>{
                    setAskRemoveOwnership(false);
                }}>{"취소"}</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={askAddOwnership}>
            <DialogTitle>{"작품 관리자 추가하기"}</DialogTitle>
            <DialogContent style={{display:'flex', flexDirection:'row', alignItems: 'flex-end'}}>
                <TextField label={"이메일"} value={pendingAddOwnershipEmail || ''} onChange={(event)=>setPendingAddOwnershipEmail(event.target.value)}></TextField>
                <Select label={"권한"} value={pendingAddOwnershipAuthority} onChange={(event)=>setPendingAddOwnershipAuthority(event.target.value)}>
                    <MenuItem value={"owner"}>{"소유자"}</MenuItem>
                    <MenuItem value={"write"}>{"수정권한"}</MenuItem>
                    <MenuItem value={"read"}>{"읽기권한"}</MenuItem>
                </Select>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"primary"} disabled={!pendingAddOwnershipEmail} onClick={()=>{
                    (async ()=>{
                        setIsLoading(true);
                        await createOwnership({serviceId, titleId, email: pendingAddOwnershipEmail, authority: pendingAddOwnershipAuthority});
                        
                        let newOwnershipList = await readOwnerships(serviceId, titleId);

                        let newProfiles = await readProfile(newOwnershipList.map(row => row.userid).join(','))

                        setOwnerProfiles(
                            ((newProfiles instanceof Array)?newProfiles:[newProfiles]).map(row => {
                                let ret = {};
                                ret[row.userid] = row;
                                return ret;
                            }).reduce((a,b) => Object.assign(a,b),{})
                        )
                
                        setPendingAddOwnershipAuthority('read');
                        setPendingAddOwnershipEmail(null);
                        setOwnershipList(newOwnershipList);
                        setIsLoading(false);
                    })();
                    setAskAddOwnership(false);
                }}>{"제출"}</Button>
                <Button color={"secondary"} onClick={()=>{
                    setAskAddOwnership(false);
                }}>{"취소"}</Button>
            </DialogActions>
        </Dialog>
        <LoadingCircle show={isLoading}/>
    </>);
}

export default EpisodeList;