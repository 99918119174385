import axios from "axios";
import ss from './Session';

export const readViral = async({query}) => {
    try{
        const result = await axios.post('https://challenge-api.webtoon.today/research/viral',{
            query: JSON.parse(query)
        }, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`,
                "Content-type": "application/json" 
            }
        });
    
        if(result.data && result.data.code === 200){
            return result.data.data;
        }else{
            return null;
        }
    }catch(e){
        console.error(e);
        return null;
    }
    
}

export const readCrawlList = async () => {
    try{
        const result = await axios.get('https://challenge-api.webtoon.today/research/viral/crawl', {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`,
                "Content-type": "application/json" 
            }
        });
    
        if(result.data && result.data.code === 200){
            return result.data.data;
        }else{
            return null;
        }
    }catch(e){
        console.error(e);
        return null;
    }
}

export const requestViralCollection = async ({query, channel}) => {
    const result = await axios.put('https://challenge-api.webtoon.today/research/viral', {
        query: JSON.parse(query), channel
    },{
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`,
            "Content-type": "application/json" 
        },
    });

    if(result.data && result.data.code === 200){
        return result.data.data;
    }else{
        return null;
    }
}