import React from "react";
import './AnalyticsBoard.scss';

import { Button } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PublishIcon from '@material-ui/icons/Publish';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import { Bar } from 'react-chartjs-2';

import TabPanel from './ChannelAnalytics/TabPanel';

const AnalyticsBoard = ({serviceId}) => {

    const onChange = (e) => {
        const img = e.target.files[0];
        const formData = new FormData();
        formData.append('file', img);
    }

    const initialData = {
        ViewData: {
            datasets: [
                {
                    data: [0, 0, 0, 0, 0],
                    lineTension: 0.1,
                    barPercentage: 1.0,
                    categoryPercentage: 1.0
                }
            ]
        },
        ViewOption: {
            responsive: true,
            maintainAspectRatio: false,
            plugins:{
                legend: {
                    display: false,
                },
            },
            scales: {
                y: {
                    display: false,
                    grid: {
                        display: false
                    },
                    ticks: {
                        stepSize: 1,
                        display: false,
                    }
                },
                x: {                    
                    grid: {
                        display: false
                    },
                    scaleLabel: {
                        display: false
                    },
                    ticks: {
                        display: false
                    }
                }
            }
        }
    }

    const ViewData = initialData.ViewData;
    const ViewOptions = initialData.ViewOption;

    const [tab, setTab] = React.useState(1);

    const add = () => {
        tab === 5 ? setTab(tab - 5 + 1):
        setTab(tab+1)
    }

    const subtract = () => {
        tab === 1 ? setTab(tab + 5 - 1):
        setTab(tab-1)
    }

    return(
        <div className={"District"}>
            <div className={"Area"}>
                <div className={"Header"}>
                    <div className={"HeadTitle"}>
                        {"Channel dashboard"}
                    </div>
                    <div className={"GroupIcons"}>
                        <PublishIcon className={"PublishIcon"} />
                        <RecordVoiceOverIcon className={"RecordVoiceOverIcon"} />
                    </div>
                </div>
                <div className={"Body"}>
                    <div className={"ContentBoxes"}>
                        <div className={"WhitePaper"}>
                            <div className={"ImgForm"}>
                                <div className={"SubText"}>
                                    {"Want to see metrics on your recent video?"}
                                </div>
                                <div className={"SubText"}>
                                    {"Upload and publish a video to get started."}
                                </div>
                                <div>
                                <label htmlFor={"InputFile"}>
                                    <Button className={"ColoredButton"} href="#text-buttons" color="primary" variant={"contained"}>{"UPLOAD VIDEOS"}</Button>
                                </label>
                                <input type={"file"}
                                    id={"InputFile"}
                                    onChange={onChange}
                                    style={{display: 'none'}}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"ContentBoxes"}>
                        <div className={"WhitePaper"}>
                            <div className={"PaperFactors"}>
                                <div className={"Title"}>{"Channel analytics"}</div>
                                <div className={"ContentText"}>{"Current subscribers"}</div>
                                <div className={"ViewCount"}>{"0"}</div>
                                <div className={"ViewChart"}>
                                    <Bar data={ViewData} options={ViewOptions} />
                                </div>
                            </div>
                            <div className={"PaperFactors"}>
                                <div className={"SubTitle"}>{"Summary"}</div>
                                <div className={"SubText"}>{"Last 28 days"}</div>
                                <div className={"Mesurements"}><div>{"Views"}</div><div>{"0 -"}</div></div>
                                <div className={"Mesurements"}><div>{"Watch time (hours)"}</div><div>{"0.0 -"}</div></div>
                            </div>
                            <div className={"DivisionBlock"}></div>
                            <div className={"PaperFactors"}>
                                <div className={"SubTitle"}>{"Top videos"}</div>
                                <div className={"SubText"}>{"Last 48 hours·Views"}</div>
                                <Button className={"ColoredButton"} href="#text-buttons" color="primary" variant={"contained"}>{"GO TO CHANNEL ANALYTICS"}</Button>
                            </div>
                        </div>
                    </div>
                    <div className={"ContentBoxes"}>
                        <div className={"WhitePaper"}>

                            
                                
                                <div className={"PaperFactors"}>
                                    <div className={"InsiderNavigation"}>
                                        <div className={"Title"}>{"Creator Insider"}</div>
                                        <div>
                                            <ChevronLeftIcon onClick={subtract}/>
                                            <ChevronRightIcon onClick={add}/>
                                        </div>
                                    </div>
                                    <TabPanel value={tab} index={1}>

                                        {/* 이미지 없어서 생성한 임시 div */}
                                        <div className={"img"} style={{height: 150}}></div>
                                        
                                        <div className={"VideoTitle"}>{"How Discovery Works: Part 2!"}</div>
                                        <div className={"VideoExplantaion"}>{"Hello Insiders! Welcome to Part 2 of this series covering how discovery works on YouTube"}</div>
                                        <Button className={"ColoredButton"} href="#text-buttons" color="primary" variant={"contained"}>{"WATCH ON YOUTUBE"}</Button>
                                    </TabPanel>
                                    <TabPanel value={tab} index={2}>
                                        2
                                    </TabPanel>
                                    <TabPanel value={tab} index={3}>
                                        3
                                    </TabPanel>
                                    <TabPanel value={tab} index={4}>
                                        4
                                    </TabPanel>
                                    <TabPanel value={tab} index={5}>
                                        5
                                    </TabPanel>
                                </div>

                        </div>
                        <div className={"WhitePaper"}>
                            <div className={"PaperFactors"}>
                                <div className={"Title"}>{"What's new in Studio"}</div>
                            </div>
                            <div className={"PaperFactors"}>
                                <div className={"ContentText"}>{"Customize your channel in Studio"}</div>
                            </div>
                            <div className={"DivisionBlock"}></div>
                            <div className={"PaperFactors"}>
                                <div className={"ContentText"}>{"Protect your privacy with new blurring features"}</div>
                            </div>
                            <div className={"DivisionBlock"}></div>
                            <div className={"PaperFactors"}>
                                <div className={"ContentText"}>{"See more Studio updates"}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"Footer"}>
                        <Button className={"AidButton"} href="#text-buttons" color="primary">{"Terms of use"}</Button>
                        <Button className={"AidButton"} href="#text-buttons" color="primary">{"Privacy policy"}</Button>
                        <Button className={"AidButton"} href="#text-buttons" color="primary">{"Policies & safety"}</Button>
                    
                </div>
            </div>
        </div>   

    )
}

export default AnalyticsBoard;