import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Select, TextField, Tooltip, Collapse, Menu, Icon } from '@material-ui/core';
import { ClearAll, Delete, FormatPaintOutlined, PlayForWork, Close, PhoneAndroid, MoreVert, Compare, ArrowRight, BurstMode } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';

import React from 'react';
import { createContents, createContentsFromChallenge, readContents, updateContents, getContentsAsZip, spreadZipOnS3 } from './Data/Contents';
import { readContentsStat } from './Data/ContentsStat';
import {createAd, deleteAd, listAds, publishFBAd} from './Data/Ad';

import ImageHolder from '../../../Component/ImageHolder';
import { NewReleases, Save } from '@material-ui/icons';

import {getOffsetInScreen, fn} from '../../../Functions';
import './Contents.scss';
import LoadingCircle from '../../../Component/LoadingCircle';
import Toast from '../../../Component/Toast';
import { sendBehaviorToChallenge } from '../../../Data/Behavior';
import { readFunnelStat } from './Data/FunnelStat';
import { getTitle } from './Data/Title';
import { useDispatch } from 'react-redux';
/**
 * 
 * @param {{
 *   serviceId: string,
 *   titleId: string,
 *   episodeId: string,
 *   snapshotId: string,
 *   mode: 'report' | 'edit'
 * }} param0 
 * @returns 
 */
const Contents = ({serviceId, titleId, episodeId, snapshotId, mode}) => {

    const setSnapshotId = (snapshotId) => {fn.goto(`/challenge/contents/${serviceId}/${titleId}/${episodeId}/${snapshotId}`)}

    const dispatch = useDispatch();
    const setNextSnapshotId = (newNextSnapshotId) => {
        setContents({
            ...contents, nextSnapshotId: newNextSnapshotId
        })
    }
    const setNextEpisodeId = (newNextEpisodeId) => {
        setContents({
            ...contents, nextEpisodeId: newNextEpisodeId
        })
    }

    const [isLoading, setIsLoading] = React.useState(false);

    const [contents, setContents] = React.useState({images:[], nextEpisodeId: '', nextSnapshotId: '', siblings: []});

    const [title, setTitle] = React.useState({});
    
    React.useEffect(()=>{
        document.title = `원고`;
        (async()=>{
            setIsLoading(true);
            let newTitle = await getTitle(serviceId, titleId, dispatch) || [{}];
            setTitle(newTitle[0] || {});
            setIsLoading(false);
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serviceId, titleId])

    React.useEffect(()=>{
        (async()=>{
            setIsLoading(true);
            if(!serviceId || !titleId || !episodeId){
                setContents({images:[], nextEpisodeId: '', nextSnapshotId: '', siblings: []});
                return;
            }

            let newContents = await readContents(serviceId, titleId, episodeId, snapshotId || undefined);
            if (newContents && newContents.id){
                setContents(newContents.data);
                if (!snapshotId){
                    let mouseEvent = document.createEvent('MouseEvent');
                    mouseEvent.initMouseEvent('mousedown', true, false);

                    if (document.querySelector('.SnapshotSelect div')){
                        document.querySelector('.SnapshotSelect div').dispatchEvent(mouseEvent);
                    }
                }

            }else {
                setContents({images:[], nextEpisodeId: '', nextSnapshotId: '', siblings: []});
            }
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serviceId, titleId, episodeId, snapshotId]);

    React.useEffect(()=>{
        let timer = null;
        
        if (contents.siblings !== undefined && contents.siblings !== null){
            setSnapshotIdCandidates(contents.siblings);
        }

        if (contents.updatedAt && contents.updatedAt + 60 > (new Date().getTime() / 1000)){
            setErrorMessage("컨텐츠가 로딩중이거나 갱신되고 있습니다. 1분에 한번씩 자동 새로고침됩니다.");
            setErrorOpen(true);
            let currSnapshotId = snapshotId + "";
            timer = setInterval(async ()=>{
                
                let newContents = await readContents(serviceId, titleId, episodeId, currSnapshotId);
                setContents(newContents.data);

                if (newContents.data.updatedAt + 60 > (new Date().getTime() / 1000)){
                    clearInterval(timer);
                }
            }, 1 * 60 * 1000)
        }else{
            setErrorOpen(false);
        }

        return ()=>{
            if (timer){
                clearTimeout(timer);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contents])
    
    const [churnData, setChurnData] = React.useState([
        1.0,
        0.9,
        0.95,
        0.7,
        0.6,
        0.3,
        0.2,
        0.15,
    ]);

    const [avgStayData, setAvgStayData] = React.useState([
        2.0,
        1.0,
        1.5,
        1.0,
        10.0,
        1.0,
        4.0,
    ]);

    const [, setMaxUserAmount] = React.useState(1);

    const [snapshotIdCandidates, setSnapshotIdCandidates] = React.useState([]);

    React.useEffect(()=>{

        (async ()=> {
            if (!contents || !contents.images){
                return;
            }
            let {serviceId, titleId, episodeId, snapshotId} = contents;
            let contentsStatDict = 
                (await readContentsStat({serviceId, titleId, episodeId, snapshotId}))
                .map(({amount, duration, cutId}) => {let ret = {}; ret[cutId] = {amount, duration}; return ret})
                .reduce( (a,b) => ({...a, ...b}), {});
                
            let maxUserAmount = Object.values(contentsStatDict).map(row => row.amount).reduce( (a,b) => a>b?a:b, 0);
            
            let contentsStat = 
                contents.images
                .map(url => !url?null:url.split('/').reverse()[0].split('.')[0])
                .map(cutId => contentsStatDict[cutId] || {amount: 0, duration: 0})
            console.log(contentsStat.map(row => row.amount * 1.0 / maxUserAmount))
            setMaxUserAmount(maxUserAmount);
            setChurnData(contentsStat.map(row => row.amount * 1.0 / maxUserAmount));
            setAvgStayData(contentsStat.map(row => row.duration))
            
        })();
        
    },[contents]);

    const [adId, setAdId] = React.useState("");
    React.useEffect(()=>{
        (async ()=>{
            let newAds = await listAds({serviceId, titleId, episodeId, snapshotId});
            if (newAds.length > 0){
                setAdId(newAds[0].adId);
            }else{
                setAdId("")
            }
        })();
    },[serviceId, titleId, episodeId, snapshotId]);

    const [scrollY, setScrollY] = React.useState(0);
    const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
    const [offsetData, setOffsetData] = React.useState([]);
    const [scrollToScreenRatio, setScrollToScreenRatio] = React.useState(1);
    const [chartHeight, setChartHeight] = React.useState(1);
    React.useEffect(()=>{
        setChartHeight(getOffsetInScreen(document.querySelector('.Chart')).height);
        setScrollToScreenRatio( (getOffsetInScreen(document.querySelector('.Chart')).height) / (getOffsetInScreen(document.querySelector('.Body')).height + innerHeight) );
    }, [innerHeight]);

    
    React.useEffect(()=>{
        const scrollListener = (event)=>{
            setScrollY(window.scrollY);
        }
        const resizeListener = ()=>{
            setInnerHeight(window.innerHeight);
        }
        document.addEventListener('scroll', scrollListener);
        document.addEventListener('resize', resizeListener);

        const refreshChart = ()=>{
            setScrollToScreenRatio( (getOffsetInScreen(document.querySelector('.Chart')).height) / (getOffsetInScreen(document.querySelector('.Body')).height + window.innerHeight) );
            setOffsetData([...document.querySelectorAll('.Body img')].map((dom,idx) => {
                let domrect = getOffsetInScreen(dom);
                return window.scrollY + domrect.top + domrect.height/2;
            }));
        };
        console.log([...document.querySelectorAll('.Body img')])

        const handle = setInterval(refreshChart, 1000);
        return ()=>{
            document.removeEventListener('scroll', scrollListener);
            document.removeEventListener('resize', resizeListener);

            clearInterval(handle);
        }
    },[]);

    const [funnelStats, setFunnelStats] = React.useState({});

    React.useEffect(()=>{
        (async()=>{
            let newFunnelStat = await readFunnelStat({serviceId, titleId, episodeId, snapshotId});
            setFunnelStats(newFunnelStat);
        })();
        
    },[serviceId, titleId, episodeId, snapshotId])

    const [newAdId, setNewAdId] = React.useState("");
    const [askNewAdId, setAskNewAdId] = React.useState(false);
    const [isCreatingAd, setIsCreatingAd] = React.useState(false);

    const [askChallengeSource, setAskChallengeSource] = React.useState(false);
    const [challengeSource, setChallengeSource] = React.useState("");

    const [creatingAdErrorMessage, setCreatingAdErrorMessage] = React.useState("");

    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorOpen, setErrorOpen] = React.useState(false);

    const [moreAnchorEl, setMoreAnchorEl] = React.useState(null);

    const [showDualView, setShowDualView] = React.useState(false);
    const [dualViewSnapshotId, setDualViewSnapshotId] = React.useState(null);
    const [dualViewContents, setDualViewContents] = React.useState({images:[], nextEpisodeId: '', nextSnapshotId: '', siblings: []});
    const [dualViewOffset, setDualviewOffset] = React.useState(0);
    
    React.useEffect(()=>{
        (async()=>{
            setIsLoading(true);
            if(!serviceId || !titleId || !episodeId){
                setDualViewContents({images:[], nextEpisodeId: '', nextSnapshotId: '', siblings: []});
                return;
            }

            let newContents = await readContents(serviceId, titleId, episodeId, dualViewSnapshotId || undefined);
            if (newContents && newContents.id){
                setDualViewContents(newContents.data);
            }else {
                setDualViewContents({images:[], nextEpisodeId: '', nextSnapshotId: '', siblings: []});
            }
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serviceId, titleId, episodeId, dualViewSnapshotId]);

    return (<div className={"ContentsHome ReportMode"}>
        <div className={`Body ${showDualView?'Wide':''}`}>
            {mode === 'report'
            ?!contents || !contents.images || contents.images.length === 0
                ?<div></div>
                :(contents.images || []).map((img,idx) =>
                    <img key={idx} src={img}
                        alt={`${idx}`}
                    />)
            :<div className={"ImageViewVert"}>
                <div className={"MainImage"}>
                    {!contents || !contents.images || contents.images.length === 0
                    ?<ImageHolder
                        accept={".jpg,.jpeg,.gif,.png,.svg,.zip"}
                        img={null}
                        field={"image"}
                        update={async (newValue)=>{
                            
                            if (newValue.image && newValue.image.endsWith('.zip')){
                                let currentSnapshotId = snapshotId;
                                if (!snapshotId){
                                    let newContentsKey = await createContents({contents: {
                                        serviceId, titleId, episodeId, data: {
                                            images: [newValue.image]
                                        }
                                    }})
                                    setSnapshotId(newContentsKey.split(":").reverse()[0]);
                                    currentSnapshotId = newContentsKey.split(":").reverse()[0]
                                }
                                try {
                                    let images = await spreadZipOnS3(newValue.image);
                                    await updateContents({contents: {
                                        serviceId, titleId, episodeId, snapshotId, data: {
                                            ...contents, images, updatedAt: (new Date()).getTime()/1000
                                        }
                                    }});
                                    setContents({...contents, images, updatedAt: (new Date()).getTime()/1000})
                                    sendBehaviorToChallenge('create-contents', {serviceId, titleId, episodeId, snapshotId});
                                    
                                }catch(e){
                                    let newContents = await readContents(serviceId, titleId, episodeId, currentSnapshotId);
                                    setContents(newContents.data);
                                    return;
                                }
                            }else{
                                setContents({...contents, images: [newValue.image]})
                                if (snapshotId){
                                    await updateContents({contents: {
                                        serviceId, titleId, episodeId, snapshotId, data: {
                                            images: [newValue.image]
                                        }
                                    }});
                                }else{
                                    let newContentsKey = await createContents({contents: {
                                        serviceId, titleId, episodeId, data: {
                                            images: [newValue.image]
                                        }
                                    }})
        
                                    setSnapshotId(newContentsKey.split(":").reverse()[0]);
                                }
                            }
                        }}
                    />
                    :(contents.images || []).map((img,idx) =>
                        <ImageHolder
                            key={`img_${idx}`}
                            img={img}
                            insertTopCallback={()=>{
                                let newContents = { ...contents, images: [
                                    ...(contents.images.slice(0,idx)),
                                    null,
                                    ...(contents.images.slice(idx))
                                ]};
                                setContents(newContents);
                            }}
                            insertBottomCallback={()=>{
                                let newContents = { ...contents, images: [
                                    ...(contents.images.slice(0,idx+1)),
                                    null,
                                    ...(contents.images.slice(idx+1))
                                ]};
                                setContents(newContents);
                            }}
                            update={(newValue)=>{
                                if (newValue.image === null){
                                    let newContents = { ...contents, images: [
                                        ...(contents.images.slice(0,idx)),
                                        ...(contents.images.slice(idx+1))
                                    ]};
                                    setContents(newContents);
                                }else{
                                    (async ()=>{
                                        let newContents = { ...contents, images: [
                                            ...(contents.images.slice(0,idx)),
                                            newValue.image,
                                            ...(contents.images.slice(idx+1))
                                        ]};
                                        setContents(newContents);
                                    })()
                                }
                            }}
                        />)}
                </div>
                {showDualView?
                <div className={"SubImage"} style={{marginTop: Number(dualViewOffset)}}>
                    {(dualViewContents.images || []).map((img,idx) =>
                    <img key={idx} src={img}
                        alt={`${idx}`}
                    />)}
                </div>
                :<></>}
            </div>}
            {mode === 'report'
                ?<div
                    className={`Chart `}
                    onClick={(event)=>{
                        window.scrollTo(0,(event.clientY-64) / scrollToScreenRatio + offsetData[0]);
                    }} onMouseMove={(event)=>{
                        if (event.buttons === 1){
                            window.scrollTo(0,(event.clientY-64) / scrollToScreenRatio + offsetData[0]);
                        }
                    }}
                >
                    <div className={"Churn"}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 150 ${chartHeight}`}>
                            <polyline
                                points={churnData.map((point,index) => 
                                    `${point * 134},${(offsetData[index] - offsetData[0]) * scrollToScreenRatio || 0}`
                                ).join('\n')}
                                stroke={"rgba(244,20,20,0.5)"} strokeWidth={"3"} fill={"none"}
                            />
                        </svg>
                    </div>
                    <div className={"Churn"}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 150 ${chartHeight}`}>
                            <polyline
                                points={ `0,${(scrollY - offsetData[0]) *scrollToScreenRatio || 0} `
                                        +`166,${(scrollY - offsetData[0]) *scrollToScreenRatio || 0} `
                                        +`166,${(scrollY+window.innerHeight - offsetData[0]) *scrollToScreenRatio || 0} `
                                        +`0,${(scrollY+window.innerHeight - offsetData[0]) *scrollToScreenRatio || 0}`
                                }
                                stroke={"none"} strokeWidth={"2"} fill={"rgba(20,244,244,0.5)"}
                            />
                        </svg>
                    </div>
                    <div className={"AvgStay"}>
                        {avgStayData.map((point,index)=>
                            <Tooltip key={index} title={`(컷 ${index+1}, ${point} 초)`}><div className={"Bar"} style={{
                                top: (offsetData[index] - offsetData[0]) * scrollToScreenRatio || 0, width: `${Math.min(10, point) * 10}%`,
                            }}></div></Tooltip>
                        )}
                    </div>
                    <div className={"Grid"}>

                    </div>
                </div>
                :<></>}
            {mode === 'report' && contents && contents.nextEpisodeId && contents.nextSnapshotId
            ?<div className={"NextEpButton"}>
                <Button variant={"outlined"} style={{backgroundColor:'white'}} onClick={()=>{
                    fn.goto(['/challenge/report',serviceId,titleId,contents.nextEpisodeId,contents.nextSnapshotId].join('/'))
                }}><ArrowRight/>{"다음화로 이동"}</Button>
            </div>:<></>}
            <div className={"Score"}>
                <Select
                    className={"SnapshotSelect"}
                    value={snapshotId}
                    fullWidth
                    onChange={event => setSnapshotId(event.target.value)}
                >
                    {snapshotIdCandidates.map(snapshotIdCandi => <MenuItem key={snapshotIdCandi} value={snapshotIdCandi}>{snapshotIdCandi}</MenuItem>)}
                </Select>
                {showDualView?
                <div className={"DualSnapshotSelectContianer"}>
                    {"듀얼 뷰"}
                    <Select
                        className={"DualSnapshotSelect"}
                        value={dualViewSnapshotId}
                        onChange={event => setDualViewSnapshotId(event.target.value)}
                    >
                        {snapshotIdCandidates.map(snapshotIdCandi => <MenuItem key={snapshotIdCandi} value={snapshotIdCandi}>{snapshotIdCandi}</MenuItem>)}
                    </Select>
                    {"오프셋"}
                    <TextField
                        style={{width:30}}
                        value={dualViewOffset} onChange={(event) =>{
                            if (/[^\-0-9]/.exec(event.target.value)){
                                return;
                            }
                            setDualviewOffset(event.target.value);
                        }}
                    ></TextField>
                </div>
                :<></>}
                {mode === 'report'
                ?<div>
                    <IconButton className={"New"}
                        onClick={()=>{
                            fn.goto(['/challenge/contents',serviceId,titleId,episodeId,snapshotId].join('/')+'#edit')
                        }}
                    >
                        <FormatPaintOutlined style={{}}/>
                    </IconButton>
                    {`${Math.floor(Object.values(funnelStats.contentReadAggregation || {}).map(row => row.fullReadCount).reduce((a,b) => a+b,0)
                        / Object.values(funnelStats.contentReadAggregation || {}).map(row => row.readCount).reduce((a,b) => a+b,0) * 10000) / 100
                    }% (/ ${Object.values(funnelStats.contentReadAggregation || {}).map(row => row.readCount).reduce((a,b) => a+b,0)})`}
                </div>
                :<div>
                    <div>
                        <TextField style={{width: 60}} value={contents.nextEpisodeId || ""} label={"다음화"} onChange={(event)=>{setNextEpisodeId(event.target.value)}}></TextField>
                        <TextField style={{width: 100}} value={contents.nextSnapshotId || ""} label={"다음화스냅샷"} onChange={(event)=>{setNextSnapshotId(event.target.value)}}></TextField>
                        {adId
                        ?<TextField style={{width: 60}} label={
                            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                {"광고ID"}<IconButton style={{padding: 0}} onClick={()=>{
                                    deleteAd({serviceId, titleId, episodeId, snapshotId, adId})
                                    setAdId("")
                                }}><Delete style={{fontSize: '1rem'}}/></IconButton>
                            </div>
                        } value={adId}/>
                        :<><Button style={{marginTop: 12}} onClick={()=>{
                            setAskNewAdId(true);
                        }}>{"광고 연결"}</Button></>}
                    </div>
                    <div>
                        <Tooltip title={"전체 지우기"}>
                            <IconButton className={"New"}
                                onClick={()=>{
                                    setContents({images: [], nextEpisodeId: '', nextSnapshotId: '', siblings: []})
                                }}
                            >
                                <ClearAll style={{}}/>
                            </IconButton>
                            </Tooltip>
                        <Tooltip title={"새로운 스냅샷"}>
                            <IconButton className={"New"}
                                onClick={()=>{
                                    (async()=>{
                                        setIsLoading(true)
                                        let resp = await createContents({
                                            contents: {
                                                serviceId, titleId, episodeId,
                                                data: {images: contents.images.filter(row => row !== null)}
                                            }
                                        });
                                        setSnapshotId(resp.split(":").reverse()[0])
                                        setIsLoading(false)
                                    })()
                                }}
                            >
                                <NewReleases style={{}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"변경사항 저장하기"}>
                            <IconButton className={"Save"}
                                onClick={()=>{
                                    updateContents({
                                        contents: {
                                            serviceId, titleId, episodeId, snapshotId,
                                            data: { ...contents, images: contents.images.filter(row => row !== null) },
                                            
                                        }
                                    })
                                }}
                            >
                                <Save style={{}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"도전만화에서 가져오기"}>
                            <IconButton className={"New"}
                                onClick={()=>{
                                    setAskChallengeSource(true);
                                }}
                            >
                                <PhoneAndroid/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"더보기"}>
                            <IconButton className={"New"} onClick={(event)=> setMoreAnchorEl(event.target)}>
                                <MoreVert/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={moreAnchorEl}
                            keepMounted
                            open={Boolean(moreAnchorEl)}
                            onClose={()=>setMoreAnchorEl(null)}
                        >
                            <MenuItem style={showDualView?{backgroundColor:'lightgray'}:{}}
                                onClick={()=>{
                                    setMoreAnchorEl(null)
                                    setShowDualView(!showDualView)}
                                }
                            >
                                <Compare/>
                                {"듀얼 뷰"}
                            </MenuItem>
                            <MenuItem
                                onClick={()=>{
                                    setMoreAnchorEl(null)
                                    sendBehaviorToChallenge('create-contents', {serviceId, titleId, episodeId, snapshotId});
                                }}
                            >
                                <BurstMode/>
                                {"이미지 자르기(비동기)"}
                            </MenuItem>
                            {contents && contents.images
                            ?<MenuItem
                                onClick={()=>{
                                    setMoreAnchorEl(null);
                                    (async()=>{
                                        let zip = await getContentsAsZip(contents.images);
                                        fn.goto(zip);
                                    })();
                                }}
                            >
                                <PlayForWork style={{}}/>
                                {"압축파일 다운로드"}
                            </MenuItem>
                            :<div></div>}
                            {contents && contents.nextEpisodeId && contents.nextSnapshotId
                            ?<MenuItem
                                onClick={()=>{
                                    fn.goto(['/challenge/contents',serviceId,titleId,contents.nextEpisodeId,contents.nextSnapshotId].join('/'))
                                }}
                            >
                                <ArrowRight style={{}}/>
                                {"다음화"}
                            </MenuItem>
                            :<div></div>}
                            <MenuItem><Icon/>{""}</MenuItem>
                        </Menu>
                    </div>
                </div>}
            </div>            
        </div>
        <Dialog open={askNewAdId}>
            <DialogTitle>{"광고ID를 입력해주세요."}</DialogTitle>
            <DialogContent>
                <Collapse in={creatingAdErrorMessage.length > 0}>
                    <Alert severity="error" action={<IconButton size={"small"} onClick={()=>setCreatingAdErrorMessage("")}><Close/></IconButton>}>{creatingAdErrorMessage}</Alert>
                    <br/>
                </Collapse>
                <div style={{display: 'flex', alignItems: 'center'}}>{"새로 만들기"}
                    <Button disabled={isCreatingAd} variant={"outlined"} style={{width: 180, marginLeft: 20}} onClick={()=>{
                        (async ()=>{
                            setIsCreatingAd(true);
                            setIsLoading(true);
                            try{
                                setCreatingAdErrorMessage("광고를 만들고 있습니다. 최대 1분까지 소요됩니다.");
                                let newFBAdId = await publishFBAd({serviceId, titleId, episodeId, snapshotId, campaignId: title.campaignId});
                                await createAd({serviceId, titleId, episodeId, snapshotId, adId: newFBAdId});
                                setAdId(newFBAdId);
                                setAskNewAdId(false);
                                setCreatingAdErrorMessage("");
                            }catch(e){
                                setCreatingAdErrorMessage(((e.response || {}).data || {}).message || "알 수 없는 오류");
                                console.error(e);
                                setIsCreatingAd(false);
                            }
                            setNewAdId("");
                            setIsLoading(false);
                            setIsCreatingAd(false);
                        })();
                    }}>{"광고 생성"}</Button>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {"생성된 코드"}
                    <TextField disabled={isCreatingAd} style={{width: 180, marginLeft: 20}} value={newAdId} onChange={(event) => setNewAdId(event.target.value)}></TextField>
                </div>
                
            </DialogContent>
            <DialogActions>
                <Button disabled={isCreatingAd} variant={"contained"} color={"primary"} onClick={()=>{
                    (async ()=>{
                        setIsLoading(true);
                        await createAd({serviceId, titleId, episodeId, snapshotId, adId: newAdId});
                        setAdId(newAdId);
                        setNewAdId("");
                        setIsLoading(false);
                    })();
                    setAskNewAdId(false);
                }}>{"제출"}</Button>
                <Button disabled={isCreatingAd} color={"secondary"} onClick={()=>{
                    setNewAdId("");
                    setAskNewAdId(false);
                }}>{"취소"}</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={askChallengeSource}>
            <DialogTitle>{"도전만화 주소를 입력해 주세요."}</DialogTitle>
            <DialogContent>                
                <TextField fullWidth value={challengeSource} onChange={(event)=>{setChallengeSource(event.target.value)}}/>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} onClick={()=>{
                    (async ()=>{
                        setIsLoading(true);
                        setAskChallengeSource(false);
                        let images = await createContentsFromChallenge({serviceId, titleId, episodeId, source: challengeSource});
                        let currentSnapshotId = snapshotId;
                        if (!snapshotId){
                            let newContentsKey = await createContents({contents: {
                                serviceId, titleId, episodeId, data: {
                                    images
                                }
                            }})
                            setSnapshotId(newContentsKey.split(":").reverse()[0]);
                            currentSnapshotId = newContentsKey.split(":").reverse()[0]
                        }else {
                            await updateContents({contents: {
                                serviceId, titleId, episodeId, snapshotId, data: {
                                    images
                                }
                            }})
                        }
                        setContents({...contents, images, updatedAt: (new Date()).getTime()/1000});
                        setChallengeSource("");
                        setIsLoading(false);
                        
                        sendBehaviorToChallenge('create-contents', {serviceId, titleId, episodeId, snapshotId: currentSnapshotId});
                        
                    })();
                }}>{"가져오기"}</Button>
                <Button color={"secondary"} onClick={()=>{
                    setChallengeSource("");
                    setAskChallengeSource(false);
                }}>{"취소"}</Button>
            </DialogActions>
        </Dialog>
        <Toast
            isOpen={errorOpen}
            message={errorMessage}
            windowSize={{width: 400, height: 500}}
            animationType={"up"}
            onClose={()=>{setErrorOpen(false)}}
        />
        <LoadingCircle show={isLoading}/>
    </div>);
}

export default Contents;