import { Button } from '@material-ui/core';
import React from 'react';
import { fn } from '../Functions';

import './Scholarship.scss';

const Scholarship = (props) => {


    return (<div className={"Scholarship"}>
        <img className={"Img"} src={"https://static.webtoon.today/static_page/scholarship.jpg"} alt={"scholarship"}/>
        <Button className={"Btn"} color={"primary"} href={"https://form.asana.com/?k=OFBHDmszKxcUDKZKZDaBUg&d=1199978600895170"} onClick={fn.gotoByAnchor} variant={"contained"}>{"지원하러 가기"}</Button>
    </div>);
}

export default Scholarship;