import React from 'react';
import './AllNotesOfTitleView.scss';
import { fn } from '../../Functions'
import moment from 'moment-timezone';
import { AddCircleOutline, CheckCircleOutline } from '@material-ui/icons';
import { IconButton, Dialog, DialogTitle, DialogActions, DialogContent, Button, TextField } from '@material-ui/core';
import { createNote, readLatestNotes } from '../../Data/TitleNote';
import { getTitle, getTitles } from '../../Data/Title';

const Progress = ({ serviceId, titleId }) => {
    
    const [notes, setNotes] = React.useState([]);

    const [titles, setTitles] = React.useState([]);

    const [titleTable, setTitleTable] = React.useState([])

    React.useEffect(()=>{

        ( async () => {
            
            const notesResult = await readLatestNotes();

            setNotes(notesResult)

        })()

    },[])
    
    React.useEffect(() => {

        ( async () => {
            
            const keys = [];

            for (let note of notes) {
                keys.push([note.serviceId, note.titleId])
            }
            
            const titleResult = await getTitles(keys)
            
            setTitles(titleResult)
            
        })()

    },[notes])

    React.useEffect(()=>{

        const newTable = {}

        for (let title of titles) {
            newTable[`${title.serviceId}:${title.titleId}`] = title.title
        }

        setTitleTable(newTable)
        
    },[titles])

    const [ pendingServiceId, setPendingServiceId ] = React.useState(null)
    const [ pendingTitleId, setPendingTitleId ] = React.useState(null)

    const [ newNote, setNewNote ] = React.useState(null)
    
    const [ isOpened, setIsOpened ] = React.useState(false);
    const [ editingTitles, setEditingTitles ] = React.useState([]);
    
    const pendingCleaning = () => {

        setPendingServiceId(null);
        setPendingTitleId(null);
    }

    const handleClickOpen = () => {

        setIsOpened(true);
    };
  
    const handleClose = () => {

        setIsOpened(false);
        pendingCleaning()
    };

    const handleAddRow = async () => {

        const checking = await getTitle(pendingServiceId, pendingTitleId)

        const matchedCounter = notes.filter(note => `${note.serviceId}:${note.titleId}` === `${pendingServiceId}:${pendingTitleId}`).length

        if ( matchedCounter !== 0 ) {

            alert('이미 해당 작품이 추가되어 있습니다.')

        }else if (checking[0].serviceId === pendingServiceId && checking[0].titleId === pendingTitleId && matchedCounter === 0){
                
            await createNote({serviceId: pendingServiceId, titleId: pendingTitleId, note: `작품이 추가되었습니다.`})
            setIsOpened(false);
            pendingCleaning()

        } else {
            alert('입력값을 다시한번 확인해주세요')
        }
        
    }
    
    return(
        <div className={"ProgressArea"}>
            <div className={"ProgressInner"}>
                <div className={'ProgressContainer'}>
                    <table style={{width: '100%'}}>
                        <tbody style={{width: '100%'}}>
                            <tr className={'StandardRow'}>
                                <td className={'Webtoon'}>
                                    {'작품 목록'}
                                </td>
                                <td className={'EmptyCell'}></td>
                                <td className={'Progress'}>
                                    {'진행 사항'}
                                </td>
                            </tr>
                            {notes
                            .filter(webtoon => titleTable[`${webtoon.serviceId}:${webtoon.titleId}`])
                            .map((webtoon, index) => 
                                <tr className={'ProgressRow'} key={webtoon.titleId}
                                >
                                    <td className={'Webtoon'} onClick={ async () => {
                                        fn.goto(['/home', 'progress', webtoon.serviceId, webtoon.titleId].join('/'))
                                    }} >
                                        {titleTable[`${webtoon.serviceId}:${webtoon.titleId}`]}
                                    </td>
                                    <td></td>
                                    <td className={'Progress'}>
                                        <div className={'ProgressTexts'}>
                                            <div className={'CommentRow'}>
                                                {!editingTitles.includes(`${webtoon.serviceId}:${webtoon.titleId}`)
                                                ?<div className={'NoteCell'}>
                                                    <span className={'PresentText'}>{webtoon.note}</span>
                                                    <IconButton className={`AddNoteButton Top`} onClick={ () => setEditingTitles(editingTitles.concat(`${webtoon.serviceId}:${webtoon.titleId}`)) }>
                                                        <AddCircleOutline style={{padding: '0px'}} />
                                                    </IconButton>
                                                </div>
                                                :<div className={'NoteCell'}>
                                                    <span className={'PreviousText'}>{webtoon.note}</span>
                                                    <div>
                                                        <TextField fullWidth onChange={ (e) => setNewNote(e.target.value) } />
                                                        <IconButton className={'AddNoteButton Bottom'} onClick={ async () => {

                                                            let result;

                                                            if (newNote) {
                                                                result = await createNote({
                                                                    serviceId: webtoon.serviceId,
                                                                    titleId: webtoon.titleId,
                                                                    note: newNote
                                                                })

                                                            } else {
                                                                setEditingTitles(editingTitles.filter(ele => ele !== `${webtoon.serviceId}:${webtoon.titleId}`))
                                                                return;
                                                            }
                                                            
                                                            if (result){
                                                                setEditingTitles(editingTitles.filter(ele => ele !== `${webtoon.serviceId}:${webtoon.titleId}`))
                                                                setNewNote(null)
                                                                
                                                            } else {
                                                                alert('전송이 실패하였습니다.')
                                                            }
                                                        
                                                        }} >
                                                            <CheckCircleOutline style={{padding: '0px'}} />
                                                        </IconButton>
                                                    </div>
                                                </div>}
                                                <div className={'TimeCell'} >
                                                    <span className={'Time'}>{moment(webtoon.createdAt).format('YY.MM.DD HH:mm')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )}
                            <tr style={{width: '100%'}}>
                                <td style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '30px 10px 10px 10px'}}>
                                    <IconButton onClick={handleClickOpen}>
                                        <AddCircleOutline fontSize={'large'} style={{color: 'lightgray'}} />
                                    </IconButton>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Dialog
              open={isOpened}
              onClose={handleClose}
            >
              <DialogTitle>{"추가할 작품의 정보를 입력해주세요."}</DialogTitle>
              <DialogContent>
                    <div style={{margin: '0 10px'}}>
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                            <div style={{width: '90px'}}>{'serviceId'}</div>
                            <TextField value={pendingServiceId || ""} onChange={(e) => setPendingServiceId(e.target.value)} />
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                            <div style={{width: '90px'}}>{'titleId'}</div>
                            <TextField value={pendingTitleId || ""} onChange={(e) => setPendingTitleId(e.target.value)} />
                        </div>
                  </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary" variant={'contained'} >
                  {'취소'}
                </Button>
                <Button onClick={handleAddRow} color="primary" variant={'contained'} autoFocus >
                  {'추가'}
                </Button>
              </DialogActions>
            </Dialog>

        </div>
    )
}

export default Progress;