import React from 'react';

import './NoticeMenuBar.scss';

import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { fn } from '../../Functions';

const MenuBar = () => {


    
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={'MenuBar'}>
            <div className={`SubmitHeader`}>
                <div className={"HeadInner"}>
                    <div className={"BarContentArea"}>
                        <a onClick={fn.gotoByAnchor} href={"/"} className={"HashMover"}>
                            <img src={"https://static.webtoon.today/ddah/logo-01.png"} alt={"logo"} />
                        </a>
                        <div className={"Informations"}>
                            <div className={"Information"} >
                                <a href="/landing#Home" className={"HashMover"}>{"Webtoon Analytics"}</a>
                            </div>
                            <div className={"Information"} >
                                <a href="/landing#PostScript" className={"HashMover"}>{"고객 사례"}</a>
                            </div>
                            <div className={"Information"} >
                                <a href='/landing#Member' className={"HashMover"}>{"팀 소개"}</a>
                            </div>
                            <div className={"Information"} >
                                <a href="/landing#Submit" className={"HashMover"}>{"데이터 분석 신청"}</a>
                            </div>
                            <div className={"Information"} >
                                <a href="/landing#QnA" className={"HashMover"}>{"자주 묻는 질문"}</a>
                            </div>
                        </div>
                        <div className={"InformationMenu"}>
                            <IconButton onClick={handleClick} className={"HashMover"}>
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem className={"Information"} onClick={()=>{
                                    handleClose();
                                    fn.goto('/landing#Home')
                                }}>
                                    <span className={"HashMover"}>{"Webtoon Analytics"}</span>
                                </MenuItem>
                                <MenuItem className={"Information"} onClick={()=>{
                                    handleClose();
                                    fn.goto('/landing#PostScript')
                                }}>
                                    <span href="#PostScript" className={"HashMover"} >{"고객 사례"}</span>
                                </MenuItem>
                                <MenuItem className={"Information"} onClick={()=>{
                                    handleClose();
                                    fn.goto('/landing#Member')
                                }}>
                                    <span href="#Member" className={"HashMover"} >{"팀 소개"}</span>
                                </MenuItem>
                                <MenuItem className={"Information"} onClick={()=>{
                                    handleClose();
                                    fn.goto('/landing#Submit')
                                }}>
                                    <span href="#Submit" className={"HashMover"}>{"데이터 분석 신청"}</span>
                                </MenuItem>
                                <MenuItem className={"Information"} onClick={()=>{
                                    handleClose();
                                    fn.goto('/landing#QnA')
                                }}>
                                    <span className={"HashMover"} >{"자주 묻는 질문"}</span>
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );

}

export default MenuBar;


