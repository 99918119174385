import React from 'react';
import {BranchFunction, fn} from './Functions';
import {Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button} from '@material-ui/core';

import Service from './Page/Service';
import Home from './Page/Home';
import Endpage from './Page/Endpage';
import Detail from './Page/Detail';
import Setting from './Page/Setting';

import AnalyticsHome from './Page/Analytics/Home';
import Scholarship from './Page/Scholarship';
import Consent from './Page/Consent';
import Report from './Page/Report';
import ReportWOContent from './Page/ReportWOContent';
import PrivacyPolicy from './Page/PrivacyPolicy';
import PrivacyPolicyKr from './Page/PrivacyPolicyKr';

import Inquiry from './Page/Landing/Inquiry';
import Survey from './Page/Survey';

import {logBehavior} from './Data/Behavior';
import ss from './Data/Session';
import SurveyView from './Page/SurveyView';
import KakaoTalkChat from './Page/Template/KakaoTalkChat';
import KakaoTalkList from './Page/Template/KakaoTalkList';
import NoticeList from './Page/Notice/NoticeList';
import TOU_20220201 from './Page/TermsOfUseKr/20220201';
import TOU_20230726 from './Page/TermsOfUseKr/20230726';
import TOU_20231010 from './Page/TermsOfUseKr/20231010';

import NotFound from './Page/NotFound';
import PreLaunchPage from './Page/PreLaunchPage/PreLaunchPage';

import FinishNote from './Page/challenge/FinishNote';
import ChallengeAgreeResult from './Page/challenge/SurveyView';
import PaymentPolicy from './Page/PaymentPolicy';
import Landing from './Page/Landing/Landing';
import Introduction from './Page/Introduction/Introduction';

/** @typedef {{width: number, height: number}} size */
/** @typedef {{x: number, y: number}} point */
/**
 * @template T
 * @typedef {React.Dispatch<React.SetStateAction<T>>} set<T>
 */

 /**
  * @typedef {import('./App').globalDataType & { 
  *   goto: (urlOrPath: string) => void,
  *   path: string,
  * }} routingDataType
  * */

 /**
  * 
  * @param {string} path 
  * @param {routingDataType} newProps 
  * @returns {() => JSX.Element}
  */
const jsxBranch = (path, newProps, query) => BranchFunction(
    path.toLowerCase(),
    [
        {
            validator: (path) => path === "/",
            defaultValue: () => <Introduction />,
        },
        {
            validator: (path) => path.startsWith("/setting"),
            defaultValue: () => <Setting/>,
        },
        {
            validator: (path) => path.startsWith("/detail"),
            sublogic:[
                {
                    validator: (path) => {
                        // eslint-disable-next-line no-unused-vars
                        let [,serviceId, titleId] = /detail\/?([^/]+)?\/?([^/]+)?/.exec(path);

                        if (['naver', 'kakao', 'lezhin', 'daum', 'toomics', 'toptoon', 'comico'].indexOf(serviceId) >= 0){
                            return true;
                        }else if (!serviceId){
                            return true;
                        }
                    },
                    defaultValue: () => {
                        return <Home {...newProps}/>;
                    },
                },
                {
                    validator: (path) => /detail\/([^/]+)\/([^/]+)/.exec(path),
                    sublogic:[
                        {
                            validator: (path) => /detail\/([^/]+)\/([^/]+)\/([^/]+)\/([^/]+)/.exec(path),
                            defaultValue: () => {
                                let [,serviceId, titleId, , episodeId, snapshotId] = /detail\/([^/]+)\/([^/]+)\/([^/]+)\/([^/]+)\/?([^/]+)?/.exec(path);
                                return <Endpage {...newProps} serviceId={serviceId} titleId={titleId} episodeId={episodeId} snapshotId={snapshotId}/>
                            }
                        }
                    ],
                    defaultValue: () => {
                        let [,serviceId, titleId] = /detail\/([^/]+)\/([^/]+)/.exec(path);
                        return <Detail {...newProps} serviceId={serviceId} titleId={titleId}/>
                    },
                },
            ],
            defaultValue: () => <Service {...newProps}/>,
        },
        {
            validator: (path) => /view\/([^/]+)\/([^/]+)\/([^/]+)/.exec(path),
            defaultValue: () => {
                let [,serviceId, titleId, episodeId, snapshotId] = /view\/([^/]+)\/([^/]+)\/([^/]+)\/?([^/]+)?/.exec(path);
                return <Endpage {...newProps} serviceId={serviceId} titleId={titleId} episodeId={episodeId} snapshotId={snapshotId}/>
            }
        },
        {
            validator: (path) => path.startsWith("/home/surveyresult"),
            defaultValue: () => {
                let [[endPage='', surveyId=''], hash = ''] = (([uri,hash])=>[uri.split('/'), hash])(path.replace(/^\/home\/?/,'').split('#'))
                hash = hash || window.location.hash.replace('#','');
                return <SurveyView {...{endPage, surveyId, hash}}></SurveyView>
            }
        },
        {
            validator: (path) => path.startsWith("/home/challengeagreeresult"),
            defaultValue: () => {
                let [[endPage='', ], hash = ''] = (([uri,hash])=>[uri.split('/'), hash])(path.replace(/^\/home\/?/,'').split('#'))
                hash = hash || window.location.hash.replace('#','');
                return <ChallengeAgreeResult {...{endPage, surveyId: "challengeAgree", hash}}></ChallengeAgreeResult>
            }
        },
        {
            validator: (path) => path.startsWith("/home") || path.startsWith("/challenge"),
            sublogic: [
                {
                    validator: (path) => !ss.getCurrentSession().email,
                    defaultValue: () => <Setting/>
                },
            ],
            defaultValue: () => {
                let [[, root='', endPage='', serviceId='', titleId='', episodeId='', snapshotId=''], hash = ''] = (([uri,hash])=>[uri.split('/'), hash])(path.split('#'))
                hash = hash || window.location.hash.replace('#','');
                return <AnalyticsHome {...{root, endPage, serviceId, titleId, episodeId, snapshotId, hash}}></AnalyticsHome>
            },
        },
        {
            validator: (path) => path.startsWith("/report"),
            defaultValue: () => {
                let [[serviceId='', titleId='', episodeId='', snapshotId=''], hash = ''] = (([uri,hash])=>[uri.split('/'), hash])(path.replace(/^\/report\/?/,'').split('#'))
                hash = hash || window.location.hash.replace('#','');
                return <Report {...{serviceId, titleId, episodeId, snapshotId, hash}}></Report>
            },
        },
        {
            validator: (path) => path.startsWith("/graph"),
            defaultValue: () => {
                let [[serviceId='', titleId='', episodeId='', snapshotId=''], hash = ''] = (([uri,hash])=>[uri.split('/'), hash])(path.replace(/^\/graph\/?/,'').split('#'))
                hash = hash || window.location.hash.replace('#','');
                return <ReportWOContent {...{serviceId, titleId, episodeId, snapshotId, hash}}></ReportWOContent>
            },
        },
        {
            validator: (path) => path.startsWith("/scholarship"),
            defaultValue: () => <Scholarship/>,
        },
        {
            validator: (path) => path.startsWith("/consent"),
            defaultValue: () => <Consent/>,
        },
        {
            validator: (path) => path.startsWith("/help"),
            defaultValue: () => <></>,
        },
        {
            validator: (path) => path.startsWith("/landing"),
            // redirect to https://challenge.webtoon.today/analysis
            defaultValue: () => <Landing/>,
        },
        {
            validator: (path) => path.startsWith("/metric"),
            // redirect to https://metric.webtoon.today/
            defaultValue: () => {window.location.href = 'https://metric.webtoon.today/'},
        },
        {
            validator: (path) => path.startsWith("/inquiry"),
            defaultValue: () => <Inquiry/>,
        },
        {
            validator: (path) => path.startsWith("/survey"),
            defaultValue: () => <Survey/>,
        },
        {
            validator: (path) => path.startsWith("/template/kakaotalk/list"),
            defaultValue: () => <KakaoTalkList/>,
        },
        {
            validator: (path) => path.startsWith("/template/kakaotalk/chat"),
            defaultValue: () => <KakaoTalkChat/>,
        },
        {
            validator: (path) => path.startsWith("/notice"),
            defaultValue: () => <NoticeList />,
        },
        {
            validator: (path) => path.startsWith("/privacypolicy"),
            sublogic:[
                {
                    validator: (path) => path.startsWith("/privacypolicy/kr"),
                    defaultValue: () => <PrivacyPolicyKr {...newProps}/>,
                },
            ],
            defaultValue: () => <PrivacyPolicy/>,
        },
        {
            validator: (path) => path.startsWith("/paymentpolicy"),
            sublogic:[
                {
                    validator: (path) => path.startsWith("/paymentpolicy/kr"),
                    defaultValue: () => < PaymentPolicy />
                }
            ],
            defaultValue: () => <PaymentPolicy />
        },
        {
            validator: (path) => path.startsWith("/termsofuse"),
            sublogic:[
                {
                    validator: (path) => path.startsWith("/termsofuse/2022"),
                    defaultValue: () => <TOU_20220201/>,
                },
                {
                    validator: (path) => path.startsWith("/termsofuse/20230726"),
                    defaultValue: () => <TOU_20230726/>,
                },
                {
                    validator: (path) => path.startsWith("/termsofuse/20231010"),
                    defaultValue: () => <TOU_20231010/>,
                },
            ],
            defaultValue: () => <TOU_20231010/>,
        },
        {
            validator: (path) => path.startsWith("/prelaunch/"),
            defaultValue: () => {
                let [, concept] = /prelaunch\/([^/]+)\/*/.exec(path)
                return <PreLaunchPage {...newProps} concept={concept}/>
            },
        },
        {
            validator: (path) => path.startsWith("/company"),
            defaultValue: () => <Home {...newProps}/>,
        },
        {
            validator: (path) => path.startsWith("/challengeagree"),
            defaultValue: () => <FinishNote {...newProps}/>,
        }
    ],
    () => <NotFound />,
)

/**
 * 
 * @param {import('./App').globalDataType} props 
 */
const VirtualRouter = (props) => {

    /**
     * @type {[
     *      [string, set<string>],
     *      [size, set<size>],
     *      [number, set<number>],
     * ]}
     * */
    const [path, setPath] = React.useState((props.injectedLocation || window.location.pathname).replace(window.location.origin));

    React.useEffect(()=>{
        const historyListener = ()=>{
            setPath(window.location.pathname);
        };
        window.addEventListener('popstate', historyListener);
        return ()=> {
            window.removeEventListener('popstate', historyListener);
        }
    },[])

    const [goto, ] = React.useState(() => (urlOrPath) => {
        if (urlOrPath === window.location.pathname){
            return;
        }
        logBehavior('goto', {to: urlOrPath});

        if (urlOrPath === undefined || urlOrPath === null) {
            window.history.back();
        }else if (urlOrPath.startsWith("/")){
            if (window.gtag){
                window.gtag('config', "G-8PRR5WLG9M", {'page_path': urlOrPath});
            }
            const path = urlOrPath.replace(window.location.origin);

            if (path.startsWith("/") || path === ""){
                window.scrollTo(0,0);
                setPath(path);
                window.history.pushState({}, document.title, window.location.origin + path);
            }
        }else {
            window.open(urlOrPath);
        }
    });
    React.useEffect(()=>{
        fn.goto = goto;
    },[goto]);

    /**
     * @type {[
     *      [boolean, set<boolean>],
     *      [string, set<string>],
     *      [string, set<string>],
     *      [(boolean)=>void, set<(boolean)=>void>],
     *      [string, set<string>],
     *      [string, set<string>],
     * ]}
     */
    const [
        [warningShown, setWarningShown],
        [warningTitle, setWarningTitle],
        [warningMessage, setWarningMessage],
        [warningShownCallback, setWarningShownCallback],
        [warningOKText, setWarningOkText],
        [warningCancelText, setWarningCancelText],
    ] = [
        React.useState(false),
        React.useState(""),
        React.useState(""),
        React.useState((isOkay) => {}),
        React.useState(""),
        React.useState(""),
    ];
    
    const [isManager, setIsManager] = React.useState(false);
    React.useEffect(()=>{
        (async()=>{
            setIsManager(await ss.isManager());
        })();
    },[])

    /**
     * 
     * @param {string} warningTitle 
     * @param {string} warningMessage 
     * @param {(boolean) => void} warningShownCallback 
     * @param {string?} warningOKText 
     * @param {string?} warningCancelText 
     */
    const [showWarning,] = React.useState(()=>(warningTitle, warningMessage, warningShownCallback, warningOKText, warningCancelText) => {
        setWarningTitle(warningTitle);
        setWarningMessage(warningMessage);
        setWarningShownCallback(warningShownCallback);
        setWarningOkText(warningOKText);
        setWarningCancelText(warningCancelText);
    });

    /** @type {routingDataType} */
    const newProps = {
        isClient: props.isClient,
        goto, path, isManager,
        showWarning
    };

    return (
    <>
        <div style={{width:'100%', height:'100%', position: 'relative'}}>
            <div style={{width:'100%', height:'100%'}}>
                {jsxBranch(path, newProps)()}
            </div>
        </div>
        <Dialog
            open={warningShown}
        >
            <DialogTitle>{warningTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{warningMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{
                    setWarningShown(false)
                    warningShownCallback(false)
                }} color="default">
                    {warningCancelText || "Close"}
                </Button>
                <Button onClick={()=>{
                    setWarningShown(true)
                    warningShownCallback(true)
                }} color="primary">
                {warningOKText || "OK"}
                </Button>
            </DialogActions>
        </Dialog>
    </>
    );
}

export default VirtualRouter