import axios from 'axios';
import ss from '../../../../Data/Session';

/**
 * @typedef {{
 *   serviceId: string,
 *   titleId: string,
 *   userid: string,
 *   authority: 'owner' | 'write' | 'access' | 'read' | 'none',
 * }} OwnershipType
 */

/**
 * 
 * @param {string} serviceId
 * @param {string} titleId
 * @returns {Promise<OwnershipType[]>}
 */
export async function readOwnerships (serviceId, titleId) {

    try{
        let res = await axios.post('https://challenge-api.webtoon.today/webtoon/ownership', {serviceId, titleId}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.data;

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}

/**
 * 
 * @param {string} serviceId
 * @param {string} titleId
 * @returns {Promise<OwnershipType[]>}
 */
 export async function readOtherOwnerships (serviceId, titleId, type = null) {

    try{
        let res = await axios.post('https://challenge-api.webtoon.today/webtoon/ownership', {serviceId, titleId, type}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.data;

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}

/**
 * 
 * @returns {Promise<OwnershipType[]>}
 */
export async function listMyOwnTitles () {
    try{
        let res = await axios.post('https://challenge-api.webtoon.today/webtoon/ownership', {}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.data;

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}

/**
 * 
 * @returns {Promise<OwnershipType[]>}
 */
export async function listMyOwnReports () {
    try{
        let res = await axios.post('https://api.webtoon.today/ownership', {type: 'report'}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.data;

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}

/**
 * 
 * @param {OwnershipType} ownership
 * @returns {Promise<string?>}
 */
export async function createOwnership (ownership) {
    try{
        let res = await axios.put('https://api.webtoon.today/ownership', ownership, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}

/**
 * 
 * @param {OwnershipType} ownership
 * @returns {Promise<string?>}
 */
export async function updateOwnership (ownership) {
    try{
        let res = await axios.patch('https://api.webtoon.today/ownership', ownership, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}

/**
* 
* @param {OwnershipType} ownership
* @returns {Promise<string?>}
*/
export async function deleteOwnership (ownership) {
    try{
        let res = await axios.delete(`https://api.webtoon.today/ownership?serviceId=${ownership.serviceId}&titleId=${ownership.titleId}&userid=${ownership.userid}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}

export async function requestOwnership (onwership) {
    try{
        let res = await axios.put('https://api.webtoon.today/ownership-request', onwership, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}