import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';

import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip} from '@material-ui/core';

import {listCompetition, updateCompetition, readCompetitionResult} from './Data/Competition';
import {getTitles} from './Data/Title';
import LoadingCircle from '../../../Component/LoadingCircle';
import EditableTable from '../../../Component/Table/EditableTable';

import {CompetitionEncourage, CompetitionPrize, FilteredApplication, CompetitionResult} from '../../Email';

import './CompetitionProcess.scss';
import moment from 'moment-timezone';

import {fn} from '../../../Functions';
import AnalysisLaunching from './AnalysisLaunching';

const currentStartingFriday = ((curr)=>(new Date(curr - ( ( ( (curr.getDay() + 2) * 24 + curr.getHours() ) * 60 + curr.getMinutes() ) * 60 + curr.getSeconds() ) * 1000 - curr.getMilliseconds() ) ) )(new Date())
const competitionGroups = Array(54).fill(0).map((row, idx) => [
    new Date(currentStartingFriday - (idx - 1) * 7 * 24 * 3600 * 1000).getTime() / 1000,
    new Date(currentStartingFriday - (idx - 1 - 1) * 7 * 24 * 3600 * 1000).getTime() / 1000,
    `${(new Date(currentStartingFriday - (idx - 1 - 1) * 7 * 24 * 3600 * 1000).getMonth() + 1)}월 ${Math.ceil(new Date(currentStartingFriday - (idx - 1 - 1) * 7 * 24 * 3600 * 1000).getDate() / 7)}주차`
]).filter( (row,idx,arr) => arr.findIndex(el => el[2] === row[2]) === idx)

const Competition = () => {
    let dispatch = useDispatch();

    const [isLoading, setIsLoading] = React.useState(false);

    const [candidates, setCandidates] = React.useState([]);
    const [titleInfo, setTitleInfo] = React.useState({});

    const [refreshTimer, setRefreshTimer] = React.useState(null);

    const [results, setResults] = React.useState([]);

    const [askStartAnalysis, setAskStartAnalysis] = React.useState(false);
    const [currentAnalysisTarget, setCurrentAnalysisTarget] = React.useState({});

    const [emailCheck, setEmailCheck] = React.useState(false);
    const [emailSendCallback, setEmailSendCallback] = React.useState(()=>()=>{});
    const [currentEmailTarget, setCurrentEmailTarget] = React.useState({});
    const [currentEmailProgress, setCurrentEmailProgress] = React.useState('');

    const [askOnceAgain, setAskOnceAgain] = React.useState(false);
    const [postponedAction, setPostonedAction] = React.useState(()=>()=>{});
    const [postponedMessage, setPostponedMessage] = React.useState("");    
    
    const [filterDeleted, setFilterDeleted] = React.useState(true);
    const [filterTest, setFilterTest] = React.useState(true);

    React.useEffect(()=>{
        document.title = `공모전`;

        (async ()=>{
            setIsLoading(true);

                let candi = await listCompetition();
                setCandidates(candi);

                let titleList = await getTitles(candi);

                let titleObj = titleList.map(row => ({[`${row.serviceId}:${row.titleId}`]: row})).reduce((a,b)=> Object.assign(a,b), {});
                setTitleInfo(titleObj);

                try{
                    let resultList = await readCompetitionResult(candi);
                    let resultObj = resultList.map(({serviceId, titleId, episodeId, snapshotId, ...other}) => ({[[serviceId, titleId, episodeId, snapshotId].join(':')]: {serviceId, titleId, episodeId, snapshotId, ...other}})).reduce((a,b)=> Object.assign(a,b), {});
                    setResults(resultObj);
                }catch(e){
                    //
                }
            setIsLoading(false);
        })();
        
    },[dispatch])

    const updateRow = async (row) => {

        const index = candidates.map(
            ({serviceId, titleId, episodeId, snapshotId}) => [serviceId, titleId, episodeId, snapshotId].join(':')
        ).indexOf(`${row.serviceId}:${row.titleId}:${row.episodeId}:${row.snapshotId}`)

        if (refreshTimer){
            clearTimeout(refreshTimer);
            setRefreshTimer(null);
        }

        setCandidates([
            ...candidates.slice(0,index),
            row,
            ...candidates.slice(index+1)
        ])
        updateCompetition(row);
        
        const timer = setTimeout(()=>{
            (async () => {
                setIsLoading(true);
    
                let candi = await listCompetition();
                setCandidates(candi);
        
                setIsLoading(false);

                setRefreshTimer(null);
            })();
        },5000)

        setRefreshTimer(timer);
    }

    return (<div className={"Competition"}>
        <div style={{display: 'flex', flexDirection:'row-reverse', paddingRight: 20, justifyContent:'flex-start', alignItems:'center'}}>
            <Checkbox checked={filterDeleted} onChange={(event, checked) => setFilterDeleted(checked)}/>
            <label>{"삭제된 항목 숨기기"}</label>
            <br/>
            <Checkbox checked={filterTest} onChange={(event, checked) => setFilterTest(checked)}/>
            <label>{"테스트 항목 숨기기"}</label>
        </div>
        <table style={{maxWidth: 1600, width: '100%', margin: 'auto'}}>
            <EditableTable
                partial='head'
                headerButtonHeaders={[]}
                headerButtonsFunction={()=>[]}
                defaultSortCol={[1, "asc"]}
                data={[]}
                columnHeaders={["제목", "생성일", "광고컷", "필터링", "테스트 집행", "결과 검토", "수상 여부", "결과 공지"]}
                columnsFunction={(row, index) => []}
                updateGenerator={(row, index)=> (newValue)=>{
                    updateRow({...row, ...newValue})
                }}
            />
            {competitionGroups.map(([startTS, endTS, weekIter]) => {
                let subsetCandidates = candidates.filter(row =>
                    startTS < row.createdAt && row.createdAt < endTS
                    && (!filterDeleted || titleInfo[`${row.serviceId}:${row.titleId}`])
                    && (!filterTest || !titleInfo[`${row.serviceId}:${row.titleId}`] || titleInfo[`${row.serviceId}:${row.titleId}`].title.indexOf('(테스트)') < 0)
                );

                if (subsetCandidates.length === 0){
                    return <tbody key={`${weekIter} subset`}><tr><td colSpan={"8"} className={"CompetitionSubset"}>
                        <h2 className={"SubsetHeader"}>{weekIter}</h2><span>{`${
                            ((num)=>(`전체: ${num} 개`))(subsetCandidates.length)
                        } ${
                            ((num)=>(num>0?`심의중: ${num} 개`:``))(subsetCandidates.filter(row => !row.validatedAt).length)
                        } ${
                            ((num)=>(num>0?`분석대기: ${num} 개`:``))(subsetCandidates.filter(row => !row.testStartedAt && row.vaildatedAt).length)
                        } ${
                            ((num)=>(num>0?`검토중: ${num} 개`:``))(subsetCandidates.filter(row => !row.resultConfirmedAt && row.testStartedAt && row.validatedAt).length)
                        } ${
                            ((num)=>(num>0?`공지대기: ${num} 개`:``))(subsetCandidates.filter(row => !row.resultNotifiedAt && row.isValid && row.resultConfirmedAt).length)
                        } ${
                            ((num)=>(num>0?`처리완료: ${num} 개`:``))(subsetCandidates.filter(row => row.resultNotifiedAt || (row.validatedAt && !row.isValid)).length)
                        }`}</span>
                    </td></tr></tbody>;
                }

                return <Fragment key={`${weekIter} subset`}><tbody><tr><td colSpan={"8"} className={"CompetitionSubset"}>
                    <h2 className={"SubsetHeader"}>{weekIter}</h2><span>{`${
                            ((num)=>(num>0?`전체: ${num} 개`:``))(subsetCandidates.length)
                        } ${
                            ((num)=>(num>0?`심의중: ${num} 개`:``))(subsetCandidates.filter(row => !row.validatedAt).length)
                        } ${
                            ((num)=>(num>0?`분석대기: ${num} 개`:``))(subsetCandidates.filter(row => !row.testStartedAt && row.vaildatedAt).length)
                        } ${
                            ((num)=>(num>0?`검토중: ${num} 개`:``))(subsetCandidates.filter(row => !row.resultConfirmedAt && row.testStartedAt && row.validatedAt).length)
                        } ${
                            ((num)=>(num>0?`공지대기: ${num} 개`:``))(subsetCandidates.filter(row => !row.resultNotifiedAt && row.isValid && row.resultConfirmedAt).length)
                        } ${
                            ((num)=>(num>0?`처리완료: ${num} 개`:``))(subsetCandidates.filter(row => row.resultNotifiedAt || (row.validatedAt && !row.isValid)).length)
                        }`}</span>
                    </td></tr></tbody>
                    <EditableTable
                        partial='body'
                        headerButtonHeaders={[]}
                        headerButtonsFunction={()=>[]}
                        defaultSortCol={[1, "asc"]}
                        data={subsetCandidates
                            .map(candidate => {
                                let title = titleInfo[`${candidate.serviceId}:${candidate.titleId}`] || {};
                                let result = results[[candidate.serviceId, candidate.titleId, candidate.episodeId, candidate.snapshotId].join(':')] || {};
                                return {...title, ...candidate, ...result};
                            })}
                        columnHeaders={["제목", "생성일", "광고컷", "필터링", "테스트 집행", "결과 검토", "수상 여부", "결과 공지"]}
                        columnsFunction={(row, index) => [
                            {isEditing: false, field: "title", type: 'default', style: {maxWidth: '20vw', borderBottom: '1px lightgray solid', margin: 4, whiteSpace: 'nowrap'},
                                defaultValue: <Tooltip title={row.title}><div style={{display:'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <div style={{whiteSpace:'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis',}}>{row.title || `${row.author}의 작품` || '(삭제됨)'}</div>
                                    <div>
                                        <IconButton size={"small"} href={`/challenge/contents/${row.serviceId}/${row.titleId}`} onClick={fn.gotoByAnchor}>📃</IconButton>
                                        <IconButton href={`/challenge/report/${row.serviceId}/${row.titleId}/${row.episodeId}/${row.snapshotId}`} onClick={fn.gotoByAnchor} size={"small"}>🎢</IconButton>
                                    </div>
                                </div></Tooltip> },
                            {isEditing: false, field: "createdAt", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',}, defaultValue: moment(row.createdAt * 1000).format('YYYY-MM-DD')},
                            {isEditing: false, field: "slides", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',},
                                defaultValue: (!row.slides || !row.slides.length)?"🚫":"🟢",},
                            {isEditing: false, field: "validatedAt", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',},
                                defaultValue: !row.validatedAt?<>
                                    <IconButton size={"small"} onClick={()=>{updateRow({...row, validatedAt: new Date().getTime() / 1000, isValid: true})}}>✅</IconButton>
                                    <IconButton size={"small"} onClick={()=>{
                                        setCurrentEmailProgress("filtered");
                                        setCurrentEmailTarget({...row, weekIter});
                                                
                                        setEmailSendCallback(()=>
                                            (success, message)=>{
                                                updateRow({...row, validatedAt: new Date().getTime() / 1000, isValid: false, rejectReason: message})
                                                setEmailCheck(false);
                                            }
                                        );

                                        setEmailCheck(true);
                                        
                                    }}>❌</IconButton>
                                    <IconButton size={"small"} onClick={()=>{
                                        setAskOnceAgain(true);
                                        setPostonedAction(()=>()=>{
                                            updateRow({...row, validatedAt: new Date().getTime() / 1000, isValid: false, rejectReason: "무시(메일X)"})
                                        })
                                        setPostponedMessage(<div>
                                            {row.title}<br/>
                                            {"이메일을 발송하지 않고 무시처리 합니다."}<br/>
                                        </div>)
                                    }} > 🟠 </IconButton>
                                </>:<>
                                    {row.isValid?"🟢":<>
                                        {"🚫"}
                                        <Tooltip title={row.rejectReason || ""}><span style={{display: "inline-block", whiteSpace:"nowrap", verticalAlign: "middle", maxWidth: 90, overflow: 'hidden', textOverflow: 'ellipsis'}}>{row.rejectReason||""}</span></Tooltip>
                                    </>}
                                    <IconButton size={"small"} style={{fontSize: '0.6rem'}} onClick={()=>{
                                        setAskOnceAgain(true);
                                        setPostonedAction(()=>()=>{
                                            updateRow({...row, validatedAt: null, isValid: null})
                                        })
                                        setPostponedMessage(<div>
                                            {row.title}<br/>
                                            {row.isValid?"🟢":"🚫"}<br/>
                                            {"원고 심의결과를 취소하고 다시 검토합니다."}<br/>
                                        </div>)
                                    }}>🔙</IconButton>
                                </>},
                            {isEditing: false, field: "testStartedAt", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',},
                                defaultValue: 
                                    (row.validatedAt && !row.isValid)?"🚫"
                                    :!row.testStartedAt?<IconButton size={"small"} style={{fontSize: '0.8rem'}} onClick={()=>{
                                        setCurrentAnalysisTarget(row);
                                        setAskStartAnalysis(true);
                                    }}>📩</IconButton>
                                    :<>
                                        {(new Date().getTime() / 1000 - row.testStartedAt < 24 * 60 * 60)?"🕝":"🟢"}
                                        <IconButton size={"small"} style={{fontSize: '0.6rem'}} onClick={()=>{
                                            setAskOnceAgain(true);
                                            setPostonedAction(()=>()=>{
                                                updateRow({...row, testStartedAt: null})
                                            })
                                            setPostponedMessage(<div>
                                                {row.title}<br/>
                                                {"데이터분석을 다시 집행합니다.(광고는 취소되지 않음)"}<br/>
                                            </  div>)
                                        }}>🔙</IconButton>
                                    </>},
                            {isEditing: false, field: "resultConfirmedAt", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center', width: 150, marginLeft: 'auto', marginRight: 'auto'},
                                defaultValue: !row.resultConfirmedAt?
                                !row.isValid?
                                !row.validatedAt?<>
                                    {"필터링 대기중"}
                                </>:<>
                                    {"제외 대상"}
                                </>:
                                !row.CTR?<>
                                    {"대기중"}
                                </>:<>
                                    {`${('' + row.CTR * 100).substring(0,3)}% / ${('' + row.fullReadRate * 100).substring(0,4)}% / ${('' + row.nextClickRate * 100).substring(0,4)}%`}
                                    <IconButton size={"small"} onClick={()=>{
                                        setCurrentEmailProgress("resultConfirmed");
                                        setCurrentEmailTarget({...row, weekIter});
                                                
                                        setEmailSendCallback(()=>
                                            ()=>{
                                                updateRow({...row, resultConfirmedAt: new Date().getTime() / 1000})
                                                setEmailCheck(false);
                                            }
                                        );

                                        setEmailCheck(true);
                                    }}>✅</IconButton>
                                </>:<>
                                    {`${('' + row.CTR * 100).substring(0,3)}% / ${('' + row.fullReadRate * 100).substring(0,4)}% / ${('' + row.nextClickRate * 100).substring(0,4)}%`}
                                    <IconButton size={"small"} style={{fontSize: '0.6rem'}} onClick={()=>{
                                        setAskOnceAgain(true);
                                        setPostonedAction(()=>()=>{
                                            updateRow({...row, resultConfirmedAt: null})
                                        })
                                        setPostponedMessage(<div>
                                            {row.title}<br/>
                                            {`${('' + row.CTR * 100).substring(0,3)}% / ${('' + row.fullReadRate * 100).substring(0,4)}% / ${('' + row.nextClickRate * 100).substring(0,4)}%`}<br/>
                                            {"분석결과 공지를 취소하고 다시 검토합니다."}<br/>
                                        </div>)
                                    }}>🔙</IconButton>
                                </>},
                            {isEditing: (row.isValid || !row.validatedAt) && !row.resultNotifiedAt, field: "isWinner", type: 'select', style: {whiteSpace: 'nowrap', textAlign: 'center',}, fullWidth: true,
                                defaultValue: row.isWinner || "fail",  options:[{value: "fail", key: "🥀"}, {value: "topclass", key: "🏅75%돌파"}, {value: "first", key: "🏅일등상"}, {value: "runnerup", key: "🏅장려상"}],},
                            {isEditing: false, field: "resultNotifiedAt", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',},
                                defaultValue:
                                    !row.validatedAt?"❓":
                                    !row.isValid?"❌":
                                    !row.resultConfirmedAt?"📃":
                                    !row.resultNotifiedAt?<IconButton size={"small"} onClick={()=>{
                                        setCurrentEmailProgress((row.isWinner && row.isWinner!=="fail")?"resultWithPrize":"resultWOPrize");
                                        setCurrentEmailTarget({...row, weekIter});
                                                
                                        setEmailSendCallback(()=>
                                            ()=>{
                                                updateRow({...row, resultNotifiedAt: new Date().getTime() / 1000})
                                                setEmailCheck(false);
                                            }
                                        );

                                        setEmailCheck(true);
                                    }}>✅</IconButton>:"🟢",},
                        ]}
                        updateGenerator={(row, index)=> (newValue)=>{
                            updateRow({...row, ...newValue})
                        }}
                    />
                </Fragment>
            })}
        </table>
        <Dialog open={askStartAnalysis} maxWidth={"md"} onClose={()=>{setAskStartAnalysis(false); setCurrentAnalysisTarget({})}}>
            <AnalysisLaunching
                candidate={currentAnalysisTarget}
                callback={()=>{
                    setAskStartAnalysis(false);
                    setCurrentAnalysisTarget({});
                    
                    if (refreshTimer){
                        clearTimeout(refreshTimer);
                        setRefreshTimer(null);
                    }

                    const timer = setTimeout(()=>{
                        (async () => {
                            setIsLoading(true);
                
                            let candi = await listCompetition();
                            setCandidates(candi);
                    
                            setIsLoading(false);

                            setRefreshTimer(null);
                        })();
                    },5000)

                    setRefreshTimer(timer);
                }}/>
        </Dialog>

        <Dialog open={emailCheck} maxWidth={"md"} onClose={()=>{setEmailCheck(false)}}>
            <DialogTitle>{"이메일 발송"}</DialogTitle>
            <DialogContent style={{backgroundColor:'rgb(233,233,233)'}}>
                <div>
                    {currentEmailProgress === "filtered"?<FilteredApplication
                        email={currentEmailTarget.email} title={currentEmailTarget.title} author={currentEmailTarget.author}
                        sendCallback={emailSendCallback}
                    />
                    :currentEmailProgress === "resultConfirmed"?<CompetitionResult
                        email={currentEmailTarget.email} weekIter={currentEmailTarget.weekIter} title={currentEmailTarget.title} author={currentEmailTarget.author}
                        analysisResultLocation={`https://challenge.webtoon.today/report/${currentEmailTarget.serviceId}/${currentEmailTarget.titleId}/${currentEmailTarget.episodeId}/${currentEmailTarget.snapshotId}`}
                        sendCallback={emailSendCallback}
                    />
                    :currentEmailProgress === "resultWithPrize"?<CompetitionPrize
                        email={currentEmailTarget.email} weekIter={currentEmailTarget.weekIter} title={currentEmailTarget.title} author={currentEmailTarget.author} isWinner={currentEmailTarget.isWinner} noticeLocation={"https://webtoon.today/notice"}
                        nextClickRate={currentEmailTarget.nextClickRate}
                        sendCallback={emailSendCallback}
                    />
                    :currentEmailProgress === "resultWOPrize"?<CompetitionEncourage
                        email={currentEmailTarget.email} weekIter={currentEmailTarget.weekIter} title={currentEmailTarget.title} author={currentEmailTarget.author} noticeLocation={"https://webtoon.today/notice"}
                        sendCallback={emailSendCallback}
                    />:<></>}
                </div>
            </DialogContent>
        </Dialog>
        <Dialog open={askOnceAgain}>
            <DialogTitle>{"다음 동작을 확인해주세요."}</DialogTitle>
            <DialogContent>{postponedMessage}</DialogContent>
            <DialogActions>
                <Button color={"primary"} variant={"contained"} onClick={()=>{
                    postponedAction();
                    setPostonedAction(()=>()=>{});
                    setPostponedMessage("");
                    setAskOnceAgain(false);
                }}>{"실행"}</Button>
                <Button color={"secondary"} variant={"outlined"} onClick={()=>{
                    setPostonedAction(()=>()=>{});
                    setPostponedMessage("");
                    setAskOnceAgain(false);
                }}>{"취소"}</Button>
            </DialogActions>
        </Dialog>
        <LoadingCircle show={isLoading}/>
    </div>);
}

export default Competition;