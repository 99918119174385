
import axios from 'axios'
import ss from './Session';

export const triggerEmail = async (email, subject, body, groups, cc=null, bcc=null) => {
    //groups 
    if ( !email || !subject || !body || (!groups.endsWith('@webtoon.today') && !groups.endsWith('@webtoontoday.com')) ){
        throw new Error("bad request");
    }
    let res = await axios.post('https://api.webtoon.today/trigger-email', {email, cc, bcc, subject, body, groups}, {
    headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    }else {
        return [];
    }
}
