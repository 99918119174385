import axios from 'axios'
import ss from './Session';

/**
 * @typedef {{
 *  serviceId: string,
 *  titleId: string,
 *  episodeId?: string,
 *  userid: string,
 *  addressid: string, userImage: string, title: string,
 *  body: string,
 *  rating?: number,
 *  hasSpoiler: 0|1
 * }} review
 * */


/**
 * 
 * @param {{
 *  serviceId: string,
 *  titleId: string,
 *  episodeId: string,
 *  snapshotId: string,
 *  addressid: string,
 *  includeFeedback: boolean,
 * }} indexInfo
 * @returns {Promise<review[]>}
 */
export const listReviews = async (indexInfo, withSession=false) => {
    if ( !(indexInfo.serviceId && indexInfo.titleId && !ss.getCurrentSession().userid) && !ss.getCurrentSession().userid ){
        console.log(ss.getCurrentSession(), indexInfo)
        return [];
    }
    let res = await axios.post('https://api.webtoon.today/review', indexInfo, {
        headers: {
            ...(withSession?{Authorization: `Bearer ${ss.getCurrentSession().jwt}`}:{})
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    }else {
        return [];
    }
}

/**
 * 
 * @param {review} review
 * @returns {Promise<string|false>}
 */
export const createReview = async (review) => {
    if (!ss.getCurrentSession().userid || !review.serviceId || !review.titleId || !review.title){
        return false;
    }
    
    let res = await axios.put('https://api.webtoon.today/review', review, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.id;
    }else{
        return false;
    }
}

/**
 * 
 * @param {import('./Session').sessionType} session 
 * @param {review} review
 * @returns {Promise<string|false>}
 */
export const updateReview = async (review) => {
    if (!ss.getCurrentSession().userid || !review.serviceId || !review.titleId || !review.title){
        return false;
    }
    
    let res = await axios.patch('https://api.webtoon.today/review', review, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return true;
    }else{
        return false;
    }
}


/**
 * 
 * @param {import('./Session').sessionType} session 
 * @param {review} review
 * @returns {Promise<string|false>}
 */
export const deleteReview = async (review) => {
    if (!ss.getCurrentSession().userid || !review.serviceId || !review.titleId || !review.title){
        return false;
    }
    
    let res = await axios.delete(`https://api.webtoon.today/review?serviceId=${review.serviceId}&titleId=${review.titleId}&episodeId=${review.episodeId?review.episodeId:`null`}&snapshotId=${review.snapshotId?review.snapshotId:`null`}`, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.id;
    }else{
        return false;
    }
}