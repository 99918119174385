import { Close, OpenInBrowser, } from '@material-ui/icons';
import { Button, Chip, IconButton, MenuItem, Select, Collapse, Checkbox, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core';
import moment from 'moment-timezone';
import React from 'react';
import LoadingCircle from '../../../Component/LoadingCircle';
import { fn } from '../../../Functions';
import {listTopic, createTopic, recommTopic, listAllTopic} from './Data/Topic';
import Alert from '@material-ui/lab/Alert';

import './TopicTagging.scss';
import { Autocomplete } from '@material-ui/lab';
import { logBehavior } from '../../../Data/Behavior';

const unique = (item, index, array) => index === array.indexOf(item);

const TopicTagging = () => {

    const [needRefresh, setNeedRefresh] = React.useState(0);
    const [pageSize, ] = React.useState(20);
    const [offset, setOffset] = React.useState(0);

    const [isLoading, setIsLoading] = React.useState(false);
    const [titles, setTitles] = React.useState([]);
    const [recomms, setRecomms] = React.useState({});
    const [allOptions, setAllOptions] = React.useState([]);

    const [newAdded, setNewAdded] = React.useState({});
    const [platformSelectOpen, setPlatformSelectOpen] = React.useState(false);
    const [platforms, setPlatforms] = React.useState(['lezhin', 'naver']);
    const [nullCase, setNullCase] = React.useState('only');

    const [currentTarget, setCurrentTarget] = React.useState({serviceId: null, titleId: null, title: '', description: '', thumbnail: ''});
    const [pendingOptions, setPendingOptions] = React.useState([]);

    const [errorMessage, setErrorMessage] = React.useState("");

    const [year, setYear] = React.useState("");

    React.useEffect(()=>{
        let isAlive = true;
        setIsLoading(true);
        (async()=>{
            let res = await listTopic([], nullCase, pageSize, offset, platforms, year || undefined);

            if (isAlive){
                setTitles(res);
            }
            let recomms = await recommTopic(res.map(({serviceId, titleId}) => ({serviceId, titleId})));

            let dict = recomms.map(({serviceId, titleId, topics}) => ({[`${serviceId}:${titleId}`]: topics})).reduce( (a,b) => ({...a, ...b}), {});

            if (isAlive){
                setRecomms(dict);
            }
            setIsLoading(false);

            res = await listAllTopic();

            setAllOptions(res);
        })();

        return ()=>{
            isAlive = false;
        }
    },[pageSize, offset, nullCase, platforms, needRefresh, year])


    const saveAdded = React.useCallback(async ()=>{
        setIsLoading(true);
        let res = await createTopic(
            Object.entries(newAdded).map( ([key, topics]) =>
                ({
                    serviceId: key.split(':')[0],
                    titleId: key.split(':')[1],
                    topics
                })
            )
        );
        console.log(res);

        if (!res){
            setErrorMessage("저장에 실패했습니다.");
            setIsLoading(false);
        }else{
            logBehavior('save-topics', {newAdded});
            setNewAdded({});
            setNeedRefresh(needRefresh => needRefresh + 1);
        }
        
    }, [newAdded])

    return (<div className='TopicTagging'>
        <div className='ControlBox'> 
            <Select size={"small"} value={nullCase} onChange={(event) => setNullCase(event.target.value)}>
                <MenuItem value={'lessThan3'}>{"3개 미만인 것만"}</MenuItem>
                <MenuItem value={'only'}>{"토픽이 없는 것만"}</MenuItem>
                <MenuItem value={'include'}>{"토픽이 없는 포함"}</MenuItem>
                <MenuItem value={'exclude'}>{"토픽이 없는 것 제외"}</MenuItem>
            </Select>
            <Select size={"small"} value={year} onChange={(event) => setYear(event.target.value)}>
                <MenuItem value={''}>{"전체"}</MenuItem>
                <MenuItem value={'2023'}>{"2023"}</MenuItem>
                <MenuItem value={'2022'}>{"2022"}</MenuItem>
                <MenuItem value={'2021'}>{"2021"}</MenuItem>
                <MenuItem value={'2020'}>{"2020"}</MenuItem>
                <MenuItem value={'2019'}>{"2019"}</MenuItem>
                <MenuItem value={'2018'}>{"2018"}</MenuItem>
                <MenuItem value={'2017'}>{"2017"}</MenuItem>
                <MenuItem value={'2016'}>{"2016"}</MenuItem>
            </Select>
            <Button onClick={()=> setOffset(offset => Math.max(0,offset- pageSize))}>{"이전 페이지로"}</Button>
            <span>{offset / pageSize + 1} {"페이지"}</span>
            <Button onClick={()=> setOffset(offset => offset+ pageSize)}>{"다음 페이지로"}</Button>
            <Button disabled={Object.keys(newAdded).length === 0} onClick={saveAdded}>{"저장하기"}</Button>
            <Button variant={platformSelectOpen?'contained':'outlined'} size={'small'} value={platformSelectOpen} onClick={(event)=> {setPlatformSelectOpen(!platformSelectOpen)}}>{"플랫폼 선택"}</Button>
            <Collapse in={errorMessage.length > 0}>
                <Alert severity="info" action={<IconButton size={"small"} onClick={()=>setErrorMessage("")}><Close/></IconButton>}>{errorMessage}</Alert>
            </Collapse>
            <Collapse in={platformSelectOpen}>
                {['bomtoon', 'comico', 'daum', 'kakao', 'lezhin', 'naver', 'ridi', 'series', 'toomics', 'toptoon'].map(platform =>
                    <FormControlLabel
                        key={platform}
                        control={<Checkbox
                            checked={platforms.indexOf(platform) >=0}
                            onChange={(event, checked) => checked?setPlatforms([...platforms, platform]):setPlatforms(platforms.filter(row => row !== platform))}
                        />}
                        label={platform}
                    />
                )}
            </Collapse>
        </div>
        <div className='TopicTaggingBody'>
            {titles.map(({serviceId, titleId, title, description, href, author, thumbnail, createdAt, topics}) =>
            <div key={`${serviceId}:${titleId}`} className='Webtoon'>
                <div className={'ThumbnailCol'}>
                    <img src={`https://cached-api.webtoon.today/thumb?mw=200&u=${encodeURIComponent(thumbnail)}`} alt={title}
                        onError={(event)=>{
                            if (event.target && typeof event.target.getAttribute === 'function'
                            && event.target.getAttribute('src') === `https://cached-api.webtoon.today/thumb?mw=200&u=${encodeURIComponent(thumbnail)}`){
                                event.target.setAttribute('src', thumbnail);
                            }
                        }}
                    /><br/>
                    <IconButton onClick={()=>fn.goto(href)} style={{position: 'absolute', bottom: 5, left: 5, backgroundColor: 'white'}}><OpenInBrowser/></IconButton>
                </div>
                <div className={'ContentsCol'}>
                    <span className={'Title'}>{title}</span><br/>
                    <span className={'Author'}>{author}</span><span className={'Service'}>{`(${serviceId})`}</span><br/>
                    <span className={'CreatedAt'}>{moment(createdAt*1000).format('YYYY.MM.DD.')}</span><br/>
                    <div className={'Topics'}>
                        {topics.map(topic =>
                            
                            <Chip key={topic.topic} style={{backgroundColor: 'rgb(180,220,240)'}} label={topic.topic} />
                        )}
                        {(newAdded[`${serviceId}:${titleId}`] || [])
                            .filter(topic => !!topic.topic)
                            .map(topic =>
                            
                            <Chip key={topic.topic} label={topic.topic} onClick={()=>{
                                setNewAdded(newAdded => ({...newAdded, [`${serviceId}:${titleId}`]: newAdded[`${serviceId}:${titleId}`].filter(row => row.topic !== topic.topic)}))
                            }}/>
                        )}
                    </div>    
                    <div style={{borderTop:'1px black solid'}}></div>
                    <div className={'Topics'}>
                        <Chip key={"단행본"} style={{backgroundColor: 'rgb(240,180,180)'}} label={"단행본"} onClick={()=>{
                            setNewAdded(newAdded => ({...newAdded, [`${serviceId}:${titleId}`]: (newAdded[`${serviceId}:${titleId}`] || []).concat([{topic:"단행본", score: 1}]) }))
                        }} />
                        <Chip key={"외서"} style={{backgroundColor: 'rgb(240,180,180)'}} label={"외서"} onClick={()=>{
                            setNewAdded(newAdded => ({...newAdded, [`${serviceId}:${titleId}`]: (newAdded[`${serviceId}:${titleId}`] || []).concat([{topic:"외서", score: 1}]) }))
                        }}/>
                        <Chip key={"광고"} style={{backgroundColor: 'rgb(240,180,180)'}} label={"광고"} onClick={()=>{
                            setNewAdded(newAdded => ({...newAdded, [`${serviceId}:${titleId}`]: (newAdded[`${serviceId}:${titleId}`] || []).concat([{topic:"광고", score: 1}]) }))
                        }}/>
                        {(recomms[`${serviceId}:${titleId}`] || [])
                        .filter(topic => !!topic.topic)
                        .sort( (a,b) => {
                            if (b.origin !== a.origin
                                && (b.origin === 'gpt' || a.origin === 'gpt')){
                                    return b.origin === 'gpt' ? 1 : -1;
                            }

                            if (b.score !== a.score){
                                return b.score - a.score
                            }

                            return b.topic > a.topic ? 1 : -1;
                            
                        }).map(topic =>
                            
                            <Chip
                                key={topic.topic} label={topic.topic}
                                style={topic.origin === 'gpt'?{backgroundColor: 'lightblue'}:(topic.origin === 'meta' && topic.score > 10)?{backgroundColor: '#d9f5ff'}:{}}
                                onClick={()=>{
                                    setNewAdded(newAdded => ({...newAdded, [`${serviceId}:${titleId}`]: (newAdded[`${serviceId}:${titleId}`] || []).concat([topic]) }))
                                }}
                            />
                        )}
                        <Button onClick={()=>setCurrentTarget({serviceId, titleId, title, description, thumbnail})}>{"수동입력"}</Button>
                        </div>
                    </div>
                
            </div>)}
        </div>
        <Dialog open={!!currentTarget.serviceId}>
            <DialogTitle>{currentTarget.title}</DialogTitle>
            <DialogContent className={"DialogBody"}>
                <div>{currentTarget.description}</div>
                <Autocomplete
                    options={allOptions}
                    renderInput={(params) => <TextField {...params} label="option" />}
                    onChange={(event, value)=>{
                        if (!value){
                            return;
                        }
                        if (pendingOptions.indexOf(value)<0){
                            setPendingOptions([...pendingOptions, value]);
                        }
                    }}
                />
                {pendingOptions.map(option =>
                    
                    <Chip key={option} label={option} onClick={()=>{
                        setPendingOptions(pendingOptions.filter(row => row !== option))
                    }}/>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant='contained'
                    onClick={()=>{
                        let {serviceId, titleId} = currentTarget;
                        setNewAdded(newAdded => ({
                            ...newAdded,
                            [`${serviceId}:${titleId}`]: (newAdded[`${serviceId}:${titleId}`] || []).concat(pendingOptions.map(option => ({topic: option, score: 1})).filter(unique))
                        }))
                        setCurrentTarget({serviceId: null, titleId: null, title: '', description: '', thumbnail: ''})
                        setPendingOptions([]);
                    }}
                >{"저장"}</Button>
            </DialogActions>
        </Dialog>
        <LoadingCircle show={isLoading}/>
    </div>);
}

export default TopicTagging;