
import axios from 'axios'
import ss from '../../../../Data/Session';

/**
 * @typedef {{
 *  serviceId: string,
 *  titleId: string,
 *  episodeId?: string,
 *  snapshotId?: string,
 *  adId: string
 * }} ad
 * */


/**
 * 
 * @param {{
 *  serviceId: string,
 *  titleId: string,
 *  episodeId: string,
 *  snpashotId: string
 * }} indexInfo
 * @returns {Promise<ad[]>}
 */
export const listAds = async (indexInfo) => {
    if ( !indexInfo.serviceId || !indexInfo.titleId || !ss.getCurrentSession().userid ){
        console.log(ss.getCurrentSession(), indexInfo)
        return [];
    }
    let res = await axios.post('https://challenge-api.webtoon.today/ads', indexInfo, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    }else {
        return [];
    }
}

/**
 * 
 * @param {ad} ad
 * @returns {Promise<string|false>}
 */
export const createAd = async (ad) => {
    if (!ss.getCurrentSession().userid || !ad.serviceId || !ad.titleId || !ad.adId){
        return false;
    }
    
    let res = await axios.put('https://challenge-api.webtoon.today/ads', ad, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.id;
    }else{
        return false;
    }
}

export const publishFBAd = async ({serviceId, titleId, episodeId, snapshotId, target}) => {
    if (!ss.getCurrentSession().userid || !serviceId || !titleId || !episodeId || !snapshotId){
        return false;
    }
    
    let res = await axios.put('https://challenge-api.webtoon.today/create-fb-ad', {serviceId, titleId, episodeId, snapshotId, target, campaignId:"23848217100820196"}, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.id;
    }else{
        return false;
    }
}

/**
 * 
 * @param {import('./Session').sessionType} session 
 * @param {ad} ad
 * @returns {Promise<string|false>}
 */
export const updateAd = async (ad) => {
    if (!ss.getCurrentSession().userid || !ad.serviceId || !ad.titleId || !ad.adId){
        return false;
    }
    
    let res = await axios.patch('https://challenge-api.webtoon.today/ads', ad, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.id;
    }else{
        return false;
    }
}


/**
 * 
 * @param {import('./Session').sessionType} session 
 * @param {ad} ad
 * @returns {Promise<string|false>}
 */
export const deleteAd = async (ad) => {
    if (!ss.getCurrentSession().userid || !ad.serviceId || !ad.titleId || !ad.title){
        return false;
    }
    
    let res = await axios.delete(`https://challenge-api.webtoon.today/ads?adId=${ad.adId}`, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.id;
    }else{
        return false;
    }
}