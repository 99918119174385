import React from 'react';
import { Button, TextField } from '@material-ui/core';
import { triggerEmail } from '../../../Data/TriggerEmail';
import {listCompetition} from './Data/CompUniversityProcess';
import {getTitles} from './Data/Title';

// eslint-disable-next-line no-empty-pattern
const MailMerge = ({}) => {

    const [message, setMessage] = React.useState('');

    const [mailMergeList, setMailMergeList] = React.useState([]);
    
    // eslint-disable-next-line no-unused-vars
    const [_isLoading, setIsLoading] = React.useState(false);
    const [isSending, setIsSending] = React.useState(false);

    React.useEffect(()=>{
        (async ()=>{
            setIsLoading(true);

                let candi = await listCompetition();

                candi = candi.filter(row => row.isValid && row.validatedAt && row.year === "2024");

                let titleList = await getTitles(candi);

                let titleObj = titleList.map(row => ({[`${row.serviceId}:${row.titleId}`]: row})).reduce((a,b)=> Object.assign(a,b), {});
                
                for(let candidate of candi){
                    if (! titleObj[`${candidate.serviceId}:${candidate.titleId}`]){
                        continue;
                    }
                    for(let [key,value] of Object.entries(titleObj[`${candidate.serviceId}:${candidate.titleId}`])){
                        candidate[key] = value;
                    }
                    candidate.mailSent = false;
                }

                setMailMergeList(candi);

            setIsLoading(false);
        })();        
    },[]);

    const body = ({title, author, message, email, token, serviceId, titleId}) => `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </head>
        <body>
            <div style="background-color: rgb(255, 255, 255); padding: 24px 0 40px 0;">
                <div>
                    <div style="margin: 0 auto; max-width: 630px; background-color: rgb(255, 255, 255); border: 1px solid rgb(236,240,241); border-radius: 12px;">
                        <div>
                            <div style="margin:0 auto; padding: 0 16px; max-width: 598px;">
                                <div style="max-width: 430px; margin: 0 auto;">
                                    <img src="https://static.webtoon.today/ddah/과제부활전2024_로고_이메일.png"
                                        alt="homepage"
                                        style="margin: 80px 0 20px 0; width: 100%; object-fit: contain;" 
                                    />
                                </div>
                                <hr style="border: 1px solid rgb(51, 51, 51); margin: 10px 0;" />
                                <div>
                                    <div style="padding: 24px 0 16px 0; font-size: 24px; font-weight: 600; line-height: 26px; color: rgb(25,24,27); word-wrap: break-word; word-break: keep-all;">
                                        2024 과제부활전 일정 안내
                                    </div>
                                    <div style="padding: 24px 0 16px 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); ">
                                        <span style="color: rgb(14, 169, 246); font-weight: 700;">${author}</span>
                                        작가님 안녕하세요.<br/>
                                        오늘의웹툰입니다.
                                    </div>
                                    <div style="padding: 0 0 16px 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        과제부활전에 참여해 주셔서 감사드립니다. <br/>
                                        접수해주신 <${title}> 작품이 검수를 통과해 심사가 진행될 예정입니다.
                                    </div>
                                    <div style="padding: 0 0 24px 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        관련해서 ‘2024 과제부활전’ 일정에 대해 간략히 안내해 드리고자 합니다.
                                    </div>
                                    <div style="background-color: rgb(246, 246, 249); border-radius: 8px;">
                                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="margin: 0 16px 0 16px; padding: 12px 0 12px 0; color: rgb(121, 120, 130); font-size: 16px; font-style: normal; line-height: 24px;">
                                            <tbody>
                                                <tr>
                                                    <td style="font-weight: 600; padding: 0 16px 0 0;">1월 10일</td>
                                                    <td style="font-weight: 500;">공모전 심사 완료</td>
                                                </tr>
                                                <tr style="height: 8px;"></tr>
                                                <tr>
                                                    <td style="font-weight: 600; padding: 0 16px 0 0;">1월 13일</td>
                                                    <td style="font-weight: 500;">수상작 발표 및 작품별 분석 결과 제공</td>
                                                </tr>
                                                <tr style="height: 8px;"></tr>
                                                <tr>
                                                    <td style="font-weight: 600; padding: 0 16px 0 0;">2월 중</td>
                                                    <td style="font-weight: 500;">상급 지급</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style="padding: 24px 0 16px 0; font-size: 16px; font-style: normal; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        ‘2024 과제부활전’ 참여작품은 기본적으로 공개 범위가 ‘전체 공개’로 설정되어 있습니다. 공모전에 참여한 작품을 홍보하는 것을 금지하고 있지 않지만, 금품이나 상품을 전제로 하는 유료 홍보 활동을 진행한 경우 해당 작품은 결격 처리됩니다. 
                                    </div>
                                    <div style="padding: 0 0 16px 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        공모전과 관련하여 궁금하신 사항은 본 메일에 회신 또는 홈페이지의 채널톡으로 연락부탁드립니다. 
                                    </div>
                                    <div style="padding: 0 0 24px 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        오늘의웹툰 팀 드림
                                    </div>
                                    <div style="max-width: 180px; padding: 16px 0 24px 0; margin: 0 auto;">
                                        <div>
                                            <a href="https://challenge.webtoon.today/signin?email=${email}&token=${token}&redirectTo=${encodeURIComponent(`/contents/${serviceId}/${titleId}`)}"
                                                style="text-decoration: none;">
                                                <img src="https://static.webtoon.today/ddah/mailing/component/btn_goto_webtoonhome.png"
                                                    alt="내 홈으로 이동"    
                                                    style="height: 40px; object-fit:contain;"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="margin: 32px auto; padding:0 16px; max-width: 598px;">
                                <div>
                                    <div style="padding-top: 24px; padding-bottom: 10px;">
                                        <div style="padding-bottom: 20px; text-align: center;">
                                            <img src="https://static.webtoon.today/ddah/mailing/logo/logo_square_big.png"
                                                alt="오늘의웹툰" 
                                                width="62px" 
                                                height="40px"
                                            />
                                        </div>
                                        <div style="text-align: center; font-size: 14px; font-weight: 400; line-height: 20px; color: rgba(158, 157, 164, 1);  word-wrap: break-word; word-break: keep-all;">
                                            사용 중 불편한 점이나 궁금한 사항이 있으시면<br/>
                                            언제든
                                            <a href="mailto:help@webtoon.today" style="color: rgba(61, 106, 255, 1);">help@webtoon.today</a>
                                            로 문의주시기 바랍니다.
                                        </div>
                                    </div>
                                    <div style="text-align: center; padding-top: 24px; font-size: 12px; font-weight: 400; line-height: 20px; color: rgba(158, 157, 164, 1);  word-wrap: break-word; word-break: keep-all;">
                                        주식회사 오늘의웹툰 <br/>
                                        서울시 강남구 강남대로 78길 33-10, 3층
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    </html>
    `
    const [mailTitle, setMailTitle] = React.useState(`[오늘의웹툰] 2024 과제부활전 일정 안내`);
    
    let {title, author, email, token, serviceId, titleId} = mailMergeList.concat([{}])[0];

    return (<div>

        <div style={{display: 'flex', justifyContent: 'center', paddingTop: '20px'}}>
            <div className={'SendEmailInner'}>
                <div className={`EmailInfo`} style={{marginBottom:10, fontSize:'0.8rem', borderBottom: '1px black solid'}}>
                    <span style={{fontWeight: 'bold'}}>{"발송 정보"}</span>
                    <table style={{width: '100%'}}>
                        <tbody>
                            <tr>
                                <td>
                                    {"제목"}
                                </td>
                                <td>
                                    <TextField
                                        value={mailTitle}
                                        onChange={(event) => {
                                            setMailTitle(event.target.value);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {"본문"}
                                </td>
                                <td>
                                    <TextField
                                        value={message}
                                        onChange={(event) => {
                                            setMessage(event.target.value);
                                        }}
                                        multiline={3}
                                        fullWidth
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={`EmailPreview`}>
                    <div dangerouslySetInnerHTML={{__html: body({title, author, message, email, token, serviceId, titleId})}} />
                </div>
            </div>
        </div>
        <div className={'SendButton'}>
            <Button 
                fullWidth
                disabled={isSending}
                variant={'contained'} color={'primary'} 
                onClick={ async () => {
                    setIsSending(true);

                    for (let index = 0; index < mailMergeList.length; index++){
                        let row = mailMergeList[index];
                        let {title, author, token, serviceId, titleId,
                            email, 
                        } = row;
                        const result = await triggerEmail(email,mailTitle,body({title, author, message, email, token, serviceId, titleId}),'help@webtoon.today')
                        if (!result) {
                            alert('이메일 전송에 실패했습니다.')
                        }else {
                            setMailMergeList(mailMergeList => [
                                ...mailMergeList.slice(0,index),
                                {...row, mailSent: true},
                                ...mailMergeList.slice(index+1)
                            ])
                        }
                    }
                    setIsSending(false);
                }}
            >
                {'이메일 보내기'}
            </Button>
        </div>
        <div className={''}></div>
            <table>
                <td>{"제목"}</td>
                <td>{"작가"}</td>
                <td>{"이메일"}</td>
                <td>{"발송여부"}</td>
                {mailMergeList.map(row => {
                    return (<tr>
                        <td>{row.title}</td>
                        <td>{row.author}</td>
                        <td>{row.email}</td>
                        <td>{JSON.stringify(row.mailSent)}</td>
                    </tr>)
                })}
            </table>
        <div className={''}></div>
    </div>);
};

export default MailMerge;