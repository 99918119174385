import axios from 'axios';
import ss from './Session';

// 유저 리스트 조회. 이름 또는 이메일로 검색
export const getMemberList = async (searchParams)=>{
    try{
        let res = await axios.post(
            'https://challenge-api.webtoon.today/admin/user', 
        {
            ...searchParams
        }, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            return res.data.data;
        }else{
            return [];
        }

    }catch(e){
        return [];
    }
}


export const getMemberInfo = async (userid)=>{
    try{
        let res = await axios.post(
            'https://challenge-api.webtoon.today/admin/user/detail',
            {
                userid
            }, 
            {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            return res.data.data;
        }else{
            return [];
        }

    }catch(e){
        return [];
    }
}