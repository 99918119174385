import React from 'react';

const styles ={
    thumb1 : {
        width: 44,
        height: 44,
        borderRadius: 16,
        border: "0.5px rgb(222,222,222) solid",
    },
    thumb2 : {
        position: "absolute",
        width: 28,
        height: 28,
        borderRadius: 10,
        border: "2px white solid",
    },
    thumb3 : {
        position: "absolute",
        width: 26,
        height: 26,
        borderRadius: 10,
        border: "2px white solid",
    },
    thumb4 : {
        position: "absolute",
        width: 21,
        height: 21,
        borderRadius: 10,
        border: "2px white solid",
    },
    thumb: {
        lt: {},
        rt: {right: 10},
        t: {left: 7, right: 7},
        lb: {left: 0, bottom: 0},
        rb: {right: 10, bottom: 0},
    }
}

const KakaoTalkChatRow = ({members=[], title=null, preview="", updatedAt="어제", numberOfUnread=0}) => {


    
    return (<div className={"KakaoTalkChatRow"} style={{display:'flex', flexDirection: 'row', paddingBottom: 10, position: 'relative'}}>
        <div className={"Thumbs"} style={{paddingRight: 10, paddingTop:3, width: 48, height: 48, position:"relative"}}>
            {members.length === 0
            ?<></>
            :members.length === 1
            ?<div>
                <img style={styles.thumb1} src={members[0].url} alt={"thumb1"}/>
            </div>
            :members.length === 2
            ?<div>
                <img style={{...styles.thumb2, ...styles.thumb.lt}} src={members[0].url} alt={"thumb1"}/>
                <img style={{...styles.thumb2, ...styles.thumb.rb}} src={members[1].url} alt={"thumb1"}/>
            </div>
            :members.length === 3
            ?<div>
                <img style={{...styles.thumb3, ...styles.thumb.lb}} src={members[1].url} alt={"thumb1"}/>
                <img style={{...styles.thumb3, ...styles.thumb.rb}} src={members[2].url} alt={"thumb1"}/>
                <img style={{...styles.thumb3, ...styles.thumb.t}} src={members[0].url} alt={"thumb1"}/>
            </div>
            :<div>
                <img style={{...styles.thumb4, ...styles.thumb.lt}} src={members[0].url} alt={"thumb1"}/>
                <img style={{...styles.thumb4, ...styles.thumb.rt}} src={members[1].url} alt={"thumb1"}/>
                <img style={{...styles.thumb4, ...styles.thumb.lb}} src={members[2].url} alt={"thumb1"}/>
                <img style={{...styles.thumb4, ...styles.thumb.rb}} src={members[3].url} alt={"thumb1"}/>
            </div>}
        </div>
        <div className={"TitleAndPreview"} style={{display:'flex', flexDirection:'column', padding: '10px 0 10px 0'}}>
            <div className={"Title"} style={{fontSize: "0.7rem", fontWeight:'bold', display: "flex", width: 240}}>
                <div style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow: 'ellipsis', maxWidth: 200}}>{title || members.map(member => member.name).join(', ')}</div>
                {members.length>=3
                    ?<div style={{paddingLeft:5, color: "rgb(150,150,150)",}}>{members.length}</div>
                    :<></>}
            </div>
            <div className={"Preview"} style={{fontSize: "0.6rem", paddingTop: 5, color: "rgb(111,111,111)"}}>
                {preview}
            </div>
        </div>
        <div className={"UpdatedAt"} style={{
            position:'absolute', right: 10, top: 10, fontSize: "0.6rem", color: "rgb(111,111,111)",
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
        }}>
            <div>{updatedAt || "어제"}</div>
            <div>{numberOfUnread>0?<div style={{backgroundColor:'red', borderRadius:'1rem', fontSize: "0.6rem", color: 'white', textAlign:'center', padding:'0 5px 0 5px'}}>{numberOfUnread}</div>:<></>}</div>
        </div>
    </div>)
}

export default KakaoTalkChatRow;