import React from 'react';
import {Avatar, IconButton} from '@material-ui/core';

import { thumbnailize } from '../Functions';


/**
 * 
 * @param {{
 *   addressid: string,
 *   userImage?: string,
 *   style: React.CSSProperties,
 *   goto: (url:string)=>void
 * }} param 
 */
const UserAvatar = ({addressid, userImage, style={}, goto}) => (
    <IconButton
        style={{ ...{width: 40, height: 40}, ...style}}
        onClick={()=>(goto || console.log)(`/detail/${addressid}`)}
    >
        <Avatar
            alt={addressid}
            style={{position:'absolute', left:0, top: 0, width: style.width||40, height: style.height||40}}
            src={userImage?thumbnailize(userImage,style.wdith || 40):null
            }
        />
    </IconButton>
);

export default UserAvatar;