import React from 'react';
import './SendEmail.scss'
import { Button } from '@material-ui/core';
import { triggerEmail } from '../../Data/TriggerEmail';


const CompetitionPrize = ({title, author, weekIter, noticeLocation, email, sendCallback, nextClickRate, isWinner}) => {

    const monthIter = (/\d+월/.exec(weekIter) || [])[0] || "";

    const body = `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </head>
        <body>
            <div style="display: block; width: 600px; background-color: white; padding: 30px 20px; font-size: 14px; box-sizing: border-box; color: rgb(68,68,68); line-height: 170%; margin: auto; border: 1px solid rgb(236,240,241); border-bottom: 0px">
                <h3 style="font-size: 18px;"><strong>${monthIter} 독자력 공모전 당선을 축하드립니다.</strong></h3><br/>
                안녕하세요 ${author} 작가님, 오늘의웹툰입니다.<br/>
                &lt;${title}&gt; 작품으로 참여해주신 ${monthIter} 독자력 공모전 결과가 발표되었습니다.<br/>
                <strong>독자력 공모전 ${isWinner==='topclass'?`연독률 75% 이상`:isWinner==='first'?`월간 1등`:/*isWinner==='runnerup'*/`장려상`}</strong> 부문으로 당선 되신 것을 진심으로 축하드립니다.<br/>
                (수상 내역은 홈페이지 공지사항에서도 확인 가능합니다.)<br/>
                <br />
                상금 지급을 위하여 <strong>신분증</strong>, <strong>통장 사본</strong>이 필요합니다. 이번달 말일까지 본 메일로 회신해주시기 바랍니다. 상금은 익월 10일 일괄 지급될 예정입니다.(제세공과금 4.4% 제외)<br/>
                <br />
                수상작의 리포트는 공개되며, 원고 공개를 원하지 않는 경우 본 메일에 회신으로 알려주시면 도움 드리겠습니다.<br/>
                <br />
                공모전 참가를 위해 소중한 시간을 내어주신 점 다시 한번 감사드리며, 오늘의웹툰은 언제나 작가님의 창작을 응원하겠습니다.<br/><br/>
                오늘의웹툰 팀 드림.<br/><br/>
                <a href=${noticeLocation} target="_blank" rel="noopener" style="color: white; background-color: rgb(0,176,240); width: 110px; font-size: 16px; padding: 10px 15px; border: 0px; border-radius: 4px; text-align: center; text-decoration: none;">결과 확인 </a>
            </div>
            <div style="background-color: rgb(236,240,241); width: 600px; box-sizing: border-box; font-size: 12px; color: rgb(177,178,181); line-height: 170%; padding: 10px 0 20px 0; text-align: center; margin: auto; border: 1px solid rgb(236,240,241);">
                주식회사 오늘의웹툰 | 웹툰 데이터 분석 · 에이전시 · 스튜디오<br/>
                서울시 강남구 강남대로 78길 33-10, 3층<br/>
                작품문의: artist@webtoon.today<br/>
                기타문의: help@webtoon.today<br/>
                <br/><div>© ${new Date().getFullYear()} All Rights Reserved</div>
            <div>
        </body>
    </html>
    `
    const mailTitle = `[오늘의웹툰] ${monthIter} 독자력 공모전 당선 공지`
    
    const [isSending, setIsSending] = React.useState(false);
    
    return (
        <div style={{display: 'flex', justifyContent: 'center', paddingTop: '20px'}}>
            <div className={'SendEmailInner'}>
                <div className={`EmailInfo`} style={{marginBottom:10, fontSize:'0.8rem', borderBottom: '1px black solid'}}>
                    <span style={{fontWeight: 'bold'}}>{"발송 정보"}</span>
                    <table>
                        <tbody>
                            <tr>
                                <td>{"수신인"}</td><td>{email}</td>
                            </tr>
                            <tr>
                                <td>{"제목"}</td><td>{mailTitle}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={`EmailPreview `}>
                    <div dangerouslySetInnerHTML={{__html: body}} />
                </div>
                <div className={'SendButton'}>
                    <Button 
                        fullWidth
                        disabled={!author || !nextClickRate || !weekIter || !noticeLocation || !email || isSending}
                        variant={'contained'} color={'primary'} 
                        onClick={async()=>{
                            setIsSending(true);
                            // 배포 직전에 groups 를 artist@webtoontoday.com 으로 바꾸기
                            const result = await triggerEmail(email,mailTitle,body,'report@webtoontoday.com', null, ['artist@webtoontoday.com'])
                            if (!result) {
                                alert('이메일 전송에 실패했습니다.')
                            }
                            sendCallback(!!result);
                            setIsSending(false);
                        }}
                    >
                        {'이메일 보내기'}
                    </Button>
                </div>
            </div>
        </div>
        
    )
}

export default CompetitionPrize ;