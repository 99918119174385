import { useState, useEffect } from "react";
import EditableTable from "../../../Component/Table/EditableTable";
import LoadingCircle from "../../../Component/LoadingCircle";
import { getAllProductInfo, createOrUpdateProduct } from "./Data/Product";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";

const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Product = () => {

    const [ productInfo, setProductInfo ] = useState([]);
    const [innerHeight, setInnerHeight] = useState(window.innerHeight);
    useEffect(()=>{
        let resizeListener = ()=>  setInnerHeight(window.innerHeight);
        window.addEventListener('resize', resizeListener);

        return ()=> window.removeEventListener('resize', resizeListener);
    },[])

    const [ editingIdx, setEditingIdx ] = useState(null);
    const [ isWaitingNewProduct, setIsWaitingNewProduct ] = useState(false);

    const [ isLoading, setIsLoading ] = useState(false);

    const [ newProductId, setNewProductId ] = useState('');
    const [ newProductName, setNewProductName ] = useState(''); 
    const [ newProductDetail, setNewProductDetail ] = useState('');
    const [ newProductAmount, setNewProductAmount ] = useState('');
    const [ newProductFinalAmount, setNewProductFinalAmount ] = useState('');
    const [ newProductImage, setNewProductImage ] = useState('');

    useEffect(()=>{
        document.title = `상품 관리`;
    },[]);

    useEffect(()=>{
        (async()=>{
            await refreshProductInfo();
        })();
    },[])


    const refreshProductInfo = async () => {
        setIsLoading(true);
        const result = await getAllProductInfo();
        setProductInfo(result);
        setIsLoading(false);
    }

    return (<>
        <div className={"ProductInfoTable"}>
            <h2 className={"SubsetHeader"}>
                {"상품 메타 정보"}
            </h2>
            <EditableTable 
                style={{maxHeight: innerHeight - 300, overflow: 'auto'}}
                headerButtonHeaders={[
                    <Button variant={'contained'} color={'primary'} onClick={()=>{ setIsWaitingNewProduct(true); }} >{'추가'}</Button>
                ]}
                headerButtonsFunction={(row, index)=>[
                    (editingIdx !== null && editingIdx === index)
                    ?{
                        name: '완료', color: 'primary', props:{ fullWidth: true, },
                        onClick:()=>{ 
                            setEditingIdx(null);
                        }
                    }
                    :{
                        name: '수정', color: 'primary', props:{ fullWidth: true, },
                        onClick:()=>{ setEditingIdx(index);}
                    }
                ]}
                isDownloadable={true}
                defaultSortCol={[1, "desc"]}
                columnHeaders={['productId','name','detail','정가','판매 가격','이미지',]}
                columnsFunction={(row, index)=>[
                    {isEditing: false, field: "productId", type: 'default', style: {width: '70px', borderBottom: '1px lightgray solid', margin: 4, whiteSpace: 'nowrap'},
                        defaultValue: row.id
                    },
                    {isEditing: index === editingIdx, field: "name", type: 'default',style: {width: '70px', borderBottom: '1px lightgray solid', margin: 4, whiteSpace: 'nowrap'},
                        defaultValue: row.name
                    },
                    {isEditing: index === editingIdx, field: "detail", type: 'default',style: {width: '70px', borderBottom: '1px lightgray solid', margin: 4, whiteSpace: 'nowrap'},
                        defaultValue: row.detail
                    },
                    {isEditing: index === editingIdx, field: "amount", type: 'default',style: {width: '70px', borderBottom: '1px lightgray solid', margin: 4, whiteSpace: 'nowrap'},
                        defaultValue: numberWithCommas(row.amount)
                    },
                    {isEditing: index === editingIdx, field: "finalAmount", type: 'default',style: {width: '70px', borderBottom: '1px lightgray solid', margin: 4, whiteSpace: 'nowrap'},
                        defaultValue: numberWithCommas(row.finalAmount)
                    },
                    {isEditing: false, field: "image", type: 'default', style: {width: '100px', padding: 5, whiteSpace: 'nowrap'},
                        defaultValue: <img alt={'상품 이미지'} src={row.image || "https://static.webtoon.today/noimage.jpg"} style={{width: '100px'}}/>
                    },
                ]}
                updateGenerator={(row, index)=> async (newValue)=>{
                    await createOrUpdateProduct({...row, ...newValue});
                    for(const [key, value] of Object.entries(newValue)){
                        row[key] = value;
                    }
                    await refreshProductInfo();
                }}
                data={productInfo}
                name={"product"}
            />
        </div>
        <Dialog open={isWaitingNewProduct} onClose={()=>{setIsWaitingNewProduct(false)}}>
            <DialogTitle>{'상품 생성'}</DialogTitle>
            <DialogContent style={{display: 'grid'}}>
                {'상품id({serviceId}:{titleId})'}<TextField value={newProductId} onChange={(event)=>{setNewProductId(event.target.value)}}/>
                {'상품 이름'}<TextField value={newProductName} onChange={(event)=>{setNewProductName(event.target.value)}}/>
                {'상품 상세 정보'}<TextField value={newProductDetail} onChange={(event)=>{setNewProductDetail(event.target.value)}}/>
                {'상품 정가'}<TextField value={newProductAmount} onChange={(event)=>{setNewProductAmount(event.target.value)}}/>
                {'상품 판매가'}<TextField value={newProductFinalAmount} onChange={(event)=>{setNewProductFinalAmount(event.target.value)}}/>
                {'상품 이미지 url'}<TextField value={newProductImage} onChange={(event)=>{setNewProductImage(event.target.value)}}/>
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} color={'primary'}
                    onClick={async () => {
                        if(!newProductId||!newProductName||!newProductDetail||!newProductImage||!newProductAmount||!newProductFinalAmount){
                            alert("모든 필드를 채워주세요");
                            return;
                        }
                        const result = await createOrUpdateProduct({
                            id: newProductId, name: newProductName, detail: newProductDetail,
                            amount: newProductAmount, finalAmount: newProductFinalAmount, image: newProductImage
                        });
                        if(result){
                            setIsWaitingNewProduct(false);
                            setNewProductId('');
                            setNewProductName('');
                            setNewProductDetail('');
                            setNewProductAmount('');
                            setNewProductFinalAmount('');
                            setNewProductImage('');

                            await refreshProductInfo();
                        }else{
                            alert("저장에 실패했습니다.");
                        }
                    }}>
                    {'저장'}
                </Button>
            </DialogActions>
        </Dialog>
        <LoadingCircle show={isLoading}/>
    </>);

}

export default Product;