import React from 'react';
import './Consent.scss';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm'

import { appearUp } from "./Landing/SettingValues";
import AnimateDiv from "../Component/AnimateDiv";

import { Button, Collapse, TextField, IconButton, Paper, Checkbox, FormControlLabel } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';

import {readChallengeData} from '../Data/ReadFromChallenge';

import LoadingCircle from '../Component/LoadingCircle';
import Header from '../Component/Header';

import { askSecret, validateSecret } from '../Data/AskPermission';
import { fn, parseUrl } from '../Functions';
import { createTitleFromChallenge } from '../Data/Title';

const Consent = () => {
    
    const [titleInfo, setTitleInfo] = React.useState({
        title: '',
        description: ``,
        author: '',
        image: '',
    });

    const [tmpEndPage, setTmpEndPage] = React.useState("");
    const [endPage, setEndPage] = React.useState("");

    React.useEffect(()=>{
        let queryString = window.location.search.replace('?','');
        let queries = Object.fromEntries(queryString.split('&').map(pair => pair.split("=").map(uri => decodeURIComponent(uri))))

        if ('endPage' in queries){
            setEndPage(queries.endPage);
        }

        if ('secret' in queries && 'email' in queries){
            setSecret(queries.secret);
            setEmailSent(true);
            (async ()=>{
                setIsLoading(false);
                let ret = await validateSecret(queries.email, queries.secret);
                setEmailVerified(ret);
                setIsLoading(true);
            })();
        }
        
    },[])

    React.useEffect(()=>{
        if (!endPage){
            return;
        }
        (async()=>{
            let newTitleInfo = await readChallengeData(endPage);

            if (!newTitleInfo.title){
                setEndPage("");
                setErrorMessage("조회에 실패했습니다. 주소를 확인해주세요.");
            }

            setTitleInfo(newTitleInfo)
        })()
    },[endPage, ])

    const [agreeCollectData, setAgreeCollectData] = React.useState(false);
    const [agreeItisNotanAgreement, setAgreeItisNotanAgreement] = React.useState(false);
    const [needConsulting, setNeedConsulting] = React.useState(false);

    const [emailSent, setEmailSent] = React.useState(false);

    const [secret, setSecret] = React.useState(null);
    const [emailVerified, setEmailVerified] = React.useState(false);

    const [isLoading, setIsLoading] = React.useState(false);

    const [errorMessage, setErrorMessage] = React.useState("");
    
    return(
        <>
            <div className={"Body"}>
                <Header pageName={`데이터 분석${titleInfo.title?`: ${titleInfo.title}`:''}`}/>
                <div className={"Background"}>
                    {titleInfo.title
                    ?<div className={"Area"}>
                        <AnimateDiv variants={appearUp}>
                            <Paper variant={"outlined"} className={"ExtractInfo"}>
                                <div className={"OutText"}>{"자동추출정보"}</div>
                                <div className={"ImgArea"}>
                                    <img
                                        className={"Thumbnail"} 
                                        src={titleInfo.image}
                                        alt={titleInfo.title}
                                    />             
                                </div>
                                <div className={"WebtoonInfo"}>
                                    <div className={"Title"}>
                                        {titleInfo.title}
                                    </div>
                                    <div className={"Description"}>
                                        {titleInfo.description.split('<br/>').map( (row,i) => <div key={i}>{row}</div>)}
                                    </div>
                                </div>
                                <p style={{wordBreak:'keep-all'}}>{"도전만화를 통한 데이터 분석은 <작품 소유자의 네이버이메일 인증>을 통해서만 가능합니다. 도전만화에 업로드 되지 않았거나, 원고를 따로 전달하고 싶으시다면 "}<a href='mailto:artist@webtoon.today'>{"artist@webtoon.today"}</a>{"로 원고를 보내주세요."}</p>
                                <div className={"Certificate"}>
                                    <div className={"Email"}>
                                        <TextField variant={"outlined"} label={"email"} value={`${titleInfo.author}@naver.com`} disabled/>
                                    </div>
                                    <Button 
                                        className={`ColoredButton ${emailVerified?"":"Blue"}`} 
                                        color="primary" 
                                        variant={"contained"}
                                        disabled={emailVerified}
                                        onClick={()=>{
                                            (async()=>{
                                                setIsLoading(true);
                                                await askSecret(`${titleInfo.author}@naver.com`);
                                                setEmailSent(true);
                                                setIsLoading(false);
                                            })();
                                            
                                        }}
                                    >
                                        {emailSent?"재발송":"보내기"}
                                    </Button>
                                </div>
                                <div className={`Certificate ${emailSent?'Hidden':''}`}>
                                    <a href={"#emailSent"} onClick={(event)=>{
                                        event.preventDefault();
                                        setEmailSent(true);
                                    }}>{"인증 코드를 가지고 계신가요?"}</a>
                                </div>
                                <div className={`Certificate ${!emailSent?'Hidden':''}`}>
                                    <div className={"Email"}>
                                        <TextField variant={"outlined"} label={"인증 코드"} value={secret || ""} onChange={(event) => setSecret(event.target.value)}/>
                                    </div>
                                    <Button 
                                        className={`ColoredButton ${emailVerified?"":"Blue"}`} 
                                        color="primary" 
                                        variant={"contained"}
                                        disabled={emailVerified}
                                        onClick={()=>{
                                            (async()=>{
                                                setIsLoading(true);
                                                let ret = await validateSecret(`${titleInfo.author}@naver.com`, secret);
                                                setEmailVerified(ret);
                                                setIsLoading(false);
                                            })();
                                        }}
                                    >
                                        {"확인"}
                                    </Button>
                                </div>
                            </Paper>
                        </AnimateDiv>
                        {emailVerified
                        ?<AnimateDiv variants={appearUp}>
                            <div className={"Check"}>
                                <div className={"CheckContainer"}>
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    className={"DataConsentButton"}
                                                    checked={agreeCollectData} 
                                                    onClick={()=>{setAgreeCollectData(!agreeCollectData)}}
                                                    color={"primary"}
                                                />}
                                        label="독자 반응 수집을 위한 SNS 퍼포먼스 마케팅(광고 게재)에 동의합니다."
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    className={"DataConsentButton"}
                                                    checked={agreeItisNotanAgreement} 
                                                    onClick={()=>{setAgreeItisNotanAgreement(!agreeItisNotanAgreement)}}
                                                    color={"primary"}
                                                />}
                                        label="데이터 분석이 에이전시 계약, 플랫폼 연재 등과는 무관함을 확인합니다."
                                    />
                                    <FormControlLabel
                                        control={<Checkbox 
                                                    className={"DataConsentButton"}
                                                    checked={needConsulting} 
                                                    onClick={()=>{setNeedConsulting(!needConsulting)}}
                                                    color={"primary"}
                                                />}
                                        label="(선택) 데이터 분석 결과의 설명 미팅을 희망합니다."
                                    />
                                </div>
                            </div>
                            <div className={"Apply"}>
                                <Button 
                                    className={`ColoredButton ${(agreeCollectData && agreeItisNotanAgreement && secret && emailVerified)?"Blue":""}`} 
                                    color="primary" 
                                    variant={"contained"}
                                    disabled={!agreeCollectData || !agreeItisNotanAgreement || !secret || !emailVerified}
                                    onClick={async ()=>{
                                        await createTitleFromChallenge({
                                            serviceId: titleInfo.author, titleId: (parseUrl(endPage).query || {}).titleId,
                                            agreements: {
                                                agreeCollectData, agreeItisNotanAgreement, secret, emailVerified, needConsulting
                                            }
                                        })
                                    }}
                                >
                                    {"신청하기"}
                                </Button>
                            </div>
                            <Paper variant={"outlined"} className={"Rule"}>
                                <style>{`
                                    .Rule li {word-break: keep-all;}
                                `}</style>
                                <ReactMarkdown remarkPlugins={[gfm]}>
                                    {`
                                    |## 권리 보호
                                    |1. 작품 정보, 작품 본문에 대한 모든 저작권은 작가님(또는 원저자)의 것입니다.
                                    |    - 그외 테스트 보고서의 분석 결과/데이터의 저작권은 오늘의웹툰에게 있습니다.
                                    |1. 오늘의웹툰은 테스트 과정에서 취득한 작가님 또는 작품의 정보를 테스트 외의 목적으로 사용하지 않습니다.
                                    |2. 저장된 모든 정보는 테스트 종료 후 작가님의 요청에 따라 홈페이지에서 내립니다.
                                    |3. 작가님이 희망하시는 경우, 테스트의 정보를 보존하여 제 3자에게 제공할 수 있습니다.
                                    |    - 제 3자는 오늘의웹툰 동의 하에 테스트 결과를 재활용할 수 있습니다.
                                    |`.replace(/\n *\|/g,'\n')}
                                </ReactMarkdown>
                                <Button style={{marginLeft:20}} variant={"contained"} href={"https://static.webtoon.today/ddah/210621-report%20review.pdf"} onClick={fn.gotoByAnchor}>{"보고서 예시/해설"}</Button>
                            </Paper>
                        </AnimateDiv>
                        :<></>}
                    </div>
                    :<div className={"Area"}>
                        <Collapse in={errorMessage.length > 0}>
                            <Alert severity="info" action={<IconButton size={"small"} onClick={()=>setErrorMessage("")}><Close/></IconButton>}>{errorMessage}</Alert>
                        </Collapse>
                        <AnimateDiv variants={appearUp}>
                            <Paper variant={"outlined"} className={"ExtractInfo"}>
                                <div className={"OutText"}>{"네이버 도전만화-간단 입력"}</div>
                                <div className={"SubmitUrl"}> 
                                    <TextField className={"Input"} label={"도전만화 링크"} variant={"outlined"} value={tmpEndPage} onChange={(event)=>{setTmpEndPage(event.target.value)}}/>
                                    
                                    <Button 
                                        className={"ColoredButton Blue"} 
                                        color={"primary"}
                                        variant={"contained"}
                                        fullWidth
                                        onClick={()=>{
                                            setEndPage(tmpEndPage)
                                        }}
                                    >
                                        {"조회하기"}
                                    </Button>
                                </div>
                            </Paper>
                        </AnimateDiv>
                    </div>}
                </div>
            </div>
            <LoadingCircle show={(endPage && !titleInfo.title) || isLoading}/>
        </>
    );
}

export default Consent;