import React from 'react';

const data = {
    title: "Privacy Policy"
    ,body: "This Policy (the 'Policy') explains the way of treatment of the information which is provided or collected in the web sites on which this Policy is posted. In addition the Policy also explains the information which is provided or collected in the course of using the applications of the Company which exist in the websites or platforms of other company. The Company is the controller of the information provided or collected in the websites on which this Policy is posted and in the course of using the applications of the Company which exist in the websites or platforms of other company. Through this Policy, the Company regards personal information of the users as important and inform them of the purpose and method of Company's using the personal information provided by the users and the measures taken by the Company for protection of those personal information."
    ,nested: [
        {
            title: "Use of collected information"
            , prefix: "i"
            , body: "The Company uses the collected information of users for the following purposes:"
            , nested: [
                {title: "Member management and identification", prefix:'-'},
                {title: "Improvement of existing services and development of new services", prefix:'-'},
                {title: "To comply with applicable laws or legal obligation", prefix: '-'},
            ]
        },
        {
            title: "Disclosure of collected information"
            , prefix: "ii"
            , body: "The company do not disclose collected information to any other 3rd party."
            , nested: [
            ]
        },
        {
            title: " User’s right"
            , prefix: "iii"
            , body: "The users or their legal representatives, as main agents of the information, may exercise the following rights regarding the collection, use and sharing of personal information by the Company:"
            , nested: [
                {title: "exercise right to access to personal information", prefix:'-'},
                {title: "request the withdrawal of their consent provided before", prefix:'-'},
                {title: "make temporary suspension of treatment of personal information", prefix:'-'},
                {
                    title: "can remove contents related to themselves:",
                    prefix:'-',
                    nested: [
                        {title: "If you are willing to delete your personal information from the services, you can withdraw your account. Following your request, Company will remove your all information by following applicable laws or legal obligation.", prefix: '-'},
                        {title: "Any else contents on service, which is your own and you want to remove, will have a 'remove' button nearby.", prefix: '-'}
                    ]
                }
            ]
        },
        {
            title: " Information to be collected and method of collection"
            , prefix: "iv"
            , body: "Personal information items to be collected by the Company are as follows:"
            , nested: [
                {title: "Encrypted Google ID, Email, name(in case of using Google SSO)", prefix:'-'},
                {title: "Encrypted Facebook ID, Email, name(in case of using Facebook SSO)", prefix:'-'},
                {title: "Browser's user agent", prefix:'-'},
                {title: "Accessing IP", prefix:'-'},
                {title: "Interaction data that made by using service", prefix:'-'},
            ]
        },
        {
            title: " Usage of Google user data"
            , prefix: "v"
            , body: "We do collect and process Google user data:"
            , nested: [
                {title: "Using Google OAuth process", prefix:'-'},
                {title: "with limited range of user information(user id, name, email)", prefix:'-'},
                {title: "To distinguish users from others", prefix:'-'},
                {title: "To serve personalized curation/recommendation.", prefix:'-'},
                {title: "We do not share any of these information.", prefix:'-'},
            ]
        },
        {
            title: "Security"
            , prefix: "vi"
            , body: "The Company regard the security of personal information of uses as very important. The company constructs the following security measures to protect the users' personal information from any unauthorized access, release, use or modification"
            , nested: [
                {title: "Encryption of personal information", prefix: '-'},
                {title: "Countermeasures against hacking", prefix: '-'},
            ]
        },
        {
            title: "Cookies, Beacons and Similar Technologies"
            , prefix: "vii"
            , body: "The Company may collect collective and impersonal information through 'cookies'. The users have an option for cookie installation. So, they may either allow all cookies by setting option in web browser, make each cookie checked whenever it is saved, or refuses all cookies to be saved: Provided that, if the user rejects the installation of cookies, it may be difficult for that user to use the parts of services provided by the Company."
            , nested: [
            ]
        },
        {
            title: "Contact information"
            , prefix: "viii"
            , body: "Please use one of the following methods to contact the Company should you have any queries in respect to this policy or wish to update your information:"
            , nested: [
                {title: "privacy@webtoon.today"}
            ]
        },
    ]
};
/*
        {
            title: ""
            , prefix: ""
            , body: ""
            , nested: [
            ]
        }
 */

const DrawData = (data, prefix, depth) => {
    return (
        <div style={{paddingLeft: depth * 20, fontSize: 120*Math.pow(0.9, depth)+'%' }}>
            <p>
                {data.prefix?`${data.prefix}`:''} {data.title || ""}
            </p>
            {data.body || ""}
            {data.nested?data.nested.map(row => DrawData(row, prefix?`${prefix}.${data.prefix}`:data.prefix, depth+1)):''}
        </div>
    );
}


export default function PrivacyPolicyEn (props) {

    window.canonicalPath = '/privacypolicy';

    return (
        <div style={{textAlign:'left'}}>
            {DrawData(data, "", 1)}
        </div>
    );
}