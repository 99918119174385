import axios from "axios";
import ss from "./Session";

export const readPool = async (serviceId, titleId) => {
    try {
        const res = await axios.get(`https://challenge-api.webtoon.today/market/title-analytics/pool?serviceId=${serviceId}&titleId=${titleId}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true,
        })
        
        if ( res ) {
            return res.data.data;
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
}

export const putInsightReport = async (serviceId, titleId, data) => {
    try {
        const res = await axios.put(`https://challenge-api.webtoon.today/market/report`,{serviceId, titleId, data}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true,
        })
        
        if ( res ) {
            return res.data.key;
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
}

export const readInsightReport = async (serviceId, titleId) => {
    try {
        const res = await axios.get(`https://challenge-api.webtoon.today/market/report?serviceId=${serviceId}&titleId=${titleId}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true,
        })
        
        if ( res ) {
            return res.data.data;
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
}