import React from 'react';
import {Avatar, Button, IconButton, Tooltip, } from '@material-ui/core';
import {WatchLater, AttachMoney, MoneyOff, FastRewind, Favorite, MoreHoriz as MoreIcon } from '@material-ui/icons';
import ServiceAvatar from './ServiceAvatar';

// @Type
// eslint-disable-next-line no-unused-vars
import {sessionType, googleResponseType, facebookResponseType} from '../Data/Session';
// eslint-disable-next-line no-unused-vars
import {interestFetchType, interestType} from '../Data/Interest';
// eslint-disable-next-line no-unused-vars
import {readTitles, titleType} from '../Data/Title';
// eslint-disable-next-line no-unused-vars
import {size} from '../VirtualRouter';
import { decodeEntities, thumbnailize, randomString, fn } from '../Functions';
import { logBehavior } from '../Data/Behavior';

import './ItemCard.scss';

/**
 * 
 * @param {{
 *   mode: 'vertical'|'horizontal'|'card',
 *   style: React.CSSProperties,
 *   windowSize: size,
 *   item: titleType,
 *   isLiked: boolean,
 *   isSubscribed: boolean,
 *   saveAndUpdateInterest: (interest: interestFetchType) => Promise<void>,
 *   deleteAndUpdateInterest: (interest: interestFetchType) => Promise<void>,
 *   recommOrEpisodeTitle: 'recomm' | 'episodeTitle',
 *   isClient: boolean
 * }} props 
 */
export const ItemCard = ({mode = 'vertical', item, style, isLiked, isSubscribed, saveAndUpdateInterest, deleteAndUpdateInterest, recommOrEpisodeTitle, isClient}) => {

    const [uniqueId,] = React.useState('grid_'+randomString(16));

    const [isOpen, setIsOpen] = React.useState(false);
    const [isActive, setIsActive] = React.useState(false);
    
    // place holder
    if (!item) {
        return (
            <div >
            </div>
        );
    }

    return (
        <div className={`FlatCardStyle ${mode} ${isActive?'isActive':''}`} id={uniqueId}
            onMouseOver={()=>{setIsActive(true)}}
            onMouseLeave={()=>{setIsActive(false)}}
        >
            <div className={"Grid"}>
                <div className={"Container"}>
                    <div className={"ThumbnailContainer"}>
                        <img
                            className={"Thumbnail"}
                            src={thumbnailize(item.lastEpisodeThumbnail,0,100)}
                            alt={item.title}
                            onClick={()=>{fn.goto(`/detail/${item.serviceId}/${item.titleId}`)}}
                        />
                    </div>
                    <div className={"LabelContainer"}>
                        <ServiceAvatar className={"ServiceAvatar"} serviceId={item.serviceId} goto={fn.goto}/>
                        <Avatar className={"Avatar"}
                            alt={item.title}
                            src={thumbnailize(item.thumbnail,40)}
                        />
                        <div className={"Labels"}  onClick={()=>{fn.goto(`/detail/${item.serviceId}/${item.titleId}`)}}>
                            <div className={"Title"}>{item.title}</div>
                            {recommOrEpisodeTitle === 'recomm'
                                ?item.popularity && item.popularity > 1000
                                    ?<Tooltip title={"누적 조회수 입니다."}>
                                        <div className={"Subtitle"}>
                                            {`조회 ${
                                                item.popularity > 10000?
                                                    `${Math.floor(item.popularity / 1000)/10} 만`
                                                :item.popularity > 1000?
                                                    `${Math.floor(item.popularity / 100)/10} 천`
                                                :   `${item.popularity}`
                                            } 회`}
                                        </div>
                                    </Tooltip>
                                    :item.rank && item.rank < 50
                                        ?<Tooltip title={"각 서비스에서 자체 선정한 랭킹입니다. 네이버의 경우 요일별로 다른 랭킹을 적용합니다."}>
                                            <div className={"Subtitle"}>
                                                {`자체 랭킹 ${item.rank} 위`}
                                            </div>
                                        </Tooltip>
                                        :item.rating && item.rating > 8
                                            ?<Tooltip title={"각 서비스에서 집계중인 별점입니다."}>
                                                <div className={"Subtitle"}>
                                                    {`별점 ${Math.floor(item.rating*10)/10}`}
                                                </div>
                                            </Tooltip>
                                            :<div className={"Subtitle"}>{"　"}</div>
                                :<div className={"Subtitle"}>{item.lastEpisodeTitle}</div>}
                        </div>
                        <IconButton
                            className={"More"}
                            onClick={()=>{setIsOpen(true)}}
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </div>
                {isClient?
                <div className={"ActiveGrid"} onClick={()=>{fn.goto(`/detail/${item.serviceId}/${item.titleId}`)}}>
                    <div className={"ThumbnailContainer"}>
                        <img
                            className={"Thumbnail"}
                            src={thumbnailize(item.lastEpisodeThumbnail)}
                            alt={item.title}
                        />
                    </div>
                </div>:<div/>}
                <div className={`OpenGrid ${isOpen?"isOpen":""}`}>
                    <div className={"Background"} onClick={()=>{setIsOpen(false)}} onWheel={()=>setIsOpen(false)}/>
                    <div className={"Card"}>
                        <div className={"ThumbnailContainer"}>
                            <img
                                className={"Thumbnail"}
                                src={thumbnailize(item.lastEpisodeThumbnail)}
                                alt={item.lastEpisodeTitle}
                            />
                        </div>
                        <div className={"ThumbTitleContainer"}>
                            <ServiceAvatar className={"Avatar"} serviceId={item.serviceId} goto={fn.goto}/>
                            <Button className={"title"} onClick={()=>{fn.goto(`/detail/${item.serviceId}/${item.titleId}`)}}>{item.title}</Button>
                        </div>
                        <div className={"Description"}>{decodeEntities(item.description)}</div>
                        <div className={"Actions"}>
                            <Tooltip className={"Button"} title='관심 작품'><span>
                                <IconButton aria-label="add to favorites" onClick={()=>{
                                if(!isLiked){
                                    logBehavior('like', {titleId:item.titleId, serviceId: item.serviceId});
                                    saveAndUpdateInterest([
                                        {type:"like", serviceId: item.serviceId, titleId: item.titleId, detail: {} }
                                    ]);
                                }else{
                                    logBehavior('unlike', {titleId:item.titleId, serviceId: item.serviceId});
                                    deleteAndUpdateInterest([
                                        {type:"like", serviceId: item.serviceId, titleId: item.titleId, detail: {} }
                                    ]);
                                }
                                }}
                                color={isLiked?"secondary":"default"}
                                >
                                <Favorite />
                                </IconButton>
                            </span></Tooltip>
                            <Tooltip className={"Button"} title='알림 설정'><span>
                                <IconButton aria-label="share" onClick={()=>{
                                    logBehavior('subscribe', {titleId:item.titleId, serviceId: item.serviceId});
                                if (!isSubscribed){
                                    saveAndUpdateInterest((isLiked?[]:[
                                        {type:"like", serviceId: item.serviceId, titleId: item.titleId, detail: {} },
                                    ]).concat([
                                        {type:"subscribe", serviceId: item.serviceId, titleId: item.titleId, detail: {} }
                                    ]));
                                }else{
                                    logBehavior('unsubscribe', {titleId:item.titleId, serviceId: item.serviceId});
                                    deleteAndUpdateInterest([
                                    {type:"subscribe", serviceId: item.serviceId, titleId: item.titleId, detail: {} }
                                    ]);
                                }
                                }}
                                color={isSubscribed?"secondary":"default"}
                                >
                                <WatchLater secondary={"true"} />
                                </IconButton>
                            </span></Tooltip>
                            <Tooltip className={"Button"} title='유료 최신화로 이동'><span>
                                <IconButton aria-label="launch" disabled={item.lastEpisodeHref === item.lastFreeEpisodeHref} onClick={() => {
                                    logBehavior('visit', {type: 'latest', titleId:item.titleId, serviceId: item.serviceId, episodeId: item.lastEpisodeId});
                                    fn.goto(item.lastEpisodeHref);
                                }} >
                                <AttachMoney/>
                                </IconButton>
                            </span></Tooltip>
                            <Tooltip className={"Button"} title='무료 최신화로 이동'><span>
                                <IconButton aria-label="launch" disabled={!item.lastFreeEpisodeHref} onClick={() => {
                                    logBehavior('visit', {type: 'latestFree', titleId:item.titleId, serviceId: item.serviceId, episodeId: item.lastFreeEpisodeId});
                                    fn.goto(item.lastFreeEpisodeHref);
                                }} >
                                <MoneyOff/>
                                </IconButton>
                            </span></Tooltip>
                            <Tooltip className={"Button"} title='첫화로 이동'><span>
                                <IconButton aria-label="launch" disabled={!item.firstEpisodeHref} onClick={() => {
                                    logBehavior('visit', {type: 'first', titleId:item.titleId, serviceId: item.serviceId, episodeId: item.firstEpisodeId});
                                    fn.goto(item.firstEpisodeHref);
                                }}>
                                <FastRewind/>
                                </IconButton>
                            </span></Tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"Backgorund"}/>
        </div>
    );
}

export default ItemCard;