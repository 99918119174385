import React from 'react';

import { TextField, Paper, Avatar, Button, Collapse, IconButton, Input } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Close, Image } from '@material-ui/icons';
import { createReview, updateReview, deleteReview } from './Data/Review';
import ReactMarkdown from 'react-markdown'
import {createImageWithS3} from '../../../Data/Image';

import ss from '../../../Data/Session';

import {randomString} from '../../../Functions';

const Feedback = ({feedback, isEditable}) => {

    const [currentWidth, setCurrentWidth] = React.useState(window.innerWidth);
    React.useEffect(()=>{
        const resizeListener = ()=>{
            setCurrentWidth(window.innerWidth);
        };
        window.addEventListener('resize', resizeListener);

        return ()=>{
            window.removeEventListener('resize', resizeListener);
        }
    },[]);

    const [isPreview, setIsPreview] = React.useState(true);
    const [currentFeedback, setCurrentFeedback] = React.useState(
        feedback.userImage
            ?feedback
            :{...feedback, userImage: ss.getCurrentSession().image}
    );

    React.useEffect(()=>{

        if (!isEditable || (feedback.body && !currentFeedback.body)){
            if (feedback.userImage){
                setCurrentFeedback(feedback);
            }else {
                setCurrentFeedback({...feedback, userImage: ss.getCurrentSession().image});
            }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isEditable, feedback])

    const [uniqueId, ] = React.useState(randomString(32));

    const [errorMessage, setErrorMessage] = React.useState("");
    
    const onImagePutIn = React.useMemo(()=> async (event)=> {
        if (event.target.files.length === 0 ){
            return;
        }
        try{
            event.target.setAttribute('disabled', true);
            let filename = await createImageWithS3(event.target, (event)=>{
                //setProgress(event.loaded / event.)
            });
            setCurrentFeedback({...currentFeedback, body: (currentFeedback.body || '') + `\n![](https://api.webtoon.today/thumb?u=${encodeURIComponent(filename)}&mw=500)` })
            document.getElementById(`${uniqueId}_image_input`).value = '';
            document.getElementById(`${uniqueId}_image_input`).removeAttribute('disabled');
        }catch(e){
            document.getElementById(`${uniqueId}_image_input`).removeAttribute('disabled');
            console.error(e)
        }
    }, [currentFeedback, uniqueId]);

    return (<>
        <Collapse in={errorMessage.length > 0}>
            <Alert severity="info" action={<IconButton size={"small"} onClick={()=>setErrorMessage("")}><Close/></IconButton>}>{errorMessage}</Alert>
        </Collapse>
        <Paper className={"Feedback"} variant={"outlined"}>
            <div className={"Thumb"}>
                <Avatar className={"Profile"} src={currentFeedback.userImage}/>
                <div className={"Name"}>{currentFeedback.name}</div>
            </div>
            <div className={"Text"}>
                <div className={"Title"}>
                    {isEditable && !isPreview
                    ?<TextField
                        value={currentFeedback.title || ""}
                        onChange={(event)=>{
                        setCurrentFeedback({...currentFeedback, title: event.target.value})
                    }}/>
                    :<p>{currentFeedback.title || ""}</p>}
                    {isEditable
                    ?<div>
                        <Button
                            className={"Btn"}
                            variant={"outlined"}
                            onClick={()=>{
                                (async()=>{
                                    try{
                                        let res = {};
                                        if (currentFeedback.id){
                                            res = await updateReview({
                                                ...currentFeedback,
                                                rating: 1
                                            })
                                        }else{
                                            res = await createReview({
                                                ...currentFeedback,
                                                rating: 1
                                            })
                                        }
                                        console.log(res)
                                        if (res){
                                            setErrorMessage("완료되었습니다.");
                                        }
                                    }catch(e){
                                        setErrorMessage("저장에 실패했습니다. 예상되는 원인: "+e.message)
                                    }
                                })();
                        }}>{"저장하기"}</Button>
                        <>
                            <Button className={"Btn"} variant={"outlined"} onClick={()=>{
                                document.getElementById(`${uniqueId}_image_input`).click();
                            }}>
                                <Image/>
                            </Button>
                            <Input
                                id={`${uniqueId}_image_input`}
                                type={"file"}
                                style={{width:1,height:1, display:'none'}}
                                onChange={onImagePutIn}
                                name={"file"}
                                inputProps={{
                                    accept: ".gif,.jpg,.jpeg,.png,.svg"
                                }}
                            >
                            </Input>
                        </>
                        <Button className={"Btn"} variant={"outlined"} onClick={()=>{setIsPreview(!isPreview)}}>
                            {isPreview?"수정하기":"미리보기"}
                        </Button>
                        {feedback.id
                        ?<Button
                            className={"Btn"}
                            variant={"outlined"}
                            color={"secondary"}
                            onClick={()=>{
                                (async()=>{
                                    setCurrentFeedback({});
                                    try{
                                        let res = await deleteReview(feedback);
                                        console.log(res)
                                        if (res){
                                            setErrorMessage("삭제했습니다.");
                                        }
                                    }catch(e){
                                        setErrorMessage("삭제에 실패했습니다.");    
                                    }
                                })();
                                
                        }}>{"삭제"}</Button>
                        :<></>}
                    </div>
                    :<></>}
                </div>
                {isEditable && !isPreview
                ?<div className={"Body"}>
                    <TextField
                        id={uniqueId}
                        fullWidth
                        value={currentFeedback.body || ""}
                        multiline rows={6}
                        onScroll={()=>{
                            let textfield = document.getElementById(uniqueId);
                            if (textfield){
                                textfield.style.height = textfield.scrollHeight + "px";
                            }
                        }}
                        onChange={(event)=>{
                            setCurrentFeedback({...currentFeedback, body: event.target.value})
                            let textfield = document.getElementById(uniqueId);
                            if (textfield){
                                textfield.style.height = textfield.scrollHeight + "px";
                            }
                    }}/>
                </div>
                :<ReactMarkdown>
                    {currentWidth > 750
                        ?(currentFeedback.body || "")
                        :(currentFeedback.body || "").replace(/&mw=500/g, '&mw=200')}
                </ReactMarkdown>}
            </div>
        </Paper>
    </>)

}

export default Feedback;