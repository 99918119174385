import { useState, useEffect, useMemo, memo, Fragment } from 'react';

import './Viral.scss';
import { Button, Input, MenuItem, Select } from '@material-ui/core';

import { requestViralCollection, readViral, readCrawlList } from '../../../Data/Viral';
import { KeyboardArrowDown, UnfoldMore } from '@material-ui/icons';


const viralChannels = {
    "youtube": "유튜브",
    "naverNews": "네이버뉴스",
    "twitter": "트위터",
    "dcinside": "디시인사이드",
    "tiktok": "틱톡"
}

const Report = () => {

    const [ requestQuery, setRequestQuery ] = useState('');
    const [ searchQuery, setSearchQuery ] = useState('');
    const [ channel, setChannel ] = useState('tiktok');
    const [ viralData, setViralData ] = useState([]);
    const [ viralRequestData, setViralRequestData ] = useState([]);
    const [ filterCol, setFilterCol ] = useState({yyymmdd: true});
    
    useEffect(()=>{
        (async()=>{
            const ViralRequestRes = await readCrawlList();
            
            if ( ViralRequestRes && ViralRequestRes.length > 0 ) {
                setViralRequestData(ViralRequestRes);
            } else {
                console.error('loading failed...');
            }
        })()
    }, [])

    const handleSearchData = async () => {
        const viralRes = await readViral({query: searchQuery}); 
        
        if ( viralRes && viralRes.length > 0 ) {
            const parsedViralData = viralRes.map( viral => viral.data ? {...viral, data: JSON.parse(viral.data)} : viral );

            setViralData(parsedViralData);
        } else {
            console.error('loading failed')
        }
    };

    const rowHeaderSet = useMemo(() => new Set(...viralData.map( rowData => Object.keys(rowData))),[viralData]) ;
    const rowHeader = Array.from(rowHeaderSet).filter( key => key !== 'data');
    
    return (<div className={"ViralAnalyticsArea"}>
        <div className={'ControlContainer'} >
            <div className={"ViralRequestBox"}>
                <div className={"RequestTitle"}>Viral Analytics</div>
                <div className={'RequestInputBox'} >
                    <Input onChange={(e)=>{setRequestQuery(JSON.stringify(e.target.value));}}/>
                    <Select 
                        value={channel} 
                        onChange={(event) => setChannel(event.target.value) }
                    >
                        {Object.entries(viralChannels).map( ([key, label]) => (
                            <MenuItem value={key} key={key} >{label}</MenuItem>
                        ))}
                    </Select>
                    <div className={"RequestTitleButtonBox"}>
                        <Button className={'RequestTitleButton'} onClick={()=>{
                            (async()=>{
                                const result = await requestViralCollection({query: requestQuery, channel});

                                if(!result){
                                    alert('수집 요청 실패');
                                }else{
                                    setViralData([]);
                                }
                            })()
                        }}>
                            {"수집 요청"}
                        </Button>
                    </div>
                </div>
            </div>
            <div className={"ViralSearchBox"}  >
                <div className={"SearchTitle"}>Viral Search</div>
                <div className={"SearchInputBox"} >
                    <Input 
                        onChange={ (e)=> setSearchQuery(JSON.stringify(e.target.value)) }
                        onKeyUp={(e) => {
                            if ( e.key === 'Enter' ) {
                                handleSearchData();
                            }
                        }}
                    />
                    <div className={"ViralSearchBoxTitleButton"}>
                        <Button style={{border: '1px solid black'}} onClick={handleSearchData} >
                            {"검색"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div className={'ViralSearchResultWrapper'} >
            <div className={"ViralDataTableBox"} >
                <div className={"ViralMainTable"} >
                    <div className={'HeadRow'} >
                        {rowHeader.map( (key, ix) => (
                            <div className={'HeadCell'} key={`rowHeader:${key}`} style={{display: 'flex', justifySelf: 'space-between'}} >
                                <span>{key}</span>
                                {Object.keys(filterCol)[0] === key
                                    ? <KeyboardArrowDown 
                                        onClick={(e) => {
                                            // eslint-disable-next-line no-unused-vars
                                            const [ prevKey, _prevValue ] = Object.entries(filterCol)[0];

                                            if ( prevKey === key ) {
                                                setFilterCol( prev => ({[key]: !prev[key]}));
                                            } else {
                                                setFilterCol({ [key] : true });
                                            }
                                        }}
                                        style={
                                            !!filterCol[key] ? {
                                                transform: 'rotate(180deg)'
                                            }:{}
                                        }
                                        />
                                    : <UnfoldMore 
                                        onClick={(e)=>{
                                            setFilterCol({[key]: true}) 
                                        }} 
                                    />
                                    }
                            </div>
                        ))}
                    </div>
                    {viralData.sort((a,b) => {
                        const col = Object.keys(filterCol)[0];
                        if ( col === 'undefined' ) {
                            return 0;
                        } else {
                            if ( typeof a[col] === 'number' ) {
                                const aV = a[col] || 0;
                                const bV = b[col] || 0;
                                if ( bV - aV === 0 ){
                                    return 0;
                                }
                                return filterCol[col] ? bV - aV : aV - bV;
                            } else {
                                const aV = a[col] || "";
                                const bV = b[col] || "";
                                if ( bV - aV === 0 ){
                                    return 0;
                                }
                                return filterCol[col] ? aV<bV ? 1 : -1 : aV>bV ? 1 : -1;
                            }
                        }
                    }).map((viral, index)=> (<div key={`Row:${index}`} >
                        <div className={'Row'} >
                            {rowHeader.map( (key, CellIdx) => {
                                const value = viral[key] || 0;
                                return key !== "data" && 
                                    <div className={'Cell Value'} key={`Cell:${key}:${CellIdx}`} >
                                        {key === 'createdAt' || key === 'updatedAt' 
                                            ? (value ? new Date(Number(value) * 1000).toISOString().substring(0,10) : 0) 
                                            : value}
                                    </div>}
                            )}
                        </div>
                        {(viral.data || []).length > 0 && <div className={'Row RawData'} >
                            <RawDataTable data={viral.data} key={`RawDataTable:${index}`} />
                        </div>}
                    </div>))}
                </div>
            </div>
        </div>
        <div className={'ViralRequestResultWrapper'}>
            {(viralRequestData || []).length < 1 
                ? <div className={"ViralRequestResultTitle"}>{"수집 중"}</div> 
                : <div className={'ViralRequestTable'} >
                    <div className={"Row Header"}  >
                        <div className={'Cell Header'} >{"Channel"}</div>
                        <div className={'Cell Header'} >{"Query"}</div>
                    </div>
                    {viralRequestData.map( obj => Object.values(obj) ).map((keys, index) => (
                        <div className={'Row'} key={keys.join(":")}  >
                            <div className={'Cell'}  >{keys[1]}</div>
                            <div className={'Cell'}  >{keys[0]}</div>
                        </div>
                    ))}
                </div>}
        </div>
    </div>);
}

const RawDataTable = memo(({ data }) => {
    if ( !data ) {
        return;
    }
    
    const recursiveRender = ({ row }) => {
        if ( typeof row === 'string' || typeof row === 'number' ){
            return <div className={'InnerCell Value'} >
                <span >{row}</span>
            </div>
        }

        if ( typeof row !== 'object' || !row ) {
            return<></>;
        }
        
        return ( 
            <div className={'CellDataInnerTable'}  >
                {Object.entries(row).map( ([key, label]) => 
                    <div className={'InnerRow'} key={key} > 
                        <div className={'InnerCell'} >{key}</div>
                        {recursiveRender({row: label})}
                    </div>)}
            </div>
        )
    }

    return (<div className={'DataCell'}  >
        {data.map( (dataRow, idx) => 
            <div className={'CellDataTable'} key={`${dataRow['id']||dataRow['href']||dataRow['full_text']||""}:${dataRow['createdAt']||dataRow['created_At']||""}:${idx}`} >
                {recursiveRender({row: dataRow})}
            </div>
        )}
    </div>)
});


export default Report;