import React from 'react';
import { useDispatch } from 'react-redux';

import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip} from '@material-ui/core';

import {listContiAnalyticsProcess, updateContiAnalyticsProcess, readContiAnalyticsProcessResult} from './Data/ContiAnalyticsProcess';
import {getTitles} from './Data/Title';
import LoadingCircle from '../../../Component/LoadingCircle';
import EditableTable from '../../../Component/Table/EditableTable';

import {FilteredApplication, FeedbackResult} from '../../Email';

import './ContiAnalyticsProcess.scss';
import moment from 'moment-timezone';

import {fn, randomString} from '../../../Functions';
import AnalysisLaunching from './AnalysisLaunching';

const currentStartingFriday = ((curr)=>(new Date(curr - ( ( ( (curr.getDay() + 2) * 24 + curr.getHours() ) * 60 + curr.getMinutes() ) * 60 + curr.getSeconds() ) * 1000 - curr.getMilliseconds() ) ) )(new Date())
const competitionGroups = Array(54).fill(0).map((row, idx) => [
    new Date(currentStartingFriday - (idx - 1) * 7 * 24 * 3600 * 1000).getTime() / 1000,
    new Date(currentStartingFriday - (idx - 1 - 1) * 7 * 24 * 3600 * 1000).getTime() / 1000,
    `${(new Date(currentStartingFriday - (idx - 1 - 1) * 7 * 24 * 3600 * 1000).getMonth() + 1)}월 ${Math.ceil(new Date(currentStartingFriday - (idx - 1 - 1) * 7 * 24 * 3600 * 1000).getDate() / 7)}주차`
])

const AnalyticsProcess = () => {
    let dispatch = useDispatch();

    const [isLoading, setIsLoading] = React.useState(false);

    const [candidates, setCandidates] = React.useState([]);
    const [titleInfo, setTitleInfo] = React.useState({});

    const [refreshTimer, setRefreshTimer] = React.useState(null);

    const [results, setResults] = React.useState([]);

    const [askStartAnalysis, setAskStartAnalysis] = React.useState(false);
    const [currentAnalysisTarget, setCurrentAnalysisTarget] = React.useState({});

    const [emailCheck, setEmailCheck] = React.useState(false);
    const [emailSendCallback, setEmailSendCallback] = React.useState(()=>()=>{});
    const [currentEmailTarget, setCurrentEmailTarget] = React.useState({});
    const [currentEmailProgress, setCurrentEmailProgress] = React.useState('');

    const [askOnceAgain, setAskOnceAgain] = React.useState(false);
    const [postponedAction, setPostonedAction] = React.useState(()=>()=>{});
    const [postponedMessage, setPostponedMessage] = React.useState("");

    const [filterDeleted, setFilterDeleted] = React.useState(true);
    const [filterTest, setFilterTest] = React.useState(true);
    
    React.useEffect(()=>{
        document.title = `분석 + 피드백`;

        (async ()=>{
            setIsLoading(true);

                let candi = await listContiAnalyticsProcess();
                setCandidates(candi);

                let titleList = await getTitles(candi);

                let titleObj = titleList.map(row => ({[`${row.serviceId}:${row.titleId}`]: row})).reduce((a,b)=> Object.assign(a,b), {});
                setTitleInfo(titleObj);

                let resultList = await readContiAnalyticsProcessResult(candi);
                let resultObj = resultList.map(({serviceId, titleId, episodeId, snapshotId, ...other}) => ({[[serviceId, titleId, episodeId, snapshotId].join(':')]: {serviceId, titleId, episodeId, snapshotId, ...other}})).reduce((a,b)=> Object.assign(a,b), {});
                setResults(resultObj);
            setIsLoading(false);
        })();
        
    },[dispatch])

    const updateRow = async (row) => {

        const index = candidates.map(
            ({serviceId, titleId, episodeId, snapshotId}) => [serviceId, titleId, episodeId, snapshotId].join(':')
        ).indexOf(`${row.serviceId}:${row.titleId}:${row.episodeId}:${row.snapshotId}`)

        if (refreshTimer){
            clearTimeout(refreshTimer);
            setRefreshTimer(null);
        }

        setCandidates([
            ...candidates.slice(0,index),
            row,
            ...candidates.slice(index+1)
        ])
        updateContiAnalyticsProcess(row);
        
        const timer = setTimeout(()=>{
            (async () => {
                setIsLoading(true);
    
                let candi = await listContiAnalyticsProcess();
                setCandidates(candi);
        
                setIsLoading(false);

                setRefreshTimer(null);
            })();
        },2000)

        setRefreshTimer(timer);
    }

    return (<div className={"AnalyticsProcess"}>
        <div style={{display: 'flex', flexDirection:'row-reverse', paddingRight: 20, justifyContent:'flex-start', alignItems:'center'}}>
            <Checkbox checked={filterDeleted} onChange={(event, checked) => setFilterDeleted(checked)}/>
            <label>{"삭제된 항목 숨기기"}</label>
            <br/>
            <Checkbox checked={filterTest} onChange={(event, checked) => setFilterTest(checked)}/>
            <label>{"테스트 항목 숨기기"}</label>
        </div>
        {competitionGroups.map(([startTS, endTS, weekIter]) => {
            let subsetCandidates = candidates.filter(row =>
                startTS < row.createdAt && row.createdAt < endTS
                && (!filterDeleted || titleInfo[`${row.serviceId}:${row.titleId}`])
                && (!filterTest || !titleInfo[`${row.serviceId}:${row.titleId}`] || titleInfo[`${row.serviceId}:${row.titleId}`].title.indexOf('(테스트)') < 0)
            );

            if (subsetCandidates.length === 0){
                return <div key={randomString(16)}></div>;
            }

            return <div key={`${weekIter} subset conti`} className={"AnalyticsProcessSubset"}>
                <h2 className={"SubsetHeader"}>{weekIter}</h2><span>{`${
                        ((num)=>(num>0?`심의중: ${num} 개`:``))(subsetCandidates.filter(row => !row.validatedAt).length)
                    } ${
                        ((num)=>(num>0?`분석대기: ${num} 개`:``))(subsetCandidates.filter(row => !row.testStartedAt && row.vaildatedAt).length)
                    } ${
                        ((num)=>(num>0?`공지대기: ${num} 개`:``))(subsetCandidates.filter(row => !row.resultConfirmedAt && row.testStartedAt && row.validatedAt).length)
                    } ${
                        ((num)=>(num>0?`처리완료: ${num} 개`:``))(subsetCandidates.filter(row => row.resultConfirmedAt || (row.validatedAt && !row.isValid)).length)
                    } `}</span>
                <EditableTable
                    headerButtonHeaders={[]}
                    headerButtonsFunction={()=>[]}
                    defaultSortCol={[1, "asc"]}
                    data={subsetCandidates
                        .map(candidate => {
                            let title = titleInfo[`${candidate.serviceId}:${candidate.titleId}`];
                            let result = results[[candidate.serviceId, candidate.titleId, candidate.episodeId, candidate.snapshotId].join(':')] || {};
                            return {...title, ...candidate, ...result};
                        })}
                    columnHeaders={["제목", "생성일", "광고컷", "필터링", "설문지 여부", "설문지 작성", "테스트 집행", "결과 검토"]}
                    columnsFunction={(row, index) => [
                        {isEditing: false, field: "title", type: 'default', style: {maxWidth: '20vw', borderBottom: '1px lightgray solid', margin: 4, whiteSpace: 'nowrap'},
                            defaultValue: <Tooltip title={row.title}><div style={{display:'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div style={{whiteSpace:'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis',}}>{row.title || `${row.author}의 작품` || '(삭제됨)'}</div>
                                <div>
                                    <IconButton size={"small"} href={`/challenge/contents/${row.serviceId}/${row.titleId}`} onClick={fn.gotoByAnchor}>📃</IconButton>
                                    <IconButton href={`/challenge/report/${row.serviceId}/${row.titleId}/${row.episodeId}/${row.snapshotId}`} onClick={fn.gotoByAnchor} size={"small"}>🎢</IconButton>
                                </div>
                            </div></Tooltip> },
                        {isEditing: false, field: "createdAt", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',}, defaultValue: moment(row.createdAt * 1000).format('YYYY-MM-DD')},
                        {isEditing: false, field: "slides", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',},
                            defaultValue: (!row.slides || !row.slides.length)?"🚫":"🟢",},
                        {isEditing: false, field: "validatedAt", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',},
                            defaultValue: !row.validatedAt?<>
                                <IconButton size={"small"} onClick={()=>{updateRow({...row, validatedAt: new Date().getTime() / 1000, isValid: true})}}>✅</IconButton>
                                <IconButton size={"small"} onClick={()=>{
                                    setCurrentEmailProgress("filtered");
                                    setCurrentEmailTarget({...row, weekIter});
                                            
                                    setEmailSendCallback(()=>
                                        (success, message)=>{
                                            updateRow({...row, validatedAt: new Date().getTime() / 1000, isValid: false, rejectReason: message})
                                            setEmailCheck(false);
                                        }
                                    );

                                    setEmailCheck(true);
                                    
                                }}>❌</IconButton>
                                <IconButton size={"small"} onClick={()=>{
                                    setAskOnceAgain(true);
                                    setPostonedAction(()=>()=>{
                                        updateRow({...row, validatedAt: new Date().getTime() / 1000, isValid: false, rejectReason: "무시(메일X)"})
                                    })
                                    setPostponedMessage(<div>
                                        {row.title}<br/>
                                        {"이메일을 발송하지 않고 무시처리 합니다."}<br/>
                                    </div>)
                                }} > 🟠 </IconButton>
                            </>:<>
                                {row.isValid?"🟢":<>
                                    {"🚫"}
                                    <Tooltip title={row.rejectReason || ""}><span style={{display: "inline-block", whiteSpace:"nowrap", verticalAlign: "middle", maxWidth: 90, overflow: 'hidden', textOverflow: 'ellipsis'}}>{row.rejectReason||""}</span></Tooltip>
                                </>}
                                <IconButton size={"small"} style={{fontSize: '0.6rem'}} onClick={()=>{
                                    setAskOnceAgain(true);
                                    setPostonedAction(()=>()=>{
                                        updateRow({...row, validatedAt: null, isValid: null, rejectReason: null})
                                    })
                                    setPostponedMessage(<div>
                                        {row.title}<br/>
                                        {row.isValid?"🟢":"🚫"}<br/>
                                        {"원고 심의결과를 취소하고 다시 검토합니다."}<br/>
                                    </div>)
                                }}>🔙</IconButton>
                            </>},
                        {isEditing: false, filed: "isSurveyRequired", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',},
                            defaultValue: <>
                                {row.isSurveyRequired?"🟢":"🚫"}
                                <IconButton size={"small"} style={{fontSize: '0.6rem'}} onClick={ () => {
                                    setAskOnceAgain(true);
                                    setPostonedAction(()=>()=>{
                                        updateRow({...row, isSurveyRequired: !row.isSurveyRequired});
                                    })
                                    setPostponedMessage(<div>
                                        {`설문조사를 ${!row.isSurveyRequired?"신청합니다":"신청하지 않습니다"}`}
                                    </div>)
                                }} > 🔙 </IconButton>
                            </>
                        },
                        {isEditing: false, filed: "fillOutSurvey", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',},
                            defaultValue: !row.isSurveyRequired?"🚫":
                            <IconButton size={"small"} style={{fontSize: '0.8rem'}} onClick={() => {
                                fn.goto(`/challenge/survey/${row.snapshotId}`);
                            }}>📝</IconButton>,},
                        {isEditing: false, field: "testStartedAt", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',},
                            defaultValue: 
                                !row.isValid?"🚫"
                                :!row.testStartedAt?<IconButton size={"small"} style={{fontSize: '0.8rem'}} onClick={()=>{
                                    setCurrentAnalysisTarget({...row, target: "gender"});
                                    setAskStartAnalysis(true);
                                }}>📩</IconButton>
                                :<>
                                    {(new Date().getTime() / 1000 - row.testStartedAt < 24 * 60 * 60)?"🕝":"🟢"}
                                    <IconButton size={"small"} style={{fontSize: '0.6rem'}} onClick={()=>{
                                        setAskOnceAgain(true);
                                        setPostonedAction(()=>()=>{
                                            updateRow({...row, testStartedAt: null})
                                        })
                                        setPostponedMessage(<div>
                                            {row.title}<br/>
                                            {"데이터분석을 다시 집행합니다.(광고는 취소되지 않음)"}<br/>
                                        </  div>)
                                    }}>🔙</IconButton>
                                </>},
                        {isEditing: false, field: "resultConfirmedAt", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center', width: 150, marginLeft: 'auto', marginRight: 'auto'},
                            defaultValue: !row.resultConfirmedAt?
                            !row.isValid?
                            !row.validatedAt?<>
                                {"필터링 대기중"}
                            </>:<>
                                {"제외 대상"}
                            </>:
                            !row.CTR?<>
                                {"대기중"}
                            </>:<>
                                {`${('' + row.CTR * 100).substring(0,3)}% / ${('' + row.fullReadRate * 100).substring(0,4)}% / ${('' + row.nextClickRate * 100).substring(0,4)}%`}
                                <IconButton size={"small"} onClick={()=>{
                                    setCurrentEmailProgress("resultConfirmed");
                                    setCurrentEmailTarget({...row, weekIter});
                                            
                                    setEmailSendCallback(()=>
                                        ()=>{
                                            updateRow({...row, resultConfirmedAt: new Date().getTime() / 1000})
                                            setEmailCheck(false);
                                        }
                                    );

                                    setEmailCheck(true);
                                }}>✅</IconButton>
                            </>:<>
                                {`${('' + row.CTR * 100).substring(0,3)}% / ${('' + row.fullReadRate * 100).substring(0,4)}% / ${('' + row.nextClickRate * 100).substring(0,4)}%`}
                                <IconButton size={"small"} style={{fontSize: '0.6rem'}} onClick={()=>{
                                    setAskOnceAgain(true);
                                    setPostonedAction(()=>()=>{
                                        updateRow({...row, resultConfirmedAt: null})
                                    })
                                    setPostponedMessage(<div>
                                        {row.title}<br/>
                                        {`${('' + row.CTR * 100).substring(0,3)}% / ${('' + row.fullReadRate * 100).substring(0,4)}% / ${('' + row.nextClickRate * 100).substring(0,4)}%`}<br/>
                                        {"분석결과 공지를 취소하고 다시 검토합니다."}<br/>
                                    </div>)
                                }}>🔙</IconButton>
                            </>},
                    ]}
                    updateGenerator={(row, index)=> (newValue)=>{
                        updateRow({...row, ...newValue})
                    }}
                />
            </div>
        })}
        <Dialog open={askStartAnalysis} maxWidth={"md"} onClose={()=>{setAskStartAnalysis(false); setCurrentAnalysisTarget({})}}>
            <AnalysisLaunching
                candidate={currentAnalysisTarget}
                callback={()=>{
                    setAskStartAnalysis(false);
                    setCurrentAnalysisTarget({});
                    
                    if (refreshTimer){
                        clearTimeout(refreshTimer);
                        setRefreshTimer(null);
                    }

                    const timer = setTimeout(()=>{
                        (async () => {
                            setIsLoading(true);

                            let candi = await listContiAnalyticsProcess();
                            setCandidates(candi);
                    
                            setIsLoading(false);

                            setRefreshTimer(null);
                        })();
                    },5000)

                    setRefreshTimer(timer);
                }}/>
        </Dialog>

        <Dialog open={emailCheck} maxWidth={"md"} onClose={()=>{setEmailCheck(false)}}>
            <DialogTitle>{"이메일 발송"}</DialogTitle>
            <DialogContent style={{backgroundColor:'rgb(233,233,233)'}}>
                <div>
                    {currentEmailProgress === "filtered"?<FilteredApplication
                        email={currentEmailTarget.email} title={currentEmailTarget.title} author={currentEmailTarget.author}
                        sendCallback={emailSendCallback}
                    />
                    :currentEmailProgress === "resultConfirmed"?<FeedbackResult
                        email={currentEmailTarget.email} title={currentEmailTarget.title} author={currentEmailTarget.author}
                        analysisResultLocation={`https://challenge.webtoon.today/report/${currentEmailTarget.serviceId}/${currentEmailTarget.titleId}/${currentEmailTarget.episodeId}/${currentEmailTarget.snapshotId}`}
                        sendCallback={emailSendCallback}
                    />:<></>}
                </div>
            </DialogContent>
        </Dialog>
        <Dialog open={askOnceAgain}>
            <DialogTitle>{"다음 동작을 확인해주세요."}</DialogTitle>
            <DialogContent>{postponedMessage}</DialogContent>
            <DialogActions>
                <Button color={"primary"} variant={"contained"} onClick={()=>{
                    postponedAction();
                    setPostonedAction(()=>()=>{});
                    setPostponedMessage("");
                    setAskOnceAgain(false);
                }}>{"실행"}</Button>
                <Button color={"secondary"} variant={"outlined"} onClick={()=>{
                    setPostonedAction(()=>()=>{});
                    setPostponedMessage("");
                    setAskOnceAgain(false);
                }}>{"취소"}</Button>
            </DialogActions>
        </Dialog>
        <LoadingCircle show={isLoading}/>
    </div>);
}

export default AnalyticsProcess;