import React from 'react';
import EditableTable from '../../../Component/Table/EditableTable';
import { getTitles } from '../Challenge/Data/Title';
import { listMarketAnalysis, readTitleTopic, updateMarketAnalysis } from './Data/MarketAnalyticsProcess';
import moment from 'moment-timezone';
import { fn, randomString } from '../../../Functions';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@material-ui/core';
import LoadingCircle from '../../../Component/LoadingCircle';
import FilteredApplication from './MarketEmail/FilteredApplication';
import SubmittedApplication from './MarketEmail/SubmittedApplication';
import ConfirmedApplication from './MarketEmail/ConfirmedApplication';


const currentStartingFriday = ((curr)=>(new Date(curr - ( ( ( (curr.getDay() + 2) * 24 + curr.getHours() ) * 60 + curr.getMinutes() ) * 60 + curr.getSeconds() ) * 1000 - curr.getMilliseconds() ) ) )(new Date())
const marketAnalysisGroups = Array(54).fill(0).map((row, idx) => [
    new Date(currentStartingFriday - (idx - 1) * 7 * 24 * 3600 * 1000).getTime() / 1000,
    new Date(currentStartingFriday - (idx - 1 - 1) * 7 * 24 * 3600 * 1000).getTime() / 1000,
    `${(new Date(currentStartingFriday - (idx - 1 - 1) * 7 * 24 * 3600 * 1000).getMonth() + 1)}월 ${Math.ceil(new Date(currentStartingFriday - (idx - 1 - 1) * 7 * 24 * 3600 * 1000).getDate() / 7)}주차`
])

const MarketAnalyticsProcess = () => {

    const [ isLoading, setIsLoading ] = React.useState(false);
    const [ candidates, setCandidates ] = React.useState([]);
    const [ titleInfo, setTitleInfo ] = React.useState({});
    const [ topicInfo, setTopicInfo ] = React.useState({});

    const [ refreshTimer, setRefreshTimer ] = React.useState(null);

    const [emailCheck, setEmailCheck] = React.useState(false);
    const [emailSendCallback, setEmailSendCallback] = React.useState(()=>()=>{});
    const [currentEmailTarget, setCurrentEmailTarget] = React.useState({});
    const [currentEmailProgress, setCurrentEmailProgress] = React.useState('submitted');

    const [askOnceAgain, setAskOnceAgain] = React.useState(false);
    const [postponedAction, setPostonedAction] = React.useState(()=>()=>{});
    const [postponedMessage, setPostponedMessage] = React.useState("");    
    
    const [filterDeleted, setFilterDeleted] = React.useState(true);
    const [filterTest, setFilterTest] = React.useState(true);
    const [filterFiltered, setFilterFiltered] = React.useState(true);

    React.useEffect(()=>{
        ( async () => {
            setIsLoading(true);

            let newCandidates = await listMarketAnalysis();
            
            // 임시로 candi 지정 나중에 필터 조건 변경
            newCandidates = newCandidates.filter(row => row.serviceId && row.titleId && row.author);
            setCandidates(newCandidates);
            
            let titleList = await getTitles(newCandidates);
            
            let titleObj = titleList.map(row => ({[`${row.serviceId}:${row.titleId}`]: row})).reduce( (a,b) => Object.assign(a,b), {});

            setTitleInfo(titleObj);

            const topicTitles = titleList.map( ({serviceId, titleId}) => ({serviceId, titleId}) );
            let topics = await readTitleTopic(topicTitles);

            let topicObj = topics.map(row => ({[`${row.serviceId}:${row.titleId}`]: row})).reduce( (a,b) => Object.assign(a,b), {});
            setTopicInfo(topicObj);

            setIsLoading(false);
        })()
    },[])
    
    const updateRow = async (row) => {

        const index = candidates.findIndex(({serviceId, titleId}) => serviceId === row.serviceId && titleId === row.titleId)

        if (refreshTimer){
            clearTimeout(refreshTimer);
            setRefreshTimer(null);
        }

        setCandidates([
            ...candidates.slice(0,index),
            row,
            ...candidates.slice(index+1)
        ])
        updateMarketAnalysis(row);
        
        const timer = setTimeout(()=>{
            (async () => {
                setIsLoading(true);
    
                let candi = await listMarketAnalysis();
                setCandidates(candi);
        
                setIsLoading(false);

                setRefreshTimer(null);
            })();
        },5000)

        setRefreshTimer(timer);
    };

    return(<div className={"MarketAnalysis"}>
        <div style={{display: 'flex', flexDirection:'row-reverse', paddingRight: 20, justifyContent:'flex-start', alignItems:'center'}}>
            <Checkbox checked={filterDeleted} onChange={(event, checked) => setFilterDeleted(checked)}/>
            <label>{"삭제된 항목 숨기기"}</label>
            <Checkbox checked={filterTest} onChange={(event, checked) => setFilterTest(checked)}/>
            <label>{"테스트 항목 숨기기"}</label>
            <Checkbox checked={filterFiltered} onChange={(event, checked) => setFilterFiltered(checked)}/>
            <label>{"필터링된 항목 숨기기"}</label>
        </div>
        {marketAnalysisGroups.map(([startTS, endTS, weekIter]) => {
            let subsetCandidates = candidates.filter(row => 
                (!filterDeleted || titleInfo[`${row.serviceId}:${row.titleId}`])
             && (!filterFiltered || !row.validatedAt || row.isValid)
             && (!filterTest || !titleInfo[`${row.serviceId}:${row.titleId}`] || (titleInfo[`${row.serviceId}:${row.titleId}`].title || '').indexOf('(테스트)') < 0)
             && (startTS < row.createdAt && row.createdAt < endTS && titleInfo[`${row.serviceId}:${row.titleId}`]) );

            if (subsetCandidates.length === 0) {
                return <div key={randomString(16)}></div>;
            }
            
            return <div key={`${startTS}:${endTS}:${weekIter}`}>
                <h2>{weekIter}</h2><span>{`${
                        ((num)=>(num>0?`전체: ${num} 개`:``))(subsetCandidates.length)
                    } ${
                        ((num)=>(num>0?`심의중: ${num} 개`:``))(subsetCandidates.filter(row => !row.validatedAt).length)
                    } ${
                        ((num)=>(num>0?`분석대기: ${num} 개`:``))(subsetCandidates.filter(row => !row.testStartedAt && row.vaildatedAt).length)
                    } ${
                        ((num)=>(num>0?`검토중: ${num} 개`:``))(subsetCandidates.filter(row => !row.resultConfirmedAt && row.testStartedAt && row.validatedAt).length)
                    } ${
                        ((num)=>(num>0?`공지대기: ${num} 개`:``))(subsetCandidates.filter(row => !row.resultNotifiedAt && row.isValid && row.resultConfirmedAt).length)
                    } ${
                        ((num)=>(num>0?`처리완료: ${num} 개`:``))(subsetCandidates.filter(row => row.resultNotifiedAt || (row.validatedAt && !row.isValid)).length)
                    }`}</span>
                <EditableTable
                    headerButtonHeaders={[]}
                    headerButtonsFunction={()=>[]}
                    isDownloadable={true}
                    defaultSortCol={[1, "desc"]}
                    data={subsetCandidates
                        .filter(candidate => titleInfo[`${candidate.serviceId}:${candidate.titleId}`])
                        .map(candidate => {
                            let title = titleInfo[`${candidate.serviceId}:${candidate.titleId}`];
                            let topic = topicInfo[`${candidate.serviceId}:${candidate.titleId}`];
                            
                            return {...title, ...candidate, ...topic};
                        })
                    }
                    columnHeaders={["제목", "생성일", "필터링", "태깅", "결과 공지", "투고 검토"]}
                    columnsFunction={(row, index) => [
                        {isEditing: false, field: "title", type: 'default', style: {maxWidth: 150, padding: 5, whiteSpace: 'nowrap', margin: '0 auto'},
                            defaultValue: <Tooltip title={row.title}><div style={{display:'flex', flexDirection: 'row'}}>
                            <div style={{whiteSpace:'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis',}}>{row.title}</div>
                            <IconButton size={"small"} href={`/challenge/contents/${row.serviceId}/${row.titleId}#meta`} onClick={fn.gotoByAnchor}>📃</IconButton>
                            <IconButton href={`https://challenge.webtoon.today/dashboard/${row.serviceId}/${row.titleId}`} onClick={fn.gotoByAnchor} size={"small"}>🎢</IconButton>
                        </div></Tooltip> },
                        {isEditing: false, field: "createdAt", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',}, defaultValue: moment(row.createdAt * 1000).format('YYYY-MM-DD')},
                        {isEditing: false, field: "submmited", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',},
                            defaultValue: !row.validatedAt?<>
                            <IconButton size={"small"} onClick={ () => updateRow({...row, validatedAt: Math.floor(new Date().getTime() / 1000), isSubmitted: true, isValid: true })}>✅</IconButton>
                            <IconButton size={"small"} onClick={ () => {
                                setCurrentEmailProgress("filtered");
                                setCurrentEmailTarget({...row, weekIter});

                                setEmailSendCallback( () =>
                                    (success, message) => {
                                        updateRow({ ...row, validatedAt: new Date().getTime() / 1000, isValid: false, rejectReason: message});
                                        setEmailCheck(false);
                                    }
                                );

                                setEmailCheck(true);
                                
                            }}>❌</IconButton>
                            <IconButton size={"small"} onClick={()=>{
                                setAskOnceAgain(true);
                                setPostonedAction(()=>()=>{
                                    updateRow({...row, validatedAt: new Date().getTime() / 1000, isValid: false, rejectReason: "무시(메일X)"})
                                })
                                setPostponedMessage(<div>
                                    {row.title}<br/>
                                    {"이메일을 발송하지 않고 무시처리 합니다."}<br/>
                                </div>)
                            }} > 🟠 </IconButton>
                            </>:<>
                                {row.isValid?"🟢":<>
                                    {"🚫"}
                                    <Tooltip title={row.rejectReason || ""}><span style={{display: "inline-block", whiteSpace:"nowrap", verticalAlign: "middle", maxWidth: 90, overflow: 'hidden', textOverflow: 'ellipsis'}}>{row.rejectReason||""}</span></Tooltip>
                                </>}
                                <IconButton size={"small"} style={{fontSize: '0.6rem'}} onClick={()=>{
                                    setAskOnceAgain(true);
                                    setPostonedAction(()=>()=>{
                                        updateRow({...row, validatedAt: null, isValid: null})
                                    })
                                    setPostponedMessage(<div>
                                        {row.title}<br/>
                                        {row.isValid?"🟢":"🚫"}<br/>
                                        {"원고 심의결과를 취소하고 다시 검토합니다."}<br/>
                                    </div>)
                                }}>🔙</IconButton>
                        </> },
                        {isEditing: false, field: "description", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',},
                            defaultValue: 
                                (!row.historicalSetting
                                || !row.fictionalSetting 
                                || !row.genre
                                || !row.perspective
                                || !row.deployment
                                || (row.topic || []).length < 1
                                || !row.drawingStyle)
                                    ?"🚫":"🟢",},
                        {isEditing: false, field: "resultConfirmedAt", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center', width: 150, marginLeft: 'auto', marginRight: 'auto'},
                            defaultValue: !row.resultConfirmedAt?
                                (!row.historicalSetting
                                || !row.fictionalSetting 
                                || !row.genre
                                || !row.perspective
                                || !row.deployment
                                || !row.drawingStyle
                                || (row.topic || []).length < 1
                                || !row.isSubmitted)?
                                !row.isValid?
                                !row.validatedAt?<>
                                    {'필터링 대기중'}
                                </>:<>
                                    {'제외 대상'}
                                </>:<>
                                    {'토픽/메타 태깅 대기중'}
                                </>:<>
                                    <IconButton size={"small"} onClick={() => {
                                       setCurrentEmailProgress("resultConfirmed")
                                       setCurrentEmailTarget({...row, weekIter});
                        
                                       setEmailSendCallback( () => 
                                           () => {
                                               updateRow({...row, resultConfirmedAt: new Date().getTime() / 1000});
                                               setEmailCheck(false);
                                           }
                                       )
                        
                                       setEmailCheck(true);
                                    }}>✅</IconButton>
                                </>:<>
                                    <IconButton size={"small"} style={{fontSize: '0.6rem'}} onClick={ () => {
                                        setAskOnceAgain(true);
                                        setPostonedAction(() => () => {
                                            updateRow({...row, resultConfirmedAt: null});
                                        })
                                        setPostponedMessage(<div>
                                            {row.title}<br/>
                                            {"시장 분석을 취소하고 다시 검토합니다."}<br/>
                                        </div>)
                                    }}>🔙</IconButton>
                                </>},
                        {isEditing: false, field: "validatedAt", type: 'default', style: {padding: 5, whiteSpace: 'nowrap', textAlign: 'center',},
                            defaultValue: row.isSubmmited?<>
                            <IconButton size={"small"} onClick={ () => updateRow({...row, validatedAt: new Date().getTime() / 1000, isvalid: true })}>✅</IconButton>
                            <IconButton size={"small"} onClick={ () => {
                                setCurrentEmailProgress("submmited");
                                setCurrentEmailTarget({...row, weekIter});

                                setEmailSendCallback( () =>
                                    (success, message) => {
                                        updateRow({ ...row, isSubmitted: false});
                                        setEmailCheck(false);
                                    } 
                                );

                                setEmailCheck(true);
                                
                            }}>❌</IconButton>
                            <IconButton size={"small"} onClick={()=>{
                                setAskOnceAgain(true);
                                setPostonedAction(()=>()=>{
                                    updateRow({...row, isSubmitted: false});
                                })
                                setPostponedMessage(<div>
                                    {row.title}<br/>
                                    {"이메일을 발송하지 않고 무시처리 합니다."}<br/>
                                </div>)
                            }} > 🟠 </IconButton>
                            </>:<>
                                {"🚫"}
                            </> },
                    ]}
                    updateGenerator={(row, index) => (newValue) => {
                        updateRow({...row, ...newValue})
                    }}
                />
            </div>
        })}
        <Dialog open={emailCheck} maxWidth={"md"} onClose={ () => setEmailCheck(false) }>
            <DialogTitle>{"이메일 발송"}</DialogTitle>
            <DialogContent style={{backgroundColor :'rgb(233,233,233)'}}>
                <div>
                    {currentEmailProgress === "filtered"?<FilteredApplication
                        serviceId={currentEmailTarget.serviceId} titleId={currentEmailTarget.titleId}
                        email={currentEmailTarget.email} title={currentEmailTarget.title} author={currentEmailTarget.author}
                        sendCallback={emailSendCallback}
                    />:<></>}
                    {currentEmailProgress === "submmited"?<SubmittedApplication
                        email={currentEmailTarget.email} title={currentEmailTarget.title} author={currentEmailTarget.author}
                        sendCallback={emailSendCallback}
                    />:<></>}
                    {currentEmailProgress === "resultConfirmed"?<ConfirmedApplication
                        serviceId={currentEmailTarget.serviceId} titleId={currentEmailTarget.titleId}
                        email={currentEmailTarget.email} title={currentEmailTarget.title} author={currentEmailTarget.author}
                        sendCallback={emailSendCallback}
                    />:<></>}
                </div>
            </DialogContent>
        </Dialog>
        <Dialog open={askOnceAgain}>
            <DialogTitle>{"다음 동작을 확인해주세요."}</DialogTitle>
            <DialogContent>{postponedMessage}</DialogContent>
            <DialogActions>
                <Button color={"primary"} variant={"contained"} onClick={()=>{
                    postponedAction();
                    setPostonedAction(()=>()=>{});
                    setPostponedMessage("");
                    setAskOnceAgain(false);
                }}>{"실행"}</Button>
                <Button color={"secondary"} variant={"outlined"} onClick={()=>{
                    setPostonedAction(()=>()=>{});
                    setPostponedMessage("");
                    setAskOnceAgain(false);
                }}>{"취소"}</Button>
            </DialogActions>
        </Dialog>
        <LoadingCircle show={isLoading} />
    </div>)
};

export default MarketAnalyticsProcess;