import React from 'react';
import { Button } from '@material-ui/core';
import EditableField from './EditableField';
import EditableDateField from './EditableDateField';
import EditableSelectField from './EditableSelectField';
import DummyField from './DummyField';
import EditableFileField from './EditableFileField';

/**
 * @typedef {import('../../Data/Creditcard/History').cardHistoryType} cardHistoryType
 * @param {{
    *   style: any,
    *   fields: {
    *     isEditing: boolean,
    *     type: 'select'|'date'|'file'|'default'|null,
    *     defaultValue: any,
    *     field: string,
    *     options: {key: string, value: string|number}[],
    *     update: (newValue: {}) => void|null,
    *   }[],
    *   update: (newValue: {}) => void,
    *   headerButtons: {
    *     name: string,
    *     color: string,
    *     onClick: (event: Event)=>void,
    *   }[]
    * }} props
   */
const EditableRow = (props) => {
    return (
        <tr
            style={Object.assign({margin: 2, backgroundColor:props.isHeader?"#333333":"#FFFFFF", color:!props.isHeader?"#333333":"#FFFFFF" },props.style || {})}
        >
            {props.headerButtons.map(({name, color, onClick, props}, index) =>
                <td key={"headerButton:"+index}><Button {...(props || {})} color={color} onClick={onClick} >
                    {name}
                </Button></td>
            )}
            {props.fields.map((option)=> {
                const {field,type} = option
                const Editable = type==='select'?EditableSelectField
                            :type==='file'?EditableFileField
                            :type==='date'?EditableDateField
                            :type===null?DummyField
                            :EditableField
                return (
                    <td
                        style={{paddingLeft:10, paddingRight:10}}
                        key={(props.rowId || '') + field}
                    >
                        <Editable {...({update: props.update, ...option})} />
                    </td>
                );
            })}
        </tr>
    );
}

export default EditableRow;