import {useState, useEffect} from 'react';

function useOnScreen(ref) {
    const [isIntersecting, setIntersecting] = useState(false)
  
    const observer = (global && global.IntersectionObserver)?(new global.IntersectionObserver(
          ([entry]) => setIntersecting(entry.isIntersecting)
    )):{observe: ()=>{}, disconnect: ()=>{}}
  
    useEffect(() => {
        observer.observe(ref.current)
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!global.IntersectionObserver){
        return false;
    }else{  
        return isIntersecting;
    }
}

export {useOnScreen};