import React from 'react';
import VirtualRouter from './VirtualRouter';

import store from './store';
import {logBehavior,} from './Data/Behavior';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import ss from './Data/Session';
import { Provider } from 'react-redux';

import { createTheme, MuiThemeProvider } from '@material-ui/core';



/**
 * 
 * @typedef {{
  *   isClient: boolean, injectedLocation: string,
  *   session: import('./Data/Session').sessionType,
  * }} globalDataType 
  */

/**
 * 
 * @param {{isClient: boolean, injectedLocation: string}} props
 * @return {JSX.Element}
 */
const App = (props) => {

    /** @type {[sessionType, (newSession:sessionType)=>void]} */
    const [session, setSession] = React.useState({});

    React.useEffect(()=>{
        (async()=>{
            await ss.sessionRefresh();
        })();
        const sessionUpdate = ()=>{
            setSession(ss.getCurrentSession());
            if (ss.getCurrentSession().userid){
                logBehavior("login", {});
            }
        }
        ss.registerSessionCallback(sessionUpdate)
    },[]);

    /** @type {globalDataType} */
    const routingProps = {
        isClient: props.isClient, injectedLocation: props.injectedLocation,
        session,
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: 'rgb(0,176,240)',
            }
        },
    })

    return (
        <Provider store={store}>
            <MuiThemeProvider theme={theme}>
                <DndProvider backend={HTML5Backend}>
                    <div className="App">
                        <VirtualRouter {...routingProps} />
                    </div>
                </DndProvider>
            </MuiThemeProvider>
        </Provider>
    );
}

export default App;
