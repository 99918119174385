
import React from 'react';
import { readContents } from '../../Data/Contents';
import { readContentsStat } from '../../Data/ContentsStat';

import './GraphOnlyReport.scss';
import LoadingCircle from '../../Component/LoadingCircle';
import { Tooltip } from '@material-ui/core';
import { Equalizer, ShowChart } from '@material-ui/icons';

/**
 * 
 * @param {{
 *   serviceId: string,
 *   titleId: string,
 *   episodeId: string,
 *   snapshotId: string,
 *   mode: 'report' | 'edit'
 * }} param0 
 * @returns 
 */
const Contents = ({serviceId, titleId, episodeId, snapshotId, setSnapshotId, mode}) => {

    const [isLoading, setIsLoading] = React.useState(false);

    const [contents, setContents] = React.useState({images:[], nextEpisodeId: '', nextSnapshotId: '', siblings: []});

    React.useEffect(()=>{
        (async()=>{
            setIsLoading(true);
            let newContents = await readContents(serviceId, titleId, episodeId, snapshotId || undefined);
            if (newContents && newContents.id){
                setContents(newContents.data);
            }
            setIsLoading(false);
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serviceId, titleId])

    const [churnData, setChurnData] = React.useState([
        1.0,
        0.9,
        0.95,
        0.7,
        0.6,
        0.3,
        0.2,
        0.15,
    ]);

    const [avgStayData, setAvgStayData] = React.useState([
        2.0,
        1.0,
        1.5,
        1.0,
        10.0,
        1.0,
        4.0,
    ]);

    const [, setMaxUserAmount] = React.useState(1);

    React.useEffect(()=>{

        (async ()=> {
            if (!contents || !contents.images){
                return;
            }
            let {serviceId, titleId, episodeId, snapshotId} = contents;
            let contentsStatDict = 
                (await readContentsStat({serviceId, titleId, episodeId, snapshotId}))
                .map(({amount, duration, cutId}) => {let ret = {}; ret[cutId] = {amount, duration}; return ret})
                .reduce( (a,b) => ({...a, ...b}), {});

            let maxUserAmount = Object.values(contentsStatDict).map(row => row.amount).reduce( (a,b) => a>b?a:b, 0);

            let contentsStat = 
                contents.images
                .map(url => !url?null:url.split('/').reverse()[0].split('.')[0])
                .map(cutId => contentsStatDict[cutId] || {amount: 0, duration: 0})

            setMaxUserAmount(maxUserAmount);
            setChurnData(contentsStat.map(row => row.amount * 1.0 / maxUserAmount));
            setAvgStayData(contentsStat.map(row => row.duration))
        })();
        
    },[contents]);

    const [chartHeight,] = React.useState(800);

    return (<div className={"GraphHome ReportMode"}>
        <div className={`Body`}>
            <div className={`Chart`}>
                <div className={'Churn'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 150 ${chartHeight}`} style={{height: '800px'}}>
                        <polyline
                            points={churnData.map((point,index) => 
                                `${point * 134},${(index + 1) * (800 / churnData.length) || 0}`
                            ).join('\n')}
                            stroke={"rgba(244,20,20,0.5)"} strokeWidth={"3"} fill={"none"}
                        />
                    </svg>
                </div>
                <div className={"AvgStay"} style={{height: '800px'}}>
                    {avgStayData.map((point,index)=>
                        <Tooltip key={index} title={`(컷 ${index+1}, ${point} 초)`}><div className={"Bar"} style={{
                            top: (index + 1) * (800 / churnData.length), width: `${Math.min(10, point) * 10}%`
                        }}></div></Tooltip>
                    )}
                </div>
            </div>
            <div >
                <div className={'Description'}>
                    <div className={'TitleText'}>{'컷별 체류시간'}<Equalizer color={'action'} className={'BarIcon'} /></div>
                    {`까만색 바 그래프는 컷별 독자가 머무른 시간을 나타냅니다.
                    까만색 바가 높다는 것은 시선이 오래 머물렀다는 뜻입니다.`}
                    <ul>
                        <li>{'인상적인 컷이어서'}</li>
                        <li>{'가독성이 나쁜 컷이어서'}</li>
                    </ul>
                    <div className={'TitleText'}>{`이탈률`}<ShowChart color={'secondary'} className={'LineIcon'} /></div>
                    {`컷별 독자가 이탈한 구간을 빨간색 선그래프로 나타냅니다.`}
                </div>
            </div>
        </div>
        <LoadingCircle show={isLoading}/>
    </div>);
}

export default Contents;