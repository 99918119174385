import React from 'react'
import {Add, Delete, Loop} from '@material-ui/icons';
import { randomString, waitImageLoaded } from '../../../Functions';
import { createImageWithS3 } from '../../../Data/Image';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';

import './DragAndDrop.scss';

const DragAndDrop = ({file, setFile, setIsImagesLoading, isEditable, placeholder, disabled = false, maximum}) => {

    const [isDragging, setIsDragging] = React.useState(false);

    const [uniqueId, ] = React.useState(randomString(32));
    
    const [cover, setCover] = React.useState(false);
    const [collapse, setCollapse] = React.useState(null);
    const [loadingImages, setLoadingImages] = React.useState([])

    const [currentCursorIndex, setCurrentCursorIndex] = React.useState(null);
    const [insertIndex, setInsertIndex] = React.useState(null);
    const [isDropped, setIsDropped] = React.useState(false);

    const [isMaximumLengthMessage, setIsMaximumLengthMessage] = React.useState(false);

    const [fileSizes, setFileSizes] = React.useState([]);
    React.useEffect(()=>{
        let isActive = true;
        (async()=>{
            let newFileSizes = [];
            for (let row of file){
                newFileSizes.push(await waitImageLoaded(row.image));
            }
            if (isActive){
                setFileSizes(newFileSizes);
            }
        })();

        return () => {
            isActive = false;
        };
    },[file])

    const disableBoolean = disabled

    const handleUploadFiles = (files) => {
        
        if (files.length + file.length > maximum) {

            setIsMaximumLengthMessage(true)

        } else {

            let temporaryFile
            let filesLength = files.length;
            let newFile = [...file]

            for (let i = 0; i < filesLength; i++) {

                temporaryFile = files[i];
                let name = temporaryFile.name
                let fileArray = files
                let reader = new FileReader()

                reader.onload = () => {
                    newFile.push({
                        name,
                        image: reader.result,
                        lastModified: fileArray[i].lastModified
                    })
                    setFile([...newFile])
                }
                reader.readAsDataURL(temporaryFile)
            }

            createServerImage(files)
        }
    }
    
    const createServerImage = async(targets)=> {
        setIsImagesLoading(true)
        const updatedNewFile = [...file]
        const waitingImages = []
        for (const target of targets) {
            waitingImages.push(target.name)
        }
        setLoadingImages(waitingImages)

        for (const target of targets) {
            try{
                let filename = await createImageWithS3({name: target.name, files:[target]});

                updatedNewFile.push({
                    name: target.name,
                    image: filename,
                    lastModified: target.lastModified
                })

                const newLoadingImages = [...loadingImages]
                const waitingLoadingImages = newLoadingImages.filter(image => image !== target.name)
                setLoadingImages(waitingLoadingImages)
            } catch(e){
                console.error(e)
            }
        }
        setFile(updatedNewFile)

        if (loadingImages.length === 0){
            setIsImagesLoading(false)
        }
    }
    
    const moveFile = (removeIndex, insertIndex) => {
        
        if (insertIndex !== currentCursorIndex) {
            if(currentCursorIndex < insertIndex){

                const newFiles = [...file]

                const modifiedFiles = [...newFiles.slice(0,removeIndex)
                    ,...newFiles.slice(removeIndex + 1, insertIndex)
                    ,newFiles[removeIndex]
                    ,...newFiles.slice(insertIndex) 
                ]

                setFile(modifiedFiles)

            } else {
                
                const newFiles = [...file]

                const modifiedFiles = [...newFiles.slice(0, insertIndex)
                    ,newFiles[removeIndex]
                    ,...newFiles.slice(insertIndex, removeIndex)
                    ,...newFiles.slice(removeIndex + 1) 
                ]

                setFile(modifiedFiles)
            }
        }
    }

    const isLastInsertIndex = (eleIndex) => {
        if (file.length === eleIndex + 1 && file.length === insertIndex){
            return true;
        } else {
            return false;
        }
    }
    
    return (
        <div className={'DragAndDrop'}>
            {!isEditable?
            <div className={`DragAndDropContainer`} >
                {(file || []).map((element,eleIndex) => 
                <div className={'FileFrame'} key={`${element.name}:${element.image}`}>
                    <img className={'FilePreview'} src={element} alt={`${element}`} />
                    
                    <IconButton size={'small'}
                        disabled
                        style={{position: 'absolute', top:10, right:0, width: 30, height: 30, border:'1px lightgray solid', backgroundColor: 'rgba(255,255,255,0.5)'}}
                    >
                        <Delete style={{fontSize: '1rem'}}/>
                    </IconButton>
                </div>)}
            </div>
            :<div className={`DragAndDropContainer ${disableBoolean?'DragAndDropContainerDisabled':''}`}
                onMouseEnter={(event)=>{
                    event.stopPropagation();
                    event.preventDefault();
                    if(!isDragging && file.length === 0 && !isDropped){
                        setCover(true)
                        setCollapse('DragAndDropContainer')
                    }
                }}
                onMouseLeave={(event)=>{
                    event.stopPropagation();
                    event.preventDefault();
                    if(!isDragging && file.length === 0){
                        setCover(false)
                        setCollapse(null)
                    }
                    setIsDropped(false)
                }}
                onDrop={(event)=>{
                    if(!isDragging){
                        event.stopPropagation();
                        event.preventDefault();
                        if(file.length < maximum){
                            handleUploadFiles(event.dataTransfer.files)
                        } else {
                            setIsMaximumLengthMessage(true)
                        }
                        setCover(false)
                        setCollapse(null)
                        setIsDropped(true)
                    } else {
                        const lastIndex = file.length
                        
                        event.stopPropagation();
                        event.preventDefault();
                        setIsDragging(false);

                        moveFile(currentCursorIndex, lastIndex);
                    }
                    setInsertIndex(null);
                    setCurrentCursorIndex(null);
                }}
                onDragLeave={()=>{
                    if(collapse === 'DragAndDropContainer' && cover){
                        setCover(false)
                        if(file.length === 0){
                            setCollapse(null);
                        }
                    }
                }}
                onDragOver={(event)=>{
                    event.stopPropagation();
                    event.preventDefault();
                    if(!isDragging){
                        if (!cover){
                            setCover(true)
                        }
                        if(collapse !== 'DragAndDropContainer'){
                            setCollapse('DragAndDropContainer')
                        }
                    }
                }}
                onClick={()=>{
                    setIsDropped(false)
                    if (!isDragging && file.length === 0){
                        document.getElementById(uniqueId).click();
                    }
                }}
            >
                {file.length === 0 && (!cover && !collapse)?<div className={'PlaceholderText'}>{placeholder}</div>:<></>}
                <div className={(!cover || !collapse)?'Hide':'AddArea'}>
                    <Add style={{pointerEvents: 'none'}} fontSize={'large'} color={'primary'} />
                </div>
                {file.map((element,eleIndex) =>
                <div draggable className={`FileFrame ${cover?'Hide':''} ${isLastInsertIndex(eleIndex)?'VacantLast':''} ${insertIndex === eleIndex?'VacantSpace':''} ${loadingImages.length > 0?'OnLoading':''}`}
                    style= {fileSizes[eleIndex]
                        && (fileSizes[eleIndex].width !== fileSizes[eleIndex].height
                            || fileSizes[eleIndex].width !== 600
                        )?{
                        backgroundColor : 'rgb(196,152,152)'
                    }:{}}
                    key={`${element.name}:${element.image}`}
                    id={eleIndex}
                    onDragStart={(event)=>{
                        event.dataTransfer.effectAllowed = 'move';  
                        if(file.filter(each => each.name === element.name)){
                            setCurrentCursorIndex(eleIndex)
                            setIsDragging(true)
                        }
                        event.dataTransfer.setDragImage(event.target, 70, 70)
                    }}
                    onDragOver={(event)=>{
                        event.stopPropagation();
                        event.preventDefault();

                        if (isDragging){

                            const draggingLocationX = event.clientX
                            const standardLocationX = event.target.getBoundingClientRect().x
                            
                            if(draggingLocationX - 1 <= standardLocationX + 35){
                                setInsertIndex(eleIndex)
                            } else if (draggingLocationX > standardLocationX + 35){
                                setInsertIndex(eleIndex + 1)
                            }

                        } else {
                            if(!cover){
                                setCover(true)
                            };
                            setCollapse('FileFrame')
                        }
                    }}
                    onDrop={(event)=>{
                        event.stopPropagation();
                        event.preventDefault();

                        if (isDragging) {
                            const draggingLocationX = event.clientX
                            const standardLocationX = event.target.getBoundingClientRect().x

                            if(draggingLocationX - 1 <= standardLocationX + 35){
                                moveFile(currentCursorIndex,eleIndex)
                            } else if (draggingLocationX > standardLocationX + 35){
                                moveFile(currentCursorIndex,eleIndex + 1)
                            }
                        }
                        setCurrentCursorIndex(null);
                        setInsertIndex(null);
                    }}
                    onDragEnd={()=>{
                        if (isDragging){
                            setIsDragging(false);
                            setInsertIndex(null);
                            setCurrentCursorIndex(null);
                        }
                    }}
                >
                    <img className={'FilePreview'} src={`${element.image}`} alt={`${element.name}`} />
                    
                    {!isDragging?<IconButton
                        size={'small'}
                        style={{position: 'absolute', top:10, right:0, width: 30, height: 30, border:'1px lightgray solid', backgroundColor: 'rgba(255,255,255,0.5)'}}
                        onClick={(event)=>{
                            event.preventDefault();
                            event.stopPropagation();
                            const newFile = [...file].filter((eachFile, idx)=> idx !== eleIndex);
                            setFile(newFile)
                            if (newFile.length === 0){
                                setCover(true);
                            }
                        }}
                    >
                        <Delete style={{fontSize: '1rem'}}/>
                    </IconButton>:<></>}
                    {loadingImages.includes(element.name)
                    ?<Loop
                        style={{position: 'absolute', top:'calc(50% - 15px)', right:'calc(50% - 15px)', width: 30, height: 30, border:'1px lightgray solid', backgroundColor: 'rgba(255,255,255,0.5)'}} />
                    :<></>}
                </div>
                )}

                <div className={`FileFrame ${(cover && file.length>0)?'Hide':''}`}
                    onDragOver={(event)=>{
                        event.stopPropagation();
                        event.preventDefault();

                        if (isDragging){
                            setInsertIndex(file.length)
                        }
                    }}
                    onDrop={(event)=>{
                        event.stopPropagation();
                        event.preventDefault();
                        if (isDragging) {
                            moveFile(currentCursorIndex, file.length)
                        }
                        setCurrentCursorIndex(null);
                        setInsertIndex(null);
                    }}
                >
                    {file.length < maximum?<div className={'FilePreview'} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}
                        onClick={()=>{
                            if (!isDragging && file.length > 0){
                                document.getElementById(uniqueId).click();
                            }
                        }}
                    >
                        <Add color={'primary'} fontSize={'large'} />
                    </div>
                    :<></>}
                </div>
                
                <div style={{display: 'none'}}>
                    <input type={'file'} multiple id={uniqueId} accept={'.jpg,.jpeg,.png'}
                        onChange={(event) => {
                            if (event.target.files && event.target.files.length > 0 ){
                                handleUploadFiles(event.target.files)
                            }
                            setCover(false);
                        }}
                    />
                </div>
            </div>}
            <Dialog className={"Dialog"} open={isMaximumLengthMessage} onClose={() => setIsMaximumLengthMessage(false)}>
                <DialogTitle style={{paddingBottom: 0}}>
                    {'파일 개수 초과'}
                </DialogTitle>
                <DialogContent>
                    <div style={{whiteSpace: 'pre-line'}}>
                        {`최대 업로드 가능 한 파일 갯수는 ${maximum}개 입니다.
                        새로운 파일을 업로드 하는 경우에는 기존 파일을 제거하여 추가하거나
                        최대 파일 갯수를 넘지 않게 업로드해 주세요.`}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} disableElevation className={"PopupButton"}
                        onClick={()=>setIsMaximumLengthMessage(false)} color={"primary"} >
                        {"확인"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}


export default DragAndDrop;
