import React from 'react';
import { Button, Chip,} from '@material-ui/core';

// @Type
// eslint-disable-next-line no-unused-vars
import {sessionType, googleResponseType, facebookResponseType} from '../Data/Session';
// eslint-disable-next-line no-unused-vars
import {interestFetchType, interestType} from '../Data/Interest';
// eslint-disable-next-line no-unused-vars
import {readTitles, titleType} from '../Data/Title';
// eslint-disable-next-line no-unused-vars
import {size} from '../VirtualRouter';
import { decodeEntities, thumbnailize, randomString, fn, unique } from '../Functions';

import './ItemDetailBox.scss';

/**
 * 
 * @param {{
 *   mode: 'vertical'|'horizontal'|'card',
 *   style: React.CSSProperties,
 *   windowSize: size,
 *   item: titleType,
 *   isLiked: boolean,
 *   isSubscribed: boolean,
 *   saveAndUpdateInterest: (interest: interestFetchType) => Promise<void>,
 *   deleteAndUpdateInterest: (interest: interestFetchType) => Promise<void>,
 *   recommOrEpisodeTitle: 'recomm' | 'episodeTitle',
 *   isClient: boolean
 * }} props 
 */
export const ItemCard = ({item = {}, isClient}) => {

    const [uniqueId,] = React.useState('grid_'+randomString(16));
    
    // place holder
    if (!item) {
        return (
            <div >
            </div>
        );
    }

    const topicCounts = {};

    return (
        <div className={`ItemDetailBox`} id={uniqueId}>
            <div className={"Banner"} style={{backgroundImage: `url('${thumbnailize(item.thumbnail)}')`}}>
            </div>
            <div className={"Other"}>
                <div className={"TextInfo"}>
                    <h3 className={"Title"}>{item.title}</h3>
                    <div className={"Tags"}>
                        
                        {(item.tags || "").split(",").filter(topic => topic.length > 0).filter(unique).map( (topic) =>
                            <Chip
                                key={topic}
                                className={"Chip"}
                                style={{
                                        borderColor:
                                            !topicCounts[topic] ||
                                            topicCounts[topic]<=3?"#C7DAC7":
                                            topicCounts[topic]<=6?"default":
                                            topicCounts[topic]<=9?"#FFB68C":
                                            topicCounts[topic]<=12?"#FE8E7B":
                                                    "#FF6787",
                                        color: 
                                            !topicCounts[topic] ||
                                            topicCounts[topic]<=3?"#C7DAC7":
                                            topicCounts[topic]<=6?"default":
                                            topicCounts[topic]<=9?"#FFB68C":
                                            topicCounts[topic]<=12?"#FE8E7B":
                                                    "#FF6787",
                                    }}
                                variant={"outlined"}
                                label={topic}
                                clickable
                            />
                        )}
                    </div>
                    <div className={"Description"}>{decodeEntities(item.description)}</div>
                </div>
                <div className={"Buttons"}>
                    <Button className={"Button"} fullWidth href={item.firstEpisodeHref} variant={"outlined"} onClick={fn.gotoByAnchor}>{"첫화 보기"}</Button>
                    <Button className={"Button"} fullWidth href={`/detail/${item.serviceId}/${item.titleId}/${item.title}`} variant={"outlined"} onClick={fn.gotoByAnchor}>{"목록 보기"}</Button>
                    <Button className={"Button"} fullWidth href={item.href} variant={"outlined"} onClick={fn.gotoByAnchor}>{"공식페이지"}</Button>
                </div>
            </div>
        </div>
    );
}

export default ItemCard;