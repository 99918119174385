import { AppBar, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography } from '@material-ui/core';
import { Comment, FormatPaintSharp, KeyboardArrowRight, ListAltOutlined, ZoomIn, PieChart, People, EmojiEvents, EventAvailable, FiberNew, SupervisorAccount, MenuBook, CloudDownload, WbIncandescent, Bookmark, Book, Mail } from '@material-ui/icons';
import React from 'react';
import Contents from './Contents';

import ss from '../../Data/Session';
import {fn, BranchFunction} from '../../Functions';

import './Home.scss';

import EpisodeListAdmin  from './EpisodeList';
import TitleListAdmin    from './TitleList';
import ReviewAdmin from './ReviewList';
import Process from './Process';
import Setting from '../Setting';

import CompetitionProcess from './CompetitionProcess';

import AnalyticsBoard from './AnalyticsBoard';
import Team from './Team';
import Member from './Member';
import AnalyticsProcess from './AnalyticsProcess';
import LatestNoteOfTitlesView from './LatestNoteOfTitlesView';
import AllNotesOfTitleView from './AllNotesOfTitleView';

import Meta from './Meta';

import LoadingCircle from '../../Component/LoadingCircle';


import ContentsChallenge from './Challenge/Contents';
import EpisodeListAdminChallenge  from './Challenge/EpisodeList';
import TitleListAdminChallenge    from './Challenge/TitleList';
import ContiAnalytics from './Challenge/ContiAnalyticsProcess';

import CompUniversityProcessChallenge from './Challenge/CompUniversityProcess';
import ReportsForPartnership from './Challenge/ReportsForPartnership';
import CompetitionProcessChallenge from './Challenge/CompetitionProcess';
import AnalyticsProcessChallenge from './Challenge/AnalyticsProcess';
import MarketAnalyticsProcess from './Market/MarketAnalyticsProcess';

import ShortcutChallenge from './Challenge/Shortcut';
import ReportChallenge from './Challenge/Report';
import { readMemberships } from '../../Data/Membership';
import { checkNDA } from '../../Data/NDA';
import TopicTagging from './Challenge/TopicTagging';
import SurveyEditor from './Challenge/SurveyEditor';
import Product from './Challenge/Product';
import Campaign from './Challenge/Campaign';
import Cloud from './Challenge/Cloud';
import Insight from './Challenge/Insight';
import InsightPool from './Market/Insight/InsightPool';

import MailMerge from './Challenge/MailMerge';
import MailReadLog from './Challenge/MailReadLog';
import Viral from './Challenge/Viral';

const PageBranch = ({root, endPage, serviceId, titleId, episodeId, snapshotId, hash, isManager, isPartnership}) => BranchFunction(
    {root, endPage, serviceId, titleId, episodeId, snapshotId, hash, isManager, isPartnership},
    [
        {
            validator: ({isManager, isPartnership}) => !isManager && !isPartnership,
            sublogic: [
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'topictagging',
                    defaultValue: TopicTagging,
                },
            ],
            defaultValue: Setting,
        },
        {
            validator: ({root, endPage, isManager, isPartnership}) => !isManager && isPartnership,
            sublogic: [
                {
                    validator: ({root, endPage}) => root === 'home',
                    sublogic: [
                        {
                            validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'membership',
                            defaultValue: Team,
                        },
                    ],
                    defaultValue: Setting,
                },
                {
                    validator: ({root}) => root === 'challenge',
                    sublogic: [
                        {
                            validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'partnership',
                            defaultValue: ReportsForPartnership,
                        },
                        {
                            validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'report',
                            defaultValue: ReportChallenge,
                        },
                    ],
                    defaultValue: Setting,
                }
            ],
            defaultValue: ()=><div></div>,
        },
        {
            validator: ({isManager, isPartnership}) => !isManager && !isPartnership,
            defaultValue: Setting,
        },
        {
            validator: ({root}) => root === 'home',
            sublogic: [
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'contents',
                    sublogic: [
                        {
                            validator: ({endPage,serviceId,titleId,episodeId,snapshotId, hash})=> serviceId && !titleId,
                            defaultValue: TitleListAdmin,
                        },
                        {
                            validator: ({endPage,serviceId,titleId,episodeId,snapshotId, hash})=> serviceId && titleId && !episodeId,
                            defaultValue: EpisodeListAdmin,
                        },
                        {
                            validator: ({endPage,serviceId,titleId,episodeId,snapshotId, hash})=> serviceId && titleId && episodeId,
                            defaultValue: Contents,
                        },
                    ],
                    defaultValue: Setting,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'comment',
                    defaultValue: ReviewAdmin,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'analytics',
                    defaultValue: AnalyticsProcess,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'process',
                    defaultValue: Process,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'meta',
                    defaultValue: Meta,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'competition',
                    defaultValue: CompetitionProcess,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'membership',
                    defaultValue: Team,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'member',
                    defaultValue: Member,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'progress',
                    sublogic: [
                        {
                            validator: ({endPage,serviceId,titleId,episodeId,snapshotId, hash})=> endPage === 'progress' && serviceId && titleId,
                            defaultValue: AllNotesOfTitleView,
                        },
                    ],
                    defaultValue: LatestNoteOfTitlesView,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId}) => !endPage,
                    defaultValue: AnalyticsBoard,
                },
            ],
            defaultValue: AnalyticsBoard,
        },

        {
            validator: ({root}) => root === 'challenge',
            sublogic: [
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'contents',
                    sublogic: [
                        {
                            validator: ({endPage,serviceId,titleId,episodeId,snapshotId, hash})=> serviceId && !titleId,
                            defaultValue: TitleListAdminChallenge,
                        },
                        {
                            validator: ({endPage,serviceId,titleId,episodeId,snapshotId, hash})=> serviceId && titleId && !episodeId,
                            defaultValue: EpisodeListAdminChallenge,
                        },
                        {
                            validator: ({endPage,serviceId,titleId,episodeId,snapshotId, hash})=> serviceId && titleId && episodeId,
                            defaultValue: ContentsChallenge,
                        },
                    ],
                    defaultValue: Setting,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash, query}) => endPage.startsWith('cloud'),
                    defaultValue: Cloud,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'product',
                    defaultValue: Product
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'campaign',
                    defaultValue: Campaign
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'mailreadlog',
                    defaultValue: MailReadLog
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'insight',
                    sublogic: [
                        {
                            validator: ({endPage,serviceId,titleId,episodeId,snapshotId, hash})=> serviceId && titleId,
                            defaultValue: InsightPool,
                        },
                    ],
                    defaultValue: Insight
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'analytics',
                    defaultValue: AnalyticsProcessChallenge,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'competition',
                    defaultValue: CompetitionProcessChallenge,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'market',
                    defaultValue: MarketAnalyticsProcess,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === '2022university',
                    defaultValue: CompUniversityProcessChallenge,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'shortcut',
                    defaultValue: ShortcutChallenge,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'report',
                    defaultValue: ReportChallenge,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'topictagging',
                    defaultValue: TopicTagging,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'contianalytics',
                    defaultValue: ContiAnalytics,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'survey',
                    defaultValue: ()=><SurveyEditor surveyId={serviceId} />,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'meta',
                    defaultValue: ()=><Meta />,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'mail',
                    defaultValue: MailMerge,
                },
                {
                    validator: ({endPage, serviceId, titleId, episodeId, snapshotId, hash}) => endPage === 'viral',
                    defaultValue: Viral
                }
            ],
            defaultValue: TitleListAdminChallenge,
        }
    ],
    Setting,
 )

const AnalyticsHome = ({root, endPage, serviceId, titleId, episodeId, snapshotId, hash}) => {

    const [title ,setTitle] = React.useState(document.title);
    React.useEffect(()=>{
        let isValid = true;
        setTimeout(() => {
            if (isValid){
                setTitle(document.title);
            }
        }, 50);

        return ()=>{
            isValid = false;
        }
    },[root, endPage, serviceId, titleId, episodeId, snapshotId, hash])

    const [isSidebarOpen, setIsSidebarOpen] = React.useState(JSON.parse(localStorage.getItem('analyticsHome:isSidebarOpen') || 'true'));

    React.useEffect(()=>{
        localStorage.setItem('analyticsHome:isSidebarOpen', JSON.stringify(isSidebarOpen));
    },[isSidebarOpen])

    const [isLoading, setIsLoading] = React.useState(false);
    const [isManager, setIsManager] = React.useState(false);
    React.useEffect(()=>{
        let isFinished = false;
        (async()=>{
            setIsLoading(true);
            try{
                let isManager = await ss.isManager();
                setIsManager(isManager);

                if (!isManager){
                    let teams = await readMemberships();
        
                    if (!isFinished){
        
                        if (teams.filter(team => team.teamid === 'kOlTqUnBftIOCRfl').length > 0){
                            setIsPartnership(true);
                        }else {
                            setIsPartnership(false);
                        }
                    }
        
                    let ndaConfirmed = await checkNDA();
                    setNdaConfirmed(ndaConfirmed);
                    setIsLoading(false);
                }else {
                    setIsPartnership(false);
                    setNdaConfirmed(false);
                    setIsLoading(false);
                }
            }catch(e){
                setIsLoading(false);
            }
        })();
        return ()=>{
            isFinished = true;
        }
    },[])

    const [isPartnership, setIsPartnership] = React.useState(true);
    const [ndaConfirmed, setNdaConfirmed] = React.useState(false);

    React.useEffect(()=>{
        (async()=>{
        })();
        (async()=>{
        })();
    },[])

    const CurrentPage = PageBranch({root, endPage, serviceId, titleId, episodeId, snapshotId, hash, isManager, isPartnership});

    return (<div className={"AnalyticsHome"}>
        <AppBar className={`AppBar ${isSidebarOpen?'Opened':'Closed'}`} position={"fixed"}>
            <Toolbar variant="dense">
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={()=>{setIsSidebarOpen(!isSidebarOpen)}}>
                    <KeyboardArrowRight className={"ArrowRight"} />
                </IconButton>
                <Typography variant="h6" color="inherit">
                    {title}
                </Typography>
            </Toolbar>
        </AppBar>
        <div className={'AppbarShadow'}></div>
        <div className={`Container ${isSidebarOpen?'Narrow':'Wide'}`}>
            <CurrentPage {...{root, endPage, serviceId, titleId, episodeId, snapshotId, hash, isPartnership, ndaConfirmed, setNdaConfirmed}}/>
        </div>
        <Drawer
            variant={"permanent"}
            className={`Sidebar ${isSidebarOpen?'Opened':'Closed'}`}
        >
            <List>
                <ListItem style={{height: '2.5rem'}}></ListItem>
                <div className={"Profile"}>
                    <img className={"Image"} src={ss.getCurrentSession().image} alt={"profile"}/>
                    <div className={"Name"}>{ss.getCurrentSession().addressid}</div>
                </div>
                <Divider/>
                {[
                    {url: '/home/contents', Icon: FormatPaintSharp, text: "사용자 정보 편집"},
                ]
                .map(({url, Icon, text}) => 
                    <ListItem key={url} button onClick={()=>{
                        fn.goto(url)
                    }}>
                        <ListItemIcon><Icon/></ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                )}
                <Divider/>
                <ListItem>
                        <ListItemIcon style={{color: '#DDD'}}><SupervisorAccount/></ListItemIcon>
                    <ListItemText style={{color: '#DDD'}}>{"내부 DB"}</ListItemText>
                </ListItem>
                {(isManager?[
                    {url: `/home/contents/${ss.getCurrentSession().addressid}`, Icon: ListAltOutlined, text: "작품 목록"},
                    {url: `/home/competition`, Icon: EmojiEvents, text: "공모전(~22.10.)"},
                    {url: `/home/analytics`, Icon: ZoomIn, text: "리포트(~22.10.)"},
                    {url: `/home/membership`, Icon: People, text: "팀 관리"},
                    {url: `/home/member`, Icon: People, text: "멤버 조회"},
                ]:isPartnership?[
                    {url: `/home/membership`, Icon: People, text: "팀 관리"},
                ]:[])
                .map(({url, Icon, text}) => 
                    <ListItem key={url} button onClick={()=>{
                        fn.goto(url)
                    }}>
                        <ListItemIcon><Icon/></ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                )}
                <Divider/>
                <ListItem>
                        <ListItemIcon style={{color: '#DDD'}}><FiberNew/></ListItemIcon>
                    <ListItemText style={{color: '#DDD'}}>{"도전만화 관련"}</ListItemText>
                </ListItem>
                {(isManager?[
                    {url: `/challenge/cloud`, Icon: CloudDownload, text: '클라우드'},
                    {url: `/challenge/competition`, Icon: EmojiEvents, text: "공모전"},
                    {url: `/challenge/analytics`, Icon: ZoomIn, text: "리포트"},
                    {url: `/challenge/contents/${ss.getCurrentSession().addressid}`, Icon: EventAvailable, text: "작품목록"},

                    
                ]:isPartnership?[
                    {url: `/challenge/partnership`, Icon: EventAvailable, text: "과제부활전 열람"},
                ]:[])
                .map(({url, Icon, text}) => 
                    <ListItem key={url} button onClick={()=>{
                        fn.goto(url)
                    }}>
                        <ListItemIcon><Icon/></ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                )}
                <Divider/>
                <ListItem>
                        <ListItemIcon style={{color: '#DDD'}}><FiberNew/></ListItemIcon>
                    <ListItemText style={{color: '#DDD'}}>{"마케팅 관련"}</ListItemText>
                </ListItem>
                {(isManager?[
                    {url: `/challenge/product`, Icon: MenuBook, text: '상품 관리'},
                    {url: `/challenge/shortcut`, Icon: Comment, text: "단축 URL"},
                    {url: `/challenge/insight`, Icon: WbIncandescent, text: "인사이트"},
                    {url: `/challenge/campaign`, Icon: Bookmark, text: "캠페인" },
                    {url: `/challenge/mailreadlog`, Icon: Mail, text: '메일열람'}
                ]:[])
                .map(({url, Icon, text}) => 
                    <ListItem key={url} button onClick={()=>{
                        fn.goto(url)
                    }}>
                        <ListItemIcon><Icon/></ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                )}
                <Divider/>
                {(isManager?[
                    {url: `/challenge/2022university`, Icon: EventAvailable, text: "과제부활전"},
                    {url: `/challenge/market`, Icon: PieChart, text: "시장 분석"},
                    {url: `/challenge/viral`, Icon: Book, text: "바이럴 분석"}
                ]:[])
                .map(({url, Icon, text}) => 
                    <ListItem key={url} button onClick={()=>{
                        fn.goto(url)
                    }}>
                        <ListItemIcon><Icon/></ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                )}
            </List>
        </Drawer>
        <LoadingCircle show={isLoading}/>
    </div>);
}

export default AnalyticsHome;