import React from 'react';
import {Paper, } from '@material-ui/core';

import './Survey.scss';

import RangeChoice from './Component/Survey/RangeChoice';
import MultipleChoice from './Component/Survey/MultipleChoice';
import Subjective from './Component/Survey/Subjective';
import Notice from './Component/Survey/NoticeBox';
import SingleChoice from './Component/Survey/SingleChoice';


const SurveyLogo = 'https://static.webtoon.today/ddah/logo-01.png';


const SurveyFormView = ({questionaire, className=""}) => {

    React.useEffect(()=>{
        document.title = "데이터 분석 평가설문";
    },[])

    const [response, setResponse] = React.useState({});
    
    return (
        <div className={className}>
            <div className={"SurveyPreviewBody"}>
                <div className={"SurveyContainer"}>
                    <div elevation={0} className={"Section SurveyLogo"}>
                        <div className={"Inner"}>
                            <img className={"WebtoonToday"} src={SurveyLogo} alt="logo"/>
                        </div>
                    </div>
                    {questionaire
                    .filter(question => (
                        !question.optional_clause
                        || (typeof response[question.optional_clause.key] === 'string'
                            && question.optional_clause.values.indexOf(response[question.optional_clause.key]) >= 0)
                        || (typeof response[question.optional_clause.key] === 'number'
                            && question.optional_clause.values.indexOf(response[question.optional_clause.key]) >= 0)
                        || (Array.isArray(response[question.optional_clause.key])
                            && response[question.optional_clause.key].filter(val => question.optional_clause.values.indexOf(val) >= 0).length > 0 )
                        || (response[question.optional_clause.key] !== null && typeof response[question.optional_clause.key] === 'object'
                            && Object.entries(response[question.optional_clause.key]).filter( ([key, val]) => question.optional_clause.values.indexOf(key) >= 0 && val).length > 0 )
                    ))
                    .map(question => 
                    <Paper
                        elevation={0}
                        className={"Section"}
                        key={question.key}
                        id={question.key}
                        style={question.indent?{marginLeft: question.indent * 30}:{}}
                    >
                        {question.type === 'notice'?
                        <Notice
                            title={question.title}
                            subtitle={question.subtitle}
                            isRequired={question.isRequired}
                        />
                        :question.type === 'range'?
                        <RangeChoice
                            title={question.title}
                            subtitle={question.subtitle}
                            isRequired={question.isRequired}
                            response={response[question.key]}
                            setResponse={(value) => setResponse({...response, [question.key]: value})}
                            caption_max={question.caption_max}
                            caption_min={question.caption_min}
                        />
                        :question.type === 'single'?
                        <SingleChoice
                            title={question.title}
                            options={question.options}
                            response={response[question.key]}
                            setResponse={(value) => setResponse({...response, [question.key]: value})}
                            isRequired={question.isRequired}
                            isToggle={question.isToggle}
                            allowOtherOption={question.isToggle && question.allowOtherOption}
                        />
                        :question.type === 'multiple'?
                        <MultipleChoice
                            title={question.title}
                            options={question.options}
                            response={response[question.key] || {}}
                            setResponse={(value) => setResponse({...response, [question.key]: { ...(response[question.key] || {}), ...value}})}
                            isRequired={question.isRequired}
                            isToggle={question.isToggle}
                            allowOtherOption={question.isToggle && question.allowOtherOption}
                            caption_true={question.caption_true} caption_false={question.caption_false}
                        />
                        :question.type === 'subjective'?
                        <Subjective
                            title={question.title}
                            subtitle={question.subtitle}
                            isRequired={question.isRequired}
                            response={response[question.key]}
                            setResponse={(value) => setResponse({...response, [question.key]: value})}
                        />
                        :<></>}
                    </Paper>)}
                </div>
            </div>
        </div>
    );
}

export default SurveyFormView;