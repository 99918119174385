import FunnelDiagnosis from "./FunnelDiagnosis";
import React from 'react';
import './DiagnosisResult.scss';
import SummaryTable from "./SummaryTable";
import ScatteredPlot from "./ScatteredPlot";

const labels = {
    comparison: "내 작품",
    target: "목표작",
    similarTop: "유사 작품 Top",
    genreTop: "장르 Top"
}
const exposurePrioritySet = {
    "comparison": { x: 20, y: 20, label: "" },
    "target"    : { x: 40, y: 60, label: "" },
    "similarTop": { x: 80, y: 70, label: "" },
}
const profitabilityPrioritySet = {
    "comparison": { x: 20, y: 20, label: ""},
    "target": { x: 60, y: 40, label: "" },
    "similarTop": { x: 80, y: 70, label: "" },
}

const DiagnosisResult = ({ 
    data
}) => {
    const diagnoses = data.diagnoses
    let tableData;
    if ( diagnoses?.genreTop ){
        const {genreTop, ...rest} = diagnoses;
        tableData = rest;
    } else {
        tableData = diagnoses;
    }

    const hasSimilarTop = Boolean(diagnoses.similarTop?.title);
    
    const {sales: mySales, popularity: myPopularity} = diagnoses.comparison.rawData;
    const {sales: targetSales, popularity: targetPopularity} = diagnoses.target.rawData;
    
    let isExposurePriority = (myPopularity/targetPopularity) < ((mySales/myPopularity) / (targetSales/targetPopularity));
    let baseSet = isExposurePriority ? {...exposurePrioritySet} : {...profitabilityPrioritySet} 
    let dataSet = [];
    let linelabels = isExposurePriority ? ["인지도 상승", "수익성 상승"]: ["수익성 상승", "인지도 상승"];
    
    if (!hasSimilarTop) {
        baseSet = Object.fromEntries(Object.entries(baseSet).filter(([key,_]) => key !== 'similarTop'));
        linelabels = linelabels.slice(0, 1);    
    }
    
    for ( const [type, info] of Object.entries(diagnoses) ) {
        if ( baseSet[type] && Object.keys(info).length > 0 ){
            dataSet.push({
                ...baseSet[type],
                label: info.title,
                legend: `${type === "genreTop" ? info.title : ""}`
            })
        }
    }

    const pointData = diagnoses['genreTop']?.title?[{ x: 85, y: 85, label: labels['genreTop'], legend: `${diagnoses['genreTop'].title}` }]:[];
    
    return(<div className={'SummaryDiagnosisArea'} >
        <div className={'DiagnosisResultHeader'} >
            <span className={'TitleText'} >{'작품 진단 결과'}</span>
        </div>
        <div className={'DiagnosisBody'} >
            <div className={'DiagnosisBlocks'} >
                <div className={'BlockHeader'} >
                    <div className={'Title'} >{'1. 매출 성장 로드맵'}</div>
                    <div className={'SubTitle'}>{'플랫폼 내 다른 작품과 비교를 통해, 매출 성장을 위한 로드맵을 제안드려요.'}</div>
                </div>
                <WithInfoMessage title={data.header.summary} >
                    <div className={'DiagnosisSummaryContainer'} >
                        <div className={'ScatteredPlot'}>
                            <ScatteredPlot 
                                width={540} height={556}
                                margin={{top: 60, right: 16, bottom: 20, left: 16}} 
                                dataset={dataSet} pointData={pointData}
                                axisLabels={["수익성","인지도"]} lineLabels={linelabels} 
                                maxValue={{x: 85, y: 85}} />
                        </div>
                        <SummaryTable data={tableData} labels={labels} />
                    </div>
                </WithInfoMessage>
            </div>
            <div className={'DiagnosisBlocks'} >
                <div className={'BlockHeader'} >
                    <div className={'Title'} >{'2. 마케팅 목표 설정'}</div>
                    <div className={'SubTitle'}>{'독자의 유료 결제는 다음 네 단계를 거쳐 발생합니다.\n목표작과의 비교를 통해 네 단계 중 개선할 부분을 파악하고, 마케팅의 핵심 목표로 설정합니다.'}</div>
                </div>
                <WithInfoMessage title={data.header.result} >
                    <FunnelDiagnosis data={tableData} colSpan={4} className={"FullSize"} />
                    <FunnelDiagnosis data={tableData} colSpan={2} className={"HalfSize"} />
                    <FunnelDiagnosis data={tableData} colSpan={1} className={"OneSize"} />
                </WithInfoMessage>
            </div>
        </div>
    </div>)
}

const WithInfoMessage = ({children, title}) => {
    return(<div className={`EachResultContainer`} >
        <div className={'InfoMessageBox'} >
            <img src={'https://static.webtoon.today/ddah/icon/lightbulb.svg'} alt={'lightbulb'} width={28} />
            <span>{title}</span>
        </div>
        {children}
    </div>)
}

export default DiagnosisResult;