import axios from 'axios';
import ss from '../../../../Data/Session';

/**
 * @typedef {{
 *   serviceId: string,
 *   titleId: string,
 *   nullCase: string,
 *   offset: string,
 *   limit: number,
 * }} marketMeta
 * 
 * @param {marketMeta[]} params 
 * @returns {Promise<titleInfo[]>}
 */
export const marketMetaList = async ({serviceId, titleId, nullCase = 'include', offset, limit = 100}) => {
    let res = await axios.post('https://challenge-api.webtoon.today/market/meta', {serviceId, titleId, nullCase, offset, limit}, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true,
    });
    
    if (res.data && res.data.code === 200) {
        return res.data.data;
    } else {
        return [];
    }
}


/**
 * 
 * @param {{
 *   author: string,
 *   createdAt: string,
 *   deployment: string | null,
 *   drawingStyle: string | null,
 *   fictionalSetting: string | null,
 *   genre: string | null,
 *   historicalSetting: string | null,
 *   href: string,
 *   perspective: string | null,
 *   serviceId: string,
 *   thumbnail: string,
 *   title: string,
 *   titleId: string,
 * }} row 
 * @returns {Promise<string | null>}
 */
export const updateMarketMeta = async (row) => {    
    let req = Object.assign({}, row);
    let res = await axios.put('https://challenge-api.webtoon.today/market/meta', req, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });
    
    if (res.data && res.data.code === 200){
        return res.data.key;
    }else{
        console.error(res.code, res.data);
    }

    return null;
};