import React from 'react';
import './Header.scss';
import { Button } from '@material-ui/core';
import {fn} from '../Functions';

/**
 * 
 * @param {{
 *  pageName: string,
 *  rightButton: JSX.Element,
 *  forceToShow?: boolean,
 *  hideClean?: any
 * }} props 
 */
const Header = (props) => {

    return (<div className={`SiteHeader ${props.forceToShow?'ForceToShow':''} ${props.hideClean?'HideClean':''}`}>
        {window.location.pathname !== "/"
            ?<Button className={"MenuButton"} onClick={()=>{
                fn.goto('/')
            }}></Button>
            :<>
                <img className={"Logo"} src={"https://cached-api.webtoon.today/thumb?u=https://detail.webtoon.today/logo512.png&mw=80"} alt={"logo"} onClick={()=>fn.goto("/")}/>
                <img className={"LogoSmall"} src={"https://cached-api.webtoon.today/thumb?u=https://static.webtoon.today/push_96.png&mw=80"} alt={"logo"} onClick={()=>fn.goto("/")}/>
            </>}
        <div className={"PageName"}>{props.pageName || "오늘의 웹툰"}</div>
        {props.rightButton }
    </div>);

}

export default Header;