import React from 'react';
import {randomString} from "../Functions";

const LocalImageReader = ({dragOnMessage="", callBack=()=>{}, style}) => {

    let [uniqueId, ] = React.useState(randomString(32));

    let [file, setFile] = React.useState(null);

    React.useEffect(()=>{
        if(!file){
            return;
        }
        const reader = new FileReader();
        reader.addEventListener('load', (event)=>{
            callBack(event.target.result);
        })
        reader.readAsDataURL(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[file])

    return <div style={style}
        onDragOver={(event)=>{
            event.stopPropagation();
            event.preventDefault();
            event.dataTransfer.dropEffect = 'copy';
        }}

        onDrop={(event)=>{
            event.stopPropagation();
            event.preventDefault();
            setFile(event.dataTransfer.files[0]);
        }}

        onClick={()=>{
            document.getElementById(uniqueId).click();
        }}
    >
        {dragOnMessage}
        <div style={{display:"none"}}>
            <input type={"file"} id={uniqueId} accept={".jpg,jpeg,.png"}
                onChange={(event)=>{
                    if (event.target.files && event.target.files.length > 0){
                        setFile(event.target.files[0])
                    }
                }}
            />
        </div>
    </div>;
}

export default LocalImageReader;