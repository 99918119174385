import { Button, Radio, TextField } from "@material-ui/core";
import React from "react";

import './MultipleChoice.scss';

/**
 * 
 * @param {{
 *   options: string[],
 *   response: {[question:string]: boolean|null},
 *   setResponse: (response:{[question:string]: boolean}) => void,
 *   title: string, subtitle: string, isRequired: boolean, isToggle: boolean,
 *   caption_true: string, caption_false: string,
 *   allowOtherOption: boolean,
 * }} param 
 */
const MultipleChoice = ({
    options=[],
    response, setResponse,
    caption_true="매우 동의", caption_false="매우 비동의",
    title = "제목", subtitle= null,
    isRequired = false,
    isToggle = false,
    allowOtherOption = false,
}) => {
    const [isOpenedOtherOption, setIsOpenedOtherOption] = React.useState(false);
    const [otherResponse, setOtherResponse] = React.useState("");

    if (isToggle) {
        return (<div className="MultipleChoice QuestionBox">
            <div className={"Title"}>{title}{isRequired?<span className={"RedAsterisk"}>{" *"}</span>:<></>}</div>
            {subtitle?<div className={"Subtitle"}>{subtitle}</div>:<></>}
            {options.map((option, index) =>
                <Button fullWidth key={option}
                    className={"SubjectiveButton"}
                    variant={response[option]
                        ?"contained":"outlined"}
                    color={"primary"}
                    onClick={()=>{
                        if (response[option] === true){
                            setResponse({
                                ...response,
                                [option]: false,
                            })
                        }else{
                            setResponse({
                                ...response,
                                [option]: true,
                            })
                        }
                    }}
                >
                    {option}
                </Button>)}
                {!allowOtherOption?<></>
                :isOpenedOtherOption
                    ?<TextField
                        fullWidth
                        value={otherResponse}
                        onChange={(event)=>{
                            setOtherResponse(event.target.value)
                        }}
                        onKeyDown={(event)=>{
                            if(event.key === 'Enter'){
                                event.target.blur();
                            }
                        }}
                        onBlur={()=>{
                            setResponse({
                                ...Object.fromEntries( Object.entries(response).filter(([key, value]) => options.indexOf(key) >= 0) ),
                                [otherResponse]: true,
                            })
                        }}
                    />
                    :<Button fullWidth
                        className={"SubjectiveButton"}
                        variant={"outlined"}
                        color={"primary"}
                        onClick={()=>{
                            setIsOpenedOtherOption(true);
                        }}
                    >
                        {"기타"}
                    </Button>}
        </div>);

    }else{
        return (<div className="MultipleChoice QuestionBox">
            <div className={"Title"}>{title}{isRequired?<span className={"RedAsterisk"}>{" *"}</span>:<></>}</div>
            {subtitle?<div className={"Subtitle"}>{subtitle}</div>:<></>}
                <table>
                    <thead>
                        <tr>
                            <td>
                            </td>
                            <td>
                                {caption_true}
                            </td>
                            <td>
                                {caption_false}
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {options.map((option, index) =>
                            <tr key={option}>
                                <td className={'OptionText'}>{option}</td>
                                <td>
                                    <Radio 
                                        checked={response[option] === true}
                                        onChange={()=>
                                            setResponse({
                                                ...response, 
                                                [option]: true
                                            })
                                        }
                                    />
                                </td>
                                <td>
                                    <Radio 
                                        checked={response[option] === false}
                                        onChange={()=>
                                            setResponse({
                                                ...response, 
                                                [option]: false
                                            })
                                        }
                                    />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
    
        </div>);

    }

}

export default MultipleChoice;