import { TextField } from "@material-ui/core";
import React from "react";

import './Subjective.scss';

const Subjective = ({
    response, setResponse,
    title = "제목", subtitle= null,
    isRequired = false,
}) => {

    return (<div className="Subjective QuestionBox">
        <div className={"Title"}>{title}{isRequired?<span className={"RedAsterisk"}>{" *"}</span>:<></>}</div>
        {subtitle?<div className={"Subtitle"}>{subtitle}</div>:<></>}
        <TextField
            fullWidth
            value={response}
            onChange={(event) => {
                setResponse(event.target.value)
            }}
        />
    </div>);
}

export default Subjective;