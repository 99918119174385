import React from 'react';
const DummyField = (props) => {

    const [value, setValue] = React.useState(props.defaultValue);

    React.useEffect(()=>{
        setValue(props.defaultValue);
    },[props.defaultValue, setValue]);

    return (<div>{value}</div>);
}

export default DummyField;