import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';

import React from 'react';
import EditableTable from '../../../Component/Table/EditableTable';

import {listShortcuts, createShortcut, updateShortcut} from './Data/Shortcut';

import { WarningRounded } from '@material-ui/icons';

import './TitleList.scss';
import LoadingCircle from '../../../Component/LoadingCircle';

const ShortcutAdmin = ({serviceId}) => {
    const [shortcuts, setShortcuts] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const refreshShortcuts = async (serviceId) => {
        setIsLoading(true);
        let newshortcuts = await listShortcuts();
        setShortcuts(newshortcuts);
        setIsLoading(false);
    }
    React.useEffect(()=>{
        refreshShortcuts(serviceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serviceId]);

    const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
    const [, setInnerWidth] = React.useState(window.innerWidth);
    React.useEffect(()=>{
        const resizeListener = () => {
            setInnerHeight(window.innerHeight)
            setInnerWidth(window.innerWidth);
        }
        window.addEventListener('resize', resizeListener);

        return ()=>{
            window.removeEventListener('resize', resizeListener);
        }
    },[])

    const [editingIdx, setEditingIdx] = React.useState(null);
    
    const [isWaitingNewShortcut, setIsWaitingNewShortcut] = React.useState(false);
    const [newShortcutPath, setNewShortcutPath] = React.useState('');
    const [newShortcutDestination, setNewShortcutDestination] = React.useState('');
    const [newShortcutIosDestination, setNewShortcutIosDestination] = React.useState('');
    const [newShortcutAndDestination, setNewShortcutAndDestination] = React.useState('');

    return (<div className={"ShortcutList"}>
        <div className={"TitleListContainer"}>
            <EditableTable
                style={{maxHeight: innerHeight - 130, overflow: 'auto'}}
                headerButtonHeaders={[
                    <Button variant={'contained'} color={'primary'} onClick={()=>{ setIsWaitingNewShortcut(true); }} >{'추가'}</Button>
                ]}
                columnHeaders={['경로', '도착지', '안드로이드', 'iOS']}
                headerButtonsFunction={(row, index)=>[
                    (editingIdx !== null && editingIdx === index)
                    ?{
                        name: '완료', color: 'primary', props:{ style: {paddingTop:0, paddingBottom:0, marginLeft: 5}, fullWidth: true, },
                        onClick:()=>{ setEditingIdx(null);}
                    } 
                    :{
                        name: '수정', color: 'primary', props:{ style: {paddingTop:0, paddingBottom:0, marginLeft: 5}, fullWidth: true, },
                        onClick:()=>{ setEditingIdx(index);}
                    }
                ]}
                columnsFunction={(row, index)=>[
                    {isEditing: false, type: 'string', field: 'path', style:{whiteSpace: 'nowrap', fontSize: '0.8rem'}, defaultValue: row.path},
                    {isEditing: index === editingIdx, type: 'string', field: 'destination',    style:{whiteSpace: 'nowrap', fontSize: '0.8rem', width: '100%'}, defaultValue: row.destination},
                    {isEditing: index === editingIdx, type: 'string', field: 'andDestination', style:{whiteSpace: 'nowrap', fontSize: '0.8rem', width: '100%'}, defaultValue: row.andDestination},
                    {isEditing: index === editingIdx, type: 'string', field: 'iosDestination', style:{whiteSpace: 'nowrap', fontSize: '0.8rem', width: '100%'}, defaultValue: row.iosDestination},
                    
                ]}
                updateGenerator={(row, index) => async (newValue) => {
                    updateShortcut({...row, ...newValue})
                    for(const [key, value] of Object.entries(newValue)){
                        row[key] = value;
                    }
                }}
                isDownloadable={true}
                data={shortcuts}
                name={"shortcuts"}

            />
        </div>
        <div className={"ShortcutListTail"}>
            {!isLoading && shortcuts.length === 0
            ?<div className={"NoShortcutWarning"}>
                <WarningRounded/>
                {"아직 생성된 단축 URL이 없습니다."}
            </div>:<></>}
            
        </div>
        <Dialog open={isWaitingNewShortcut} onClose={()=>{setIsWaitingNewShortcut(false)}}>
            <DialogTitle>{"단축 URL 생성"}</DialogTitle>
            <DialogContent>
                {'경로'}<TextField value={newShortcutPath} onChange={(event)=>{setNewShortcutPath(event.target.value)}}/>
                {'목적지'}<TextField value={newShortcutDestination} onChange={(event)=>{setNewShortcutDestination(event.target.value)}}/>
                {'안드로이드'}<TextField value={newShortcutAndDestination} onChange={(event)=>{setNewShortcutAndDestination(event.target.value)}}/>
                {'아이폰'}<TextField value={newShortcutIosDestination} onChange={(event)=>{setNewShortcutIosDestination(event.target.value)}}/>
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} color={'primary'}
                    onClick={async ()=>{
                        if (!newShortcutPath.startsWith('/')){
                            alert('경로는 항상 "/"로 시작해야 합니다.');
                            return;
                        }
                        if (!newShortcutDestination.startsWith('http://') && !newShortcutDestination.startsWith('https://')){
                            alert('목적지는 항상 "https://" 또는 "http://"로 시작해야 합니다.');
                            return;
                        }
                        if (newShortcutAndDestination && !newShortcutAndDestination.startsWith('http://') && !newShortcutAndDestination.startsWith('https://')){
                            alert('안드로이드 목적지는 항상 "https://" 또는 "http://"로 시작해야 합니다.');
                            return;
                        }
                        if (newShortcutIosDestination && !newShortcutIosDestination.startsWith('http://') && !newShortcutIosDestination.startsWith('https://')){
                            alert('iOS 목적지는 항상 "https://" 또는 "http://"로 시작해야 합니다.');
                            return;
                        }
                        let res = await createShortcut({path: newShortcutPath, destination: newShortcutDestination, iosDestination: newShortcutIosDestination, andDestination: newShortcutAndDestination});
                        if (res){
                            setIsWaitingNewShortcut(false)
                            setNewShortcutPath('')
                            setNewShortcutDestination('')
                            setNewShortcutAndDestination('')
                            setNewShortcutIosDestination('')
                            refreshShortcuts();
                        }else{
                            alert("저장에 실패했습니다.")
                        }
                    }}
                >{"저장"}</Button>
                <Button variant={'default'} color={'secondary'} onClick={()=>{setIsWaitingNewShortcut(false)}}>{"취소"}</Button>
            </DialogActions>
        </Dialog>
        <LoadingCircle show={isLoading}/>
    </div>);
}

export default ShortcutAdmin;
