import axios from "axios";
import ss from '../../../../Data/Session';

let s3Object = {};

export const listObjects = async ({bucket, maxKeys, prefix}) => {
    try{
        const continuationToken = s3Object[prefix || 'root']?.continuationToken || null;

        if(continuationToken && maxKeys === 300 && s3Object[prefix || 'root']){
            s3Object[prefix || 'root'].isTruncated = false;
            return s3Object[prefix || 'root'];
        }

        if(!continuationToken && s3Object[prefix || 'root']){
            s3Object[prefix || 'root'].isTruncated = false;
            return s3Object[prefix || 'root'];
        }

        let objectList = await axios.post(`https://challenge-api.webtoon.today/cloud`, {
            bucket, continuationToken, maxKeys, prefix
        },{
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        if(objectList.data.code === 200){
            s3Object[prefix || 'root'] 
                = (s3Object[prefix || 'root'] || [])
                    .concat([...(objectList.data.data.commonPrefixes.map(row=>({...row, key: row.prefix}))),...objectList.data.data.contents]
                                .sort((a,b)=>(a.Key>b.Key)?1:(a.Key<b.Key)?-1:0));
            s3Object[prefix || 'root']['continuationToken'] = objectList.data.data.nextContinuationToken || null;
            s3Object[prefix || 'root']['isTruncated'] = objectList.data.data.isTruncated;
            return s3Object[prefix || 'root'];
        }else{
            return null;
        }
    }catch(e){
        console.error(e);
        return null;
    }
}

export const getObjectDownloadURL = async ({bucket, filename}) => {
    try{
        let result = await axios.post(`https://challenge-api.webtoon.today/cloud`, {
            bucket, filename
        },{
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        if(result.data.code === 200){
            return result.data.data.downloadURL;
        }else{
            return null;
        }
    }catch(e){
        console.error(e);
        return null;
    }
}

export const uploadFileToS3 = async ({bucket, key, file}) => {
    let ret = null;

    let S3Signed = await axios.put(`https://challenge-api.webtoon.today/cloud`, {
        bucket, key
    }, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    })
    
    let res = await axios.put(S3Signed.data.data.uploadURL, file, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        withCredentials: true
    })

    if (res.status === 200){
        ret = 'https://'+bucket+S3Signed.data.data.key;
    }

    return ret;
}

