import axios from 'axios';
import ss from './Session';

// @type
// eslint-disable-next-line no-unused-vars
import {sessionType} from './Session';

let memStore = {};

/**
 * 
 * @typedef {{
 *     serviceId: string, titleId: string, title: string, thumbnail: string, tags: string, isAdult: boolean,
 *     lastUpdatedAt: number, lastEpisodeId: string, lastFreeEpisodeId: string, firstEpisodeId: string, episodeCount: number, freeEpisodeCount: number,
 *     lastEpisodeTitle: string, lastEpisodeHref: string, lastEpisodeThumbnail: string,
 *     lastFreeEpisodeTitle: string, lastFreeEpisodeHref: string,
 *     firstEpisodeTitle: string, firstEpisodeHref: string,
 *     href: string,
 *     rating?: number, popularity?: number, rank?: number,
 *     is_finished: 0|1, weekday: string, slides: string[]|null,
 * }} titleType 
 */

/**
 * 
 * @param {string} serviceId 
 * @param {string} titleId 
 * @returns {Promise<titleType[]>}
 */
export async function getTitle (serviceId, titleId, dispatch = null) {
    if (dispatch) {
        return await dispatch(getTitlesThunk([[serviceId, titleId]], false));
    }

    try{
        let res = await axios.post('https://api.webtoon.today/titles', {key: [serviceId, titleId].join(":")}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.data;

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}

export async function clearTitle (serviceId, titleId, dispatch = null) {
    if (!dispatch){
        return;
    }

    return await dispatch(clearTitlesThunk([serviceId, titleId]));
}

/**
 * 
 * @param {string} key 
 * @param {boolean} ignoreCache
 * @returns {Promise<titleType[]>}
 */
export async function getTitles (keys, ignoreCache = false, dispatch = null) {
    if (dispatch) {
        return await dispatch(getTitlesThunk(keys, ignoreCache));
    }
    
    if (!ignoreCache && memStore[`titles?`+JSON.stringify({keys})]){
        return memStore[`titles?`+JSON.stringify({keys})];
    }

    try{
        let res = await axios.post('https://api.webtoon.today/titles', {key: keys.map(pair => pair.join(":")).join(",")}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            memStore[`titles?`+JSON.stringify({keys})] = res.data.data;
            return res.data.data;

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}

/**
 * 
 * @param {string} query 
 * @param {string} limit 
 * @param {string} weekday 
 * @param {string} serviceId 
 * @param {boolean} includeAll 
 * @returns {Promise<titleType[]>}
 */
export async function readTitles (query, limit, weekday, serviceId, includeAll, ignoreCache = false, dispatch = null) {
    if (dispatch) {
        
        let keyObjects = await dispatch(searchThunk( query, limit, weekday, serviceId, includeAll, ignoreCache));

        let keysArrays = [];
        for(const keyObject of keyObjects) {
            keysArrays.push(Object.values(keyObject)) 
        }
        
        return await dispatch(getTitlesThunk(keysArrays, ignoreCache))
    }

    if (!ignoreCache && memStore[`titles?`+JSON.stringify({limit,query,weekday, serviceId, includeAll})]){
        return memStore[`titles?`+JSON.stringify({limit,query,weekday, serviceId, includeAll})];
    }

    try{
        let keys = await axios.get(`https://api.webtoon.today/search?limit=${limit}${query?`&query=${query}`:``}${weekday?`&weekday=${weekday}`:``}${serviceId?`&serviceId=${serviceId}`:``}${includeAll?`&includeAll=true`:``}`);
        
        if (keys.data && keys.data.code === 200){
            
            if (keys.data.data.length === 0){
                return [];
            }

            let res = await axios.post(`https://api.webtoon.today/titles`, {key: keys.data.data.map(pair => `${pair.serviceId}:${pair.titleId}`).join(',')});
            
            if (res.data && res.data.code === 200){

                memStore[`titles?`+JSON.stringify({limit,query,weekday, serviceId, includeAll})] = res.data.data;
                return res.data.data;
                
            }else {
                return [];
            }
        }else{
            return [];
        }
    }catch(e){
        console.error(e);
        return [];
    }
}

/**
 * @param {string} query 
 * @param {string} limit 
 * @param {string} weekday 
 * @param {string} serviceId 
 * @param {boolean} includeAll 
 * @returns {Promise<titleType[]>}
 */
export async function readTitlesByCache (query, limit, weekday, serviceId, includeAll) {

    if (memStore[`titles?`+JSON.stringify({limit,query,weekday, serviceId, includeAll})]){
        return memStore[`titles?`+JSON.stringify({limit,query,weekday, serviceId, includeAll})];
    }
    try{
        let keys = await axios.get(`https://cached-api.webtoon.today/search?limit=${limit}${query?`&query=${query}`:``}${weekday?`&weekday=${weekday}`:``}${serviceId?`&serviceId=${serviceId}`:``}${includeAll?`&includeAll=true`:``}`);

        if (keys.data && keys.data.code === 200){

            if (keys.data.data.length === 0){
                return [];
            }

            let res = await axios.post(`https://api.webtoon.today/titles`, {key: keys.data.data.map(pair => `${pair.serviceId}:${pair.titleId}`).join(',')});

            if (res.data && res.data.code === 200){

                memStore[`titles?`+JSON.stringify({limit,query,weekday, serviceId, includeAll})] = res.data.data;
                return res.data.data;
    
            }else {
                return [];
            }
        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}

/**
 * 
 * @param {{serviceId: string, titleId:string}} props
 * @returns {Promise<string|null>}
 */
export async function createTitle ({serviceId, titleId}) {
    try{
        let res = await axios.put('https://api.webtoon.today/titles', {
            serviceId, titleId
        }, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}

/**
 * 
 * @param {{serviceId: string, titleId:string}} props
 * @returns {Promise<string|null>}
 */
 export async function createTitleFromChallenge ({serviceId, titleId, ...others}) {
    try{
        let res = await axios.put('https://api.webtoon.today/create-title-from-challenge', {
            serviceId, titleId, ...others
        }, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}

/**
 * 
 * @param {{serviceId: string, titleId:string}} props
 * @returns {Promise<string|null>}
 */
export async function updateTitle (title, dispatch) {

    try{
        let res = await axios.patch('https://api.webtoon.today/titles', title, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        await dispatch(clearTitlesThunk([title.serviceId, title.titleId]))
        if (res.data && res.data.code === 200){

            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        console.error(e)
        return null;
    }
}

/**
 * 
 * @param {{serviceId: string, titleId:string}} props
 * @returns {Promise<string|null>}
 */
export async function deleteTitle ({serviceId,titleId}) {
    try{
        let res = await axios.delete(`https://api.webtoon.today/titles?serviceId=${serviceId}&titleId=${titleId}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}



// 리듀서

const initialTitlesState = {
    loading: false,
    data: {},
    emptyTitleKeys: [],
    error: null
}

const GET_ACTION_START = "GET_ACTION_START";
const GET_ACTION_SUCCESS = "GET_ACTION_SUCCESS";
const GET_ACTION_FAIL = "GET_ACTION_FAIL";

const getActionStart = () => {
    return {
        type: GET_ACTION_START
    };
};

const getActionSuccess = (data, emptyTitleKeys) => {
    return {
        type: GET_ACTION_SUCCESS,
        data,
        emptyTitleKeys
    };
};

const getActionFail = (error) => {
    return {
        type: GET_ACTION_FAIL,
        error
    };
};

const CLEAR_ACTION_START = "CLEAR_ACTION_START";
const CLEAR_ACTION_SUCCESS = "CLEAR_ACTION_SUCCESS";
const CLEAR_ACTION_FAIL = "CLEAR_ACTION_FAIL";

const clearActionStart = () => {
    return {
        type: CLEAR_ACTION_START
    };
};

const clearActionSuccess = (key) => {
    return {
        type: CLEAR_ACTION_SUCCESS,
        key,
    };
};

const clearActionFail = (error) => {
    return {
        type: CLEAR_ACTION_FAIL,
        error
    };
};

/**
 * 
 * @param {Array<String<>>} keys 
 * @param {boolean} ignoreCache 
 * @returns {Promise<titletype[]>}
 * @description thunk: 기존의 서브루틴에 추가적인 연산을 삽입할 때 사용되는 서브루틴 입니다.
 */
const getTitlesThunk = ( keys, ignoreCache = false ) => {
    return async ( dispatch, getState ) => {

        const titlesStore = getState().titles.data
        const emptyTitleKeys = getState().titles.emptyTitleKeys
        
        const existentTitles = [];
        let nonExistentKeys = [];
        let unknownKeys = [];

        if (!ignoreCache) {

            for(const key of keys){
                if(titlesStore.hasOwnProperty(`${key.join(':')}`)) {
                    existentTitles.push(titlesStore[`${key.join(':')}`]) 
                } else if (!titlesStore.hasOwnProperty(`${key.join(':')}`)) {
                    nonExistentKeys.push(key)
                };
            };

        } else {
            nonExistentKeys = keys
        };
        unknownKeys = nonExistentKeys
        if ( emptyTitleKeys && emptyTitleKeys.length > 0 ) {
            let exceptKeys = new Set([])
            for(const emptyKey of emptyTitleKeys) {
                exceptKeys.add(emptyKey.join(':'))
            };
            
            unknownKeys = nonExistentKeys.filter(newkey => !(exceptKeys.has(`${newkey.join(":")}`)));
        };

        if(unknownKeys && unknownKeys.length > 0 ){

            try{
                dispatch(getActionStart());
                let res = await axios.post('https://api.webtoon.today/titles', {key: unknownKeys.map(pair => pair.join(":")).join(",")}, {
                    headers: {
                        Authorization: `Bearer ${ss.getCurrentSession().jwt}`
                    },
                    withCredentials: true
                });
                
                let gottenTitles = {};
                for (const title of res.data.data) {
                    gottenTitles[`${title.serviceId}:${title.titleId}`] = title
                };

                let vacantTitleKeys = [];
                for(const key of unknownKeys){
                    if(!(gottenTitles.hasOwnProperty(key.join(':')))){
                        vacantTitleKeys.push(key)
                    };
                };

                if (res.data && res.data.code === 200){
                    
                    dispatch(getActionSuccess(gottenTitles, vacantTitleKeys));
                    
                    return existentTitles.concat(res.data.data);
        
                }else{
                    dispatch(getActionSuccess(gottenTitles, unknownKeys));
                    return [];
                };
            }catch(e){
                dispatch(getActionFail(e));
                return [];
            };

        } else {
            return existentTitles;
        };

    };
};

const clearTitlesThunk = (key) => {
    return async ( dispatch, getState ) => {
        dispatch(clearActionStart());

        const {data:titlesStore, emptyTitleKeys: emptyTitleStore} = getState().titles
        if (titlesStore.hasOwnProperty(key.join(":")) || emptyTitleStore.map(pair => pair.join(":")).indexOf(key.join(":")) >= 0){
            dispatch(clearActionSuccess(key));

            return key;
        }else{
            dispatch(clearActionFail(new Error("no key matched")));

            return null;
        }
    };
}

//titlesReducer

export const titlesReducer = (state = initialTitlesState, action) => {
    if(action.type === GET_ACTION_START){
        return {
            ...state,
            loading: true,
            error: null
        };
    };
    if(action.type === GET_ACTION_SUCCESS){
        return {
            ...state,
            loading: false,
            data: {
                ...state.data,
                ...action.data
            },
            emptyTitleKeys: [
                ...state.emptyTitleKeys,
                ...action.emptyTitleKeys
            ]
        };
    };
    if(action.type === GET_ACTION_FAIL){
        return {
            ...state,
            loading: false,
            data: {
                ...state.data
            },
            emptyTitleKeys: [
                ...state.emptyTitleKeys
            ]
        };
    };

    if(action.type === CLEAR_ACTION_START){
        return {
            ...state,
            error: null
        };
    };
    if(action.type === CLEAR_ACTION_SUCCESS){

        const [serviceId, titleId] = action.key;

        let newStateData = Object.fromEntries(Object.entries(state.data).filter(([k,v], index) => k !== `${serviceId}:${titleId}`))
        let newEmptyData = [...state.emptyTitleKeys.filter(([k,v], index) => `${k}:${v}` !== `${serviceId}:${titleId}`)]
        return {
            ...state,
            data: {
                ...newStateData
            },
            emptyTitleKeys: newEmptyData
        };
    };
    if(action.type === CLEAR_ACTION_FAIL){
        return {
            ...state
        };
    };
    
    return state;
};


// 리듀서 readTitles

const initialSearchState = {
    loading: false,
    data: {},
    error: null
};

const SEARCH_ACTION_START = "SEARCH_ACTION_START";
const SEARCH_ACTION_SUCCESS = "SEARCH_ACTION_SUCCESS";
const SEARCH_ACTION_FAIL = "SEARCH_ACTION_FAIL";

const searchActionStart = () => {
    return {
        type: SEARCH_ACTION_START
    };
};

const searchActionSuccess = (data) => {
    return {
        type: SEARCH_ACTION_SUCCESS,
        data
    };
};

const searchActionFail = (error) => {
    return{
        type: SEARCH_ACTION_FAIL,
        error
    };
};

/**
 * 
 * @param {string} query 
 * @param {string} limit 
 * @param {string} weekday 
 * @param {string} serviceId 
 * @param {boolean} includeAll 
 * @param {boolean} ignoreCache
 * @returns {Promise<string<>>}
 */
const searchThunk = (query, limit, weekday, serviceId, includeAll, ignoreCache = false) => {
    return async ( dispatch, getState ) => {

        let searchedKeysStore = getState().search.data;

        let queryString =	            
            `limit=${limit}`+
            (query?`&query=${query}`:``)+
            (weekday?`&weekday=${weekday}`:``)+
            (serviceId?`&serviceId=${serviceId}`:``)+
            (includeAll?`&includeAll=true`:``)

        if (!ignoreCache && searchedKeysStore[`${queryString}`]) {
            return searchedKeysStore[`${queryString}`];
        };
    
        try{
            dispatch(searchActionStart());
            let keys = await axios.get(`https://api.webtoon.today/search?${queryString}`);

            if (keys.data && keys.data.code === 200){
                
                if (keys.data.data.length === 0){
                    dispatch(searchActionSuccess({[queryString]: []}))
                    return [];
                };
                
                let gottonKeys = {};
                gottonKeys[`${queryString}`] = keys.data.data;

                dispatch(searchActionSuccess(gottonKeys));
                return keys.data.data;
            } else {
                dispatch(searchActionSuccess({[queryString]: []}));
                return [];
            };
        }catch(e){
            dispatch(searchActionFail(e));
            console.error(e);
            return [];
        };
    };
};

export const SearchReducer = (state = initialSearchState, action) => {
    if (action.type === SEARCH_ACTION_START){
        return {
            ...state,
            loading: false,
            error: null
        };
    };
    if (action.type === SEARCH_ACTION_SUCCESS){
        return {
            ...state,
            data: {
                ...state.data,
                ...action.data
            }
        };
    };
    if (action.type === SEARCH_ACTION_FAIL){
        return {
            ...state,
            data: {
                ...state.data
            },
            error: action.error
        };
    };
    return state;
};

