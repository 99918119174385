import ss from '../../../../Data/Session';
import axios from 'axios';

/**
 * @typedef {{
 *   name: string, episodeRange: [number, number], title:string, serviceId:string, titleId:string, school:string,
 *   service:'competition'|'analytics', introduction:string, advertisementCuts: {name: string, image: string, createdAt: number}[], requestCreatives: boolean, consentList: string[], currentTermsofuseVersion: string
 * }} CompetitionInfo
 * 
 * @param {CompetitionInfo} competitionInfo
 * @returns 
 */

export const AnalyticsSubmit = async (competitionInfo) => {

    try{
        
        let res = await axios.post('https://challenge-api.webtoon.today/analytics-submit-confirm', competitionInfo,  {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data.code === 200 && res.data.data) {
            return res.data.data;
        } else {
            return null;
        };

    }catch(e){
        console.error(e)
        return null;
    };
};

