import { Button, Dialog, DialogContent, Slide, TextField } from '@material-ui/core';
import { KeyboardArrowRight, SaveAltOutlined } from '@material-ui/icons';

import React from 'react';
import { useDispatch } from 'react-redux';
import ContentsNavigator from '../Component/ContentsNavigator';
import ReviewShort from '../Component/ReviewShort';
import Toast from '../Component/Toast';

import { logBehavior, logBulkBehavior } from '../Data/Behavior';
import { readContents } from '../Data/Contents';
import { readEpisodes } from '../Data/Episode';
import { listReviews } from '../Data/Review';
import ss from '../Data/Session';
import { getTitle } from '../Data/Title';
import { thumbnailize, fn, getOffsetInScreen } from '../Functions';
import './Endpage.scss';

/** @typedef {import('../Data/Episode').episodeType} episodeType */

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

//['content-first-seen', 'content-landing', 'content-stay', 'content-read', 'content-cut-seen', 'content-cut-leave', 'content-reach-bottom', 'content-next']

let logs = [];

let cummulated_seen_time = {};

const Endpage = ({serviceId, titleId, episodeId, snapshotId}) => {
    let {
        titleInfo: titleInfoFromServer = null,
        episodeList: episodeListFromServer = [],
        episodeInfo: episodeInfoFromServer = {},
        contents: contentsFromServer = {data:{images: [], nextEpisodeId: null, nextSnapshotId: null}}
    } = window.predef_data || {};

    const dispatch = useDispatch();
    const [item, setItem] = React.useState(titleInfoFromServer);
    React.useEffect(()=>{
        (async ()=>{
            let newTitle = (await getTitle(serviceId, titleId, dispatch)).concat([null])[0];
            setItem(newTitle);
            document.title = `${(newTitle || {}).title || "--"} ${episodeId}`;
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serviceId, titleId])

    const [nextEpisodeId, setNextEpisodeId] = React.useState((contentsFromServer.data || {}).nextEpisodeId);
    const [nextSnapshotId, setNextSnapshotId] = React.useState((contentsFromServer.data || {}).nextSnapshotId);
    const [contents, setContents] = React.useState((contentsFromServer.data || {images: []}));
    React.useEffect(()=>{
        (async()=>{
            cummulated_seen_time = {};
            let newContents = await readContents(serviceId,titleId,episodeId, snapshotId);
            if (newContents.status === 403 && !ss.getCurrentSession().email){
                fn.goto(`/settings?redirectTo=/view/${serviceId}/${titleId}/${episodeId}/${snapshotId || ""}`)
                return;
            }

            setContents(newContents.data || {images: []});
            setNextEpisodeId(newContents.data.nextEpisodeId);
            setNextSnapshotId(newContents.data.nextSnapshotId);
        })();
    },[serviceId, titleId, episodeId, snapshotId]);

    const [loadedImages, setLoadedImages] = React.useState((contentsFromServer.data || {images: []}).images);
    React.useEffect(()=>{
        setLoadedImages(contents.images || []);

        let {serviceId, titleId, episodeId, snapshotId} = contents;

        if (contents.serviceId && contents.titleId && contents.episodeId){
            if (!localStorage.getItem('content:didContentFirstSeen')){
                localStorage.setItem('content:didContentFirstSeen', 'true');
                logBehavior('content-first-seen', {serviceId, titleId, episodeId, snapshotId});
            }
            logBehavior('content-landing', {serviceId, titleId, episodeId, snapshotId});
        }

        const scrollListener = () => {
            let imageDOMs = [...document.querySelectorAll('.Body img.Contents')];
            for( let dom of imageDOMs ){
                let boundingRect = getOffsetInScreen(dom);
                if (0 < boundingRect.top && boundingRect.top + boundingRect.height < window.innerHeight){
                    if(dom.dataset.exposed !== 'true') {
                        
                        logs.push({what: 'content-cut-seen', detail: {serviceId, titleId, episodeId, snapshotId, cutId: dom.id}});

                        dom.dataset.exposed = 'true';
                        dom.dataset.seenFrom = new Date().getTime() / 1000;
                    }
                }else {
                    if(dom.dataset.exposed === 'true') {

                        cummulated_seen_time[dom.id] = (cummulated_seen_time[dom.id] || 0) + new Date().getTime() / 1000 - dom.dataset.seenFrom;
                        logs.push({what: 'content-cut-leave', detail: {serviceId, titleId, episodeId, snapshotId, cutId: dom.id, enterAt: dom.dataset.seenFrom+0, duration: cummulated_seen_time[dom.id] }});

                        dom.dataset.exposed = 'false';
                    }
                }
            }
        }
        window.addEventListener('scroll', scrollListener);

        const logScheduler = () => {
            if (logs.length > 0){
                logBulkBehavior([...logs]);
                logs = [];
            }
        }
        let handle = setInterval(logScheduler, 1000);

        return () => {
            window.removeEventListener('scroll', scrollListener);
            clearInterval(handle);
        }
    },[contents])

    /** @type {[episodeType[], React.Dispatch<React.SetStateAction<episodeType[]>>]} */
    const [allEpisodes, setAllEpisodes] = React.useState(episodeListFromServer);
    React.useEffect(()=>{
        (async()=>{
            let newEpisode = (await readEpisodes(serviceId, titleId));
            
            setAllEpisodes(newEpisode);
        })();
    },[serviceId, titleId]);

    /** @type {[episodeType[], ()=>void]} */
    const [episode, setEpisode] = React.useState(episodeInfoFromServer);
    React.useEffect(()=>{
        if(allEpisodes.filter(episode => episode.episodeId === episodeId).length >0){
            setEpisode(allEpisodes.filter(episode => episode.episodeId === episodeId)[0]);
        }else {
            setEpisode({});
        }
    },[episodeId, allEpisodes]);

    const [reviews, setReviews] = React.useState([]);
    React.useEffect(()=>{
        (async()=>{
            let newReviews = await listReviews({serviceId, titleId, episodeId, snapshotId});
            setReviews(newReviews);
        })();
    },[serviceId, titleId, episodeId, snapshotId])

    const [showNavigator, setShowNavigator] = React.useState(false);
    const [showDialog, setShowDialog] = React.useState(false);

    const [subscribingEmail, setSubscribingEmail] = React.useState('');

    const [openSubscribeDoneToast, setOpenSubscribeDoneToast] = React.useState(false);

    if (loadedImages.length === 0){
        return (<div className={"EndPage"}>
            <ContentsNavigator
                pageName={episode.title}
                forceToShow={showNavigator}
                hideClean
                rightButton={<div className={"MenuButton"} style={{opacity: 0, }}/>}
                nextOnClick={()=>{
                    logBehavior('content-next', )
                    
                    if(window.gtag){
                        window.gtag('event', 'conversion', {
                            'send_to': 'AW-604780621/qTXfCLbp4PgBEM3wsKAC',
                            'event_callback': ()=>{}
                        });
                    }
                    let idxOfEpisode=  allEpisodes.sort((a,b) => { try{ return Number(a.episodeId) - Number(b.episodeId)}catch(e){return 0} }).map((episode, index) => [episode, index]).filter(([episode, index]) => episode.episodeId === episodeId).concat([null,allEpisodes.length])[0][1];
                    if (idxOfEpisode < allEpisodes.length - 1){
                        let newerEpisodes = allEpisodes.slice(idxOfEpisode+1);
                        fn.goto(`/view/${serviceId}/${titleId}/${nextEpisodeId || newerEpisodes[0].episodeId}${nextSnapshotId?`/${nextSnapshotId}`:''}`);
                    }else{
                        setShowDialog(true)
                    }
                }}
                prevOnClick={()=>{
                    logBehavior('content-prev')
                    let idxOfEpisode=  allEpisodes.sort((a,b) => { try{ return Number(a.episodeId) - Number(b.episodeId)}catch(e){return 0} }).map((episode, index) => [episode, index]).filter(([episode, index]) => episode.episodeId === episodeId).concat([null,-1])[0][1];
                    if (idxOfEpisode > 0){
                        let olderEpisodes = allEpisodes.slice(0, idxOfEpisode);
                        fn.goto(`/view/${serviceId}/${titleId}/${olderEpisodes[olderEpisodes.length-1].episodeId}`);
                    }else{
                        alert("첫번째 에피소드입니다.")
                    }
                }}
            />
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: window.innerHeight}}>
                <img src='https://static.webtoon.today/loading.gif' alt={"loading"} style={{width: '33%', maxWidth: 200, height: 200, borderRadus: 20, objectFit: 'contain'}}/>
            </div>
        </div>)
    }

    let currentSession = ss.getCurrentSession();
    return (<div className={"Endpage"} onClick={()=>setShowNavigator(!showNavigator)}>
        <ContentsNavigator
            pageName={episode.title}
            forceToShow={showNavigator}
            hideClean
            rightButton={<div className={"MenuButton"} style={{opacity: 0, }}/>}
            nextOnClick={()=>{
                logBehavior('content-next', )
                
                if(window.gtag){
                    window.gtag('event', 'conversion', {
                        'send_to': 'AW-604780621/qTXfCLbp4PgBEM3wsKAC',
                        'event_callback': ()=>{}
                    });
                }
                let idxOfEpisode=  allEpisodes.sort((a,b) => { try{ return Number(a.episodeId) - Number(b.episodeId)}catch(e){return 0} }).map((episode, index) => [episode, index]).filter(([episode, index]) => episode.episodeId === episodeId).concat([null,allEpisodes.length])[0][1];
                if (idxOfEpisode < allEpisodes.length - 1 && (nextEpisodeId === undefined || nextEpisodeId === null || Number(nextEpisodeId) >= 0)){
                    let newerEpisodes = allEpisodes.slice(idxOfEpisode+1);
                    fn.goto(`/view/${serviceId}/${titleId}/${nextEpisodeId || newerEpisodes[0].episodeId}${nextSnapshotId?`/${nextSnapshotId}`:''}`);
                }else{
                    setShowDialog(true)
                }
            }}
            prevOnClick={()=>{
                let idxOfEpisode=  allEpisodes.sort((a,b) => { try{ return Number(a.episodeId) - Number(b.episodeId)}catch(e){return 0} }).map((episode, index) => [episode, index]).filter(([episode, index]) => episode.episodeId === episodeId).concat([null,-1])[0][1];
                if (idxOfEpisode > 0){
                    let olderEpisodes = allEpisodes.slice(0, idxOfEpisode);
                    fn.goto(`/view/${serviceId}/${titleId}/${olderEpisodes[olderEpisodes.length-1].episodeId}`);
                }else{
                    alert("첫번째 에피소드입니다.")
                }
            }}
        />
        <div className={`Body`}>
            {loadedImages.map(image =>
                <img
                    className={'Contents'}
                    id={image.split('/').reverse()[0].split('.').reverse()[1]}
                    key={image}
                    src={thumbnailize(image)}
                    alt={"main contents"}
                    style={{}}
                />)}
            <Button style={{width: '100%', color: 'rgb(0, 176, 240)'}} fullWidth onClick={()=>{
                logBehavior('content-next', )
                
                if(window.gtag){
                    window.gtag('event', 'conversion', {
                        'send_to': 'AW-604780621/qTXfCLbp4PgBEM3wsKAC',
                        'event_callback': ()=>{}
                    });
                }
                let idxOfEpisode=  allEpisodes.sort((a,b) => { try{ return Number(a.episodeId) - Number(b.episodeId)}catch(e){return 0} }).map((episode, index) => [episode, index]).filter(([episode, index]) => episode.episodeId === episodeId).concat([null,allEpisodes.length])[0][1];
                if (idxOfEpisode < allEpisodes.length - 1 && (nextEpisodeId === undefined || nextEpisodeId === null || Number(nextEpisodeId) >= 0)){
                    let newerEpisodes = allEpisodes.slice(idxOfEpisode+1);
                    fn.goto(`/view/${serviceId}/${titleId}/${nextEpisodeId || newerEpisodes[0].episodeId}${nextSnapshotId?`/${nextSnapshotId}`:''}`);
                }else{
                    setShowDialog(true)
                }
            }}>{"다음화 보기"}<KeyboardArrowRight style={{
                borderRadius: '50%',
                border: '1px rgb(0, 176, 240) solid',
                marginLeft: 10,
                marginRight: 10,
            }}/></Button>
            {reviews.filter(review => review.addressid === currentSession.addressid || review.addressid === currentSession.userid).length===0?<div className={"ReviewInShort"}>
                <div className={"Message"}>{"새로운 리뷰를 남겨주세요!"}</div>
                <ReviewShort
                    windowSize={{width: window.innerWidth, height: window.innerHeight}}
                    indexInfo={{serviceId, titleId, episodeId}}
                    givenReview={null}
                    reviewCallback={async()=>{
                        (async()=>{
                            let newReviews = await listReviews({serviceId, titleId, episodeId, snapshotId});
                            setReviews(newReviews);
                        })();
                    }}
                />
            </div>:<></>}
            {reviews.map(review =>
            <ReviewShort
                key={`${review.serviceId}:${review.titleId}:${review.episodeId}:${review.addressid}`}
                windowSize={{width: window.innerWidth, height: window.innerHeight}}
                indexInfo={{serviceId, titleId, episodeId}}
                givenReview={review}
                reviewCallback={async()=>{
                    (async()=>{
                        let newReviews = await listReviews({serviceId, titleId, episodeId, snapshotId});
                        setReviews(newReviews);
                    })();
                }}
            />)}
        </div>
        <Dialog
            open={showDialog}
            TransitionComponent={Transition}
            onBackdropClick={()=>{setShowDialog(false)}}
        >
            <DialogContent style={{margin: 0, padding: 0}}>
                <div>
                    <img src={(item || {}).thumbnail} style={{width: '100%', objectFit:'contain'}} alt={"item"}/>
                </div>
                <div style={{lineHeight: '1.5rem', padding: 32}}>
                    {`<${(item || {}).title}> 마지막화 입니다.`}<br/>
                    {"구독정보를 입력하고 다음 회차를 받아보세요."}
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        
                        <TextField fullWidth style={{height: '1rem'}} value={subscribingEmail} placeholder={"이메일 주소"} onChange={(event)=>{setSubscribingEmail(event.target.value)}}></TextField>
                        <Button style={{width: 100,}} onClick={()=>{
                            logBehavior('content-subscribe', {email: subscribingEmail});
                            setShowDialog(false);
                            setOpenSubscribeDoneToast(true);
                        }}>{"구독"}<SaveAltOutlined style={{color: 'rgba(100,100,100,1)'}}/></Button>
                    </div>
                </div>
                <img style={{display: 'inherit', width: 'calc(100% - 128px)', marginLeft: 'auto', marginRight: 'auto', marginBottom: 32, verticalAlign:'top'}} src={"https://static.webtoon.today/ddah/logo-01.png"} alt={"logo"}/>
            </DialogContent>
        </Dialog>
        <Toast
            isOpen={openSubscribeDoneToast}
            message={"구독처리 되었습니다!"}
            windowSize={{width: window.innerWidth, height: window.innerHeight}}
            positionY={0}
            animationType={"up"}
            onCllick={()=>{setOpenSubscribeDoneToast(false)}}
            onClose={()=>{setOpenSubscribeDoneToast(false)}}
        />
    </div>);
}

export default Endpage;