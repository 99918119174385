import React, {useRef} from 'react';
import './Submit.scss';
import SubmitFileField from './SubmitFileField';
import { 
    Paper, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, 
    Button, TextField, RadioGroup, FormControlLabel, Radio, Checkbox, Select, 
    MenuItem, IconButton, FormControl, InputLabel, Tooltip 
} from '@material-ui/core';
import { verifyEmail, verifyOtp } from '../../../Data/Email';
import { sendApplication } from '../../../Data/Analytics';
import LoadingCircle from '../../../Component/LoadingCircle';
import { readChallengeData } from '../../../Data/ReadFromChallenge';

import { ArrowRight, CheckBox, Error as ErrorIcon} from '@material-ui/icons';
import {useOnScreen} from '../../../Hooks';
import {logBehavior} from '../../../Data/Behavior';
import {validateEmailForm} from '../../../Functions';
import DragAndDrop from './DragAndDrop';
import { useDispatch } from 'react-redux';
import { getTitle } from '../../../Data/Title';
import {fn} from '../../../Functions';
import AdsSlides from '../../AdsSlides';

let didSeen = false;

const Submit = () => {

    const ref = useRef();
    const onScreen = useOnScreen(ref);
    if (!didSeen && onScreen){
        didSeen = onScreen;
        logBehavior('scroll-submit', {});
    }

    const dispatch = useDispatch();
    const [exampleTitle, setExampleTitle] = React.useState({slides:[]})
    
    React.useEffect(()=>{
        (async()=>{
            let newTitle = await getTitle('today', 'the-law-of-the-jungle', dispatch) || [{}];
            setExampleTitle(newTitle[0] || {});
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    const [email, setEmail] = React.useState("");
    const [onVerifying, setOnVerifying] = React.useState(false);
    const [otp, setOtp] = React.useState("");
    const [isEmailVerified, setIsEmailVerified] = React.useState(false);

    const [name, setName] = React.useState("");
    const [service, setService] = React.useState('');

    const [title, setTitle] = React.useState("");
    const [introduction, setIntroduction] = React.useState("");
    const [targetGender, setTargetGender] = React.useState('');
    const [targetAge, setTargetAge] = React.useState('');

    const [testRequests, setTestRequests] = React.useState({
        '기본 통계 분석(연독률, 완독률)': true,
        '스토리 피드백': false,
        '그림 피드백': false
    });
    
    const [challengeAddress, setChallengeAddress] = React.useState("");
    const [isChallengeWebtoon, setIsChallengeWebtoon] = React.useState(false);
    const [challengeData, setChallengeData] = React.useState({author: ''});
    
    const [manuscriptOrChallenge, setManuscriptOrChallenge] = React.useState("none");

    const [disabled, setDisabled] = React.useState(false);
    const [submitted, setSubmmited] = React.useState(false);
    const [popupOpen, setPopupOpen] = React.useState(false);
    const [errorPopupOpen, setErrorPopupOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const [firstManuscript, setFirstManuscript] = React.useState([null,null])
    const [secondManuscript, setSecondManuscript] = React.useState([null,null])
    const [thirdManuscript, setThirdManuscript] = React.useState([null,null])
    
    const [isAppendixOpen, setIsAppendixOpen] = React.useState(false);

    const [referral, setReferral] = React.useState("");
    const [hasExperience, setHasExperience] = React.useState(false);

    const [advertisementCuts, setAdvertisementCuts] = React.useState([]);
    const [requestCreatives, setRequestCreatives] = React.useState(true);

    const [isImagesLoading, setIsImagesLoading] = React.useState(false);
    const [isExampleAdsCutOpen, setIsExampleAdsCutOpen] = React.useState(false);

    const [isExtraInfomations, setIsExtraInfomationsLoading] = React.useState(false);
    const [extraInfomations, setExtraInfomations] = React.useState([]);

    const [isLoadingFirstManuscript, setIsLoadingFirstManuscript] = React.useState(false);
    const [isLoadingSecondManuscript, setIsLoadingSecondManuscript] = React.useState(false);
    const [isLoadingThirdManuscript, setIsLoadingThirdManuscript] = React.useState(false);
    const [isAgreeDisclosure, setIsAgreeDisclosure] = React.useState(false);

    const currentTermsofuseVersion = "/termsofuse/termsofuse/analytics/20220202/kr";
    
    const consentList = [
        "독자 반응 수집을 위한 SNS 퍼포먼스 마케팅(광고 게재)에 동의합니다.",
        "접수된 작품의 저작권이 본인의 소유임을 확인합니다.",
        "데이터 분석 결과 및 피드백의 저작권은 오늘의웹툰에 있음을 확인합니다."
    ];

    const analysisUseConsent = "오늘의웹툰 서비스를 위한 데이터 분석 보고서 활용에 동의합니다."

    React.useEffect(()=>{
        if(advertisementCuts.length > 0){
            setRequestCreatives(false);
        }
    },[advertisementCuts])

    const formSubmit = async () => {
        setDisabled(true)
        
        const formJson = {
            name, title, email, otp, service, 
            manuscripts: [firstManuscript, secondManuscript, thirdManuscript], 
            challengeUrl: email.startsWith(challengeData.author.replace(/\*/g,'') || "@#")?challengeData.url:"", 
            targetReader: [targetGender, targetAge].filter(word => word).join(), introduction,
            advertisementCuts,
            requestCreatives,
            testRequests,
            application: {slide: extraInfomations.map(row => [row.image, row.name])},
            referral, hasExperience,
            consentList: [
                ...consentList,
                ...((isAgreeDisclosure || service === 'competition')?[analysisUseConsent]:[]),
            ],
            currentTermsofuseVersion
        }
        logBehavior('input-submitted',{})
        const result = await sendApplication(formJson)
        
        if (result) {
            setPopupOpen(true);
        } else{
            setErrorMessage("데이터 전송이 실패했습니다")
            setErrorPopupOpen(true);
        }

        setDisabled(false)
    };
    const popupClose = () => {
        
        setSubmmited(true)
        setPopupOpen(false);

    };

    const handleSharing = async () => {

        try{
            await navigator.share({
                    title: '링크를 저장해두고, 준비되는 대로 다시 만나요!',
                    url: 'http://webtoon.today/landing#Submit'
                })
        } catch (error) {
            if (!error.includes("AbortError")){
                alert(`Error: ${error}`);
            } 
        };
    };

    const testRequestsHandler = (e) => {
        
        const newTestRequests = {...testRequests}
        if (e.target.checked) {
            newTestRequests[e.target.value] = true
            setTestRequests(newTestRequests)
        } else {
            newTestRequests[e.target.value] = false
            setTestRequests(newTestRequests)
        }
    }

    return (
        <div ref={ref}>
            <div className={"ApplicationTemplate"} id={"Submit"}>
                <div className={"TemplateFooter"}>
                
                    <div className={"EasyToGet"}>
                        <div className={"Title"}>
                            {"데이터 분석 신청하기"}
                        </div>
                        <div className={"TextBox"}>
                            <div>{"버튼 한번으로 예상 독자 반응을 받아보세요."}</div>
                            <div>
                                {'공모전에서 '}
                                <span className={"WeightedText"}>{'연독률 75%'}</span>
                                {'를 넘길 시 '}
                                <span className={"WeightedText"}>{'창작지원금'}</span>
                                {'이 함께 지급됩니다.'}
                            </div>
                        </div>
                    </div>
                    <div className={"SubmitArea"} onClick={()=>{
                        fn.goto('https://challenge.webtoon.today/application/analysis')}
                    }>
                        <div className={"InfomationForm"}>
                            <div className={`FormUl`} >
                                <div className={`Formli Half`}>
                                    <label className={"Labels"}>{"이름 or 필명 "}
                                        <span className={"redAsterisk"}>{"*"}</span>
                                    </label>
                                    <TextField
                                        onBlur={()=>{
                                            if ((name !== "")){
                                                logBehavior('input-name',{})
                                            }
                                        }}
                                        className={`Tags`}
                                        disabled={submitted}
                                        type="text"
                                        value={name}
                                        size={"small"}
                                        variant={"outlined"}
                                        InputProps={{
                                            style: {
                                                borderRadius: 4,
                                            }
                                        }}
                                        onChange={(event) => setName(event.target.value)}
                                    />
                                </div>
                                <div className={`Formli Half`} style={{marginLeft: '2%'}}>
                                    <label className={"Labels"}>{"제목"}
                                        <span className={"redAsterisk"}>{" *"}</span>
                                    </label>
                                    <TextField
                                        onBlur={()=>{
                                            if ((title !== "")){
                                                logBehavior('input-title',{})
                                            }
                                        }}
                                        className={`Tags`}
                                        disabled={submitted}
                                        type="text"
                                        value={title}
                                        size={"small"}
                                        variant={"outlined"}
                                        InputProps={{
                                            style: {
                                                borderRadius: 4,
                                            }
                                        }}
                                        onChange={(event) => setTitle(event.target.value)}
                                    />
                                </div>
                                <div className={"Formli Half"}>
                                    <label className={"Labels"}>{"이메일 "}
                                        <span className={"redAsterisk"}>{"*"}</span>
                                    </label>
                                    <div className={"EmailVerifying"}>
                                        <TextField 
                                        onBlur={()=>{
                                            if ((email !== "")){
                                                logBehavior('input-email',{})
                                            }
                                        }}
                                            disabled={onVerifying || isEmailVerified || submitted} 
                                            className={"Tags"} 
                                            value={email}
                                            variant={"outlined"}
                                            size={"small"}
                                            InputProps={{
                                                style: {
                                                    borderRadius: '4px 0 0 4px',
                                                }
                                            }}
                                            onChange={(event) => setEmail(event.target.value)}/>
                                        <Button variant={"contained"} color={"primary"} disableElevation onClick={ async () => {
                                                setDisabled(true)
                                                const result = await verifyEmail(email)
                                                if (result) {
                                                    logBehavior('input-onVerifying',{})
                                                    setOnVerifying(true);
                                                }
                                                setDisabled(false)
                                            }}
                                            className={`VerifyingButton`}
                                            disabled={onVerifying || isEmailVerified || !validateEmailForm(email)}
                                        >
                                            {"메일인증"}
                                        </Button>
                                    </div>
                                    <label className={"EmailReference"}>{"* 도전만화 링크 제출 시 네이버 메일을 입력해주세요."} </label>
                                </div>
                                <div className={'Formli Half'} style={{marginLeft: '2%'}}>
                                    <label className={"Labels"}>{"서비스 유형 선택 "}
                                        <span className={"redAsterisk"}>{"*"}</span>
                                    </label>
                                    <Select
                                        className={'ServiceSelector'}
                                        variant={'outlined'}
                                        fullWidth
                                        disabled={submitted}
                                        value={service}
                                        onChange={ (e) => {
                                            setService(e.target.value)
                                            logBehavior('input-type',{type: e.target.value})
                                        }}
                                        inputProps={{   
                                            MenuProps: {
                                                disableScrollLock: true
                                            }
                                        }}
                                    >
                                        <MenuItem value={'competition'}>{'분석 서비스 + 창작지원금 응모'}</MenuItem>
                                        <MenuItem value={'feedback'}>{'분석 서비스 + 디렉터 피드백'}</MenuItem>
                                    </Select>
                                </div>

                                {onVerifying
                                ?<div className={"Formli"}>
                                    <label className={"Labels"}>{"인증키"}
                                        <span className={"redAsterisk"}>{"*"}</span>
                                    </label>
                                    <div className={"VerifiedOtp"}>
                                        <TextField
                                            onBlur={()=>{
                                                if ((otp !== "")){
                                                    logBehavior('input-otp',{})
                                                }
                                            }}
                                            className={"Tags"} 
                                            value={otp}
                                            size={"small"}
                                            variant={"outlined"}
                                            InputProps={{
                                                style: {
                                                    borderRadius: '4px 0 0 4px',
                                                }
                                            }}
                                            onChange={(event) => setOtp(event.target.value)}/>
                                        <Button variant={"contained"} color={"primary"} disableElevation onClick={async () => {
                                            setDisabled(true);
                                            const result = await verifyOtp(email, otp)
                                            if (result) {
                                                setIsEmailVerified(true);
                                                logBehavior('input-emailVerified',{})
                                                setOnVerifying(false);
                                            }
                                            setDisabled(false);
                                        }}
                                            className={"VerifiedOtpButton"}
                                        >
                                            {"인증하기"}
                                        </Button>
                                    </div>
                                </div>
                                :isEmailVerified
                                    ? <div className={"Formli"} style={{fontSize: '0.9rem', color: 'rgba(0,176,240,1)'}}>
                                        {"* 이메일이 인증되었습니다."}
                                    </div>
                                    :<></>}
                            </div>
                            <div>
                                <label className={"Labels"}>{"원고 "}
                                    <span className={"redAsterisk"}>{"*"}</span>
                                </label>
                                <label className={"References"}>{"* 원고 파일 또는 도전만화 링크를 올려주세요.(택1)"}</label>
                                <RadioGroup className={"RadioGroup"} aria-label="gender" name="gender1" value={manuscriptOrChallenge} onChange={(event) => setManuscriptOrChallenge(event.target.value)}>
                                    <FormControlLabel value="manuscript" control={<Radio color={"primary"} disabled={submitted || isLoadingFirstManuscript || isLoadingSecondManuscript || isLoadingThirdManuscript} />} label={"파일"} />
                                    {manuscriptOrChallenge === "manuscript"?
                                        <>
                                            <label className={"References"}>{"* 연독률 파악을 위해 원고 3화 업로드를 권장 드려요.(zip 파일)"} </label>
                                            <div className={"Manuscripts"}>
                                                <div className={"ManuscriptsFlex"}>
                                                    <div className={`ScriptPokets 1화`} >
                                                        <SubmitFileField
                                                            key={`SubmitField0`}
                                                            className={`SlideThumb`}
                                                            defaultVluae={firstManuscript[0]}
                                                            update={ async(newValue, orgFilename)=>{
                                                                await setFirstManuscript([Object.values(newValue)[0], orgFilename])
                                                                if(newValue){
                                                                    logBehavior('input-firstManuscript',{})
                                                                }
                                                            }}
                                                            isEditing={true}
                                                            accept={".zip"}
                                                            disabled={submitted || isLoadingFirstManuscript}
                                                            innertext={`${isLoadingFirstManuscript?'로딩중':'1화*'}`}
                                                            altertext={`${firstManuscript[1] || ""}`}
                                                            loading={async(boolean)=>setIsLoadingFirstManuscript(boolean)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={"ManuscriptsFlex"}>
                                                    <div className={`ScriptPokets 2화`} >
                                                        <SubmitFileField
                                                            key={`SubmitField1`}
                                                            className={`SlideThumb`}
                                                            defaultVluae={secondManuscript[0]}
                                                            update={ async(newValue, orgFilename)=>{
                                                                await setSecondManuscript([Object.values(newValue)[0], orgFilename])
                                                                if(newValue){
                                                                    logBehavior('input-secondManuscript',{})
                                                                }
                                                            }}
                                                            isEditing={true}
                                                            accept={".zip"}
                                                            disabled={submitted || isLoadingSecondManuscript}
                                                            innertext={`${isLoadingSecondManuscript?'로딩중':'2화*'}`}
                                                            altertext={`${secondManuscript[1] || ""}`}
                                                            loading={(boolean)=>setIsLoadingSecondManuscript(boolean)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={"ManuscriptsFlex"}>
                                                    <div className={`ScriptPokets 3화`} >
                                                        <SubmitFileField
                                                            key={`SubmitField2`}
                                                            className={`SlideThumb`}
                                                            defaultVluae={thirdManuscript[0]}
                                                            update={ async(newValue, orgFilename)=>{
                                                                await setThirdManuscript([Object.values(newValue)[0], orgFilename])
                                                                if(newValue){
                                                                    logBehavior('input-thirdManuscript',{});
                                                                }
                                                            }}
                                                            isEditing={true}
                                                            accept={".zip"}
                                                            disabled={submitted || isLoadingThirdManuscript}
                                                            innertext={`${isLoadingThirdManuscript?'로딩중':'3화*'}`}
                                                            altertext={`${thirdManuscript[1] || ""}`}
                                                            loading={(boolean)=>setIsLoadingThirdManuscript(boolean)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    :<></>
                                    }
                                    <FormControlLabel className={"ChallengeLabel"} value="challenge" control={<Radio color={"primary"} disabled={submitted || isLoadingFirstManuscript || isLoadingSecondManuscript || isLoadingThirdManuscript} />} label={"도전만화"} />
                                        {manuscriptOrChallenge === "challenge"?
                                            <div className={"ChallengeWebtoonArea"}>
                                                <TextField
                                                    onBlur={()=>{
                                                        if ((challengeAddress !== "")){
                                                            logBehavior('input-challengeAddress',{})
                                                        }
                                                    }}
                                                    className={"Input ChallengeAddressField"} 
                                                    label={"도전만화 링크"} 
                                                    variant={"outlined"} 
                                                    value={challengeAddress}
                                                    onChange={(event)=>{setChallengeAddress(event.target.value)}}
                                                    disabled={isChallengeWebtoon}
                                                    size={"small"}
                                                    InputLabelProps={{
                                                        style: { color: '#b0b0b0' },
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: '4px 0 0 4px',
                                                        }
                                                    }}
                                                />
                                                <Button 
                                                    className={"ColoredButton Blue"} 
                                                    color={"primary"}
                                                    variant={"contained"}
                                                    disabled={isChallengeWebtoon}
                                                    onClick={async()=>{
                                                        setDisabled(true);
                                                        const challengeData = await readChallengeData(challengeAddress)
                                                        setChallengeData(challengeData || {author:''})
                                                        
                                                        if (!challengeData.title || !challengeData){
                                                            alert("요청이 실패하였습니다. 도전만화 링크를 확인해주세요.")
                                                        } else if (challengeData.title && (challengeData !== {}) && (challengeData.author !== "")){
                                                            setIsChallengeWebtoon(true);
                                                            logBehavior('input-isChallengeWebtoon',{})
                                                            if(email.startsWith(challengeData.author.replace(/\*/g,'') || "@%")){
                                                                logBehavior('input-challengeCorrespond',{})
                                                            }
                                                        } else if (challengeData && (challengeData.title === "네이버 웹툰" || challengeData.author === "")){
                                                            alert("작품을 가져오는데 실패하였습니다. 작품 링크를 다시 한번 확인해주세요.")
                                                        }

                                                        setChallengeAddress("");
                                                        setDisabled(false);
                                                    }}
                                                >
                                                    {"조회하기"}
                                                </Button>
                                            </div>
                                        :<></>
                                        }
                                </RadioGroup>
                            </div>
                            
                            <div className={"ChallengeInfo"}>
                                {isChallengeWebtoon?
                                    <Paper variant={"outlined"} className={"ExtractInformation"}>
                                        <div className={"OutText"}>{"자동추출정보"}</div>
                                        <div className={"ImgArea"}>
                                            <img
                                                className={"Thumbnail"} 
                                                src={challengeData.image}
                                                alt={challengeData.title}
                                            />             
                                        </div>
                                        <div className={"WebtoonInfo"}>
                                            <div className={"Title"}>
                                                {challengeData.title}
                                            </div>
                                            <div className={"Description"}>
                                                {challengeData.description.split('<br/>').map( (row,i) => <div key={i}>{row}</div>)}
                                            </div>
                                        </div>
                                        {isChallengeWebtoon && email.startsWith(challengeData.author.replace(/\*/g,'') || "@#")
                                        ?isEmailVerified
                                            ?<div className={"CorrespondText"}>{"* 이메일과 도전만화의 아이디가 일치합니다."}</div>
                                            :<div className={"CorrespondText"}>{"* 이메일을 인증해주세요."}</div>
                                        :<div className={"CorrespondText"}>{"* 이메일과 도전만화의 아이디가 일치하지 않습니다."}</div>}
                                    </Paper>
                                    :<></>
                                }
                            </div>
                            <div className={'AdsCutUl'}>
                                <div className={'AdsCutli'}>
                                    <label className={'Labels'}>{'홍보컷(600 x 600) '}
                                        <span className={'redAsterisk'}>{"*  "}</span>
                                        <span>
                                            <IconButton color={"primary"} style={{fontSize:'0.8rem', padding:3, width:20, height:20, color:'#9AC9FA', border:'1px #00b0f0 solid'}}
                                                onClick={() => setIsExampleAdsCutOpen(true)}
                                            >
                                                {"?"}
                                            </IconButton>
                                        </span>
                                    </label>
                                    <DragAndDrop
                                        disabled={submitted}
                                        isEditable={true}
                                        file={advertisementCuts} 
                                        setFile={(newData)=>setAdvertisementCuts(newData)} 
                                        setIsImagesLoading={(boolean)=>{setIsImagesLoading(boolean)}}
                                        placeholder={`내가 독자라면 이 만화는 더 보고 싶다는 생각이 드는 홍보컷을 만들어주세요.
                                        직접 제작하는 만큼 내 작품의 장점이 극대화 됩니다.
                                        (단, 작성해 주신 홍보컷의 광고 효과가 낮다고 판단될 경우 홍보컷이 일부 수정될 수 있습니다)`}
                                        maximum={10}
                                    />
                                    <div className={'AdsCutRequest'} >
                                        <Checkbox className={'AdsCutRequestCheckbox'} checked={requestCreatives} value={requestCreatives} disabled={submitted || advertisementCuts.length > 0} size={'small'} color={'primary'}
                                            onChange={ (event) => setRequestCreatives(!requestCreatives)}
                                        />
                                        {'홍보컷 자동설정을 희망합니다.'}
                                    </div>
                                </div>
                            </div>

                            {service === 'feedback'?<div className={'TitleUl'}>
                                <div className={'Titleli'}>
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <label className={'Labels'}>{'분석 서비스 선택 '}
                                            <span className={'redAsterisk'}>{"*"}</span>
                                        </label>
                                        <div 
                                            className={'ExampleReport'} 
                                            href={"https://webtoon.today/report/today/the-law-of-the-jungle/1/94"}
                                            onClick={fn.gotoByAnchor}
                                        >
                                            {'데이터 분석 보고서의 실제 모습은?'}
                                        </div>
                                    </div>
                                    
                                    <ul className={'TestRequests'} style={{display: 'flex'}}>
                                        {Object.keys(testRequests).map((request, index)=>(
                                        <li className={'RequestBox'} key={request}>
                                            {index === 0?
                                            <Tooltip title={"필수 선택사항입니다."}><CheckBox style={{color:"rgba(0,176,240)", fontSize:'1.2rem', marginRight:'0.2rem'}}/></Tooltip>
                                            :<Checkbox className={'Checker'} color={'primary'} disabled={submitted} value={request} size={'small'} onChange={testRequestsHandler} />
                                            }
                                            {request}
                                        </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            :<div style={{height: '80px'}}></div>}

                            <label className={"Labels"} style={{lineHeight: '23px', marginLeft: -5}} onClick={()=>setIsAppendixOpen(!isAppendixOpen)}>
                                <div className={`ArrowButton  ${isAppendixOpen?"Open":"Closed"}`}><ArrowRight/></div>
                                {"추가자료(선택)"}
                            </label>
                            <div className={`Appendix ${isAppendixOpen?"Open":"Closed"}`}>
                                <div className={`FormUl`} >
                                    <div className={`TitleExtraInfomation`}>
                                        <label className={'Labels'}>{'타겟 독자'}</label>
                                        <div className={'TargetSelectArea'}>
                                            <FormControl>
                                                <InputLabel id={"selectGender"}>{'성별'}</InputLabel>
                                                <Select
                                                    id={"selectGender"}
                                                    disabled={submitted}
                                                    className={'TargetGender'}
                                                    value={targetGender}
                                                    onChange={ (e) => setTargetGender(e.target.value) }
                                                >
                                                    <MenuItem value={'남성'}>{'남성'}</MenuItem>
                                                    <MenuItem value={'여성'}>{'여성'}</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <InputLabel id={"selectAge"}>{'나이'}</InputLabel>
                                                <Select 
                                                    id={"selectAge"}
                                                    disabled={submitted}
                                                    className={'TargetAge'}
                                                    value={targetAge}
                                                    onChange={ (e) => setTargetAge(e.target.value) }
                                                >
                                                    {['18~24','25~34','35~44','45~54','55~64','65+'].map(age=>
                                                        <MenuItem value={age} key={age}>{age}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className={`Formli`}>
                                        <label className={"Labels"}>{"작품 소개글"}
                                        </label>
                                        <TextField  type={'text'} fullWidth rows={2} multiline
                                            value={introduction}
                                            variant={'outlined'}
                                            onChange={(e) => setIntroduction(e.target.value)}
                                            disabled={submitted}
                                        />
                                    </div>
                                </div>
                                <div className={`FormUl`} >
                                    <div className={`Formli`}>
                                        <label className={"Labels"}>{"기타 소개자료"}
                                        </label>
                                        <DragAndDrop
                                            disabled={submitted}
                                            isEditable={true}
                                            file={extraInfomations} 
                                            setFile={(newData) => setExtraInfomations(newData)} 
                                            setIsImagesLoading={(boolean)=>{setIsExtraInfomationsLoading(boolean)}}
                                            placeholder={`기타 소개자료가 있다면 첨부해 주세요.`}
                                            maximum={10}
                                        />
                                    </div>
                                    <div className={`Formli`}>
                                        <label className={"Labels"}>{"추천인 (이메일, 필명 등)"}</label>
                                        <TextField
                                            onBlur={()=>{
                                                if ((referral !== "")){
                                                    logBehavior('input-referral',{})
                                                }
                                            }}
                                            className={`Tags`}
                                            disabled={submitted}
                                            type="text"
                                            value={referral}
                                            size={"small"}
                                            variant={"outlined"}
                                            InputProps={{
                                                style: {
                                                    borderRadius: 4,
                                                }
                                            }}
                                            onChange={(event) => setReferral(event.target.value)}
                                        />
                                    </div>
                                    <div className={`HasExperienceRow`} style={{fontSize: '0.9rem'}}>
                                        <Checkbox 
                                            className={'HasExperienceCheckbox'} 
                                            checked={hasExperience} size={'small'} 
                                            onClick={ () => setHasExperience(!hasExperience) } 
                                            disabled={submitted} 
                                            color={'primary'}
                                        />
                                        {"연재 경력이 있습니다."}
                                    </div>
                                </div>
                            </div>
                            <div className={"AgreementsArea"}>
                                <div className={"ClickArea"}>
                                    <div className={"ConsentArea"}>
                                        <div className={`CheckRow`}>
                                            <Tooltip title={"필수 선택사항입니다."}>
                                                <CheckBox style={{color:"rgba(0,176,240)", fontSize:'1.2rem', marginRight:'0.2rem'}}/>
                                            </Tooltip>
                                            <div><a href={currentTermsofuseVersion} target={"_blank"} rel={"noreferrer"} style={{textDecoration: 'none', color: 'rgb(0,176,240)'}}>{"서비스 제공 약관"}</a>{'에 동의합니다.'}</div>
                                        </div>
                                        {consentList.map( consent => (
                                        <div className={`CheckRow`} 
                                            key={consent}>
                                            <Tooltip title={"필수 선택사항입니다."}>
                                                <CheckBox style={{color:"rgba(0,176,240)", fontSize:'1.2rem', marginRight:'0.2rem'}}/>
                                            </Tooltip>
                                            {consent}
                                        </div>
                                        ))}
                                        <div className={`CheckRow`}>
                                            <Tooltip title={`${service === 'competition'?'필수 선택사항입니다.':''}`}>
                                                {service === 'competition'
                                                ?<CheckBox style={{color:"rgba(0,176,240)", fontSize:'1.2rem', marginRight:'0.2rem', width: '19.2px', height: '19.2px'}}/>
                                                :<Checkbox disabled={submitted} size={'small'} color={'primary'} 
                                                    onChange={ () => setIsAgreeDisclosure(!isAgreeDisclosure)} 
                                                    style={{padding: 0, fontSize:'1.2rem', marginRight:'0.2rem', width: '19.2px', height: '19.2px'}}/>
                                                }
                                            </Tooltip>
                                            {`${analysisUseConsent} ${service === 'competition'?'':'(선택)'}`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`PopupArea`}>
                                <Button
                                    variant={"contained"} disableElevation color={"primary"}
                                    type={"submit"} 
                                    className={`SubmitButton`}
                                    onClick={ async () => formSubmit()}
                                    disabled={
                                        ((!firstManuscript[0]) 
                                        && (!challengeData.author
                                            || !email.startsWith(challengeData.author.replace(/\*/g,'') || "@#"))
                                        )
                                        || submitted
                                        || !title
                                        || !name
                                        || !isEmailVerified
                                        || (service !== 'feedback' && service !== 'competition')
                                        || isImagesLoading
                                        || isExtraInfomations
                                        || (advertisementCuts.length === 0 && !requestCreatives)
                                        || (isLoadingFirstManuscript || isLoadingSecondManuscript || isLoadingThirdManuscript)
                                    }
                                >
                                    {"동의 후 신청하기"}
                                </Button>
                                <Dialog className={"Dialog"} open={popupOpen}>
                                    <DialogTitle>
                                        {"신청이 정상적으로 완료되었습니다"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <div>
                                            <div>{"데이터 분석 결과는 7일내 메일로 보내드립니다."}</div>
                                            <div>{"빠른 시일 내에 예상 독자 반응과 함께 찾아뵙겠습니다."}</div>
                                            <div>{"떨리는 마음으로 조금만 기다려주세요."}</div>
                                        </div>  
                                    </DialogContent>
                                    <DialogActions>
                                        <Button variant={"contained"} disableElevation className={"PopupButton"}
                                            onClick={popupClose} color={"primary"} >
                                            {"확인"}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog className={"FailDialog"} open={errorPopupOpen}>
                                    <DialogTitle>
                                        {"요청이 실패했습니다."}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            {errorMessage}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button variant={"contained"} disableElevation className={"PopupButton"}
                                            onClick={()=>{
                                            setErrorPopupOpen(false)
                                        }} color={"primary"} >
                                            {"확인"}
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                                <Dialog className={"Dialog"} open={isExampleAdsCutOpen} onClose={() => setIsExampleAdsCutOpen(false)}>
                                    <DialogTitle style={{paddingBottom: '0px', paddingTop: '23px'}}>
                                        {"홍보컷 예시"}
                                        <div style={{fontSize: '0.8rem', whiteSpace: 'pre-line', marginTop: '5px'}}>{`예상 독자에게 SNS 광고를 통해 보여질 이미지입니다.(최대 10장)
                                        더 좋은 홍보컷은 더 적절한 독자를 유입시켜 성적을 높이는데 도움이 됩니다. 
                                        아래는 예시보고서에 사용된 홍보컷입니다.`}</div>
                                    </DialogTitle>
                                    <DialogContent>
                                        <AdsSlides title={exampleTitle} />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button variant={"contained"} disableElevation className={"PopupButton"}
                                            onClick={()=>setIsExampleAdsCutOpen(false)} color={"primary"} >
                                            {"확인"}
                                        </Button>
                                    </DialogActions>
                                </Dialog>

                            </div>
                        </div>
                        <div className={"ClickableArea"} style={{
                            position: 'absolute',
                            top:0, left:0, width: '100%', height: '100%',
                            display: 'flex', justifyContent: 'center', alignItems: 'center',
                            color: 'white', fontSize: '2rem',
                        }}>
                            <div style={{textAlign:'center'}}>{"데이터 분석 신청이 더 편리해졌습니다. 새로운 접수 페이지를 이용해주세요. (클릭)"}</div>
                        </div>

                    </div>
                    {navigator.share?<>
                        <div className={"DialogArea"} onClick={handleSharing}>
                            <ErrorIcon color={"primary"} className={"Exclamation"} />
                            <div className={"DialogText"}>{"앗. 지금은 원고가 없어요"}</div>

                        </div>
                    </>
                    :<></>
                    }

                </div>
                <LoadingCircle show={disabled} />
            </div>
        </div>
    );
}

export default Submit;