import { Button, Paper, Tab, Tabs, TextField, Tooltip, Dialog, DialogContent, DialogActions, IconButton } from '@material-ui/core';
import React from 'react';
import moment from 'moment-timezone';

import EditableFileField from '../Component/Table/EditableFileField';
import Feedback from '../Component/Feedback';
import Header from '../Component/Header';

import { readFunnelStat } from '../Data/FunnelStat';
import { clearTitle, getTitle } from '../Data/Title';
import { listReviews, } from '../Data/Review';
import ss from '../Data/Session';
import { listMyOwnTitles } from '../Data/Ownership';
import { listTitleRank, updateTitleRank } from '../Data/TitleRank';

import './Report.scss';
import { Doughnut, Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import TabPanel from './Analytics/ChannelAnalytics/TabPanel';
import TextBox from './Analytics/ChannelAnalytics/TextBox';
import Contents from './Analytics/Contents';
import { fn, getOffsetInScreen } from '../Functions';
import AdsSlides from './AdsSlides';
import { useDispatch } from 'react-redux';

const Report = ({serviceId, titleId, episodeId, snapshotId}) => {

    const dispatch = useDispatch();
    /** 
     * @summary contents information
    */
    let [title, setTitle] = React.useState({});
    React.useEffect(()=>{
        (async()=>{
            let newTitle = await getTitle(serviceId, titleId, dispatch);
            setTitle(newTitle.concat([{}])[0]);
            document.title = `[분석] ${(newTitle[0] || {}).title || ""} - ${episodeId} - ${snapshotId || "*"}`;
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serviceId, titleId]);
    /** 
     * @summary statistics information
    */
    let [duration, ] = React.useState(30);
    let [funnelStats, setFunnelStats] = React.useState([]);
    React.useEffect(()=>{
        (async()=>{
            try{
                let newFunnelStat = await readFunnelStat({serviceId, titleId, episodeId, snapshotId});
                
                setFunnelStats(newFunnelStat);
            }catch(e){
                if (e.response.data.code === 403 && !ss.getCurrentSession().email){
                    await clearTitle(serviceId, titleId, dispatch);
                    fn.goto(`/settings?redirectTo=/report/${serviceId}/${titleId}/${episodeId}/${snapshotId || ""}`)
                    return;
                }
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serviceId, titleId, episodeId, snapshotId])

    const [isManager, setIsManager] = React.useState(false);
    React.useEffect(()=>{
        (async()=>{
            setIsManager(await ss.isManager());
        })();
    },[])

    /** 
     * @summary chart renderable data information
    */
    const [labels, setLabels] = React.useState([]);
    React.useEffect(()=>{

        let keys = funnelStats.impressionAggregation?Object.keys(funnelStats.impressionAggregation).sort():[];
        if (keys.length > 0){
            let startAt = moment(keys[0]).subtract(1, "days");
            setLabels(Array(duration).fill(0).map((o,i) => moment(startAt).add(i, "days").format("YYYY-MM-DD")));
        }else{
            setLabels(Array(duration).fill(0).map((o,i) => moment().subtract(i, "days").format("YYYY-MM-DD")).reverse());
        }
    },[funnelStats, duration])

    const [viewData, setViewData] = React.useState({});
    const [maxViewY, setMaxViewY] = React.useState(0);
    React.useEffect(() => {
        setViewData({
            labels,
            datasets: [
                {
                    label: 'reads',
                    data: labels.map(date => ((funnelStats.contentReadAggregation || {})[date] || {}).readCount || 0),
                    lineTension: 0,
                    borderWidth: 1.5,
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    
                },
                {
                    label: 'full reads',
                    data: labels.map(date => ((funnelStats.contentReadAggregation || {})[date] || {}).fullReadCount || 0),
                    lineTension: 0,
                    borderWidth: 1.5,
                    borderColor: 'rgba(62,62,62,1)',
                    borderCapStyle: 'butt',
                    
                }
            ]
        })
        setMaxViewY(Math.ceil(labels.map(date => ((funnelStats.contentReadAggregation || {})[date] || {}).readCount || 0).reduce( (a,b) => a>b?a:b, 0)*1.1))
    }, [labels, funnelStats.contentReadAggregation]);

    const [engageData, setEngageData] = React.useState({});
    React.useEffect(() => {
        let data = labels.map(date => (((funnelStats.contentReadAggregation || {})[date] || {}).fullReadCount || 0)/(((funnelStats.contentReadAggregation || {})[date] || {}).readCount || 1))
        let average = labels.map(date => ((funnelStats.contentReadAggregation || {})[date] || {}).fullReadCount || 0).reduce((a,b)=> a+b, 0)
                        / labels.map(date => ((funnelStats.contentReadAggregation || {})[date] || {}).readCount || 0).reduce((a,b)=> a+b, 0)

        setEngageData({
            labels,
            datasets: [
                {
                    label: 'full read ratio',
                    data: data.map(point => point || null),
                    lineTension: 0,
                    borderWidth: 1.5,
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    
                },
                {
                    label: 'average',
                    data: labels.map(date => average),
                    lineTension: 0,
                    borderWidth: 1.5,
                    borderColor: 'rgba(192,75,192,1)',
                    radius: 0.2,
                    
                },
            ]
        })
        setMaxViewY(Math.ceil(labels.map(date => ((funnelStats.contentReadAggregation || {})[date] || {}).readCount || 0).reduce( (a,b) => a>b?a:b, 0)*1.1))
    }, [labels, funnelStats.contentReadAggregation]);

    const [nextClickData, setNextClickData] = React.useState({});
    React.useEffect(()=>{
        setNextClickData({
            labels,
            datasets: [
                {
                    label: 'reads',
                    data: labels.map(date => ((funnelStats.contentReadAggregation || {})[date] || {}).nextClickCount || 0),
                    lineTension: 0,
                    borderWidth: 1.5,
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    
                },
            ]
        })
    },[labels, funnelStats.contentReadAggregation])
    
    const [impressionData, setImpressionData] = React.useState({});
    const [maxImpressionY, setMaxImpressionY] = React.useState(0);
    React.useEffect(() => { 
        setImpressionData({
            labels,
            datasets: [{
                data: labels.map(date => ((funnelStats.impressionAggregation || {})[date] || {}).impressions || 0),
                lineTension: 0.1,
                backgroundColor: [
                    'rgba(75,192,192,0.4)',
                ],
                borderColor: [
                    'rgba(75,192,192,1)',
                ],
            }]
        })
        setMaxImpressionY(Math.ceil(labels.map(date => ((funnelStats.impressionAggregation || {})[date] || {}).impressions || 0).reduce((a,b) => a>b?a:b,0)*1.1))
    }, [labels, funnelStats.impressionAggregation])

    const [CTRData, setCTRData] = React.useState({});
    const [CTRAverage, setCTRAverage] = React.useState(0);
    React.useEffect(() => { 
        
        let average = 
            labels.map(date => ((funnelStats.impressionAggregation || {})[date] || {}).clicks || 0).reduce((a,b) => a+b, 0)
            / (labels.map(date => ((funnelStats.impressionAggregation || {})[date] || {}).impressions || 0).reduce((a,b) => a+b, 0) || 1)
            setCTRAverage(average);
        setCTRData({
            labels,
            datasets: [{
                data: labels.map(date =>  Math.floor((((funnelStats.impressionAggregation || {})[date] || {}).clicks || 0)/(((funnelStats.impressionAggregation || {})[date] || {}).impressions || 1) * 10000) / 100 ).map(point => point > 0?point:null),
                lineTension: 0.1,
                backgroundColor: [
                    'rgba(75,192,192,0.4)',
                ],
                borderColor: [
                    'rgba(75,192,192,1)',
                ],
            },
            {
                label: 'average',
                data: labels.map(date => average * 100),
                lineTension: 0,
                borderWidth: 1.5,
                borderColor: 'rgba(192,75,192,1)',
                radius: 0.2,
                
            },]
        })
    }, [labels, funnelStats.impressionAggregation])
    React.useEffect(()=>{
    },[CTRData])

    const [clickData, setClickData] = React.useState({});
    const [maxClickY, setMaxClickY] = React.useState(0);
    React.useEffect(() => { 
        
        setClickData({
            labels,
            datasets: [{
                data: labels.map(date => ((funnelStats.impressionAggregation || {})[date] || {}).clicks || 0),
                lineTension: 0.1,
                backgroundColor: [
                    'rgba(75,192,192,0.4)',
                ],
                borderColor: [
                    'rgba(75,192,192,1)',
                ],
            }]
        })
        setMaxClickY(Math.ceil(labels.map(date => ((funnelStats.impressionAggregation || {})[date] || {}).clicks || 0).reduce((a,b) => a>b?a:b,0)*1.1))

    }, [labels, funnelStats.impressionAggregation])

    const [totalDurationData, setTotalDurationData] = React.useState({});
    React.useEffect(() => { 
        
        setTotalDurationData({
            labels,
            datasets: [
                {
                    label: 'all reader',
                    data: labels.map(date => (((funnelStats.contentReadAggregation || {})[date] || {}).totalReadTime || 0) / 3600),
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                },
                {
                    label: 'full reader',
                    data: labels.map(date => (((funnelStats.contentReadAggregation || {})[date] || {}).totalFullReadTime || 0) / 3600),
                    lineTension: 0.1,
                    backgroundColor: 'rgba(192,75,192,0.4)',
                    borderColor: 'rgba(192,75,192,1)',
                    borderCapStyle: 'round',
                }
            ]
        })

    }, [labels, funnelStats.contentReadAggregation])

    const [totalAvergaeDurationData, setTotalAverageDurationData] = React.useState({});
    React.useEffect(() => { 
        
        setTotalAverageDurationData({
            labels,
            datasets: [
                {
                    label: 'all reader',
                    data: labels.map(date => 
                            (((funnelStats.contentReadAggregation || {})[date] || {}).totalReadTime || 0)
                            / (((funnelStats.contentReadAggregation || {})[date] || {}).readCount || 1)
                        ).map(point => point >0? point: null),
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                },
                {
                    label: 'full reader',
                    data: labels.map(date => 
                            (((funnelStats.contentReadAggregation || {})[date] || {}).totalFullReadTime || 0)
                            / (((funnelStats.contentReadAggregation || {})[date] || {}).fullReadCount || 1)
                        ).map(point => point >0? point: null),
                    lineTension: 0.1,
                    backgroundColor: 'rgba(192,72,192,0.4)',
                    borderColor: 'rgba(192,75,192,1)',
                    borderCapStyle: 'butt',
                }
            ]
        })

    }, [labels, funnelStats.contentReadAggregation])
    
    const [demographicStat, setDemographicStat] = React.useState({});
    React.useEffect(()=>{
        let newDemographicStat = 
            Object.values(funnelStats.demographicAggregation || {})
                .reduce( (a,b) => a.concat(b), [])
                .map(({gender, age, clicks, impressions}) => {
                    let ret = {};
                    ret[`${gender}:${age}`] = {clicks, impressions}
                    return ret;
                }).reduce((a,b) => {
                    for(let key of Object.keys(b)){
                        if (!(key in a)){
                            a[key] = {
                                clicks: 0, impressions: 0
                            }
                        }
                        a[key].clicks += b[key].clicks
                        a[key].impressions += b[key].impressions
                    }
                    return a
                }, {})
        setDemographicStat(newDemographicStat)
    },[funnelStats])
    
    const [donutData, setDonutData] = React.useState({});
    const [donutOptions, setDonutOptions] = React.useState({});
    React.useEffect(() => { 

        
        setDonutData({
            labels: Object.keys(demographicStat),
            datasets: [{
                label: 'My First Dataset',
                data: Object.values(demographicStat).map(row => row.clicks),
                backgroundColor: Object.keys(demographicStat).map(key =>
                    key.startsWith('female')?`rgba(255, 99, 132,${1/(Number(key.split(':')[1][0]) || 7)})`:
                    key.startsWith('male')?`rgba(54, 162, 235,${1/(Number(key.split(':')[1][0]) || 7)})`:
                    `rgb(100,100,100,${1/(Number(key.split(':')[1][0]) || 7)})`),
            hoverOffset: 4,
            }],
        })
        
        setDonutOptions({
            responsive: true,
            maintainAspectRatio: false,
            plugins:{
                legend: {
                    display: false,
                },
                datalabels: {
                    formatter: (value, context) => {
                        return Object.keys(demographicStat)[Number(context.dataIndex)].replace("female","여").replace("male","남")
                    },
                    display: (context)=>{
                        return context.dataset.data[Number(context.dataIndex)] !== 0
                    }
                }
            },
        })

    }, [demographicStat])

    /**
     * @summary feedback data
     */
    const [feedbackList, setFeedbackList] = React.useState([]);
    const [currentFeedback, setCurrentFeedback] = React.useState({});
    React.useEffect(()=>{
        (async()=>{
            let newFeedbackList = await listReviews({serviceId, titleId, episodeId, snapshotId, includeFeedback: true}, true);
            setFeedbackList(newFeedbackList);

            for(let aFeedback of newFeedbackList){
                if (aFeedback.userid === ss.getCurrentSession().userid){
                    setCurrentFeedback(aFeedback);
                    return;
                }
            }
            setCurrentFeedback({});
        })();
    },[serviceId, titleId, episodeId, snapshotId]);
    
    const [ownershipList, setOwnershipList] = React.useState([]);

    React.useEffect(()=>{
        (async()=>{
            let newOwnershipList = await listMyOwnTitles();
            setOwnershipList(newOwnershipList);
        })();
    },[])

    /** @type {[import('../Data/TitleRank').titleRankType, ()=>{}]} */
    const [titleRank, setTitleRank] = React.useState({serviceId, titleId, episodeId, snapshotId});
    React.useEffect(()=>{
        (async ()=>{
            let newTitleRank = await listTitleRank({serviceId, titleId, episodeId, snapshotId});
            if (newTitleRank.snapshotId){
                setTitleRank(newTitleRank);
            }
        })();
    },[serviceId, titleId, snapshotId, episodeId])
    
    const [errorMessageForCtrRank, setErrorMessageForCtrRank] = React.useState("");
    const [errorMessageForFullReadRank, setErrorMessageForFullReadRank] = React.useState("");
    const [errorMessageForRetentionRank, setErrorMessageForRetentionRank] = React.useState("");

    let maxWidth = Object.values(demographicStat).map(row => row.clicks).reduce((a,b) => a>b?a:b, 0) * 2

    let [viewTab, setViewTab] = React.useState(0);
    let [reachTab, setReachTab] = React.useState(0);
    let [engageTab, setEngageTab] = React.useState(0);

    let [isDeepdiveSeeing, setIsDeepdiveSeeing] = React.useState(false);
    React.useEffect(()=>{
        let scrollAndResizeListener = () =>{
            let box = getOffsetInScreen(document.getElementsByClassName('DeepdiveReport')[0]);
            if (box.top < window.innerHeight * 0.5){
                setIsDeepdiveSeeing(true);
            }else{
                setIsDeepdiveSeeing(false);
            }
        }

        window.addEventListener('resize', scrollAndResizeListener);
        window.addEventListener('scroll', scrollAndResizeListener);

        return ()=>{
            window.removeEventListener('resize', scrollAndResizeListener);
            window.removeEventListener('scroll', scrollAndResizeListener);    
        }
    },[])

    const [isClickRateExplanation, setIsClickRateExplanation] = React.useState(false);
    const [isReadingRateExplanation, setIsReadingRateExplanation] = React.useState(false);
    const [isReadingNextRateExplanation, setIsReadingNextRateExplanation] = React.useState(false);
    const [isAudienceAnalysis, setIsAudienceAnalysis] = React.useState(false);

    return (<div className={"ReportForOthers"}>
        <Header
            pageName={"데이터 분석 리포트"}
            rightButton={
                <Button
                    style={{color:'white'}}
                    href={`mailto:artist@webtoon.today?subject=[분석결과문의]%20${title.title}`}
                    onClick={fn.gotoByAnchor}
                >{"문의"}</Button>}
        />
        <div className={"TitleInfoContainer"}>
            <h2 style={{marginLeft: 20}}>{"작품 기본 정보"}</h2>
            <div className={"TitleInfo"}>
                <div className={"Col WithThumb"}>
                    <h3 style={{paddingLeft: '0.5rem' }}>{"대표 이미지"}</h3>
                    <Paper variant={"outlined"} className={"MainThumbContainer"} style={{backgroundImage: `url('${title.thumbnail}')`}}>
                        <div className={"MainThumbBackdrop"}>
                            <EditableFileField
                                className={"MainThumb"}
                                defaultValue={title.thumbnail}
                                update={(newValue)=>{}}
                                onLoad={()=>{}}
                                field={"thumbnail"}
                                isEditing={false}
                            />
                        </div>
                    </Paper>
                        <h3 style={{paddingLeft: '0.5rem' }}>{"제목, 소개글"}</h3>
                        <TextField
                            className={"TextField Title"} fullWidth variant={"outlined"}
                            label={"작품 제목"}
                            value={title.title || ''}
                            onChange={(event)=>{}}
                        />
                        <TextField
                            className={"TextField Description"} fullWidth variant={"outlined"}
                            label={"작품 설명"}
                            multiline minRows={4}
                            value={title.description || ''}
                            onChange={(event)=>{}}
                        />
                        <TextField
                            className={"TextField Title"} fullWidth variant={"outlined"}
                            label={"태그"}
                            value={title.tags || ''}
                            onChange={(event)=>{
                                setTitle({...title, tags: event.target.value.replace(/ /g,'')})
                            }}
                        />
                </div>
                <div className={"Col WithPreview"}>
                    <h3 style={{paddingLeft: '0.5rem' }}>{"광고 이미지"}</h3>
                    <AdsSlides title={title} />
                </div>
            </div>
        </div>
        {!titleRank.ctr_rank && !isManager
        ?<></>
            :!isManager
            ?<>
                <div className={"RankContainer"}>
                    <h2 style={{marginLeft: 20}}>{"상대평가 정보"}</h2>
                    <div className={"Rank"}>
                        <div className={"Col"}>
                            <div className={"Number"}>{`상위 ${titleRank.ctr_rank}%`}
                            </div>
                            <div className={"HelpText"}>{"클릭률"}
                                <span
                                    className={"QuestionModal"}
                                    onClick={()=>setIsClickRateExplanation(true)}
                                >
                                    <IconButton className={"QuestionButton"} color="primary" >{"?"}</IconButton>
                                </span>
                                <Dialog className={"Dialog"} open={isClickRateExplanation}>
                                    <DialogContent>
                                        {`CTR ${Math.floor(CTRAverage*10000)/100}% (상위 ${titleRank.ctr_rank}%)`}
                                        <ul>
                                            <li>{"CTR은 광고를 본 사람 중 클릭하여 들어온 사람의 비율이며, 이 값을 통해 작품의 대중적인 선호도를 확인할 수 있습니다."}</li>
                                            <li>{"이 값은 평균적으로 1~3%이며, 높을수록 좋습니다."}</li>
                                        </ul>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button variant={"contained"} disableElevation
                                            onClick={()=>setIsClickRateExplanation(false)} color={"primary"} >
                                            {"확인"}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </div>
                        <div className={"Col"}>
                            <div className={"Number"}>{`상위 ${titleRank.fullread_rank}%`}</div>
                            <div className={"HelpText"}>{"완독률"}
                                <span
                                    className={"QuestionModal"}
                                    onClick={()=>setIsReadingRateExplanation(true)}
                                >
                                    <IconButton className={"QuestionButton"} color="primary" >{"?"}</IconButton>
                                </span>
                                <Dialog className={"Dialog"} open={isReadingRateExplanation}>
                                    <DialogContent>
                                        {`완독률 ${Math.floor((((viewData.datasets || [{},{}])[1] || {}).data || []).reduce((a,b) => a+b, 0) / ((((viewData.datasets || [{}, {}])[0] || {}).data || []).reduce((a,b) => a+b, 0) || 1) * 10000)/100}%
                                            (상위 ${titleRank.fullread_rank}%)`
                                        }
                                        <ul>
                                            <li>{"완독률은 작품을 읽은 사람 중 완독한 사람의 비율이며, 이 값을 통해 작품에 대한 독자반응을 확인할 수 있습니다."}</li>
                                            <li>{"70% 이상 되어야 독자 반응이 호의적이라고 판단하고, 높을수록 좋습니다."}</li>
                                        </ul>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button variant={"contained"} disableElevation
                                            onClick={()=>setIsReadingRateExplanation(false)} color={"primary"} >
                                            {"확인"}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </div>
                        <div className={"Col"}>
                            <div className={"Number"}>{`상위 ${titleRank.retention}%`}</div>
                            <div className={"HelpText"}>{"연독률"}
                                <span
                                    className={"QuestionModal"}
                                    onClick={()=>setIsReadingNextRateExplanation(true)}
                                >
                                    <IconButton className={"QuestionButton"} color="primary" >{"?"}</IconButton>
                                </span>
                                <Dialog className={"Dialog"} open={isReadingNextRateExplanation}>
                                    <DialogContent>
                                        {`연독률 ${Math.floor((((nextClickData.datasets || [{}])[0] || {}).data || []).reduce((a,b) => a+b, 0) / ((((viewData.datasets || [{}, {}])[0] || {}).data || []).reduce((a,b) => a+b, 0) || 1) * 10000)/100}%
                                            (상위 ${titleRank.retention}%)`
                                        }
                                        <ul>
                                            <li>{"연독률은 작품을 읽은 사람 중 다음화 클릭을 누른 사람의 비율이며, 이 값을 통해 작품에 대한 독자반응을 확인할 수 있습니다."}</li>
                                            <li>{"60% 이상 되어야 호의적이라고 판단하고, 높을수록 좋습니다."}</li>
                                        </ul>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button variant={"contained"} disableElevation
                                            onClick={()=>setIsReadingNextRateExplanation(false)} color={"primary"} >
                                            {"확인"}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            :<>
                <div className={"RankContainer"}>
                    <h2 style={{marginLeft: 20}}>{"상대평가 정보"}</h2>
                    <div className={"Rank"}>
                        <div className={"Col"}>
                            <TextField value={titleRank.ctr_rank || -1} onChange={(event)=>{
                                let newValue = event.target.value
                                if (/[^0-9]/.exec(newValue)){
                                    setErrorMessageForCtrRank("허용되지 않은 문자가 있습니다.")
                                    return;
                                }
                                setErrorMessageForCtrRank("");
                                setTitleRank({...titleRank, ctr_rank: event.target.value});
                            }}
                            onKeyDown={(event)=>{
                                if (event.key === "Enter"){
                                    event.target.blur();
                                }
                            }}
                            onBlur={()=>{
                                updateTitleRank(titleRank);
                            }}
                            error={!!errorMessageForCtrRank} helperText={errorMessageForCtrRank}/>
                            <div className={"HelpText"}>{"클릭률 상위 n%"}</div>
                        </div>
                        <div className={"Col"}>
                            <TextField value={titleRank.fullread_rank || -1} onChange={(event)=>{
                                let newValue = event.target.value
                                if (/[^0-9-]/.exec(newValue)){
                                    setErrorMessageForFullReadRank("허용되지 않은 문자가 있습니다.")
                                    return;
                                }
                                setErrorMessageForFullReadRank("");
                                setTitleRank({...titleRank, fullread_rank: event.target.value});
                            }}
                            onKeyDown={(event)=>{
                                if (event.key === "Enter"){
                                    event.target.blur();
                                }
                            }}
                            onBlur={()=>{
                                updateTitleRank(titleRank);
                            }}
                            error={!!errorMessageForFullReadRank} helperText={errorMessageForFullReadRank}/>
                            <div className={"HelpText"}>{"완독률 상위 n%"}</div>
                        </div>
                        <div className={"Col"}>
                            <TextField value={titleRank.retention || -1} onChange={(event)=>{
                                let newValue = event.target.value
                                if (/[^0-9]/.exec(newValue)){
                                    setErrorMessageForRetentionRank("허용되지 않은 문자가 있습니다.")
                                    return;
                                }
                                setErrorMessageForRetentionRank("");
                                setTitleRank({...titleRank, retention: event.target.value});
                            }}
                            onKeyDown={(event)=>{
                                if (event.key === "Enter"){
                                    event.target.blur();
                                }
                            }}
                            onBlur={()=>{
                                updateTitleRank(titleRank);
                            }}
                            error={!!errorMessageForRetentionRank} helperText={errorMessageForRetentionRank}/>
                            <div className={"HelpText"}>{"연독률 상위 n%"}</div>
                        </div>
                    </div>
                </div>
            </>}
        <div className={"FeedbackReport"}>
            <h2 style={{margin: 20}}>{"작품 개선 포인트"}</h2>
            {feedbackList
                .filter(feedback => feedback.userid !== ss.getCurrentSession().userid && feedback.body)
                .map(feedback => 
                    <Feedback key={`ddah-${feedback.id}`} feedback={{serviceId, titleId, episodeId, snapshotId, ...feedback}} isEditable={false}/>
                )}
            {ownershipList.filter(ownTitle => `${ownTitle.serviceId}:${ownTitle.titleId}` === `${serviceId}:${titleId}`).length > 0 && isManager
                ?<Feedback feedback={{serviceId, titleId, episodeId, snapshotId, ...currentFeedback}} isEditable={true}/>
                :<></>}
        </div>
        <div className={"FunnelReport"}>
            <h2 style={{margin: 20}}>{"대중성 분석"}
                <span 
                    className={"QuestionModal"}
                    onClick={()=>setIsAudienceAnalysis(true)}
                >
                    <IconButton className={"QuestionTitleButton"} color="primary" >{"?"}</IconButton>
                </span>
                <Dialog className={"Dialog"} open={isAudienceAnalysis}>
                    <DialogContent>
                        {`CTR ${Math.floor(CTRAverage*10000)/100}% (상위 ${titleRank.ctr_rank}%)`}
                        <ul>
                            <li>{"CTR은 광고를 본 사람 중 클릭하여 들어온 사람의 비율이며, 이 값을 통해 작품의 대중적인 선호도를 확인할 수 있습니다."}</li>
                            <li>{"이 값은 평균적으로 1~3%이며, 높을수록 좋습니다."}</li>
                        </ul><br/>
                        {`완독률(완독 비율) ${Math.floor((((viewData.datasets || [{},{}])[1] || {}).data || []).reduce((a,b) => a+b, 0) / ((((viewData.datasets || [{}, {}])[0] || {}).data || []).reduce((a,b) => a+b, 0) || 1) * 10000)/100}%
                            (상위 ${titleRank.fullread_rank}%)`
                        }
                        <ul>
                            <li>{"완독률은 작품을 읽은 사람 중 완독한 사람의 비율이며, 이 값을 통해 작품에 대한 독자반응을 확인할 수 있습니다."}</li>
                            <li>{"70% 이상 되어야 독자 반응이 호의적이라고 판단하고, 높을수록 좋습니다."}</li>
                        </ul><br/>
                        {`연독률(다음화 클릭) ${Math.floor((((nextClickData.datasets || [{}])[0] || {}).data || []).reduce((a,b) => a+b, 0) / ((((viewData.datasets || [{}, {}])[0] || {}).data || []).reduce((a,b) => a+b, 0) || 1) * 10000)/100}%
                            (상위 ${titleRank.retention}%)`
                        }
                        <ul>
                            <li>{"연독률은 작품을 읽은 사람 중 다음화 클릭을 누른 사람의 비율이며, 이 값을 통해 작품에 대한 독자반응을 확인할 수 있습니다."}</li>
                            <li>{"60% 이상 되어야 호의적이라고 판단하고, 높을수록 좋습니다."}</li>
                        </ul><br/>
                        {`평균 읽은 시간 ${
                            (seconds =>
                                `${seconds > 60?`${Math.floor(seconds/60)}분 `:''}${Math.floor((seconds - 60 * Math.floor(seconds / 60))*100)/100}초`
                            )(
                                labels.map(date => ((funnelStats.contentReadAggregation || {})[date] || {}).totalReadTime || 0).reduce( (a,b) => a+b, 0)
                                / (labels.map(date => ((funnelStats.contentReadAggregation || {})[date] || {  }).readCount || 0).reduce( (a,b) => a+b, 0) || 1)
                            )
                        }`}
                        <ul>
                            <li>{"평균 읽은 시간은 작품을 읽은 사람들이 걸린 평균 시간이며, 이 값을 통해 독자의 재미와 작품의 가독성을 확인할 수 있습니다."}</li>
                            <li>{"1분 30초 이상이 좋습니다, 끝까지 읽은 사람의 경우 2분 30초가 좋습니다."}</li>
                        </ul>

                    </DialogContent>
                    <DialogActions>
                        <Button variant={"contained"} disableElevation
                            onClick={()=>setIsAudienceAnalysis(false)} color={"primary"} >
                            {"확인"}
                        </Button>
                    </DialogActions>
                </Dialog>
            </h2>
            <div className={"ReachBox"}>
            
                <Tabs
                    className={"ReachTaps"}
                    value={reachTab}
                    onChange={(event, newTab) => {
                        setReachTab(newTab)
                    }}
                    variant={"fullWidth"}
                    TabIndicatorProps={{style: {backgroundColor: "rgb(255, 255, 255)"}}}
                    textColor="primary"
                >
                    <Tab 
                        className={`ChartAreaTab ${reachTab === 0?'Active':''}`} 
                        label={<>{"광고 노출"}<div>{labels.map(date => ((funnelStats.impressionAggregation || {})[date] || {}).impressions || 0).reduce((a,b) => a+b, 0)}
                        </div></>} 
                    />
                    <Tab 
                        className={`ChartAreaTab ${reachTab === 1?'Active':''}`} 
                        label={<>{"CTR(노출 대비 클릭 비율)"}<div>{`${Math.floor(CTRAverage*10000)/100}%`}
                        </div></>}
                    />
                    <Tab 
                        className={`ChartAreaTab ${reachTab === 2?'Active':''}`}
                        label={<>{"클릭 수"}<div>{labels.map(date => ((funnelStats.impressionAggregation || {})[date] || {}).clicks || 0).reduce((a,b)=>a+b,0)}
                        </div></>}
                    />
                </Tabs>
                <div
                    role="tabpanel"
                    hidden={reachTab !== 0}
                >
                    {reachTab === 0 
                        ?<div className={"ChartCon"}>
                            <div className={"ChartContainer"}>
                                {impressionData.datasets && <Line
                                    data={impressionData}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        plugins: {
                                            legend: {
                                                display: false
                                            }
                                        },
                                        scales: {
                                            y: {
                                                min: 0,
                                                max: maxImpressionY,
                                                position: 'right',
                                                ticks: {
                                                    beginAtZero: false,
                                                    stepSize: 1
                                                }
                                            },
                                            x: {
                                                display: true,
                                                grid: {
                                                    display: false,
                                                },
                                                scaleLabel: {
                                                    display: false
                                                },
                                                ticks: {
                                                    maxTicksLimit: 4
                                                }
                                            }
                                        }
                                    }}
                                />}
                            </div>
                        </div>
                        :<></>}
                        
                </div>
                <TabPanel value={reachTab} index={1}>
                    <div className={"ChartCon"}>
                        <div className={"ChartContainer"}>
                            {CTRData.datasets && <Line
                                data={CTRData}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    plugins: {
                                        legend: {
                                            display: false
                                        }
                                    },
                                    scales: {
                                        y: {
                                            min: 0,
                                            max: 20,
                                            position: 'right',
                                            ticks: {
                                                beginAtZero: false,
                                                stepSize: 1
                                            },
                                            grace: '5%'
                                        },
                                        x: {
                                            display: true,
                                            grid: {
                                                display: false,
                                            },
                                            scaleLabel: {
                                                display: false
                                            },
                                            ticks: {
                                                maxTicksLimit: 4
                                            }
                                        }
                                    }
                                }}
                            />}
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={reachTab} index={2}>
                    <div className={"ChartCon"}>
                        <div className={"ChartContainer"}>
                            {clickData.datasets && <Line
                                data={clickData}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    plugins: {
                                        legend: {
                                            display: false
                                        }
                                    },
                                    scales: {
                                        y: {
                                            min: 0,
                                            max: maxClickY,
                                            position: 'right',
                                            ticks: {
                                                beginAtZero: false,
                                                stepSize: 1
                                            }
                                        },
                                        x: {
                                            display: true,
                                            grid: {
                                                display: false,
                                            },
                                            scaleLabel: {
                                                display: false
                                            },
                                            ticks: {
                                                maxTicksLimit: 4
                                            }
                                        }
                                    }
                                }}
                            />}
                        </div>
                    </div>
                </TabPanel>


            </div>
            <div className={"ViewBox"}>
                <Tabs
                    className="ViewBar"
                    value={viewTab}
                    onChange={(event, newTab) => {
                        setViewTab(newTab)
                    }}
                    indicatorColor="primary"
                    TabIndicatorProps={{style: {backgroundColor: "rgb(255, 255, 255)"}}}
                    textColor="primary"
                    variant={"fullWidth"}
                >
                    <Tab
                        className={`ChartAreaTab ${viewTab === 0?'Active':''}`} 
                        label={<>{"완독자 / 전체 독자"}<div>{`${(((viewData.datasets || [{},{}])[1] || {}).data || []).reduce((a,b) => a+b, 0) || '--'} / ${(((viewData.datasets || [{}, {}])[0] || {}).data || []).reduce((a,b) => a+b, 0) || '--'}`}
                        </div></>} 
                    />
                    <Tab 
                        className={`ChartAreaTab ${viewTab === 1?'Active':''}`} 
                        label={<>{"완독 비율"}<div>{`${Math.floor((((viewData.datasets || [{},{}])[1] || {}).data || []).reduce((a,b) => a+b, 0) / ((((viewData.datasets || [{}, {}])[0] || {}).data || []).reduce((a,b) => a+b, 0) || 1) * 10000)/100}%`}
                        </div></>} 
                    />
                    <Tab 
                        className={`ChartAreaTab ${viewTab === 2?'Active':''}`} 
                        label={<>{"다음화 클릭"}<div>{`${
                            (((nextClickData.datasets || [{}])[0] || {}).data || []).reduce((a,b) => a+b, 0) || '--'
                        } / ${
                            Math.floor((((nextClickData.datasets || [{}])[0] || {}).data || []).reduce((a,b) => a+b, 0) / ((((viewData.datasets || [{}, {}])[0] || {}).data || []).reduce((a,b) => a+b, 0) || 1) * 10000)/100
                        }%`}
                        </div></>} 
                    />
                </Tabs>
                <div
                    role="tabpanel"
                    hidden={viewTab !== 0}
                >
                    {viewTab === 0 
                        ?<div className={"WatcherChart"}>
                            {viewData.datasets && <Line
                                data={viewData}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    plugins:{
                                        legend: {
                                            display: false,
                                        },
                                    },
                                    scales: {
                                        x: {
                                            grid: {
                                                display: false,
                                            },
                                        },
                                        y: {
                                            position: 'right',
                                            min: 0,
                                            max: maxViewY,
                                            ticks: {
                                                stepSize: 1,
                                            }
                                        },
                                    },
                                }}
                            />}
                        </div>
                        :<></>}
                </div>

                <TabPanel value={viewTab} index={1}>
                    {engageData.datasets && <Line
                        className={"WatcherChart"}
                        data={engageData}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins:{
                                legend: {
                                    display: false,
                                },
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                },
                                y: {
                                    position: 'right',
                                    min: 0,
                                    max: 1.1,
                                    ticks: {
                                        stepSize: 1,
                                    }
                                },
                            },
                        }}
                    />}
                </TabPanel>
                <TabPanel value={viewTab} index={2}>
                    {nextClickData.datasets && <Line
                        className={"WatcherChart"}
                        data={nextClickData}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins:{
                                legend: {
                                    display: false,
                                },
                            },
                            scales: {
                                x: {
                                    grid: {
                                        display: false,
                                    },
                                },
                                y: {
                                    position: 'right',
                                    min: 0,
                                    max: maxViewY,
                                    ticks: {
                                        stepSize: 1,
                                    }
                                },
                            },
                        }}
                    />}
                </TabPanel>
            </div>
            <div className={"EngageBox"}>
                
                <Tabs
                    className={"Table"}
                    value={engageTab}
                    onChange={(event, newTab) => {
                        setEngageTab(newTab)
                    }}
                    variant={"fullWidth"}
                    TabIndicatorProps={{style: {backgroundColor: "rgb(255, 255, 255)"}}}
                    textColor="primary"
                >
                    <Tab 
                        className={`ChartAreaTab ${engageTab === 0?'Active':''}`} 
                        label={<>{"총 읽은 시간(시간)"}<div>{
                            Math.floor(
                            labels.map(date => ((funnelStats.contentReadAggregation || {})[date] || {}).totalReadTime || 0).reduce( (a,b) => a+b, 0)/3600
                            *100) / 100
                        }
                        </div></>}
                    />
                    <Tab 
                        className={`ChartAreaTab ${engageTab === 1?'Active':''}`} 
                        label={<>{"평균 읽은 시간(분)"}<div>{
                            (seconds =>
                                `${seconds > 60?`${Math.floor(seconds/60)}분 `:''}${Math.floor((seconds - 60 * Math.floor(seconds / 60))*100)/100}초`
                            )(
                                labels.map(date => ((funnelStats.contentReadAggregation || {})[date] || {}).totalReadTime || 0).reduce( (a,b) => a+b, 0)
                                / (labels.map(date => ((funnelStats.contentReadAggregation || {})[date] || {  }).readCount || 0).reduce( (a,b) => a+b, 0) || 1)
                                
                            )
                        }
                        </div></>}
                    />
                </Tabs>
                <div
                    role="tabpanel"
                    hidden={engageTab !== 0}
                >
                    {engageTab === 0 
                        ?<div className={"ChartCon"}>
                            <div className={"ChartContainer"}>
                                {totalDurationData.datasets && <Line
                                    data={totalDurationData}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        plugins: {
                                            legend: {
                                                display: false
                                            }
                                        },
                                        scales: {
                                            y: {
                                                min: 0,
                                                max: (totalDurationData.datasets || [{data: []}])[0].data.reduce( (a,b) => a>b?a:b, 0) * 1.1,
                                                position: 'right',
                                                ticks: {
                                                    beginAtZero: false,
                                                    stepSize: 1
                                                }
                                            },
                                            x: {
                                                grid: {
                                                    display: false,
                                                },
                                                scaleLabel: {
                                                    display: false
                                                },
                                                display: true,
                                            }
                                        }
                                    }}
                                />}
                            </div>
                        </div>
                        :<></>}
                        
                </div>
                <TabPanel value={engageTab} index={1}>
                    <div className={"ChartCon"}>
                        <div className={"ChartContainer"}>
                            {totalAvergaeDurationData.datasets && <Line
                                data={totalAvergaeDurationData}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    plugins: {
                                        legend: {
                                            display: false
                                        }
                                    },
                                    scales: {
                                        y: {
                                            min: 0,
                                            max: (totalAvergaeDurationData.datasets || [{},{data: []}])[1].data.reduce( (a,b) => a>b?a:b, 0) * 1.1,
                                            position: 'right',
                                            ticks: {
                                                beginAtZero: false,
                                                stepSize: 1
                                            }
                                        },
                                        x: {
                                            grid: {
                                                display: false,
                                            },
                                            scaleLabel: {
                                                display: false
                                            },
                                            display: true,
                                        }
                                    }
                                }}
                            />}
                        </div>
                        <Button className={"SeeMore"}>{"See more"}</Button>
                    </div>
                </TabPanel>

            </div>
            <div className={"AudienceBox"}>
                <TextBox
                    Title={"세대성별 분석"}
                    SubTitle={"Views Since published"}
                    ChartAdd={
                        <div className={"MainChart"}>
                            <div className={"DoughnutArea"}>
                                {donutData.dataSets && <Doughnut
                                    className={"Donut"}
                                    data={donutData}
                                    options={donutOptions}
                                    plugins={[ChartDataLabels]}
                                />}
                            </div>
                            <div className={"CohortTable"}>
                                {["18-24","25-34","35-44","45-54","55-64","65+","Unknown"].map(age => 
                                    <div key ={`ddah-${age}`} className={`Row ${age}`}>
                                        {["Female", "Classification", "Male", "Unknown"].map(gender =>
                                            <>
                                                {gender === "Classification"
                                                ?<div className={"AgeScope"}>{`${age === "Unknown"?`미상`:`${age.replace('-','~')}`}`}</div>
                                                :<></>}
                                                {gender !== "Classification"
                                                ?<div key={`ddah-${gender}`} className={`Cell ${gender}`}>
                                                    <Tooltip title={`${gender}:${age}: ${(demographicStat[`${gender.toLowerCase()}:${age.toLowerCase()}`] || {}).clicks || 0}`}>
                                                        <div style={{width: `${((demographicStat[`${gender.toLowerCase()}:${age.toLowerCase()}`] || {}).clicks || 0) /(maxWidth || 1) * 100}%`}}></div>
                                                    </Tooltip>
                                                    <Tooltip title={`${gender}:${age}: ${(demographicStat[`${gender.toLowerCase()}:${age.toLowerCase()}`] || {}).impressions || 0}, CTR: ${
                                                        Math.floor(
                                                            ((demographicStat[`${gender.toLowerCase()}:${age.toLowerCase()}`] || {}).clicks || 0) 
                                                            / ((demographicStat[`${gender.toLowerCase()}:${age.toLowerCase()}`] || {}).impressions || 1) * 10000
                                                        ) / 100
                                                    }%`}>
                                                        <div style={{
                                                            width: `${(((demographicStat[`${gender.toLowerCase()}:${age.toLowerCase()}`] || {}).impressions || 0)
                                                            - ((demographicStat[`${gender.toLowerCase()}:${age.toLowerCase()}`] || {}).clicks || 0)) /(maxWidth || 1) * 100}%`
                                                            
                                                        }}></div>
                                                    </Tooltip>
                                                </div>:<></>}
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
        <div className={`DeepdiveReport ${isDeepdiveSeeing?'Exposed':'Hidden'}`}>
            <h2 style={{margin: 20}}>{"흡인력 분석"}</h2>
            <Contents {...{serviceId, titleId, episodeId, snapshotId, setSnapshotId: ()=>{}, mode: 'report'}}/>
        </div>
    </div>);
}
export default Report;