import axios from 'axios';
import ss from '../../../../Data/Session';

export const readContentsStat = async ({serviceId, titleId, episodeId, snapshotId}, ) => {
    let ret = [];

    let req = {
        serviceId, titleId, episodeId, snapshotId
    };
    let res = await axios.post('https://challenge-api.webtoon.today/contents-stat', req, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        ret = res.data.data;
    }else{
        console.error(res.code, res.data);
    }
    
    return ret;
};